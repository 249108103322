import muiTableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
    head: {
        backgroundColor: "#333333",
        maxWidth: 200,
        [theme.breakpoints.up("lg")]: {
            padding: "10px 12px",
            "&:last-child": {
                paddingRight: 12
            }
        },
        [theme.breakpoints.down("md")]: {
            padding: "4px 8px",
            "&:last-child": {
                paddingRight: 8
            }
        },
        "& p": {
            fontWeight: 500,
            color: "#ffffff"
        },
        "& svg": {
            color: "#ffffff"
        }
    },
    body: {
        fontSize: 14.5,
        fontFamily: "'Source Sans Pro', sans-serif",
        color: "#262626",
        fontWeight: 400,
        maxWidth: 200,
        [theme.breakpoints.up("lg")]: {
            padding: "4px 12px",

            "&:last-child": {
                paddingRight: 12
            }
        },
        [theme.breakpoints.down("md")]: {
            padding: "4px 8px",
            "&:last-child": {
                paddingRight: 8
            }
        }
    }
});

export default withStyles(styles)(muiTableCell);
