import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import Toolbar from "@material-ui/core/Toolbar";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import EditIcon from "@material-ui/icons/Edit";
import { withStyles } from "@material-ui/core";

import Close from "@material-ui/icons/Close";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import AppContainer from "../common/AppContainer";
import TextField from "../common/TextField";
import EnhancedTableHead from "../common/EnhancedTableHead";
import CustomTableCell from "../common/TableCell";
import { createSelector } from "../common/orm";
import WarningDialog from "../common/WarningDialog";
import { Page, ModuleNotes } from "../page/models";


const getPage = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, pageID) => {
        return session.Page.withId(pageID);
    }
);

const getNotes = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, page_id) => {
        return session.ModuleNotes.filter((rs) => rs.page === page_id).orderBy("id", "desc").toRefArray();
    }
);

const styles = (theme) => ({
    centerAlign: {
        textAlign: "center",
    },
    nowrap: {
        whiteSpace: "nowrap",
    },
    minWidth: {
        minWidth: 600,
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        margin: "0 8px 0 0",
    },
    flex: {
        flex: 1,
    },
});

const columnData = [
    { id: "actions", numeric: false, label: "", allowSort: false },
    { id: "notes", numeric: false, label: "Notes", allowSort: true },
    { id: "user_label", numeric: false, label: "User", allowSort: true },
    { id: "formatted_date_added", numeric: false, label: "Date Added", allowSort: true },
];

class ModuleNotesContainer extends Component {
    state = {
        order: "desc",
        orderBy: "id",
        dialogOpen: false,
        deleteDialogOpen: false,
        selectedNote: null
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        if (orderBy === "formatted_date_added") {
            order === "desc"
                ? this.props.notes.sort((a, b) => (new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1))
                : this.props.notes.sort((a, b) => (new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1));
        } else {
            order === "desc"
                ? this.props.notes.sort((a, b) => (b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1))
                : this.props.notes.sort((a, b) => (a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1));
        }

        this.setState({ order, orderBy });
    };

    updateNotes = (value) => {
        const { page, ormPageUpdate } = this.props;
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function () {
            ormPageUpdate({ id: page.id, general_notes: value });
        }, 1000);
    };

    updateRecord = (values) => {
        const { ormModuleNotesCreate, ormModuleNotesUpdate, authState, page } = this.props;
        const { selectedNote } = this.state;
        if (selectedNote && selectedNote.id) {
            ormModuleNotesUpdate({
                id: selectedNote.id,
                ...values,
            });
        } else {
            ormModuleNotesCreate({
                user_id: authState.user.id,
                page_id: page.id,
                ...values,
            });
        }
        this.setState({ dialogOpen: false });
    };

    render() {
        const { classes, page, notes, ormModuleNotesDelete } = this.props;
        const { order, orderBy, selectedNote, dialogOpen, deleteDialogOpen } = this.state;

        return (
            <AppContainer authenticated>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                            {page.name} Configuration Notes
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={10}>
                        <Form defaultValues={page}>
                            {(formApi) => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                field="general_notes"
                                                label="General Configuration Notes"
                                                multiline
                                                rows={6}
                                                eventHandle={this.updateNotes}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button variant="contained" color="primary">
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>       
                    </Grid>
                    <Grid item xs={12} md={10} lg={4} xl={3}>
                        <Button
                            onClick={() => this.setState({ dialogOpen: true, selectedNote: null })}
                            fullWidth
                            variant="contained"
                            color="primary">
                            <AddCircleOutlineIcon />
                            &nbsp;&nbsp;&nbsp;Add New Log Entry
                        </Button>
                    </Grid>
                    <Grid item xs={12} lg={10}>
                        <Table>
                            <EnhancedTableHead columnData={columnData} order={order} orderBy={orderBy} onRequestSort={this.handleRequestSort} />
                            <TableBody>
                                {notes.map((l) => {
                                    return (
                                        <TableRow hover>
                                            <CustomTableCell className={classes.nowrap}>
                                                <Tooltip title="Edit">
                                                    <Button
                                                        onClick={() => this.setState({ dialogOpen: true, selectedNote: l })}
                                                        className={classes.deleteWidth}>
                                                        <EditIcon />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="Delete">
                                                    <Button
                                                        onClick={() => this.setState({ deleteDialogOpen: true, selectedNote: l })}
                                                        className={classes.deleteWidth}>
                                                        <DeleteIcon />
                                                    </Button>
                                                </Tooltip>
                                            </CustomTableCell>
                                            <CustomTableCell>{l.notes}</CustomTableCell>
                                            <CustomTableCell>{l.user_label}</CustomTableCell>
                                            <CustomTableCell>{l.formatted_date_added}</CustomTableCell>
                                        </TableRow>
                                    );
                                })}
                                {notes.length < 1 && (
                                    <TableRow>
                                        <CustomTableCell colSpan={4} className={classes.centerAlign}>
                                            No Entries Added
                                        </CustomTableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                <Dialog open={dialogOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar>
                        <Typography variant="h5" className={classes.flex}>
                            {selectedNote === null ? "Add New Notes Record" : "Edit Notes Record"}
                        </Typography>
                        <IconButton onClick={() => this.setState({ dialogOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent>
                        <DialogContentText>
                            <Form
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                defaultValues={selectedNote}
                                onSubmit={(values) => this.updateRecord(values)}>
                                {(formApi) => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField field="notes" label="Notes" multiline rows={6} fullWidth />
                                            </Grid>
                                            <Grid item xs={12} className={classes.centerAlign}>
                                                <Button type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <WarningDialog
                    confirmAction={() => {
                        ormModuleNotesDelete(selectedNote.id);
                        this.setState({ deleteDialogOpen: false });
                    }}
                    cancelAction={() => this.setState({ deleteDialogOpen: false })}
                    open={deleteDialogOpen}
                    title="Delete Record"
                    text={"Are you sure you wish to permanently delete this record?"}
                />
            </AppContainer>
        );
    }
}
ModuleNotesContainer.displayName = "ModuleNotesContainer";

ModuleNotesContainer = connect(
    (state, ownProps) => ({
        notes: getNotes(state, ownProps),
        page: getPage(state, ownProps),
        authState: state.auth,
    }),
    {
        ...Page.actions,
        ...ModuleNotes.actions,
    }
)(ModuleNotesContainer);

export default withStyles(styles)(withRouter(ModuleNotesContainer));
