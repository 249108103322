import {
    AUTH_LOGIN_PENDING,
    AUTH_LOGIN_ERROR,
    AUTH_LOGIN_SUCCESS,
    AUTH_LOGOUT,
    AUTH_REGISTER_PENDING,
    AUTH_REGISTER_SUCCESS,
    AUTH_REGISTER_ERROR,
    AUTH_REVALIDATE_PENDING,
    AUTH_REVALIDATE_SUCCESS,
    AUTH_REVALIDATE_ERROR,
    AUTH_UPDATE_PENDING,
    AUTH_UPDATE_SUCCESS,
    AUTH_UPDATE_ERROR,
    AUTH_PASSWORD_RESET_PENDING,
    AUTH_PASSWORD_RESET_SUCCESS,
    AUTH_PASSWORD_RESET_ERROR,
    AUTH_PASSWORD_RECOVER_PENDING,
    AUTH_PASSWORD_RECOVER_SUCCESS,
    AUTH_PASSWORD_RECOVER_ERROR,
    AUTH_PASSWORD_CHANGE_PENDING,
    AUTH_PASSWORD_CHANGE_SUCCESS,
    AUTH_PASSWORD_CHANGE_ERROR
} from "./actions";

//error output to show on the screen, maps over the "data" array joins multiple with a comma
function serializeError(data) {
    return (
        "Error: " +
        Object.values(data)
            .map(err => err /*[0]*/)
            .join(", ")
    );
}

//authorization reducer takes in state and action
function authReducer(state = {}, action) {
    switch (action.type) {
        case AUTH_LOGIN_PENDING:
            return {
                pending: true,
                user: state.user || null
            };
        case AUTH_LOGIN_SUCCESS:
            return {
                user: action.user
            };
        case AUTH_LOGIN_ERROR:
            return {
                error: action.error
            };
        case AUTH_LOGOUT:
            return {};
        case AUTH_REGISTER_PENDING:
            return { register: { pending: true } };
        case AUTH_REGISTER_SUCCESS:
            return { register: { success: true } };
        case AUTH_REGISTER_ERROR:
            return { register: { error: serializeError(action.error) } };
        case AUTH_UPDATE_PENDING:
            return {
                update: {
                    pending: true
                },
                user: state.user
            };
        case AUTH_UPDATE_SUCCESS:
            return {
                update: {
                    success: true
                },
                user: {
                    ...state.user,
                    ...action.user
                }
            };
        case AUTH_UPDATE_ERROR:
            return {
                update: {
                    error: serializeError(action.error)
                },
                user: state.user
            };
        case AUTH_REVALIDATE_PENDING:
            return { revalidate: { pending: true } };
        case AUTH_REVALIDATE_SUCCESS:
            return { revalidate: { success: true } };
        case AUTH_REVALIDATE_ERROR:
            return { revalidate: { error: serializeError(action.error) } };
        case AUTH_PASSWORD_RESET_PENDING:
            return { passwordReset: { pending: true } };
        case AUTH_PASSWORD_RESET_SUCCESS:
            return { passwordReset: { success: action.data } };
        case AUTH_PASSWORD_RESET_ERROR:
            return { passwordReset: { error: action.error } };
        case AUTH_PASSWORD_RECOVER_PENDING:
            return { passwordRecover: { pending: true } };
        case AUTH_PASSWORD_RECOVER_SUCCESS:
            return { passwordRecover: { success: true } };
        case AUTH_PASSWORD_RECOVER_ERROR:
            return { passwordRecover: { error: serializeError(action.error) } };
        case AUTH_PASSWORD_CHANGE_PENDING:
            return {
                change: {
                    pending: true
                },
                user: state.user
            };
        case AUTH_PASSWORD_CHANGE_SUCCESS:
            return {
                change: {
                    success: true
                },
                user: state.user
            };
        case AUTH_PASSWORD_CHANGE_ERROR:
            return {
                change: {
                    error: serializeError(action.error)
                },
                user: state.user
            };
        default:
            return state;
    }
}

export default authReducer;
