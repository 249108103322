import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Form as Formm } from "react-form";

import Grid from "@material-ui/core/Grid";
import { createSelector } from "../../common/orm";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import SectionTabHeader from "./SectionTabHeader";
import TopButtons from "./TopButtons";

import { RecordSection, RecordField, RepeatableField, RepeatableTable } from "../models";
import Select from "../../common/Select";
import HelpLabel from "../../common/HelpLabel";
import AppContainer from "../../common/AppContainer";
// import RepeatableTable from "./RepeatableTable";

const getPermission = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["pageId"]),
    (session, pageID) => {
        // Get all permissions related to the page id
        return session.Permission.filter((p) => p.page === pageID).toRefArray();
    }
);

const getPage = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["pageId"]),
    (session, pageID) => {
        // Get the specific page we are on
        return session.Page.withId(pageID);
    }
);

const getSection = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["sectionId"]),
    (session, sectionId) => {
        // Get the specific section we are on
        return session.Section.withId(sectionId);
    }
);

const getForms = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["sectionId"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (session, actSection) => {
        return session.Form.filter((sect) => sect.section === actSection)
            .orderBy("id")
            .toModelArray()
            .map((form) => ({
                groups: form.groups //form.groups is FK linkage
                    .all()
                    .orderBy("id")
                    .toModelArray()
                    .map((group) => ({
                        fields: group.fields.all().orderBy("order").toRefArray(),
                        ...group.ref, // take all groups as a reference and tie in each field
                    })),
                ...form.ref, // take all forms as a reference and tie in each group
            }));
    } //toRefArray returns a plain JavaScript object which are references to the DB
);
const getParentRecordId = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["parentRecordId"]),
    (session, parentRecord) => {
        return parentRecord;
    }
);

const getRecordSection = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["sectionId"]),
    (state, ownProps) => parseInt(ownProps.match.params["parentRecordId"]),
    (session, sectionId, parentId) => {
        return session.RecordSection.filter((f) => f.section === sectionId && f.parentrecord === parentId).toRefArray();
    }
);

const getParentRecordSection = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["parentRecordId"]),
    (session, parentId) => {
        return session.RecordSection.filter((f) => f.parentrecord === parentId).orderBy("name").first();
    }
);
const styles = (theme) => ({
    flex: {
        flex: 1,
    },
    dialogMin: {
        minWidth: 420,
        minHeight: 145,
    },

    minWidth: {
        maxWidth: 507,
        minWidth: 489,
    },
    progress: {
        display: "table",
        marginLeft: "auto",
        marginRight: "auto",
    },
});

class CreateRepeatableSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            flag: false,
            count: 0,
            open: true,
            loadingFeatures: false,
        };
    }
    handleForm = (form) => {
        const {
            forms,
            page,
            parentrecordid,
            history,
            ormRecordSectionUpdateLocalOnly,
            authState,
        } = this.props;
        const { count } = this.state;

        var actForm = [];

        //get actual form from multiple forms
        if (form) {
            actForm = forms.filter((e) => {
                if (e.name === form) {
                    return e;
                } else {
                    return null;
                }
            });
        } else {
            //get single form
            actForm[0] = forms[0];
        }
        if (actForm.length === 1) {
            this.setState({
                loading: true,
                flag: false,
                count: count + 1,
            });

            if (actForm[0].groups.length > 0 && count === 0) {
                try {
                    //eslint-disable-next-line
                    const res = fetch(
                        `page/${page.id}/${parentrecordid}/${actForm[0].section}/${actForm[0].id}/${actForm[0].groups[0].fields.length}/${authState.user.auth_token}`,
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "Token " + authState.user.auth_token,
                            },
                        }
                    ).then((e) => {
                        if (e.status === 200) {
                            e.json().then((rsp) => {
                                ormRecordSectionUpdateLocalOnly({
                                    id: rsp.rsid,
                                    has_children_loaded: true,
                                });

                                if (this.props.loadingFields) this.props.loadingFields(false); //set circular dial off from 'expansionPanelFormRecord'
                                history.push(
                                    "/page/" +
                                        rsp.page +
                                        "/parentrecord/" +
                                        rsp.prid +
                                        "/section/" +
                                        rsp.section +
                                        "/recordsection/" +
                                        rsp.rsid +
                                        "/form"
                                );
                            });
                        } else {
                            this.setState({ loading: false });
                        }
                    });
                } catch {
                    this.setState({ loading: false });
                }
            }
        }
    };

    componentDidMount() {
        const { parentrecordid, ormRecordSectionLoadDetailChild } = this.props;
        const { loadingFeatures } = this.state;
        var component = this;

        if (parentrecordid && !loadingFeatures) {
            ormRecordSectionLoadDetailChild(
                parentrecordid,
                function () {
                    component.setState({ loadingFeatures: false, flag: true }); //callback sets back to false after recordsections are loaded
                },
                "RecordSectionMultiple" //flag to get all recordsections for parentId
            );

            this.setState({ loadingFeatures: true }); //sets to true initally to run above ormrecordsectionloaddetailchild
        }
    }

    render() {
        const { authState, permission, classes, forms, page, section, recordsection, history, parentrecordsection } = this.props;
        const { loading, open, flag, loadingFeatures } = this.state;

        if (!page) return "";

        if (authState.user) {
            var readOnly;
            var perm = permission.find((p) => {
                if (p.user === authState.user.id) {
                    return p;
                }
                return null;
            });
            if (perm) readOnly = perm.readonly || perm.readonlyexternal ? true : false;
            if (readOnly === undefined) readOnly = false;
        }
        // These two functions just make values to a mandatory label/value which is what react-forms expects
        function MAKE_OPTIONS(table) {
            return table.map((row) => ({
                label: row,
                value: row,
            }));
        }

        //this is to get the 'formNames' for the selected section so that the 'dialog dropdown' can be populated.
        if (forms)
            var formNames = forms.map((n) => {
                return n.name;
            });

        var onlyOneForm = {}; // for 1 form creation
        if (formNames) onlyOneForm.label = formNames[0];

        var title = loading ? "Creating a New Record" : "Select a Record Type";

        var iframe = section.iframe_url_section;
        if (iframe && iframe.includes("[") && !loadingFeatures) {
            var foundfield = iframe.match(/\[[a-zA-Z0-9._\s]+\]+/); //has brackets

            if (foundfield && foundfield.length > 0) {
                var removedbracketsfield = foundfield[0].replace(/[^-+*/\w\s]/gi, ""); //remove brackets from found fields
                iframe = iframe.replace("[" + removedbracketsfield + "]", parentrecordsection.columns.find(x => x.field === removedbracketsfield).value);
            }
        }


        return (
            //more than "One" form and repeatable section has 'not' been created yet
            <AppContainer authenticated>
                {section && section.iframe_url_section && !loadingFeatures ? (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid item xs={12}>
                                    <TopButtons perm={perm} section={section} page={page} authState={authState} showPageName={true} />
                                </Grid>
                                <SectionTabHeader value={section.id} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{ height: "calc(100% - 56px)" }}>
                            <Grid item xs={12} style={{ paddingTop: 40, marginLeft: 28, marginRight: 22, height: "100%" }}>
                                <iframe
                                    src={iframe}
                                    title={section.name}
                                    frameborder="0"
                                    style={{ overflow: "hidden", height: "99%", width: "100%" }}
                                    height="99%"
                                    width="100%"></iframe>
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <>
                        {forms.length > 0 && recordsection.length === 0 && !loadingFeatures && (
                            <>
                                {/*used to keep screen with topbuttons and sectiontab header without flashing */}
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid item xs={12}>
                                            <TopButtons perm={perm} section={section} page={page} authState={authState} showPageName={true} />
                                        </Grid>
                                        <SectionTabHeader value={section.id} />
                                    </Grid>
                                </Grid>
                                {!loading && (
                                    <Dialog open={open}>
                                        <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                                            <Typography variant="h5" className={classes.flex}>
                                                {title}
                                            </Typography>
                                        </Toolbar>
                                        <DialogContent style={{ minWidth: 375 }}>
                                            <DialogContentText>
                                                <Formm
                                                    getApi={(el) => (this.formApi = el)}
                                                    onSubmit={(values) => this.handleForm(values)}
                                                    style={{ width: "50%" }}>
                                                    {(formApi) => (
                                                        <form onSubmit={formApi.submitForm}>
                                                            {!loading &&
                                                                formNames &&
                                                                formNames.length > 1 && ( //only display if there is a prior form created
                                                                    <Select
                                                                        style={{ paddingRight: "1%", paddingLeft: "1%" }}
                                                                        field="name"
                                                                        label={
                                                                            <HelpLabel
                                                                                inputLabel="Select a Form"
                                                                                helpText="Select a Reapeatable Form"
                                                                            />
                                                                        }
                                                                        options={MAKE_OPTIONS(formNames)}
                                                                        eventHandle={this.handleForm}
                                                                        fullWidth
                                                                    />
                                                                )}
                                                        </form>
                                                    )}
                                                </Formm>
                                            </DialogContentText>
                                        </DialogContent>
                                    </Dialog>
                                )}
                            </>
                        )}
                        {/* route to "automatically" create new record as we only have ONE form and not a prior record created */}
                        {forms.length === 1 && recordsection.length === 0 && flag && this.handleForm()}
                        {forms.length > 0 && //recordsection already "created" so route to it
                            recordsection.length > 0 &&
                            flag &&
                            history.push(
                                "/page/" +
                                    page.id +
                                    "/parentrecord/" +
                                    recordsection[0].parentrecord +
                                    "/section/" +
                                    section.id +
                                    "/recordsection/" +
                                    recordsection[0].id +
                                    "/form"
                            )}
                        {forms.length > 0 && loadingFeatures && (
                            // Show a loading screen while waiting for the recordsection to load
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        <TopButtons perm={perm} section={section} page={page} authState={authState} showPageName={true} />
                                    </Grid>
                                    <SectionTabHeader value={section.id} />

                                    <CircularProgress size={90} className={classes.progress} />
                                    <Typography variant="h6" style={{ textAlign: "center" }}>
                                        Loading... Please Wait
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                    </>
                )}
            </AppContainer>
        );
    }
}
CreateRepeatableSection.displayName = "CreateRepeatableSection";

CreateRepeatableSection = connect(
    (state, ownProps) => ({
        authState: state.auth,
        permission: getPermission(state, ownProps),
        page: getPage(state, ownProps),
        section: getSection(state, ownProps),
        forms: getForms(state, ownProps),
        recordsection: getRecordSection(state, ownProps),
        parentrecordid: getParentRecordId(state, ownProps),
        parentrecordsection: getParentRecordSection(state, ownProps),
    }),
    {
        ...RecordSection.actions,
        ...RecordField.actions,
        ...RepeatableTable.actions,
        ...RepeatableField.actions,
    }
)(CreateRepeatableSection);

export default withStyles(styles)(withRouter(CreateRepeatableSection));
