import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
import AppContainer from "../common/AppContainer";
import { Permission } from "../page/models";
import CreateAdvancedSearches from "../page/advancedSearch/CreateAdvancedSearches";

const styles = (theme) => ({});

const getdashboardPermissions = Permission.selectAll();

class Dashboard extends Component {
    render() {
        const { sync, authState, sections, page, parentrecords, dashboardPermissions } = this.props;
        var synced = false;
        // If the record sections haven't loaded yet (sync.progress < sync.total) and never have been loaded (recordsections.length === 0) then display the loading icon
        // if (recordsections.length === 0 && sync.progress < sync.total) {
        //     synced = true;
        // }

        if (parentrecords && parentrecords.length === 0 && sections && page.length > 0 && sync.progress < sync.total) {
            synced = true;
        }

        var filteredDashboardPermissions = dashboardPermissions.filter((d) => {
            if (authState.user) {
                if (d.user === authState.user.id) {
                    return d;
                } else return null;
            } else return null;
        });

        return (
            <AppContainer authenticated synced={synced}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Saved Search Groups</Typography>
                        <CreateAdvancedSearches dashboardNotConfigured={true} filteredDashboardPermissions={filteredDashboardPermissions} />
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}
Dashboard.displayName = "Dashboard";

Dashboard = connect(
    (state, ownProps) => ({
        sync: state.sync,
        authState: state.auth,
        dashboardPermissions: getdashboardPermissions(state, ownProps),
    }),
    null
)(Dashboard);

export default withStyles(styles)(withRouter(Dashboard));
