import { createMuiTheme } from "@material-ui/core/styles/";

// Place any constant values here you wish to access across multiple components

// Global Yes/No radio buttons
export const YES_NO_OPTIONS = [
    { label: "Yes", value: "true" },
    { label: "No", value: "false" }
];

// Convert API name/id into react label/value
export function MAKE_OPTIONS(table) {
    return table.map(row => ({
        label: row.name,
        value: row.id
    }));
}

export const theme = createMuiTheme({
    typography: {
        fontFamily: "'Source Sans Pro', sans-serif"
    },
    overrides: {
        MuiInputLabel: {
            root: {
                fontSize: 15,
                fontWeight: 400,
                color: "#262626 !important"
            },
            shrink: {
                transform: "translate(0, 0px) scale(1)"
            }
        },
        MuiListItem: {
            root: {
                fontSize: 16,
                fontWeight: 400,
                color: "#262626"
            }
        },
        MuiTab: {
            root: {
                minWidth: "120px !important",
                minHeight: "48px !important"
            }
        },
        MuiTypography: {
            h6: {
                fontWeight: 400,
                fontSize: 23,
                color: "#262626"
            },
            h5: {
                fontWeight: 400,
                fontSize: 23,
                color: "#262626"
            },
            h4: {
                fontWeight: 400,
                fontSize: 20,
                color: "#262626"
            },
            body1: {
                fontWeight: 400,
                fontSize: 15,
                color: "#262626"
            },
            gutterBottom: {
                marginBottom: ".6em"
            }
        },
        MuiButton: {
            root: {
                textTransform: "none",
                fontSize: 16,
                fontWeight: 300,
                color: "#2b6dad",
                "&:hover": {
                    backgroundColor: "#F9EBC8",
                    textDecoration: "none"
                },
                border: "1px solid",
                borderRadius: 4
            },
            contained: {
                border: "none",
                fontSize: 18,
                fontWeight: 400
            }
        }
    },
    //https://material.io/tools/color/#!/?view.left=1&view.right=1&primary.color=3c8ac7&secondary.color=74A94C
    palette: {
        primary: {
            light: "#75bafa",
            main: "#3c8ac7",
            dark: "#005d96",
            contrastText: "#fff"
        },
        secondary: {
            light: "#a5db7a",
            main: "#74A94C",
            dark: "#457a1f",
            contrastText: "#fff"
        }
    }
});
