import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Button, withStyles } from "@material-ui/core";

import HelpLabel from "../common/HelpLabel";
import AppContainer from "../common/AppContainer";
import Submit from "../common/Submit";
import Select from "../common/Select";
import TextField from "../common/TextField";
import * as authActions from "./actions";
import { Page } from "../page/models";
import { User } from "../adm/models";
import { createSelector } from "../common/orm";

const styles = {
    registerError: {
        color: "red",
        textAlign: "center",
    },
};

const getUser = User.selectByUrlId();

const getPages = Page.selectAll();

const getUserPermissions = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, id) => {
        return session.Permission.filter((p) => p && p.user === id && (p.readonly || p.fullaccess)).toRefArray();
    }
);
function MAKE_OPTIONS(page) {
    return page.map((page) => ({
        label: page.name,
        value: page.name,
    }));
}
class UpdatePage extends Component {
    errorValidator = (values) => {
        const validateEmail = (email) => {
            var re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !re.test(email) ? "Invalid Email Address" : null;
        };
        return {
            email: validateEmail(values.email),
        };
    };
    errorValidatorPassword = (values) => {
        const validatePasswordSingle = (password) => {
            if (!password || password.length < 8) return "Invalid password";
            else return null;
        };
        const validatePassword = (password, password2) => {
            if (password !== password2) return "Passwords do not match";
            else if (!password || password.length < 8) return "Invalid password";
            else return null;
        };
        return {
            current_password: validatePasswordSingle(values.current_password),
            new_password: validatePassword(values.new_password, values.re_new_password),
            re_new_password: validatePassword(values.new_password, values.re_new_password),
        };
    };

    submitForm = (values) => {
        this.props.authUpdate(values).then(() => {
            if (this.props.update.success) {
                this.props.history.push("/dashboard");
            }
        });
    };

    submitFormPassword = (values) => {
        this.props.authPasswordChange(values).then(() => {
            if (this.props.change.success) {
                this.props.history.push("/dashboard");
            }
        });
    };

    defaultDashboard = () => {
        const { pages, user } = this.props;
        let dashboard = pages.find((p) => p.id === parseInt(user.default_dashboard));
        let obj = {};

        if (dashboard && dashboard.name) {
            obj["default_dashboard"] = dashboard.name;
            return obj;
        }
    };
    submitDashboard = (values) => {
        const { user } = this.props.authState;

        const { pages, ormUserUpdate } = this.props;
        let newDashboard = pages.find((p) => p.name === values.default_dashboard);
        user.default_dashboard = newDashboard.id;
        ormUserUpdate({ id: user.id, default_dashboard: newDashboard.id });
        setTimeout((dum) => {
            this.props.history.push("/dashboard");
        }, 700);
    };

    render() {
        const { classes, update, change, authState, pages, permissions } = this.props;

        var allowedPages = pages.filter((page) => (permissions.find((p) => p.page === page.id) ? page : null));

        return (
            <AppContainer authenticated>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Form
                            dontValidateOnMount="true"
                            validateOnSubmit="true"
                            defaultValues={authState.user}
                            validateError={this.errorValidator}
                            onSubmit={this.submitForm}>
                            {(formApi) => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" gutterBottom>
                                                User Profile
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <TextField field="username" label="Username" fullWidth disabled />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <TextField field="email" label="Email" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <TextField field="name" label="Name" fullWidth />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Submit
                                                label="Update User Information"
                                                altAction="/dashboard"
                                                altLabel="Return to Dashboard"
                                                loading={update.pending}
                                            />
                                            <Typography className={classes.registerError}>{update.error}</Typography>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Form
                            dontValidateOnMount="true"
                            validateOnSubmit="true"
                            validateError={this.errorValidatorPassword}
                            onSubmit={this.submitFormPassword}>
                            {(formApi) => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" gutterBottom>
                                                Update Password
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField field="current_password" label="Current Password" type="password" fullWidth />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField field="new_password" label="New Password" type="password" fullWidth />
                                        </Grid>
                                        <Grid item xs={0} md={6}></Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField field="re_new_password" label="Retype New Password" type="password" fullWidth />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Submit
                                                label="Update Password"
                                                altAction="/dashboard"
                                                altLabel="Return to Dashboard"
                                                loading={change.pending}
                                            />
                                            <Typography className={classes.registerError}>{change.error}</Typography>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </Grid>{" "}
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Form dontValidateOnMount="true" defaultValues={this.defaultDashboard()} onSubmit={(values) => this.submitDashboard(values)}>
                            {(formApi) => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" gutterBottom>
                                                Set Module Dashboard
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Select
                                                field="default_dashboard"
                                                label={
                                                    <HelpLabel
                                                        inputLabel="User Dashboard"
                                                        helpText={`Select the dashboard module, this will be the default module when first logging into the database or clicking on the dashboard icon.`}
                                                    />
                                                }
                                                options={MAKE_OPTIONS(allowedPages)}
                                                fullWidth
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid item xs={6} style={{ paddingRight: 10 }}>
                                                <Button type="submit" fullWidth style={{ marginTop: 10 }} variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}
UpdatePage.displayName = "UpdatePage";
UpdatePage = connect(
    (state, ownProps) => ({
        authState: state.auth,
        update: (state.auth && state.auth.update) || {},
        change: (state.auth && state.auth.change) || {},
        pages: getPages(state),
        permissions: getUserPermissions(state, ownProps),
        user: getUser(state, ownProps), //component wide state prop "user" gets derived info from database
    }),
    {
        ...authActions,
        ...User.actions,
    }
)(UpdatePage);

export default withStyles(styles)(withRouter(UpdatePage));
