import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Form } from "react-form";
import classNames from "classnames";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import SearchIcon from "@material-ui/icons/Search";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ExitIcon from "@material-ui/icons/ExitToApp";
import EditIcon from "@material-ui/icons/Edit";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Close from "@material-ui/icons/Close";
import HelpIcon from "@material-ui/icons/Help";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import LayersIcon from "@material-ui/icons/Layers";
import Bookmarks from "@material-ui/icons/Bookmarks";
import DeleteIcon from "@material-ui/icons/Delete";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ReactSelectAsync from "./esrimap/ReactSelectAsync";
import Map from "@material-ui/icons/Map";

import FileInput from "./FileInput";
import logo from "../../ms4logo.png";
import pack from "../../../package.json";
import TextField from "./TextField";
// import { createSelector } from "../common/orm";
import { connect } from "react-redux";
import * as authActions from "../auth/actions";
import { reloadAll, createSelector } from "./orm";
import { Shortcut, HelpDocument, Permission } from "../page/models";

const styles = (theme) => ({
    appLogo: {
        width: 115,
    },
    zIndex: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
        zIndex: 1300,
        backgroundColor: theme.palette.primary.light,
    },
    listItem: {
        borderBottom: "1px solid #262626",
        cursor: "pointer"
    },
    alertText: {
        color: "#262626",
    },
    topListItem: {
        borderBottom: "1px solid #262626",
        backgroundColor: "#eee",
        cursor: "default",
        textAlign: "center",
    },
    list: {
        "& ul": {
            padding: 0,
        },
    },
    list2: {
        overflow: "visible",
    },
    updateReady: {
        color: "#fff",
        backgroundColor: "#4e4e4e",
        position: "absolute",
        width: "100%",
        height: 70,
    },
    headerButton: {
        verticalAlign: "top",
        margin: "8px 4px",
        color: "#3d3d3d",
        "& svg": {
            fontSize: 32,
        },
    },
    flex: {
        display: "flex",
        flexFlow: "row wrap",
        height: 64,
    },
    flexCenter: {
        marginLeft: 24,
        marginTop: 16,
        flex: "1 auto",
    },
    flexRight: {
        textAlign: "right",
        flex: "1 auto",
        marginTop: -4,
    },
    hideSmall: {
        [theme.breakpoints.down("md")]: {
            display: "none !important",
        },
    },
    hideLarge: {
        [theme.breakpoints.up("lg")]: {
            display: "none !important",
        },
    },
    welcome: {
        color: "#3d3d3d",
        fontSize: "large",
        display: "inline-block",
        marginTop: 20,
    },
    userLogo: {
        maxHeight: 64,
    },
    minWidth: {
        minWidth: 400,
    },
    flexButton: {
        flex: 1,
    },
    centerAlign: {
        textAlign: "center",
    },
    noPadding: {
        padding: "0px !important",
    },
});
const allShortcuts = Shortcut.selectAll();
const allHelpDocuments = HelpDocument.selectAll();
const allReleaseNotes = createSelector((session) => {
    return session.ReleaseNote.all().orderBy("release_date", "desc").toRefArray();
});
const userPermissions = Permission.selectAll();
 
class Header extends Component {
    constructor() {
        super();
        this.state = {
            userMenuAnchor: null,
            userMenuAnchor2: null,
            quickAnchor: null,
            smallAnchor: null,
            releaseAnchor: null,
            openDialog: false,
            openHelpDialog: false,
            helpMenu: null,
        };
    }

    handleUserMenu = (event) => {
        this.setState({ userMenuAnchor: event.currentTarget });
    };

    handleUserMenuClose = () => {
        this.setState({ userMenuAnchor: null });
    };

    handleQuickMenu = (event) => {
        this.setState({ quickAnchor: event.currentTarget });
    };

    handleQuickMenuClose = () => {
        this.setState({ quickAnchor: null });
    };

    handleUserMenu2 = (event) => {
        this.setState({ userMenuAnchor2: event.currentTarget });
    };

    handleUserMenuClose2 = () => {
        this.setState({ userMenuAnchor2: null });
    };
    handleHelpMenu = (event) => {
        this.setState({ helpMenu: event.currentTarget });
    };

    handleHelpMenuClose = () => {
        this.setState({ helpMenu: null });
    };

    handleSmallMenu = (event) => {
        this.setState({ smallAnchor: event.currentTarget });
    };

    handleSmallMenuClose = () => {
        this.setState({ smallAnchor: null });
    };

    handleReleaseMenu = (event) => {
        this.setState({ releaseAnchor: event.currentTarget });
    };

    handleReleaseMenuClose = () => {
        this.setState({ releaseAnchor: null });
    };

    logOut = () => {
        this.props.authLogout();

        this.props.history.push("/");
    };

    addShortcut = (values) => {
        const { ormShortcutCreate, authState } = this.props;
        ormShortcutCreate({
            user_id: authState.user.id,
            ...values,
        });
        this.setState({ openDialog: false });
    };

    addHelpMenu = (values) => {
        const { ormHelpDocumentCreate } = this.props;
        if (values.url) {
            ormHelpDocumentCreate({
                name: values.name,
                file: values.add_attachment,
                url: values.url,
            });
        } else {
            ormHelpDocumentCreate({
                name: values.name,
                file: values.add_attachment,
            });
        }

        this.setState({ openHelpDialog: false });
    };

    render() {
        const { classes, authenticated, authState, navState, shortcuts, ormShortcutDelete, helpDocuments, ormHelpDocumentDelete, permissions, releaseNotes } = this.props;
        const { userMenuAnchor, userMenuAnchor2, smallAnchor, releaseAnchor, openDialog, helpMenu, openHelpDialog, quickAnchor } = this.state;
        const userMenuOpen = Boolean(userMenuAnchor);
        const userMenuOpen2 = Boolean(userMenuAnchor2);
        const quickMenuOpen = Boolean(quickAnchor);
        const smallMenuOpen = Boolean(smallAnchor);
        const releaseMenuOpen = Boolean(releaseAnchor);
        const userHelpOpen = Boolean(helpMenu);

        if (!authState) return "...";

        var { user } = authState;
        if (!user) {
            user = {};
            if (authenticated) {
                // Page requires authentication
                if (!navState.storeReady) {
                    // Give store a chance to reload
                    user.city_name = "...";
                } else {
                    user.city_name = "Not Logged In!";
                }
            }
        }
        const perm = permissions.find(p=>p.user === user.id && p.readonlyexternal)
        //const updateReady = navState.sw && navState.sw.update;
        const updateReady = false;

        const UpdateHeader = () => (
            <Toolbar className={classes.updateReady}>
                <Grid container justify="center">
                    <Grid item>
                        <Typography variant="subheading" color="inherit">
                            A newer version of the manager is available. Please save your work and refresh your browser. &nbsp;
                            <Button variant="contained" onClick={() => window.location.reload()}>
                                Refresh Now
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
            </Toolbar>
        );

        return authenticated ? ( // Logged In
            <AppBar position="fixed" style={{backgroundColor: user.header_color}} className={classes.zIndex}>
                {updateReady && <UpdateHeader />}
                {!updateReady && (
                    <Toolbar>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <div className={classes.flex}>
                                    <div>
                                        {(user.display === "Both" || user.display === "Logo Only") && (
                                            <img
                                                onError={(e) => {
                                                    e.currentTarget.style.display = "none";
                                                }}
                                                src={user.logo}
                                                alt={user.city_name}
                                                className={classes.userLogo}
                                            />
                                        )}
                                    </div>
                                    <div className={classes.flexCenter}>
                                        {(user.display === "Both" || user.display === "Name Only") && (
                                            <Typography variant="h5" style={{ color: user.button_color }}>
                                                {user.city_name}
                                            </Typography>
                                        )}
                                    </div>
                                    <div className={classes.flexRight}>
                                        <Typography variant="h6" style={{ color: user.button_color }} className={classNames(classes.welcome, classes.hideSmall)}>
                                            {user.name && "Welcome - " + user.name}
                                        </Typography>
                                        <Typography variant="h6" style={{ color: user.button_color }} className={classNames(classes.welcome, classes.hideLarge)}>
                                            {user.name}
                                        </Typography>
                                        <Tooltip className={classes.hideLarge} title="Navigation Menu" placement="bottom-start" enterDelay={300}>
                                            <IconButton className={classes.headerButton} style={{ color: user.button_color }} onClick={this.handleSmallMenu}>
                                                <MoreVertIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip className={classes.hideSmall} title="Quick Search" placement="bottom-start" enterDelay={300}>
                                            <IconButton className={classes.headerButton} style={{ color: user.button_color }} onClick={this.handleQuickMenu}>
                                                <SearchIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip className={classes.hideSmall} title="All Modules Map" placement="bottom-start" enterDelay={300}>
                                            <IconButton className={classes.headerButton} style={{ color: user.button_color }} component={Link} to={"/map/" + user.id}>
                                                <Map />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip className={classes.hideSmall} title="Shortcuts" placement="bottom-start" enterDelay={300}>
                                            <IconButton className={classes.headerButton} style={{ color: user.button_color }} onClick={this.handleUserMenu2}>
                                                <Bookmarks />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip className={classes.hideSmall} title="Help" placement="bottom-start" enterDelay={300}>
                                            <IconButton className={classes.headerButton} style={{ color: user.button_color }} onClick={this.handleHelpMenu}>
                                                <HelpIcon />
                                            </IconButton>
                                        </Tooltip>
                                        {!perm && ( 
                                            <Tooltip className={classes.hideSmall} title="Account Options" placement="bottom-start" enterDelay={300}>
                                                <IconButton className={classes.headerButton} style={{ color: user.button_color }} onClick={this.handleUserMenu}>
                                                    <AccountCircle />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        <Tooltip title="Dashboard" placement="bottom-start" enterDelay={300}>
                                            <IconButton
                                                component={Link}
                                                to={`/dashboard/${
                                                    authState && authState.user && authState.user.default_dashboard
                                                        ? authState.user.default_dashboard
                                                        : ""
                                                }`}
                                                style={{ padding: 3 }}>
                                                <img src={logo} alt="MS4Front" className={classes.appLogo} />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>

                        <Menu
                            id="menu-appbar"
                            anchorEl={userMenuAnchor}
                            classes={{
                                paper: classes.list,
                            }}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={userMenuOpen}
                            onClose={this.handleUserMenuClose}>
                            <ListItem button className={classes.topListItem}>
                                <ListItemText
                                    primary={<Typography variant="h6">Account Options</Typography>}
                                    classes={{
                                        root: classes.topListText,
                                        primary: classes.topListText,
                                    }}
                                />
                            </ListItem>
                            <ListItem
                                button
                                component={Link}
                                to={`/updateuser/${authState && authState.user && authState.user.id}`}
                                className={classes.listItem}>
                                <ListItemIcon>
                                    <EditIcon />
                                </ListItemIcon>
                                <ListItemText primary="Edit Profile" />
                            </ListItem>
                            <ListItem button onClick={this.logOut} className={classes.listItem}>
                                <ListItemIcon>
                                    <ExitIcon />
                                </ListItemIcon>
                                <ListItemText primary="Log Out" />
                            </ListItem>
                            <ListItem button className={classes.topListItem}>
                                <ListItemText inset primary={"Version " + pack.version} />
                            </ListItem>
                        </Menu>

                        <Menu
                            anchorEl={quickAnchor}
                            classes={{
                                paper: classes.list2,
                                list: classes.noPadding,
                            }}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={quickMenuOpen}
                            onClose={this.handleQuickMenuClose}>
                            <Form validateOnSubmit={false}>
                                {(formApi) => (
                                    <form onSubmit={formApi.submitForm} style={{ width: 300, padding: "0" }}>
                                        <ReactSelectAsync
                                            authState={authState}
                                            placeholder="Search Geometry Records Across Modules"
                                            eventHandle={(searchvalue) => {
                                                if (searchvalue !== "") {
                                                    const { history } = this.props;
                                                    history.push(searchvalue.link);
                                                }
                                            }}
                                        />
                                    </form>
                                )}
                            </Form>
                        </Menu>

                        <Menu
                            id="menu-appbar2"
                            anchorEl={userMenuAnchor2}
                            classes={{
                                paper: classes.list,
                            }}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={userMenuOpen2}
                            onClose={this.handleUserMenuClose2}>
                            <ListItem button className={classes.topListItem}>
                                <ListItemText
                                    primary={<Typography variant="h6">Shortcuts</Typography>}
                                    classes={{
                                        root: classes.topListText,
                                        primary: classes.topListText,
                                    }}
                                />
                            </ListItem>
                            {shortcuts.map((shortcut, index) => {
                                return (
                                    <table key={index} style={{ width: "100%", borderCollapse: "collapse" }}>
                                        <tr>
                                            <td>
                                                <ListItem button onClick={() => window.open(shortcut.url)} className={classes.listItem}>
                                                    <ListItemText primary={shortcut.name} />
                                                </ListItem>
                                            </td>
                                            <td style={{ width: 50 }}>
                                                <IconButton onClick={() => ormShortcutDelete(shortcut.id)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </td>
                                        </tr>
                                    </table>
                                );
                            })}
                            <ListItem button onClick={() => this.setState({ openDialog: true })} className={classes.listItem}>
                                <ListItemText primary="Add New Shortcut" />
                                <ListItemIcon style={{ marginRight: -32 }}>
                                    <AddCircleOutlineIcon />
                                </ListItemIcon>
                            </ListItem>
                        </Menu>
                        <Menu
                            id="help-appbar3"
                            anchorEl={helpMenu}
                            classes={{
                                paper: classes.list,
                            }}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={userHelpOpen}
                            onClose={this.handleHelpMenuClose}>
                            <ListItem button className={classes.topListItem}>
                                <ListItemText
                                    primary={<Typography variant="h6">Help Directory</Typography>}
                                    classes={{
                                        root: classes.topListText,
                                        primary: classes.topListText,
                                    }}
                                />
                            </ListItem>
                            <ListItem button 
                                onMouseOver={this.handleReleaseMenu}
                                onClick={this.handleReleaseMenu} 
                                className={classes.listItem}>
                                {releaseNotes.length > 0 && releaseNotes[0].is_new && (
                                    <ListItemIcon style={{ marginLeft: -4, marginRight: -24}}>
                                        <PriorityHighIcon style={{color:"darkred"}} />
                                    </ListItemIcon>
                                )}
                                <ListItemText primary="Release Notes" style={{ marginLeft: 4 }} />
                                <ListItemIcon style={{ marginRight: -32 }}>
                                    <ArrowRightIcon />
                                </ListItemIcon>
                            </ListItem>
                            {helpDocuments.map((help, index) => {
                                return (
                                    <table key={index} style={{ width: "100%" }}>
                                        <tr>
                                            {!help.file && (
                                                <td>
                                                    <ListItem
                                                        button
                                                        onClick={() => {
                                                            // eslint-disable-next-line
                                                            return window.open(help.url), this.handleHelpMenuClose();
                                                        }}
                                                        className={classes.listItem}>
                                                        <ListItemText primary={help.name} />
                                                    </ListItem>
                                                </td>
                                            )}
                                            {help.file && (
                                                <td>
                                                    <ListItem
                                                        button
                                                        className={classes.listItem}
                                                        onClick={() => {
                                                            // eslint-disable-next-line
                                                            return window.open(help.file), this.handleHelpMenuClose();
                                                        }}>
                                                        <ListItemText primary={help.name} />
                                                    </ListItem>
                                                </td>
                                            )}

                                            {user.is_superuser && (
                                                <td style={{ width: 50 }}>
                                                    <IconButton onClick={() => ormHelpDocumentDelete(help.id)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </td>
                                            )}
                                        </tr>
                                    </table>
                                );
                            })}
                            {user.is_superuser && (
                                <ListItem button onClick={() => this.setState({ openHelpDialog: true })} className={classes.listItem}>
                                    <ListItemText primary="Add Help Item" />
                                    <ListItemIcon style={{ marginRight: -32 }}>
                                        <AddCircleOutlineIcon />
                                    </ListItemIcon>
                                </ListItem>
                            )}
                        </Menu>

                        <Menu
                            id="menu-release"
                            anchorEl={releaseAnchor}
                            classes={{
                                paper: classes.list,
                            }}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={releaseMenuOpen}
                            onClose={this.handleReleaseMenuClose}
                        >
                            {releaseNotes.map((help) => {
                                return (
                                    <ListItem
                                        button
                                        className={classes.listItem}
                                        onClick={() => {
                                            // eslint-disable-next-line
                                            return window.open(help.file), this.handleReleaseMenuClose(), this.handleHelpMenuClose();
                                        }}>
                                        {help.is_new && (
                                            <ListItemIcon style={{ marginLeft: -4, marginRight: -24}}>
                                                <PriorityHighIcon style={{color:"darkred"}} />
                                            </ListItemIcon>
                                        )}
                                        <ListItemText primary={help.name + " (" + help.release_date_formatted + ")"} />
                                    </ListItem>
                                )
                            })}
                        </Menu>

                        <Menu
                            id="menu-small"
                            anchorEl={smallAnchor}
                            classes={{
                                paper: classes.list,
                            }}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={smallMenuOpen}
                            onClose={this.handleSmallMenuClose}>
                            <ListItem button className={classes.topListItem}>
                                <ListItemText
                                    primary={<Typography variant="h6">Navigation Menu</Typography>}
                                    classes={{
                                        root: classes.topListText,
                                        primary: classes.topListText,
                                    }}
                                />
                            </ListItem>
                            <ListItem button onClick={this.handleQuickMenu} className={classes.listItem}>
                                <ListItemIcon>
                                    <SearchIcon />
                                </ListItemIcon>
                                <ListItemText primary="Quick Search" />
                            </ListItem>
                            <ListItem button component={Link} to={"/map/" + user.id} className={classes.listItem}>
                                <ListItemIcon>
                                    <LayersIcon />
                                </ListItemIcon>
                                <ListItemText primary="Map" />
                            </ListItem>
                            <ListItem button onClick={this.handleUserMenu2} className={classes.listItem}>
                                <ListItemIcon>
                                    <Bookmarks />
                                </ListItemIcon>
                                <ListItemText primary="Shortcuts" />
                            </ListItem>
                            <ListItem button onClick={this.handleHelpMenu} className={classes.listItem}>
                                <ListItemIcon>
                                    <HelpIcon />
                                </ListItemIcon>
                                <ListItemText primary="Help" />
                            </ListItem>
                            {!perm && ( 
                                <ListItem button onClick={this.handleUserMenu} className={classes.listItem}>
                                    <ListItemIcon>
                                        <AccountCircle />
                                    </ListItemIcon>
                                    <ListItemText primary="Account Options" />
                                </ListItem>
                            )}
                        </Menu>
                    </Toolbar>
                )}
                <Dialog open={openDialog} classes={{ paper: classes.minWidth }}>
                    <Toolbar>
                        <Typography variant="h5" className={classes.flexButton}>
                            Add Shortcut
                        </Typography>
                        <IconButton onClick={() => this.setState({ openDialog: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent>
                        <DialogContentText>
                            <Form dontValidateOnMount={true} validateOnSubmit={true} onSubmit={(values) => this.addShortcut(values)}>
                                {(formApi) => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField field="name" label="Name" fullWidth />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField field="url" label="URL" fullWidth />
                                            </Grid>
                                            <Grid item xs={12} className={classes.centerAlign}>
                                                <Button type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog open={openHelpDialog} classes={{ paper: classes.minWidth }}>
                    <Toolbar style={{ backgroundColor: "#eee" }}>
                        <Typography variant="h5" className={classes.flexButton}>
                            Add Help Menu Item
                        </Typography>
                        <IconButton onClick={() => this.setState({ openHelpDialog: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent>
                        <DialogContentText>
                            <Form dontValidateOnMount={true} validateOnSubmit={true} onSubmit={(values) => this.addHelpMenu(values)}>
                                {(formApi) => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField field="name" label="Name" fullWidth />
                                                <TextField field="url" label="URL" fullWidth />
                                                <Typography variant="h6" style={{ fontSize: 15 }}>
                                                    NOTE: only add a 'URL' or 'Select File' (not both) to be properly populated into the 'Help
                                                    Directory'
                                                </Typography>
                                                <FileInput /*readOnly={readOnly}*/ field="add_attachment" id="add_attachment" fullWidth />
                                            </Grid>
                                            <Grid item xs={12} className={classes.flexRight}>
                                                <Button type="submit" variant="contained" color="primary" style={{ marginTop: -80 }}>
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </AppBar>
        ) : (
            // Public
            <AppBar position="fixed" className={classes.zIndex}>
                {updateReady && <UpdateHeader />}
                {!updateReady && (
                    <Toolbar>
                        <img src={logo} alt="MS4Front" className={classes.appLogo} />
                    </Toolbar>
                )}
            </AppBar>
        );
    }
}
Header.displayName = "Header";
Header = connect(
    (state, ownProps) => ({
        authState: state.auth,
        navState: state.nav,
        shortcuts: allShortcuts(state, ownProps),
        helpDocuments: allHelpDocuments(state, ownProps),
        releaseNotes: allReleaseNotes(state, ownProps),
        permissions: userPermissions(state, ownProps),
    }),
    {
        ...authActions,
        ...Shortcut.actions,
        reload: reloadAll,
        ...HelpDocument.actions,
    }
)(Header);

export default withStyles(styles)(withRouter(Header));
