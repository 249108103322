import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Form as Formm } from "react-form";
import { withRouter } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import BookmarkIcon from "@material-ui/icons/PlusOne";
import NotesIcon from "@material-ui/icons/SpeakerNotes";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import CloudUpload from "@material-ui/icons/CloudUpload";
import CircularProgress from "@material-ui/core/CircularProgress";

import HelpLabel from "../common/HelpLabel";

import * as authActions from "../auth/actions";
import FileInput from "../common/FileInput";
import drag from "../common/icons/dnd.png";
import AppContainer from "../common/AppContainer";
import Select from "../common/Select";
import TextField from "../common/TextField";
import WarningDialog from "../common/WarningDialog";
import { ICON_LIST, MAKE_OPTIONS_ICON } from "../common/icons";
import { createSelector } from "../common/orm";
import { Page, Section, Permission, Field, Group, Form } from "../page/models";
import { User } from "../adm/models";

const allPages = createSelector((schema) => {
    return schema.Page.all()
        .orderBy("order")
        .toModelArray()
        .map((page) => ({
            _sections: page.sections
                .all()
                .orderBy("id")
                .toModelArray()
                .map((section) => ({
                    _forms: section.forms
                        .all()
                        .toModelArray()
                        .map((form) => ({
                            _group: form.groups
                                .all()
                                .toModelArray()
                                .map((group) => ({
                                    _fields: group.fields.all().toRefArray(),
                                    ...group.ref,
                                })),
                            ...form.ref,
                        })),
                    ...section.ref,
                })), //take all sections from model.js and link to each page
            ...page.ref,
        }));
});

const getAllUsers = User.selectAll();

// CSS in JS styles
const styles = (theme) => ({
    italics: {
        fontStyle: "italic",
    },
    table: {
        minWidth: 500,
    },
    dialogMin: {
        minWidth: 400,
    },
    flex: {
        flex: 1,
    },
    fieldButton: {
        display: "block",
        // backgroundColor: "#262626",
        // color: "primary",
        // borderRadius: 10,
        minWidth: 200,
        margin: 8,
    },
    button: {
        float: "right",
    },
    minWidth: {
        minWidth: 600,
    },
    minWidth2: {
        width: 420,
        minHeight: 420,
    },

    gutterBottom: {
        marginBottom: ".6em",
    },
    root: {
        width: "100%",
        overflowX: "auto",
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        margin: "0 8px 0 0",
    },
    centerAlign: {
        textAlign: "center",
    },
    nowrap: {
        whiteSpace: "nowrap",
    },
    floatLeft: {
        float: "left",
        marginLeft: 9,
    },
    floatRight: {
        float: "right",
    },
});

//fields styling section for groups
const getItemStyle2 = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 4,
    margin: `6px 14px 6px 14px`,
    color: "primary",
    fontSize: "18px",
    borderRadius: 5,
    textAlign: "left",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 2px, rgba(0, 0, 0, 0.25) 0px 2px 2px",

    // change background of fields when dragging
    background: isDragging ? "lightgrey" : "lightgrey",

    // styles we need to apply on draggables
    ...draggableStyle,
});

// background color on "fields child card" section when dragging
const getListStyle2 = (isDraggingOver) => ({
    background: isDraggingOver ? "#4e4e4e" : "",
    borderRadius: 5,
    marginBottom: isDraggingOver ? 80 : 30,
});

// The table component
// http://localhost:3000/#/config/page
class PageTable extends Component {
    constructor(props, context) {
        super(props, context);
        // Default states
        this.state = {
            deleteDialogOpen: false,
            deleteDialogText: null,
            deleteDialogConfirmAction: null,
            // loading: false
        };
    }

    // When the delete button is pressed
    deletePage = (page, name) => {
        // Set the state which controls a popup
        // If deleteDialogOpen = true then the popup will appear during the render event
        this.setState({
            deleteDialogOpen: true,
            deleteDialogConfirmAction: page,
            deleteDialogText:
                "WARNING: This will remove all records associated with this module. Are you sure you wish to permanently delete " + name + "?",
        });
    };

    // When the copy button is pressed
    copyPage = (page) => {
        const {
            ormPageCreate,
            ormSectionCreate,
            ormFormCreate,
            ormGroupCreate,
            ormFieldCreate,
            ormPageReload,

            history,
        } = this.props;

        var copy = { ...page };
        delete copy.id;

        // copy._sections.sort(function(a, b) {
        //     return a.id - b.id;
        // });
        // Create a new page object with the same payload (minus the id)
        ormPageCreate(copy).then((page1) => {
            const pageId = page1.id;
            // Also create new sections for the new page object
            copy._sections.forEach((sect, index) => {
                // Create a deep copy of the section object
                // This is so that the original section object is not modified
                // https://stackoverflow.com/questions/41847581/what-is-the-difference-between-object-assign-and-json-parsejson-stringifyobj
                sect = JSON.parse(JSON.stringify(sect));
                delete sect.id;
                delete sect.page;
                delete sect.page_id;
                // Remove the id and references to the other page and create the section
                ormSectionCreate({
                    page: pageId,
                    ...sect,
                }).then((s) => {
                    const sectid = s.id;
                    sect._forms.forEach((form) => {
                        form = JSON.parse(JSON.stringify(form));
                        delete form.id;
                        delete form.section;
                        delete form.section_id;
                        ormFormCreate({
                            section: sectid,
                            ...form,
                        }).then((f) => {
                            const formid = f.id;
                            form._group.forEach((group) => {
                                group = JSON.parse(JSON.stringify(group));
                                delete group.id;
                                delete group.form;
                                delete group.form_id;
                                ormGroupCreate({
                                    form: formid,
                                    ...group,
                                }).then((g) => {
                                    const groupid = g.id;
                                    group._fields.forEach((field) => {
                                        field = JSON.parse(JSON.stringify(field));
                                        delete field.id;
                                        delete field.group;
                                        delete field.group_id;
                                        ormFieldCreate({
                                            group: groupid,
                                            ...field,
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
                if (index + 1 === copy._sections.length) {
                    ormPageReload();
                    // this.setState({ loading: false });
                    if (pageId) history.push("/page/" + pageId + "/config");
                }
            });
        });
    };

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const { ormPageUpdate, pages } = this.props;
        console.log(result);

        // //fields
        // // Order pages
        if (result.destination.droppableId === "page_list") {
            // Set the order of Pages moved between starting and ending position
            pages.forEach(function (p) {
                // If the destination is greater than the original position then shift all items between them down 1
                // result.source.index = 0
                // result.destination.index = 2
                if (result.destination.index > result.source.index) {
                    if (p.order > result.source.index && p.order <= result.destination.index) {
                        ormPageUpdate({
                            id: p.id,
                            order: p.order - 1,
                        });
                    }
                }
                // If the destination is less than the original position then shift all items between them up 1
                // result.source.index = 2
                // result.destination.index = 0
                else if (result.destination.index < result.source.index) {
                    if (p.order >= result.destination.index && p.order < result.source.index) {
                        ormPageUpdate({
                            id: p.id,
                            order: p.order + 1,
                        });
                    }
                }
            });
            // Set the order of group moved
            ormPageUpdate({
                id: result.draggableId,
                order: result.destination.index,
            });
        }
    };

    // Render the table
    render() {
        const { classes, pages, ormPageDelete, ormPageCreate, newDialogOpen, returnDialog, history, allUsers, ormPermissionCreate, authState } = this.props;

        const { deleteDialogOpen, deleteDialogText, deleteDialogConfirmAction } = this.state;

        return (
            <>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="page_list" type="page_list" key="page_list">
                        {(provided, snapshot) => (
                            <Accordion
                                style={{
                                    backgroundColor: snapshot.isDraggingOver ? "#4e4e4e" : "",
                                }}
                                defaultExpanded
                                expanded={true}>
                                <AccordionSummary
                                    className={classes.titlePanel}
                                    classes={{ root: classes.expandedMargin }}
                                    style={{
                                        backgroundColor: "#333",
                                        borderRadius: 3,
                                        boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 0px, rgba(0, 0, 0, 0.2) 0px 1px 1px",
                                    }}>
                                    <table style={{ width: "100%" }}>
                                        <tbody>
                                        <tr>
                                            <td style={{ width: "25%" }}></td>
                                            <td style={{ width: "60%" }}>
                                                <Typography variant="h5" style={{ fontSize: 18, color: "#fff" }}>
                                                    Module
                                                </Typography>
                                            </td>
                                            <td style={{ width: "15%" }}>
                                                <Typography variant="h5" style={{ fontSize: 18, color: "#fff" }}>
                                                    Icon
                                                </Typography>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </AccordionSummary>
                                <div ref={provided.innerRef} style={getListStyle2(snapshot.isDraggingOver)}>
                                    {pages.map((n, index) => {
                                        const icon_array = ICON_LIST.find(function (itm) {
                                            return itm.id === n.icon;
                                        });
                                        var icon_component;
                                        if (icon_array) icon_component = icon_array["component"];

                                        return (
                                            <Draggable key={n.id} draggableId={n.id} index={n.order} /*isDragDisabled={disableDrag}*/>
                                                {(provided, snapshot) => (
                                                    <div
                                                        key={n.id}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle2(snapshot.isDragging, provided.draggableProps.style)}
                                                        className={classes.fieldButton}>
                                                        <table style={{ width: "100%", marginTop: -3, marginBottom: -3, fontSize: "initial" }}>
                                                            <tbody>
                                                            <tr hover='true' key={n.id} style={{ width: "100%" }}>
                                                                <td className={classes.nowrap} style={{ width: "20%" }}>
                                                                    <Tooltip title="Drag and Drop Modules to order them on the navigation side panel">
                                                                        <img
                                                                            style={{
                                                                                width: "1.5em",
                                                                                height: "1.6em",
                                                                                verticalAlign: "middle",
                                                                                marginRight: 11,
                                                                                marginLeft: 6,
                                                                            }}
                                                                            src={drag}
                                                                            alt="fireSpot"
                                                                        />
                                                                    </Tooltip>
                                                                    <Tooltip title="Edit">
                                                                        <Button
                                                                            component={Link}
                                                                            to={"/page/" + n.id + "/config"}
                                                                            className={classes.deleteWidth}>
                                                                            <EditIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                    <Tooltip title="Copy">
                                                                        <Button
                                                                            onClick={() => {
                                                                                this.copyPage(n);
                                                                            }}
                                                                            className={classes.deleteWidth}>
                                                                            <BookmarkIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                    {authState && authState.auth && authState.auth.user.is_city_admin && (
                                                                        <Tooltip title="Manage Notes">
                                                                            <Button
                                                                                component={Link}
                                                                                to={"/module/notes/" + n.id}
                                                                                className={classes.deleteWidth}>
                                                                                <NotesIcon color="primary" />
                                                                            </Button>
                                                                        </Tooltip>
                                                                    )}
                                                                    <Tooltip title="Delete">
                                                                        <Button
                                                                            onClick={() => this.deletePage(n.id, n.name)}
                                                                            className={classes.deleteWidth}>
                                                                            <DeleteIcon />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </td>
                                                                <td style={{ width: "60%" }}>
                                                                    <Typography>{n.name}</Typography>
                                                                </td>
                                                                <td style={{ width: "20%" }}>
                                                                    <div style={{ marginBottom: -4 }}>
                                                                        <ListItemIcon style={{color:n.icon_color}} className={classes.svg}>{icon_component}</ListItemIcon>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                </div>
                                {pages.length < 1 && (
                                    <table>
                                        <tr colSpan={3} className={classes.centerAlign}>
                                            <td style={{ whiteSpace: "nowrap", paddingLeft: "200%" }}> No Module Defined</td>
                                        </tr>
                                    </table>
                                )}
                            </Accordion>
                        )}
                    </Droppable>
                </DragDropContext>

                <AddPage
                    classes={classes}
                    open={newDialogOpen}
                    returnDialog={returnDialog}
                    ormPageCreate={ormPageCreate}
                    history={history}
                    allUsers={allUsers}
                    ormPermissionCreate={ormPermissionCreate}
                    pages={pages}
                />
                <WarningDialog
                    confirmAction={() => {
                        ormPageDelete(deleteDialogConfirmAction);
                        this.setState({ deleteDialogOpen: false });
                    }}
                    cancelAction={() => this.setState({ deleteDialogOpen: false })}
                    open={deleteDialogOpen}
                    title="Delete Module"
                    text={deleteDialogText}
                />
                {/* {loading && <CircularProgress size={24} style={{ display: "table", marginLeft: "auto", marginRight: "auto" }} />} */}
            </>
        );
    }
}

// The add page dialog component
// http://localhost:3000/#/config/page
class AddPage extends Component {
    // When the "Add and Proceed to Edit" button is pressed
    createPage(values) {
        const { ormPageCreate, returnDialog, history, allUsers, ormPermissionCreate, pages } = this.props;

        var pageId;
        // Create the page
        ormPageCreate({
            name: values.name,
            icon: values.icon,
            icon_color: values.icon_color,
            order: pages.length,
        })
            .then((page) => {
                pageId = page.id;

                //set all users to have default access to this page
                allUsers.map((u) => {
                    return ormPermissionCreate({
                        page: pageId,
                        user: u.id,
                        noaccess: false,
                        fullaccess: true,
                        readonly: false,
                        readonlyexternal: false,
                    });
                });
            })
            .then(() => {
                // Then close the dialog and navigate to the new page that was just created
                returnDialog();
                history.push("/page/" + pageId + "/config");
            });
    }
    //
    // Form validator
    // Make sure a name of some sort is entered
    errorValidator = (values) => {
        const isRequired = (val) => {
            return !val ? "Required" : null;
        };
        var valObj = {
            name: isRequired(values.name),
        };
        return valObj;
    };

    // Render the popup
    render() {
        const { classes, returnDialog, open } = this.props;

        return (
            <Dialog open={open} classes={{ paper: classes.minWidth }}>
                <Toolbar>
                    <Typography variant="h5" className={classes.flex}>
                        Add New Module
                    </Typography>
                    <IconButton onClick={returnDialog}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent className={classes.dialogMin}>
                    <DialogContentText>
                        <Formm
                            getApi={(el) => (this.formApi = el)}
                            dontValidateOnMount="true"
                            validateOnSubmit="true"
                            validateError={this.errorValidator}
                            onSubmit={(values) => this.createPage(values)}>
                            {(formApi) => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField field="name" label="Name" fullWidth />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Select field="icon" label="Icon" options={MAKE_OPTIONS_ICON(ICON_LIST)} fullWidth />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button type="submit" variant="contained" color="primary" className={classes.button}>
                                                Add and Proceed to Edit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formm>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

// The root component of the page
// http://localhost:3000/#/config/page
class PageConfig extends Component {
    constructor(props, context) {
        super(props, context);
        // The new page dialog is closed by default
        this.state = {
            newDialogOpen: false,
            open: false,
        };
    }

    // A function that closes the new page dialog
    // This function is a good example of how to pass objects/funtions from their parents down
    // It is passed down several levels from here -> PageTable -> AddPage
    returnDialog = () => {
        this.setState({ newDialogOpen: false });
    };

    //add click for adding a new page & invert state
    addPageHandleClick = () => {
        this.setState({ newDialogOpen: !this.state.newDialogOpen });
        console.log(this.state.newDialogOpen);
    };

    render() {
        const {
            classes,
            pages,
            ormPageDelete,
            ormPageCreate,
            history,
            ormSectionCreate,
            ormFieldCreate,
            ormGroupCreate,
            ormFormCreate,
            allUsers,
            ormPermissionCreate,
            ormPageUpdate,
            authState,
            ormFieldReload,
            ormGroupReload,
            ormFormReload,
            ormSectionReload,
            ormPageReload,
            form,
            authLogout,
        } = this.props;
        const { newDialogOpen, open } = this.state;
        return (
            <AppContainer authenticated>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                        <Typography variant="h5">Module Configuration</Typography>
                        <Typography className={classes.italics} gutterBottom>
                            Use this Module to create, edit, or delete modules in your account. Note: modules are sortable from 'Dragging and
                            Dropping' the module within the sortable column. This ordering will be mirrored on the left hand 'Navagation' menu-bar
                        </Typography>
                    </Grid>
                    <Grid style={{ textAlign: "end", marginTop: 10 }} item xs={12} sm={4}>
                        <Tooltip
                            title={
                                <div>
                                    Import module, sections and form configurations.
                                    <br />
                                    This does not import module records.
                                </div>
                            }>
                            <Button onClick={() => this.setState({ open: true })} className={classes.gutterBottom} variant="raised" color="primary">
                                <CloudUpload />
                                &nbsp;&nbsp;Import Module from Spreadsheet
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            onClick={() => this.setState({ newDialogOpen: true })}
                            // onClick={this.addPageHandleClick}
                            fullWidth
                            className={classes.gutterBottom}
                            variant="contained"
                            color="primary">
                            <AddCircleOutlineIcon />
                            &nbsp;&nbsp;&nbsp;Add New Module
                        </Button>
                        <PageTable
                            history={history}
                            pages={pages}
                            returnDialog={this.returnDialog}
                            ormSectionCreate={ormSectionCreate}
                            ormFieldCreate={ormFieldCreate}
                            ormGroupCreate={ormGroupCreate}
                            ormFormCreate={ormFormCreate}
                            newDialogOpen={newDialogOpen}
                            authState={authState}
                            classes={classes}
                            ormPageCreate={ormPageCreate}
                            ormPageDelete={ormPageDelete}
                            ormPageUpdate={ormPageUpdate}
                            ormPermissionCreate={ormPermissionCreate}
                            ormPageReload={ormPageReload}
                            allUsers={allUsers}
                        />
                    </Grid>
                    <FormUpload
                        ormPageReload={ormPageReload}
                        ormSectionReload={ormSectionReload}
                        ormFormReload={ormFormReload}
                        ormGroupReload={ormGroupReload}
                        ormFieldReload={ormFieldReload}
                        authState={authState}
                        form={form}
                        authLogout={authLogout}
                        classes={classes}
                        open={open}
                        returnDialog={() => {
                            this.setState({ open: false });
                        }}
                    />
                </Grid>
            </AppContainer>
        );
    }
}
class FormUpload extends Component {
    state = {
        loading: false,
        results: null,

        warningOpen: false,
    };
    formKey = window.performance.now();

    uploadDocument = (values, formApi) => {
        var { authState, history, ormPageReload, ormSectionReload, ormFormReload, ormGroupReload, ormFieldReload, authLogout } = this.props;
        this.setState({ loading: true, results: null });
        fetch("/excel/importpage", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + authState.auth.user.auth_token,
            },
            body: JSON.stringify(values),
        }).then((e) => {
            if (e.status === 401) {
                history.push("/");
                authLogout();
            } else if (e.status === 500) {
                let msg = "<b>Upload Failed</b><br/>";
                this.setState({ loading: false, results: msg });
            } else {
                var msg;
                e.json()
                    .then((data) => {
                        msg = "<b>Upload Results:</b><br/>";
                        data.message.forEach(function (m) {
                            msg += m.name + ": " + m.message + "<br/>";
                        });
                        formApi.resetAll();
                        this.formKey = window.performance.now();
                        ormPageReload();
                        ormSectionReload();
                        ormFormReload();
                        ormGroupReload();
                        ormFieldReload();
                    })
                    .then(() => {
                        this.setState({ loading: false, results: msg });
                    });
            }
        });
    };

    errorValidator = (values) => {
        const isRequired = (val) => {
            return !val ? "Required" : null;
        };
        var valObj = {
            file: isRequired(values.file),
        };

        return valObj;
    };

    closeDialog = () => {
        const { returnDialog } = this.props;

        this.setState({ results: null, loading: false });
        returnDialog();
    };

    render() {
        const { classes, open } = this.props;
        const { loading, results } = this.state;

        return (
            <Dialog open={open} classes={{ paper: classes.minWidth2 }}>
                <Toolbar style={{ backgroundColor: "#ddd" }}>
                    <Typography variant="h5" className={classes.flex}>
                        Import Module Configuration
                    </Typography>
                    <IconButton
                        aria-label="Close Dialog"
                        onClick={(e) => {
                            return this.closeDialog();
                        }}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <DialogContentText>
                        <Formm
                            key={this.formKey}
                            dontValidateOnMount={true}
                            getApi={(el) => (this.formApi = el)}
                            validateOnSubmit={true}
                            validateError={this.errorValidator}
                            onSubmit={(values, e, formApi) => this.uploadDocument(values, formApi)}>
                            {(formApi) => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FileInput
                                                field="file"
                                                id="file_upl"
                                                fullWidth
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Configuration File"
                                                        helpText="Upload an Excel spreadsheet (.xlsx) containing your module, section and form fields. The tabs and column names should match the spreadsheet generated from the 'Export Full Module to Spreadsheet' button."
                                                    />
                                                }
                                            />
                                        </Grid>

                                        <Grid xs={12}>
                                            {loading && (
                                                <CircularProgress size={24} style={{ display: "table", marginLeft: "auto", marginRight: "auto" }} />
                                            )}

                                            <Typography
                                                style={{ marginLeft: 7, font: "15px Arial, sans-serif", marginTop: 4 }}
                                                className={classes.flex}>
                                                Results:
                                            </Typography>
                                            <div
                                                style={{
                                                    backgroundColor: "rgb(250 249 249)",
                                                    height: 200,
                                                    border: ".1px solid black",
                                                    borderRadius: 2,
                                                    marginLeft: 7,
                                                    marginTop: 5,
                                                    marginBottom: 14,
                                                    overflowY: "scroll",
                                                }}>
                                                <Typography style={{ textAlign: "center" }}>
                                                    <div dangerouslySetInnerHTML={{ __html: results }} />
                                                </Typography>
                                            </div>

                                            <div style={{ marginTop: 10 }}>
                                                <Button
                                                    onClick={(e) => this.formApi.submitForm()}
                                                    variant="raised"
                                                    color="primary"
                                                    className={classes.floatLeft}>
                                                    Upload
                                                </Button>
                                                <Button
                                                    onClick={(e) => {
                                                        return this.closeDialog();
                                                    }}
                                                    type="button"
                                                    variant="raised"
                                                    color="primary"
                                                    className={classes.floatRight}>
                                                    Close
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formm>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}
PageConfig.displayName = "PageConfig";

// This connects the redux-orm "allPages" function and allows the PageConfig to have the props "pages"
// The second part loads all orm actions into the PageConfig props for both Page and Section: ormPageCreate, ormSectionCreate, orPageUpdate, etc.
PageConfig = connect(
    (state) => ({
        pages: allPages(state),
        allUsers: getAllUsers(state),
        authState: state,
    }),
    {
        ...Page.actions,
        ...Section.actions,
        ...Group.actions,
        ...Field.actions,
        ...Form.actions,
        ...User.actions,
        ...Permission.actions,
        ...authActions,
    }
)(PageConfig);

export default withStyles(styles)(withRouter(PageConfig));
