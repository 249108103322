import React, { Component } from "react";
import classNames from "classnames";
import DateFnsUtils from "@date-io/date-fns";
import { withStyles } from "@material-ui/core";
import { KeyboardDatePicker as MuiDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { FormField } from "react-form";
import FormHelperText from "@material-ui/core/FormHelperText";

const styles = (theme) => ({
    bootstrapInput: {
        borderRadius: 4,
        border: "1px solid #979797",
        padding: "6px 8px",
        width: "calc(100% - 24px)",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderColor: theme.palette.secondary.light,
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
        backgroundColor: "#fff",
        fontSize: "15px",
        fontWeight: 500,
        color: "#262626 !important",
    },
    bootstrapInputError: {
        "& input": {
            border: "1px solid #f44336",
        },
    },
    rootLabelShrink: {
        marginLeft: 0,
        marginTop: -2,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
    },
    rootLabelShrinkExpansionPanel: {
        //for help with label to offset header
        marginLeft: 0,
        marginTop: -7,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
    },
    disabled: {
        backgroundColor: "#EEEEEE",
    },
    button: {
        "& button": {
            border: 0,
        },
        "& button:hover": {
            backgroundColor: "rgba(60, 138, 199, 0.08)",
        },
    },
});

class DatePickerWrapper extends Component {
    // The first commit of Material-UI
    //const [selectedDate, setSelectedDate] = React.useState(new Date());

    handleDateChange = (date) => {
        const { fieldApi, eventHandle } = this.props;
        const { setValue, setError, setTouched } = fieldApi;

        if (date) {
            if (isNaN(date)) {
                setError("Invalid Date");
            } else if (date.getFullYear().toString().length < 4) {
                setError("Invalid Year");
            } else {
                setError(null);

                var formatDate =
                    (date.getMonth() + 1 < 10 ? "0" : "") +
                    (date.getMonth() + 1) +
                    "/" +
                    ((date.getDate() < 10 ? "0" : "") + date.getDate()) +
                    "/" +
                    date.getFullYear();

                setValue(formatDate);
            }
            setTouched(); // This should be set onblur but onblur isnt called when using the button
            if (eventHandle) eventHandle(date.toLocaleDateString());
        } else if (!date) {
            //occurs when date is deleted from form
            setTouched();
            setValue(" ");
            if (eventHandle) eventHandle("Required");
        }
    };
    componentDidMount() {
        const { autoPopulateAnotherDate, eventHandle } = this.props;
        if (autoPopulateAnotherDate) eventHandle("find other date");
    }

    render() {
        const { helperText, fieldApi, classes, disabled, eventHandle, required, expansionPanel, ...rest } = this.props;
        const { getValue, getError, getWarning, getSuccess, setTouched } = fieldApi;
        var value = getValue(),
            error = getError(),
            warning = getWarning(),
            success = getSuccess(),
            hasError = !!error,
            errorText = error || warning || success || helperText;

        let InputProps = {};
        InputProps["disableUnderline"] = true;
        if (rest.advancedSearch) InputProps["disabled"] = true; //for icon entry only on advanced search as couldnt get manual entry to work, and caused chunk.js to rerender in network
        InputProps["classes"] = {
            input: classNames(classes.bootstrapInput, disabled && classes.disabled),
            error: classes.bootstrapInputError,
        };

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <MuiDatePicker
                    value={value === "None" || value === null || value === "" ? null : value}
                    // initialFocusedDate={value ? value : null}
                    disabled={disabled}
                    required={rest.required}
                    onChange={this.handleDateChange}
                    onBlur={() => setTouched()}
                    format="MM/dd/yyyy"
                    autoOk
                    InputProps={InputProps}
                    InputLabelProps={{
                        shrink: true,
                        classes: {
                            shrink: !expansionPanel ? classes.rootLabelShrink : classes.rootLabelShrinkExpansionPanel,
                        },
                    }}
                    emptyLabel=""
                    okLabel={null}
                    cancelLabel={null}
                    error={hasError}
                    helperText={errorText}
                    DialogProps={{ className: classes.button }}
                    {...rest}
                />
                {required && !hasError && <FormHelperText style={{ marginTop: -1 }}>Required</FormHelperText>}
            </MuiPickersUtilsProvider>
        );
    }
}
DatePickerWrapper.displayName = "DatePickerWrapper";
const DatePickerr = FormField(DatePickerWrapper);

export default withStyles(styles)(DatePickerr);
