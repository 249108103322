/******************************************************************************
 * This GroupTable Component shows the group tables for TableRecord.js
 * Each group will allow for deletion, editing, etc.
 *
 * Author: Robert Gautier
 * Date: 11/4/19
 * Rev Initial
 *******************************************************************************/

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form as Formm } from "react-form";
import ReactExport from "react-export-excel";

import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
//import AttachEmailIcon from "@material-ui/icons/Email";
import TablePagination from "@material-ui/core/TablePagination";
import Table from "@material-ui/core/Table";
import Grid from "@material-ui/core/Grid";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditIcon from "@material-ui/icons/Edit";
import GetApp from "@material-ui/icons/GetApp";
import Toolbar from "@material-ui/core/Toolbar";
import Dialog from "@material-ui/core/Dialog";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Close from "@material-ui/icons/Close";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Icon from "@material-ui/core/Icon";
import SettingsIcon from "@material-ui/icons/Settings";
import FolderIcon from "@material-ui/icons/Folder";
import NumberFormat from "react-number-format";
import EmailIcon from '@material-ui/icons/Email';
import shp from "../../common/icons/GIS_Polygon_Data_Shapefile3.svg"

import AppContainer from "../../common/AppContainer";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import TablePaginationActionsWrapped from "../../common/Paginator";
import { AdvancedSearch, AdvancedTableFields, AdvancedGroup } from "../models";
import { createSelector } from "../../common/orm";
import AdvancedFilters from "./AdvancedFilters";
import AdvancedSearchSettings from "./AdvancedSearchSettings";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

// CSS in JS styles
const styles = (theme) => ({
    ellipsis: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 15,
        WebkitBoxOrient: "vertical",
    },
    table: {
        minWidth: 500,
        paddingTop: "10px",
    },

    flex: {
        flex: 1,
    },
    minWidth: {
        minWidth: 600,
    },
    minWidth2: {
        minWidth: 750,
    },
    massEmail:{
        overflow: "hidden",
        minWidth: 750
    },
    root: {
        width: "100%",
        overflowX: "auto",
        backgroundColor: "#f0f0f0",
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        margin: "0 8px 0 0",
    },
    centerAlign: {
        textAlign: "center",
        fontWeight: "400px",
    },
    nowrap: {
        whiteSpace: "nowrap",
    },
    //view table & s
    icon: {
        width: "32px",
        marginRight: "7%",
        height: "32px",
        padding: "0px",
    },

    button: {
        float: "right",
        marginBottom: "7px",
    },

    statistics: {
        paddingLeft: "10px",
        fontWeight: 500,
        width: "15%",
        display: "inline-block",
        color: "#262626",
    },
    statistictable: {
        width: "100%",
        paddingTop: 10,
        paddingBottom: 10,
        borderRadius: 3,
    },
    expandedMargin: {
        "& > div:first-child": {
            margin: 0,
        }
    },
});

//get the pageID from the URL
const getPageId = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (session, pageID) => {
        //return pageID; //result function
        return session.Page.filter((p) => p.id === pageID).toRefArray();
    }
);

//get permissions
const getPermission = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (session, pageID) => {
        return session.Permission.filter((p) => p.page === pageID).toRefArray();
    } //toRefArray returns a plain JavaScript object which are references to the DB
);

const getAllSections = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (state, ownProps) => parseInt(ownProps.match.params["pageId"]),
    (session, id, pageId) => {
        if (isNaN(id)) {
            return session.Section.filter((sect) => sect.page === pageId)
                .orderBy("id")
                .toRefArray();
        } else {
            return session.Section.filter((sect) => sect.page === id)
                .orderBy("id")
                .toRefArray();
        }
    }
);

const getAdvancedSearchID = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["advID"]),
    (session, advS) => {
        return session.AdvancedSearch.filter((r) => r.id === advS)
            .orderBy("id")
            .toModelArray()
            .map((search) => ({
                lettertemplates: session.LetterTemplate.filter((lt) => lt.section === search.ref.section)
                    .orderBy("id")
                    .toRefArray(),
                ...search.ref,
            }));
    } //toRefArray returns a plain JavaScript object which are references to the DB
);
const getAdvancedTableFields = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["advID"]),
    (session, advS) => {
        return session.AdvancedTableFields.filter((r) => r.advancedsearch === advS)
            .orderBy("order")
            .toRefArray();
    } //toRefArray returns a plain JavaScript object which are references to the DB
);
const getAdvancedGroups = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, pageID) => {
        return session.AdvancedGroup.filter((r) => r.page === pageID)
            .orderBy("id")
            .toModelArray()
            .map((advancedGroup) => ({
                advancedSearch: advancedGroup.advancedsearch.all().orderBy("id").toRefArray(),
                ...advancedGroup.ref,
            }));
    }
);

const getFields = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (session, actPage) => {
        return session.Section.filter((sect) => sect.page === actPage)
            .orderBy("id")
            .toModelArray()
            .map((section) => ({
                form: section.forms //form.groups is FK linkage
                    .all()
                    .orderBy("id")
                    .toModelArray()
                    .map((form) => ({
                        groups: form.groups //form.groups is FK linkage
                            .all()
                            .orderBy("id")
                            .toModelArray()
                            .map((group) => ({
                                fields: group.fields.all().orderBy("id").toRefArray(),
                                ...group.ref, // take all groups as a reference and tie in each field
                            })),
                        ...form.ref, // take all forms as a reference and tie in each field
                    })),
                ...section.ref,
            }));
    } //toRefArray returns a plain JavaScript object which are references to the DB
);

//get users selected table columns
const getTableFields = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["advID"]),
    (session, advS) => {
        const tableFields = session.AdvancedTableFields.filter((r) => r.advancedsearch === advS)
            .orderBy("order")
            .toRefArray();

        // eslint-disable-next-line
        const test = session.Field.filter((m) => {
            for (var i = 0; i < tableFields.length; i++) {
                if (m.id === tableFields[i].field) return m;
            }
        })

            .toModelArray()
            .map((field) => ({
                advancedtablefield: field.advancedtablefields
                    .filter((t) => t.advancedsearch === advS)

                    .toRefArray(),
                ...field.ref,
            }));

        // eslint-disable-next-line
        const test2 = session.ContactField.filter((m) => {
            for (var i = 0; i < tableFields.length; i++) {
                if (m.id === tableFields[i].contact_field) return m;
            }
        })
            .orderBy("id")
            .toModelArray()
            .map((field) => ({
                advancedtablefield: field.advancedtablefields2.filter((t) => t.advancedsearch === advS).toRefArray(),
                ...field.ref,
            }));
        return test.concat(test2);
    }
);

const getAllContactFields = createSelector((schema) => {
    return schema.ContactField.all().orderBy("order").toModelArray();
});
//render of advanced search table
class AdvancedRecordTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            order: "asc",
            orderBy: null,
            deleteDialogOpen: false,
            deleteDialogText: null,
            deleteDialogConfirmAction: null,
            parent: null,
            formDialogStatus: false,
            parentrecord: null,
            pageStart: 0,
            rowsPerPage: 25,
            tableDialog: false,
            filteredFields: null,
            excel_is_Open: false,
            report_selection: false,
            report_selection_rs: null,
            batch_loading: false,
            autoLoadingRecords: false,
            formFields: null,
            shapefileLoading:false,
            massEmailFileLoading:false,
            openEmailDialog:false
        };
    }

    // When the delete button is pressed
    deletePage = (n) => {
        const { sections } = this.props;

        //get parent section as index's are not always consistant
        var parentSection = sections.filter((sect) => {
            if (sect.parent === true) {
                return sect.id;
            }
            return null;
        });

        //check for parent if parent delete all records below
        if (n.section === parentSection.id) {
            this.setState({
                deleteDialogOpen: true,
                deleteDialogConfirmAction: n.parentrecord,
                parent: true,
                deleteDialogText:
                    "WARNING: This will remove all attached records associated with this record. Are you sure you wish to permanently this?",
            });
        } //delete child relationship
        else {
            this.setState({
                deleteDialogOpen: true,
                deleteDialogConfirmAction: n.id,
                parent: false,
                deleteDialogText: "WARNING: This record will be removed. Are you sure you wish to permanently delete this record?",
            });
        }
    };

    handleRequestSort = (event, property) => {
        const { filteredFields } = this.state;

        // property = fieldID to sort by
        const orderBy = property;
        var order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }
        if (filteredFields.length > 0) {
            //to get type for date types & fieldname as we will sort by fieldname as they could have the same name used across a couple form inspections that will be grouped in one column
            var fieldType = "", fieldName = '';
            filteredFields.forEach((f) => f.recordfields.find((r) => (r.field_id === orderBy ? (fieldType = r.type, fieldName=r.name) : null)));
        }

        order === "desc"
            ? filteredFields.sort((a, b) => {
                  var bInput = "";
                  b.recordfields.forEach((e) => {
                      return e.name === fieldName ? (e.userinput ? (bInput = e.userinput) : "") : "";
                  });

                  var aInput = "";
                  a.recordfields.forEach((e) => {
                      return e.name === fieldName ? (e.userinput ? (aInput = e.userinput) : "") : "";
                  });

                  if (fieldType === "Date") {
                      if (aInput === "") return 1;
                      if (bInput === "") return -1;
                      aInput = Date.parse(aInput);
                      bInput = Date.parse(bInput);
                      return bInput < aInput ? -1 : 1;
                  } else if (fieldType === "Numeric" || fieldType === "Currency" || fieldType === "Calculated") {
                      aInput = parseFloat(aInput.replaceAll(",", "").replaceAll("$", ""));
                      bInput = parseFloat(bInput.replaceAll(",", "").replaceAll("$", ""));
                      return bInput < aInput ? -1 : 1;
                  } else return bInput.toUpperCase() < aInput.toUpperCase() ? -1 : 1; //all other types
              })
            : filteredFields.sort((a, b) => {
                  var aInput = "";
                  a.recordfields.forEach((e) => {
                      return e.name === fieldName ? (e.userinput ? (aInput = e.userinput) : "") : "";
                  });

                  var bInput = "";
                  b.recordfields.forEach((e) => {
                      return e.name === fieldName ? (e.userinput ? (bInput = e.userinput) : "") : "";
                  });

                  if (fieldType === "Date") {
                      if (aInput === "") return -1;
                      if (bInput === "") return 1;
                      aInput = Date.parse(aInput);
                      bInput = Date.parse(bInput);

                      return aInput < bInput ? -1 : 1;
                  } else if (fieldType === "Numeric" || fieldType === "Currency" || fieldType === "Calculated") {
                      aInput = parseFloat(aInput.replaceAll(",", "").replaceAll("$", ""));
                      bInput = parseFloat(bInput.replaceAll(",", "").replaceAll("$", ""));
                      return aInput < bInput ? -1 : 1;
                  } else return aInput.toUpperCase() < bInput.toUpperCase() ? -1 : 1; //all other types
              });

        this.setState({rowsPerPage: 25, order, orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ pageStart: page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value, pageStart: 0 });
    };

    //table dialog for selecting users chosen fields
    handleDialogClose = () => {
        this.setState({ tableDialog: false, filteredFields: null });
    };

    setFilteredFields = (data) => {
        this.setState({ filteredFields: data, pageStart:0 });
        window.scrollTo({top:350, behavior: 'smooth'})
    };
    setFormFields = (data) => {
        //triggered from AdvancedFilters.js -- section was set to include parent/child sections with fields now add contactfields if they are available occurs on initial section/search creation
        const { contact_fields } = this.props;
        if (contact_fields) {
            let temparray = [];
            contact_fields.forEach((k) => {
                temparray.push({ label: k.field_name, value: "c_" + k.id });
            });
            let form = { label: "Contacts (Section Contacts)", options: temparray };
            data.push(form);
        }
        this.setState({ formFields: data });
    };
    setAutoLoadingRecords = (e) => {
        this.setState({ autoLoadingRecords: e }); 
    };

    render() {
        const {
            classes,
            ormAdvancedTableFieldsCreate,
            ormAdvancedTableFieldsUpdate,
            ormAdvancedTableFieldsDelete,
            ormAdvancedSearchUpdate,
            ormAdvancedGroupCreate,
            ormAdvancedGroupUpdate,
            advancedsearchID,
            advancedtablefields,
            page,
            sections,
            authState,
            allFields,
            tableFields,
            permission,
            key,
            advancedgroups,
            contact_fields,  
        } = this.props;
        const {
            order,
            orderBy,
            rowsPerPage,
            pageStart,
            tableDialog,
            filteredFields,
            excel_is_Open,
            report_selection,
            report_selection_rs,
            batch_loading,
            autoLoadingRecords,
            formFields,
            batch_loading2,
            shapefileLoading,
            massEmailFileLoading,
            openEmailDialog,
        } = this.state;

        var tableData;
        var columnRecord = [],
            sortedTableFields = [];
        columnRecord.push({ id: "actions", label: "", allowSort: false }); //push the first action area to be blank in the header on the table to allow for alignment
        // if(advancedsearchID && advancedsearchID.length > 0 && advancedsearchID[0].showlatloncolumns){
        //     columnRecord.push({ id: "Latitude", label: "Latitude", allowSort: false });
        //     columnRecord.push({ id: "Longitude", label: "Longitude", allowSort: false });
        // }
        //sort these to match order in react column selector
        advancedtablefields.forEach((f) => {
            var v = tableFields.filter((tf) => tf.id === f.field || tf.id === f.contact_field)[0];
            if (v) sortedTableFields.push(v);
        });
        if (sortedTableFields.length > 0) {
            var t = [];
            var addMain = true;
            sortedTableFields.forEach((f) => {
                //look for contact fields if so recreate as they have diff prop names and push to arr/obj for columns
                if (f && f.field_name) {
                    if (addMain) {
                        //we will add main contact in front of the first contact field
                        let mainObj = {};
                        mainObj.id = "Main Contact?";
                        mainObj.name = "Main Contact?";
                        mainObj.type = "text";
                        t.push(mainObj);
                        addMain = false;
                    }
                    let obj = {};
                    obj.id = f.id;
                    obj.name = f.field_name;
                    obj.type = f.field_type;
                    obj.allowSort = true;
                    if (!t.find((j) => j.name === f.field_name)) {
                        return t.push(obj);
                    }
                } else if (!t.find((j) => j.name === f.name)) {
                    //all other standard fields
                    return t.push(f);
                }
            });

            //now just get into other object for the columns names
            t.forEach((c) => {
                columnRecord.push({
                    id: c.id,
                    label: c.name,
                    type: c.type,
                    allowSort: true,
                });
            });
        }

        tableData = filteredFields;

        var pageInitialized = false;
        if (page.length > 0) pageInitialized = true;

        if (authState.user) {
            var readOnly;
            var perm = permission.find((p) => {
                if (p.user === authState.user.id) {
                    return p;
                }
                return null;
            });
            if (perm) readOnly = perm.readonly || perm.readonlyexternal ? true : false;
            if (readOnly === undefined) readOnly = false;
        }

        //statistic fields placed into object for table
        var statisticFields = advancedtablefields.filter((f) => {
            return f.statistics === true;
        });
        if (tableData) {
            statisticFields.forEach((s) => {
                var sum = 0,
                    count = 0;
                var c = [];
                tableData.forEach((t) => {
                    t.recordfields.forEach((r) => {
                        if (r.field_id === s.field && r.userinput) {
                            //verify not empty string, if include a comma get rid of for calculation
                            if (r.userinput.includes(",")) {
                                c.push(r.userinput.replace(",", ""));
                            } else {
                                c.push(r.userinput);
                            }
                        }
                    });
                });
                c.forEach((c) => {
                    sum += parseFloat(c);
                    count += 1;
                });
                let field = tableFields.find((f) => {
                    return f.id === s.field;
                });
                s["sum"] = sum.toFixed(2);
                s["count"] = count;
                s["avg"] = (sum / count).toFixed(2);
                s["min"] = Math.min.apply(null, c);
                s["max"] = Math.max.apply(null, c);
                s["fieldname"] = field.name;
            });

            //for crwd mass email for permit module
            if (tableData[0] && tableData[0].page_id === '42'){

                var massEmailData = tableData.filter(n=>{  
                    return n.recordfields &&
                    n.recordfields.find((actCol) => {
                        if (actCol && actCol.name === 'Main Contact?' && actCol.userinput === 'Yes') {
                            return actCol;
                        }
                        return null;
                    })? n: null;
                }) 
            }
        }
        var cols = ["Field", "Count", "Sum", "Average", "Min", "Max"];

        var disabled; //weather or not to show user having edit previliges
        advancedsearchID.forEach((a) => {
            if (
                (a.editableby === "Creator" && parseInt(a.editcreatorid) === authState.user.id) ||
                (a.editableby === "Admin" && authState.user.is_city_admin.toString() === "true") ||
                a.editableby === "All" ||
                (a.editableby === "All Except External Read Only Users" && !perm.readonlyexternal)
            ) {
                return (disabled = false);
            } else {
                return (disabled = true);
            }
        });

        if (sections && advancedsearchID[0] && advancedsearchID[0].section)
            var sectionName = sections.find((s) => s.id === advancedsearchID[0].section);



        return (
            <AppContainer authenticated>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={6}>
                                    {page.length > 0 && (
                                        <Typography variant="h5" style={{ marginTop: 6 }}>
                                            {page[0].name + " - Saved Search"}
                                        </Typography>
                                    )}
                                </Grid>
                                {/* MAIN PAGE ICONS */}
                                {pageInitialized && (
                                    <Grid item xs={6} style={{ textAlign: "right" }}>
                                        {page && (
                                            <Tooltip className={classes.hideSmall} title={`Dashboard`}>
                                                <IconButton component={Link} to={"/dashboard/" + page[0].id}>
                                                    <Icon>timeline</Icon>
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        {sectionName && !sectionName.is_master_contact_form && (
                                            <Tooltip title={"View all " + sectionName.name ? sectionName.name : null + "s"}>
                                                <IconButton
                                                    component={Link}
                                                    to={"/page/" + page[0].id + "/" + advancedsearchID[0].section + "/table"} //route to section table view of all records for that page
                                                >
                                                    <Icon>view_headline </Icon>
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        {sectionName && sectionName.has_geometry && (
                                            <Tooltip title={"All " + page[0].name + " Geometries"}>
                                                <IconButton
                                                    component={Link}
                                                    to={"/map/" + page[0].id + "/section/" + 0 + "/advancedsearch/" + 1}>
                                                    <Icon>map</Icon>
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        <Tooltip title={`All ${page[0].name} Saved Searches`}>
                                            <IconButton
                                                component={Link}
                                                to={"/page/" + page[0].id + "/advancedsearch"}>
                                                <Icon>search</Icon>
                                            </IconButton>
                                        </Tooltip>
                                        {authState.user && authState.user.is_city_admin === true && (
                                            <Tooltip title="Module Configuration">
                                                <IconButton
                                                    disabled={readOnly}
                                                    component={Link}
                                                    to={"/page/" + page[0].id + "/config"} //route to page config
                                                >
                                                    <Icon>settings</Icon>
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        {/* ADVANCED FILTER COMPONENT */}
                        <AdvancedFilters
                            key={sections.id}
                            //onRef={el => (this.expansionFilters = el)}
                            page={page}
                            filteredData={this.setFilteredFields}
                            autoLoadingRecords={this.setAutoLoadingRecords}
                            getFormFields={this.setFormFields}
                            authState={authState}
                            contact_fields={contact_fields}
                            sections={sections}
                        />
                        <Formm
                            key={key}
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            getApi={(el) => (this.formApi = el)}
                            onSubmit={(values) => this.updateFields(values)}>
                            {(formApi) => (
                                <form onSubmit={formApi.submitForm}>
                                    <Accordion defaultExpanded={statisticFields.length > 0 ? true : false} style={{ marginBottom: "15px" }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            style={{ backgroundColor: "#ddd" }}>
                                            <Typography
                                                className={classes.heading}
                                                style={{ paddingLeft: "0px", fontSize: "1.15rem", width: "90%", display: "inline-block" }}>
                                                Statistics
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                            <table style={{ width: "100%" }}>
                                                <tr>
                                                    <td
                                                        className={classes.statistictable}
                                                        style={{
                                                            backgroundColor: "#333333",
                                                        }}>
                                                        {cols.map((c) => {
                                                            return (
                                                                <Typography
                                                                    style={{
                                                                        paddingLeft: "10px",
                                                                        fontWeight: 500,
                                                                        width: "15%",
                                                                        display: "inline-block",

                                                                        color: "#ffffff",
                                                                    }}>
                                                                    {c}
                                                                </Typography>
                                                            );
                                                        })}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td
                                                        className={classes.statistictable}
                                                        style={{
                                                            backgroundColor: "#f0f0f0",
                                                        }}>
                                                        {statisticFields.map((s) => {
                                                            return (
                                                                <>
                                                                    <Typography className={classes.statistics}>{s.fieldname}</Typography>
                                                                    <Typography className={classes.statistics}>{s.count}</Typography>
                                                                    <Typography className={classes.statistics}>{s.sum}</Typography>
                                                                    <Typography className={classes.statistics}>{s.avg}</Typography>
                                                                    <Typography className={classes.statistics}>{s.min}</Typography>
                                                                    <Typography className={classes.statistics}>{s.max}</Typography>
                                                                </>
                                                            );
                                                        })}
                                                    </td>
                                                </tr>
                                            </table>
                                        </AccordionDetails>
                                    </Accordion>
                                </form>
                            )}
                        </Formm>
                        <Formm
                            key={this.formKey}
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            getApi={(el) => (this.formApi = el)}
                            onSubmit={(values) => this.updateFields(values)}>
                            {(formApi) => (
                                <form onSubmit={formApi.submitForm}>
                                    <Accordion defaultExpanded={true}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            style={{ backgroundColor: "#ddd"}}
                                            classes={{ root: classes.expandedMargin }}>
                                            <table style={{width:"100%"}}>
                                                <tr>
                                                    <td>
                                                        <Typography
                                                            className={classes.heading}
                                                            style={{
                                                                paddingLeft: "0px",
                                                                fontSize: "1.15rem",
                                                            }}>
                                                            Results
                                                            {columnRecord.length < 2
                                                                ? "- 'setup' a filter above and click 'search' to populate the result table"
                                                                : ""}
                                                        </Typography>
                                                    </td>
                                                    <td style={{textAlign:"right"}}>
                                                        {tableData &&
                                                            tableData.length > 0 &&
                                                            advancedsearchID[0].lettertemplates.map((n) => {
                                                                var recsects = "";
                                                                var number_of_reports = 0;
                                                                tableData.forEach((td) => {
                                                                    recsects += td.recordsection + ",";
                                                                    number_of_reports++;
                                                                });
                                                                recsects = recsects.substring(0, recsects.length - 1);

                                                                if (batch_loading) {
                                                                    return (
                                                                        <CircularProgress
                                                                            size={24}
                                                                            style={{ marginTop: 14, marginLeft: -40, position: "absolute" }}
                                                                        />
                                                                    );
                                                                }
                                                                return (
                                                                    <Tooltip style={{ marginLeft: "5px" }} title={"Generate Batch Report for " + n.name}>
                                                                        <IconButton
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                if (number_of_reports > 500) {
                                                                                    alert(
                                                                                        "The maximum number of reports that can be ran is 500. Please narrow your search down and try again."
                                                                                    );
                                                                                    return;
                                                                                }
                                                                                this.setState({ batch_loading: true });
                                                                                fetch("/reports/batch/custom", {
                                                                                    method: "POST",
                                                                                    headers: {
                                                                                        "Content-Type": "application/json",
                                                                                        Authorization: "Token " + authState.user.auth_token,
                                                                                    },
                                                                                    body: JSON.stringify({
                                                                                        city: authState.user.city_id,
                                                                                        recordsections: recsects,
                                                                                        customreport: n.id,
                                                                                    }),
                                                                                })
                                                                                    .then((res) => res.blob())
                                                                                    .then((blob) => {
                                                                                        // Shorter but no name
                                                                                        //var file = window.URL.createObjectURL(blob);
                                                                                        //window.location.assign(file);

                                                                                        var url = window.URL.createObjectURL(blob);
                                                                                        var a = document.createElement("a");
                                                                                        a.href = url;
                                                                                        a.download =
                                                                                            "Batch Reports for " + advancedsearchID[0].name + ".zip";
                                                                                        document.body.appendChild(a);
                                                                                        a.click();
                                                                                        a.remove();
                                                                                        this.setState({ batch_loading: false });
                                                                                    });
                                                                            }}>
                                                                            <LibraryBooks />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                );
                                                            })}
                                                        {shapefileLoading ? (
                                                            <CircularProgress size={24}  />
                                                        ): (
                                                            <Tooltip
                                                                style={{ marginLeft: "5px" }}
                                                                title={"Generate Shapefile for " + page[0].name + " Search "}>
                                                                <IconButton
                                                                    onClick={e=>{
                                                                        e.stopPropagation();
                                                                        this.setState({ shapefileLoading: true });
                                                                        try {
                                                                            const res = fetch(`page/${page[0].id}/advancedsearch/${advancedsearchID[0].id}`, {
                                                                                method: "POST",
                                                                                headers: {
                                                                                    "Content-Type": "application/json",
                                                                                    Authorization: "Token " + authState.user.auth_token,
                                                                                    "shapefile": false
                                                                                },
                                                                                body: JSON.stringify({
                                                                                    "shapefile": true,
                                                                                 }),
                                                                            })   
                                                                            .then((res) => res.blob())
                                                                            .then((blob) => {
                                                                                var url = window.URL.createObjectURL(blob);
                                                                                var a = document.createElement("a");
                                                                                a.href = url;
                                                                                a.download = "Shapefile Results for " + advancedsearchID[0].name + " search.zip";
                                                                                document.body.appendChild(a);
                                                                                a.click();
                                                                                a.remove();
                                                                                this.setState({ shapefileLoading: false });
                                                                            });
                                                                            console.log(res);
                                                                        } catch {
                                                                            this.setState({ shapefileLoading: false });
                                                                        }
        
                                                                    }}>
                            
                                                                    <img
                                                                        style={{
                                                                            width: "1.4em",
                                                                            height: "1.4em",
                                                                            verticalAlign: "middle" 
                                                                        }}
                                                                        src={shp}
                                                                        alt="fireSpot"
                                                                    />
                                                                             
                                                                </IconButton>
                                                            </Tooltip>
                                                        )} 
                                                        {advancedsearchID && advancedsearchID[0].geometriesonmap && (
                                                            <Tooltip
                                                                style={{ marginLeft: "5px" }}
                                                                title={"Saved Search " + page[0].name + " Geometries"}>
                                                                <IconButton
                                                                    component={Link}
                                                                    to={
                                                                        "/map/" +
                                                                        page[0].id +
                                                                        "/section/" +
                                                                        advancedsearchID[0].section +
                                                                        "/advancedsearch/" +
                                                                        advancedsearchID[0].id
                                                                    }>
                                                                    <Icon>map</Icon>
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                        {batch_loading2 ? (
                                                            <CircularProgress size={24} style={{ marginLeft: 8 }} />
                                                        ) : (
                                                            <Tooltip
                                                                title="Download Attachments"
                                                                style={{ marginLeft: 5 }}
                                                                disabled={!tableData}>
                                                                <IconButton
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        this.setState({ batch_loading2: true });
                                                                        var precords = tableData.map((td) => td.parentrecord);
                                                                        var unique_parents = [...new Set(precords)];
                                                                        fetch("/reports/batch/attachments", {
                                                                            method: "POST",
                                                                            headers: {
                                                                                "Content-Type": "application/json",
                                                                                Authorization: "Token " + authState.user.auth_token,
                                                                            },
                                                                            body: JSON.stringify({
                                                                                city: authState.user.city_id,
                                                                                parentrecords: unique_parents,
                                                                            }),
                                                                        })
                                                                            .then((res) => res.blob())
                                                                            .then((blob) => {
                                                                                var url = window.URL.createObjectURL(blob);
                                                                                var a = document.createElement("a");
                                                                                a.href = url;
                                                                                a.download =
                                                                                    "Attachment Results for " + advancedsearchID[0].name + ".zip";
                                                                                document.body.appendChild(a);
                                                                                a.click();
                                                                                a.remove();
                                                                                this.setState({ batch_loading2: false });
                                                                            });
                                                                    }}>
                                                                    <FolderIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                        <Tooltip title="Download" style={{ marginLeft: 5 }} disabled={!tableData}>
                                                            <IconButton
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    // import("react-export-excel").then(ReactExport => {
                                                                    //     ExcelFile = ReactExport.ExcelFile;
                                                                    //     ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
                                                                    //     ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
                                                                    // });
                                                                    this.setState({ excel_is_Open: true });
                                                                    setTimeout(() => {
                                                                        this.setState({ excel_is_Open: false });
                                                                    }, 1000);
                                                                }}>
                                                                <GetApp />
                                                            </IconButton>
                                                        </Tooltip>
                                                        {massEmailFileLoading ? (
                                                            <CircularProgress size={24}  />
                                                        ): (
                                                            <>
                                                            {page && page[0] && page[0].id === 42 && (  
                                                                <Tooltip
                                                                    style={{ marginLeft: "5px" }}
                                                                    title={"Maintenance Email Communication"}>
                                                                    <IconButton
                                                                        onClick={e=>{
                                                                            e.stopPropagation()
                                                                            this.setState({openEmailDialog:true})
                                                                    
                                                                        }}
                                                                        > 
                                                                        <EmailIcon/>  
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )}
                                                            </>
                                                        )}   
                                                        {readOnly === false && advancedsearchID && !advancedsearchID[0].customized && (
                                                            <Tooltip title="Table Settings" style={{ marginLeft: 5 }}>
                                                                <IconButton
                                                                    disabled={disabled}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        this.setState({ tableDialog: true });
                                                                    }}>
                                                                    <Icon>settings</Icon>
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}    
                                                    </td>
                                                </tr>
                                            </table>
                                        </AccordionSummary>
                                        <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                            <Grid item xs={12}>
                                                {/* TABLE SECTION */}
                                                <Paper className={classes.root}>
                                                    {tableData && tableData.length > 15 && (
                                                            <TableFooter>
                                                                <TableRow>
                                                                    <TablePagination
                                                                        colSpan={columnRecord.length}
                                                                        count={tableData.length}
                                                                        rowsPerPage={rowsPerPage}
                                                                        page={pageStart}
                                                                        onChangePage={this.handleChangePage}
                                                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                                        ActionsComponent={TablePaginationActionsWrapped}
                                                                    />
                                                                </TableRow>
                                                            </TableFooter>
                                                        )}
                                                    <Table className={classes.table}>
                                                        <EnhancedTableHead
                                                            order={order}
                                                            orderBy={orderBy}
                                                            onRequestSort={this.handleRequestSort}
                                                            columnData={columnRecord}
                                                        />
                                                        <TableBody>
                                                            {(() => {
                                                                if (!tableData) {
                                                                    return (
                                                                        <TableRow>
                                                                            <CustomTableCell
                                                                                colSpan={columnRecord.length}
                                                                                // className={classes.centerAlign}
                                                                                style={{ paddingBottom: "35px", height: 10 }}>
                                                                                <div
                                                                                    className={classes.centerAlign}
                                                                                    style={{ position: "absolute", float: "right", width: "100%" }}>
                                                                                    {columnRecord &&
                                                                                        columnRecord.length < 2 &&
                                                                                        "Select Fields from 'Table Settings'  "}

                                                                                    {columnRecord && columnRecord.length < 2 && <SettingsIcon />}
                                                                                </div>
                                                                            </CustomTableCell>
                                                                        </TableRow>
                                                                    );
                                                                }
                                                                if (tableData.length < 1) {
                                                                    return (
                                                                        <TableRow>
                                                                            <CustomTableCell
                                                                                colSpan={columnRecord.length}
                                                                                className={classes.centerAlign}
                                                                                style={{ paddingBottom: 8 }}>
                                                                                No Records Found
                                                                            </CustomTableCell>
                                                                        </TableRow>
                                                                    );
                                                                }
                                                                if (tableData.length > 0) {
                                                                    return tableData
                                                                        .slice(pageStart * rowsPerPage, pageStart * rowsPerPage + rowsPerPage)
                                                                        .map((n) => {
                                                                            return (
                                                                                <TableRow hover /*key={n.id}*/>
                                                                                    <CustomTableCell className={classes.nowrap}>
                                                                                        <Tooltip title="Edit">
                                                                                            {/* LINK TO EDIT OF RECORD "AccordionFORMRECORD" */}
                                                                                            <Button
                                                                                                component={Link}
                                                                                                to={
                                                                                                    "/page/" +
                                                                                                    n.page_id +
                                                                                                    "/parentrecord/" +
                                                                                                    n.parentrecord +
                                                                                                    "/section/" +
                                                                                                    n.section +
                                                                                                    "/recordsection/" +
                                                                                                    n.recordsection +
                                                                                                    "/form"
                                                                                                }
                                                                                                className={classes.deleteWidth}>
                                                                                                <EditIcon color="primary" />
                                                                                            </Button>
                                                                                        </Tooltip>
                                                                                        <Tooltip title="Word Document">
                                                                                            <Button
                                                                                                className={classes.deleteWidth}
                                                                                                onClick={() => {
                                                                                                    if (
                                                                                                        advancedsearchID[0].lettertemplates.length ===
                                                                                                        0
                                                                                                    )// no templates so just open parentsection report in word
                                                                                                        window.open(
                                                                                                            `/reports/${authState.user.city_id}/${n.parentrecord}/${n.recordsection}/word`
                                                                                                        );
                                                                                                    else
                                                                                                        this.setState({
                                                                                                            report_selection: true,
                                                                                                            report_selection_rs: n.recordsection,
                                                                                                        });
                                                                                                }}>
                                                                                                <i className="material-icons" color="primary">
                                                                                                    assignment
                                                                                                </i>
                                                                                            </Button>
                                                                                        </Tooltip>
                                                                                    </CustomTableCell>
                                                                                    {/* PRINTING OF THE DATA IN THE TABLE */}
                                                                                    {columnRecord.map((column, index) => {
                                                                                        if (index !== 0) {
                                                                                            //dont print 0 as that is button location
                                                                                            var found =
                                                                                                n.recordfields &&
                                                                                                n.recordfields.find((actCol) => {
                                                                                                    if (actCol && actCol.name === column.label) {
                                                                                                        return actCol.userinput;
                                                                                                    }
                                                                                                    return null;
                                                                                                });
                                                                                            if (found) {
                                                                                                if (found.type === "Phone Number") {
                                                                                                    return (
                                                                                                        <CustomTableCell key={index + column.label}>
                                                                                                            <NumberFormat
                                                                                                                format="+1 (###) ###-####"
                                                                                                                allowEmptyFormatting
                                                                                                                mask="_"
                                                                                                                displayType={"text"}
                                                                                                                value={found.userinput}
                                                                                                            />
                                                                                                        </CustomTableCell>
                                                                                                    );
                                                                                                } else if (
                                                                                                    found.type === "Numeric" ||
                                                                                                    found.type === "Calculated"
                                                                                                ) {
                                                                                                    return (
                                                                                                        <CustomTableCell key={index + column.label}>
                                                                                                            <NumberFormat
                                                                                                                allowEmptyFormatting
                                                                                                                mask="_"
                                                                                                                displayType={"text"}
                                                                                                                decimalScale={found.decimals}
                                                                                                                value={found.userinput}
                                                                                                            />
                                                                                                        </CustomTableCell>
                                                                                                    );
                                                                                                } else if (found.type === "Currency") {
                                                                                                    return (
                                                                                                        <CustomTableCell key={column.id}>
                                                                                                            <NumberFormat
                                                                                                                prefix="$"
                                                                                                                mask="_"
                                                                                                                displayType={"text"}
                                                                                                                value={found ? found.userinput : "-"}
                                                                                                                thousandSeparator={true}
                                                                                                            />
                                                                                                        </CustomTableCell>
                                                                                                    );
                                                                                                } else {
                                                                                                    if (found.userinput.length > 250) {
                                                                                                        return (
                                                                                                            <Tooltip title={found.userinput}>
                                                                                                                <CustomTableCell key={index + column.label}>
                                                                                                                    <div className={classes.ellipsis}>
                                                                                                                        {found.userinput}
                                                                                                                    </div>
                                                                                                                </CustomTableCell>
                                                                                                            </Tooltip>
                                                                                                        )
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <CustomTableCell key={index + column.label}>
                                                                                                                {found.userinput}
                                                                                                            </CustomTableCell>
                                                                                                        )
                                                                                                    }
                                                                                                }
                                                                                            } else {
                                                                                                return <CustomTableCell>{"-"}</CustomTableCell>;
                                                                                            }
                                                                                        } else {
                                                                                            return null;
                                                                                        }
                                                                                    })}
                                                                                </TableRow>
                                                                            );
                                                                        });
                                                                }
                                                            })()}
                                                        </TableBody>
                                                        {tableData && tableData.length > 8 && (
                                                            <TableFooter>
                                                                <TableRow>
                                                                    <TablePagination
                                                                        colSpan={columnRecord.length}
                                                                        count={tableData.length}
                                                                        rowsPerPage={rowsPerPage}
                                                                        page={pageStart}
                                                                        onChangePage={this.handleChangePage}
                                                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                                        ActionsComponent={TablePaginationActionsWrapped}
                                                                    />
                                                                </TableRow>
                                                            </TableFooter>
                                                        )}
                                                    </Table>
                                                </Paper>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </form>
                            )}
                        </Formm>
                        {/* SETTINGS FOR TABLE AND SEARCH PERMISSIONS */}

                        <AdvancedSearchSettings
                            open={tableDialog}
                            handleDialog={this.handleDialogClose}
                            ormAdvancedTableFieldsCreate={ormAdvancedTableFieldsCreate}
                            ormAdvancedTableFieldsUpdate={ormAdvancedTableFieldsUpdate}
                            ormAdvancedTableFieldsDelete={ormAdvancedTableFieldsDelete}
                            ormAdvancedGroupUpdate={ormAdvancedGroupUpdate}
                            ormAdvancedGroupCreate={ormAdvancedGroupCreate}
                            ormAdvancedSearchUpdate={ormAdvancedSearchUpdate}
                            advancedgroups={advancedgroups}
                            advancedtablefields={advancedtablefields}
                            allFields={allFields}
                            formFields={formFields} //for inital update of fields when section is selected
                            sections={sections}
                            advancedsearchID={advancedsearchID}
                            authState={authState} 
                        />

                        {advancedsearchID.length > 0 && (
                            <Download
                                excelOpen={excel_is_Open}
                                columnHeader={columnRecord}
                                tableData={tableData}
                                searchName={"Adv. Search " + advancedsearchID[0].name}
                                showlatloncolumns={advancedsearchID[0].showlatloncolumns}
                            />
                        )}
                    </Grid>
                </Grid>

                {/* Auto Loading on display occurs when routing from a card analytic */}
                <Dialog open={autoLoadingRecords} classes={{ paper: classes.minWidth2 }}>
                    <DialogContent>
                        <DialogContentText style={{ minWidth: 350, minHeight: 50 }}>
                            <>
                                <CircularProgress size={24} style={{ textAlign: "center", flex: 1 }} />
                                <Typography
                                    variant="h6"
                                    style={{
                                        textAlign: "center",
                                        display: "inline-block",
                                        fontSize: "1.15rem",
                                        paddingLeft: "9%",
                                    }}>
                                    Retrieving Records. Please Wait...
                                </Typography>
                            </>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>

                <Dialog open={report_selection} classes={{ paper: classes.minWidth2 }}>
                    <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                        <Typography variant="h5" className={classes.flex}>
                            Select Report
                        </Typography>
                        <IconButton onClick={() => this.setState({ report_selection: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent>
                        <DialogContentText>
                            <List>
                                <ListItem
                                    button
                                    onClick={() => {
                                        window.open(`/reports/${authState.user.city_id}/${report_selection_rs}/word/`);
                                        this.setState({ report_selection: false });
                                    }}>
                                    <ListItemText primary="MS4Front Basic Report" />
                                </ListItem>
                                {advancedsearchID[0] &&
                                    advancedsearchID[0].lettertemplates.map((t) => {
                                        return (
                                            <ListItem
                                                button
                                                title="Generate Report as Word Document"
                                                style={{ borderBottom: "1px solid lightgray", paddingRight: 84 }}
                                                onClick={() => {
                                                    window.open("/reports/custom/" + authState.user.city_id + "/" + report_selection_rs + "/" + t.id);
                                                    this.setState({ report_selection: false });
                                                }}>
                                                <ListItemText primary={t.name} />
                                                <ListItemSecondaryAction>
                                                    <IconButton
                                                        edge="end"
                                                        title="Generate Report as PDF"
                                                        aria-label="pdf"
                                                        onClick={() => {
                                                            window.open(
                                                                "/reports/custom/" +
                                                                    authState.user.city_id +
                                                                    "/" +
                                                                    report_selection_rs +
                                                                    "/" +
                                                                    t.id +
                                                                    "/pdf"
                                                            );
                                                            this.setState({ report_selection: false });
                                                        }}>
                                                        <PictureAsPdfIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        );
                                    })}
                            </List>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                {/* Custom email of results for CRWD */}
                <Dialog open={openEmailDialog} classes={{ paper: classes.massEmail  }} >
                    <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                        <Typography variant="h5" className={classes.flex}>
                            Send Maintenance Email Communication?
                        </Typography>
                        <IconButton onClick={() => this.setState({ openEmailDialog: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent>
                        <DialogContentText> 
                            {tableData && tableData.length > 30 && ( 
                                <Button
                                    onClick={()=>{
                                        try {
                                            const res = fetch(`savedsearch/massemail`, {
                                                method: "POST",
                                                headers: {
                                                    "Content-Type": "application/json",
                                                    Authorization: "Token " + authState.user.auth_token,
                                                    
                                                },
                                                body: JSON.stringify({
                                                    "recs":  massEmailData,
                                                    }),
                                            })   
                                            .then((res) =>  { 
                                                this.setState({ openEmailDialog: false });
                                            });
                                            console.log(res);
                                        } catch {
                                            this.setState({ openEmailDialog: false });
                                        }
                                        this.setState({ openEmailDialog: false });
                                    }}
                                    disabled={disabled}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    style={{ marginTop: 10, marginBottom: 10,width: "100%",  }}>
                                    Confirm and Send
                                </Button>
                            )} 
                            <Table className={classes.table}>
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={this.handleRequestSort}
                                    columnData={[{ id: "permit", label: "Permit #", allowSort: false },{id: "main", label: "Main Contact?", allowSort: false }, {id: "email", label: "E-Mail", allowSort: false }]}
                                />
                                <TableBody>
                                    {(() => {
                                        if (!massEmailData) {
                                            return (
                                                <TableRow>
                                                    <CustomTableCell
                                                        colSpan={columnRecord.length}
                                                        // className={classes.centerAlign}
                                                        style={{ paddingBottom: "35px", height: 10 }}>
                                                        <div
                                                            className={classes.centerAlign}
                                                            style={{ position: "absolute", float: "right", width: "100%" }}>
                                                            No Records Found
                                                        </div>
                                                    </CustomTableCell>
                                                </TableRow>
                                            );
                                        }
                                        if (massEmailData.length < 1) {
                                            return (
                                                <TableRow>
                                                    <CustomTableCell
                                                        colSpan={columnRecord.length}
                                                        className={classes.centerAlign}
                                                        style={{ paddingBottom: 8 }}>
                                                        No Records Found
                                                    </CustomTableCell>
                                                </TableRow>
                                            );
                                        }
                                        if (massEmailData.length > 0) {
                                            return massEmailData
                                                // .slice(pageStart * rowsPerPage, pageStart * rowsPerPage + rowsPerPage)
                                                .map((n) => {
                                                    return (
                                                        <TableRow hover /*key={n.id}*/> 
                                                            {/* PRINTING OF THE DATA IN THE TABLE */}
                                                            {columnRecord.map((column, index) => {
                                                                if (index !== 0) {
                                                                    //dont print 0 as that is button location
                                                                
                                                                        let found =
                                                                            n.recordfields &&
                                                                            n.recordfields.find((actCol) => {
                                                                                if (actCol && actCol.name === column.label) {
                                                                                    return actCol.userinput;
                                                                                }
                                                                                return null;
                                                                            });
                                                                        if (found && (found.name === 'Permit #' || found.name === 'E-mail' || found.name === 'Main Contact?')) {
                                                                        
                                                                                if (found.userinput.length > 250) {
                                                                                    return (
                                                                                        <Tooltip title={found.userinput}>
                                                                                            <CustomTableCell key={index + column.label}>
                                                                                                <div className={classes.ellipsis}>
                                                                                                    {found.userinput}
                                                                                                </div>
                                                                                            </CustomTableCell>
                                                                                        </Tooltip>
                                                                                    )
                                                                                } else { 
                                                                                    return (
                                                                                    <CustomTableCell key={index + column.label}>
                                                                                        {found.userinput}
                                                                                    </CustomTableCell>
                                                                                    )  
                                                                                }
                                                                            
                                                                        }else return null  
                                                                    
                                                                } else {
                                                                    return null;
                                                                }
                                                            })}
                                                        </TableRow>
                                                    );
                                                });
                                        }
                                    })()}
                                </TableBody>
                            </Table>
                            {tableData && tableData.length > 0 && ( 
                                <Button
                                    onClick={()=>{
                                        try {
                                            const res = fetch(`savedsearch/massemail`, {
                                                method: "POST",
                                                headers: {
                                                    "Content-Type": "application/json",
                                                    Authorization: "Token " + authState.user.auth_token,
                                                    
                                                },
                                                body: JSON.stringify({
                                                    "recs":  massEmailData,
                                                    }),
                                            })   
                                            .then((res) =>  {
                                                
                                                this.setState({ openEmailDialog: false });
                                            });
                                            console.log(res);
                                        } catch {
                                            this.setState({ openEmailDialog: false });
                                        }
                                        this.setState({ openEmailDialog: false });
                                    }}
                                    disabled={disabled}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    style={{ marginTop: 29, marginBottom: 29,width: "100%"  }}>
                                    Confirm and Send
                                </Button>
                            )} 
                          
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                { massEmailFileLoading && <CircularProgress size={24}  />}
            </AppContainer>
        );
    }
}

class Download extends Component {
    cleanUpData = (tableData, columnHeader, showlatloncolumns) => {
        var obj = {},
            data = [],
            temp = [];

        if (showlatloncolumns){
            let lat = { id: "Latitude", label: "Latitude", allowSort: false }
            let lon = { id: "Longitude", label: "Longitude", allowSort: false }
            columnHeader.unshift(lat)
            columnHeader.unshift(lon)
        }
        //organize the data so that it matches the column header arrangement
        //will output into an array of array objects
        //eslint-disable-next-line
        tableData.map((n) => {
            //eslint-disable-next-line
            columnHeader.map((column) => {
                var found =
                    n.recordfields &&
                    n.recordfields.find((actCol) => {
                        if (actCol.name === column.label) {
                            return actCol;
                        }
                        return null;
                    });
                if (found) {
                    if (found.userinput === "Infinity" || found.userinput === "NaN") found.userinput = "";
                    if ((found.type === "Numeric" || found.type === "Currency" || found.type === "Calculated") && found.userinput && found.userinput !== "")
                        found.userinput = parseFloat(found.userinput.replaceAll(",", "")).toFixed(found.decimals);
                    obj["value"] = found.userinput ? found.userinput : "";
                    temp.push(obj);
                    obj = {};
                }else{//column might be from a section that WASNT queried so we will just add a blank one so columns dont shift
                    obj["value"] = ""
                    temp.push(obj);
                    obj = {};
                }
            });
            data.push(temp);
            temp = [];
        });

        return data;
    };

    render() {
        const { excelOpen, columnHeader, tableData, searchName, showlatloncolumns } = this.props;

        if (excelOpen) columnHeader.shift(); //remove actions column
        if (tableData && excelOpen) var cleanData = this.cleanUpData(tableData, columnHeader, showlatloncolumns);
        var trimmedFileName = searchName.replace(/[^\w\s]/gi, "").substring(0, 30);

        if (excelOpen) {
            let coloredColumns = [];
            //put attributes to color header columns
            columnHeader.forEach((c, i) => {
                coloredColumns.push({
                    value: c.label,
                    style: { width: { wch: 40 }, fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } } },
                });
            });

            //combine column header and data into a custom json setup
            let d = [];
            d.push(coloredColumns);
            if (cleanData) cleanData.forEach((c) => d.push(c));
            var multiDataSet = [
                {
                    ySteps: -1, //used as columns header as has a bug for styling so we roll back one from 2 to 1 column wise
                    columns: [], //no headers as bug in lib js file
                    data: d,
                },
            ];
        }
        return (
            <>
                {excelOpen && (
                    <ExcelFile hideElement={true} filename={trimmedFileName}>
                        <ExcelSheet dataSet={multiDataSet} name={trimmedFileName} />
                    </ExcelFile>
                )}
            </>
        );
    }
}
AdvancedRecordTable.displayName = "AdvancedRecordTable";

// This connects the parentRecord tables to allow for deletion ormParentRecordDelete,
AdvancedRecordTable = connect(
    (state, ownProps) => ({
        sections: getAllSections(state, ownProps),
        page: getPageId(state, ownProps),
        allFields: getFields(state, ownProps),
        tableFields: getTableFields(state, ownProps),
        advancedgroups: getAdvancedGroups(state, ownProps),
        advancedsearchID: getAdvancedSearchID(state, ownProps),
        advancedtablefields: getAdvancedTableFields(state, ownProps),
        authState: state.auth, //component wide state prop "authState" gets derived info from database
        permission: getPermission(state, ownProps),
        contact_fields: getAllContactFields(state, ownProps),
    }),
    {
        ...AdvancedSearch.actions,
        ...AdvancedGroup.actions,
        ...AdvancedTableFields.actions,
    }
)(AdvancedRecordTable);

export default withStyles(styles)(withRouter(AdvancedRecordTable));
