import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";

import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import CreateIcon from "@material-ui/icons/Create";
import SearchIcon from "@material-ui/icons/Search";
import TableFooter from "@material-ui/core/TableFooter";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TablePagination from "@material-ui/core/TablePagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import NumberFormat from "react-number-format";

import EnhancedTableHead from "../../common/EnhancedTableHead";
import TextField from "../../common/TextField";
import CustomTableCell from "../../common/TableCell";
import WarningDialog from "../../common/WarningDialog";
import TablePaginationActionsWrapped from "../../common/Paginator";
import ReactSelectAsync from "../../common/ReactSelectAsync";
import ReactSelect from "../../common/ReactSelect";
import Radio from "@material-ui/core/Radio";
import ContactDialog from "../../config/ContactDialog";
import Switch from "../../common/Switch";
import RadioGroup from "../../common/RadioGroup";
import DatePicker from "../../common/DatePicker";
import TimePicker from "../../common/TimePicker";
import { ContactRecord, ContactNonGlobalField, ContactRecordField, RecordSection } from "../models";
import { createSelector } from "../../common/orm";

// CSS in JS styles
const styles = (theme) => ({
    deleteWidth: {
        minWidth: 34,
        width: 34,
        margin: "0 8px 0 0",
    },
    centerAlign: {
        textAlign: "center",
    },
    nowrap: {
        whiteSpace: "nowrap",
    },
    progress: {
        display: "table",
        marginLeft: "auto",
        marginRight: "auto",
    },
});

const findParentRecordId = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["parentRecordId"]),
    (session, parentRecord) => {
        return parentRecord;
    }
);

const findSectionId = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["sectionId"]),
    (session, sectionId) => {
        return sectionId;
    }
);

//get permissions
const getPermission = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (state, ownProps) => parseInt(ownProps.match.params["pageId"]),
    (session, id, pageID) => {
        if (isNaN(id)) {
            return session.Permission.filter((p) => p.page === pageID).toRefArray();
        } else {
            return session.Permission.filter((p) => p.page === id).toRefArray();
        }
    }
);

const getContactFields = createSelector((schema) => {
    return schema.ContactField.all().orderBy("order").toModelArray();
});

const getMasterContacts = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["parentRecordId"]),
    (session, parentRecord) => {
        return session.ContactRecord.filter((cr) => cr.parentrecord.includes(parentRecord)).toModelArray();
    }
);

const getAllNonGlobalFields = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["parentRecordId"]),
    (session, parentRecord) => {
        return session.ContactNonGlobalField.filter((f) => f.parentrecord === parentRecord).toModelArray();
    }
);

class ViewContactsTable extends Component {
    state = {
        pageContact: 0,
        rowsPerPageContact: 25,
        orderContact: "desc",
        orderByContact: null,
        contactDialogOpen: false,
        contactDialogOpen2: false,
        contactEdit: null,
        _loaded: false,
        loadingFields: false,
        loadingFields2: false,
        dialogOpen: false,
        activeContact: null,
        contactLoading: false,
    };
    typingTimer = [];

    componentDidMount() {
        this._loaded = false;
        this.loadRecordFields();
    }

    componentDidUpdate() {
        this.loadRecordFields();
    }

    loadRecordFields = () => {
        const { authState, parentRecordId, sectionId, ormContactRecordLoadDetailChild2, ormContactNonGlobalFieldLoadDetailChild2, ormRecordSectionReloadDynamic } = this.props;
        const { loadingFields, _loaded } = this.state;

        const _this = this;

        // If you load this page from a bookmark it doesn't have an authstate right away
        if (!authState || !authState.user) return;

        // Only do this for the fields view - not the table
        if (!loadingFields && !_loaded) {
            this.setState({ loadingFields: true });
            ormContactRecordLoadDetailChild2("parent" + parentRecordId, function () {
                ormContactNonGlobalFieldLoadDetailChild2(parentRecordId, function () {
                    _this.setState({ loadingFields: false, loadingFields2: false, _loaded: true, _formkey: window.performance.now() });
                });
            });
            ormRecordSectionReloadDynamic("&parent=" + parentRecordId +"&section=" + sectionId + "&count=100&offset=0&order_by=null&order=desc").then(() => {});
        }
    };

    defaultContactValues = () => {
        const { non_global_fields } = this.props;

        var defaultValues = {};
        non_global_fields.forEach(function (rf) {
            if (rf.userinput === "Yes") defaultValues["c_" + rf.id] = true;
            else if (rf.userinput === "No") defaultValues["c_" + rf.id] = false;
            else defaultValues["c_" + rf.id] = rf.userinput;
        });
        return defaultValues;
    };

    handleChangePageContact = (event, page) => {
        this.setState({ pageContact: page });
    };

    handleChangeRowsPerPageContact = (event) => {
        this.setState({ rowsPerPageContact: event.target.value, pageContact: 0 });
    };

    handleRequestSortContact = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        if (order === "desc") {
            this.props.master_contacts.sort((a, b) => {
                var field_value_a = "";
                a.columns.forEach(function (_a) {
                    if (_a.field_name === orderBy) field_value_a = _a.userinput;
                });

                var field_value_b = "";
                b.columns.forEach(function (_b) {
                    if (_b.field_name === orderBy) field_value_b = _b.userinput;
                });

                return field_value_b.toUpperCase() < field_value_a.toUpperCase() ? -1 : 1;
            });
        } else {
            this.props.master_contacts.sort((a, b) => {
                var field_value_a = "";
                a.columns.forEach(function (_a) {
                    if (_a.field_name === orderBy) field_value_a = _a.userinput;
                });

                var field_value_b = "";
                b.columns.forEach(function (_b) {
                    if (_b.field_name === orderBy) field_value_b = _b.userinput;
                });

                return field_value_a.toUpperCase() < field_value_b.toUpperCase() ? -1 : 1;
            });
        }

        this.setState({ order, orderBy });
    };

    // Render the table this is used by landing page tables and tables in AccordionFormRecord component
    render() {
        const {
            classes,
            history,
            authState,
            permission,
            ormContactNonGlobalFieldCreate,
            non_global_fields,
            ormContactNonGlobalFieldUpdate,
            master_contacts,
            contact_fields,
            ormContactRecordUpdate,
            parentRecordId,
            ormContactRecordFieldCreate,
            ormContactRecordFieldUpdate,
            ormContactRecordCreate,
            ormContactRecordLoadDetailChild,
            ormContactRecordLoadDetailChild2,
            ormContactNonGlobalFieldLoadDetailChild2,
        } = this.props;
        const {
            orderByContact,
            orderContact,
            pageContact,
            rowsPerPageContact,
            contactDialogOpen,
            _formkey,
            contactDialogOpen2,
            contactEdit,
            loadingFields,
            loadingFields2,
            dialogOpen,
            activeContact,
            contactLoading,
        } = this.state;

        function MAKE_OPTIONS(table) {
            return table.map((row) => ({
                label: row,
                value: row,
            }));
        }

        if (authState.user) {
            var readOnly;
            var perm = permission.find((p) => {
                if (p.user === authState.user.id) {
                    return p;
                }
                return null;
            });
            if (perm) readOnly = perm.readonly || perm.readonlyexternal ? true : false;
            if (readOnly === undefined) readOnly = false;
        }

        var columnData = [
            { id: "main", numeric: false, label: "Main Contact", allowSort: false, width: "50px" },
            { id: "actions", numeric: false, label: "", allowSort: false, width: "20px" },
        ];
        contact_fields.forEach(function (cf) {
            columnData.push({ id: cf.field_name, numeric: false, label: cf.field_name, allowSort: true });
        });

        return (
            <>
                <Form
                    defaultValues={this.defaultContactValues()}
                    key={parentRecordId + _formkey}
                    getApi={(el) => (this.formApi2 = el)}
                    validateOnSubmit={true}>
                    {(formApi2) => (
                        <form onSubmit={formApi2.submitForm}>
                            <Accordion defaultExpanded={true} style={{ width: "99%" }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    style={{ backgroundColor: "#ddd" }}
                                    classes={{ root: classes.expandedMargin }}
                                    className={classes.titlePanel}>
                                    <table style={{ width: "99%" }}>
                                        <tr>
                                            <td>
                                                <SearchIcon />
                                            </td>
                                            <td style={{ width: "99%" }}>
                                                <Typography className={classes.heading}>Add Existing Master Contact:</Typography>
                                            </td>
                                            {authState && authState.user && authState.user.contacts_read_only === false && (
                                                <td style={{ whiteSpace: "nowrap" }}>
                                                    <Button
                                                        disabled={readOnly}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            this.setState({ contactDialogOpen: true });
                                                        }}>
                                                        Create New Master Contact
                                                    </Button>
                                                </td>
                                            )}
                                        </tr>
                                    </table>
                                </AccordionSummary>  {/*LAYOUT THE CONTACT FIELDS WHICH ARE ALL IN SELECT DROPDOWNS--OCCURS ON THE SECTION IN RECORD */}
                                <AccordionDetails style={{ paddingBottom: 8 }}>
                                    <Grid container spacing={2}>
                                        {contact_fields
                                            .filter((f) => f.is_global)
                                            .map((cf) => {
                                                return (
                                                    <Grid item xs={12} md={6} lg={4} xl={3}>
                                                        <ReactSelectAsync
                                                            field={"_" + cf.field_name}
                                                            label={cf.field_name}
                                                            contact_field={cf.id}
                                                            eventHandle={(value) => {
                                                                if (value !== "") {
                                                                    this.setState({ loadingFields2: true });
                                                                    value.parentrecord.push(parentRecordId);
                                                                    ormContactRecordUpdate({
                                                                        id: value["id"],
                                                                        parentrecord: value.parentrecord,
                                                                    });
                                                                    let i = 0;
                                                                    let j = 0;
                                                                    const comp = this;

                                                                    setTimeout(function () {
                                                                        ormContactRecordLoadDetailChild("parent" + parentRecordId, function () {
                                                                            contact_fields
                                                                                .filter((cf) => !cf.is_global)
                                                                                .forEach(function (cf) {
                                                                                    ormContactNonGlobalFieldCreate({
                                                                                        parentrecord: parentRecordId,
                                                                                        contactrecord: value["id"],
                                                                                        contactfield: cf.id,
                                                                                    }).then((z) => {
                                                                                        j++;
                                                                                        if (i === j) {
                                                                                            ormContactNonGlobalFieldLoadDetailChild2(
                                                                                                parentRecordId,
                                                                                                function () {
                                                                                                    comp.formApi2.setValue("_" + cf.field_name, "");
                                                                                                    comp.setState({
                                                                                                        loadingFields: false,
                                                                                                        loadingFields2: false,
                                                                                                        _loaded: true,
                                                                                                        _formkey: window.performance.now(),
                                                                                                    });
                                                                                                }
                                                                                            );
                                                                                        }
                                                                                    });
                                                                                    i++;
                                                                                });
                                                                            //isnt any non-global fields
                                                                            if (contact_fields.filter((cf) => !cf.is_global).length === 0) {
                                                                                comp.setState({
                                                                                    loadingFields: false,
                                                                                    loadingFields2: false,
                                                                                    _loaded: true,
                                                                                    _formkey: window.performance.now(),
                                                                                });
                                                                            }
                                                                        });
                                                                    }, 500);
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                );
                                            })}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            {master_contacts.length > 25 && (
                                <div
                                    style={{
                                        backgroundColor: "#dddddd",
                                        marginTop: 20,
                                        borderRadius: 1,
                                        maxWidth: "99%",
                                    }}>
                                    <TablePagination
                                        colSpan={columnData.length}
                                        count={master_contacts.length}
                                        rowsPerPage={rowsPerPageContact}
                                        page={pageContact}
                                        onChangePage={this.handleChangePageContact}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPageContact}
                                        ActionsComponent={TablePaginationActionsWrapped}
                                    />
                                </div>
                            )}
                            <div style={{ overflowX: "auto", maxWidth: "99%" }}>
                                <Table>
                                    <EnhancedTableHead
                                        order={orderContact}
                                        orderBy={orderByContact}
                                        onRequestSort={this.handleRequestSortContact}
                                        columnData={columnData}
                                    />
                                    <TableBody>
                                        {(loadingFields || loadingFields2) && (
                                            <TableRow>
                                                <CustomTableCell
                                                    colSpan={columnData.length}
                                                    className={classes.centerAlign}
                                                    style={{ paddingBottom: 16 }}>
                                                    Loading Contacts...
                                                    <CircularProgress size={30} className={classes.progress} />
                                                </CustomTableCell>
                                            </TableRow>
                                        )}
                                        {!loadingFields &&
                                            !loadingFields2 &&
                                            master_contacts
                                                .slice(pageContact * rowsPerPageContact, pageContact * rowsPerPageContact + rowsPerPageContact)
                                                .map((n, tableIndex) => {
                                                    return (
                                                        <TableRow>
                                                            <CustomTableCell className={classes.nowrap}>
                                                                <Radio
                                                                    checked={n.is_main && n.is_main.includes(parentRecordId)}
                                                                    onChange={(value) => {
                                                                        master_contacts.forEach(function (mc) {
                                                                            if (mc.is_main) {
                                                                                var ismain = mc.is_main.replace(parentRecordId.toString() + "|", "");//remove
                                                                                ormContactRecordUpdate({
                                                                                    id: mc.id,
                                                                                    is_main: ismain,
                                                                                });
                                                                            }
                                                                        });
                                                                        var main_string = n.is_main;
                                                                        if (!main_string) main_string = "";
                                                                        ormContactRecordUpdate({
                                                                            id: n.id,
                                                                            is_main: main_string + parentRecordId.toString() + "|",
                                                                            // main_updated: true //keyword arg to trigger looking for an application to update email to send furture grants too
                                                                        });
                                                                    }}
                                                                    value={n.id.toString()}
                                                                    name="main_contact"
                                                                />
                                                            </CustomTableCell>
                                                            <CustomTableCell className={classes.nowrap}>
                                                                <Tooltip title="Edit Master Contact">
                                                                    <Button
                                                                        disabled={readOnly}
                                                                        onClick={() =>
                                                                            this.setState({
                                                                                contactDialogOpen2: true,
                                                                                contactEdit: n.id,
                                                                            })
                                                                        }
                                                                        className={classes.deleteWidth}>
                                                                        <CreateIcon color="inherit" />
                                                                    </Button>
                                                                </Tooltip>
                                                                {authState && authState.user && authState.user.contacts_read_only === false && (
                                                                    <Tooltip title="Remove Contact From Record">
                                                                        <Button
                                                                            disabled={readOnly}
                                                                            onClick={() => {
                                                                                var arrayIndex = n.parentrecord.indexOf(parentRecordId);
                                                                                if (arrayIndex > -1) {
                                                                                    n.parentrecord.splice(arrayIndex, 1);
                                                                                }
                                                                                ormContactRecordUpdate({
                                                                                    id: n.id,
                                                                                    parentrecord: n.parentrecord,
                                                                                });
                                                                            }}
                                                                            className={classes.deleteWidth}>
                                                                            <DeleteIcon color="inherit" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                )}
                                                            </CustomTableCell>
                                                            {contact_fields.map((cf) => {
                                                                var userinput = null;
                                                                n.columns.forEach(function (fi) {
                                                                    if (fi.field_name === cf.field_name) userinput = fi.userinput;
                                                                });
                                                                if (cf.is_global) {
                                                                    if (cf.field_type === "Phone Number") {
                                                                        return (
                                                                            <CustomTableCell>
                                                                                <NumberFormat
                                                                                    format="+1 (###) ###-####"
                                                                                    allowEmptyFormatting
                                                                                    mask="_"
                                                                                    displayType={"text"}
                                                                                    value={userinput ? userinput : "-"}
                                                                                />
                                                                            </CustomTableCell>
                                                                        );
                                                                    } else {
                                                                        return <CustomTableCell>{userinput}</CustomTableCell>;
                                                                    }
                                                                } else {
                                                                    try {
                                                                        var field_info = non_global_fields.find(
                                                                            (ngf) =>
                                                                                ngf.contactfield &&
                                                                                ngf.contactrecord &&
                                                                                ngf.contactfield.id === cf.id &&
                                                                                ngf.contactrecord.id === n.id
                                                                        );
                                                                    } catch (err) {
                                                                        console.log(non_global_fields);
                                                                        console.log(cf.id);
                                                                        console.log(n.id);
                                                                        return <CustomTableCell>Reload...</CustomTableCell>;
                                                                    }
                                                                    if (!field_info) return null;
                                                                    return (
                                                                        <CustomTableCell style={{minWidth:150}}>
                                                                            {cf.field_type === "Text" && (
                                                                                <TextField
                                                                                    label=""
                                                                                    field={"c_" + field_info.id}
                                                                                    disabled={readOnly}
                                                                                    fullWidth
                                                                                    margin="none"
                                                                                    eventHandle={(val) => {
                                                                                        clearTimeout(this.typingTimer[field_info.id]);
                                                                                        this.typingTimer[field_info.id] = setTimeout(function () {
                                                                                            ormContactNonGlobalFieldUpdate({
                                                                                                id: field_info.id,
                                                                                                userinput: val,
                                                                                            });
                                                                                        }, 1000);
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            {cf.field_type === "Email" && (
                                                                                <TextField
                                                                                    type="email"
                                                                                    label=""
                                                                                    field={"c_" + field_info.id}
                                                                                    disabled={readOnly}
                                                                                    fullWidth
                                                                                    margin="none"
                                                                                    pattern=".+@globex.com"
                                                                                    eventHandle={(val) => {
                                                                                        clearTimeout(this.typingTimer[field_info.id]);
                                                                                        this.typingTimer[field_info.id] = setTimeout(function () {
                                                                                            ormContactNonGlobalFieldUpdate({
                                                                                                id: field_info.id,
                                                                                                userinput: val,
                                                                                            });
                                                                                        }, 1000);
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            {cf.field_type === "Phone Number" && (
                                                                                <TextField
                                                                                    label=""
                                                                                    field={"c_" + field_info.id}
                                                                                    disabled={readOnly}
                                                                                    fullWidth
                                                                                    margin="none"
                                                                                    eventHandle={(val) => {
                                                                                        clearTimeout(this.typingTimer[field_info.id]);
                                                                                        this.typingTimer[field_info.id] = setTimeout(function () {
                                                                                            ormContactNonGlobalFieldUpdate({
                                                                                                id: field_info.id,
                                                                                                userinput: val,
                                                                                            });
                                                                                        }, 1000);
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            {cf.field_type === "Yes/No" && (
                                                                                <Switch
                                                                                    field={"c_" + field_info.id}
                                                                                    name={field_info.id}
                                                                                    label=""
                                                                                    disabled={readOnly}
                                                                                    eventHandle={(val) => {
                                                                                        ormContactNonGlobalFieldUpdate({
                                                                                            id: field_info.id,
                                                                                            userinput: val ? "Yes" : "No",
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            {(cf.field_type === "Drop Down"  || cf.field_type === "Multi Select") && (
                                                                                <ReactSelect
                                                                                    field={"c_" + field_info.id}
                                                                                    options={MAKE_OPTIONS(cf.values_csv.split(","))}
                                                                                    label={null}
                                                                                    isMulti={cf.field_type === "Drop Down" ? false : true}
                                                                                    fullWidth
                                                                                    disabled={readOnly}
                                                                                    eventHandle={(val) => {
                                                                                        ormContactNonGlobalFieldUpdate({
                                                                                            id: field_info.id,
                                                                                            userinput: val,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            {cf.field_type === "Date" && (
                                                                                <DatePicker
                                                                                    field={"c_" + field_info.id}
                                                                                    label={null}
                                                                                    fullWidth
                                                                                    disabled={readOnly}
                                                                                    eventHandle={(val) => {
                                                                                        ormContactNonGlobalFieldUpdate({
                                                                                            id: field_info.id,
                                                                                            userinput: val,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            {cf.field_type === "Time" && (
                                                                                <TimePicker
                                                                                    field={"c_" + field_info.id}
                                                                                    label={null}
                                                                                    autoPopulate={cf.autopopulate}
                                                                                    disabled={readOnly}
                                                                                    eventHandle={(val) => {
                                                                                        ormContactNonGlobalFieldUpdate({
                                                                                            id: field_info.id,
                                                                                            userinput: val,
                                                                                        });
                                                                                    }}
                                                                                    fullWidth
                                                                                />
                                                                            )}
                                                                            {cf.field_type === "Currency" && (     
                                                                                <TextField
                                                                                    field={"c_" + field_info.id}
                                                                                    label={null}
                                                                                    useTextFormat={true}
                                                                                    fullWidth
                                                                                    margin="none"
                                                                                    disabled={readOnly}
                                                                                    eventHandle={(val) => {
                                                                                        ormContactNonGlobalFieldUpdate({
                                                                                            id: field_info.id,
                                                                                            userinput: val,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            {cf.field_type === "Numeric" && (     
                                                                                <TextField
                                                                                    field={"c_" + field_info.id}
                                                                                    label={null}
                                                                                    useNumberFormat
                                                                                    fullWidth
                                                                                    margin="none"
                                                                                    disabled={readOnly}
                                                                                    eventHandle={(val) => {
                                                                                        ormContactNonGlobalFieldUpdate({
                                                                                            id: field_info.id,
                                                                                            userinput: val,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            {cf.field_type === "Radio" && (  
                                                                                <RadioGroup
                                                                                    field={"c_" + field_info.id}
                                                                                    label={null}
                                                                                    name={field_info.id}
                                                                                    fullWidth
                                                                                    options={MAKE_OPTIONS(cf.values_csv.split(","))}
                                                                                    alignment={true}
                                                                                    disabled={readOnly}
                                                                                    eventHandle={(val) => {
                                                                                        ormContactNonGlobalFieldUpdate({
                                                                                            id: field_info.id,
                                                                                            userinput: val,
                                                                                        });
                                                                                    }}
                                                                                />  
                                                                            )}
                                                                        </CustomTableCell>
                                                                    );
                                                                }
                                                            })}
                                                        </TableRow>
                                                    );
                                                })}
                                        {!loadingFields && master_contacts.length < 1 && (
                                            <TableRow>
                                                <CustomTableCell colSpan={columnData.length} className={classes.centerAlign}>
                                                    No Contacts Added
                                                </CustomTableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    {master_contacts.length > 25 && (
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    colSpan={columnData.length}
                                                    count={master_contacts.length}
                                                    rowsPerPage={rowsPerPageContact}
                                                    page={pageContact}
                                                    onChangePage={this.handleChangePageContact}
                                                    onChangeRowsPerPage={this.handleChangeRowsPerPageContact}
                                                    ActionsComponent={TablePaginationActionsWrapped}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    )}
                                </Table>
                            </div>
                        </form>
                    )}
                </Form>

                <ContactDialog
                    authState={authState}
                    closeDialog={() => this.setState({ dialogOpen: false, activeContact: null, _loaded: false })}
                    activeContact={activeContact}
                    ormContactRecordFieldUpdate={ormContactRecordFieldUpdate}
                    dialogOpen={dialogOpen}
                />
                <WarningDialog
                    confirmAction={() => {
                        var comp = this;
                        this.setState({ contactLoading: true });
                        ormContactRecordCreate({
                            city_id: authState.user.city_id,
                            parentrecord: [parentRecordId],
                        }).then((record) => {
                            let i = 0;
                            let j = 0;
                            contact_fields.forEach(function (cf) {
                                if (cf.is_global) {
                                    ormContactRecordFieldCreate({
                                        contactrecord_id: record.id,
                                        contactfield_id: cf.id,
                                    }).then((z) => {
                                        j++;
                                        if (i === j) {
                                            ormContactRecordLoadDetailChild2(record.id, function (what) {
                                                ormContactNonGlobalFieldLoadDetailChild2(parentRecordId, function () {
                                                    comp.setState({
                                                        dialogOpen: true,
                                                        activeContact: what[0],
                                                        contactDialogOpen: false,
                                                        _loaded: false,
                                                        contactLoading: false,
                                                    });
                                                });
                                            });
                                        }
                                    });
                                    i++;
                                } else {
                                    ormContactNonGlobalFieldCreate({
                                        parentrecord: parentRecordId,
                                        contactrecord: record.id,
                                        contactfield: cf.id,
                                    }).then((z) => {
                                        j++;
                                        if (i === j) {
                                            ormContactRecordLoadDetailChild2(record.id, function (what) {
                                                ormContactNonGlobalFieldLoadDetailChild2(parentRecordId, function () {
                                                    comp.setState({
                                                        dialogOpen: true,
                                                        activeContact: what[0],
                                                        contactDialogOpen: false,
                                                        _loaded: false,
                                                        contactLoading: false,
                                                    });
                                                });
                                            });
                                        }
                                    });
                                    i++;
                                }
                            });
                        });
                    }}
                    cancelAction={() => this.setState({ contactDialogOpen: false })}
                    open={contactDialogOpen}
                    title="New Master Contact"
                    contactLoading={contactLoading}
                    confirmText="Ok"
                    cancelText="Cancel"
                    text="You are about to create a brand new contact within the master contact list. Make sure the contact you are about to add does not already exist."
                />
                <WarningDialog
                    confirmAction={() => {
                        history.push("/contacts?edit=" + contactEdit);
                    }}
                    cancelAction={() => this.setState({ contactDialogOpen2: false })}
                    open={contactDialogOpen2}
                    title="Edit Master Contact"
                    confirmText="Ok"
                    cancelText="Cancel"
                    text="You are about to edit a master contact. Editing a master contact will update the contact across every record."
                />
            </>
        );
    }
}
ViewContactsTable.displayName = "ViewContactsTable";

ViewContactsTable = connect(
    (state, ownProps) => ({
        authState: state.auth,
        permission: getPermission(state, ownProps), // ok
        contact_fields: getContactFields(state, ownProps), // ok
        master_contacts: getMasterContacts(state, ownProps), // ?
        non_global_fields: getAllNonGlobalFields(state, ownProps), // probably ok
        parentRecordId: findParentRecordId(state, ownProps), // ok
        sectionId: findSectionId(state, ownProps), // ok
    }),
    {
        ...ContactRecord.actions,
        ...ContactNonGlobalField.actions,
        ...ContactRecordField.actions,
        ...RecordSection.actions
    }
)(ViewContactsTable);

export default withStyles(styles)(withRouter(ViewContactsTable));
