import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "react-form";
import { withRouter, Link } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import SettingsIcon from "@material-ui/icons/Settings";
import Close from "@material-ui/icons/Close";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import CreateIcon from "@material-ui/icons/Create";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteIcon from "@material-ui/icons/Delete";
import Table from "@material-ui/core/Table";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CloudDownload from "@material-ui/icons/CloudDownload";
import CloudUpload from "@material-ui/icons/CloudUpload";
import NumberFormat from "react-number-format";

import AppContainer from "../common/AppContainer";
import Select from "../common/Select";
import TextField from "../common/TextField";
import ContactDialog from "./ContactDialog";
import { createSelector } from "../common/orm";
import Switch from "../common/Switch";
import * as authActions from "../auth/actions";
import WarningDialog from "../common/WarningDialog";
import FileInput from "../common/FileInput";
import HelpLabel from "../common/HelpLabel";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";
import CustomTableCell from "../common/TableCell";
import { ContactField, ContactRecord, ContactRecordField } from "../page/models";

const styles = (theme) => ({
    marginLeft: {
        marginLeft: 16,
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8,
        marginTop: 2,
        marginBottom: 2,
    },
    centerAlign: {
        textAlign: "center",
    },
    flex: {
        flex: 1,
    },
    nowrap: {
        whiteSpace: "nowrap",
    },
    minWidth2: {
        width: 400,
    },
    floatLeft: {
        float: "left",
    },
    floatRight: {
        float: "right",
    },
    button: {
        marginTop: 32,
        marginLeft: "auto",
        marginRight: "auto",
        display: "table",
    },
    ellipsis: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 15,
        WebkitBoxOrient: "vertical",
    },
});

const getContactFields = createSelector((schema) => {
    return schema.ContactField.all().orderBy("order").toModelArray();
});

const getMasterContacts = createSelector((schema) => {
    return schema.ContactRecord.all().toModelArray();
});

class Contacts extends Component {
    state = {
        order: "desc",
        orderBy: null,
        rowsPerPage: 25,
        page: 0,
        dialogOpen: false,
        activeContact: null,
        deleteDialogOpen: false,
        deleteDialogConfirmAction: null,
        open: false,
        totalCount: 0,
        tableLoading: false,
        buttonLoading: false,
        searchLoading: false,
        searchedContacts: [],
        // goBack: false
    };

    componentDidMount() {
        const { authState, /*ormContactRecordCreate, , ormContactRecordFieldCreate, contact_fields,*/ ormContactRecordLoadDetailChild } = this.props;
        const params = new URLSearchParams(this.props.location.search);
        //const filters = params.get("new");
        const filters2 = params.get("edit");
        var comp = this;
        // Not used?
        /*if (filters && filters === "true") {
            this.setState({buttonLoading: true});
            ormContactRecordCreate({
                city_id: authState.user.city_id
            }).then(record => {
                let i = 0;
                let j = 0;
                contact_fields.forEach(function(cf) {
                    if (cf.is_global) {
                        ormContactRecordFieldCreate({
                            contactrecord_id: record.id,
                            contactfield_id: cf.id
                        }).then(z => {
                            j++;
                            if (i === j) {
                                // Grab the newly created columns
                                comp.loadTotalRecords();
                                ormContactRecordLoadDetailChild(record.id, function(what) {
                                    comp.setState({ dialogOpen: true, activeContact: what[0], buttonLoading: false });
                                });
                            }
                        });
                        i++;
                    }
                });
            });
        } else */ if (filters2) {
            this.setState({ buttonLoading: true });
            ormContactRecordLoadDetailChild(parseInt(filters2), function (what) {
                comp.setState({ dialogOpen: true, activeContact: what[0], buttonLoading: false /* goBack: true*/ });
            });
        }

        // Reload the fields here because if the is_global flag is changed in the update viewset there is no way to call reload after an update
        if (authState && authState.user) {
            this.loadTotalRecords();
            this.dynamicLoading(0, 25, "asc", null);
        }
    }

    componentDidUpdate(prevProps) {
        const { authState } = this.props;
        if (authState && authState !== prevProps.authState) {
            this.loadTotalRecords();
            this.dynamicLoading(0, 25, "asc", null);
        }
    }

    loadTotalRecords = (_filters) => {
        const { authState, history, authLogout } = this.props;
        var component = this;

        fetch("/count/contacts", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token,
            },
        }).then((e) => {
            if (e.status === 401) {
                history.push("/");
                authLogout();
            } else if (e.status !== 500) {
                e.json().then((data) => {
                    component.setState({ totalCount: data.totalCount });
                });
            }
        });
    };

    dynamicLoading = (_page, _rowsPerPage, _order, _orderBy) => {
        const { ormContactRecordReloadDynamic } = this.props;
        var { order, orderBy, rowsPerPage, page } = this.state;
        var component = this;
        // These if/elses are kind messy but apparently you can't overwrite _page, etc.
        var p, r, o, ob;
        if (_page !== undefined) p = _page;
        else p = page;
        if (_rowsPerPage !== undefined) r = _rowsPerPage;
        else r = rowsPerPage;
        if (_order !== undefined) o = _order;
        else o = order;
        if (_orderBy !== undefined) ob = _orderBy;
        else ob = orderBy;

        var str = "";

        this.setState({ tableLoading: true });
        str += "&contacts=true&count=" + r + "&offset=" + r * p + "&order=" + o + "&order_by=" + encodeURIComponent(ob);
        ormContactRecordReloadDynamic(str).then(() => {
            component.setState({ tableLoading: false });
        });
    };

    searchForm(values) {
        var { authState, history, authLogout } = this.props;

        var filt1 = values.filter1;
        if (filt1) filt1 = filt1.trim();
        var filt2 = values.filter1;
        if (filt2) filt2 = filt2.trim();
        var filt3 = values.filter1;
        if (filt3) filt3 = filt3.trim();

        if (!filt1 && !filt2 && !filt3) {
            this.setState({ searchLoading: false, searchedContacts: [] });
            return;
        }

        this.setState({ searchLoading: true, results: [] });
        fetch("/contacts/search", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token,
            },
            body: JSON.stringify(values),
        }).then((e) => {
            if (e.status === 401) {
                history.push("/");
                authLogout();
            } else {
                e.json().then((data) => {
                    this.setState({ searchLoading: false, searchedContacts: data });
                });
            }
        });
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
        this.dynamicLoading(page);
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
        this.dynamicLoading(0, event.target.value);
    };

    handleRequestSort = (event, property, full_property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        // Reset to page/rowsperpage if this happens
        this.setState({ order, orderBy, page: 0, rowsPerPage: 25 });
        this.dynamicLoading(0, 25, order, orderBy);
    };

    render() {
        const {
            contact_fields,
            classes,
            master_contacts,
            ormContactRecordCreate,
            authState,
            ormContactRecordFieldCreate,
            ormContactRecordDelete,
            ormContactRecordFieldUpdate,
            ormContactRecordLoadDetailChild,
            authLogout,
            // history
        } = this.props;
        const {
            order,
            orderBy,
            page,
            rowsPerPage,
            dialogOpen,
            activeContact,
            deleteDialogOpen,
            deleteDialogConfirmAction,
            open,
            totalCount,
            tableLoading,
            buttonLoading,
            searchLoading,
            searchedContacts,
            // goBack
        } = this.state;

        var columnData = [{ id: "actions", numeric: false, label: "Actions", allowSort: false, width: "20px" }];
        contact_fields.forEach(function (cf) {
            if (cf.is_global) columnData.push({ id: cf.field_name, numeric: false, label: cf.field_name, allowSort: true });
        });

        var searching = searchedContacts.length > 0 ? searchedContacts : master_contacts;
        var searchingcount = searchedContacts.length > 0 ? searchedContacts.length : totalCount;

        return (
            <AppContainer authenticated>
                <Grid container spacing={2}>
                    {contact_fields.length === 0 &&
                        authState &&
                        authState.user &&
                        authState.user.is_city_admin === true &&
                        authState.user.contacts_read_only === false && (
                            <Grid item xs={12}>
                                <Button component={Link} to="/contacts/config" variant="contained" color="primary">
                                    <SettingsIcon />
                                    &nbsp;&nbsp;Configure Contact Fields
                                </Button>
                            </Grid>
                        )}
                    {contact_fields.length > 0 && (
                        <>
                            <Grid item xs={12}>
                                {buttonLoading && (
                                    <CircularProgress style={{ marginLeft: 100, marginBottom: -24 }} size={30} className={classes.progress} />
                                )}
                                {!buttonLoading && authState && authState.user && authState.user.contacts_read_only === false && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            var comp = this;
                                            this.setState({ buttonLoading: true });
                                            ormContactRecordCreate({
                                                city_id: authState.user.city_id,
                                            }).then((record) => {
                                                let i = 0;
                                                let j = 0;
                                                contact_fields.forEach(function (cf) {
                                                    if (cf.is_global) {
                                                        ormContactRecordFieldCreate({
                                                            contactrecord_id: record.id,
                                                            contactfield_id: cf.id,
                                                        }).then((z) => {
                                                            j++;
                                                            if (i === j) {
                                                                // Grab the newly created columns
                                                                comp.loadTotalRecords();
                                                                ormContactRecordLoadDetailChild(record.id, function (what) {
                                                                    comp.setState({ dialogOpen: true, activeContact: what[0], buttonLoading: false });
                                                                });
                                                            }
                                                        });
                                                        i++;
                                                    }
                                                });
                                            });
                                        }}>
                                        <AddCircleOutlineIcon />
                                        &nbsp;&nbsp;Add New Master Contact
                                    </Button>
                                )}
                                {authState && authState.user && authState.user.is_city_admin === true && authState.user.contacts_read_only === false && (
                                    <>
                                        <Button style={{ float: "right" }} component={Link} to="/contacts/config" variant="contained" color="primary">
                                            <SettingsIcon />
                                            &nbsp;&nbsp;Configure Contact Fields
                                        </Button>{" "}
                                        <Button
                                            style={{ float: "right", marginRight: 8 }}
                                            onClick={() => this.setState({ open: true })}
                                            variant="contained"
                                            color="primary">
                                            <CloudUpload />
                                            &nbsp;&nbsp;Import Contacts
                                        </Button>
                                    </>
                                )}

                                <Button
                                    style={{ float: "right", marginRight: 8 }}
                                    onClick={() => window.open("/excel/contacts/export/" + authState.user.auth_token)}
                                    variant="contained"
                                    color="primary">
                                    <CloudDownload />
                                    &nbsp;&nbsp;Export Contacts
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Form
                                    defaultValues={{ type: "AND" }}
                                    dontValidateOnMount={true}
                                    validateOnSubmit={true}
                                    onSubmit={(values) => this.searchForm(values)}>
                                    {(formApi) => (
                                        <form onSubmit={formApi.submitForm}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6} md={4} lg={3} xl={2}>
                                                    <Select
                                                        label="Search Type"
                                                        options={[
                                                            { label: "AND", value: "AND" },
                                                            { label: "OR", value: "OR" },
                                                        ]}
                                                        field="type"
                                                        fullWidth>
                                                        <HelpLabel
                                                            inputLabel="Search Type"
                                                            showLabel={true}
                                                            helpText={
                                                                <div>
                                                                    An 'AND' search will only return programs that match <b>all</b> of the search
                                                                    terms.
                                                                    <br />
                                                                    An 'OR' search will return all programs that match <b>at least one</b> of the
                                                                    search terms.
                                                                    <br />
                                                                    <br />
                                                                    Ex: Return results with... [Search Term 1] <b>AND</b> [Search Term 2]
                                                                    <br />
                                                                    [Search Term 1] <b>OR</b> [Search Term 2]
                                                                </div>
                                                            }
                                                        />
                                                    </Select>
                                                    {searchLoading && <CircularProgress size={24} className={classes.button} />}
                                                    {!searchLoading && (
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <Button
                                                                            variant="contained"
                                                                            type="submit"
                                                                            color="primary"
                                                                            style={{ marginTop: 32, marginRight: 32 }}>
                                                                            Search
                                                                        </Button>
                                                                    </td>
                                                                    <td>
                                                                        <Button
                                                                            variant="contained"
                                                                            type="button"
                                                                            color="primary"
                                                                            onClick={() => {
                                                                                this.setState({ searchLoading: false, searchedContacts: [] });
                                                                                formApi.resetAll();
                                                                            }}
                                                                            style={{ marginTop: 32 }}>
                                                                            Reset
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    )}
                                                </Grid>
                                                <Grid item xs={6} md={4} lg={3} xl={2}>
                                                    <TextField
                                                        field="filter1"
                                                        label="Search Term 1"
                                                        filterInput
                                                        fullWidth
                                                        style={{ marginBottom: 3 }}
                                                    />
                                                    <TextField
                                                        field="filter2"
                                                        label="Search Term 2"
                                                        filterInput
                                                        fullWidth
                                                        style={{ marginBottom: 3 }}
                                                    />
                                                    <TextField
                                                        field="filter3"
                                                        label="Search Term 3"
                                                        filterInput
                                                        fullWidth
                                                        style={{ marginBottom: 3 }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </form>
                                    )}
                                </Form>
                            </Grid>
                            <Grid item xs={12}>
                                {searchingcount > 25 && (
                                    <div
                                        style={{
                                            backgroundColor: "#dddddd",
                                            marginTop: 20,
                                            borderRadius: 1,
                                        }}>
                                        <TablePagination
                                            colSpan={columnData.length}
                                            count={searchingcount}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActionsWrapped}
                                        />
                                    </div>
                                )}
                                <div style={{ overflowX: "auto", minWidth: "100%" }}>
                                    <Table className={classes.table} stickyHeader>
                                        <EnhancedTableHead
                                            columnData={!tableLoading ? columnData : []}
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={this.handleRequestSort}
                                        />
                                        <TableBody>
                                            {tableLoading && (
                                                <TableRow>
                                                    <CustomTableCell colSpan={0} className={classes.centerAlign} style={{ paddingBottom: 16 }}>
                                                        Loading Table Records...
                                                        <CircularProgress size={30} className={classes.progress} />
                                                    </CustomTableCell>
                                                </TableRow>
                                            )}
                                            {!tableLoading &&
                                                searching.map((n) => {
                                                    return (
                                                        <TableRow key={n.id} hover>
                                                            <CustomTableCell className={classes.nowrap}>
                                                                <Tooltip title="Edit Contact">
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => this.setState({ dialogOpen: true, activeContact: n })}
                                                                        className={classes.deleteWidth}>
                                                                        <CreateIcon color="primary" />
                                                                    </Button>
                                                                </Tooltip>
                                                                {authState && authState.user && authState.user.contacts_read_only === false && (
                                                                    <Tooltip title="Delete Contact">
                                                                        <Button
                                                                            color="primary"
                                                                            onClick={() =>
                                                                                this.setState({
                                                                                    deleteDialogOpen: true,
                                                                                    deleteDialogConfirmAction: n.id,
                                                                                })
                                                                            }
                                                                            className={classes.deleteWidth}>
                                                                            <DeleteIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                )}
                                                            </CustomTableCell>
                                                            {contact_fields
                                                                .filter((cf) => cf.is_global)
                                                                .map((cf) => {
                                                                    var userinput = null;
                                                                    n.columns.forEach(function (fi) {
                                                                        if (fi.field_name === cf.field_name) userinput = fi.userinput;
                                                                    });

                                                                    if (cf.field_type === "Phone Number") {
                                                                        return (
                                                                            <CustomTableCell key={cf.id}>
                                                                                <NumberFormat
                                                                                    format="+1 (###) ###-####"
                                                                                    allowEmptyFormatting
                                                                                    mask="_"
                                                                                    displayType={"text"}
                                                                                    value={userinput ? userinput : "-"}
                                                                                />
                                                                            </CustomTableCell>
                                                                        );
                                                                    } else if (cf.field_type === "Yes/No") {
                                                                        return (
                                                                            <CustomTableCell key={cf.id}>
                                                                                {userinput === "" || userinput === "false" ? "No" : "Yes"}
                                                                            </CustomTableCell>
                                                                        );
                                                                    } else {
                                                                        if (userinput && userinput.length > 250) {
                                                                            return (
                                                                                <Tooltip title={userinput}>
                                                                                    <CustomTableCell key={cf.id}>
                                                                                        <div className={classes.ellipsis}>
                                                                                            {userinput}
                                                                                        </div>
                                                                                    </CustomTableCell>
                                                                                </Tooltip>
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <CustomTableCell key={cf.id}>
                                                                                    {userinput}
                                                                                </CustomTableCell>
                                                                            )
                                                                        }
                                                                    }
                                                                })}
                                                        </TableRow>
                                                    );
                                                })}
                                            {(searchingcount < 1 || searching.length < 1) && (
                                                <TableRow>
                                                    <CustomTableCell colSpan={columnData.length} className={classes.centerAlign}>
                                                        No Contacts Found
                                                    </CustomTableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                        {searchingcount > 25 && (
                                            <TableFooter>
                                                <TableRow>
                                                    <TablePagination
                                                        colSpan={columnData.length}
                                                        count={searchingcount}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        onChangePage={this.handleChangePage}
                                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                        ActionsComponent={TablePaginationActionsWrapped}
                                                    />
                                                </TableRow>
                                            </TableFooter>
                                        )}
                                    </Table>
                                </div>
                            </Grid>
                        </>
                    )}
                </Grid>
                <ContactDialog
                    closeDialog={() => {
                        // Ideally we would refresh the table but the 2 second delay is janky
                        /*const comp = this;
                        setTimeout(function() {
                            comp.dynamicLoading(page, rowsPerPage, order, orderBy);
                        }, 2000);*/
                        // if (goBack) history.goBack();
                        this.loadTotalRecords();
                        this.dynamicLoading(page,rowsPerPage, order, null);

                        this.setState({ dialogOpen: false, activeContact: null });
                    }}
                    authState={authState}
                    activeContact={activeContact}
                    ormContactRecordFieldUpdate={ormContactRecordFieldUpdate}
                    dialogOpen={dialogOpen}
                />
                <WarningDialog
                    confirmAction={() => {
                        ormContactRecordDelete(deleteDialogConfirmAction);
                        const comp = this;
                        setTimeout(function () {
                            comp.loadTotalRecords();
                        }, 1000);
                        this.setState({ deleteDialogOpen: false });
                    }}
                    cancelAction={() => this.setState({ deleteDialogOpen: false })}
                    open={deleteDialogOpen}
                    title="Delete Contact"
                    text="Are you sure you wish to delete this contact? Deleting this contact will also remove it from any other record."
                />
                <FormUpload
                    authState={authState}
                    classes={classes}
                    authLogout={authLogout}
                    open={open}
                    returnDialog={() => {
                        this.loadTotalRecords();
                        this.setState({ open: false });
                    }}
                />
            </AppContainer>
        );
    }
}

class FormUpload extends Component {
    state = {
        loading: false,
        results: null,
    };
    formKey = window.performance.now();

    uploadDocument = (values, formApi) => {
        var { authState, history, authLogout } = this.props;
        this.setState({ loading: true, results: null });
        fetch("/excel/contacts/import", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token,
            },
            body: JSON.stringify(values),
        }).then((e) => {
            if (e.status === 401) {
                history.push("/");
                authLogout();
            } else if (e.status === 500) {
                let msg = "<b>Upload Failed</b><br/>";
                this.setState({ loading: false, results: msg });
            } else {
                var msg;
                e.json()
                    .then((data) => {
                        msg = "<b>Upload Results:</b><br/>";
                        data.message.forEach(function (m) {
                            msg += m.name + ": " + m.message + "<br/>";
                        });
                        formApi.resetAll();
                        this.formKey = window.performance.now();
                    })
                    .then(() => {
                        this.setState({ loading: false, results: msg });
                    });
            }
        });
    };

    errorValidator = (values) => {
        const isRequired = (val) => {
            return !val ? "Required" : null;
        };
        var valObj = {
            file: isRequired(values.file),
        };

        return valObj;
    };

    closeDialog = () => {
        const { returnDialog } = this.props;

        this.setState({ results: null, loading: false });
        returnDialog();
    };

    render() {
        const { classes, open } = this.props;
        const { loading, results } = this.state;

        return (
            <Dialog open={open} classes={{ paper: classes.minWidth2 }}>
                <Toolbar>
                    <Typography variant="h5" className={classes.flex}>
                        Upload Master Contacts
                    </Typography>
                    <IconButton aria-label="Close Dialog" onClick={this.closeDialog}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <DialogContentText>
                        <Form
                            key={this.formKey}
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            validateError={this.errorValidator}
                            onSubmit={(values, e, formApi) => this.uploadDocument(values, formApi)}>
                            {(formApi) => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FileInput
                                                field="file"
                                                id="file_upl"
                                                fullWidth
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Field Spreadsheet"
                                                        helpText="Upload an Excel spreadsheet (.xlsx) containing your contacts on each row. The column names should match the spreadsheet generated from the 'Export Contacts' button."
                                                    />
                                                }
                                            />
                                            <Switch
                                                field="delete_current"
                                                name="delete_current"
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Remove All Existing Master Contacts?"
                                                        helpText="Select Yes to overwrite any existing contacts prior to uploading these new fields. Warning: Deletion of contacts is permanent and contacts will be removed from existing records."
                                                    />
                                                }
                                            />
                                            {loading && (
                                                <CircularProgress size={24} style={{ display: "table", marginLeft: "auto", marginRight: "auto" }} />
                                            )}
                                            {results && (
                                                <Typography style={{ textAlign: "center" }}>
                                                    <div dangerouslySetInnerHTML={{ __html: results }} />
                                                </Typography>
                                            )}
                                            {!loading && (
                                                <>
                                                    <Button type="submit" variant="raised" color="primary" className={classes.floatLeft}>
                                                        Upload
                                                    </Button>
                                                    <Button
                                                        onClick={this.closeDialog}
                                                        type="button"
                                                        variant="raised"
                                                        color="primary"
                                                        className={classes.floatRight}>
                                                        Close
                                                    </Button>
                                                </>
                                            )}
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}
Contacts.displayName = "Contacts";

Contacts = connect(
    (state, ownProps) => ({
        contact_fields: getContactFields(state, ownProps),
        master_contacts: getMasterContacts(state, ownProps),
        authState: state.auth,
    }),
    {
        ...ContactField.actions,
        ...ContactRecordField.actions,
        ...ContactRecord.actions,
        ...authActions,
    }
)(Contacts);

export default withStyles(styles)(withRouter(Contacts));
