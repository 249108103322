//REACTSELECT
import React from "react";
import AsyncSelect from "react-select/async";
import { FormField } from "react-form";

//styles for select component.
const colourStyles = {
    control: (styles, state) => ({
        //1st param is default styles, to modify use 2nd prop 'state' and modify
        // refer to https://react-select.com/styles#overriding-the-theme
        ...styles,
        backgroundColor: "white",
        margin: "-1.5px",
        color: "#262626",
        minHeight: 32,
        marginRight: "0 !important",
        fontFamily: "'Avenir Next','Helvetica Neue',Helvetica,Arial,sans-serif",
        fontSize: 14
    }),

    singleValue: (styles, state) => ({
        ...styles,
        fontSize: "15px",
        fontWeight: 500,
        color: "#262626 !important",
        fontFamily: "'Source Sans Pro', sans-serif",
    }),

    container: (styles, state) => ({
        ...styles,
        borderRadius: "4px",
        borderColor: state.isFocused && "#a5db7a",
        boxShadow: state.isFocused && "0 0 0 0.2rem rgba(0,123,255,.25)",
    }),
    //for overriding optgroups styling of header
    groupHeading: () => ({
        color: "#515151",
        backgroundColor: "#d3d3d3",
        paddingLeft: "1%",
        paddingTop: 8,
        height: 30,
        fontWeight: 500,
    }),
    group: () => ({
        //each group styling, no passing of params as we override everything
        paddingTop: 0,
        paddingBottom: 0,
    }),
    MenuList: () => ({
        paddingTop: 0,
    }),
    placeholder: (styles) => ({
        ...styles,
        fontFamily: '"Avenir Next","Helvetica Neue",Helvetica,Arial,sans-serif',
       color: "hsl(0,0%,80%)",
    }),
};

class SelectWrapper extends React.Component {
    handleChange = (event) => {
        const { eventHandle } = this.props;

        if (!event || event.length === 0) {
            if (eventHandle) eventHandle("");
            return;
        }
        if (eventHandle) eventHandle(event.searchvalue);
    };

    loadOptions = (inputValue, callback) => {
        const { authState } = this.props;

        clearTimeout(this.typingTimer);
        if (inputValue.length > 2) {
            this.typingTimer = setTimeout(function () {
                return fetch("mapsearch/filter", {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Token " + authState.user.auth_token,
                    },
                    body: JSON.stringify({"inputValue": inputValue})
                })
                .then((result) => result.json())
                .then((data) => {
                    callback(data);
                });
            }, 1000);
        } else {
            callback(null)
        }
    };

    loadMessage = (e) => {
        return "Begin Typing to Load Results";
    };

    render() {
        const { placeholder } = this.props;
        return (
            <div>
                <AsyncSelect
                    isClearable
                    isSearchable
                    onChange={this.handleChange}
                    styles={colourStyles}
                    value={null}
                    loadOptions={this.loadOptions}
                    isLoading={false}
                    noOptionsMessage={this.loadMessage}
                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                    placeholder={placeholder}
                />
            </div>
        );
    }
}
const ReactSelect = FormField(SelectWrapper);

export default ReactSelect;
