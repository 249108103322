import React from "react";
// import { Form as Formm } from "react-form";
import classNames from "classnames";
import { loadModules } from "esri-loader";
import { arcgisToGeoJSON } from "@esri/arcgis-to-geojson-utils";

import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
// import AddLocation from "@material-ui/icons/AddLocation";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline"; 
import DeleteForever from "@material-ui/icons/DeleteForever";
// import Cancel from "@material-ui/icons/Cancel";

// import WarningDialog from "../../common/WarningDialog";
// import TextField from "../../common/TextField";

const styles = (theme) => ({
    root: { zIndex: "999", width: "280px", backgroundColor: "#fff" },
    buttons: {
        width: 250,
        margin: "0px 16px 16px 16px",
        height: 54,
    },
    blueColor: {
        backgroundColor: "#3c8ac7",
    },
    whiteText: {
        color: "white",
    },
    rightMargin: {
        marginRight: "0px !important",
    },
    deleteToggled: {
        border: "2px solid red",
        backgroundColor: "#005d96",
    },
});

class MapEditing extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            drawActive: false, // Used to prevent map interaction on top of the expansion panels
            latLong: false, // Whether the lat/lon form is opened
            editing: false, // If we are performing an edit
            editToggled: false, // Whether the edit button is active or not
            drawingFeature: false, // Whether the draw button is active or not
            deleteAllDialog: false, // The delete all geometries dialog window
            deleteToggled: false, // Whether the delete single geometry button is active or not
            disabled:false
        };

        var _this = this;
        // Add a new drawing layer  
        _this.featureGroups = new _this.props.GraphicsLayer();

        loadModules(["esri/widgets/Sketch/SketchViewModel","esri/geometry/support/webMercatorUtils"], { css: true }).then(([SketchViewModel,webMercatorUtils]) => {
            _this.sketchVM = new SketchViewModel({
                layer: _this.featureGroups,
                view: _this.props.view,
                updateOnGraphicClick: false,
                // updateSymbol when the edit is active
                updatePolygonSymbol: {
                    type: "simple-fill",
                    style: "solid",
                    color: "rgba(255, 0, 0, 0.2)",
                    outline: {
                        style: "dash",
                        color: "rgba(255, 0, 0, 1)",
                        width: 1,
                    },
                },
                updatePointSymbol: {
                    type: "simple-marker",
                    color: "red",
                    width: 20,
                    outline: {
                        color: [255, 255, 255],
                        width: 2,
                    },
                },
                updatePolylineSymbol: {
                    type: "simple-line",
                    color: "red",
                    width: 5,
                    cap: "round",
                    join: "round",
                },
                // symbols are when drawing
                polygonSymbol: {
                    type: "simple-fill",
                    style: "solid",
                    color: "rgba(0, 0, 255, 0.2)",
                    outline: {
                        color: "rgba(0, 0, 255, 1)",
                        width: 1,
                    },
                },
                pointSymbol: {
                    type: "simple-marker",
                    color: "blue",
                    width: 20,
                    outline: {
                        color: [255, 255, 255],
                        width: 2,
                    },
                },
                polylineSymbol: {
                    type: "simple-line",
                    color: "blue",
                    width: 5,
                    cap: "round",
                    join: "round",
                },
            });

            // Save the drawing while creating
            _this.sketchVM.on("create", function (event) {
                if (event.state === "complete") {
                    _this.setState({ drawingFeature: false, disabled:true });
                    var geom, geojson, converted_geom;
                    geom = event.graphic.geometry; 

                    if(geom && _this.props.setDrawingFeature){ //used with sandhill app
                        converted_geom = webMercatorUtils.webMercatorToGeographic(geom);
                        geojson = arcgisToGeoJSON(converted_geom);
                        
                        //This function should be used for all other 
                        _this.props.setDrawingFeature(geojson) //pass upto app component with geometry used with sandhillapp,

                    }
                  
                }
            });

        });
           
    }

    // Update border to red when deleting--this is if we wanted people to be able to click and delete individual geometries but we decided to just delete all and only are supporting 1 geometry atm
    colorSectionsIconsForDelete = (active) => {
        const { view, _getPolygonGrpahic, _getLineGrpahic, _getPointGrapicWeb, Graphic } = this.props;

        const _this = this;
        var graphicsCopy = [...this.featureGroups.graphics.items]; // Copy the array of graphics because removing them from the original screws up the array
        graphicsCopy.forEach(function (graphic) {
            // Why TF do you have to remove and add a new graphic just to update the color
            _this.featureGroups.remove(graphic);
            var temp;
            if (graphic.geometry.type === "polygon") {
                temp = _getPolygonGrpahic(active ? "red" : "blue", graphic.geometry.rings, view, Graphic, graphic.attributes);
            } else if (graphic.geometry.type === "polyline") {
                temp = _getLineGrpahic(active ? "red" : "blue", graphic.geometry.paths, view, Graphic, graphic.attributes);
            } else {
                temp = _getPointGrapicWeb(active ? "red" : "blue", graphic.geometry.x, graphic.geometry.y, view, Graphic, graphic.attributes);
            }
            _this.featureGroups.add(temp);
        });
    };

    

    componentDidMount() {
        const { map, view } = this.props;
        // const {disabled} = this.state
        const _this = this;

        map.add(this.featureGroups);

        // this.populateFeatures();

        // Click Event
        view.on("click", function (event) {
            view.hitTest(event).then(function (response) {
                response.results.forEach((x) => {
                    // Can't do anything without id attributes - This should rarely if ever happen on drawing graphics layer
                    // It's probably a reference layer
                    if (!x.graphic.attributes || !x.graphic.attributes["key"] || _this.state.editing) return;

                    // We are currently deleting - Remove geometry from orm and graphic from layer--was only used when deleting individual geometries
                    if (_this.state.deleteToggled) {
                        x.graphic.layer.remove(x.graphic);
                        _this.setState({disabled:false, deleteToggled:false}) //so let them create another one
                        return; // Don't do anything else

                    
                    }

                    // Check we are working with colored blue (active layer; kinda hacky)
                    if (x.graphic.symbol.color.b === 255) {
                        // We are currently editing -
                        if (_this.state.editToggled) {
                            _this.setState({ editing: true });

                            // The default tool for polygons/lines is called "transform" but we don't really want that one
                            var tool = "move";
                            if (x.graphic.geometry.type !== "point") tool = "reshape";
                            _this.sketchVM.update([x.graphic], {
                                enableScaling: false,
                                tool: tool,
                            });
                        }
                    }
                });
            });
        });
    }

    
    //only used if we allow for multiple geometries to be deleted--in the application case we just delete them 
    _onDeleted = () => {
        var _this = this
        if (this.featureGroups) {
            this.featureGroups.graphics.items.forEach(function (g) {
             
            _this.featureGroups.removeAll();
        })
        
    };}

    render() {
        const { classes, readOnly  } = this.props;
        const { latLong, editToggled, drawingFeature, deleteToggled, disabled } = this.state;

    
                return (
                    <Paper>
                        <Accordion className={classes.root} expanded={true}>
                            <AccordionSummary className={classNames(classes.rightMargin, classes.blueColor)}>
                                <Typography variant="subtitle1" className={classes.whiteText}>
                                    Mapping Tools
                                </Typography>
                            </AccordionSummary>
                            {!latLong && (
                                <Grid container>
                                    <Grid item xs={12} style={{ marginTop: 12 }}>
                                        <Tooltip title="Add new map location by clicking on the map."> 
                                            <div>
                                                <Button
                                                    disabled={
                                                        readOnly ||
                                                        editToggled || 
                                                        disabled
                                                    }
                                                    variant="contained"
                                                    color="primary"
                                                    className={classNames(classes.buttons, drawingFeature && classes.deleteToggled)}
                                                    onClick={() => {
                                                        if (drawingFeature) {
                                                            this.setState({ formDialogStatus: false, drawingFeature: false });
                                                            this.sketchVM.cancel();
                                                        } else {
                                                            this.setState({
                                                                formDialogStatus: true,
                                                                drawingFeature: true,
                                                                editToggled: false,
                                                                deleteToggled: false,
                                                            });
                                                        
                                                            this.sketchVM.create("point");
                                                        }
                                                    }}>
                                                    <Typography variant="subtitle1">
                                                        {drawingFeature
                                                            ? "Stop Drawing"
                                                            : "Add New Project Location"}
                                                    </Typography>
                                                    <AddCircleOutline style={{ marginLeft: 20 }} />
                                                </Button>
                                            </div>
                                        </Tooltip>
                                         <Tooltip
                                            title={
                                                this.featureGroups.graphics.length === 0
                                                    ? "This button clears project location":null
                                            }>
                                            <div>
                                                <Button
                                                    disabled={
                                                        readOnly ||
                                                        this.featureGroups.graphics.length === 0 ||
                                                        editToggled ||
                                                        deleteToggled ||
                                                        drawingFeature
                                                    }
                                                    variant="contained"
                                                    color="primary"
                                                    className={classNames(classes.buttons)}
                                                    onClick={(e) => {
                                                        this._onDeleted();
                                                        this.setState({ deleteAllDialog: false, editToggled: false, disabled:false});
                                                    }}>
                                                    <Typography variant="subtitle1">Delete Project Location</Typography>
                                                    <DeleteForever style={{ marginLeft: 20 }} />
                                                </Button>
                                            </div>
                                        </Tooltip>  
                                    </Grid>
                                </Grid>
                            )}
                        </Accordion>

                   
                    </Paper>
                );
            
    }
}
MapEditing.displayName = "MapEditingApplication";

export default withStyles(styles)(MapEditing);
