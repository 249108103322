import React from "react";
import { Link } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core";

const styles = {
    submitWrapper: {
        position: "relative"
    },
    progress: {
        top: "50%",
        left: "50%",
        color: "#4CAF50",
        position: "absolute",
        "margin-top": -12,
        "margin-left": -12
    }
};

const Submit = ({ label, subLabel, altAction, altLabel, classes, loading, gridSize = 12, justify = "center" }) => (
    <Grid container justify={justify}>
        {altAction ? (
            <Grid item xs={gridSize / 2} style={{ paddingRight: 16 }}>
                <Button fullWidth component={Link} to={altAction}>
                    {altLabel}
                </Button>
            </Grid>
        ) : (
            <div />
        )}
        <Grid item xs={gridSize / 2}>
            <div className={classes.submitWrapper}>
                <Button fullWidth type="submit" variant="contained" color="primary" disabled={loading}>
                    {label} {subLabel}
                </Button>
                {loading && <CircularProgress size={24} className={classes.progress} />}
            </div>
        </Grid>
    </Grid>
);

export default withStyles(styles)(Submit);
