/******************************************************************************
 * This GroupTable Component shows the group tables for TableRecord.js
 * Each group will allow for deletion, editing, etc.
 *
 * Author: Robert Gautier
 * Date: 3/18/20
 * Rev B
 *******************************************************************************/

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form as Formm } from "react-form";

import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import Paper from "@material-ui/core/Paper";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Description from "@material-ui/icons/Description";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import TableBody from "@material-ui/core/TableBody";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import AttachEmailIcon from "@material-ui/icons/Email";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Table from "@material-ui/core/Table";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditLocation from "@material-ui/icons/EditLocation";
import EditIcon from "@material-ui/icons/Edit";
import Toolbar from "@material-ui/core/Toolbar";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Close from "@material-ui/icons/Close";
import NumberFormat from "react-number-format";

import CreateNewDialog from "../landing/CreateNewDialog";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import WarningDialog from "../../common/WarningDialog";
import Checkbox from "../../common/Checkbox";

import TablePaginationActionsWrapped from "../../common/Paginator";
import { Section, RecordSection, RecordField, ParentRecord } from "../models";
import ExpansionPanelFilters from "./ExpansionPanelFilters";
import { createSelector } from "../../common/orm";
import * as authActions from "../../auth/actions";
import Select from "../../common/Select";
import HelpLabel from "../../common/HelpLabel";
import EmailForm from "../EmailForm";

// CSS in JS styles
const styles = (theme) => ({
    table: {
        minWidth: 500,
    },
    dialogMin: {
        minWidth: 420,
        minHeight: 145,
    },
    flex: {
        flex: 1,
    },
    minWidth: {
        maxWidth: 507,
        minWidth: 489,
    },
    minWidth2: {
        maxWidth: 500,
    },
    gutterBottom: {
        marginBottom: ".4em",
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    root: {
        width: "100%",
        overflowX: "auto",
        //for table with the scrollbars
        [theme.breakpoints.up("1281")]: {
            maxHeight: "calc(100% - 125px)",
        },
        //for table with the scrollbars
        [theme.breakpoints.down("1281")]: {
            maxHeight: "fit-content",
        },
        backgroundColor: "#f0f0f0",
    },
    childRoot: {
        width: "100%",
        overflowX: "auto",
        //for table with the scrollbars
        [theme.breakpoints.up("1281")]: {
            maxHeight: "calc(100% - 176px)",
        },
        //for table with the scrollbars
        [theme.breakpoints.down("1281")]: {
            maxHeight: "fit-content",
        },
        backgroundColor: "#f0f0f0",
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        margin: "0 8px 0 0",
    },
    centerAlign: {
        textAlign: "center",
    },
    nowrap: {
        whiteSpace: "nowrap",
    },
    //view table & map icons
    icon: {
        width: "32px",
        marginRight: "7%",
        height: "32px",
        padding: "0px",
    },
    //create new icon styling
    iconAdd: {
        backgroundColor: "#34aa20",
        color: "white",
        height: "25px",
        width: "25px",
        paddingTop: "0px",
        fontSize: "100%",
        borderRadius: 12,
    },
    button: {
        float: "right",
        marginBottom: "7px",
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    progress: {
        display: "table",
        marginLeft: "auto",
        marginRight: "auto",
    },
    hideIfSmall: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    orange: {
        color: "orange",
    },
    listItem: {
        borderBottom: "1px solid #262626",
    },
    topListItem: {
        borderBottom: "1px solid #262626",
        backgroundColor: "#eee",
        cursor: "default",
        textAlign: "center",
    },
    list: {
        "& ul": {
            padding: 0,
        },
    },
    ellipsis: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 15,
        WebkitBoxOrient: "vertical",
    },
});

//get permissions
const getPermission = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (state, ownProps) => parseInt(ownProps.match.params["pageId"]),
    (session, id, pageID) => {
        if (isNaN(id)) {
            return session.Permission.filter((p) => p.page === pageID).toRefArray();
        } else {
            return session.Permission.filter((p) => p.page === id).toRefArray();
        }
    }
);

//get the sectionID from the URL
const getSections = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["sectionId"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (session, sectionID) => {
        return session.Section.filter((s) => s.id === sectionID).toRefArray();
    }
);

const getTemplates = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["sectionId"]),
    (session, sectionId) => {
        // Get all templates associated with the section we are on
        return session.LetterTemplate.filter((l) => l.section === sectionId)
            .orderBy("name")
            .toModelArray();
    }
);

const getAllSections = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (state, ownProps) => parseInt(ownProps.match.params["pageId"]),
    (session, id, pageId) => {
        if (isNaN(id)) {
            return session.Section.filter((sect) => sect.page === pageId).toRefArray();
        } else {
            return session.Section.filter((sect) => sect.page === id).toRefArray();
        }
    }
);

//get the parentRecordID from the URL--expansionPanelFormRecord child table case
const getParentRecordId = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["parentRecordId"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (session, recordID) => {
        return recordID; //result function uses session.ModelName.filter to match pageID with section.page (.page is page_id in section model)
    } //toRefArray returns a plain JavaScript object which are references to the DB
);

const getRecordSections = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["parentRecordId"]), //get from URL
    (session) => {
        // JJ: Now we just want to display whatever recordsections we have
        return session.RecordSection.all().toRefArray();
    }
);

const getForms = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["sectionId"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (session, actSection) => {
        return session.Form.filter((sect) => sect.section === actSection)
            .orderBy("id")
            .toModelArray()
            .map((form) => ({
                groups: form.groups //form.groups is FK linkage
                    .all()
                    .orderBy("order")
                    .toModelArray()
                    .map((group) => ({
                        fields: group.fields.all().orderBy("order").toRefArray(),
                        ...group.ref, // take all groups as a reference and tie in each field
                    })),
                ...form.ref, // take all forms as a reference and tie in each group
            }));
    } //toRefArray returns a plain JavaScript object which are references to the DB
);

class FormSelector extends Component {
    state = {
        count: 0,
        loadingFields: false,
    };
    //for child records to use already created parent record
    createNewSection = (e) => {
        const { history, pageID, parent_recordId, ormRecordSectionUpdateLocalOnly, forms, authState } = this.props;

        //get form name for creating a record
        const actForm = forms.find((f) => {
            if (f.name === e) {
                return f;
            }
            return null;
        });

        if (this.props.loadingFields) {
            this.props.loadingFields(true); //external call from component
        } else {
            this.setState({ loadingFields: true });
        }

        if (parent_recordId) {
            try {
                //eslint-disable-next-line
                const res = fetch(
                    `page/${pageID}/${parent_recordId}/${actForm.section}/${actForm.id}/${actForm.groups[0].fields.length}/${authState.user.auth_token}`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Token " + authState.user.auth_token,
                        },
                    }
                ).then((e) => {
                    if (e.status === 200) {
                        e.json().then((rsp) => {
                            ormRecordSectionUpdateLocalOnly({
                                id: rsp.rsid,
                                has_children_loaded: true,
                                //...record_section
                            });

                            if (this.props.loadingFields) this.props.loadingFields(false); //set circular dial off from 'expansionPanelFormRecord'
                            history.push(
                                "/page/" + rsp.page + "/parentrecord/" + rsp.prid + "/section/" + rsp.section + "/recordsection/" + rsp.rsid + "/form"
                            );
                        });
                    } else {
                        this.setState({ loading: false });
                        console.log(res);
                    }
                });
            } catch {
                this.setState({ loading: false });
            }
        }

        this.setState({ count: this.state.count + 1 }); //make sure it only runs once
    };

    render() {
        const { classes, formDialogClose, formDialogStatus, forms } = this.props;
        const { count, loadingFields } = this.state;

        // These two functions just make values to a mandatory label/value which is what react-forms expects
        function MAKE_OPTIONS(table) {
            return table.map((row) => ({
                label: row,
                value: row,
            }));
        }

        //this is to get the 'formNames' for the selected section so that the 'dialog dropdown' can be populated.
        var formNames = forms.map((n) => {
            return n.name;
        });

        var onlyOneForm = formNames[0];

        return (
            <Dialog open={formDialogStatus} classes={{ paper: classes.minWidth }}>
                <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                    {formNames.length > 1 ? (
                        <Typography variant="h5" className={classes.flex} style={{ marginLeft: "7%" }}>
                            Select a Form to Create a Record
                        </Typography>
                    ) : (
                        <Typography variant="h5" className={classes.flex}>
                            Record is being Created
                        </Typography>
                    )}

                    <IconButton onClick={formDialogClose}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent className={classes.dialogMin}>
                    <DialogContentText>
                        <Formm getApi={(el) => (this.formApi = el)} onSubmit={(values) => this.createForm(values)}>
                            {(formApi) => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2} style={{ marginTop: 3 }}>
                                        <Grid item xs={12}>
                                            {
                                                formNames.length === 1 && count === 0 && this.createNewSection(onlyOneForm) //only display if there is a prior form created
                                            }
                                            {!loadingFields &&
                                                formNames.length > 1 && ( //only display if there is a prior form created
                                                    <Select
                                                        style={{ paddingRight: "1%", paddingLeft: "1%" }}
                                                        field="name"
                                                        label={
                                                            <HelpLabel inputLabel="Select a Form" helpText="Select a Form to Create a New Record" />
                                                        }
                                                        options={MAKE_OPTIONS(formNames)}
                                                        eventHandle={this.createNewSection}
                                                        fullWidth
                                                    />
                                                )}
                                            {loadingFields && (
                                                <>
                                                    <CircularProgress size={90} className={classes.progress} />
                                                    <Typography variant="h6" style={{ textAlign: "center" }}>
                                                        Loading... Please Wait
                                                    </Typography>
                                                </>
                                            )}
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formm>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

// The table component
// http://localhost:3000/#/config/page
class ViewRecordTable extends Component {
    constructor(props, context) {
        super(props, context);
        // Default states
        this.state = {
            order: "desc",
            orderBy: null,
            parent: null,
            formDialogStatus: false,
            parentrecord: null,
            page: 0,
            rowsPerPage: 25,
            loadingFields: true,
            noFormOpen: false,
            totalCount: 0,
            tableLoading: false,
            report_selection: false,
            report_selection_rs: null,
            report_selection_pr: null,
            deleteMultiple: [],
            deleteMultipleOpen: false,
            key: window.performance.now(),
            deleteParentorChild: "",
            deleteChecked: false,
            deleting: false,
            count: 0,
            hasFilters: false,
            tablePixels: 0,
            emailFormOpen: false,
            emailUrl: null,
            emailReportName: null,
            smallAnchor2: null,
            activeReport: null,
        };
    }

    handleSmallMenu2 = (event, t) => {
        this.setState({ smallAnchor2: event.currentTarget, activeReport: t });
    };

    handleSmallMenuClose2 = () => {
        this.setState({ smallAnchor2: null });
    };

    returnDialog = () => {
        this.setState({ emailFormOpen: false });
    };

    // Initially load table data
    componentDidMount() {
        // Only load the total # of records if filter is not active
        const params = new URLSearchParams(this.props.location.search);
        const filters = params.get("filters");
        if (!filters) {
            this.loadTotalRecords();
        }

        // //use section setup for rows per page on mounting
        const {forms, sections} = this.props
        if(forms && sections){
            let sect = sections.find(s=>s.id===forms[0].section?s:null)  
            this.handleCookie(0, parseInt(sect.tableview_rowsperpage), true); 
        }else{//shouldnt ever happen but if it does go back to 25
            this.handleCookie(0, 25, true);
        }
        // 
    }

    // Also initially load table data if props are different
    // ComponentDidMount is not called if you go from one table straight to another
    componentDidUpdate(prevProps) {
        const { actSection } = this.props;
        if (actSection[0].id !== prevProps.actSection[0].id) {
            // Also reset component state
            this.setState({
                order: "asc",
                orderBy: null,
                parent: null,
                formDialogStatus: false,
                parentrecord: null,
                page: 0,
                rowsPerPage: 25,
                loadingFields: true,
                noFormOpen: false,
                totalCount: 0,
                tableLoading: false,
                isLastRecord: false,
                deleteMultiple: [],
                key: window.performance.now(),
                deleteParentorChild: "",
                deleteChecked: false,
                deleting: false,
                count: 0,
                hasFilters: false,
            });
            // Only load the total # of records if filter is not active
            const params = new URLSearchParams(this.props.location.search);
            const filters = params.get("filters");
            if (!filters) {
                this.loadTotalRecords();
            }
            //create/update cookie and call the values from the backend for the table
            this.handleCookie(0, 25, true);
        }
    }

    // Get the total number of records that SHOULD be in the table using a special endpoint
    loadTotalRecords = (_filters, areWeDeleteing) => {
        const { actSection, authState, history, parent_recordId, showParentTable, authLogout } = this.props;
        var component = this;

        var f = "";
        const params = new URLSearchParams(this.props.location.search);
        const filters = params.get("filters");
        if (_filters && _filters !== "noFilters") {
            f = "/" + _filters;
        } else if (filters && _filters !== "noFilters") {
            f = "/" + filters;
        }

        // If its a parent table get all records for that section
        if (showParentTable) {
            fetch("/count/recordsections/" + actSection[0].id + f, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + authState.user.auth_token,
                },
            }).then((e) => {
                if (e.status === 401) {
                    history.push("/");
                    authLogout();
                } else if (e.status !== 500) {
                    e.json().then((data) => {
                        if (areWeDeleteing) component.setState({ deleting: false });
                        component.setState({ totalCount: data.totalCount });
                    });
                }
            });
        } else {
            // Otherwise only get total count for a specific parent
            fetch("/count/recordsections/" + actSection[0].id + "/parent/" + parent_recordId + f, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + authState.user.auth_token,
                },
            }).then((e) => {
                if (e.status === 401) {
                    history.push("/");
                    authLogout();
                } else if (e.status !== 500) {
                    e.json().then((data) => {
                        if (areWeDeleteing) component.setState({ deleting: false });
                        component.setState({ totalCount: data.totalCount });
                    });
                }
            });
        }
    };

    // Get the actual data on django based on the current page you are on in the table
    dynamicLoading = (_page, _rowsPerPage, _order, _orderBy, _lastrecord, _filters, _fromExpansionPanel) => {
        const { showParentTable, ormRecordSectionReloadDynamic, actSection, parent_recordId } = this.props;
        var { order, orderBy, rowsPerPage, page, isLastRecord } = this.state;
        var component = this;
        // These if/elses are kind messy but apparently you can't overwrite _page, etc.
        var p, r, o, ob, lr;
        if (_page !== undefined) p = _page;
        else p = page;
        if (_rowsPerPage !== undefined) r = _rowsPerPage;
        else r = rowsPerPage;
        if (_order !== undefined) o = _order;
        else o = order;
        if (_orderBy !== undefined) ob = _orderBy;
        else ob = orderBy;
        if (_lastrecord !== undefined) lr = _lastrecord;
        else lr = isLastRecord;

        this.setState({order:o, orderBy:ob, rowsPerPage:r, page:p, isLastRecord:lr }) //for table to know if filter is enabled or not
        var str = "";

        const params = new URLSearchParams(this.props.location.search);
        const filters = params.get("filters");
        if (_filters && _fromExpansionPanel) {
            // If this has been called from ExpansionPanelFilters and there has been filters set use those
            str += "&filters=" + _filters;
            this.loadTotalRecords(_filters);
        } else if (!_filters && _fromExpansionPanel) {
            // Otherwise if this was called from ExpansionPanelFilters but there are no longer filters then just refresh the total
            this.loadTotalRecords("noFilters");
        } else if (filters && !_fromExpansionPanel) {
            // Otherwise use the filter state if not from ExpansionPanelFilters
            str += "&filters=" + filters;
            this.loadTotalRecords();
        }

        this.setState({ tableLoading: true });
        if (showParentTable) {
            str +=
                // "&parentsection=" +
                // parentsection.id +
                "&section=" + actSection[0].id + "&count=" + r + "&offset=" + r * p + "&order=" + o + "&order_by=" + encodeURIComponent(ob);
            if (lr) {
                str += "&child=true";
            }
            ormRecordSectionReloadDynamic(str).then(() => {
                component.setState({ tableLoading: false });
            });
        } else {
            str +=
                "&parent=" +
                parent_recordId +
                "&section=" +
                actSection[0].id +
                "&count=" +
                r +
                "&offset=" +
                r * p +
                "&order=" +
                o +
                "&order_by=" +
                encodeURIComponent(ob);
            ormRecordSectionReloadDynamic(str).then(() => {
                component.setState({ tableLoading: false });
            });
        }
    };

    //close form dialog
    formDialogClose = () => {
        this.setState({ formDialogStatus: false });
    };

    handleClearSort = (event, property, full_property) => {
        // console.log(event, property, full_property)
        this.setState({orderBy:null, rowsPerPage:25, page:0})
        this.handleRequestSort(null,null,null, true)
    }
    // sort the cells for the table, this is for each column and accomidates parent sorting as well as child case sorting
    handleRequestSort = (event, property, full_property, clear=false) => {
        const { actSection } = this.props;
        const { rowsPerPage } = this.state;
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }
        //determine if on a table for a all child record sections, parenttable or on an individual child section table
        var formOrTable;
        var form = this.props.location.pathname.includes("form");
        var table = this.props.location.pathname.includes("table");
        if (form) formOrTable = "form";
        else if (table) formOrTable = "table";

        var tempRows,
            p = 0,
            values = "";

        //update cookie with sort info
        var cookie = document.cookie;
        if (cookie || cookie === "") {
            //look for actual cookie
            if (cookie.indexOf(`pageid${actSection[0].page}section${actSection[0].id}${formOrTable}`) !== -1) {
                //we have a cookie now find it and split into an array
                var cookiesSplit = cookie.split(";");
                var actualCookie = cookiesSplit
                    .find((c) => (c.includes(`pageid${actSection[0].page}section${actSection[0].id}${formOrTable}`) ? c : null))
                    .split(":");
                tempRows = actualCookie[1].split("rowsPerPage=")[1];

                var filterIdx, filters;
                actualCookie.forEach((c, i) => {
                    return c.includes("filters=") ? (filterIdx = i) : null;
                });
                //look to see if cookie had filter previously, if so set it otherwise dont
                if (filterIdx) {
                    filters = actualCookie[filterIdx].split("filters=")[1];
                    values = `page=${p}: rowsPerPage=${tempRows}: filters=${filters}: order=${order}: orderBy=${orderBy}`;
                } else {
                    values = `page=${p}: rowsPerPage=${tempRows}: order=${order}: orderBy=${orderBy}`;
                }

                //theres a filter so set url
                if (filters !== "" && filters) {
                    const param = new URLSearchParams();
                    param.append("filters", filters);
                    this.props.history.push({ search: param.toString() });
                }
                if (filters === "" || !filters) filters = null;

                //update cookie
                document.cookie = `pageid${actSection[0].page}section${actSection[0].id}${formOrTable}=${values}; path=/; expires=Wed, 12 Oct 2050 08:50:17 UTC`;
            }
        }

        // Reset to page/rowsperpage if this happens
        this.setState({ order, orderBy, isLastRecord: full_property ? full_property.lastrecord : full_property, page: 0 });
        if (clear)this.dynamicLoading(0, 25, 'asc', null, null); //clear the filter
        else if (!filters) this.dynamicLoading(0, rowsPerPage, order, orderBy, full_property ? full_property.lastrecord : full_property);
        else this.dynamicLoading(0, rowsPerPage, order, orderBy, full_property ? full_property.lastrecord : full_property, filters); //has a filter
    };

    handleChangePage = (event, p) => {
        this.setState({ page: p });
        this.unselectAll(); //there could have been records selected dont allow multiple pages to be selected b4 a delete as will screw up cookies
        this.handleCookie(p);
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
        // this.dynamicLoading(0, event.target.value);
        this.handleCookie(0, event.target.value);
    };

    handleCookie = (p = 0, r, mount = false, shownFilters) => {
        const { actSection } = this.props;
        const { rowsPerPage } = this.state;

        const params = new URLSearchParams(this.props.location.search);
        var filters = params.get("filters");

        //determine if on a table for a all child record sections, parenttable or on an individual child section table
        var formOrTable;
        var form = this.props.location.pathname.includes("form");
        var table = this.props.location.pathname.includes("table");
        if (form) formOrTable = "form";
        else if (table) formOrTable = "table";

        var values = "",
            order = "desc",
            orderBy = null;

        var tempRows = r; //passed in rows, else use state
        if (!r) {
            tempRows = rowsPerPage;
        }

        if (filters || shownFilters) {
            //chk for passed in filters from expansionpanelfilter
            if (shownFilters === "" || shownFilters) filters = shownFilters;

            //there is a filter in URL so set it for the next cookie
            if (filters !== "") {
                values = `page=${p}: rowsPerPage=${tempRows}: filters=${filters !== "" && filters ? filters : shownFilters}`;
            } else {
                //shownfilters was passed in from expansionpanelfilter as "" as it was turned off and filter is an empty string "" so we wont record it
                values = `page=${p}: rowsPerPage=${tempRows}`;
            }
        } else {
            //filter is "" and shownFilters from expansionpanelfilter is undefined occurs when no filters are set so we dont record filters
            values = `page=${p}: rowsPerPage=${tempRows}`;
        }

        var cookie = document.cookie;
        if (cookie || cookie === "") {
            //look for actual cookie
            if (cookie.indexOf(`pageid${actSection[0].page}section${actSection[0].id}${formOrTable}`) !== -1) {
                //we have a cookie now find it and split into an array
                var cookiesSplit = cookie.split(";");
                var actualCookie = cookiesSplit
                    .find((c) => (c.includes(`pageid${actSection[0].page}section${actSection[0].id}${formOrTable}`) ? c : null))
                    .split(":");

                //this is for first mounting or revert to 0 when a filter has been set
                if (mount || shownFilters) {
                    p = actualCookie[0].split("page=")[1];
                    tempRows = actualCookie[1].split("rowsPerPage=")[1];

                    var orderIdx, orderByIdx, filterIdx;
                    //find where these indexes are for filter, order and orderby as older ones wont have order & orderby
                    actualCookie.forEach((c, i) => {
                        return (
                            c.includes("order=") ? (orderIdx = i) : null,
                            c.includes("orderBy=") ? (orderByIdx = i) : null,
                            c.includes("filters=") ? (filterIdx = i) : null
                        );
                    });

                    if (filterIdx && !shownFilters) {
                        //look to see if cookie has a filterIdx and orderIdx, shownFilters passed from expansionfilters
                        filters = actualCookie[filterIdx].split("filters=")[1];
                        if (orderIdx) {
                            //order was prev set
                            order = actualCookie[orderIdx].split("order=")[1];
                            orderBy = actualCookie[orderByIdx].split("orderBy=")[1];
                            values = `page=${p}: rowsPerPage=${tempRows}: filters=${filters}: order=${order}: orderBy=${orderBy}`;
                        } else {
                            //order was not set
                            values = `page=${p}: rowsPerPage=${tempRows}: filters=${filters}: order=${order}: orderBy=${orderBy}`;
                        }
                    } else if (shownFilters !== "" && shownFilters) {
                        //there is a filter on load OR it was just set by selecting a previous filter, start page back at 0 and update cookie
                        if (mount) p = 0;
                        filters = shownFilters;
                        if (orderIdx) {
                            //order was prev set
                            order = actualCookie[orderIdx].split("order=")[1];
                            orderBy = actualCookie[orderByIdx].split("orderBy=")[1];
                            values = `page=${p}: rowsPerPage=${tempRows}: filters=${filters}: order=${order}: orderBy=${orderBy}`;
                        } else {
                            //order was not set
                            values = `page=${p}: rowsPerPage=${tempRows}: filters=${filters}: order=${order}: orderBy=${orderBy}`;
                        }
                    } else {
                        //there wasnt a previous filter set so dont record filter
                        if (orderIdx) {
                            //order was prev set
                            order = actualCookie[orderIdx].split("order=")[1];
                            orderBy = actualCookie[orderByIdx].split("orderBy=")[1];
                            values = `page=${p}: rowsPerPage=${tempRows}: order=${order}: orderBy=${orderBy}`;
                        } else {
                            //order was not set
                            values = `page=${p}: rowsPerPage=${tempRows}: order=${order}: orderBy=${orderBy}`;
                        }
                    }

                    //set state of page only occurs on first mounting from prev setting
                    if (mount) {
                        p = parseInt(p);
                        this.setState({ page: p });
                    }
                } else {
                    //delete all filters case to add previous order and orderby only if they were prev set
                    let orderIdx, orderByIdx;
                    actualCookie.forEach((c, i) => {
                        return c.includes("order=") ? (orderIdx = i) : null;
                    });
                    actualCookie.forEach((c, i) => {
                        return c.includes("orderBy=") ? (orderByIdx = i) : null;
                    });
                    if (orderIdx) {
                        //only set if order is set
                        order = actualCookie[orderIdx].split("order=")[1];
                        orderBy = actualCookie[orderByIdx].split("orderBy=")[1];
                        values = `page=${p}: rowsPerPage=${tempRows}: order=${order}: orderBy=${orderBy}`;
                    }
                }
                //theres a filter so set url
                if (filters !== "" && filters) {
                    const param = new URLSearchParams();
                    param.append("filters", filters);
                    this.props.history.push({ search: param.toString() });
                }
                if (filters === "" || !filters) filters = null;

                //get backend records and update cookie
                this.dynamicLoading(p, tempRows, order, orderBy, null, filters, true);
                document.cookie = `pageid${actSection[0].page}section${actSection[0].id}${formOrTable}=${values}; path=/; expires=Wed, 12 Oct 2050 08:50:17 UTC`;
            } else {
                //no cookie on page so create--determine if on table or form
                document.cookie = `pageid${actSection[0].page}section${actSection[0].id}${formOrTable}=${values}; path=/; expires=Wed, 12 Oct 2050 08:50:17 UTC`;
                this.dynamicLoading(p, tempRows, order, orderBy);
            }
        }

        this.setState({ rowsPerPage: tempRows, page: parseInt(p), hasFilters: filters !== "" ? true : false }); //hasFilters is for expansionpanelfilter to open or not
    };

    // This is called from within ExpansionPanelFilters.
    // It basically updates the state of the parent component (ViewRecordTable) so it re-renders the table after shownfilters is updated
    dummyState = () => {
        this.setState({ foo: "bar" });
    };

    handleDelete = (v) => {
        let deleteParentorChild = this.props.actSection[0].parent ? "Parent" : "Child";

        this.setState({ deleteMultipleOpen: true, deleteParentorChild });
    };

    unselectAll = () => {
        const { deleteMultiple } = this.state;

        deleteMultiple.forEach((c) => {
            this.formApi.values[`chk_${c}`] = false;
        });

        this.setState({
            deleteMultipleOpen: false,
            deleteMultiple: [],
            deleteChecked: false,
            deleteParentorChild: "",
            key: window.performance.now(),
        });
    };

    //THIS IS FOR SELECT ALL
    handleSelectAll = (e) => {
        const { recordsection, actSection } = this.props;
        const { deleteMultiple } = this.state;
        var deleteClone = [...deleteMultiple];
        var isChecked;
        if (e.target.checked) {
            recordsection.forEach((r) => {
                if (actSection[0].parent) {
                    this.formApi.values[`chk_${r.parentrecord}`] = true;
                    if (!deleteMultiple.find((d) => d === r.parentrecord)) {
                        deleteClone.push(r.parentrecord);
                    }
                } else {
                    this.formApi.values[`chk_${r.id}`] = true;
                    if (!deleteMultiple.find((d) => d === r.id)) {
                        deleteClone.push(r.id);
                    }
                }
            });

            isChecked = true;
        } else {
            recordsection.forEach((r) => {
                if (actSection[0].parent) {
                    this.formApi.values[`chk_${r.parentrecord}`] = false;
                } else {
                    this.formApi.values[`chk_${r.id}`] = false;
                }
            });

            isChecked = false;
            deleteClone = [];
        }

        this.setState({ deleteMultiple: deleteClone, deleteChecked: isChecked, count: 0 });
    };

    //used for select all only
    defaultValues = () => {
        const { recordsection, actSection } = this.props;
        const { deleteChecked, count } = this.state;
        var obj = {};

        if (deleteChecked === true) {
            recordsection.forEach((r) => {
                if (actSection[0].parent) {
                    obj[`chk_${r.parentrecord}`] = true;
                } else {
                    obj[`chk_${r.id}`] = true;
                }
            });
            if (count === 0)
                this.setState({
                    key: window.performance.now(),
                    count: count + 1,
                });
        } else {
            recordsection.forEach((r) => {
                if (actSection[0].parent) {
                    obj[`chk_${r.parentrecord}`] = false;
                } else {
                    obj[`chk_${r.id}`] = false;
                }
            });
            if (count === 0)
                this.setState({
                    key: window.performance.now(),
                    count: count + 1,
                });
        }
        return obj;
    };

    handleTablePixels = (p) => {
        this.setState({ tablePixels: p });
    };

    // Render the table this is used by landing page tables and tables in expansionPanelFormRecord component
    render() {
        const {
            classes,
            ormParentRecordDelete,
            ormRecordSectionDelete,
            ormRecordSectionCreate,
            ormRecordFieldCreate,
            ormRecordSectionUpdateLocalOnly,
            recordsection,
            pageID,
            newSection,
            forms,
            history,
            parent_recordId,
            actSection,
            authState,
            permission,
            showParentTable,
            templates,
        } = this.props;
        const {
            order,
            orderBy,
            rowsPerPage,
            page,
            loadingFields,
            noFormOpen,
            formDialogStatus,
            totalCount,
            tableLoading,
            report_selection,
            report_selection_rs,
            report_selection_pr,
            deleteMultiple,
            deleteMultipleOpen,
            deleteParentorChild,
            key,
            deleteChecked,
            hasFilters,
            deleting,
            tablePixels,
            emailFormOpen,
            emailUrl,
            emailReportName,
            smallAnchor2,
            activeReport,
        } = this.state;
        const smallMenuOpen2 = Boolean(smallAnchor2);

        var columnRecord = [];
        if (window.innerWidth >= 960) columnRecord.push({ id: "actions", label: "", allowSort: false }); //push the first action area to be blank in the header on the table to allow for alignment

        if (authState.user) {
            var readOnly;
            var perm = permission.find((p) => {
                if (p.user === authState.user.id) {
                    return p;
                }
                return null;
            });
            if (perm) readOnly = perm.readonly || perm.readonlyexternal ? true : false;
            if (readOnly === undefined) readOnly = false;
        }

        // check for is form is used as a column
        var hasFormName = forms.filter((f) => {
            return f.is_column === true ? f : null;
        });

        ////////////////////////////MAIN PARENT TABLE RENDERS THIS WHEN A PAGE IS CLICKED ON, THIS ALSO IS SHOWN FOR 'ALL' RECORDS IN A CHILD SECTION///////////////////////////////////
        if (showParentTable) {
            var tableData = recordsection;

            // Get table columns
            var counter = 0;
            if (recordsection.length > 0) {
                // Every record section might have a different form so loop through and add any column
                while (counter !== recordsection.length) {
                    // eslint-disable-next-line
                    recordsection[counter].columns.forEach((c) => {
                        var found;
                        columnRecord.find((f) => {
                            if (f.id !== c.field) {
                                return (found = c.field);
                            }
                            return null;
                        });
                        if (columnRecord.length === 0) {
                            if (c.is_id && !actSection[0].parent) {
                                //show identification field in beginning of all child record tables
                                columnRecord.splice(1, 0, {
                                    id: c.field,
                                    label: c.field,
                                    type: c.type,
                                    allowSort: false,//FIXME doesnt sort with true on child ID field
                                });
                            } else {
                                columnRecord.push({
                                    id: c.field,
                                    label: c.field,
                                    type: c.type,
                                    allowSort: !c.lastrecord ? true : false,
                                });
                            }
                        } else if (found) {
                            if (c.is_id && !actSection[0].parent) {
                                //show identification field in beginning of all child record tables
                                columnRecord.splice(1, 0, {
                                    id: found,
                                    label: found,
                                    type: c.type,
                                    allowSort: false,//FIXME doesnt sort with true on child ID field
                                });
                            } else {
                                columnRecord.push({
                                    id: found,
                                    label: found,
                                    type: c.type,
                                    allowSort: !c.lastrecord ? true : false,
                                });
                            }
                        }
                    });

                    counter++;
                }

                // Then Remove duplicate column objects
                columnRecord = columnRecord.filter((c, index) => {
                    return (
                        index ===
                        columnRecord.findIndex((obj) => {
                            return obj.id === c.id;
                        })
                    );
                });
            }

            //select all and delete shown occurs rows are selected
            if (deleteMultiple.length !== 0) {
                columnRecord[0] = {
                    delete: true,
                    allowSort: false,
                    handleDelete: this.handleDelete,
                    select: true,
                    handleSelect: this.handleSelectAll,
                    checked: deleteChecked, //used to uncheckbox with unselecting
                };
            } else {
                //select all only
                columnRecord[0] = {
                    id: "selectall",
                    select: true,
                    allowSort: false,
                    handleSelect: this.handleSelectAll,
                };
            }

            //show form names on the table
            if (hasFormName.length > 0) {
                columnRecord.splice(2, 0, {
                    id: "Form",
                    label: "Form Name",
                    colorChange: true,
                });
            }

            return (
                <>
                    <ExpansionPanelFilters
                        pixels={this.handleTablePixels}
                        hasFilters={hasFilters}
                        setCookieFilter={this.handleCookie}
                        dynamicLoading={this.dynamicLoading}
                        dummyState={this.dummyState}
                    />
                    <br className={classes.hideIfSmall} />
                    {actSection && actSection[0].parent && (
                        <Button
                            type="submit"
                            disabled={readOnly}
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            fullWidth
                            onClick={() => {
                                if (forms.length === 0) {
                                    this.setState({ noFormOpen: true });
                                } else {
                                    this.setState({ formDialogStatus: true });
                                }
                            }}>
                            <AddIcon className={classes.iconAdd} />
                            &nbsp;&nbsp;&nbsp; Create New Record
                        </Button>
                    )}
                    {totalCount > 1 && (
                        <div
                            style={{
                                backgroundColor: "#dddddd",
                                marginTop: actSection[0].parent ? 53 : 0,
                                borderRadius: 1,
                            }}>
                            <TablePagination
                                colSpan={columnRecord.length}
                                count={totalCount}
                                rowsPerPage={parseInt(rowsPerPage)}
                                page={page}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActionsWrapped}
                            />
                        </div>
                    )}
                    <Paper className={classes.root} style={{ maxHeight: tablePixels !== 0 ? `calc(100% - ${tablePixels}px)` : null }}>
                        <Table className={classes.table} stickyHeader>
                            <EnhancedTableHead
                                key={actSection[0].id + "_table"}
                                order={order}
                                orderBy={orderBy}
                                disabled={readOnly}
                                onRequestSort={this.handleRequestSort}
                                onClearSort={this.handleClearSort}
                                columnData={!tableLoading && !deleting ? columnRecord : []}
                                recordsView={true}
                            />

                            <TableBody>
                                {tableLoading && !deleting && (
                                    <TableRow>
                                        <CustomTableCell colSpan={0} className={classes.centerAlign} style={{ paddingBottom: 16 }}>
                                            Loading Table Records...
                                            <CircularProgress size={30} className={classes.progress} />
                                        </CustomTableCell>
                                    </TableRow>
                                )}
                                {deleting && (
                                    <TableRow>
                                        <CustomTableCell colSpan={0} className={classes.centerAlign} style={{ paddingBottom: 16 }}>
                                            Deleting Table Records...
                                            <CircularProgress size={30} className={classes.progress} />
                                        </CustomTableCell>
                                    </TableRow>
                                )}
                                {(() => {
                                    if (tableLoading || deleting) return null;

                                    // Group columns with same name together
                                    tableData.forEach(function (n) {
                                        var cols = [];
                                        n.columns.forEach(function (c) {
                                            // If column isn't in temp array yet add it
                                            if (cols.filter((e) => e.field === c.field).length === 0) {
                                                cols.push(c);
                                            } else if (c.value) {
                                                // Else the column already is in the temp array
                                                // And if this new column has a value that means the other column has no value
                                                // Update the value here
                                                var objIndex = cols.findIndex((e) => e.field === c.field);
                                                cols[objIndex].value = c.value;
                                            }
                                        });
                                        n.cols = cols;
                                    });

                                    if (totalCount < 1 || tableData.length < 1) {
                                        return (
                                            <TableRow>
                                                <CustomTableCell colSpan={columnRecord.length} className={classes.centerAlign}>
                                                    No Records Available
                                                </CustomTableCell>
                                            </TableRow>
                                        );
                                    }

                                    return tableData.map((n, i) => {
                                        return (
                                            <TableRow hover key={n.id}>
                                                {/* {window.innerWidth >= 960 && ( */}
                                                <CustomTableCell className={classes.nowrap} style={{ width: 165 }}>
                                                    {readOnly === false && (
                                                        <Formm key={key} defaultValues={this.defaultValues()} getApi={(el) => (this.formApi = el)}>
                                                            {(formApi) => (
                                                                <form style={{ display: "inline-block" }}>
                                                                    {/* individual checkboxes next to each record */}
                                                                    <Tooltip title="Delete">
                                                                        <Checkbox
                                                                            disabled={readOnly}
                                                                            field={actSection[0].parent ? `chk_${n.parentrecord}` : `chk_${n.id}`}
                                                                            eventHandle={(e) => {
                                                                                if (e === true) {
                                                                                    var clone = [...deleteMultiple];
                                                                                    //parent table case
                                                                                    if (actSection[0].parent) {
                                                                                        if (!deleteMultiple.find((d) => d === n.parentrecord)) {
                                                                                            clone.push(n.parentrecord);
                                                                                        }
                                                                                        this.setState({
                                                                                            deleteMultiple: clone,
                                                                                            deleteParentorChild: "Parent",
                                                                                        });
                                                                                    } else {
                                                                                        //child section all records table case
                                                                                        if (!deleteMultiple.find((d) => d === n.id)) {
                                                                                            clone.push(n.id);
                                                                                        }

                                                                                        this.setState({
                                                                                            deleteMultiple: clone,
                                                                                            deleteParentorChild: "Child",
                                                                                        });
                                                                                    }
                                                                                } else {
                                                                                    //chk for values in array if there delete

                                                                                    var temp = [];
                                                                                    if (actSection[0].parent) {
                                                                                        temp = deleteMultiple.filter((d) => d !== n.parentrecord);
                                                                                    } else {
                                                                                        temp = deleteMultiple.filter((d) => d !== n.id);
                                                                                    }
                                                                                    this.formApi.values[
                                                                                        actSection[0].parent ? `chk_${n.parentrecord}` : `chk_${n.id}`
                                                                                    ] = false;
                                                                                    this.setState({
                                                                                        deleteMultiple: temp,
                                                                                        deleteParentorChild: "Child",
                                                                                    });
                                                                                    // }
                                                                                }
                                                                            }}
                                                                            label=""
                                                                        />
                                                                    </Tooltip>
                                                                </form>
                                                            )}
                                                        </Formm>
                                                    )}
                                                    <Tooltip title="Form Edit" style={{ marginLeft: 15 }}>
                                                        <Button
                                                            component={Link}
                                                            to={
                                                                "/page/" +
                                                                pageID +
                                                                "/parentrecord/" +
                                                                n.parentrecord +
                                                                "/section/" +
                                                                n.section +
                                                                "/recordsection/" +
                                                                n.id +
                                                                "/form"
                                                            }
                                                            className={classes.deleteWidth}>
                                                            <EditIcon color="primary" />
                                                        </Button>
                                                    </Tooltip>
                                                    {window.innerWidth >= 960 && (
                                                        <>
                                                            {actSection[0].has_geometry && (
                                                                <Tooltip title={n.geometry ? "Map Edit" : "Map Edit - No Mapped Location"}>
                                                                    <Button
                                                                        component={Link}
                                                                        to={
                                                                            "/page/" +
                                                                            pageID +
                                                                            "/parentrecord/" +
                                                                            n.parentrecord +
                                                                            "/recordsection/" +
                                                                            n.id +
                                                                            "/map"
                                                                        }
                                                                        className={classes.deleteWidth}>
                                                                        <EditLocation className={!n.geometry ? classes.orange : ""} color="primary" />
                                                                    </Button>
                                                                </Tooltip>
                                                            )}
                                                            <Tooltip title="Word Document">
                                                                <Button
                                                                    className={classes.deleteWidth}
                                                                    onClick={() => {
                                                                        if (templates.length === 0)
                                                                            window.open(
                                                                                `/reports/${authState.user.city_id}/${n.parentrecord}/word/parent`
                                                                            );
                                                                        else
                                                                            this.setState({
                                                                                report_selection: true,
                                                                                report_selection_pr: n.parentrecord,
                                                                                report_selection_rs: n.id,
                                                                            });
                                                                    }}>
                                                                    <i className="material-icons" color="primary">
                                                                        assignment
                                                                    </i>
                                                                </Button>
                                                            </Tooltip>
                                                        </>
                                                    )}
                                                </CustomTableCell>

                                                {/* PRINTING OF THE DATA IN THE TABLE */}
                                                {/* ALL PARENT PAGE TABLES*/}
                                                {columnRecord.map((column, index) => {
                                                    if (index > 0 /*&& window.innerWidth >= 960) || window.innerWidth < 960*/) {
                                                        var found = n.cols.find((actCol) => {
                                                            if (actCol.field === column.id) {
                                                                return actCol.value;
                                                            } else if (column.id === "Form" && actCol.is_column) {
                                                                return (
                                                                    //set the key to the formname
                                                                    (actCol["value"] = actCol.form)
                                                                );
                                                            }
                                                            return null;
                                                        });
                                                        if (column.type === "Phone Number") {
                                                            return (
                                                                <CustomTableCell key={index + column.id}>
                                                                    <NumberFormat
                                                                        format="+1 (###) ###-####"
                                                                        allowEmptyFormatting
                                                                        mask="_"
                                                                        displayType={"text"}
                                                                        value={found ? found.value : "-"}
                                                                    />
                                                                </CustomTableCell>
                                                            );
                                                        } else if (column.label === "Form Name") {
                                                            return (
                                                                <CustomTableCell key={index + column.id}>
                                                                    <Tooltip title="Form Name associated with this record">
                                                                        <div>
                                                                            {found
                                                                                ? found.value.length > 49
                                                                                    ? found.value.substring(0, 50) + "..."
                                                                                    : found.value
                                                                                : "-"}
                                                                        </div>
                                                                    </Tooltip>
                                                                </CustomTableCell>
                                                            );
                                                        } else if (column.type === "Numeric") {
                                                            return (
                                                                <CustomTableCell key={index + column.id}>
                                                                    <NumberFormat
                                                                        // {...other}
                                                                        // getInputRef={inputRef}
                                                                        displayType={"text"}
                                                                        value={found ? found.value : "-"}
                                                                        decimalScale={found && found.decimals}
                                                                        thousandSeparator={found ? found.thousand_seperator : false}
                                                                    />
                                                                </CustomTableCell>
                                                            );
                                                        } else if (column.type === "Currency") {
                                                            return (
                                                                <CustomTableCell key={column.id}>
                                                                    <NumberFormat
                                                                        prefix="$"
                                                                        mask="_"
                                                                        displayType={"text"}
                                                                        value={found ? found.value : "-"}
                                                                        thousandSeparator={true}
                                                                    />
                                                                </CustomTableCell>
                                                            );
                                                        } else if (column.type === "Calculated") {
                                                            return (
                                                                <CustomTableCell key={index + column.id}>
                                                                    <NumberFormat
                                                                        // {...other}
                                                                        // getInputRef={inputRef}
                                                                        displayType={"text"}
                                                                        value={found ? found.value : "-"}
                                                                        decimalScale={found && found.decimals}
                                                                        thousandSeparator={true}
                                                                    />
                                                                </CustomTableCell>
                                                            );
                                                        } else if (found && found.lastrecord) {
                                                            if(tableData && tableData[0].form === 348 && column.id === 'Status'){ //custom ninemile permit coloring on status
                                                                let color = ''
                                                                if(found && found.value === 'Completeness Review'){
                                                                    color = 'red'
                                                                }else if(found && found.value === 'Incomplete'){
                                                                    color = 'orange'
                                                                }else if(found && found.value === 'Approved with Conditions'){
                                                                    color = 'yellow'
                                                                }else if(found && found.value === 'Permit Issued'){
                                                                    color = 'green'
                                                                }else if(found && found.value === 'Permit Closed'){
                                                                    color = 'gray'
                                                                } 
                                                                return( 
                                                                    <CustomTableCell key={index + column.id}  style={{color}}>
                                                                        <Tooltip title={`Form: ${found.formname} / Section: ${found.sectionname}`}>
                                                                            <div> 
                                                                                {found && found.value
                                                                                    ? found.value.replaceAll("_", " ").replaceAll(",", ", ")
                                                                                    : "-"}
                                                                            </div>
                                                                        </Tooltip>
                                                                    </CustomTableCell> 
                                                                )
                                                            }
                                                            else { //normal last record printing
                                                                return ( 
                                                                    //display custom tooltip to show on last record on the parent table
                                                                    <CustomTableCell key={index + column.id}>
                                                                        <Tooltip title={`Form: ${found.formname} / Section: ${found.sectionname}`}>
                                                                            <div>
                                                                                {found && found.value
                                                                                    ? found.value.replaceAll("_", " ").replaceAll(",", ", ")
                                                                                    : "-"}
                                                                            </div>
                                                                        </Tooltip>
                                                                    </CustomTableCell>
                                                                ); 
                                                            }
                                                        } else {
                                                            if (!found || !found.value) {
                                                                return (
                                                                    <CustomTableCell key={index + column.id}>
                                                                        {"-"}
                                                                    </CustomTableCell>
                                                                )
                                                            }

                                                            if (found.value.length > 250) {
                                                                return (
                                                                    <Tooltip title={found.value.replaceAll("_", " ").replaceAll(",", ", ")}>
                                                                        <CustomTableCell key={index + column.id}>
                                                                            <div className={classes.ellipsis}>
                                                                                {found.value.replaceAll("_", " ").replaceAll(",", ", ")}
                                                                            </div>
                                                                        </CustomTableCell>
                                                                    </Tooltip>
                                                                )
                                                            } else {
                                                                return (
                                                                    <CustomTableCell key={index + column.id}>
                                                                        {found.value.replaceAll("_", " ").replaceAll(",", ", ")}
                                                                    </CustomTableCell>
                                                                )
                                                            }
                                                        }
                                                    } else return null;
                                                })}
                                            </TableRow>
                                        );
                                    });
                                })()}
                            </TableBody>
                            {totalCount > 1 && (
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            colSpan={columnRecord.length}
                                            count={totalCount}
                                            rowsPerPage={parseInt(rowsPerPage)}
                                            page={page}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActionsWrapped}
                                        />
                                    </TableRow>
                                </TableFooter>
                            )}
                        </Table>
                        <WarningDialog
                            confirmAction={() => {
                                const { deleteParentorChild, page } = this.state;
                                const _this = this;

                                _this.setState({ deleteMultiple: [], deleteParentorChild: "", deleting: true });
                                _this.unselectAll();

                                var counter = 0;
                                deleteMultiple.forEach((d, i) => {
                                    deleteParentorChild === "Parent"
                                        ? ormParentRecordDelete(d).then(() => {
                                              counter++;
                                              if (deleteMultiple.length === counter) {
                                                  //if we delete all of the records in the viewable table we want to update the cookie to rollback page & update recordsections
                                                  if (page > 0) {
                                                      setTimeout(() => {
                                                          _this.handleCookie(page - 1, null);
                                                      }, 500);
                                                  } else {
                                                      setTimeout(() => {
                                                          _this.handleCookie(page, null);
                                                      }, 500);
                                                  }
                                                  // call again a little later as deleting larger sets can have count off
                                                  setTimeout(function () {
                                                      _this.loadTotalRecords(null, true);
                                                  }, 1000);
                                              }
                                          })
                                        : ormRecordSectionDelete(d).then(() => {
                                              counter++;
                                              if (deleteMultiple.length === counter) {
                                                  //if we delete all of the records in the viewable table we want to update the cookie to rollback page & update recordsections
                                                  if (page > 0) {
                                                      setTimeout(() => {
                                                          _this.handleCookie(page - 1, null);
                                                      }, 500);
                                                  } else {
                                                      setTimeout(() => {
                                                          _this.handleCookie(page, null);
                                                      }, 500);
                                                  }
                                                  // call again a little later as deleting larger sets can have count off
                                                  setTimeout(function () {
                                                      _this.loadTotalRecords(null, true);
                                                  }, 1000);
                                              }
                                          });
                                });
                                this.setState({ deleteMultipleOpen: false });
                            }}
                            cancelAction={() => this.unselectAll()}
                            open={deleteMultipleOpen}
                            title={`Delete ${deleteMultiple.length} Records`}
                            text={
                                deleteParentorChild === "Parent"
                                    ? "WARNING: The selected records will be removed with all attached related records. Are you sure you wish to permanently delete these?"
                                    : "WARNING: The selected records will be removed. Are you sure you wish to permanently delete?"
                            }
                        />
                        <WarningDialog open={deleting} tableDeleting title="Deleting Records Please Wait" text="Please Wait...." />
                        <WarningDialog
                            cancelAction={() => this.setState({ noFormOpen: false })}
                            open={noFormOpen}
                            noDeleteButtons
                            title="Form Not Created"
                            text="A new record can not be created until a 'form' has been configured for this section. Please go to the configuration module and configure a form first or talk to the software administrator."
                        />
                        <CreateNewDialog
                            classes={classes}
                            open={formDialogStatus} //open is a keyword that knows dialog is open is based on flag value from ExpansionPanel
                            returnDialog={this.formDialogClose}
                            page={pageID}
                            forms={forms}
                            sectionGeometry={actSection[0].has_geometry}
                            form_or_map={actSection[0].form_or_map}
                            sectionid={actSection[0].id}
                            has_confirmation={actSection[0].has_confirmation}
                        />
                        <Dialog open={report_selection} classes={{ paper: classes.minWidth2 }}>
                            <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                                <Typography variant="h5" className={classes.flex}>
                                    Select Report
                                </Typography>
                                <IconButton onClick={() => this.setState({ report_selection: false })}>
                                    <Close />
                                </IconButton>
                            </Toolbar>
                            <DialogContent>
                                <DialogContentText>
                                    <List>
                                        {(!templates || templates.length === 0) && (
                                            <ListItem
                                                button
                                                onClick={() => {
                                                    window.open(`/reports/${authState.user.city_id}/${report_selection_pr}/word/parent`);
                                                    this.setState({ report_selection: false });
                                                }}>
                                                <ListItemText primary="MS4Front Basic Report" />
                                            </ListItem>
                                        )}
                                        {templates.map((t) => {
                                            return (
                                                <ListItem
                                                    key={t.id}
                                                    button
                                                    title="Generate Report as Word Document"
                                                    style={{ borderBottom: "1px solid lightgray" /*, paddingRight: 84*/ }}
                                                    onClick={() => {
                                                        window.open(
                                                            "/reports/custom/" + authState.user.city_id + "/" + report_selection_rs + "/" + t.id
                                                        );
                                                        this.setState({ report_selection: false });
                                                    }}>
                                                    <ListItemText primary={t.name} />
                                                    <ListItemSecondaryAction>
                                                        <IconButton
                                                            edge="end"
                                                            title="Generate Report as PDF"
                                                            aria-label="pdf"
                                                            onClick={() => {
                                                                window.open(
                                                                    "/reports/custom/" +
                                                                        authState.user.city_id +
                                                                        "/" +
                                                                        report_selection_rs +
                                                                        "/" +
                                                                        t.id +
                                                                        "/pdf"
                                                                );
                                                                this.setState({ report_selection: false });
                                                            }}>
                                                            <PictureAsPdfIcon />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </DialogContentText>
                            </DialogContent>
                        </Dialog>
                        {templates && (
                            <EmailForm open={emailFormOpen} url={emailUrl} report_name={emailReportName} returnDialog={this.returnDialog} />
                        )}
                    </Paper>
                </>
            );
        } else {
            //child table for a 'specific' parent recordsection
            if (recordsection.length > 0) {
                //get all columns names
                let counter = 0;
                while (counter !== recordsection.length) {
                    // eslint-disable-next-line
                    recordsection[counter].columns.forEach(function (c) {
                        var found;
                        columnRecord.find((f) => {
                            if (f.id !== c.field) {
                                return (found = c.field);
                            } else {
                                return null;
                            }
                        });

                        if (columnRecord.length === 0) {
                            if (!c.is_id) {
                                columnRecord.push({
                                    id: c.field,
                                    label: c.field,
                                    type: c.type,
                                    allowSort: true,
                                });
                            }
                        } else if (found) {
                            if (!c.is_id) {
                                columnRecord.push({
                                    id: found,
                                    label: found,
                                    type: c.type,
                                    allowSort: true,
                                });
                            }
                        }
                    });
                    counter++;
                }
            }

            //select all and delete shown occurs rows are selected
            if (deleteMultiple.length !== 0) {
                columnRecord[0] = {
                    delete: true,
                    allowSort: false,
                    handleDelete: this.handleDelete,
                    select: true,
                    handleSelect: this.handleSelectAll,
                    checked: deleteChecked, //used to uncheckbox with unselecting
                };
            } else {
                //select all only
                columnRecord[0] = {
                    id: "selectall",
                    select: true,
                    allowSort: false,
                    handleSelect: this.handleSelectAll,
                };
            }

            if (hasFormName.length > 0) {
                columnRecord.splice(1, 0, {
                    id: "Form",
                    label: "Form Name",
                    colorChange: true,
                });
            }
            // Remove duplicate column objects
            columnRecord = columnRecord.filter((c, index) => {
                return (
                    index ===
                    columnRecord.findIndex((obj) => {
                        return obj.id === c.id;
                    })
                );
            });

            if (!tableData && loadingFields === true) this.setState({ loadingFields: false });
            tableData = recordsection;

            return (
                <>
                    <div style={{ marginTop: 133 }}>
                        <ExpansionPanelFilters
                            pixels={this.handleTablePixels}
                            actSection={actSection}
                            hasFilters={hasFilters}
                            setCookieFilter={this.handleCookie}
                            dynamicLoading={this.dynamicLoading}
                            dummyState={this.dummyState}
                        />
                    </div>
                    <br className={classes.hideIfSmall} />{" "}
                    <Button
                        fullWidth
                        disabled={readOnly}
                        className={classes.gutterBottom}
                        gutterBottom={true}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            if (forms.length === 0) {
                                this.setState({ noFormOpen: true });
                            } else {
                                this.setState({ formDialogStatus: true });
                            }
                        }}>
                        <AddIcon className={classes.iconAdd} />
                        &nbsp;&nbsp;&nbsp;Create New
                    </Button>
                    {totalCount > 25 && (
                        <div
                            style={{
                                backgroundColor: "#dddddd",
                                marginTop: 8,
                                borderRadius: 1,
                            }}>
                            <TablePagination
                                colSpan={columnRecord.length}
                                count={totalCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActionsWrapped}
                            />
                        </div>
                    )}
                    <Paper className={classes.childRoot} style={{ maxHeight: tablePixels !== 0 ? `calc(100% - ${tablePixels}px)` : null }}>
                        <Table className={classes.table} stickyHeader>
                            <EnhancedTableHead
                                recordsView={true}
                                disabled={readOnly}
                                key={newSection.id}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={this.handleRequestSort}
                                onClearSort={this.handleClearSort}
                                columnData={!tableLoading && !deleting ? columnRecord : []}
                            />
                            <TableBody>
                                {tableLoading && !deleting && (
                                    <TableRow>
                                        <CustomTableCell colSpan={0} className={classes.centerAlign} style={{ paddingBottom: 16 }}>
                                            Loading Table Records...
                                            <CircularProgress size={30} className={classes.progress} />
                                        </CustomTableCell>
                                    </TableRow>
                                )}
                                {deleting && (
                                    <TableRow>
                                        <CustomTableCell colSpan={0} className={classes.centerAlign} style={{ paddingBottom: 16 }}>
                                            Deleting Table Records...
                                            <CircularProgress size={30} className={classes.progress} />
                                        </CustomTableCell>
                                    </TableRow>
                                )}
                                {(() => {
                                    if (tableLoading || deleting) return null;

                                    // Group columns with same name together
                                    tableData.forEach(function (n) {
                                        var cols = [];
                                        n.columns.forEach(function (c) {
                                            // If column isn't in temp array yet add it
                                            if (cols.filter((e) => e.field === c.field).length === 0) {
                                                cols.push(c);
                                            } else if (c.value) {
                                                // Else the column already is in the temp array
                                                // And if this new column has a value that means the other column has no value
                                                // Update the value here
                                                var objIndex = cols.findIndex((e) => e.field === c.field);
                                                cols[objIndex].value = c.value;
                                            }
                                        });
                                        n.cols = cols;
                                    });

                                    if (totalCount < 1) {
                                        return (
                                            <TableRow>
                                                <CustomTableCell colSpan={columnRecord.length} className={classes.centerAlign}>
                                                    No Records Available
                                                </CustomTableCell>
                                            </TableRow>
                                        );
                                    }

                                    return tableData.map((n) => {
                                        return (
                                            <TableRow hover key={n.id}>
                                                {/* {window.innerWidth >= 960 && ( */}
                                                <CustomTableCell className={classes.nowrap} style={{ width: 165 }}>
                                                    {readOnly === false && (
                                                        <Formm key={key} defaultValues={this.defaultValues()} getApi={(el) => (this.formApi = el)}>
                                                            {(formApi) => (
                                                                <form style={{ display: "inline-block" }}>
                                                                    <Tooltip title="Delete">
                                                                        <Checkbox
                                                                            disabled={readOnly}
                                                                            key={n.id}
                                                                            field={`chk_${n.id}`}
                                                                            eventHandle={(e) => {
                                                                                if (e === true) {
                                                                                    if (!deleteMultiple.find((d) => d === n.parentrecord)) {
                                                                                        let clone = [...deleteMultiple];
                                                                                        clone.push(n.id);

                                                                                        this.setState({
                                                                                            deleteMultiple: clone,
                                                                                        });
                                                                                    }
                                                                                } else {
                                                                                    let ix = deleteMultiple.findIndex((d) => d === n.id);
                                                                                    if (ix > -1) {
                                                                                        deleteMultiple.splice(ix, 1);

                                                                                        this.setState({ deleteMultiple });
                                                                                    }
                                                                                }
                                                                            }}
                                                                            label=""
                                                                        />
                                                                    </Tooltip>
                                                                </form>
                                                            )}
                                                        </Formm>
                                                    )}
                                                    <Tooltip title="Form Edit" style={{ marginLeft: 15 }}>
                                                        <Button
                                                            component={Link}
                                                            to={
                                                                "/page/" +
                                                                pageID +
                                                                "/parentrecord/" +
                                                                n.parentrecord +
                                                                "/section/" +
                                                                newSection.id +
                                                                "/recordsection/" +
                                                                n.id +
                                                                "/form"
                                                            }
                                                            className={classes.deleteWidth}>
                                                            <EditIcon color="primary" />
                                                        </Button>
                                                    </Tooltip>
                                                    {window.innerWidth >= 960 && (
                                                        <>
                                                            {actSection[0].has_geometry && (
                                                                <Tooltip title={n.geometry ? "Map Edit" : "Map Edit - No Mapped Location"}>
                                                                    <Button
                                                                        component={Link}
                                                                        to={
                                                                            "/page/" +
                                                                            pageID +
                                                                            "/parentrecord/" +
                                                                            n.parentrecord +
                                                                            "/recordsection/" +
                                                                            n.id +
                                                                            "/map"
                                                                        }
                                                                        className={classes.deleteWidth}>
                                                                        <EditLocation className={!n.geometry && classes.orange} color="primary" />
                                                                    </Button>
                                                                </Tooltip>
                                                            )}
                                                            <Tooltip title="Word Document">
                                                                <Button
                                                                    className={classes.deleteWidth}
                                                                    onClick={() => {
                                                                        if (templates.length === 0)
                                                                            window.open(
                                                                                `/reports/${authState.user.city_id}/${n.parentrecord}/${n.id}/word`
                                                                            );
                                                                        else {
                                                                            this.setState({
                                                                                report_selection: true,
                                                                                report_selection_pr: n.parentrecord,
                                                                                report_selection_rs: n.id,
                                                                            });
                                                                        }
                                                                    }}>
                                                                    <i className="material-icons" color="primary">
                                                                        assignment
                                                                    </i>
                                                                </Button>
                                                            </Tooltip>{" "}
                                                        </>
                                                    )}
                                                </CustomTableCell>
                                                {/* */}
                                                {/* PRINTING OF THE DATA IN THE TABLE */}
                                                {columnRecord.map((column, index) => {
                                                    if (index !== 0 /*&& window.innerWidth >= 960) || window.innerWidth < 960*/) {
                                                        //dont print 0 as that is button location
                                                        var found = n.cols.find((actCol) => {
                                                            if (actCol.field === column.id) {
                                                                return actCol.value;
                                                            } else if (column.id === "Form" && actCol.is_column) {
                                                                return (actCol["value"] = actCol.form);
                                                            }
                                                            return null;
                                                        });
                                                        if (column.type === "Phone Number") {
                                                            return (
                                                                <CustomTableCell key={index + column.id}>
                                                                    <NumberFormat
                                                                        format="+1 (###) ###-####"
                                                                        allowEmptyFormatting
                                                                        mask="_"
                                                                        displayType={"text"}
                                                                        value={found ? found.value : "-"}
                                                                    />
                                                                </CustomTableCell>
                                                            );
                                                        } else if (column.label === "Form Name") {
                                                            return (
                                                                <CustomTableCell /*style={{ whiteSpace: "pre" }}*/ key={index + column.id}>
                                                                    <Tooltip title="Form Name associated with this record">
                                                                        <div>
                                                                            {found
                                                                                ? found.value.length > 49
                                                                                    ? found.value.substring(0, 50) + "..."
                                                                                    : found.value
                                                                                : "-"}
                                                                        </div>
                                                                    </Tooltip>
                                                                </CustomTableCell>
                                                            );
                                                        } else if (column.type === "Numeric") {
                                                            return (
                                                                <CustomTableCell key={index + column.id}>
                                                                    <NumberFormat
                                                                        // {...other}
                                                                        // getInputRef={inputRef}
                                                                        displayType={"text"}
                                                                        value={found ? found.value : "-"}
                                                                        decimalScale={found && found.decimals}
                                                                        thousandSeparator={found ? found.thousand_seperator : false}
                                                                    />
                                                                </CustomTableCell>
                                                            );
                                                        } else if (column.type === "Currency") {
                                                            return (
                                                                <CustomTableCell key={column.id}>
                                                                    <NumberFormat
                                                                        prefix="$"
                                                                        mask="_"
                                                                        displayType={"text"}
                                                                        value={found ? found.value : "-"}
                                                                        thousandSeparator={true}
                                                                    />
                                                                </CustomTableCell>
                                                            );
                                                        } else if (column.type === "Calculated") {
                                                            return (
                                                                <CustomTableCell key={index + column.id}>
                                                                    <NumberFormat
                                                                        // {...other}
                                                                        // getInputRef={inputRef}
                                                                        displayType={"text"}
                                                                        value={found ? found.value : "-"}
                                                                        decimalScale={found && found.decimals}
                                                                        thousandSeparator={true}
                                                                    />
                                                                </CustomTableCell>
                                                            );
                                                        } else {
                                                            if (!found || !found.value) {
                                                                return (
                                                                    <CustomTableCell key={index + column.id}>
                                                                        {"-"}
                                                                    </CustomTableCell>
                                                                )
                                                            }

                                                            if (found.value.length > 250) {
                                                                return (
                                                                    <Tooltip title={found.value.replaceAll("_", " ").replaceAll(",", ", ")}>
                                                                        <CustomTableCell key={index + column.id}>
                                                                            <div className={classes.ellipsis}>
                                                                                {found.value.replaceAll("_", " ").replaceAll(",", ", ")}
                                                                            </div>
                                                                        </CustomTableCell>
                                                                    </Tooltip>
                                                                )
                                                            } else {
                                                                return (
                                                                    <CustomTableCell key={index + column.id}>
                                                                        {found.value.replaceAll("_", " ").replaceAll(",", ", ")}
                                                                    </CustomTableCell>
                                                                )
                                                            }
                                                        }
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                            </TableRow>
                                        );
                                    });
                                })()}
                            </TableBody>
                            {totalCount > 1 && (
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            colSpan={columnRecord.length}
                                            count={totalCount}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActionsWrapped}
                                        />
                                    </TableRow>
                                </TableFooter>
                            )}
                        </Table>
                        <WarningDialog
                            confirmAction={() => {
                                const { page } = this.state;
                                const _this = this;

                                _this.setState({ deleteMultiple: [], deleteParentorChild: "", deleting: true });
                                _this.unselectAll();

                                var counter = 0;
                                deleteMultiple.forEach((d, i) => {
                                    ormRecordSectionDelete(d).then(() => {
                                        counter++;
                                        if (deleteMultiple.length === counter) {
                                            //if we delete all of the records in the viewable table we want to update the cookie to rollback page & update rsections
                                            if (page > 0) {
                                                setTimeout(() => {
                                                    _this.handleCookie(page - 1, null);
                                                }, 500);
                                            } else {
                                                setTimeout(() => {
                                                    _this.handleCookie(page, null);
                                                }, 500);
                                            }
                                            setTimeout(() => {
                                                _this.loadTotalRecords(null, true);
                                            }, 1000);
                                        }
                                    });
                                });
                                this.setState({ deleteMultipleOpen: false });
                            }}
                            cancelAction={() => this.unselectAll()}
                            open={deleteMultipleOpen}
                            title={`Delete ${deleteMultiple.length} Records`}
                            text="WARNING: The selected records will be removed. Are you sure you wish to permanently delete?"
                        />
                        <WarningDialog open={deleting} tableDeleting title="Deleting Records Please Wait" text="Please Wait...." />
                        <WarningDialog
                            cancelAction={() => this.setState({ noFormOpen: false })}
                            open={noFormOpen}
                            noDeleteButtons
                            title="Form Not Created"
                            text="A new record can not be created until a 'form' has been configured for this section. Please go to the configuration module and configure a form first or talk to the software administrator."
                        />
                        <FormSelector
                            authState={authState}
                            formDialogClose={this.formDialogClose}
                            formDialogStatus={this.state.formDialogStatus}
                            forms={forms}
                            history={history}
                            classes={classes}
                            loadingFields={this.props.loadingFields}
                            pageID={pageID}
                            parent_recordId={parent_recordId}
                            ormRecordSectionCreate={ormRecordSectionCreate}
                            ormRecordFieldCreate={ormRecordFieldCreate}
                            ormRecordSectionUpdateLocalOnly={ormRecordSectionUpdateLocalOnly}
                        />
                        <Dialog open={report_selection} classes={{ paper: classes.minWidth2 }}>
                            <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                                <Typography variant="h5" className={classes.flex}>
                                    Select Report
                                </Typography>  
                                <IconButton onClick={() => this.setState({ report_selection: false })}>
                                    <Close />
                                </IconButton>   
                            </Toolbar>
                            <DialogContent>
                                <DialogContentText>
                                <Typography variant="h7"   >
                                <u>Record Form Name: "{recordsection && recordsection.length > 0 && recordsection[0].columns.find(c=>c.form?c:null).form}"</u>
                                </Typography>
                                    <List>
                                        {(!templates || templates.length === 0) && (
                                            <ListItem
                                                button
                                                onClick={() => {
                                                    window.open(
                                                        `/reports/${authState.user.city_id}/${report_selection_pr}/${report_selection_rs}/word`
                                                    );
                                                    this.setState({ report_selection: false });
                                                }}>
                                                <ListItemText primary="MS4Front Basic Report" />
                                            </ListItem>
                                        )}
                                        {templates.map((t) => {
                                            return (
                                                <ListItem
                                                    button
                                                    title="Generate Report as Word Document"
                                                    style={{ borderBottom: "1px solid lightgray", paddingRight: 84 }}
                                                    onClick={() => {
                                                        window.open(
                                                            "/reports/custom/" + authState.user.city_id + "/" + report_selection_rs + "/" + t.id
                                                        );
                                                        this.setState({ report_selection: false });
                                                    }}>
                                                    <ListItemText primary={t.name} />
                                                    <ListItemSecondaryAction>
                                                        <IconButton edge="end" title="More Options" onClick={(e) => this.handleSmallMenu2(e, t)}>
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </DialogContentText>
                            </DialogContent>
                        </Dialog>
                        {templates && (
                            <EmailForm open={emailFormOpen} url={emailUrl} report_name={emailReportName} returnDialog={this.returnDialog} />
                        )}
                        <Menu
                            id="menu-small2"
                            anchorEl={smallAnchor2}
                            classes={{
                                paper: classes.list,
                            }}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={smallMenuOpen2}
                            onClose={this.handleSmallMenuClose2}>
                            <ListItem button className={classes.topListItem}>
                                <ListItemText
                                    primary={<Typography variant="h6">Options</Typography>}
                                    classes={{
                                        root: classes.topListText,
                                        primary: classes.topListText,
                                    }}
                                />
                            </ListItem>
                            <ListItem
                                button
                                onClick={() => {
                                    return (
                                        window.open("/reports/custom/" + authState.user.city_id + "/" + report_selection_rs + "/" + activeReport.id),
                                        this.handleSmallMenuClose2(),
                                        this.setState({ report_selection: false })
                                    );
                                }}
                                className={classes.listItem}>
                                <ListItemIcon>
                                    <Description />
                                </ListItemIcon>
                                <ListItemText primary="Generate Report as Word Document" />
                            </ListItem>
                            <ListItem
                                button
                                onClick={() => {
                                    return (
                                        window.open(
                                            "/reports/custom/" + authState.user.city_id + "/" + report_selection_rs + "/" + activeReport.id + "/pdf"
                                        ),
                                        this.handleSmallMenuClose2(),
                                        this.setState({ report_selection: false })
                                    );
                                }}
                                className={classes.listItem}>
                                <ListItemIcon>
                                    <PictureAsPdfIcon />
                                </ListItemIcon>
                                <ListItemText primary="Generate Report as PDF" />
                            </ListItem>
                            <ListItem
                                button
                                onClick={() => {
                                    return (
                                        this.setState({
                                            emailFormOpen: true,
                                            emailReportName: activeReport.name,
                                            emailUrl: report_selection_rs + "/" + activeReport.id,
                                        }),
                                        this.handleSmallMenuClose2(),
                                        this.setState({ report_selection: false })
                                    );
                                }}
                                className={classes.listItem}>
                                <ListItemIcon>
                                    <AttachEmailIcon />
                                </ListItemIcon>
                                <ListItemText primary="Email Report" />
                            </ListItem>
                        </Menu>
                    </Paper>
                </>
            );
        }
    }
}
ViewRecordTable.displayName = "ViewRecordTable";

// This connects the parentRecord tables to allow for deletion ormParentRecordDelete,
ViewRecordTable = connect(
    (state, ownProps) => ({
        recordsection: getRecordSections(state, ownProps),
        parent_recordId: getParentRecordId(state, ownProps),
        sections: getAllSections(state, ownProps),
        actSection: getSections(state, ownProps),
        templates: getTemplates(state, ownProps),
        forms: getForms(state, ownProps),
        authState: state.auth, //component wide state prop "authState" gets derived info from database
        permission: getPermission(state, ownProps),
    }),
    {
        ...ParentRecord.actions,
        ...Section.actions,
        ...RecordSection.actions,
        ...RecordField.actions,
        ...authActions,
    }
)(ViewRecordTable);

export default withStyles(styles)(withRouter(ViewRecordTable));
