import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteIcon from "@material-ui/icons/Delete";

const styles = {
    supportIcon: {
        width: "3em",
        height: "3em",
        color: "#fff",
        marginLeft: 24,
    },
    titleContainer: {
        backgroundColor: "#74A94C",
        textAlign: "center",
    },
    questionTitle: {
        marginTop: ".6em",
        color: "#74A94C",
        fontWeight: 500,
    },
    centerButtons: {
        justifyContent: "center",
        marginTop: 24,
    },
    primaryButton: {
        backgroundColor: "#74A94C",
        color: "#fff",
    },
    nooverflow: {
        overflow: "hidden",
    },
    closeIcon: {
        float: "right",
        color: "#fff",
        marginTop: -16,
        marginRight: -16,
    },
};

const WarningDialog = ({
    title,
    text,
    classes,
    confirmAction,
    cancelAction,
    confirmText,
    cancelText,
    noActions,
    onClose,
    noDeleteButtons,
    tableDeleting,
    contactLoading,
    ...other
}) => (
    <Dialog {...other} onClose={onClose} classes={{ paper: classes.nooverflow }}>
        <DialogTitle className={classes.titleContainer}>
            {!tableDeleting && (
                <>
                    <ErrorOutline className={classes.supportIcon} />
                    <IconButton onClick={noActions ? onClose : cancelAction} className={classes.closeIcon}>
                        <Close />
                    </IconButton>
                </>
            )}
            {tableDeleting && <DeleteIcon className={classes.supportIcon} />}
        </DialogTitle>
        <DialogContent>
            <Typography variant="h5" className={classes.questionTitle} gutterBottom>
                {title}
            </Typography>
            <DialogContentText>{text}</DialogContentText>
            {contactLoading && <CircularProgress size={24} style={{ display: "table", marginLeft: "auto", marginRight: "auto" }} />}
            {!contactLoading && !noActions && !noDeleteButtons && !tableDeleting && (
                <DialogActions className={classes.centerButtons}>
                    <Button onClick={confirmAction} color="primary" variant="contained">
                        {confirmText ? confirmText : "Delete Entry"}
                    </Button>
                    <Button onClick={cancelAction} autoFocus>
                        {cancelText ? cancelText : "Do Not Delete"}
                    </Button>
                </DialogActions>
            )}
            {!contactLoading &&
                !noActions &&
                noDeleteButtons && ( //Just for a warning with an 'ok' button
                    <DialogActions className={classes.centerButtons}>
                        <Button style={{ width: 88 }} onClick={confirmAction} color="primary" variant="contained">
                            {confirmText}
                        </Button>
                    </DialogActions>
                )}
        </DialogContent>
    </Dialog>
);
WarningDialog.displayName = "WarningDialog";
export default withStyles(styles)(WarningDialog);
