//REACTSELECT
import React from "react";
import AsyncSelect from "react-select/async";
import { FormField } from "react-form";
import Typography from "@material-ui/core/Typography";

//styles for select component.
const colourStyles = {
    control: (styles, state) => ({
        //1st param is default styles, to modify use 2nd prop 'state' and modify
        // refer to https://react-select.com/styles#overriding-the-theme
        ...styles,
        backgroundColor: "white",
        margin: "-1.5px",
        borderColor: state.isFocused ? "lightgreen" : "gray",
        "&:hover": { borderColor: state.isFocused ? "lightgreen" : "gray" },
        font: "16px",
        color: "#262626",
        boxShadow: "none",
        minHeight: 32,
    }),

    singleValue: (styles, state) => ({
        ...styles,
        fontSize: "15px",
        fontWeight: 500,
        color: "#262626 !important",
        fontFamily: "'Source Sans Pro', sans-serif",
    }),

    container: (styles, state) => ({
        ...styles,
        borderRadius: "4px",
        marginTop: 4,
        borderColor: state.isFocused && "#a5db7a",
        boxShadow: state.isFocused && "0 0 0 0.2rem rgba(0,123,255,.25)",
    }),
    //for overriding optgroups styling of header
    groupHeading: () => ({
        color: "#515151",
        backgroundColor: "#d3d3d3",
        paddingLeft: "1%",
        paddingTop: 8,
        height: 30,
        fontWeight: 500,
    }),
    group: () => ({
        //each group styling, no passing of params as we override everything
        paddingTop: 0,
        paddingBottom: 0,
    }),
    MenuList: () => ({
        paddingTop: 0,
    }),
};
const colourStylesError = {
    control: (styles, state) => ({
        //1st param is default styles, to modify use 2nd prop 'state' and modify
        // refer to https://react-select.com/styles#overriding-the-theme
        ...styles,
        backgroundColor: "white",
        margin: "-1.5px",
        borderColor: state.isFocused ? "lightgreen" : "#f44336",
        "&:hover": { borderColor: state.isFocused ? "lightgreen" : "#f44336" },
        font: "16px",
        color: "#262626",
        boxShadow: "none",
    }),

    container: (styles, state) => ({
        ...styles,
        borderRadius: "4px",
        marginTop: 4,
        borderColor: state.isFocused && "#a5db7a",
        boxShadow: state.isFocused && "0 0 0 0.2rem rgba(0,123,255,.25)",
    }),
};

class SelectWrapper extends React.Component {
    handleChange = (event) => {
        const { fieldApi, eventHandle } = this.props;
        const { setValue, setTouched } = fieldApi;

        setTouched();
        if (!event || event.length === 0) {
            //removal case for 'drop down' & 'multiselect' case
            setValue("");
            if (eventHandle) eventHandle("");
            return;
        }
        //multicase save--sets 1 or more selections
        if (event[0]) {
            let temp = "";
            event.forEach((selection, index) => {
                temp += selection.value;
                if (index !== event.length - 1) temp += ","; //add comma after each selection
            });
            /*return */ setValue(temp);
            if (eventHandle) eventHandle(temp);
        } else if (event.type) {
            // adv search case only has type
            setValue(event.value);
            if (eventHandle) eventHandle(event);
        } else if (event.value || event.type) {
            //dropdown single case save

            setValue(event.value);
            if (eventHandle) eventHandle(event.value);
        } else if (event.searchvalue) {
            if (eventHandle) eventHandle(event.searchvalue);
        }
    };

    //input = value user types in, callback will fire after we get data and pass back to dropdown window, when user selects will be passed to handleChange function
    loadOptions = (inputValue, callback) => {
        const { contact_field, quick_search_field, dropDownAutoLoad, parentRecordId, groupfield, parentSection, relatedField, authState, page } =
            this.props;

        clearTimeout(this.typingTimer);
        if (dropDownAutoLoad) {
            //load values into dropdown from another section case, load instantly
            var input = inputValue === "" ? 0 : inputValue;
            this.typingTimer = setTimeout(function () {
                return fetch(
                    "csvvalues/" +
                        groupfield.populate_field +
                        "/" +
                        parentRecordId +
                        "/" +
                        groupfield.populate_section +
                        "/" +
                        parentSection +
                        "/" +
                        input +
                        `?t=${Date.now()}`
                )
                    .then((result) => result.json())
                    .then((data) => {
                        callback(data);
                    });
            }, 0);
        } else if (contact_field) {
            //contacts on a section search
            this.typingTimer = setTimeout(function () {
                return fetch("contacts/filter/" + contact_field + "/" + inputValue + `?t=${Date.now()}`)
                    .then((result) => result.json())
                    .then((data) => {
                        callback(data);
                    });
            }, 1000);
        }
        //quicksearch for contacts on dashboard
        else if (quick_search_field && quick_search_field.contactfield) {
            this.typingTimer = setTimeout(function () {
                return fetch("quicksearch/filter/" + quick_search_field.value + "/" + inputValue + `/True?t=${Date.now()}`)
                    .then((result) => result.json())
                    .then((data) => {
                        callback(data);
                    });
            }, 1000);
        } else if (quick_search_field) {
            //quick recordsection search
            this.typingTimer = setTimeout(function () {
                return fetch("quicksearch/filter/" + quick_search_field + "/" + inputValue + "/" + page + `/False?t=${Date.now()}`)
                    .then((result) => result.json())
                    .then((data) => {
                        callback(data);
                    });
            }, 1000);
        } else if (relatedField) {
            //quick relatedfield search
            this.typingTimer = setTimeout(function () {
                return fetch("relatedfield/filter/" + inputValue + "/" + authState.user.city_id + `?t=${Date.now()}`)
                    .then((result) => result.json())
                    .then((data) => {
                        callback(data);
                    });
            }, 1000);
        }
    };

    loadMessage = (e) => {
        return "Type to Load Options";
    };

    render() {
        const { fieldApi, classes, label, options, section, formSelection, repeatable, dropDownAutoLoad, ...rest } = this.props;

        var value = fieldApi.getValue();
        if (typeof value === "string") {
            var hit = value.split(","); //convert to array
        }

        //logic for setting the output value that will be seen on the screen
        var obj = {}; //obj used to encapsulate the object for outputting with selects 'defaultValue' prop

        if (value === "") {
            //nothing was selected output on screen
            obj = null;
        } else if (value && hit) {
            //all previously saved, load into and object 'obj' to be outputted in selects 'defaultValue' prop
            obj = hit.map((val) => {
                if (val) {
                    let temp = {};
                    temp.value = val;
                    temp.label = val;
                    temp.color = "#3b7dd4";
                    return temp;
                }
                return null;
            });
        } else {
            //needed for inital render but check other cases first
            obj = null;
        }

        //this is used for advancedSearch Field setups, it uses an 'id' and 'label' for each saved value to be rendered.
        var defaultVal = [];
        if (rest.defaultValue) {
            rest.defaultValue.forEach((v) => {
                var t = {};
                t.value = v.value;
                t.label = v.label;
                t.color = "#3b7dd4";
                defaultVal.push(t);
            });
        }

        return (
            <div>
                {label && <Typography style={{ marginTop: !repeatable ? -8 : 6 }}>{label}</Typography>}

                <AsyncSelect //default case for dropdown field setups, etc.
                    isMulti={rest.isMulti} //allow multiple
                    defaultValue={rest.defaultValue ? defaultVal : obj} //defaultValue is used for when not using form fields. ie when grabbing multiple fields ids
                    isDisabled={rest.disabled}
                    isClearable
                    isSearchable
                    value={dropDownAutoLoad ? obj : null} //allows window to clear after selection of a record (quicksearch related), if is used from grabbing from another section use that value otherwise null
                    //onBlur={setTouched}
                    onChange={this.handleChange} // call local handlechange for normal fields in records, for passing upwards use advancedDialog prop to true
                    name="dropdowns"
                    options={options} //value/label table
                    styles={fieldApi && fieldApi.getError() && obj === null ? colourStylesError : colourStyles} //calls object above for styling, any errors use diff object
                    menuIsOpen={rest.menuIsOpen}
                    //defaultOptions

                    loadOptions={this.loadOptions} //async fetching of backend when typed into window
                    noOptionsMessage={this.loadMessage}
                    placeholder={dropDownAutoLoad ? "" : "Type to Search"}
                    defaultOptions={dropDownAutoLoad ? true : false} //this triggers autoloading when rendering page--only used for dropdown values from another section
                />
                {rest.required && fieldApi && fieldApi.getError() && (
                    <input //use to allow required to come up on screen
                        tabIndex={-1}
                        value={obj === null ? "Required Field" : ""}
                        style={{
                            opacity: 1,
                            border: 0,
                            color: "#f44336",
                            fontSize: 11,
                            zIndex: 0,
                        }}
                        autoComplete="off"
                    />
                )}
            </div>
        );
    }
}
SelectWrapper.displayName = "SelectWrapper";
const ReactSelect = FormField(SelectWrapper);

export default ReactSelect;
