import React, { Component } from "react";
import { withStyles, Typography } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { FormField } from "react-form";

const styles = (theme) => ({
    root: {
        display: "flex",
    },
    formControl: {
        margin: theme.spacing(3),
        marginLeft: "-14px",
        marginTop: "8px",
    },
    group: {
        margin: theme.spacing(1, 0),
    },
    nowrap: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "100%",
    },
});

class RadioButtonsGroup extends Component {
    handleChange = (event) => {
        const { fieldApi, eventHandle } = this.props;
        const { setValue, setTouched } = fieldApi;
        setTouched();
        if (event.target.value) {
            setValue(event.target.value);

            if (eventHandle) eventHandle(event.target.value);
        }
    };

    render() {
        const { fieldApi, classes, label, buttonName, ...rest } = this.props;
        var value = fieldApi.getValue();

        return (
            <div className={classes.root}>
                {buttonName ? (
                    buttonName.map((name) => {
                        return (
                            <FormControl required={rest.required} component="fieldset" className={classes.formControl}>
                                <FormLabel component="legend">{label}</FormLabel>
                                <RadioGroup name="gender2" className={classes.group} value={value} onChange={this.handleChange} row>
                                    <FormControlLabel
                                        value={name}
                                        control={<Radio color="primary" required={rest.required} disabled={rest.disabled} />}
                                        label={name}
                                        labelPlacement="start"
                                    />
                                    {/* <FormControlLabel
                                        value="No"
                                        control={<Radio color="primary" required={rest.required} disabled={rest.disabled} />}
                                        label="No"
                                        labelPlacement="start"
                                    /> */}
                                </RadioGroup>
                            </FormControl>
                        );
                    })
                ) : (
                    <FormControl component="fieldset" className={classes.formControl} required={rest.required}>
                        <FormLabel style={{ display: "inline-flex" }} component="legend">
                            <Typography className={classes.nowrap} style={{ marginTop: -0, zIndex: 1, marginLeft: 14 }}>
                                {label}
                            </Typography>
                        </FormLabel>
                        <RadioGroup name="gender2" className={classes.group} value={value} onChange={this.handleChange} row>
                            <FormControlLabel
                                value="Yes"
                                control={<Radio color="primary" required={rest.required} disabled={rest.disabled} />}
                                label="Yes"
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                value="No"
                                control={<Radio color="primary" required={rest.required} disabled={rest.disabled} />}
                                label="No"
                                labelPlacement="start"
                            />
                        </RadioGroup>
                    </FormControl>
                )}
            </div>
        );
    }
}

RadioButtonsGroup.displayName = "RadioButtonsGroup";
const RadioButtonV4 = FormField(RadioButtonsGroup);

export default withStyles(styles)(RadioButtonV4);
