import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";

const styles = {
    root: {
        height: "90%",
    },
    message: {
        textAlign: "center",
    },
    error: {
        fontSize: "1.5em",
        color: "red",
    },
};

class NotReady extends Component {
    render() {
        const { classes, storeReady, message, match } = this.props;
        return (
            <Grid container className={classes.root} justify="center" alignItems="center">
                <Grid item className={classes.message}>
                    {!storeReady && (
                        // While store is still initializing, don't assume user isn't logged in.
                        <>
                            <CircularProgress size={100} thickness={1.5} />
                            <Typography>{message ? message : "Loading Application..."}</Typography>
                        </>
                    )}
                    {storeReady && (
                        <>
                            <Typography className={classes.error} gutterBottom>
                                You must be logged in to view this module.
                            </Typography>
                            <Button component={Link} to={"/?return=" + match.url} variant="raised" color="primary">
                                Return to Home
                            </Button>
                        </>
                    )}
                </Grid>
            </Grid>
        );
    }
}
NotReady.displayName = "NotReady";
export default withStyles(styles)(withRouter(NotReady));
