import React, { Component } from "react";
import classNames from "classnames";

import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { withStyles } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";

const styles = (theme) => ({
    snackbar: {
        marginBottom: 40,
    },
    snackbarContainer: {
        backgroundColor: "#d32f2f",
    },
    snackbarContainerSuccess: {
        backgroundColor: "#43a047",
    },
    snackbarMessage: {
        display: "flex",
        alignItems: "center",
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
});

class SnackbarWrapper extends Component {
    autoClose = (e) => {};

    render() {
        const { missing, classes, snackbarOpen, handleSnackbarClose, isSuccess, operationType, snackbarMessage, attachment, advSearch, openTime } =
            this.props;

        return isSuccess ? (
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                className={classes.snackbar}
                open={snackbarOpen}
                autoHideDuration={openTime ? openTime : 3000}
                onClose={handleSnackbarClose}>
                <SnackbarContent
                    className={classes.snackbarContainerSuccess}
                    message={
                        <span className={classes.snackbarMessage}>
                            <CheckCircleIcon className={classNames(classes.icon, classes.iconVariant)} />
                            {"You have successfully " + (snackbarMessage ? snackbarMessage : "saved") + " this " + operationType + "."}
                        </span>
                    }
                />
            </Snackbar>
        ) : (
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                className={classes.snackbar}
                open={snackbarOpen}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}>
                <SnackbarContent
                    className={classes.snackbarContainer}
                    message={
                        attachment ? ( //attachment warning case
                            <span className={classes.snackbarMessage}>
                                <ErrorIcon className={classNames(classes.icon, classes.iconVariant)} />
                                {
                                    "Attachment was not saved please try again"
                                }
                            </span>
                        ) : (
                            //default case
                            <>
                                {" "}
                                {!advSearch && (
                                    <span style={{ whiteSpace: "pre-line" }} className={classes.snackbarMessage}>
                                        <ErrorIcon className={classNames(classes.icon, classes.iconVariant)} />
                                        {`You are missing required ${missing === 1 ? "field" : "fields"}
                                
                                Please enter values for these fields and save. Leaving this module 
                                without saving may result in changes not being saved.`}
                                    </span>
                                )}
                                {advSearch && (
                                    <span style={{ whiteSpace: "pre-line" }} className={classes.snackbarMessage}>
                                        <ErrorIcon className={classNames(classes.icon, classes.iconVariant)} />
                                        {` 
                                           Verify all query fields are properly filled in as zero results returned from the search.
                                         
                                        `}
                                    </span>
                                )}
                            </>
                        )
                    }
                />
            </Snackbar>
        );
    }
}
SnackbarWrapper.displayName = "SnackbarWrapper";
export default withStyles(styles)(SnackbarWrapper);
