//TIMEPICKER
import React, { Component } from "react";
import classNames from "classnames";
import DateFnsUtils from "@date-io/date-fns";
import { withStyles } from "@material-ui/core";
import { TimePicker as MuiTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { FormField } from "react-form";
import FormHelperText from "@material-ui/core/FormHelperText";

const styles = (theme) => ({
    bootstrapInput: {
        borderRadius: 4,
        border: "1px solid #979797",
        padding: "6px 8px",
        width: "calc(100% - 24px)",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderColor: theme.palette.secondary.light,
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
        backgroundColor: "#fff",
        fontSize: "15px",
        fontWeight: 500,
        color: "#262626 !important",
    },
    bootstrapInputError: {
        border: "1px solid #f44336",
        borderRadius: 4,
        padding: "6px 8px",
        width: "calc(100% - 24px)",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderColor: theme.palette.secondary.light,
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
        backgroundColor: "#fff",
        fontSize: "15px",
        fontWeight: 500,
        color: "#262626 !important",
    },
    rootLabelShrink: {
        marginLeft: 0,
        marginTop: -2,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
    },
    rootLabelShrinkExpansionPanel: {
        //for help with label to offset header
        marginLeft: 0,
        marginTop: -7,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
    },
    disabled: {
        backgroundColor: "#EEEEEE",
    },
    button: {
        "& button": {
            border: 0,
        },
        "& button:hover": {
            backgroundColor: "rgba(60, 138, 199, 0.08)",
        },
    },
});

class TimePickerWrapper extends Component {
    handleDateChange = (date) => {
        const { fieldApi, eventHandle } = this.props;
        const { setValue } = fieldApi;

        if (date) {
            if (date.toString() !== "Invalid Date" && date.toString() !== null) {
                setValue(date.toLocaleTimeString());
                if (eventHandle) eventHandle(date.toLocaleTimeString());
            }
        }
    };

    render() {
        const { fieldApi, classes, disabled, required, expansionPanel, ...rest } = this.props;
        const { setTouched, getError, getWarning, getSuccess } = fieldApi;
        var value = fieldApi.getValue();
        const error = getError(),
            warning = getWarning(),
            success = getSuccess(),
            hasError = !!error,
            errorText = error || warning || success;

        if (rest.autoPopulate === true && !value) {
            value = new Date();
            this.handleDateChange(value);
        }

        var time = null;
        //manipulate the time to get proper input to ISOString for timepicker
        if (value)
            if (value.length > 0 && value.length < 12) {
                var timeSplit = value.split(" ");

                var convertedTime = timeSplit;

                if (timeSplit[1] === "PM") {
                    convertedTime = timeSplit[0].split(":");
                    if (convertedTime[0] !== "12") convertedTime[0] = 12 + parseInt(convertedTime[0]); //add 12 to keep PM if under 12
                    convertedTime[0] = convertedTime[0].toString() + ":" + convertedTime[1] + ":" + convertedTime[2];
                }
                if (timeSplit[1] === "AM") {
                    convertedTime = timeSplit[0].split(":");
                    if (convertedTime[0] === "12") {
                        convertedTime[0] = "0:" + convertedTime[1] + ":" + convertedTime[2];
                    } else {
                        convertedTime[0] = convertedTime[0].toString() + ":" + convertedTime[1] + ":" + convertedTime[2];
                    }
                }
                time = new Date("Mon Oct 28 2019 " + convertedTime[0]); //create new to use users time zone
            }

        let InputProps = {};
        InputProps["disableUnderline"] = true;
        InputProps["classes"] = {
            input: classNames(!error ? classes.bootstrapInput : classes.bootstrapInputError, disabled && classes.disabled),
            // error: classes.bootstrapInputError
        };

        return (
            <div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <MuiTimePicker
                        value={time !== undefined ? time : ""}
                        disabled={disabled}
                        required={required}
                        autoOk
                        InputProps={InputProps}
                        InputLabelProps={{
                            shrink: true,
                            classes: {
                                shrink: !expansionPanel ? classes.rootLabelShrink : classes.rootLabelShrinkExpansionPanel,
                            },
                        }}
                        error={hasError}
                        helperText={errorText}
                        okLabel={null}
                        cancelLabel={null}
                        onChange={this.handleDateChange}
                        onBlur={() => setTouched()}
                        variant="dialog"
                        DialogProps={{ className: classes.button }}
                        {...rest}
                    />{" "}
                    {required && !hasError && <FormHelperText style={{ marginTop: -1 }}>Required</FormHelperText>}
                </MuiPickersUtilsProvider>

                {/* <input //use to allow required to come up on screen
                    tabIndex={-1}
                    value={time === null && ""}
                    style={{ opacity: 0, height: 0 }}
                    required={required}
                /> */}
            </div>
        );
    }
}
TimePickerWrapper.displayName = "TimePickerWrapper";
const TimePicker = FormField(TimePickerWrapper);

export default withStyles(styles)(TimePicker);
