import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
//import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Linkk from "@material-ui/core/Link";
import { Link, withRouter } from "react-router-dom";

const styles = (theme) => ({
    root: {
        justifyContent: "center",
        flexWrap: "wrap",
    },
    paper: {
        padding: theme.spacing(1, 1),
        width: "100%",
        // marginLeft: "35%",
        backgroundColor: "inherit",
    },
});

class Breadcrumb extends Component {
    render() {
        const { classes, allPageConfig, sectionConfig, pageName, formName, style } = this.props;
        var firstCrumbName;
        if (allPageConfig) {
            if (this.props.firstCrumbName) firstCrumbName = this.props.firstCrumbName;
            else firstCrumbName = "All Module Configuration";
        }
        if (sectionConfig) var secondCrumbName = `${pageName} Configuration`;

        return (
            <div style={style} className={classes.root}>
                <Paper elevation={0} className={classes.paper}>
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <Linkk color="inherit" underline={"always"} component={Link} to={allPageConfig}>
                            {firstCrumbName}
                        </Linkk>
                        {sectionConfig && (
                            <Linkk color="inherit" underline={"always"} component={Link} to={sectionConfig}>
                                {secondCrumbName}
                            </Linkk>
                        )}
                        <Linkk separator=">" underline={"None"} color="primary">
                            {formName}
                        </Linkk>
                    </Breadcrumbs>
                </Paper>
            </div>
        );
    }
}
Breadcrumb.displayName = "Breadcrumb";
export default withStyles(styles)(withRouter(Breadcrumb));
