import {
    NAV_TOGGLE_NAVIGATION,
    NAV_TOGGLE_PAGES,
    NAV_TOGGLE_REPORTS,
    NAV_TOGGLE_CONFIG,
    DASHBOARD_SELECTOR,
    MAP_SETTINGS,
    TAB_REF,
    SW_CHECKING,
    SW_REGISTERED,
    SW_INSTALLED,
    SW_UPDATE,
    SW_ERROR,
    SW_DISABLED,
    STORE_READY,
} from "./actions";

function navReducer(state = {}, action) {
    //action has two props from actions.js (type = TAB_REF, etc or payload)
    switch (action.type) {
        case NAV_TOGGLE_NAVIGATION:
            // If closing the navigation panel then also collapse the main menus
            // Don't worry about sub-menus - it is better to retain their status
            if (state.navigationOpen) {
                return {
                    ...state,
                    navigationOpen: !state.navigationOpen,
                    pagesOpen: false,
                    reportsOpen: false,
                    configOpen: false,
                    pagesOpenPrevious: state.pagesOpen,
                    reportsOpenPrevious: state.reportsOpen,
                    configOpenPrevious: state.configOpen,
                };
            } else {
                // If they have a previous state re-open to that
                if ("pagesOpenPrevious" in state) {
                    return {
                        ...state,
                        navigationOpen: !state.navigationOpen,
                        pagesOpen: state.pagesOpenPrevious,
                        reportsOpen: state.reportsOpenPrevious,
                        configOpen: state.configOpenPrevious,
                    };
                } else {
                    return {
                        ...state,
                        pagesOpen: true,
                        navigationOpen: !state.navigationOpen,
                    };
                }
            }

        case NAV_TOGGLE_PAGES:
            if (!state.navigationOpen) {
                return {
                    ...state,
                    navigationOpen: !state.navigationOpen,
                    pagesOpen: true,
                };
            } else {
                return {
                    ...state,
                    pagesOpen: !state.pagesOpen,
                };
            }

        case NAV_TOGGLE_REPORTS:
            if (!state.navigationOpen) {
                return {
                    ...state,
                    navigationOpen: !state.navigationOpen,
                    reportsOpen: true,
                };
            } else {
                return {
                    ...state,
                    reportsOpen: !state.reportsOpen,
                };
            }

        case NAV_TOGGLE_CONFIG:
            if (!state.navigationOpen) {
                return {
                    ...state,
                    navigationOpen: !state.navigationOpen,
                    configOpen: true,
                };
            } else {
                return {
                    ...state,
                    configOpen: !state.configOpen,
                };
            }

        case MAP_SETTINGS:
            return {
                ...state,
                map: action.map,
            };
        //create state with sectiontab header reference to trigger its two methods (updateindicator(), etc)
        case TAB_REF:
            return {
                ...state,
                tabref: action.tabref,
            };

        case DASHBOARD_SELECTOR:
            return {
                ...state,
                selectedButton: action.button,
            };

        case SW_CHECKING:
            return {
                ...state,
                sw: { checking: true },
            };

        case SW_REGISTERED:
            return {
                ...state,
                sw: { registered: true },
            };

        case SW_INSTALLED:
            return {
                ...state,
                sw: { installed: true },
            };

        case SW_UPDATE:
            return {
                ...state,
                sw: { update: true },
            };

        case SW_ERROR:
            return {
                ...state,
                sw: { error: true },
            };

        case SW_DISABLED:
            return {
                ...state,
                sw: null,
            };

        case STORE_READY:
            return {
                ...state,
                storeReady: true,
            };

        default:
            return state;
    }
}

export default navReducer;
