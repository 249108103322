import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import { IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Description from "@material-ui/icons/Description";
import Menu from "@material-ui/core/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import AttachEmailIcon from "@material-ui/icons/Email";
import FormUpload from "../../common/FormUpload";
import EmailForm from "../EmailForm";
import { createSelector } from "../../common/orm";

const styles = (theme) => ({
    iconbutton: {
        float: "right",
        fontSize: 32,
        marginRight: 27,
    },
    hideSmall: {
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    hideLarge: {
        [theme.breakpoints.up("lg")]: {
            display: "none",
        },
    },
    flex: {
        flex: 1,
    },
    listItem: {
        borderBottom: "1px solid #262626",
    },
    topListItem: {
        borderBottom: "1px solid #262626",
        backgroundColor: "#eee",
        cursor: "default",
        textAlign: "center",
    },
    list: {
        "& ul": {
            padding: 0,
        },
    },
    topButtons: {
        position: "fixed",
        zIndex: 2,
        backgroundColor: "#f0f0f0",
        paddingBottom: 10,
        marginTop: -13,
        marginLeft: -3,
        [theme.breakpoints.down(601)]: {
            marginTop: -36,
        },
    },
    label: {
        marginTop: 18,
        display: "inline-block",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        [theme.breakpoints.down("md")]: {
            maxWidth: "calc(100% - 75px)",
        },
        [theme.breakpoints.up("lg")]: {
            maxWidth: "calc(100% - 450px)",
        },
    },
});

//only used for label on header
const getRecordSection = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["parentRecordId"]),
    (session, pr) => {
        return session.RecordSection.filter((rs) => rs.parentrecord === pr).toRefArray();
    }
);

class TopButtons extends Component {
    state = {
        open: false,
        helpMenu: null,
        smallAnchor: null,
        smallAnchor2: null,
        emailFormOpen: false,
        emailUrl: null,
        emailReportName: null,
        activeReport: null
    };

    handleHelpMenu = (event) => {
        this.setState({ helpMenu: event.currentTarget });
    };

    handleHelpMenuClose = () => {
        this.setState({ helpMenu: null });
    };
    handleSmallMenu = (event) => {
        this.setState({ smallAnchor: event.currentTarget });
    };

    handleSmallMenuClose = () => {
        this.setState({ smallAnchor: null });
    };

    handleSmallMenu2 = (event, t) => {
        this.setState({ smallAnchor2: event.currentTarget, activeReport: t });
    };

    handleSmallMenuClose2 = () => {
        this.setState({ smallAnchor2: null });
    };

    returnDialog = () => {
        this.setState({ emailFormOpen: false });
    };

    render() {
        const { classes, section, page, authState, readOnly, perm, recordsection, templates, showPageName, navState, fromAnalytics, rs } = this.props;
        const { open, helpMenu, smallAnchor, smallAnchor2, emailFormOpen, emailUrl, emailReportName, activeReport } = this.state;
        const userHelpOpen = Boolean(helpMenu);
        const smallMenuOpen = Boolean(smallAnchor);
        const smallMenuOpen2 = Boolean(smallAnchor2);
        var dashboard = window.location.href.includes("/dashboard/");

        //needed for collapsed menu bar with open viewable window
        let thisWidth;
        if (window.innerWidth < 601) thisWidth = "calc(100% - 50px)";
        else if (window.innerWidth < 960) thisWidth = "calc(100% - 74px)";
        else if (window.innerWidth < 1280 && window.innerWidth > 960 && !navState.pagesOpen) thisWidth = "calc(100% - 92px)";
        else if (window.innerWidth < 1280 && window.innerWidth > 960 && navState.pagesOpen) thisWidth = "calc(100% - 289px)";
        else if (window.innerWidth > 1280 && navState.pagesOpen) thisWidth = "calc(100% - 335px)";
        else thisWidth = "calc(100% - 80px)"; //pages closed but larger screen

        return (
            <>
                {page && (
                    <Grid
                        container
                        spacing={0}
                        className={!fromAnalytics ? classes.topButtons : null}
                        style={{ width: !fromAnalytics ? thisWidth : null }}>
                        <Grid item xs={12}>
                            {showPageName && rs.length > 0 && (
                                <Tooltip className={classes.hideSmall} title={page.name + (rs && rs[0].parentinfo && " - " + rs[0].parentinfo.label)}>
                                    <Typography variant="h5" className={classes.label}>
                                        {page.name} {rs && rs[0].parentinfo && " - " + rs[0].parentinfo.label}
                                    </Typography>
                                </Tooltip>
                            )}
                            <div style={{ whiteSpace: "nowrap", float: "right", marginTop: 12 }}>
                                {authState.user && authState.user.is_city_admin === true && (
                                    <Tooltip className={classes.hideSmall} title="Module Configuration">
                                        <IconButton
                                            disabled={readOnly}
                                            className={classes.iconbutton}
                                            component={Link}
                                            to={"/page/" + page.id + "/config"} //route to page config
                                        >
                                            <Icon>settings</Icon>
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {section && (
                                    <Tooltip className={classes.hideSmall} title={"Search"}>
                                        <IconButton
                                            className={classes.iconbutton}
                                            component={Link}
                                            to={"/page/" + page.id + "/advancedsearch"}>
                                            <Icon>search</Icon>
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {section && section.has_geometry && (
                                    <Tooltip className={classes.hideSmall} title={`${page.name} Map`}>
                                        <IconButton
                                            className={classes.iconbutton}
                                            component={Link}
                                            to={"/map/" + page.id + "/section/" + 0 + "/advancedsearch/" + 1}>
                                            <Icon>map</Icon>
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {section && !section.is_master_contact_form && (
                                    <Tooltip className={classes.hideSmall} title={"Table View"}>
                                        <IconButton
                                            className={classes.iconbutton}
                                            component={Link}
                                            to={"/page/" + page.id + "/" + section.id + "/table"} //route to section table view of all records for that page
                                        >
                                            <Icon>view_headline </Icon>
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {page && !dashboard && (
                                    <Tooltip className={classes.hideSmall} title={`Dashboard`}>
                                        <IconButton className={classes.iconbutton} component={Link} to={"/dashboard/" + page.id}>
                                            <Icon>timeline</Icon>
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {recordsection  && perm && !perm.readonlyexternal && (
                                    <Tooltip className={classes.hideSmall} title="Reports" placement="bottom-start" enterDelay={300}>
                                        <IconButton className={classes.iconbutton} onClick={this.handleHelpMenu}>
                                            <Description />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                <Tooltip className={classes.hideLarge} title="Module Menu" placement="bottom-start" enterDelay={300}>
                                    <IconButton className={classes.iconbutton} onClick={this.handleSmallMenu}>
                                        <MoreVertIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>

                            <FormUpload
                                page={page}
                                authState={authState}
                                open={open}
                                returnDialog={() => {
                                    this.setState({ open: false });
                                }}
                                section={section.id}
                            />
                            <Menu
                                id="customreports"
                                anchorEl={helpMenu}
                                classes={{
                                    paper: classes.list,
                                }}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={userHelpOpen}
                                onClose={this.handleHelpMenuClose}>
                                <ListItem button className={classes.topListItem}>
                                    <ListItemText
                                        primary={<Typography variant="h6">Reports</Typography>}
                                        classes={{
                                            root: classes.topListText,
                                            primary: classes.topListText,
                                        }}
                                    />
                                </ListItem>
                                <Typography variant="h7" style={{padding:'5px', display:'block'}}   >
                                    <u>Record Form Name: "{recordsection && recordsection.columns.find(c=>c.form?c:null).form}"</u>
                                </Typography>
                                {templates &&
                                    templates.map((t, index) => {
                                        return (
                                            <table key={index} style={{ width: "100%" /*, borderCollapse: "collapse" */ }}>
                                                <tr>
                                                    {!t.name && (
                                                        <td>
                                                            <ListItem
                                                                button
                                                                style={{ borderBottom: "1px solid lightgray" }}
                                                                onClick={() => {
                                                                    // eslint-disable-next-line
                                                                    return window.open(help.url), this.handleHelpMenuClose();
                                                                }}
                                                                className={classes.listItem}>
                                                                <ListItemText primary={t.name} />
                                                            </ListItem>
                                                        </td>
                                                    )}
                                                    {t.name && (
                                                        <td>
                                                            <ListItem
                                                                button
                                                                title="Generate Report as Word Document"
                                                                style={{ borderBottom: "1px solid lightgray", paddingRight: 84 }}
                                                                className={classes.listItem}
                                                                onClick={() => {
                                                                    return (
                                                                        window.open(
                                                                            "/reports/custom/" +
                                                                                authState.user.city_id +
                                                                                "/" +
                                                                                recordsection.id +
                                                                                "/" +
                                                                                t.id
                                                                        ),
                                                                        this.handleHelpMenuClose()
                                                                    );
                                                                }}>
                                                                <ListItemText primary={t.name} />
                                                                <ListItemSecondaryAction>
                                                                    <IconButton
                                                                        edge="end"
                                                                        title="More Options"
                                                                        onClick={(e) => this.handleSmallMenu2(e, t)}>
                                                                        <MoreVertIcon />
                                                                    </IconButton>
                                                                </ListItemSecondaryAction>
                                                            </ListItem>
                                                        </td>
                                                    )}
                                                </tr>
                                            </table>
                                        );
                                    })}
                                {(!templates || templates.length === 0) && (
                                    <table style={{ width: "100%" }}>
                                        <tr>
                                            <td>
                                                <ListItem
                                                    button
                                                    className={classes.listItem}
                                                    onClick={() => {
                                                        return (
                                                            window.open(
                                                                section.parent
                                                                    ? `/reports/${authState.user.city_id}/${rs[0].parentrecord}/pdf/parent`
                                                                    : `/reports/${authState.user.city_id}/${rs[0].parentrecord}/${recordsection.id}/pdf`
                                                            ),
                                                            this.handleHelpMenuClose()
                                                        );
                                                    }}>
                                                    <ListItemText primary={"Basic Report - PDF"} />
                                                </ListItem>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <ListItem
                                                    button
                                                    className={classes.listItem}
                                                    onClick={() => {
                                                        return (
                                                            window.open(
                                                                section.parent
                                                                    ? `/reports/${authState.user.city_id}/${rs[0].parentrecord}/word/parent`
                                                                    : `/reports/${authState.user.city_id}/${rs[0].parentrecord}/${recordsection.id}/word`
                                                            ),
                                                            this.handleHelpMenuClose()
                                                        );
                                                    }}>
                                                    <ListItemText primary={"Basic Report - Word"} />
                                                </ListItem>
                                            </td>
                                        </tr>
                                    </table>
                                )}
                            </Menu>
                            <Menu
                                id="menu-small"
                                anchorEl={smallAnchor}
                                classes={{
                                    paper: classes.list,
                                }}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={smallMenuOpen}
                                onClose={this.handleSmallMenuClose}>
                                <ListItem button className={classes.topListItem}>
                                    <ListItemText
                                        primary={<Typography variant="h6">Page Menu</Typography>}
                                        classes={{
                                            root: classes.topListText,
                                            primary: classes.topListText,
                                        }}
                                    />
                                </ListItem>
                                {recordsection && (
                                    <ListItem button component={Link} onClick={this.handleHelpMenu} className={classes.listItem}>
                                        <ListItemIcon>
                                            <Icon>description</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary="Reports" />
                                    </ListItem>
                                )}
                                {section && !section.is_master_contact_form && (
                                    <ListItem button component={Link} to={"/page/" + page.id + "/" + section.id + "/table"} className={classes.listItem}>
                                        <ListItemIcon>
                                            <Icon>view_headline</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary={"View all " + section.name + "s"} />
                                    </ListItem>
                                )}
                                {section && section.has_geometry && (
                                    <ListItem
                                        button
                                        component={Link}
                                        to={"/map/" + page.id + "/section/" + 0 + "/advancedsearch/" + 1}
                                        className={classes.listItem}>
                                        <ListItemIcon>
                                            <Icon>map</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary={`${page.name} Map`} />
                                    </ListItem>
                                )}
                                {section && (
                                    <ListItem button component={Link} to={"/page/" + page.id + "/advancedsearch"} className={classes.listItem}>
                                        <ListItemIcon>
                                            <Icon>search</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary={"Search"} />
                                    </ListItem>
                                )}
                                {authState.user && authState.user.is_city_admin === true && (
                                    <ListItem button component={Link} to={"/page/" + page.id + "/config"} className={classes.listItem}>
                                        <ListItemIcon>
                                            <Icon>settings</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary="Module Configuration" />
                                    </ListItem>
                                )}
                                {page && !dashboard && (
                                    <ListItem button component={Link} to={"/dashboard/" + page.id} className={classes.listItem}>
                                        <ListItemIcon>
                                            <Icon>timeline</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary="Dashboard" />
                                    </ListItem>
                                )}
                            </Menu>
                       
                            <Menu
                                id="menu-small2"
                                anchorEl={smallAnchor2}
                                classes={{
                                    paper: classes.list,
                                }}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={smallMenuOpen2}
                                onClose={this.handleSmallMenuClose2}>
                                <ListItem button className={classes.topListItem}>
                                    <ListItemText
                                        primary={<Typography variant="h6">Options</Typography>}
                                        classes={{
                                            root: classes.topListText,
                                            primary: classes.topListText,
                                        }}
                                    />
                                </ListItem>
                                <ListItem button onClick={() => {
                                        return (
                                            window.open(
                                                "/reports/custom/" + authState.user.city_id + "/" +
                                                 recordsection.id + "/" + activeReport.id
                                            ),
                                            this.handleSmallMenuClose2(),
                                            this.handleHelpMenuClose()
                                        );
                                    }} className={classes.listItem}>
                                    <ListItemIcon>
                                        <Description />
                                    </ListItemIcon>
                                    <ListItemText primary="Generate Report as Word Document" />
                                </ListItem>
                                <ListItem button onClick={() => {
                                        return (
                                            window.open(
                                                "/reports/custom/" + authState.user.city_id + "/" +
                                                recordsection.id + "/" + activeReport.id + "/pdf"
                                            ),
                                            this.handleSmallMenuClose2(),
                                            this.handleHelpMenuClose()
                                        );
                                    }} className={classes.listItem}>
                                    <ListItemIcon>
                                        <PictureAsPdfIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Generate Report as PDF" />
                                </ListItem>
                                <ListItem button onClick={() => {
                                        return (
                                            this.setState({
                                                emailFormOpen: true,
                                                emailReportName: activeReport.name,
                                                emailUrl: recordsection.id + "/" + activeReport.id,
                                            }),
                                            this.handleSmallMenuClose2(),
                                            this.handleHelpMenuClose()
                                        );
                                    }} className={classes.listItem}>
                                    <ListItemIcon>
                                        <AttachEmailIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Email Report" />
                                </ListItem>
                            </Menu>
                       </Grid>
                        {templates && (
                            <EmailForm open={emailFormOpen} url={emailUrl} report_name={emailReportName} returnDialog={this.returnDialog} />
                        )}
                    </Grid>
                )}
            </>
        );
    }
}
TopButtons.displayName = "TopButtons";

TopButtons = connect(
    (state, ownProps) => ({
        rs: getRecordSection(state, ownProps),
        authState: state.auth,
        navState: state.nav,
    }),
    null
)(TopButtons);

export default withStyles(styles)(withRouter(TopButtons));
