import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Form as Formm } from "react-form";

import { withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";

import TimeLineIcon from "@material-ui/icons/Timeline";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import WarningIcon from "@material-ui/icons/Warning";

import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";

import AddIcon from "@material-ui/icons/Add";
import AppContainer from "../common/AppContainer";
import WarningDialog from "../common/WarningDialog";
import CreateNewDialog from "../page/landing/CreateNewDialog";
import { createSelector } from "../common/orm";
import { PageAnalytics } from "../page/models";
import { differenceInDays } from "date-fns";
import { Section, RecordSection, RecordField, ParentRecord } from "../page/models";
import * as authActions from "../auth/actions";

const styles = (theme) => ({
    expandedMargin: {
        marginTop: "12px",
    },
    titlePanel: {
        minHeight: "auto !important",
    },

    timeline: {
        "&:hover": {
            backgroundColor: "#dedede",
            borderRadius: 3,
        },
        "&: ul": {
            alignItems: "flexStart",
            whiteSpace: "noWrap",
        },
        height: "auto",
    },

    root: {
        //default for analytic card sizing
        flexBasis: "93%",
        maxWidth: "100%",
        marginBottom: -28,
        [theme.breakpoints.up(960)]: {
            flexBasis: "97%",
            maxWidth: "97%",
        },
        [theme.breakpoints.up(1440)]: {
            flexBasis: "99%",
            maxWidth: "100%",
        },
        [theme.breakpoints.up(2300)]: {
            flexBasis: "100%",
            maxWidth: "100%",
        },
    },
    table: {
        minWidth: 500,
        [theme.breakpoints.up(1860)]: {
            maxWidth: "100%",
            flexBasis: "100%",
        },
    },
    dialogMin: {
        minWidth: 420,
        minHeight: 145,
    },
    flex: {
        flex: 1,
    },
    minWidth: {
        maxWidth: 507,
        minWidth: 489,
    },
    minWidth2: {
        maxWidth: 300,
    },
    gutterBottom: {
        marginBottom: ".4em",
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },

    deleteWidth: {
        minWidth: 34,
        width: 34,
        margin: "0 8px 0 0",
    },
    centerAlign: {
        textAlign: "center",
    },
    nowrap: {
        whiteSpace: "nowrap",
    },
    //view table & map icons
    icon: {
        width: "32px",
        marginRight: "7%",
        height: "32px",
        padding: "0px",
    },
    //create new icon styling
    iconAdd: {
        backgroundColor: "#34aa20",
        color: "white",
        height: "25px",
        width: "25px",
        paddingTop: "0px",
        fontSize: "100%",
        borderRadius: 12,
    },
    button: {
        float: "right",
        marginBottom: "7px",
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    progress: {
        display: "table",
        marginLeft: "auto",
        marginRight: "auto",
    },
    warningGrid: {
        width: "35px",
        marginLeft: theme.spacing(1),
        marginBottom: "-7px",
        opacity: "1",
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.error.dark,
        },
        color: "#3d3d3d",
    },
});

//get the pageID from the URL
const getPageId = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (session, pageID) => {
        //return pageID; //result function
        return session.Page.filter((p) => p.id === pageID).toRefArray();
    }
);

const getAllSections = createSelector(
    //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, pageId) => {
        if (isNaN(pageId)) {
            return session.Section.filter((sect) => sect.page === pageId)
                .orderBy("id")
                .toRefArray();
        } else {
            return session.Section.filter((sect) => sect.page === pageId)
                .orderBy("id")
                .toRefArray();
        }
    }
);

const getRecordSectionFeeds = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session) => {
        //we will only display what came thru for the dynamic loading
        return session.RecordSection.all().toRefArray();
    }
);
//get permissions
const getPermission = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (session, pageID) => {
        return session.Permission.filter((p) => p.page === pageID).toRefArray();
    } //toRefArray returns a plain JavaScript object which are references to the DB
);

const getPageFields = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (session, actPage) => {
        return session.Section.filter((sect) => sect.page === actPage)
            .orderBy("id")
            .toModelArray()
            .map((section) => ({
                form: section.forms //form.groups is FK linkage
                    .all()
                    .orderBy("id")
                    .toModelArray()
                    .map((form) => ({
                        groups: form.groups //form.groups is FK linkage
                            .all()
                            .orderBy("id")
                            .toModelArray()
                            .map((group) => ({
                                fields: group.fields.all().orderBy("order").toRefArray(),
                                ...group.ref, // take all groups as a reference and tie in each field
                            })),
                        ...form.ref, // take all forms as a reference and tie in each field
                    })),
                ...section.ref,
            }));
    } //toRefArray returns a plain JavaScript object which are references to the DB
);

class DataFeed extends Component {
    constructor(props, context) {
        super(props, context);
        const { pagefields } = this.props;

        var childFields = [];
        //get all child form fields
        var child = pagefields.filter((p) => {
            if (!p.parent) {
                return p;
            }
            return null;
        });
        child.forEach((c) => {
            c.form.forEach((f) => {
                f.groups.forEach((g) => {
                    g.fields.forEach((k) => {
                        childFields.push(k.name);
                    });
                });
            });
        });

        //get all parent form fields
        var parentFields = [];
        const parent = pagefields.filter((p) => {
            if (p.parent) {
                return p;
            }
            return null;
        });
        parent.forEach((c) => {
            c.form.forEach((f) => {
                f.groups.forEach((g) => {
                    g.fields.forEach((k) => {
                        parentFields.push(k.name);
                    });
                });
            });
        });
        //this is to get the 'columnNames' for the selected section so that the 'dialog dropdown' can be populated.

        // Remove the duplicates from tempFields
        this.state = {
            order: "asc",
            orderBy: null,
            formDialogStatus: false,
            parentrecord: null,
            totalSectionCounts: 0,
            deleteMultipleOpen: false,
            key: window.performance.now(),
            count: 0,
            totalDailyFeedCount: null,
            loadingFeeds: true,
            noFormOpen: false,
            authenticated: false,
        };

        // I don't even...
        this.formKey = window.performance.now();
    }
    typingTimer;

    //close form dialog
    formDialogClose = () => {
        this.setState({ formDialogStatus: false });
    };

    componentDidMount() {
        this.dynamicLoading();
    }
    componentDidUpdate(prevProps) {
        const { thispage, authState } = this.props;
        const { authenticated } = this.state;

        if (thispage.length > 0 && prevProps.match && thispage[0].id !== parseInt(prevProps.match.params.id)) {
            // Also reset component state
            this.setState({
                order: "asc",
                orderBy: null,
                parent: null,
                formDialogStatus: false,
                totalDailyFeedCount: 0,
                noFormOpen: false,
                totalSectionCounts: 0,
                key: window.performance.now(),
                count: 0,
            });

            setTimeout(() => this.dynamicLoading(), 1);
        } else if (!authenticated && authState && authState.user) {
            this.dynamicLoading();
        }
    }

    // Get the total number of records for each section to put into a table
    loadTotalRecords = (_filters) => {
        const { authState, history, sections, authLogout } = this.props;
        var component = this;

        //get all section ids
        var allSections = [];
        sections.filter((s) => !s.is_master_contact_form && !s.iframe_url_section).forEach((s) => allSections.push(s.id));
        // get all the section total records
        if (sections.length > 0) {
            fetch("/count/recordsections/dashboard/" + allSections, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + authState.user.auth_token,
                },
            }).then((e) => {
                if (e.status === 401) {
                    history.push("/");
                    authLogout();
                } else if (e.status !== 500) {
                    e.json().then((data) => {
                        component.setState({ totalSectionCounts: data.message });
                    });
                }
            });
        }
    };
    // Get all of the last records for each section, we will also get all sections with the total count of records
    dynamicLoading = () => {
        const { ormRecordSectionReloadDynamic, thispage, authState } = this.props;
        const { totalDailyFeedCount, count } = this.state;
        var component = this;

        this.setState({ loadingFeeds: true });

        if (authState.user) {
            this.loadTotalRecords();

            var countBack = 10;
            if (count !== 0) {
                //count back 7 for each loading of records
                countBack = count + 7;
                //we hit end of records so just show what we have
                if (totalDailyFeedCount && totalDailyFeedCount[0].TotalCount - countBack < 0) {
                    countBack = totalDailyFeedCount[0].TotalCount;
                }
            }
            //update count
            this.setState({ count: countBack, authenticated: true });

            var onPage;
            if (thispage.length === 0) {
                onPage = authState.user.default_dashboard;
            } else {
                onPage = thispage[0].id;
            }

            var str = ""; // eslint-disable-next-line
            str += "&page=" + onPage + "&countBack=" + countBack + "&dashboard=" + true;

            ormRecordSectionReloadDynamic(str).then(() => {
                component.setState({ loadingFeeds: false });
            });
        }
    };

    render() {
        const { thispage, authState, permission, sections, classes, history, recordsectionfeeds, pagefields, sync } = this.props;
        const { totalSectionCounts, totalDailyFeedCount, count, loadingFeeds, formDialogStatus, noFormOpen } = this.state;
        if (authState.user) {
            var readOnly;
            var perm = permission.find((p) => {
                if (p.user === authState.user.id) {
                    return p;
                }
                return null;
            });
            if (perm) readOnly = perm.readonly || perm.readonlyexternal ? true : false;
            if (readOnly === undefined) readOnly = false;
        }

        var parentSection = sections.find((s) => (s.parent === true ? s : null));

        // var overflowY = count > 7 ? "scroll" : "none";

        //used for create new records
        var forms;
        if (pagefields.length > 0) forms = pagefields.find((p) => p.parent === true).form;

        return (
            <>
                <AppContainer authenticated synced={false}>
                    <Grid container spacing={3} style={{ marginTop: 0 }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} style={{ display: "inline-flex" }}>
                                <Grid item xs={12} sm={7}>
                                    {thispage && thispage[0] && <Typography variant="h5"> {`${thispage[0].name} Data Feed`}</Typography>}
                                    {!thispage && <Typography variant="h5"> Data Feed</Typography>}
                                </Grid>
                                {/* <Grid item xs={12} sm={5}>
                                    {parentSection && (
                                        <TopButtons section={parentSection} page={thispage[0]} authState={authState} readOnly={false} />
                                    )}
                                </Grid> */}
                            </Grid>
                            <Grid item xs={12} style={{ display: "flex", marginTop: 18 }}>
                                <Typography variant="subtitle1" style={{ marginTop: -24, marginLeft: 1, paddingRight: 11, color: "#262626" }}>
                                    Go To Section:
                                </Typography>
                                {totalSectionCounts.length > 0 &&
                                    totalSectionCounts.map((m, i) => {
                                        return (
                                            <Tooltip key={i} title={m.SectionName + " Table View"}>
                                                <Link
                                                    style={{ textDecoration: "underline", paddingRight: 10, marginTop: -19 }}
                                                    to={`/page/${thispage[0].id}/${m.SectionId}/table`}>
                                                    {m.SectionName + ` records - ` + m.TotalCount + "  "}
                                                </Link>
                                            </Tooltip>
                                        );
                                    })}
                                {(totalSectionCounts === 0 || totalSectionCounts.length === 0) && (
                                    <CircularProgress
                                        size={19}
                                        className={classes.progress}
                                        style={{
                                            marginTop: -17,
                                            color: "#000",
                                            marginLeft: 0,
                                        }}
                                    />
                                )}
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Formm
                                            key={this.formKey}
                                            dontValidateOnMount={true}
                                            validateOnSubmit={true}
                                            getApi={(el) => (this.formApi = el)}>
                                            {(formApi) => (
                                                <form onSubmit={formApi.submitForm}>
                                                    <Accordion defaultExpanded={true}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            style={{ backgroundColor: "#75bafa" }}
                                                            classes={{ root: classes.expandedMargin }}
                                                            className={classes.titlePanel}>
                                                            <Icon style={{ color: "#fff", fontWeight: 500 }}>
                                                                <TimeLineIcon />
                                                            </Icon>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12}>
                                                                    <Typography
                                                                        variant="subtitle1"
                                                                        style={{
                                                                            marginLeft: 12,
                                                                            color: "#fff",
                                                                            fontWeight: 500,
                                                                            display: "inline-block",
                                                                        }}>
                                                                        {`Data Feed (${
                                                                            count > recordsectionfeeds.length ? recordsectionfeeds.length : count
                                                                        } Records Shown)  `}
                                                                    </Typography>
                                                                    <Tooltip
                                                                        title="Data created prior to February 3, 2021 will only be shown as modified."
                                                                        onClick={(e) => e.stopPropagation()}>
                                                                        <WarningIcon className={classes.warningGrid} />
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>
                                                        </AccordionSummary>

                                                        <AccordionDetails style={{ padding: "2px 3px 8px" }}>
                                                            <Grid container spacing={1} style={{ overflowY: "auto", maxHeight: 453, minHeight: 453 }}>
                                                                <Grid item xs={12}>
                                                                    <Timeline style={{ maxheight: 478, marginTop: 0 }}>
                                                                        {recordsectionfeeds.map((df, i) => {
                                                                            //check if its modified, if so get time when last modified

                                                                            var od = new Date(df.modified_time ? df.modified_time : df.created_date);
                                                                            var nd = new Date();

                                                                            // let oldDate =
                                                                            //     od.getUTCMonth() + 1 + "-" + od.getDate() + "-" + od.getUTCFullYear();
                                                                            // let newDate =
                                                                            //     nd.getUTCMonth() + 1 + "-" + nd.getDate() + "-" + nd.getUTCFullYear();
                                                                            let oldDate =
                                                                                od.getUTCFullYear() +
                                                                                "-" +
                                                                                (od.getUTCMonth() + 1) +
                                                                                "-" +
                                                                                od.getDate();
                                                                            let newDate =
                                                                                nd.getUTCFullYear() +
                                                                                "-" +
                                                                                (nd.getUTCMonth() + 1) +
                                                                                "-" +
                                                                                nd.getDate();
                                                                            var timedelta;

                                                                            var diffInDays = differenceInDays(new Date(newDate), new Date(oldDate));
                                                                            //same date for an update so we chk if its within the last 15 minutes is so we will not update the log otherwise we will
                                                                            if (diffInDays === 0) {
                                                                                //chk for same hr
                                                                                if (od.getHours() === nd.getHours()) {
                                                                                    //how many minutes ago
                                                                                    if (nd.getMinutes() > od.getMinutes()) {
                                                                                        timedelta = nd.getMinutes() - od.getMinutes();
                                                                                        timedelta += " min ago";
                                                                                    } else {
                                                                                        // }
                                                                                        timedelta = " moments ago";
                                                                                    }
                                                                                } else {
                                                                                    //how many hours ago
                                                                                    timedelta = nd.getHours() - od.getHours();
                                                                                    timedelta += parseInt(timedelta) === 1 ? " hr ago" : " hrs ago";
                                                                                }
                                                                            } else {
                                                                                //how many days ago
                                                                                var diffInTime = nd.getTime() - od.getTime();
                                                                                timedelta = (diffInTime / (1000 * 3600 * 24)).toFixed(0);
                                                                                timedelta += parseInt(timedelta) === 1 ? " day ago" : " days ago";
                                                                            }

                                                                            oldDate = //change date format
                                                                                od.getUTCMonth() + 1 + "-" + od.getDate() + "-" + od.getUTCFullYear();

                                                                            //create custom bold string for jsx
                                                                            var paperLabel = null;
                                                                            if (df.modified_by) {
                                                                                if (df.sectionname) {
                                                                                    paperLabel = `${df.sectionname} on <b>${df.parentinfo.label}</b> modified by <b>${df.modified_by}</b>`;
                                                                                } else {
                                                                                    paperLabel = `<b>${df.parentinfo.label}</b> modified by <b>${df.modified_by}</b>`;
                                                                                }
                                                                            } else {
                                                                                if (df.sectionname) {
                                                                                    paperLabel = `New ${df.sectionname} on <b>${df.parentinfo.label}</b> created by <b>${df.created_by}</b>`;
                                                                                } else {
                                                                                    paperLabel = `<b>${df.parentinfo.label}</b> created by <b>${df.created_by}</b>`;
                                                                                }
                                                                            }

                                                                            return (
                                                                                <TimelineItem key={i}>
                                                                                    <TimelineOppositeContent style={{ flex: 0 }} />
                                                                                    <TimelineSeparator style={{ marginTop: 16 }}>
                                                                                        <TimelineDot color="primary" /*style={{ marginTop: 22 }}*/ />
                                                                                        <TimelineConnector style={{ marginBottom: -16 }} />
                                                                                    </TimelineSeparator>
                                                                                    <TimelineContent
                                                                                        onClick={() => {
                                                                                            history.push(
                                                                                                "/page/" +
                                                                                                    df.page +
                                                                                                    "/parentrecord/" +
                                                                                                    df.parentrecord +
                                                                                                    "/section/" +
                                                                                                    df.section +
                                                                                                    "/recordsection/" +
                                                                                                    df.id +
                                                                                                    "/form"
                                                                                            );
                                                                                        }}>
                                                                                        <Paper elevation={3} className={classes.timeline}>
                                                                                            <Grid container>
                                                                                                <Grid item xs={12} style={{ display: "inline-flex" }}>
                                                                                                    <Grid item xs={9}>
                                                                                                        <Typography
                                                                                                            style={{
                                                                                                                fontSize: 19,
                                                                                                                fontWeight: 500,
                                                                                                                paddingLeft: 10,
                                                                                                            }}
                                                                                                            component="h1">
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    lineHeight: "21px",
                                                                                                                    marginTop: 4,
                                                                                                                }}
                                                                                                                dangerouslySetInnerHTML={{
                                                                                                                    __html: paperLabel,
                                                                                                                }}
                                                                                                            />
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                    <Grid
                                                                                                        item
                                                                                                        xs={3}
                                                                                                        style={{ paddingRight: 5, textAlign: "end" }}>
                                                                                                        <Typography
                                                                                                            style={{
                                                                                                                fontSize: 19,
                                                                                                                fontWeight: 500,
                                                                                                                lineHeight: "21px",
                                                                                                                marginTop: 4,
                                                                                                            }}
                                                                                                            component="h1">
                                                                                                            {timedelta}
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                            <Typography
                                                                                                style={{
                                                                                                    paddingLeft: 10,
                                                                                                }}>
                                                                                                {`Date: ${oldDate}`}
                                                                                            </Typography>
                                                                                        </Paper>
                                                                                    </TimelineContent>
                                                                                </TimelineItem>
                                                                            );
                                                                        })}
                                                                    </Timeline>
                                                                </Grid>
                                                            </Grid>
                                                        </AccordionDetails>
                                                        <AccordionDetails style={{ paddingBottom: 5 }}>
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    style={{
                                                                        backgroundColor:
                                                                            (totalDailyFeedCount && totalDailyFeedCount[0].TotalCount - count < 0) ||
                                                                            (totalDailyFeedCount && count === totalDailyFeedCount[0].TotalCount)
                                                                                ? "#ddd"
                                                                                : "#3c8ac7",
                                                                        color:
                                                                            (totalDailyFeedCount && totalDailyFeedCount[0].TotalCount - count < 0) ||
                                                                            (totalDailyFeedCount && count === totalDailyFeedCount[0].TotalCount)
                                                                                ? "#000"
                                                                                : "#fff",
                                                                        border: "0px solid",
                                                                        boxShadow:
                                                                            " 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.06)",
                                                                        fontSize: "1.0625rem",
                                                                        fontWeight: 400,
                                                                    }}
                                                                    fullWidth
                                                                    disabled={totalDailyFeedCount && totalDailyFeedCount[0].TotalCount - count < 0}
                                                                    onClick={() => this.dynamicLoading()}>
                                                                    &nbsp;
                                                                    {(totalDailyFeedCount && totalDailyFeedCount[0].TotalCount - count < 0) ||
                                                                    (totalDailyFeedCount && count === totalDailyFeedCount[0].TotalCount)
                                                                        ? ` End of Records`
                                                                        : "Load Records"}
                                                                    {loadingFeeds && (
                                                                        <CircularProgress
                                                                            size={19}
                                                                            className={classes.progress}
                                                                            style={{ marginLeft: 65, position: "fixed", marginTop: 2, color: "#fff" }}
                                                                        />
                                                                    )}
                                                                </Button>
                                                            </Grid>
                                                        </AccordionDetails>
                                                        <AccordionDetails style={{ paddingBottom: 17 }}>
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    style={{
                                                                        // backgroundColor: "#3c8ac7",
                                                                        // color: "#fff",
                                                                        fontSize: "1.0625rem",
                                                                        fontWeight: 400,
                                                                    }}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    fullWidth
                                                                    disabled={readOnly}
                                                                    onClick={() => {
                                                                        if (
                                                                            forms &&
                                                                            forms.length === 0 /*|| forms[0].groups[0].fields.length === 0*/
                                                                        ) {
                                                                            this.setState({ noFormOpen: true });
                                                                        } else {
                                                                            this.setState({ formDialogStatus: true });
                                                                        }
                                                                    }}>
                                                                    <AddIcon className={classes.iconAdd} /> &nbsp;&nbsp;&nbsp;Create New Record
                                                                    {sync && sync.ready === false && (
                                                                        <>
                                                                            <CircularProgress
                                                                                size={19}
                                                                                className={classes.progress}
                                                                                style={{
                                                                                    marginLeft: 105,
                                                                                    position: "fixed",
                                                                                    marginTop: 2,
                                                                                    color: "#fff",
                                                                                }}
                                                                            />
                                                                        </>
                                                                    )}
                                                                </Button>
                                                            </Grid>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </form>
                                            )}
                                        </Formm>
                                        {thispage && forms && parentSection && (
                                            <CreateNewDialog
                                                classes={classes}
                                                open={formDialogStatus} //open is a keyword that knows dialog is open is based on flag value from Accordion
                                                returnDialog={this.formDialogClose}
                                                page={thispage[0].id}
                                                forms={forms}
                                                sectionGeometry={parentSection.has_geometry}
                                                form_or_map={parentSection.form_or_map}
                                                sectionid={parentSection.id}
                                                has_confirmation={parentSection.has_confirmation}
                                            />
                                        )}

                                        <WarningDialog
                                            cancelAction={() => this.setState({ noFormOpen: false })}
                                            open={noFormOpen}
                                            noDeleteButtons
                                            title="Form Not Created"
                                            text="A new record can not be created until a 'form' has been configured for this section. Please go to the configuration module and configure a form first or talk to the software administrator."
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </AppContainer>
            </>
        );
    }
}
DataFeed.displayName = "DataFeed";

DataFeed = connect(
    (state, ownProps) => ({
        sync: state.sync,
        thispage: getPageId(state, ownProps),
        sections: getAllSections(state, ownProps),
        pagefields: getPageFields(state, ownProps),
        permission: getPermission(state, ownProps),
        authState: state.auth,
        recordsectionfeeds: getRecordSectionFeeds(state, ownProps),
        // recordsections: getRecordSections(state, ownProps),  // advancedfilter: getAdvancedFilters(state, ownProps),
    }),
    {
        ...PageAnalytics.actions,
        ...ParentRecord.actions,
        ...Section.actions,
        ...RecordSection.actions,
        ...RecordField.actions,
        ...authActions,
    }
)(DataFeed);

export default withStyles(styles)(withRouter(DataFeed));
