import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import DialogContent from "@material-ui/core/DialogContent";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import DialogContentText from "@material-ui/core/DialogContentText";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core";

import ViewIcon from "@material-ui/icons/Visibility";
import Close from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";

import AppContainerApplication from "../../common/AppContainerApplication";
import TextField from "../../common/TextField";
import Select from "../../common/Select";
import CheckboxGroup from "../../common/CheckboxGroup";
import ReactSelect from "../../common/ReactSelect";
import DatePicker from "../../common/DatePicker2";
import FileInput from "../../common/FileInput";
import Checkbox from "../../common/Checkbox";
import Subquestion from "../../common/Subquestion";
import HelpLabel from "../../common/HelpLabel";
import { createSelector } from "../../common/orm";
import states from "../../common/statesmn.json";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import { ApplicationRecord, ApplicationRecordStatus, RpbcwdPermit, RpbcwdPermitContact, RpbcwdPermitAttachment } from "../../page/models";

const styles = (theme) => ({
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8,
        marginTop: 2,
        marginBottom: 2,
    },
    centerAlign: {
        textAlign: "center",
    },
    centerAlign2: {
        textAlign: "center",
        display: "inline-block",
        width: "100%",
    },
    nowrap: {
        whiteSpace: "nowrap",
    },
    rightAlign: {
        textAlign: "right",
    },
    flex: {
        flex: 1,
    },
    printDivider: {
        height: 8,
        backgroundColor: "rgba(0, 0, 0, 0.42)",
        marginTop: 16,
        marginBottom: 8,
    },
    dialogMin: {
        minWidth: 400,
    },
    minWidth: {
        minWidth: 600,
    },
});

function MAKE_OPTIONS(table) {
    return table.map((row) => ({
        label: row,
        value: row,
    }));
}

const getApplication = createSelector(
    (state, ownProps) => ownProps.match.params["hash"],
    (session, hash) => {
        return session.RpbcwdPermit.filter((p) => p.hash === hash).toModelArray()[0];
    }
);

class Rpbcwd extends Component {
    state = {
        activeStep: 0,
        contactDialogOpen: false,
        contactEditing: null,
        formKey: null,
        order: "desc",
        orderBy: null,
        temp_contacts: [],
        attachmentDialogOpen: false,
        attachmentEditing: null,
        attachmentFormKey: null,
        attachmentOrder: "desc",
        attachmentOrderBy: null,
        temp_attachments: [],
        loading: false,
        new_save: false,
        new_save2: false,
        mainFormKey: null,
        entryError: false,
    };

    componentDidMount() {
        const { match, ormRpbcwdPermitLoadDetailChild, ormRpbcwdPermitContactLoadDetailChild, ormRpbcwdPermitAttachmentLoadDetailChild } = this.props;
        var component = this;

        if (match.params && match.params.hash) {
            ormRpbcwdPermitLoadDetailChild(match.params.hash, function () {
                component.setState({ mainFormKey: window.performance.now() });
            });
            ormRpbcwdPermitContactLoadDetailChild(match.params.hash, function (data) {
                component.setState({ temp_contacts: data });
            });
            ormRpbcwdPermitAttachmentLoadDetailChild(match.params.hash, function (data) {
                component.setState({ temp_attachments: data });
            });
        }
        if (match.params && match.params.print) {
            component.setState({ activeStep: "PRINT" });
        }
    }

    errorValidator = (values) => {
        const isRequired = (val) => {
            return !val ? "Required" : null;
        };
        const validateEmail = (email) => {
            var re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !re.test(email) ? "Invalid Email Address" : null;
        };
        const validateEmailNotRequired = (email) => {
            var re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!email) return null;
            return !re.test(email) ? "Invalid Email Address" : null;
        };
        var valObj = {
            owner_first_name: isRequired(values.owner_first_name),
            owner_last_name: isRequired(values.owner_last_name),
            owner_mailing_address: isRequired(values.owner_mailing_address),
            owner_phone: isRequired(values.owner_phone),
            owner_city: isRequired(values.owner_city),
            owner_state: isRequired(values.owner_state),
            owner_zip: isRequired(values.owner_zip),
            owner_email: validateEmail(values.owner_email),
            secondary_email: validateEmailNotRequired(values.secondary_email),
            address_1: isRequired(values.address_1),
            municipality: isRequired(values.municipality),
            zip: isRequired(values.zip),
            subwatershed: isRequired(values.subwatershed),
            project_name: isRequired(values.project_name),
            requirements: isRequired(values.requirements),
            signature: isRequired(values.signature),
            signature2: isRequired(values.signature2),
        };

        var alerts = "";
        var ai = 5;
        if (valObj["owner_first_name"] === "Required") {
            alerts += "Property Owner First Name (Step 1)\n";
            ai = 0;
        }
        if (valObj["owner_last_name"] === "Required") {
            alerts += "Property Owner Last Name (Step 1)\n";
            ai = 0;
        }
        if (valObj["owner_mailing_address"] === "Required") {
            alerts += "Property Owner Mailing Address (Step 1)\n";
            ai = 0;
        }
        if (valObj["owner_city"] === "Required") {
            alerts += "Property Owner City (Step 1)\n";
            ai = 0;
        }
        if (valObj["owner_state"] === "Required") {
            alerts += "Property Owner State (Step 1)\n";
            ai = 0;
        }
        if (valObj["owner_zip"] === "Required") {
            alerts += "Property Owner Zip (Step 1)\n";
            ai = 0;
        }
        if (valObj["owner_phone"] === "Required") {
            alerts += "Property Owner Phone (Step 1)\n";
            ai = 0;
        }
        if (valObj["owner_email"] === "Invalid Email Address") {
            alerts += "Property Owner Email (Step 1)\n";
            ai = 0;
        }
        if (valObj["address_1"] === "Required") {
            alerts += "Project Location Address (Step 2)\n";
            ai = 1;
        }
        if (valObj["municipality"] === "Required") {
            alerts += "Project Location Municipality (Step 2)\n";
            ai = 1;
        }
        if (valObj["zip"] === "Required") {
            alerts += "Project Location Zip (Step 2)\n";
            ai = 1;
        }
        if (valObj["subwatershed"] === "Required") {
            alerts += "Project Location Receiving Waterbody (Step 2)\n";
            ai = 1;
        }
        if (valObj["project_name"] === "Required") {
            alerts += "Project Name (Step 4)\n";
            ai = 3;
        }
        if (valObj["requirements"] === "Required") {
            alerts += "Submittal Requirements (Step 6)\n";
        }
        if (valObj["signature"] === "Required") {
            alerts += "Applicant Signature (Step 6)\n";
        }
        if (valObj["signature2"] === "Required") {
            alerts += "Applicant Signature 2 (Step 6)\n";
        }
        if (alerts) {
            alert("The following fields are missing or invalid:\n" + alerts);
            this.setState({ activeStep: ai });
        }

        return valObj;
    };

    errorValidatorContact = (values) => {
        const isRequired = (val) => {
            return !val ? "Required" : null;
        };
        var valObj = {
            first_name: isRequired(values.first_name),
            last_name: isRequired(values.last_name),
            mailing_address: isRequired(values.mailing_address),
            city: isRequired(values.city),
            state: isRequired(values.state),
            zip: isRequired(values.zip),
            phone: isRequired(values.phone),
            email: isRequired(values.email),
            type: isRequired(values.type),
        };

        return valObj;
    };

    errorValidatorAttachment = (values) => {
        const isRequired = (val) => {
            return !val ? "Required" : null;
        };
        var valObj = {
            file: isRequired(values.file),
            name: isRequired(values.name),
        };

        return valObj;
    };

    submitApplication = (values) => {
        const {
            application,
            ormApplicationRecordCreate,
            ormApplicationRecordStatusCreate,
            ormRpbcwdPermitCreate,
            ormRpbcwdPermitContactCreate,
            ormRpbcwdPermitAttachmentCreate,
            ormRpbcwdPermitUpdatePublic,
        } = this.props;
        const { temp_contacts, temp_attachments } = this.state;

        const _this = this;
        this.setState({ loading: true });

        // Editing
        if (application) {
            ormRpbcwdPermitUpdatePublic({
                id: application.id,
                ...values,
            });

            var i = 0;
            var j = 0;
            temp_contacts.forEach(function (tc) {
                if (tc.id) {
                    /*ormRpbcwdPermitContactUpdate({
                        id: tc.id,
                        ...tc
                    });*/
                } else {
                    i++;
                    ormRpbcwdPermitContactCreate({
                        rpbcwdpermit: application.id,
                        ...tc,
                    }).then((_z) => {
                        j++;
                        if (i === j) {
                            _this.setState({ loading: false, new_save2: true });
                        }
                    });
                }
            });
            temp_attachments.forEach(function (tc) {
                if (tc.id) {
                    /*ormRpbcwdPermitAttachmentUpdate({
                        id: tc.id,
                        ...tc
                    });*/
                } else {
                    i++;
                    ormRpbcwdPermitAttachmentCreate({
                        rpbcwdpermit: application.id,
                        ...tc,
                    }).then((_z) => {
                        j++;
                        if (i === j) {
                            _this.setState({ loading: false, new_save2: true });
                        }
                    });
                }
            });
            if (i === 0) {
                // No attachments or contacts - its finished loading
                setTimeout(function () {
                    _this.setState({ loading: false, new_save2: true });
                }, 1000);
            }
        } else {
            // New
            ormApplicationRecordCreate({
                application: 1,
            }).then((ar) => {
                ormApplicationRecordStatusCreate({
                    applicationrecord: ar.id,
                    status: "Received",
                });
                ormRpbcwdPermitCreate({
                    applicationrecord: ar.id,
                    ...values,
                }).then((rpb) => {
                    if (rpb && rpb.error) {
                        this.setState({ entryError: true, loading: false });
                        fetch("/error/post", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                error: JSON.stringify(values),
                                error_info: rpb.error.toString(),
                            }),
                        });
                    } else {
                        var i = 0;
                        var j = 0;
                        temp_contacts.forEach(function (tc) {
                            i++;
                            ormRpbcwdPermitContactCreate({
                                rpbcwdpermit: rpb.id,
                                ...tc,
                            }).then((_z) => {
                                j++;
                                if (i === j) _this.setState({ loading: false, new_save: true });
                            });
                        });
                        temp_attachments.forEach(function (tc) {
                            i++;
                            ormRpbcwdPermitAttachmentCreate({
                                rpbcwdpermit: rpb.id,
                                ...tc,
                            }).then((_z) => {
                                j++;
                                if (i === j) _this.setState({ loading: false, new_save: true });
                            });
                        });
                        if (i === 0) {
                            // No attachments or contacts - its finished loading
                            _this.setState({ loading: false, new_save: true });
                        }
                    }
                });
            });
        }
    };

    addContact = (values) => {
        var { contactEditing, temp_contacts } = this.state;

        if (!contactEditing) {
            temp_contacts.push(values);
            this.setState({ temp_contacts: temp_contacts });
        } else {
            var temp = temp_contacts.filter((tc) => tc !== contactEditing);
            temp.push(values);
            this.setState({ temp_contacts: temp });
        }

        this.setState({ contactDialogOpen: false });
    };

    addAttachment = (values) => {
        var { attachmentEditing, temp_attachments } = this.state;

        if (!attachmentEditing) {
            temp_attachments.push(values);
            this.setState({ temp_attachments: temp_attachments });
        } else {
            var temp = temp_attachments.filter((tc) => tc !== attachmentEditing);
            temp.push(values);
            this.setState({ temp_attachments: temp });
        }

        this.setState({ attachmentDialogOpen: false });
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.state.temp_contacts.sort((a, b) => (b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1))
            : this.state.temp_contacts.sort((a, b) => (a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1));

        this.setState({ order, orderBy });
    };

    handleRequestSort2 = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.state.temp_attachments.sort((a, b) => (b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1))
            : this.state.temp_attachments.sort((a, b) => (a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1));

        this.setState({ order, orderBy });
    };

    handleNext = (index) => {
        const { activeStep } = this.state;
        if (index || index === 0) {
            this.setState({ activeStep: index });
        } else if (activeStep < 5) this.setState({ activeStep: activeStep + 1 });
        else {
            this.formApi.submitForm();
        }
    };

    render() {
        const { classes, application } = this.props;
        const {
            activeStep,
            contactDialogOpen,
            contactEditing,
            formKey,
            temp_contacts,
            order,
            orderBy,
            attachmentDialogOpen,
            attachmentEditing,
            attachmentFormKey,
            attachmentOrder,
            attachmentOrderBy,
            temp_attachments,
            loading,
            new_save,
            mainFormKey,
            new_save2,
            entryError,
        } = this.state;

        var is_read_only = false;
        if (application && application.applicationrecord && application.applicationrecord.status === "Received") is_read_only = true;

        var columnDataContacts = [
            { id: "actions", numeric: false, label: "", allowSort: false, width: "20px" },
            { id: "type", numeric: false, label: "Type", allowSort: true },
            { id: "first_name", numeric: false, label: "First Name", allowSort: true },
            { id: "last_name", numeric: false, label: "Last Name", allowSort: true },
            { id: "mailing_address", numeric: false, label: "Address", allowSort: true },
            { id: "phone", numeric: false, label: "Phone", allowSort: true },
            { id: "email", numeric: false, label: "Email", allowSort: true },
        ];

        var columnDataAttachments = [
            { id: "actions", numeric: false, label: "", allowSort: false, width: "20px" },
            { id: "name", numeric: false, label: "Name", allowSort: true },
            { id: "_type", numeric: false, label: "Type", allowSort: true },
            { id: "notes", numeric: false, label: "Notes", allowSort: true },
        ];

        if (new_save) {
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "12" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} lg={10} xl={8}>
                            <Typography>
                                This is a confirmation that the permit application has been submitted successfully. A copy of the permit application
                                has been emailed to the primary property owner's email address. Please check your spam and junk folders if you have
                                not received it or contact the district. The district will contact you as the application is being reviewed.
                            </Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }
        if (entryError) {
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "12" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} lg={10} xl={8}>
                            <Typography>
                                This permit application was 'not' successfully saved. Please contact the permit 'managing' staff to alert them of the
                                entry failure.
                            </Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }

        if (new_save2) {
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "12" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} lg={10} xl={8}>
                            <Typography>This is a confirmation that the permit application has been resubmitted successfully.</Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }

        return (
            <AppContainerApplication
                city_id={window.location.origin.includes("ms4prod") ? "12" : "2"}
                page_loading={loading}
                printing={activeStep === "PRINT"}>
                <Form
                    getApi={(el) => (this.formApi = el)}
                    defaultValues={application}
                    key={mainFormKey}
                    dontValidateOnMount={true}
                    validateOnSubmit={true}
                    validateError={(values) => this.errorValidator(values)}
                    onSubmit={(values) => this.submitApplication(values)}>
                    {(formApi) => (
                        <form onSubmit={formApi.submitForm}>
                            <Grid container spacing={1} style={{ padding: "0 12px" }}>
                                <Grid item xs={12} lg={10} xl={8}>
                                    <Typography className={classes.centerAlign} variant="h6">
                                        Permit Application Form {application && application.permit_number && "#" + application.permit_number}
                                    </Typography>
                                    <Typography className={classes.centerAlign}>
                                        <b>IMPORTANT:</b> The entire permit application must be filled out at one time and progress will not be saved
                                        so please plan accordingly.
                                    </Typography>
                                    <Typography variant="caption" className={classes.centerAlign2}>
                                        Required Fields are marked with an asterisk (*)
                                    </Typography>
                                </Grid>
                                {activeStep !== "PRINT" && (
                                    <Grid item xs={12} lg={10} xl={8}>
                                        <Stepper style={{ padding: 8 }} alternativeLabel nonLinear activeStep={activeStep}>
                                            {[
                                                "Contact Info",
                                                "Project Location",
                                                "Applicable Rules",
                                                "Project Details",
                                                "Attach Documents",
                                                "Review and Submit",
                                            ].map((label, index) => {
                                                return (
                                                    <Step key={label}>
                                                        <StepButton style={{ cursor: "pointer" }} onClick={() => this.handleNext(index)}>
                                                            {label}
                                                        </StepButton>
                                                    </Step>
                                                );
                                            })}
                                        </Stepper>
                                    </Grid>
                                )}
                                {(activeStep === 0 || activeStep === "PRINT") && (
                                    <Grid item xs={12} lg={10} xl={8}>
                                        <Typography>
                                            Please add all contacts associated with the permit and project. There is a button towards the bottom to
                                            add additional contacts such as Consultants, Contractors and additional property owners or
                                            representatives.
                                        </Typography>
                                        <Accordion defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Primary Property Owner / Applicant (required)
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="owner_first_name" label="First Name *" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="owner_last_name" label="Last Name *" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="owner_organization"
                                                            label="Organization / Company"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="owner_mailing_address"
                                                            label="Mailing Address *"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="owner_mailing_address2"
                                                            label="Mailing Address 2"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="owner_city" label="City *" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Select
                                                            disabled={is_read_only}
                                                            field="owner_state"
                                                            label="State *"
                                                            options={states}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="owner_zip" label="Zip *" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="owner_phone" label="Phone *" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="owner_email" label="Email *" fullWidth />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Secondary Owner Representative
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="secondary_first_name"
                                                            label="First Name"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="secondary_last_name" label="Last Name" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="secondary_organization"
                                                            label="Organization / Company"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="secondary_mailing_address"
                                                            label="Mailing Address"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="secondary_mailing_address2"
                                                            label="Mailing Address 2"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="secondary_city" label="City" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Select
                                                            disabled={is_read_only}
                                                            field="secondary_state"
                                                            label="State"
                                                            options={states}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="secondary_zip" label="Zip" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="secondary_phone" label="Phone" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField disabled={is_read_only} field="secondary_email" label="Email" fullWidth />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Typography variant="subtitle1">Other Contacts</Typography>
                                        <Typography gutterBottom>
                                            If different from the primary owner, please provide contact information for another property owners
                                            subject to this application (secondary owners), a project representative (e.g., Consultant, Engineer,
                                            Arcitect, etc), and Erosion control Representative)
                                        </Typography>
                                        <Button
                                            onClick={() =>
                                                this.setState({ contactDialogOpen: true, contactEditing: null, formKey: window.performance.now() })
                                            }
                                            variant="contained"
                                            color="primary">
                                            Add Contact
                                        </Button>
                                        <Table style={{ marginTop: 8 }}>
                                            <EnhancedTableHead
                                                columnData={columnDataContacts}
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={this.handleRequestSort}
                                            />
                                            <TableBody>
                                                {temp_contacts.map((n) => {
                                                    return (
                                                        <TableRow hover>
                                                            <CustomTableCell className={classes.nowrap}>
                                                                <Tooltip title="Edit">
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() =>
                                                                            this.setState({
                                                                                contactDialogOpen: true,
                                                                                contactEditing: n,
                                                                                formKey: window.performance.now(),
                                                                            })
                                                                        }
                                                                        className={classes.deleteWidth}>
                                                                        <ViewIcon color="primary" />
                                                                    </Button>
                                                                </Tooltip>
                                                                <Tooltip title="Delete">
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() =>
                                                                            this.setState({ temp_contacts: temp_contacts.filter((tc) => tc !== n) })
                                                                        }
                                                                        className={classes.deleteWidth}>
                                                                        <DeleteIcon color="primary" />
                                                                    </Button>
                                                                </Tooltip>
                                                            </CustomTableCell>
                                                            <CustomTableCell>{n.type}</CustomTableCell>
                                                            <CustomTableCell>{n.first_name}</CustomTableCell>
                                                            <CustomTableCell>{n.last_name}</CustomTableCell>
                                                            <CustomTableCell>{n.mailing_address}</CustomTableCell>
                                                            <CustomTableCell>{n.phone}</CustomTableCell>
                                                            <CustomTableCell>{n.email}</CustomTableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                                {temp_contacts.length < 1 && (
                                                    <TableRow>
                                                        <CustomTableCell colSpan={columnDataContacts.length} className={classes.centerAlign}>
                                                            No Other Contacts Added
                                                        </CustomTableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                )}
                                {activeStep === "PRINT" && (
                                    <Grid item xs={12} lg={10} xl={8}>
                                        <Divider className={classes.printDivider} />
                                    </Grid>
                                )}
                                {(activeStep === 1 || activeStep === "PRINT") && (
                                    <Grid item xs={12} lg={10} xl={8}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            Project Location
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <TextField disabled={is_read_only} field="address_1" label="Address 1 *" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField disabled={is_read_only} field="address_2" label="Address 2" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Select
                                                    disabled={is_read_only}
                                                    field="municipality"
                                                    label="Municipality *"
                                                    fullWidth
                                                    options={MAKE_OPTIONS([
                                                        "Bloomington",
                                                        "Chanhassen",
                                                        "Chaska",
                                                        "Deephaven",
                                                        "Eden Prairie",
                                                        "Minnetonka",
                                                        "Shorewood",
                                                    ])}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField disabled={is_read_only} field="zip" label="Zip *" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Select
                                                    disabled={is_read_only}
                                                    field="subwatershed"
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Waterbody receiving runoff from the site *"
                                                            helpText="The list water resources is based on the MNDNR Level 9 subwatershed names."
                                                        />
                                                    }
                                                    fullWidth
                                                    options={MAKE_OPTIONS([
                                                        "Aquila Pond",
                                                        "Cemetery Pond",
                                                        "Colorado Pond",
                                                        "Drake Pond",
                                                        "Jacques Pond",
                                                        "Mintree Pond",
                                                        "Sunrise Pond",
                                                        "Tarnhill Pond",
                                                        "Utah Avenue Pond",
                                                        "Warren Pond",
                                                        "Woodbridge Marsh",
                                                        "Lake Ann",
                                                        "Duck Lake",
                                                        "Lake Harrison",
                                                        "Hyland Lake",
                                                        "Lake Idlewild",
                                                        "Lotus Lake",
                                                        "Lake Lucy",
                                                        "Lake McCoy",
                                                        "Mitchell Lake",
                                                        "Neil Lake",
                                                        "Purgatory Park Preserve",
                                                        "Red Rock Lake",
                                                        "Rice Marsh Lake",
                                                        "Lake Riley",
                                                        "Round Lake",
                                                        "School Lake",
                                                        "Silver Lake",
                                                        "Staring Lake",
                                                        "Lake Susan",
                                                        "Bluff Creek",
                                                        "Purgatory Creek",
                                                        "Riley Creek",
                                                        "Unknown",
                                                    ])}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    disabled={is_read_only}
                                                    field="parcel_number"
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Parcel Number"
                                                            helpText="If the project involves more than one parcel, please provide a comma separated list of parcel numbers."
                                                        />
                                                    }
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    disabled={is_read_only}
                                                    field="location_description"
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Location Description"
                                                            helpText="Please provide a narrative describing the location of the property on which the proposed activities will be conducted and where on the subject property the activity will occur."
                                                        />
                                                    }
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                {activeStep === "PRINT" && (
                                    <Grid item xs={12} lg={10} xl={8}>
                                        <Divider className={classes.printDivider} />
                                    </Grid>
                                )}
                                {(activeStep === 2 || activeStep === "PRINT") && (
                                    <Grid item xs={12} lg={10} xl={8}>
                                        <Typography gutterBottom>
                                            Rules are available at:{" "}
                                            <a target="_blank" rel="noopener noreferrer" href="http://rpbcwd.org/permits">
                                                http://rpbcwd.org/permits
                                            </a>
                                            <br />
                                            Rule guidance materials and FAQs can be found at:{" "}
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href="http://rpbcwd.org/application/files/8515/8888/6646/RPBCWD_Rule-Guidance_2020May_Final.pdf">
                                                http://rpbcwd.org/application/files/8515/8888/6646/RPBCWD_Rule-Guidance_2020May_Final.pdf
                                            </a>
                                        </Typography>
                                        <CheckboxGroup
                                            field="rules"
                                            label="Rule(s) being triggered (check all that apply)"
                                            row={true}
                                            width={205}
                                            disabled={is_read_only}
                                            options={MAKE_OPTIONS([
                                                "Floodplain Management - Rule B",
                                                "Erosion & Sediment Control - Rule C",
                                                "Wetland & Creek Buffers - Rule D",
                                                "Dredging & Sediment Removal - Rule E",
                                                "Shoreline/Streambank Stabilization - Rule F",
                                                "Waterbody Crossings/Structures - Rule G",
                                                "Appropriation of Public Waters - Rule H",
                                                "Stormwater Management - Rule J",
                                            ])}
                                        />
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <ReactSelect
                                                    label="Select all other agencies from which a permit has been applied for"
                                                    field="permits_applied"
                                                    options={MAKE_OPTIONS([
                                                        "City",
                                                        "County",
                                                        "MN Pollution Control Agency",
                                                        "MN Department of Natural Resources",
                                                        "Army Corps of Engineers",
                                                        "Other",
                                                        "None",
                                                    ])}
                                                    disabled={is_read_only}
                                                    isMulti={true}
                                                    height="auto"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <ReactSelect
                                                    label="Select all other agencies from which a permit has been received"
                                                    field="permits_received"
                                                    options={MAKE_OPTIONS([
                                                        "City",
                                                        "County",
                                                        "MN Pollution Control Agency",
                                                        "MN Department of Natural Resources",
                                                        "Army Corps of Engineers",
                                                        "Other",
                                                        "None",
                                                    ])}
                                                    disabled={is_read_only}
                                                    isMulti={true}
                                                    height="auto"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                {activeStep === "PRINT" && (
                                    <Grid item xs={12} lg={10} xl={8}>
                                        <Divider className={classes.printDivider} />
                                    </Grid>
                                )}
                                {(activeStep === 3 || activeStep === "PRINT") && (
                                    <Grid item xs={12} lg={10} xl={8}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            Project Details
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <TextField label="Project Name *" field="project_name" disabled={is_read_only} fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Select
                                                    disabled={is_read_only}
                                                    field="project_type"
                                                    label="Project Type"
                                                    options={MAKE_OPTIONS([
                                                        "Existing Single - Family",
                                                        "Government - Linear",
                                                        "Government - Other",
                                                        "Private - Commercial/Industrial",
                                                        "Private - Residential",
                                                        "Shoreline",
                                                    ])}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Proposed Work"
                                                    field="proposed_work"
                                                    multiline
                                                    rows={8}
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <DatePicker
                                                    field="construction_start"
                                                    label="Planned Construction Start Date"
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <DatePicker
                                                    field="construction_end"
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Planned Construction End Date"
                                                            helpText="By default, a permit issued by the RPBCWD is valid for one year from the date of approval. If you know your project will require greater than a year to complete, please request additional time."
                                                        />
                                                    }
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    useNumberFormat3
                                                    label="Size of project parcel(s)"
                                                    field="size"
                                                    unitsVisual="acres"
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    useNumberFormat3
                                                    label="Volume of excavation/fill"
                                                    field="volume"
                                                    unitsVisual="cubic yards"
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    useNumberFormat3
                                                    label="Area of disturbance"
                                                    field="area_disturbance"
                                                    unitsVisual="acres"
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    useNumberFormat3
                                                    label="Area of existing impervious surface"
                                                    field="area_existing_impervious"
                                                    unitsVisual="acres"
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    useNumberFormat3
                                                    label="Area of proposed impervious surfaces"
                                                    field="area_proposed_impervious"
                                                    unitsVisual="acres"
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    useNumberFormat3
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Area of fully reconstructed impervious surface"
                                                            helpText={
                                                                <div>
                                                                    Impervious surface is considered fully reconstructed if the underlying soils are
                                                                    disturbed. See "Rehabilitation" in{" "}
                                                                    <a
                                                                        rel="noopener noreferrer"
                                                                        href="http://rpbcwd.org/application/files/2515/7781/4739/Definitions_and_Acronyms_from_Rules_12.19.pdf"
                                                                        target="_blank">
                                                                        Definitions and Acronyms
                                                                    </a>
                                                                    .
                                                                </div>
                                                            }
                                                        />
                                                    }
                                                    field="area_reconstructed_impervious"
                                                    unitsVisual="acres"
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    useNumberFormat3
                                                    label="New increase in site impervious aea"
                                                    field="new_increase_impervious"
                                                    unitsVisual="acres"
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6} />
                                            <Grid item xs={6}>
                                                <Select
                                                    label="Is there a wetland present on or adjacent to the property"
                                                    field="wetland_present"
                                                    disabled={is_read_only}
                                                    fullWidth
                                                    options={MAKE_OPTIONS(["Yes", "No"])}
                                                />
                                            </Grid>
                                            <Grid item xs={6} />
                                            <Grid item xs={6}>
                                                <Subquestion
                                                    component={
                                                        <Select
                                                            disabled={is_read_only}
                                                            field="delineated"
                                                            label="If a wetland(s) is present on the property, have they been delineated?"
                                                            options={MAKE_OPTIONS(["Yes", "No", "Not Applicable", "Unknown"])}
                                                            fullWidth
                                                        />
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={6} />
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Only applicable if Rule F was selected
                                                </Typography>
                                                <TextField
                                                    useNumberFormat3
                                                    label="Length of shoreline and/or streambank affected"
                                                    field="length_of_shoreline"
                                                    unitsVisual="feet"
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6} />
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Only applicable if Rule J was selected
                                                </Typography>
                                                <TextField
                                                    useNumberFormat3
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Total regulated impervious surface"
                                                            helpText="Regulated impervious surface equals the total new and fully reconstructed impervious surface less any exempt impervious surface such as trail 10-feet wide or narrower that are bordered downgradient by a pervious area at least one-half the width of the trail.  On redevelopment sites, the total regulated impervious surface would include all impervious surface on the site if the proposed activity exceeds the thresholds in Rule J, subsection 2.3.  If you do not know the total, please leave blank and work with district staff to determine the area."
                                                        />
                                                    }
                                                    field="total_regulared_impervious"
                                                    unitsVisual="acres"
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle1" gutterBottom style={{ visibility: "hidden" }}>
                                                    A
                                                </Typography>
                                                <TextField
                                                    useNumberFormat3
                                                    label="Area of trails exempted under subsection 2.2d of Rule J"
                                                    field="trails_exempted"
                                                    unitsVisual="acres"
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                {activeStep === "PRINT" && (
                                    <Grid item xs={12} lg={10} xl={8}>
                                        <Divider className={classes.printDivider} />
                                    </Grid>
                                )}
                                {(activeStep === 4 || activeStep === "PRINT") && (
                                    <Grid item xs={12} lg={10} xl={8}>
                                        <Typography variant="subtitle1">Attachments</Typography>
                                        <Typography gutterBottom>
                                            Please attached all electronic files associated with your applications. This could include but is not
                                            limited to signed permit form, construction drawings, project narrative, water quantity and quality
                                            modeling in their respective native form, draft maintenance declaration/agreement, Stormwater Management
                                            plan, Chloride Management plan, etc.
                                        </Typography>
                                        <Button
                                            onClick={() =>
                                                this.setState({
                                                    attachmentDialogOpen: true,
                                                    attachmentEditing: null,
                                                    attachmentFormKey: window.performance.now(),
                                                })
                                            }
                                            disabled={is_read_only}
                                            variant="contained"
                                            color="primary">
                                            Add Attachment
                                        </Button>
                                        <Table style={{ marginTop: 8 }}>
                                            <EnhancedTableHead
                                                columnData={columnDataAttachments}
                                                order={attachmentOrder}
                                                orderBy={attachmentOrderBy}
                                                onRequestSort={this.handleRequestSort2}
                                            />
                                            <TableBody>
                                                {temp_attachments.map((n) => {
                                                    return (
                                                        <TableRow hover>
                                                            <CustomTableCell className={classes.nowrap}>
                                                                {n.file.includes("https") && (
                                                                    <Tooltip title="Open Link">
                                                                        <Button
                                                                            color="primary"
                                                                            onClick={() => {
                                                                                window.open(n.file);
                                                                            }}
                                                                            className={classes.deleteWidth}>
                                                                            <ViewIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                )}
                                                                <Tooltip title="Delete">
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() =>
                                                                            this.setState({
                                                                                temp_attachments: temp_attachments.filter((tc) => tc !== n),
                                                                            })
                                                                        }
                                                                        disabled={is_read_only}
                                                                        className={classes.deleteWidth}>
                                                                        <DeleteIcon color="primary" />
                                                                    </Button>
                                                                </Tooltip>
                                                            </CustomTableCell>
                                                            <CustomTableCell>{n.name}</CustomTableCell>
                                                            <CustomTableCell>{n._type}</CustomTableCell>
                                                            <CustomTableCell>{n.notes}</CustomTableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                                {temp_attachments.length < 1 && (
                                                    <TableRow>
                                                        <CustomTableCell colSpan={columnDataAttachments.length} className={classes.centerAlign}>
                                                            No Attachments Added
                                                        </CustomTableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                )}
                                {activeStep === "PRINT" && (
                                    <Grid item xs={12} lg={10} xl={8}>
                                        <Divider className={classes.printDivider} />
                                    </Grid>
                                )}
                                {(activeStep === 5 || activeStep === "PRINT") && (
                                    <Grid item xs={12} lg={10} xl={8}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            Submittal Requirements
                                        </Typography>
                                        <table>
                                            <tr>
                                                <td style={{ whiteSpace: "nowrap", paddingRight: 16 }}>
                                                    <Checkbox disabled={is_read_only} label="I AGREE *" field="requirements" />
                                                </td>
                                                <td>
                                                    <Typography>
                                                        I HAVE READ THE DISTRICT PERMIT RULES AND ATTEST THAT A COMPLETE APPLICATIONS MUST BE
                                                        SUBMITTED AND RECEIVED 30 BUSINESS DAYS PRIOR TO THE REGULAR BOARD OF MANAGERS MEETING,
                                                        GENERALLY THE FIRST WEDNESDAY OF EACH MONTH (CHECK WEBSITE FOR EXACT MEETING DAYS) WITH ALL OF
                                                        THE REQUIRED INFORMATION OTHERWISE THE APPLICATION WILL BE DEEMED INCOMPLETE.
                                                    </Typography>
                                                </td>
                                            </tr>
                                        </table>
                                        <Typography variant="subtitle1" gutterBottom>
                                            Applicant Signature
                                        </Typography>
                                        <table>
                                            <tr>
                                                <td style={{ whiteSpace: "nowrap", paddingRight: 16 }}>
                                                    <Checkbox disabled={is_read_only} label="I AGREE *" field="signature" />
                                                </td>
                                                <td>
                                                    <Typography gutterBottom>
                                                        “BY CHECKING I AGREE, I UNDERSTAND THAT, AS THE PERMITTEE, I AM LEGALLY ACCOUNTABLE TO ENSURE
                                                        COMPLIANCE WITH THE TERMS AND CONDITIONS OF THE PERMIT. I UNDERSTAND THAT I AM NOT AUTHORIZED
                                                        TO BEGIN THE PROJECT UNTIL I HAVE RECEIVED THE PERMIT. IF THE PROJECT IS MODIFIED, I WILL
                                                        OBTAIN APPROVAL BY THE RILEY PURGATORY BLUFF CREEK WATERSHED DISTRICT BEFORE I CONTINUE WITH
                                                        THE PROJECT. I AUTHORIZE THE DISTRICT, AND ITS AGENTS, EMPLOYEES, OFFICERS, AND CONTRACTORS,
                                                        TO ENTER THE PROJECT SITE TO PERFORM ANY INSPECTIONS OF WORK AUTHORIZED BY THE PERMIT OR ANY
                                                        APPLICABLE LAW.”
                                                    </Typography>
                                                    <Typography>
                                                        “I CERTIFY THAT I HAVE THOROUGHLY READ AND UNDERSTAND THE ABOVE INFORMATION.”
                                                    </Typography>
                                                </td>
                                            </tr>
                                        </table>
                                        <Typography variant="subtitle1" gutterBottom>
                                            Applicant Acknowledgement
                                        </Typography>
                                        <table>
                                            <tr>
                                                <td style={{ whiteSpace: "nowrap", paddingRight: 16 }}>
                                                    <Checkbox disabled={is_read_only} label="I AGREE *" field="signature2" />
                                                </td>
                                                <td>
                                                    <Typography>
                                                        I HAVE READ AND AGREE TO COMPLY WITH THE RPBCWD PERMIT FEE DEPOSIT AND RATE SCHEDULE AVAILABLE
                                                        UNDER THE SUPPORTING DOCUMENTS SECTION OF THE RPBCWD PERMITTING WEB PAGE (
                                                        <a rel="noopener noreferrer" href="http://rpbcwd.org/permits" target="_blank">
                                                            HTTP://RPBCWD.ORG/PERMITS
                                                        </a>
                                                        )
                                                    </Typography>
                                                </td>
                                            </tr>
                                        </table>
                                    </Grid>
                                )}
                                {activeStep !== "PRINT" && (
                                    <Grid item xs={12} lg={10} xl={8} className={classes.rightAlign}>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={() => this.setState({ activeStep: activeStep - 1 })}
                                            className={classes.button}>
                                            Back
                                        </Button>
                                        <Button
                                            onClick={() => this.handleNext()}
                                            disabled={is_read_only && activeStep === 5}
                                            variant="contained"
                                            color="primary"
                                            style={{ marginLeft: 16 }}>
                                            {activeStep === 5 ? "Submit Permit Application" : "Next"}
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </form>
                    )}
                </Form>
                <Dialog open={contactDialogOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.flex}>
                            Other Contact
                        </Typography>
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({ contactDialogOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <DialogContentText>
                            <Form
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                key={formKey}
                                defaultValues={contactEditing}
                                validateError={this.errorValidatorContact}
                                onSubmit={(values) => this.addContact(values)}>
                                {(formApi) => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <TextField field="first_name" label="First Name *" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="last_name" label="Last Name *" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="organization" label="Organization / Company" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="mailing_address" label="Mailing Address *" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="mailing_address2" label="Mailing Address 2" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="city" label="City *" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Select field="state" label="State *" options={states} fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="zip" label="Zip *" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="phone" label="Phone *" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField field="email" label="Email *" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Select
                                                    field="type"
                                                    label="Type *"
                                                    options={MAKE_OPTIONS([
                                                        "Secondary Property Owner",
                                                        "Secondary Owner Representative",
                                                        "Contractor",
                                                        "Consultant",
                                                        "Erosion Control Representative",
                                                        "Other",
                                                    ])}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6} />
                                            <Grid item xs={6}>
                                                <Button fullWidth onClick={() => this.setState({ contactDialogOpen: false })}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog open={attachmentDialogOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.flex}>
                            Attachment
                        </Typography>
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({ attachmentDialogOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <DialogContentText>
                            <Form
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                key={attachmentFormKey}
                                defaultValues={attachmentEditing}
                                validateError={this.errorValidatorAttachment}
                                onSubmit={(values) => this.addAttachment(values)}>
                                {(formApi) => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography gutterBottom>
                                                    Only these file types can be directly uploaded as an attachment. All other file types should be
                                                    put into a .zip file and then uploaded. Direct supported file types are: PDF (.pdf), Microsoft
                                                    Word (.doc, .docx), Microsoft Excel (.xls, .xlsx), Images (.png, .jpg, .jpeg, .tiff, .bmp),
                                                    Microsoft Powerpoint (.ppt, .pptx), Zip files (.zip).
                                                </Typography>
                                                <FileInput field="file" id="file_upl" fullWidth label="File *" />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField field="name" label="Name *" fullWidth />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Select
                                                    field="_type"
                                                    label="Type"
                                                    options={MAKE_OPTIONS([
                                                        "Civil Plans (Grading Plan/Drainage Plan/Utility Plan)",
                                                        "Project Narrative",
                                                        "H&H Model Native Form",
                                                        "Water Quality Model - Native Form",
                                                        "Stormwater Management Plan",
                                                        "Erosion Prevention and Sediment Control Plan",
                                                        "Inspection Plan",
                                                        "Chloride Plan",
                                                        "Maintenance Declaration",
                                                        "Maintenance Agreement",
                                                        "Variance Discussion",
                                                        "Other",
                                                    ])}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField field="notes" label="Notes" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth onClick={() => this.setState({ attachmentDialogOpen: false })}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </AppContainerApplication>
        );
    }
}

Rpbcwd = connect(
    (state, ownProps) => ({
        application: getApplication(state, ownProps),
        authState: state.auth, // Admins will have an authstate
    }),
    {
        ...ApplicationRecord.actions,
        ...ApplicationRecordStatus.actions,
        ...RpbcwdPermit.actions,
        ...RpbcwdPermitContact.actions,
        ...RpbcwdPermitAttachment.actions,
    }
)(Rpbcwd);

export default withStyles(styles)(withRouter(Rpbcwd));
