import React, { Component } from "react";
import classNames from "classnames";
import { FormField } from "react-form";
import NumberFormat from "react-number-format";
import FormHelperText from "@material-ui/core/FormHelperText";
import MuiTextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Cancel from "@material-ui/icons/Cancel"; 
 
import HelpLabel from "./HelpLabel";

const styles = (theme) => ({
    repeatable: {
        display: "contents",
    },
    bootstrapInput: {
        borderRadius: 4,
        border: "1px solid #979797",
        padding: "6px 8px",
        width: "calc(100% - 24px)",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderColor: theme.palette.secondary.light,
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
        backgroundColor: "#fff",
        fontSize: "15px",
        fontWeight: 500,
        color: "#262626 !important",
    },
    bootstrapInputError: {
        border: "1px solid #f44336",
        borderRadius: 4,
        padding: "6px 8px",
        width: "calc(100% - 24px)",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderColor: theme.palette.secondary.light,
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
        backgroundColor: "#fff",
        fontSize: "15px",
        fontWeight: 500,
        color: "#262626 !important",
    },
    bootstrapSelectError: {
        // Select menus are a div not a input
        border: "1px solid #f44336",
    },
    bootstrapSelectError2: {
        paddingRight: "8px !important",
    },
    selectIcon: {
        right: "8px !important",
    },
    rootLabelShrink: {
        marginLeft: 0,
        marginBottom: -12,
        position: "inherit",
        // marginTop: -2,
        // whiteSpace: "nowrap",
        //overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
    },
    rootLabelShrinkCustom: {
        marginLeft: 0,
        marginTop: -2,
        //whiteSpace: "nowrap",
        //overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
    },
    rootLabelShrinkCustom2: {
        marginLeft: 0,
        marginTop: -16,
        //whiteSpace: "nowrap",
        //overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
    },
    rootLabelShrinkExpansionPanel: {
        //for help with label to offset header
        marginLeft: 0,
        marginTop: -7,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
    },
    whiteSpace: {
        whiteSpace: "nowrap",
    },
    disabled: {
        backgroundColor: "#EEEEEE",
    },
    noPadding: {
        padding: 0,
    },
    wordCount: {
        backgroundColor: "#EEEEEE",
        padding: 4,
        //marginTop: -16,
        marginBottom: 4,
        border: "1px solid #979797",
        borderRadius: 4,
        marginRight: 8,
    },
});

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            fixedDecimalScale //adds cents to value
            prefix="$"
            // allowNegative={true}
            decimalScale={2}
        />
    );
}

// number with 0 decimals
function NumberFormatCustom4(props) {
    const { inputRef, onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            allowNegative={false}
            decimalScale={0}
        />
    );
}

function PhoneNumberFormat(props) {
    const { inputRef, onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            format="+1 (###) ###-####"
            allowEmptyFormatting
            mask="_"
        />
    );
}

// See https://react-form.js.org/#/custom-input
class TextFieldWrapper extends Component {
    // generic number//numeric
    NumberFormatCustom2 = (props) => {
        const { inputRef, onChange, ...other } = props;
        const { decimals, thousandSeperator } = this.props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    if (values.formattedValue === ".") values.formattedValue = "0.";
                    if (values.value === ".") values.value = "0.";

                    if (this.props.restrictValue) {
                        var split = this.props.restrictValue.split(",");
                        if ((values.value >= parseFloat(split[0]) && values.value <= parseFloat(split[1])) || values.value === "") {
                            onChange({
                                target: {
                                    value: values.value,
                                },
                            });
                        } else {
                            let preVal = props.value;
                            //add zero infront of decimal only case
                            if (preVal.charAt(0, ".") === ".") preVal = "0" + preVal;

                            //dont change UI as out of bounds of restrictValue prop
                            onChange({
                                target: {
                                    value: preVal,
                                },
                            });
                        }
                    } else if (!thousandSeperator) {
                        //no commas with number
                        onChange({
                            target: {
                                value: values.value,
                            },
                        });
                    } else {
                        onChange({
                            target: {
                                value: values.value, //changed to remove commas from db, 4/7/22
                            },
                        });
                    }
                }}
                decimalScale={decimals}
                thousandSeparator={thousandSeperator}
            />
        );
    };

    //applications
    NumberFormatCustom3 = (props) => {
        const { inputRef, onChange, ...other } = props;
        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            value: values.value,
                        },
                    });
                }}
                isAllowed={(val)=>{//set maxValue for max to be entered, minValue for minimum to be entered 
                    var maxValue = this.props.maxValue;
                    var minValue = this.props.minValue;    
                    
                    let {floatValue} = val
                    if(!floatValue) floatValue = 0
                    if (!maxValue) maxValue = 100000000000000000000000000000000000000000000000000000000000000000 
                    
                    if(typeof(minValue) === 'number'){   //there is a minValue and maxValue make sure its within
                        return floatValue >= minValue && floatValue <= maxValue
                    }else{//there isnt a min value so just check that its <= to maxValue
                        return floatValue <= maxValue
                    }
                }}
                thousandSeparator
            />
        );
    };

    handleChange = (e) => {
        var value = e.target.value;

        const { fieldApi, eventHandle } = this.props;
        const { setValue, setTouched } = fieldApi;

        if (value === "") value = null;

        setValue(value);
        setTouched();

        if (eventHandle) eventHandle(value);
    };

    handleClear = () => {
        const { fieldApi, eventHandle } = this.props;
        const { setValue, setTouched } = fieldApi;

        setValue(null);
        setTouched();

        if (eventHandle) eventHandle(null);
    };

    render() {
        const {
            fieldApi,
            helperText,
            SelectProps,
            units,
            help,
            classes,
            label,
            disabled,
            unitsVisual,
            useTextFormat, //for currency
            useNumberFormat,
            usePhoneNumberFormat,
            useNumberFormat3,
            children,
            isRequired,
            required,
            margin,
            identification,
            isInteger,
            select,
            userNotRequired,
            expansionPanel,
            filterInput,
            useCustom,
            useCustom2,
            maxWords,
            thousandSeperator,
            repeatable,
            id,
            ...rest
        } = this.props;
        const { getValue, getError, getWarning, getSuccess, setValue /* setTouched*/ } = fieldApi;

        const value = getValue() || (SelectProps ? (SelectProps.multiple ? [] : "") : getValue() === 0 ? 0 : ""),
            error = getError(),
            warning = getWarning(),
            success = getSuccess(),
            hasError = !!error,
            errorText = error || warning || success || helperText;

        let InputProps = {},
            renderValue;

        //end of field write out measurement_unit
        if (help || units || unitsVisual) {
            if (units || unitsVisual) {
                //print measurement unit after field
                InputProps["endAdornment"] = (
                    <InputAdornment position="end" className={classes.whiteSpace}>
                        {(units || unitsVisual) && (
                            <Typography color="textSecondary">
                                {units}
                                {unitsVisual}
                                {help && <HelpLabel question={label} helpText={help} inputLabel={true} />}
                            </Typography>
                        )}
                        {help && !units && !unitsVisual && <HelpLabel question={label} helpText={help} />}
                    </InputAdornment>
                );
            }
        }

        if (units) {
            if (value && value.split) {
                renderValue = value.split(" ")[0];
            } else {
                renderValue = value;
            }
        } else {
            renderValue = value;
        }

        InputProps["disableUnderline"] = true;
        InputProps["classes"] = {
            input: classNames(!error ? classes.bootstrapInput : classes.bootstrapInputError, disabled && classes.disabled),
            // error: classes.bootstrapInputError,
            root: classes.noPadding,
        };

        //number formater case -- uses above functions
        if (useTextFormat) {
            InputProps["inputComponent"] = NumberFormatCustom; //currency
        } else if (useNumberFormat) {
            InputProps["inputComponent"] = this.NumberFormatCustom2; //numeric
        } else if (usePhoneNumberFormat) {
            InputProps["inputComponent"] = PhoneNumberFormat; //phone
        } else if (isInteger) {
            InputProps["inputComponent"] = NumberFormatCustom4; // 0 decimals
        } else if (useNumberFormat3) {
            InputProps["inputComponent"] = this.NumberFormatCustom3; //applications
        }

        let sProps = {};
        if (SelectProps !== undefined) {
            sProps = SelectProps;
        }
        sProps["classes"] = {
            select: hasError ? classes.bootstrapSelectError : classes.bootstrapSelectError2,
            icon: classes.selectIcon,
        };

        var val = renderValue.toString();
        if (val && filterInput) {
            InputProps["endAdornment"] = (
                <InputAdornment position="end">
                    <Tooltip title="Clear Filter">
                        <IconButton aria-label="Clear Filter" onClick={this.handleClear}>
                            <Cancel />
                        </IconButton>
                    </Tooltip>
                </InputAdornment>
            );
        }

        if (maxWords) {
            var textLimit = parseFloat(maxWords);
            var count;
            var splitString = val.split(/[\s]+/);
            splitString = splitString.filter((ss) => ss !== "");
            count = splitString.length;

            if (splitString.length > textLimit) {
                var counter = 0;
                var j = 0;
                while (j <= textLimit - 1) {
                    counter += splitString[j].length;
                    j++;
                }
                var first_part = val.substring(0, counter);
                var second_part = val.substring(counter);

                var word1 = splitString[textLimit - 3];
                var word2 = splitString[textLimit - 2];
                var word3 = splitString[textLimit - 1];
                var testreg = new RegExp("\\s" + word1 + "\\s" + word2 + "\\s" + word3);
                var test = second_part.split(testreg);

                val = first_part + test[0] + second_part.match(testreg);
                setValue(val);
                console.log(val);
                splitString = val.split(/[\s]+/);
                splitString = splitString.filter((ss) => ss !== "");
                count = splitString.length;
            }

            var color2 = "auto";
            if (count >= textLimit) color2 = "#be0000";
            else color2 = "rgb(38, 38, 38)";
        }

        return (
            <>
                {!repeatable && (
                    
                    <MuiTextField
                        id={id}
                        value={val}
                        error={hasError || rest.isRequired || isRequired}
                        label={!label && !select ? children : label}
                        disabled={disabled}
                        helperText={errorText}
                        select={select}
                        onChange={this.handleChange}
                        // onBlur={(e) => {
                        //     setTouched(_key, true);
                        // }}
                        required={required}
                        SelectProps={sProps}
                        InputProps={InputProps}
                        margin="none"
                        children={children}
                        InputLabelProps={{
                            shrink: true,
                            classes: {
                                shrink: useCustom
                                    ? classes.rootLabelShrinkCustom
                                    : useCustom2
                                    ? classes.rootLabelShrinkCustom2
                                    : !expansionPanel
                                    ? classes.rootLabelShrink
                                    : classes.rootLabelShrinkExpansionPanel,
                            },
                        }}
                        {...rest}
                    />
                )}
                {repeatable && (
                    <MuiTextField
                        value={val}
                        error={hasError || rest.isRequired || isRequired}
                        label={!label && !select ? children : label}
                        disabled={disabled}
                        helperText={errorText}
                        select={select}
                        onChange={this.handleChange}
                        required={required}
                        SelectProps={sProps}
                        InputProps={InputProps}
                        margin="none"
                        children={children}
                        InputLabelProps={{
                            shrink: true,
                            classes: {
                                shrink: classes.repeatable,
                            },
                        }}
                        {...rest}
                    />
                )}
                {maxWords && (
                    <Typography className={classes.wordCount} style={{ color: color2 }}>
                        Word Count: {count} out of {maxWords}
                    </Typography>
                )}
                {required && !hasError && !userNotRequired && <FormHelperText style={{ marginTop: -1 }}>Required</FormHelperText>}
            </>
        );
    }
}
TextFieldWrapper.displayName = "TextFieldWrapper";

const TextField = FormField(TextFieldWrapper);

export default withStyles(styles)(TextField);
