import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import * as authActions from "../auth/actions";
import LoginPage from "../auth/LoginPage";
import AppContainer from "../common/AppContainer";
import * as navActions from "../common/actions";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";

// The front landing page of the application
// Useful if you want to see a basic completed page and how to call a completely different component from a different page (LoginPage)
// Notice the grid layouts and how all the text are wrapped in typography tags
// https://material-ui.com/layout/grid/

const styles = (theme) => ({
    marginTopMain: {
        marginTop: 24,
    },
    marginBottomMain: {
        [theme.breakpoints.up("md")]: {
            marginBottom: 72,
        },
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    marginBottom: {
        marginBottom: 40,
    },
    marginBottomButton: {
        marginBottom: 24,
    },
    linkColor: {
        color: "#808080",
    },
    leftDivide: {
        [theme.breakpoints.up("md")]: {
            paddingRight: 32,
            borderRight: "2px solid #262626",
        },
    },
    nowrap: {
        [theme.breakpoints.up("md")]: {
            flexWrap: "nowrap",
        },
    },
    rightDivide: {
        [theme.breakpoints.up("md")]: {
            paddingLeft: 32,
        },
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
});

class HomePage extends Component {
    continueRegister = () => {
        this.props.history.push("/register");
    };

    render() {
        const { classes } = this.props;
        return (
            <AppContainer>
                <Grid container spacing={5} justify="center">
                    <Grid item xs={11} md={8} lg={6} xl={4}>
                        <Typography variant="h6" gutterBottom className={classes.marginTopMain}>
                            <b>Welcome to the MS4Front Data Management Platform</b>
                        </Typography>
                        <Typography variant="body1" className={classes.marginBottomMain}>
                        A Software as a Service (SaaS) platform to tackle organizations most challenging data management needs. Our platform provides configurable data entry forms, GIS integration and reporting tools.  
                        Our roots stem from helping City and Counties with their MS4 permit tracking but recently our system has been extending to assist numerous other organizations. Those include grant, conservation and regulatory permit program management.
                        </Typography>
                        <Grid container className={classes.nowrap}>
                            <Grid item xs={12} md={6} className={classes.leftDivide}>
                                <LoginPage />
                            </Grid>
                            <Grid item xs={12} md={6} className={classes.rightDivide}>
                                <Typography variant="h6" gutterBottom>
                                    New Users
                                </Typography>
                                <Typography variant="body1" className={classes.marginBottom}>
                                    If you are interested in a demonstration of the software please visit{" "}
                                    <a className={classes.linkColor} href="http://www.ms4front.com/schedule-a-demonstration/" target="_blank" rel="noopener noreferrer">
                                        our website
                                    </a>
                                    .
                                </Typography>
                                <Typography variant="h6" gutterBottom>
                                    Need Help?
                                </Typography>
                                <Typography variant="body1">
                                    Please contact our technical support at{" "}
                                    <a className={classes.linkColor} href="mailto:jlewis@houstoneng.com?subject=MS4Front Support Request">
                                        jlewis@houstoneng.com
                                    </a>{" "}
                                    or call 763-493-4522
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}
HomePage.displayName = "HomePage";

HomePage = connect((state) => ({ authState: state.auth }), {
    ...authActions,
    ...navActions,
})(HomePage);

export default withStyles(styles)(withRouter(HomePage));
