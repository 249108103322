import { ORM, Model as ORMModel, ForeignKey, createSelector as ormSelector } from "redux-orm";
import { AUTH_LOGOUT } from "../auth/actions";
// This page acts as a wrapper between our models and redux-orm
// The important details are to understand how to access objects from the models [createSelector(), selectAll(), selectByUrlId()]
// And how to save/load objects directly from the database orm[model name][action]()
// Exs: ormPageCreate(payload), ormPageUpdate(payload), ormPageDelete(id)
// See static get actions() from class Model (not BaseModel)

//instance "orm" from 'redux-orm'. This instance uses model.js class to "register" all of the models
// "orm" then generates a full schema from all of the Models and passes that information onto the database
const orm = new ORM();
export default orm;

// A custom function to get every object from a model
// For example if you need the objects in a custom order
/* Ex: 
    const allForms = createSelector(schema => {
    return schema.Form.all()
        .orderBy("name")
        .toModelArray();
    });
*/

// Behind the scenes, `ormSelector` begins a Redux-ORM session
// with the value returned by `state` and passes
// that Session instance as an argument instead. For more info and an example
// refer to https://github.com/redux-orm/redux-orm and search createSelector
export function createSelector(...args) {
    return ormSelector(orm, (state) => state.orm, ...args);
}

// A quick function to get every object from a model
export function selectAll(cls, serialize = (obj) => obj.ref, orderBy = null) {
    return createSelector((schema) => schema[cls.modelName].all().orderBy(orderBy).toModelArray().map(serialize));
}

// A quick function to get a specific object from a model where the "id" is in the url params
export function selectByUrlId(cls, serialize = (obj) => obj.ref, param = "id") {
    return createSelector(
        (state, ownProps) => ownProps.match.params[param],
        (schema, objId) => {
            cls = schema[cls.modelName];
            if (cls.idExists(objId)) {
                var obj = cls.withId(objId);
                return serialize(obj);
            } else {
                // FIXME: Render a 404 page?
                return { name: "Not Found" };
            }
        }
    );
}

// If the user is not logged in they won't have an authstate
function isRealUser(authState) {
    return authState && authState.user;
}

export class BaseModel extends ORMModel {
    static selectAll(serializer) {
        return selectAll(this, serializer);
    }

    static selectByUrlId(serializer, param = "id") {
        return selectByUrlId(this, serializer, param);
    }

    static get source() {
        return null;
    }

    static get loadByDefault() {
        return null;
    }

    static get allowPublicAccess() {
        return null;
    }

    static get actions() {
        const functionPrefix = `orm${this.modelName}`,
            typePrefix = `ORM_${this.modelName.toUpperCase()}`;
        return {
            [`${functionPrefix}Reload`]: () => {
                var cls = this;
                return function (dispatch, getState) {
                    const state = getState();
                    if (cls.loadByDefault) {
                        if (!isRealUser(state.auth)) {
                            return;
                        }
                    }
                    dispatch({
                        type: `${typePrefix}_PULLING`,
                    });
                    let options = {};
                    options.headers = {
                        Authorization: "Token " + state.auth.user.auth_token,
                    };
                    return fetch(`${cls.source}?format=json&t=${Date.now()}`, options)
                        .then((result) => result.json())
                        .then((data) => {
                            if (data.list) {
                                data = data.list;
                            }
                            if (!(data instanceof Array)) {
                                throw new Error(data.detail || data);
                            }
                            return data;
                        })
                        .then((data) => {
                            dispatch({
                                type: `${typePrefix}_PULLED_DELETE`,
                                payload: data,
                            });
                        })
                        .catch((e) => {
                            if (e.message === "Invalid token.") {
                                dispatch({
                                    type: AUTH_LOGOUT,
                                });
                            }
                            dispatch({
                                type: `${typePrefix}_PULLERROR`,
                                error: e,
                            });
                        });
                };
            },
            [`${functionPrefix}ReloadDynamic`]: (attributes) => {
                var cls = this;
                return function (dispatch, getState) {
                    const state = getState();
                    dispatch({
                        type: `${typePrefix}_PULLING`,
                    });
                    let options = {};
                    options.headers = {
                        Authorization: "Token " + state.auth.user.auth_token,
                    };
                    return fetch(`${cls.source}?format=json&t=${Date.now()}${attributes}`, options)
                        .then((result) => result.json())
                        .then((data) => {
                            if (data.list) {
                                data = data.list;
                            }
                            if (!(data instanceof Array)) {
                                throw new Error(data.detail || data);
                            }
                            return data;
                        })
                        .then((data) => {
                            dispatch({
                                type: `${typePrefix}_PULLED_DELETEDYNAMIC`,
                                payload: data,
                            });
                        })
                        .catch((e) => {
                            if (e.message === "Invalid token.") {
                                dispatch({
                                    type: AUTH_LOGOUT,
                                });
                            }
                            dispatch({
                                type: `${typePrefix}_PULLERROR`,
                                error: e,
                            });
                        });
                };
            },
        };
    }

    static fromResponse(data) {
        return data;
    }

    static reducer(action, cls) {
        const prefix = `ORM_${cls.modelName.toUpperCase()}`;
        switch (action.type) {
            case `${prefix}_PULLED`:
                action.payload.forEach((obj) => cls.upsert(cls.fromResponse(obj)));
                break;
            case `${prefix}_PULLED_DELETE`:
                var ids = action.payload.map((obj) => obj.id);
                cls.exclude((obj) => ids.includes(obj.id)).delete();
                action.payload.forEach((obj) => cls.upsert(cls.fromResponse(obj)));
                break;
            case `${prefix}_PULLED_DELETEDYNAMIC`:
                cls.delete();
                action.payload.forEach((obj) => cls.upsert(cls.fromResponse(obj)));
                break;
            default:
                break;
        }
    }
}

export class Model extends BaseModel {
    static get source() {
        return `/api/db/${this.pluralName}`;
    }

    static get pluralName() {
        return `${this.modelName.toLowerCase()}s`;
    }

    static get loadByDefault() {
        return true;
    }

    static createAction({ type, payload, effectIfLoggedIn, generateId }) {
        return (dispatch, getState) => {
            const state = getState();
            const { auth } = state;
            if (generateId) {
                if (!payload) {
                    payload = {};
                }
                payload.id = this.generateId(payload);
            }
            let action = {
                type: type,
                payload: payload,
            };
            if (isRealUser(auth)) {
                action.meta = {
                    offline: {
                        effect: {
                            ...effectIfLoggedIn,
                            body: JSON.stringify(this.toRequest(payload)),
                            headers: {
                                Accept: "application/json",
                                Authorization: "Token " + auth.user.auth_token,
                            },
                        },
                        commit: {
                            type: `${type}_PUSHED`,
                        },
                        rollback: {
                            type: `${type}_PUSHERROR`,
                            meta: { objectId: payload.id },
                        },
                    },
                };
            }
            dispatch(action);
            if (generateId) {
                return payload.id;
            }
        };
    }

    static fail(message) {
        message = `MS4 ORM Error: ${message}`;
        console.error(message);
        throw new Error(message);
    }

    static generateId(payload) {
        if (payload.id) {
            this.fail(`Unexpected id in orm${this.modelName}Create payload`);
        }

        return Math.floor(Math.random() * (99000000000 - 1000000000) + 1000000000);
    }

    static toRequest(data) {
        var req = {};
        Object.entries(data).forEach(([key, value]) => {
            if (this.fields[key] instanceof ForeignKey) {
                req[key + "_id"] = value;
            } else if (!(key in req)) {
                req[key] = value;
            }
        });
        return req;
    }

    static fromResponse(data) {
        Object.keys(data).forEach((key) => {
            const field = key.replace(/_id$/, "");
            if (field !== key && this.fields[field] instanceof ForeignKey) {
                data[field] = data[key];
                delete data[key];
            }
        });
        return data;
    }

    // these are orm actions CREATOR methods below which call the reducer method, & are commands used with .js files for creating, loadDetail, updating, and deleting data.
    static get actions() {
        const functionPrefix = `orm${this.modelName}`,
            typePrefix = `ORM_${this.modelName.toUpperCase()}`,
            baseActions = super.actions;

        return {
            // orm method layout is orm[page,user, etc]Create. For example: ormPageCreate(payload)
            [`${functionPrefix}Create`]: (payload) => {
                var cls = this;
                return function (dispatch, getState) {
                    const state = getState(),
                        { auth } = state;
                    if (!isRealUser(auth) && !cls.allowPublicAccess) {
                        dispatch({
                            type: `${typePrefix}_CREATE_PUSHERROR`,
                            error: new Error("Not logged in."),
                        });
                        return;
                    }
                    dispatch({
                        type: `${typePrefix}_CREATE_PUSHING`,
                    });
                    var headers = {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    };
                    if (isRealUser(auth)) headers["Authorization"] = "Token " + auth.user.auth_token;
                    return fetch(`${cls.source}?format=json&t=${Date.now()}`, {
                        method: "POST",
                        body: JSON.stringify(cls.toRequest(payload)),
                        headers: headers,
                    })
                        .then((result) => {
                            if (!result.ok) {
                                return result.text().then((text) => {
                                    throw new Error(text);
                                });
                            } else {
                                return result.json();
                            }
                        })
                        .then((data) => {
                            data = cls.fromResponse(data);
                            dispatch({
                                type: `${typePrefix}_CREATE`,
                                payload: data,
                            });
                            dispatch({
                                type: `${typePrefix}_CREATE_PUSHED`,
                            });
                            return data;
                        })
                        .catch((e) => {
                            if (e.message === "Invalid token.") {
                                dispatch({
                                    type: AUTH_LOGOUT,
                                });
                            } else {
                                dispatch({
                                    type: `${typePrefix}_CREATE_PUSHERROR`,
                                    error: e,
                                })
                                return e;
                            }
                        });
                };
            }, //only for redux store to load on front end
            [`${functionPrefix}UpdatePublic`]: (payload) => {
                var cls = this;
                return function (dispatch, getState) {
                    const state = getState(),
                        { auth } = state;
                    if (!isRealUser(auth) && !cls.allowPublicAccess) {
                        dispatch({
                            type: `${typePrefix}_UPDATE_PUSHERROR`,
                            error: new Error("Not logged in."),
                        });
                        return;
                    }
                    dispatch({
                        type: `${typePrefix}_UPDATE_PUSHING`,
                    });
                    var headers = {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    };
                    if (isRealUser(auth)) headers["Authorization"] = "Token " + auth.user.auth_token;
                    return fetch(`${cls.source}/${payload.id}?t=${Date.now()}`, {
                        method: "PATCH",
                        body: JSON.stringify(cls.toRequest(payload)),
                        headers: headers,
                    })
                        .then((result) => {
                            if (!result.ok) {
                                return result.text().then((text) => {
                                    throw new Error(text);
                                });
                            } else {
                                return result.json();
                            }
                        })
                        .then((data) => {
                            data = cls.fromResponse(data);
                            dispatch({
                                type: `${typePrefix}_UPDATE`,
                                payload: data,
                            });
                            dispatch({
                                type: `${typePrefix}_UPDATE_PUSHED`,
                            });
                            return data;
                        })
                        .catch((e) =>
                            dispatch({
                                type: `${typePrefix}_UPDATE_PUSHERROR`,
                                error: e,
                            })
                        );
                };
            },
            [`${functionPrefix}LoadDetailChild`]: (id, callback, multiple, map) => {
                var cls = this;
                return function (dispatch, getState) {
                    const state = getState(),
                        { auth } = state;
                    dispatch({
                        type: `${typePrefix}_DETAIL_LOADING`,
                    });
                    let options = {};
                    if (isRealUser(auth)) {
                        options.headers = {
                            Authorization: "Token " + state.auth.user.auth_token,
                        };
                    }
                    var atrrs = `&type=${cls.modelName}&filter=${id}`;
                    if (multiple) {
                        if (map === "true") {
                            atrrs = `&type=${multiple}&filter=${id}&map=${map}`;
                        } else {
                            // Loading all recordsections for a parent
                            atrrs = `&type=${multiple}&filter=${id}`;
                        }
                    }
                    fetch(`${cls.source}?format=json&t=${Date.now()}${atrrs}`, options)
                        .then((result) => result.json())
                        .then((data) => {
                            if (data.list) {
                                data = data.list;
                            }
                            if (!(data instanceof Array)) {
                                throw new Error(data.detail || data);
                            }
                            return data;
                        })
                        .then((data) => {
                            dispatch({
                                type: `${typePrefix}_DETAIL_LOADED`,
                                payload: data,
                            });
                            if (callback) callback(data);
                        })
                        .catch((e) => {
                            if (e.message === "Invalid token.") {
                                dispatch({
                                    type: AUTH_LOGOUT,
                                });
                            } else {
                                dispatch({
                                    type: `${typePrefix}_DETAIL_ERROR`,
                                    error: e,
                                });
                            }
                        });
                };
            },
            [`${functionPrefix}LoadDetailChild2`]: (id, callback, multiple, map) => {
                var cls = this;
                return function (dispatch, getState) {
                    const state = getState(),
                        { auth } = state;
                    dispatch({
                        type: `${typePrefix}_PULLING`,
                    });
                    let options = {};
                    if (isRealUser(auth)) {
                        options.headers = {
                            Authorization: "Token " + state.auth.user.auth_token,
                        };
                    }
                    var atrrs = `&type=${cls.modelName}&filter=${id}`;
                    if (multiple) {
                        if (map === "true") {
                            atrrs = `&type=${multiple}&filter=${id}&map=${map}`;
                        } else {
                            // Loading all recordsections for a parent
                            atrrs = `&type=${multiple}&filter=${id}`;
                        }
                    }
                    fetch(`${cls.source}?format=json&t=${Date.now()}${atrrs}`, options)
                        .then((result) => result.json())
                        .then((data) => {
                            if (data.list) {
                                data = data.list;
                            }
                            if (!(data instanceof Array)) {
                                throw new Error(data.detail || data);
                            }
                            return data;
                        })
                        .then((data) => {
                            dispatch({
                                type: `${typePrefix}_PULLED_DELETEDYNAMIC`,
                                payload: data,
                            });
                            if (callback) callback(data);
                        })
                        .catch((e) => {
                            if (e.message === "Invalid token.") {
                                dispatch({
                                    type: AUTH_LOGOUT,
                                });
                            } else {
                                dispatch({
                                    type: `${typePrefix}_DETAIL_ERROR`,
                                    error: e,
                                });
                            }
                        });
                };
            },
            [`${functionPrefix}LoadMirrorRepeatable`]: (section, parentrs, groupids, model, callback) => {
                var cls = this;
                return function (dispatch, getState) {
                    const state = getState(),
                        { auth } = state;
                    dispatch({
                        type: `${typePrefix}_DETAIL_LOADING`,
                    });
                    let options = {};
                    if (isRealUser(auth)) {
                        options.headers = {
                            Authorization: "Token " + state.auth.user.auth_token,
                        };
                    }
                    var atrrs = `&type=${cls.modelName}&section=${section}&pr=${parentrs}&groupids=${groupids}&model=${model}`;

                    fetch(`${cls.source}?format=json&t=${Date.now()}${atrrs}`, options)
                        .then((result) => result.json())
                        .then((data) => {
                            if (data.list) {
                                data = data.list;
                            }
                            if (!(data instanceof Array)) {
                                throw new Error(data.detail || data);
                            }
                            return data;
                        })
                        .then((data) => {
                            dispatch({
                                type: `${typePrefix}_DETAIL_LOADED`,
                                payload: data,
                            }); //syncronous callback to nested function in expansionpanelform/loadrepeatabletables function, to update recordsectionlocally
                            if (callback) callback(data);
                        })
                        .catch((e) => {
                            if (e.message === "Invalid token.") {
                                dispatch({
                                    type: AUTH_LOGOUT,
                                });
                            } else {
                                dispatch({
                                    type: `${typePrefix}_DETAIL_ERROR`,
                                    error: e,
                                });
                            }
                        });
                };
            },
            // orm method layout is orm[page,user, etc]Update. For example: ormPageUpdate(payload)
            [`${functionPrefix}Update`]: (payload) =>
                this.createAction({
                    type: `${typePrefix}_UPDATE`,
                    payload: payload,
                    effectIfLoggedIn: {
                        url: `${this.source}/${payload.id}?t=${Date.now()}`,
                        method: "PATCH",
                    },
                }), //update just the frontend UI store, used to update other recordsections based on one rs changing, etc.
            [`${functionPrefix}UpdateLocalOnly`]: (payload) => ({
                type: `${typePrefix}_UPDATE`,
                payload: payload,
            }),
            [`${functionPrefix}MapUpdate`]: (payload, callback) => {
                //only for the mapview recordfields to allow for a callback before routing to form
                var cls = this;
                return function (dispatch, getState) {
                    const state = getState(),
                        { auth } = state;

                    if (!isRealUser(auth) && !cls.allowPublicAccess) {
                        dispatch({
                            type: `${typePrefix}_UPDATE_PUSHERROR`,
                            error: new Error("Not logged in."),
                        });
                        return;
                    }

                    var headers = {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    };
                    if (isRealUser(auth)) headers["Authorization"] = "Token " + auth.user.auth_token;
                    return fetch(`${cls.source}/${payload.id}?t=${Date.now()}`, {
                        method: "PATCH",
                        body: JSON.stringify(cls.toRequest(payload)),
                        headers: headers,
                    })
                        .then((data) => {
                            if (data.status !== 200) {
                                callback(data); // error;
                            }
                            data = cls.fromResponse(data);
                            let obj = {};
                            obj["id"] = payload;
                            //pass payload id to reducer to have ormselectors to update after the deleting of id
                            dispatch({
                                type: `${typePrefix}_UPDATE`,
                                payload: obj,
                            });
                            if (callback) callback(data);
                        })
                        .catch((e) => {
                            if (e.message === "Invalid token.") {
                                dispatch({
                                    type: AUTH_LOGOUT,
                                });
                            } else {
                                dispatch({
                                    type: `${typePrefix}_UPDATE_PUSHERROR`,
                                    error: e,
                                })
                            }
                        });
                };
            },
            [`${functionPrefix}AppUpdate`]: (payload, callback) => {
                //only for the apps to have a callback for appstatus to be updated after
                var cls = this;
                return function (dispatch, getState) {
                    const state = getState(),
                        { auth } = state;

                    if (!isRealUser(auth) && !cls.allowPublicAccess) {
                        dispatch({
                            type: `${typePrefix}_UPDATE_PUSHERROR`,
                            error: new Error("Not logged in."),
                        });
                        return;
                    }

                    var headers = {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    };
                    if (isRealUser(auth)) headers["Authorization"] = "Token " + auth.user.auth_token;
                    return fetch(`${cls.source}/${payload.id}?t=${Date.now()}`, {
                        method: "PATCH",
                        body: JSON.stringify(cls.toRequest(payload)),
                        headers: headers,
                    })
                        .then((data) => {
                            if (data.status !== 200) {
                                callback(data); // error;
                            }
                            data = cls.fromResponse(data);
                            let obj = {};
                            obj["id"] = payload;
                            //pass payload id to reducer to have ormselectors to update after the deleting of id
                            dispatch({
                                type: `${typePrefix}_UPDATE`,
                                payload: obj,
                            });
                            if (callback) callback(data);
                        })
                        .catch((e) =>
                            dispatch({
                                type: `${typePrefix}_UPDATE_PUSHERROR`,
                                error: e,
                            })
                        );
                };
            },
            [`${functionPrefix}ThenUpdate`]: (payload, callback) => {
                //for places in code to have a callback (ex. repeatablefields)
                var cls = this;
                return function (dispatch, getState) {
                    const state = getState(),
                        { auth } = state;

                    if (!isRealUser(auth) && !cls.allowPublicAccess) {
                        dispatch({
                            type: `${typePrefix}_UPDATE_PUSHERROR`,
                            error: new Error("Not logged in."),
                        });
                        return;
                    }

                    var headers = {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    };
                    if (isRealUser(auth)) headers["Authorization"] = "Token " + auth.user.auth_token;
                    return fetch(`${cls.source}/${payload.id}?t=${Date.now()}`, {
                        method: "PATCH",
                        body: JSON.stringify(cls.toRequest(payload)),
                        headers: headers,
                    })
                        .then((data) => {
                            if (data.status !== 200) {
                                callback(data); // error;
                            }
                            data = cls.fromResponse(data);
                            // let obj = {};
                            // obj["id"] = payload;
                            //pass payload id to reducer to have ormselectors to update after the deleting of id
                            dispatch({
                                type: `${typePrefix}_UPDATE`,
                                payload: payload,
                            });
                            if (callback) callback(data);
                          
                        })
                        .catch((e) =>
                            dispatch({
                                type: `${typePrefix}_UPDATE_PUSHERROR`,
                                error: e,
                            })
                        );
                };
            },
            //OLD WAY TO DELETE--ISSUE IS WITH LARGER DATASETS DONT HAVE A .THEN CALLBACK
            // orm method layout is orm[page,user, etc]Delete. For example: ormPageDelete(objId), dont get a .then callback
            // [`${functionPrefix}Delete`]: (objId) =>
            //     this.createAction({
            //         type: `${typePrefix}_DELETE`,
            //         payload: { id: objId },
            //         // payload: objId,
            //         effectIfLoggedIn: {
            //             url: `${this.source}/${objId}?&t=${Date.now()}`,
            //             method: "DELETE",
            //         },
            //     }),
            // ...baseActions,

            //This below delete is the same as CREATE (above) however having the .then in the fetch vs. the above delete allows us to get the .then callback
            //on an ormRecordSectionDelete. Ex. ormRecordSectionDelete({id:xx}).then()
            [`${functionPrefix}Delete`]: (payload) => {
                var cls = this;
                return function (dispatch, getState) {
                    const state = getState(),
                        { auth } = state;
                    if (!isRealUser(auth) && !cls.allowPublicAccess) {
                        dispatch({
                            type: `${typePrefix}_DELETE_PUSHERROR`,
                            error: new Error("Not logged in."),
                        });
                        return;
                    }

                    var headers = {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    };
                    if (isRealUser(auth)) headers["Authorization"] = "Token " + auth.user.auth_token;
                    return fetch(`${cls.source}/${payload}?t=${Date.now()}`, {
                        method: "DELETE",
                        body: JSON.stringify(cls.toRequest(payload)),
                        headers: headers,
                    })
                        .then((data) => {
                            //no .then return
                            if (!data.ok) {
                                return data.text().then((text) => {
                                    throw new Error(text);
                                });
                            }
                            data = cls.fromResponse(data);
                            let obj = {};
                            obj["id"] = payload;
                            //pass payload id to reducer to have ormselectors to update after the deleting of id
                            dispatch({
                                type: `${typePrefix}_DELETE`,
                                payload: obj,
                            });
                        })
                        .catch((e) => {
                            if (e.message === "Invalid token.") {
                                dispatch({
                                    type: AUTH_LOGOUT,
                                });
                            } else {
                                dispatch({
                                    type: `${typePrefix}_DELETE_PUSHERROR`,
                                    error: e,
                                })
                            }
                        });
                };
            }, //only for redux store to load on front end
            ...baseActions,
        };
    }

    //first arg action, second arg session specific model.
    static reducer(action, cls) {
        const prefix = `ORM_${cls.modelName.toUpperCase()}`,
            errorPattern = new RegExp(`^${prefix}_([^_]+)_PUSHERROR$`),
            { payload, meta } = action,
            objId = (payload && payload.id) || (meta && meta.objectId);
        //check for action types of model
        switch (action.type) {
            case `${prefix}_CREATE`:
                cls.create(payload || {});
                break;
            case `${prefix}_UPDATE`:
            case `${prefix}_CREATE_PUSHED`:
            case `${prefix}_UPDATE_PUSHED`:
                if (!cls.idExists(objId)) {
                    break;
                }
                cls.withId(objId).update(action.payload);
                break;
            case `${prefix}_DETAIL_LOADED`:
                action.payload.forEach((obj) => cls.upsert(cls.fromResponse(obj)));
                break;
            case `${prefix}_CREATE_PUSHERROR`:
            case `${prefix}_UPDATE_PUSHERROR`:
            case `${prefix}_DELETE_PUSHERROR`:
            case `${prefix}_DETAIL_ERROR`:
                if (!cls.idExists(objId)) {
                    break;
                }
                cls.withId(objId).update({
                    serverError: payload.response || payload.status,
                });
                break;
            case `${prefix}_DELETE`:
                if (!cls.withId(objId)) {
                    //uses model id
                    break;
                }
                cls.withId(objId).delete();
                break;
            default:
                if (action.type.match(errorPattern)) {
                    console.warn(action);
                } else {
                    super.reducer(action, cls);
                }
        }
    }

    _onDelete() {
        const virtualFields = this.getClass().virtualFields;
        for (const key in virtualFields) {
            // eslint-disable-line
            if (this[key] !== null) {
                const relatedQs = this[key];
                if (relatedQs.exists()) {
                    relatedQs.delete();
                }
            }
        }
    }
}

// reloadAll is called in a variety of places - most noticeably if a user first hits the main pages this is called, and then called again when they log in.
// This creates a backload of requests which can take 10-20 or more seconds to clear out.
// To help alleviate this only call every model once during the initial page load in index.js.
// But everywhere else we don't need to reload the ReadOnlyModels (!loadByDefault) since they never change.
// This should cut down the number of requests to the server.
export function reloadAll() {
    return function (dispatch) {
        orm.registry.forEach((model) => {
            if (model.loadByDefault) {
                const fn = model.actions[`orm${model.modelName}Reload`];
                dispatch(fn());
            }
        });
    };
}

// This function determines if redux-orm is still loading or saving items back to the database--loading bar on side
//gets called in combineReducers in index.js which is a specific part of the store state tree.
export function syncReducer(state = {}, action) {
    let { type } = action;
    if (action.meta && action.meta.offline) {
        type = `${type}_PUSHING`;
    }
    const pushPattern = /^ORM_([^_]+)_([^_]+)_(PUSH[^_]+)$/;
    const pullPattern = /^ORM_([^_]+)_((PULL[^_]+))$/;
    const pullPattern2 = /^ORM_([^_]+)_((PULLED_D[^_]+))$/;
    const detailPattern = /^ORM_([^_]+)_(DETAIL)_([^_]+)$/;
    // const deletePattern = /^ORM_([^_]+)_(DELETE)_([^_]+)$/;
    var match = type.match(pushPattern) || type.match(pullPattern) || type.match(detailPattern) || type.match(pullPattern2); //|| type.match(deletePattern);
    if (!match) {
        return state;
    }

    let pending = {},
        error = {};
    let [, modelName, actionName, statusName] = match;
    let total = 0;

    //goes thru each model to determine what has updated
    orm.registry.forEach((model) => {
        if (!model.loadByDefault /* && model.modelName !== "RecordSection"*/) {
            return;
        }
        total += 1;
        if (model.modelName.toUpperCase() === modelName) {
            modelName = model.modelName;
        } else if (state.error && state.error[model.modelName]) {
            error[model.modelName] = state.error[model.modelName];
        } else if (state.pending && state.pending[model.modelName]) {
            pending[model.modelName] = state.pending[model.modelName];
        }
    });

    switch (statusName) {
        case "PUSHING":
        case "PULLING":
        case "LOADING":
            pending[modelName] = actionName;
            break;
        case "DELETE":
        case "PUSHED":
        case "PULLED":
        case "PULLED_DELETE":
        case "PULLED_DELETEDYNAMIC":
        case "LOADED":
            break;
        case "PUSHERROR":
        case "PULLERROR":
        case "ERROR":
            error[modelName] = actionName;
            break;
        default:
            break;
    }
    const pendingCount = Object.keys(pending).length;
    let progress, ready;
    if (pendingCount > 0) {
        progress = total - pendingCount;
        ready = false;
    } else {
        progress = total;
        ready = true;
    }
    return {
        ready,
        progress,
        total,
        pending,
        error,
    };
}
