import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core";

import ViewIcon from "@material-ui/icons/Visibility";
import Close from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";

import AppContainerApplication from "../../common/AppContainerApplication";
import TextField from "../../common/TextField";
import Select from "../../common/Select";
import FileInput from "../../common/FileInput";
import { createSelector } from "../../common/orm";
import states from "../../common/statesmn.json";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import { ApplicationRecord, ApplicationRecordStatus, CrwdStewardship, CrwdStewardshipAttachment } from "../../page/models";

const styles = theme => ({
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8,
        marginTop: 2,
        marginBottom: 2
    },
    centerAlign: {
        textAlign: "center"
    },
    centerAlign2: {
        textAlign: "center",
        display: "inline-block",
        width: "100%"
    },
    nowrap: {
        whiteSpace: "nowrap"
    },
    rightAlign: {
        textAlign: "right"
    },
    flex: {
        flex: 1
    },
    printDivider: {
        height: 8,
        backgroundColor: "rgba(0, 0, 0, 0.42)",
        marginTop: 16,
        marginBottom: 8
    },
    dialogMin: {
        minWidth: 400
    },
    minWidth: {
        minWidth: 600
    },
    gridPadding: {
        padding: "1px 8px !important"
    }
});

function MAKE_OPTIONS(table) {
    return table.map(row => ({
        label: row,
        value: row
    }));
}

const getApplication = createSelector(
    (state, ownProps) => ownProps.match.params["hash"],
    (session, hash) => {
        return session.CrwdStewardship.filter(p => p.hash === hash).toModelArray()[0];
    }
);

class CrwdStewardshipComp extends Component {
    state = {
        activeStep: 0,
        attachmentDialogOpen: false,
        attachmentEditing: null,
        attachmentFormKey: null,
        attachmentOrder: "desc",
        attachmentOrderBy: null,
        temp_attachments: [],
        loading: false,
        new_save: false,
        new_save2: false,
        mainFormKey: null
    };

    componentDidMount() {
        const { match, ormCrwdStewardshipLoadDetailChild, ormCrwdStewardshipAttachmentLoadDetailChild } = this.props;
        var component = this;

        if (match.params && match.params.hash) {
            ormCrwdStewardshipLoadDetailChild(match.params.hash, function() {
                component.setState({ mainFormKey: window.performance.now() });
            });
            ormCrwdStewardshipAttachmentLoadDetailChild(match.params.hash, function(data) {
                component.setState({ temp_attachments: data });
            });
        }
        if (match.params && match.params.print) {
            component.setState({ activeStep: "PRINT" });
        }
    }

    errorValidator = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        const validateEmail = email => {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !re.test(email) ? "Invalid Email Address" : null;
        };
        var valObj = {
            participant_name: isRequired(values.participant_name),
            participant_email: validateEmail(values.participant_email)
        };

        var alerts = "";
        var ai = 5;
        if (valObj["participant_name"] === "Required") {
            alerts += "Participant Name (Step 1)\n";
            ai = 0;
        }
        if (valObj["participant_email"] === "Invalid Email Address") {
            alerts += "Participant Email (Step 1)\n";
            ai = 0;
        }
        if (alerts) {
            alert("The following fields are missing or invalid:\n" + alerts);
            this.setState({ activeStep: ai });
        }

        return valObj;
    };

    errorValidatorAttachment = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            file: isRequired(values.file),
            name: isRequired(values.name)
        };

        return valObj;
    };

    submitApplication = values => {
        const {
            application,
            ormApplicationRecordCreate,
            ormApplicationRecordStatusCreate,
            ormCrwdStewardshipCreate,
            ormCrwdStewardshipAttachmentCreate,
            ormCrwdStewardshipUpdatePublic
        } = this.props;
        const { temp_attachments } = this.state;

        const _this = this;
        this.setState({ loading: true });

        // Editing
        if (application) {
            ormCrwdStewardshipUpdatePublic({
                id: application.id,
                ...values
            });

            var i = 0;
            var j = 0;
            temp_attachments.forEach(function(tc) {
                if (tc.id) {
                } else {
                    i++;
                    ormCrwdStewardshipAttachmentCreate({
                        crwdstewardship_id: application.id,
                        ...tc
                    }).then(_z => {
                        j++;
                        if (i === j) _this.setState({ loading: false, new_save2: true });
                    });
                }
            });
            if (i === 0) {
                // No attachments or contacts - its finished loading
                _this.setState({ loading: false, new_save2: true });
            }
        } else {
            // New
            ormApplicationRecordCreate({
                application: 3
            }).then(ar => {
                ormApplicationRecordStatusCreate({
                    applicationrecord: ar.id,
                    status: "Initial Inquiry"
                });
                ormCrwdStewardshipCreate({
                    applicationrecord: ar.id,
                    ...values
                }).then(rpb => {
                    var i = 0;
                    var j = 0;
                    temp_attachments.forEach(function(tc) {
                        i++;
                        ormCrwdStewardshipAttachmentCreate({
                            crwdstewardship_id: rpb.id,
                            ...tc
                        }).then(_z => {
                            j++;
                            if (i === j) _this.setState({ loading: false, new_save: true });
                        });
                    });
                    if (i === 0) {
                        // No attachments or contacts - its finished loading
                        _this.setState({ loading: false, new_save: true });
                    }
                });
            });
        }
    };

    addAttachment = values => {
        var { attachmentEditing, temp_attachments } = this.state;

        if (!attachmentEditing) {
            temp_attachments.push(values);
            this.setState({ temp_attachments: temp_attachments });
        } else {
            var temp = temp_attachments.filter(tc => tc !== attachmentEditing);
            temp.push(values);
            this.setState({ temp_attachments: temp });
        }

        this.setState({ attachmentDialogOpen: false });
    };

    handleRequestSort2 = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.state.temp_attachments.sort((a, b) => (b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1))
            : this.state.temp_attachments.sort((a, b) => (a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1));

        this.setState({ order, orderBy });
    };

    handleNext = index => {
        const { activeStep } = this.state;
        if (index || index === 0) {
            this.setState({ activeStep: index });
        } else if (activeStep < 3) this.setState({ activeStep: activeStep + 1 });
        else {
            this.formApi.submitForm();
        }
    };

    render() {
        const { classes, application } = this.props;
        const {
            activeStep,
            attachmentDialogOpen,
            attachmentEditing,
            attachmentFormKey,
            attachmentOrder,
            attachmentOrderBy,
            temp_attachments,
            loading,
            new_save,
            mainFormKey,
            new_save2
        } = this.state;

        const is_read_only = false;

        var columnDataAttachments = [
            { id: "actions", numeric: false, label: "", allowSort: false, width: "20px" },
            { id: "name", numeric: false, label: "Name", allowSort: true },
            { id: "_type", numeric: false, label: "Type", allowSort: true },
            { id: "notes", numeric: false, label: "Notes", allowSort: true }
        ];

        if (new_save) {
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "10" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} lg={10} xl={8}>
                            <Typography>
                                Thank you! Your Stewardship Grant Inquiry has been successfully submitted. Staff at Capitol Region Watershed District
                                or Ramsey County SWCD will contact you shortly to schedule a site visit. A copy of your grant inquiry has been sent to
                                the e-mail address you provided. Please contact CRWD at 651-644-8888 if you do not receive this email or if you have
                                any questions.
                            </Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }

        if (new_save2) {
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "10" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} lg={10} xl={8}>
                            <Typography>This is a confirmation that the stewardship application has been resubmitted successfully.</Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }

        return (
            <AppContainerApplication
                city_id={window.location.origin.includes("ms4prod") ? "10" : "2"}
                page_loading={loading}
                printing={activeStep === "PRINT"}>
                <Form
                    getApi={el => (this.formApi = el)}
                    defaultValues={application}
                    key={mainFormKey}
                    dontValidateOnMount={true}
                    validateOnSubmit={true}
                    validateError={values => this.errorValidator(values)}
                    onSubmit={values => this.submitApplication(values)}>
                    {formApi => (
                        <form onSubmit={formApi.submitForm}>
                            <Grid container spacing={1} style={{ padding: "0 12px" }}>
                                <Grid item xs={12} lg={10} xl={8}>
                                    <Typography className={classes.centerAlign} variant="h6">
                                        Stewardship Grant Application Form
                                    </Typography>
                                    <Typography className={classes.centerAlign}>
                                        <b>IMPORTANT:</b> The entire stewardship grant application must be filled out at one time and progress will
                                        not be saved so please plan accordingly.
                                    </Typography>
                                    <Typography variant="caption" className={classes.centerAlign2}>
                                        Required Fields are marked with an asterisk (*)
                                    </Typography>
                                </Grid>
                                {activeStep !== "PRINT" && (
                                    <Grid item xs={12} lg={10} xl={8}>
                                        <Stepper style={{ padding: 8 }} alternativeLabel nonLinear activeStep={activeStep}>
                                            {[
                                                "Project Participant Information",
                                                "Project Location (if different from participant)",
                                                "Project Details",
                                                "Attach Documents"
                                            ].map((label, index) => {
                                                return (
                                                    <Step key={label}>
                                                        <StepButton style={{ cursor: "pointer" }} onClick={() => this.handleNext(index)}>
                                                            {label}
                                                        </StepButton>
                                                    </Step>
                                                );
                                            })}
                                        </Stepper>
                                    </Grid>
                                )}
                                {(activeStep === 0 || activeStep === "PRINT") && (
                                    <Grid item xs={12} lg={10} xl={8}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            Project Participant Information
                                        </Typography>
                                        <Grid container spacing={2} style={{ marginTop: 0 }}>
                                            <Grid item xs={6} className={classes.gridPadding}>
                                                <TextField disabled={is_read_only} field="participant_name" label="Participant Name *" fullWidth />
                                            </Grid>
                                            <Grid item xs={6} className={classes.gridPadding}>
                                                <TextField disabled={is_read_only} field="participant_street" label="Street" fullWidth />
                                            </Grid>
                                            <Grid item xs={6} className={classes.gridPadding}>
                                                <TextField disabled={is_read_only} field="participant_city" label="City" fullWidth />
                                            </Grid>
                                            <Grid item xs={6} className={classes.gridPadding}>
                                                <Select disabled={is_read_only} field="participant_state" label="State" options={states} fullWidth />
                                            </Grid>
                                            <Grid item xs={6} className={classes.gridPadding}>
                                                <TextField disabled={is_read_only} field="participant_zip" label="Zip" fullWidth />
                                            </Grid>
                                            <Grid item xs={6} className={classes.gridPadding}>
                                                <TextField disabled={is_read_only} field="participant_email" label="Email *" fullWidth />
                                            </Grid>
                                            <Grid item xs={6} className={classes.gridPadding}>
                                                <TextField disabled={is_read_only} field="participant_phone" label="Phone" fullWidth />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                {activeStep === "PRINT" && (
                                    <Grid item xs={12} lg={10} xl={8}>
                                        <Divider className={classes.printDivider} />
                                    </Grid>
                                )}
                                {(activeStep === 1 || activeStep === "PRINT") && (
                                    <Grid item xs={12} lg={10} xl={8}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            Project Location (if different from participant)
                                        </Typography>
                                        <Grid container spacing={2} style={{ marginTop: 0 }}>
                                            <Grid item xs={6} className={classes.gridPadding}>
                                                <TextField disabled={is_read_only} field="street" label="Street" fullWidth />
                                            </Grid>
                                            <Grid item xs={6} className={classes.gridPadding}>
                                                <TextField disabled={is_read_only} field="city" label="City" fullWidth />
                                            </Grid>
                                            <Grid item xs={6} className={classes.gridPadding}>
                                                <Select disabled={is_read_only} field="state" label="State" options={states} fullWidth />
                                            </Grid>
                                            <Grid item xs={6} className={classes.gridPadding}>
                                                <TextField disabled={is_read_only} field="zip" label="Zip" fullWidth />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                {activeStep === "PRINT" && (
                                    <Grid item xs={12} lg={10} xl={8}>
                                        <Divider className={classes.printDivider} />
                                    </Grid>
                                )}
                                {(activeStep === 2 || activeStep === "PRINT") && (
                                    <Grid item xs={12} lg={10} xl={8}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            Project Details
                                        </Typography>
                                        <Grid container spacing={2} style={{ marginTop: 0 }}>
                                            <Grid item xs={12} className={classes.gridPadding}>
                                                <TextField
                                                    multiline
                                                    rows={8}
                                                    label="1. Briefly describe why you are requesting a site visit and what you are hoping to accomplish through the CRWD Stewardship Grant Program (e.g. improve local water quality, address property flooding concerns, add green space, etc.)"
                                                    field="q_1"
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.gridPadding}>
                                                <TextField
                                                    multiline
                                                    rows={8}
                                                    label="2. Have you determined a location on your property to best manage stormwater runoff? If so, where?"
                                                    field="q_2"
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.gridPadding}>
                                                <TextField
                                                    multiline
                                                    rows={8}
                                                    label="3. Does your home and/or garage have gutters and downspouts?"
                                                    field="q_3"
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.gridPadding}>
                                                <TextField
                                                    multiline
                                                    rows={8}
                                                    label="4. If known, what are your property’s soil conditions (i.e. sandy, clayey, well-draining, poorly draining)?"
                                                    field="q_4"
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.gridPadding}>
                                                <TextField
                                                    multiline
                                                    rows={8}
                                                    label="5. Are you planning to complete the work yourself, hire a contractor, or a combination of the two?"
                                                    field="q_5"
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                {activeStep === 3 && (
                                    <Grid item xs={12} lg={10} xl={8}>
                                        <Typography variant="subtitle1">Attachments</Typography>
                                        <Typography gutterBottom>Please attach photos of potential project locations.</Typography>
                                        <Button
                                            onClick={() =>
                                                this.setState({
                                                    attachmentDialogOpen: true,
                                                    attachmentEditing: null,
                                                    attachmentFormKey: window.performance.now()
                                                })
                                            }
                                            variant="contained"
                                            color="primary">
                                            Add Attachment
                                        </Button>
                                        <Table style={{ marginTop: 8 }}>
                                            <EnhancedTableHead
                                                columnData={columnDataAttachments}
                                                order={attachmentOrder}
                                                orderBy={attachmentOrderBy}
                                                onRequestSort={this.handleRequestSort2}
                                            />
                                            <TableBody>
                                                {temp_attachments.map(n => {
                                                    return (
                                                        <TableRow hover>
                                                            <CustomTableCell className={classes.nowrap}>
                                                                {n.file.includes("https") && (
                                                                    <Tooltip title="Open Link">
                                                                        <Button
                                                                            color="primary"
                                                                            onClick={() => {
                                                                                window.open(n.file);
                                                                            }}
                                                                            className={classes.deleteWidth}>
                                                                            <ViewIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                )}
                                                                <Tooltip title="Delete">
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() =>
                                                                            this.setState({
                                                                                temp_attachments: temp_attachments.filter(tc => tc !== n)
                                                                            })
                                                                        }
                                                                        className={classes.deleteWidth}>
                                                                        <DeleteIcon color="primary" />
                                                                    </Button>
                                                                </Tooltip>
                                                            </CustomTableCell>
                                                            <CustomTableCell>{n.name}</CustomTableCell>
                                                            <CustomTableCell>{n._type}</CustomTableCell>
                                                            <CustomTableCell>{n.notes}</CustomTableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                                {temp_attachments.length < 1 && (
                                                    <TableRow>
                                                        <CustomTableCell colSpan={columnDataAttachments.length} className={classes.centerAlign}>
                                                            No Attachments Added
                                                        </CustomTableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                )}
                                {activeStep !== "PRINT" && (
                                    <Grid item xs={12} lg={10} xl={8} className={classes.rightAlign}>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={() => this.setState({ activeStep: activeStep - 1 })}
                                            className={classes.button}>
                                            Back
                                        </Button>
                                        <Button onClick={() => this.handleNext()} variant="contained" color="primary" style={{ marginLeft: 16 }}>
                                            {activeStep === 3 ? "Submit Stewardship Grant Application" : "Next"}
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </form>
                    )}
                </Form>
                <Dialog open={attachmentDialogOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.flex}>
                            Attachment
                        </Typography>
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({ attachmentDialogOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <DialogContentText>
                            <Form
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                key={attachmentFormKey}
                                defaultValues={attachmentEditing}
                                validateError={this.errorValidatorAttachment}
                                onSubmit={values => this.addAttachment(values)}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <FileInput field="file" id="file_upl" fullWidth label="File *" />
                                                <TextField field="name" label="Name *" fullWidth style={{ marginTop: 24 }} />
                                                <Select field="_type" label="Type" options={MAKE_OPTIONS(["Photo", "Other"])} fullWidth />
                                                <TextField field="notes" label="Notes" fullWidth style={{ marginTop: 20 }} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth onClick={() => this.setState({ attachmentDialogOpen: false })}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </AppContainerApplication>
        );
    }
}

CrwdStewardshipComp = connect(
    (state, ownProps) => ({
        application: getApplication(state, ownProps),
        authState: state.auth // Admins will have an authstate
    }),
    {
        ...ApplicationRecord.actions,
        ...ApplicationRecordStatus.actions,
        ...CrwdStewardship.actions,
        ...CrwdStewardshipAttachment.actions
    }
)(CrwdStewardshipComp);

export default withStyles(styles)(withRouter(CrwdStewardshipComp));
