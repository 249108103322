import React, { Component } from "react";
import { Form } from "react-form";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Close from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Linkk from "@material-ui/core/Link";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "../common/TextField";
import DatePicker from "../common/DatePicker";
import TimePicker from "../common/TimePicker";
import ReactSelect from "../common/ReactSelect";
import RadioGroup from "../common/RadioGroup";
import Switch from "../common/Switch";
import HelpLabel from "../common/HelpLabel";

const styles = (theme) => ({
    minWidth: {
        minWidth: 600,
    },
    flex: {
        flex: 1,
    },
    progress: {
        display: "table",
        marginLeft: "auto",
        marginRight: "auto",
    },
});

class ContactDialog extends Component {
    state = {
        loading: false,
    };
    defaultValues = () => {
        const { activeContact } = this.props;

        var defaultValues = {};
        if (activeContact && activeContact.columns) {
            activeContact.columns.forEach(function (rf) {
                if (rf.field_type === "Yes/No") {
                    defaultValues["f_" + rf.id] = rf.userinput === "true" ? true : false;
                } else {
                    defaultValues["f_" + rf.id] = rf.userinput;
                }
            });
        }
        return defaultValues;
    };

    updateMasterContact = (values) => {
        const { ormContactRecordFieldUpdate, closeDialog } = this.props;
        this.setState({ loading: true });
        const newValues = Object.entries(values);

        //used to make sure table updates with proper changes else will update table right away with old values
        var waitTime = 0;
        newValues.forEach(function (f) {
            //save yes/no as strings else db string field wont accept boolean
            if (f[1] === true || f[1] === false) {
                ormContactRecordFieldUpdate({
                    id: f[0].replace("f_", ""),
                    userinput: f[1] === true ? "true" : "false",
                });
            } else {
                ormContactRecordFieldUpdate({
                    id: f[0].replace("f_", ""),
                    userinput: f[1],
                });
            }
            //avg time per request
            waitTime += 550;
        });
        const comp = this;

        setTimeout(function () {
            comp.setState({ loading: false });
            closeDialog();
        }, waitTime);
    };

    render() {
        const { dialogOpen, classes, activeContact, closeDialog, authState } = this.props;
        const { loading } = this.state;

        function MAKE_OPTIONS(table) {
            return table.map((row) => ({
                label: row,
                value: row,
            }));
        }

        return (
            <Dialog open={dialogOpen} classes={{ paper: classes.minWidth }}>
                <Toolbar>
                    <Typography variant="h5" className={classes.flex}>
                        Edit Master Contact
                    </Typography>
                    <IconButton onClick={closeDialog}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <DialogContentText>
                        <Form
                            key={activeContact && activeContact.id}
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            defaultValues={this.defaultValues()}
                            onSubmit={(values) => this.updateMasterContact(values)}>
                            {(formApi) => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        {activeContact &&
                                            activeContact.columns &&
                                            activeContact.columns.map((fi) => {
                                                return (
                                                    <Grid item xs={12} key={fi.id}>
                                                        {fi.field_type === "Text" && (
                                                            <TextField
                                                                field={"f_" + fi.id}
                                                                fullWidth
                                                                label={
                                                                    fi.help_description ? (
                                                                        <HelpLabel inputLabel={fi.field_name} helpText={fi.help_description} />
                                                                    ) : (
                                                                        fi.field_name
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                        {fi.field_type === "Yes/No" && (
                                                            <Switch
                                                                field={"f_" + fi.id}
                                                                name={fi.id}
                                                                label={
                                                                    fi.help_description ? (
                                                                        <HelpLabel inputLabel={fi.field_name} helpText={fi.help_description} />
                                                                    ) : (
                                                                        fi.field_name
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                        {(fi.field_type === "Drop Down" || fi.field_type === "Multi Select") && (
                                                            <ReactSelect
                                                                field={"f_" + fi.id}
                                                                options={MAKE_OPTIONS(fi.values_csv.split(","))}
                                                                label={
                                                                    fi.help_description ? (
                                                                        <HelpLabel inputLabel={fi.field_name} helpText={fi.help_description} />
                                                                    ) : (
                                                                        fi.field_name
                                                                    )
                                                                }
                                                                isMulti={fi.field_type === "Drop Down" ? false : true}
                                                                fullWidth
                                                            />
                                                        )}
                                                        {fi.field_type === "Phone Number" && (
                                                            <TextField
                                                                field={"f_" + fi.id}
                                                                label={
                                                                    fi.help_description ? (
                                                                        <HelpLabel inputLabel={fi.field_name} helpText={fi.help_description} />
                                                                    ) : (
                                                                        fi.field_name
                                                                    )
                                                                }
                                                                fullWidth
                                                                // margin="none"
                                                                usePhoneNumberFormat={true}
                                                            />
                                                        )}
                                                        {fi.field_type === "Email" && (
                                                            <TextField
                                                                type="email"
                                                                label={
                                                                    fi.help_description ? (
                                                                        <HelpLabel inputLabel={fi.field_name} helpText={fi.help_description} />
                                                                    ) : (
                                                                        fi.field_name
                                                                    )
                                                                }
                                                                field={"f_" + fi.id}
                                                                pattern=".+@globex.com"
                                                                fullWidth
                                                            />
                                                        )}
                                                        {fi.field_type === "Date" && (
                                                            <DatePicker
                                                                field={"f_" + fi.id}
                                                                label={
                                                                    fi.help_description ? (
                                                                        <HelpLabel inputLabel={fi.field_name} helpText={fi.help_description} />
                                                                    ) : (
                                                                        fi.field_name
                                                                    )
                                                                }
                                                                fullWidth
                                                            />
                                                        )}
                                                        {fi.field_type === "Time" && (        
                                                            <TimePicker
                                                                field={"f_" + fi.id}
                                                                autoPopulate={fi.autopopulate}
                                                                label={
                                                                    fi.help_description ? (
                                                                        <HelpLabel inputLabel={fi.field_name} helpText={fi.help_description} />
                                                                    ) : (
                                                                        fi.field_name
                                                                    )
                                                                }
                                                                fullWidth
                                                            />
                                                        )}
                                                        {fi.field_type === "Currency" && (             
                                                            <TextField
                                                                field={"f_" + fi.id}
                                                                label={
                                                                    fi.help_description ? (
                                                                        <HelpLabel inputLabel={fi.field_name} helpText={fi.help_description} />
                                                                    ) : (
                                                                        fi.field_name
                                                                    )
                                                                }
                                                                useTextFormat={true}
                                                                fullWidth
                                                                margin="none"
                                                            />
                                                        )}
                                                        {fi.field_type === "Numeric" && (             
                                                            <TextField
                                                                field={"f_" + fi.id}
                                                                label={
                                                                    fi.help_description ? (
                                                                        <HelpLabel inputLabel={fi.field_name} helpText={fi.help_description} />
                                                                    ) : (
                                                                        fi.field_name
                                                                    )
                                                                }
                                                                useNumberFormat
                                                                fullWidth
                                                                margin="none"
                                                            />
                                                        )}
                                                        {fi.field_type === "Radio" && (  
                                                            <RadioGroup
                                                                field={"f_" + fi.id}
                                                                label={
                                                                    fi.help_description ? (
                                                                        <HelpLabel inputLabel={fi.field_name} helpText={fi.help_description} />
                                                                    ) : (
                                                                        fi.field_name
                                                                    )
                                                                }
                                                                name={fi.id}
                                                                fullWidth
                                                                options={MAKE_OPTIONS(fi.values_csv.split(","))}
                                                                alignment={true}
                                                            />
                                                        )}
                                                    </Grid>
                                                );
                                            })}
                                        <Grid item xs={12}>
                                            {loading && <CircularProgress size={30} className={classes.progress} />}
                                            {!loading && authState && authState.user && authState.user.contacts_read_only === false && (
                                                <Button type="submit" variant="contained" color="primary" style={{ width: "100%" }}>
                                                    Save
                                                </Button>
                                            )}
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                        {activeContact && activeContact.parentrecords.length > 0 && (
                            <Typography variant='h6'>Records tied to this contact:</Typography>
                        )}
                        {activeContact &&
                            activeContact.parentrecords.map((p) => {
                                // var parent = parentRecords.find((pr) => pr.id === p);
                                return (
                                    <>
                                        <Linkk
                                            color="inherit"
                                            underline={"always"}
                                            target='_blank'
                                            component={Link}
                                            to={
                                                "/page/" +
                                                p.page +
                                                "/parentrecord/" +
                                                p.id +
                                                "/section/" +
                                                p.section +
                                                "/recordsection/" +
                                                p.parentrs +
                                                "/form"
                                            }>
                                            {p.parent_label ? `${p.parent_label} - Module: ${p.module}` : `Record, Module: ${p.module}`}
                                        </Linkk>
                                        <br />
                                    </>
                                );
                            })}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}
ContactDialog.displayName = "ContactDialog";

ContactDialog = connect((state) => ({}))(ContactDialog);

export default withStyles(styles)(withRouter(ContactDialog));
