/******************************************************************************
 * This is the expansion panel layout for the pages sections
 * Each panel is mapped onto the screen from List.js
 *
 * Author: Robert Gautier
 * Date: 4/8/19
 *******************************************************************************/

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form as Formm } from "react-form";

import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Clear";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";

import TextField from "../../common/TextField";
import Select from "../../common/Select";
import { createSelector } from "../../common/orm";
import { Filter } from "../models";

const styles = (theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(17),
        fontWeight: theme.typography.fontWeightRegular,
        paddingLeft: 15,
    },
    iconAdd: {
        backgroundColor: "#34aa20",
        color: "white",
        height: "25px",
        width: "25px",
        // paddingTop: 9,
        borderRadius: 12,
    },
    disable: {
        height: "33px",
        width: "33px",
        paddingTop: "4px",
        marginTop: 33,
        [theme.breakpoints.up("lg")]: {
            marginRight: 40,
        },
        [theme.breakpoints.down("md")]: {
            marginRight: 16,
        },
    },
    delete: {
        backgroundColor: "red",
        height: "33px",
        width: "33px",
        // paddingTop: "4px",
        marginTop: 16,
    },
    expandedMargin: {
        marginTop: "12px",
    },
    titlePanel: {
        minHeight: "auto !important",
    },
    hideIfSmall: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
});
const getSections = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["pageId"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (session, _pageId) => {
        return session.Section.filter((sect) => sect.page === _pageId)
            .orderBy("id")
            .toRefArray(); //result function uses session.ModelName.filter to match pageID with section.page (.page is page_id in section model)
    } //toRefArray returns a plain JavaScript object which are references to the DB
);
const getFilters = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["sectionId"]),
    (session, section) => {
        return session.Filter.filter((r) => r.section === section).toRefArray();
    } //toRefArray returns a plain JavaScript object which are references to the DB
);

const getForms = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["sectionId"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (session, actSection) => {
        return session.Form.filter((sect) => sect.section === actSection)
            .orderBy("id")
            .toModelArray()
            .map((form) => ({
                groups: form.groups //form.groups is FK linkage
                    .all()
                    .orderBy("id")
                    .toModelArray()
                    .map((group) => ({
                        fields: group.fields.all().orderBy("order").toRefArray(),
                        ...group.ref, // take all groups as a reference and tie in each field
                    })),
                ...form.ref, // take all forms as a reference and tie in each field
            }));
    } //toRefArray returns a plain JavaScript object which are references to the DB
);

class ExpansionPanelFilters extends Component {
    constructor(props) {
        super(props);
        const { forms } = this.props;
        var tempFields = [];

        //this is to get the 'columnNames' for the selected section so that the 'dialog dropdown' can be populated.
        // This is a little easier to read since it is all in one
        forms.forEach(function (form) {
            form.groups.forEach(function (group) {
                group.fields.forEach(function (field) {
                    if (field.is_column) {
                        if (tempFields.length === 0) {
                            tempFields.push("All");
                        }
                        tempFields.push(field.name);
                    }
                });
            });
        });

        // Remove the duplicates from tempFields
        this.state = {
            formCleanUp: [...new Set(tempFields)],
            shownFilters: [],
            expanded: false,
        };

        // I don't even...
        this.formKey = window.performance.now();
    }
    typingTimer;

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        const filters = params.get("filters");
        if (filters) {
            this.setState({ shownFilters: Array.from(filters.split(",")).map((item) => Number(item)) });
        }
    }
    componentDidUpdate(prevProps) {
        const { formCleanUp } = this.state;
        const { forms, actSection } = this.props;
        var tempFields = [];

        //this is to get the 'columnNames' for the selected section so that the 'dialog dropdown' can be updated if its different
        forms.forEach(function (form) {
            form.groups.forEach(function (group) {
                group.fields.forEach(function (field) {
                    if (field.is_column) {
                        if (tempFields.length === 0) {
                            tempFields.push("All");
                        }
                        tempFields.push(field.name);
                    }
                });
            });
        });
        var r = 0; //chk if filter fields are different and setstate if so
        tempFields.forEach((t) => (formCleanUp.includes(t) ? (r += 1) : null));
        if (r !== formCleanUp.length) this.setState({ formCleanUp: tempFields });

        if (actSection && actSection[0].id !== prevProps.actSection[0].id) {
            // Also reset component state
            this.setState({
                shownFilters: [],
            });

            // this.dummyState();
        }
    }

    // Delay upon saving text field
    // This removes the "jumping"
    saveQueryText = (value, id) => {
        const { ormFilterUpdate, ormFilterUpdateLocalOnly, setCookieFilter } = this.props;
        const { shownFilters } = this.state;
        const test = this;

        ormFilterUpdateLocalOnly({ id: id, query_text: value });
        this.formKey = window.performance.now();
        setTimeout(function () {
            test["query_text_" + id].focus();
        }, 1);

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function () {
            ormFilterUpdate({ id: id, query_text: value });
            setTimeout(function () {
                setCookieFilter(undefined, undefined, true, shownFilters.toString());
                // dynamicLoading(0, undefined, undefined, undefined, undefined, shownFilters.toString(), true);
            }, 1000);
        }, 1000);
    };

    defaultValues = () => {
        const { filters } = this.props;
        //setup default values for Filter. defaultValues is setup with an array of k/v pairs (i.e. 2816: NY, 2817: 55303 etc.)
        //this is setup to allow the "field" parameter of TextField (below) to place the ID values in the correct spot on the 'screen'
        var defaultValues = [];
        if (filters) {
            filters.forEach(function (filter) {
                defaultValues["field_" + filter.id] = filter.field;
                defaultValues["filter_type_" + filter.id] = filter.filter_type;
                defaultValues["query_text_" + filter.id] = filter.query_text;
            });
            defaultValues["existing"] = null;
        }
        return defaultValues;
    };

    MAKE_OPTIONS = (table) => {
        return table.map((row) => ({
            label: row,
            value: row,
        }));
    };

    MAKE_OPTIONS_ALL = (table) => {
        return table.map((row) => ({
            label: row.field + " " + row.filter_type + ": " + row.query_text,
            value: row.id,
        }));
    };

    showFilter = (value, mount = true) => {
        const { dummyState, setCookieFilter } = this.props;
        var { shownFilters } = this.state;

        //on loading/routing of page value can be an string with multiple filters
        if (typeof value === "string" && value.includes(",") !== -1) {
            value = value.split(",");
            value.forEach((v) => shownFilters.push(parseInt(v)));
        } else {
            //standard selecting a prior filter
            shownFilters.push(parseInt(value));
        }

        this.formKey = window.performance.now();
        //this.formApi.resetAll();
        this.setState({ shownFilters });
        dummyState();

        const params = new URLSearchParams();
        params.append("filters", shownFilters.toString());
        this.props.history.push({ search: params.toString() });

        //pass back to parent component to log cookies and then pull new values
        setCookieFilter(undefined, undefined, mount, shownFilters.toString());
        // dynamicLoading(0, undefined, undefined, undefined, undefined, shownFilters.toString(), true);
    };

    //this function is used to pass upto viewrecordtable the pixels to show for the table based on how many filters were selected
    maxHeightOfTable = (open) => {
        const { shownFilters } = this.state;

        let pix = [280, 380, 420, 500, 520, 540, 560];
        let childSameParentPix = [298, 400, 440, 520, 540, 560, 580];
        if (!this.props.match.path.includes("form")) {
            //parent table or all childsection table
            return this.props.pixels(open ? (shownFilters.length > 0 ? pix[shownFilters.length - 1] : 278) : 220);
        } else if (this.props.match.path.includes("form")) {
            //child section tied to one parent
            return this.props.pixels(open ? (shownFilters.length > 0 ? childSameParentPix[shownFilters.length - 1] : 298) : 220);
        }
    };

    render() {
        const { classes, forms, filters, ormFilterUpdate, ormFilterCreate, ormFilterDelete, dummyState, setCookieFilter, hasFilters } = this.props;
        const { formCleanUp, shownFilters, expanded } = this.state;

        const filterType = ["is equal to", "contains"];

        const selectFilters = filters.filter((n) => !shownFilters.includes(n.id));

        const params = new URLSearchParams(this.props.location.search);
        const fil = params.get("filters");
        if (hasFilters && fil && shownFilters && shownFilters.length === 0) {
            this.showFilter(fil, false);
            this.setState({ expanded: true });
            this.maxHeightOfTable();
        }

        return (
            <Accordion defaultExpanded={fil !== "" && fil !== null ? true : false} expanded={expanded} className={classes.hideIfSmall}>
                <AccordionSummary
                    onClick={(e) => {
                        //eslint-disable-next-line
                        return this.setState({ expanded: !expanded }), this.maxHeightOfTable(!expanded);
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    style={{ backgroundColor: "#ddd" }}
                    classes={{ root: classes.expandedMargin }}
                    className={classes.titlePanel}>
                    <Icon style={{ color: "#66686a" }}>filter_list</Icon>
                    <Typography className={classes.heading}>Filters</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ paddingBottom: 8 }}>
                    <Grid item xs={12}>
                        <Formm
                            key={this.formKey}
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            defaultValues={this.defaultValues()}
                            getApi={(el) => (this.formApi = el)}>
                            {(formApi) => (
                                <form onSubmit={formApi.submitForm}>
                                    {/* FILTERS COMPONENTS FOR Accordion */}
                                    {filters.map((filter) => {
                                        if (shownFilters.includes(filter.id)) {
                                            return (
                                                <Grid container spacing={1} alignItems="center">
                                                    <Grid item xs={6} md={3} xl={2}>
                                                        <Select
                                                            field={"field_" + filter.id}
                                                            options={this.MAKE_OPTIONS(formCleanUp)}
                                                            label="Field"
                                                            fullWidth
                                                            eventHandle={(value) => {
                                                                ormFilterUpdate({ id: filter.id, field: value });
                                                                this.formKey = window.performance.now();
                                                                setTimeout(function () {
                                                                    // dynamicLoading(
                                                                    //     0,
                                                                    //     undefined,
                                                                    //     undefined,
                                                                    //     undefined,
                                                                    //     undefined,
                                                                    //     shownFilters.toString(),
                                                                    //     true
                                                                    // );
                                                                    setCookieFilter(undefined, undefined, undefined, shownFilters.toString());
                                                                }, 1000);
                                                            }} //manually trigger a save
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} md={3} xl={2}>
                                                        <Select
                                                            field={"filter_type_" + filter.id}
                                                            options={this.MAKE_OPTIONS(filterType)}
                                                            label="Filter Type"
                                                            fullWidth
                                                            eventHandle={(value) => {
                                                                ormFilterUpdate({ id: filter.id, filter_type: value });
                                                                this.formKey = window.performance.now();
                                                                setTimeout(function () {
                                                                    // dynamicLoading(
                                                                    //     0,
                                                                    //     undefined,
                                                                    //     undefined,
                                                                    //     undefined,
                                                                    //     undefined,
                                                                    //     shownFilters.toString(),
                                                                    //     true
                                                                    // );
                                                                    setCookieFilter(undefined, undefined, undefined, shownFilters.toString());
                                                                }, 1000);
                                                            }} //manually trigger a save
                                                        />
                                                    </Grid>
                                                    <Grid item xs={9} md={4} lg={4} xl={3}>
                                                        <TextField
                                                            field={"query_text_" + filter.id}
                                                            label="Search Term"
                                                            fullWidth
                                                            autoFocus
                                                            inputRef={(input) => (this["query_text_" + filter.id] = input)}
                                                            eventHandle={(value) => this.saveQueryText(value, filter.id)} //manually trigger a save
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} md={1} style={{ whiteSpace: "nowrap" }}>
                                                        <Tooltip title="Turn off Filter">
                                                            <IconButton
                                                                className={classes.disable}
                                                                onClick={(e) => {
                                                                    var index = shownFilters.indexOf(filter.id);
                                                                    if (index > -1) {
                                                                        shownFilters.splice(index, 1);
                                                                        this.setState({ shownFilters });
                                                                        this.maxHeightOfTable(true);
                                                                        dummyState();
                                                                        const params = new URLSearchParams();
                                                                        params.append("filters", shownFilters.toString());
                                                                        this.props.history.push({ search: params.toString() });
                                                                        // dynamicLoading(
                                                                        //     0,
                                                                        //     undefined,
                                                                        //     undefined,
                                                                        //     undefined,
                                                                        //     undefined,
                                                                        //     shownFilters.toString(),
                                                                        //     true
                                                                        // );
                                                                        setCookieFilter(
                                                                            undefined,
                                                                            undefined,
                                                                            undefined,
                                                                            shownFilters.toString()
                                                                            // true
                                                                        );
                                                                    }
                                                                }}>
                                                                <VisibilityOff />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Permanently Delete Filter">
                                                            <IconButton
                                                                className={classes.delete}
                                                                onClick={(e) => {
                                                                    ormFilterDelete(filter.id);
                                                                    var sF = shownFilters.filter(function (item) {
                                                                        return item !== filter.id;
                                                                    });
                                                                    this.setState({ shownFilters: sF });
                                                                    dummyState();
                                                                    this.maxHeightOfTable(true);
                                                                    const params = new URLSearchParams();
                                                                    params.append("filters", sF.toString());
                                                                    this.props.history.push({
                                                                        search: params.toString() === "filters=" ? "" : params.toString(),
                                                                    });
                                                                    // dynamicLoading(
                                                                    //     0,
                                                                    //     undefined,
                                                                    //     undefined,
                                                                    //     undefined,
                                                                    //     undefined,
                                                                    //     sF.toString(),
                                                                    //     true
                                                                    // );
                                                                    setCookieFilter(undefined, undefined, undefined, sF.toString());
                                                                }}>
                                                                <DeleteIcon style={{ color: "white" }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            );
                                        } else return null;
                                    })}
                                    {selectFilters.length === 0 ? (
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={5} lg={4} xl={3}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={(e) => {
                                                        ormFilterCreate({
                                                            section_id: forms[0].section, //get just section for FK creation
                                                        })
                                                            .then((filt) => {
                                                                shownFilters.push(filt.id); // show on form after creation
                                                                this.setState({ shownFilters });
                                                                dummyState();

                                                                const params = new URLSearchParams();
                                                                params.append("filters", shownFilters.toString());
                                                                this.props.history.push({ search: params.toString() });
                                                            })
                                                            .then((dum) => {
                                                                this.maxHeightOfTable(true);
                                                            });
                                                    }}>
                                                    <IconButton className={classes.iconAdd}>
                                                        <AddIcon />
                                                    </IconButton>
                                                    &nbsp;&nbsp;&nbsp;Add New Filter
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        //styled border with saved filters
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={5} lg={4} xl={3}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={(e) => {
                                                        ormFilterCreate({
                                                            section_id: forms[0].section, //get just section for FK creation
                                                        })
                                                            .then((filt) => {
                                                                shownFilters.push(filt.id); // show on form after creation
                                                                this.setState({ shownFilters });
                                                                dummyState();
                                                                const params = new URLSearchParams();
                                                                params.append("filters", shownFilters.toString());
                                                                this.props.history.push({ search: params.toString() });
                                                            })
                                                            .then((dum) => {
                                                                this.maxHeightOfTable(true);
                                                            });
                                                    }}>
                                                    {/* <IconButton > */}
                                                    <AddIcon className={classes.iconAdd} style={{ marginTop: 2 }} />
                                                    {/* </IconButton> */}
                                                    &nbsp;&nbsp;&nbsp;Add New Filter
                                                </Button>
                                            </Grid>
                                            <Grid item xs={1} style={{ textAlign: "center" }}>
                                                <Typography>OR</Typography>
                                            </Grid>
                                            {selectFilters.length > 0 && (
                                                <Grid item xs={4} lg={4} xl={3} style={{ paddingBottom: "22px" }}>
                                                    <Select
                                                        field="existing"
                                                        fullWidth
                                                        label="Add Existing Filter"
                                                        options={this.MAKE_OPTIONS_ALL(selectFilters)}
                                                        eventHandle={(value) => {
                                                            //eslint-disable-next-line
                                                            return this.showFilter(value), this.maxHeightOfTable(true);
                                                        }}
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                    )}
                                </form>
                            )}
                        </Formm>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        );
    }
}
ExpansionPanelFilters.displayName = "ExpansionPanelFilters";

ExpansionPanelFilters = connect(
    (state, ownProps) => ({
        sections: getSections(state, ownProps),
        forms: getForms(state, ownProps),
        filters: getFilters(state, ownProps),
    }),
    { ...Filter.actions }
)(ExpansionPanelFilters);

export default withStyles(styles)(withRouter(ExpansionPanelFilters));
