import React, { Component } from "react";
import { Form } from "react-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { IconButton } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import Toolbar from "@material-ui/core/Toolbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";

import { withStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";

import { createSelector } from "../common/orm";
import TextField from "../common/TextField";
import CheckboxGroup from "../common/CheckboxGroup";
import { ContactRecord } from "./models";

const styles = (theme) => ({
    minWidth: {
        minWidth: 600,
    },
    flex: {
        flex: 1,
    },
});

const findParentRecordId = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["parentRecordId"]),
    (session, parentRecord) => {
        return parentRecord;
    }
);

const getMasterContacts = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["parentRecordId"]),
    (state, ownProps) => state,
    (session, parentRecord, state) => {
        var master_contacts = session.ContactRecord.filter((cr) => cr.parentrecord.includes(parentRecord)).toRefArray();
        var arr = [];
        master_contacts.forEach(function(mc) {
            var name = "";
            var email = ""
            mc.columns.forEach(function(c) {
                if (c.field_name && c.field_name.toUpperCase().includes("CONTACT NAME"))
                    name = c.userinput;
                else if (c.field_name && c.field_name.toUpperCase().includes("FIRST NAME"))
                    name = c.userinput;
                else if (c.field_name && c.field_name.toUpperCase().includes("LAST NAME"))
                    name += " " + c.userinput;
                else if (c.field_name && c.field_name.toUpperCase().includes("MAIL"))
                    email = c.userinput;
            });
            arr.push({label: name + " (" + email + ")", value: email});
        });
        arr.sort((a, b) => {
            let fa = a.label.toLowerCase(),
                fb = b.label.toLowerCase();
        
            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });
        arr.push({label: "Myself (" + state.auth.user.email + ")", value: state.auth.user.email});
        arr.push({label: "Other (Enter Below)", value: "Other"});
        return arr;
    }
);

class EmailForm extends Component {
    state = {
        showOther: false,
        loading: false,
        loadingFields: false
    };

    componentDidMount() {
        this.loadRecordFields();
    }

    componentDidUpdate() {
        this.loadRecordFields();
    }

    loadRecordFields = () => {
        const { authState, parentRecordId, ormContactRecordLoadDetailChild2 } = this.props;
        const { loadingFields } = this.state;

        const _this = this;

        // If you load this page from a bookmark it doesn't have an authstate right away
        if (!authState || !authState.user) return;

        // Only do this for the fields view - not the table
        if (!loadingFields && parentRecordId) {
            this.setState({ loadingFields: true });
            ormContactRecordLoadDetailChild2("parent" + parentRecordId, function () {
                _this.setState({ "foo":"bar" });
            });
        }
    };

    sendForm = (values) => {
        const { url, authState, returnDialog } = this.props;
        this.setState({ loading: true });

        var emails = values.emails;
        if (emails.includes("Other")) {
            emails = emails.filter(item => item !== "Other");
            var other_emails = values.other_emails.split(",").map(function(item) {
                return item.trim();
            });
            emails = emails.concat(other_emails);
        }
        
        fetch("/reports/email/send", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token
            },
            body: JSON.stringify({
                emails: emails,
                report: url,
                message: values.message,
            }),
        }).then((e) => {
            this.setState({ loading: false });
            if (e.status === 500 || e.status === 401) {
                alert("Email Failure");
            } else {
                alert("Your email has been sucessfully sent.");
                returnDialog();
            }
        });;
    };

    render() {
        const { classes, returnDialog, open, master_contacts, report_name } = this.props;
        const { showOther, loading } = this.state;

        return (
            <Dialog open={open} classes={{ paper: classes.minWidth }}>
                <Toolbar>
                    <Typography variant="h6" className={classes.flex}>
                        Email {report_name}
                    </Typography>
                    <IconButton aria-label="Close Dialog" onClick={returnDialog}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent className={classes.dialogMin}>
                    <DialogContentText>
                        <Form
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            onSubmit={(values) => this.sendForm(values)}>
                            {(formApi) => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <CheckboxGroup
                                                field="emails"
                                                wtfwidth2
                                                label="Who should be included in the email?"
                                                options={master_contacts}
                                                eventHandle={(value) => {
                                                    if (value.includes("Other")) {
                                                        this.setState({showOther: true})
                                                    } else {
                                                        this.setState({showOther: false})
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        {showOther && (
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    field="other_emails"
                                                    label="Enter Other Email Receipient(s). Separate multiple addresses with a comma (,)"
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                multiline
                                                rows={4}
                                                field="message"
                                                label="Email Body Message (Optional)"
                                            />
                                        </Grid>
                                        {loading ? (
                                            <Grid item xs={12} style={{textAlign:"center"}}>
                                                <CircularProgress size={24}/>
                                            </Grid>
                                        ) : (
                                            <>
                                            <Grid item xs={6}>
                                                <Button fullWidth onClick={returnDialog}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth type="submit" variant="contained" color="primary">
                                                    Email Report
                                                </Button>
                                            </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}
EmailForm.displayName = "EmailForm";

EmailForm = connect(
    (state, ownProps) => ({
        master_contacts: getMasterContacts(state, ownProps), 
        authState: state.auth,
        parentRecordId: findParentRecordId(state, ownProps),
    }),
    {
        ...ContactRecord.actions,
    }
)(EmailForm);

export default withStyles(styles)(withRouter(EmailForm));
