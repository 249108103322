//used for react route change to global map

import React, { Component } from "react";
import AppContainer from "../common/AppContainer";
import BaseMapControl from "../common/esrimap/BaseMapControl";

class MapGlobal extends Component {
    render() {
        return (
            <AppContainer authenticated>
                <BaseMapControl />
            </AppContainer>
        );
    }
}
MapGlobal.displayName = "MapGlobal";

export default MapGlobal;
