import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core";
import { createSelector } from "../common/orm";
import AppContainer from "../common/AppContainer";
import { Permission } from "../page/models";
import CreateAdvancedSearches from "../page/advancedSearch/CreateAdvancedSearches";
import PageAnalytics from "./PageAnalytics";

const styles = (theme) => ({});

const getdashboardPermissions = Permission.selectAll();

const getPageId = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (session, pageID) => {
        return session.Page.filter((p) => p.id === pageID).toRefArray();
    }
);

class Dashboard extends Component {
    render() {
        const { authState, dashboardPermissions, page /*parentrecord*/ } = this.props;

        var synced = false;
        // If the parentrecords haven't loaded yet (sync.progress < sync.total) and never have been loaded (parentrecords.length === 0) then display the loading icon
        // if (JSON.stringify(sync.pending) !== "{}" && !sync.ready && sync.progress !== sync.total) {
        //     synced = true;
        // }

        var filteredDashboardPermissions = dashboardPermissions.filter((d) => {
            if (authState.user) {
                if (d.user === authState.user.id && page.length > 0 && d.page === page[0].id) {
                    return d;
                } else return null;
            } else return null;
        });

        return (
            <AppContainer authenticated synced={synced}>
                {page[0] && page[0].iframe_url ? (
                    <iframe src={page[0].iframe_url} title={page[0].name} frameborder="0" style={{ overflow: "hidden", height: "99%", width: "100%" }} height="99%" width="100%"></iframe>
                ) : (
                    <Grid container spacing={3}>
                        <Grid key={1} item xs={12}>
                            {page.length > 0 && <PageAnalytics pageID={page[0].id} />}
                        </Grid>
                        {filteredDashboardPermissions.length > 0 && (
                            <Grid key={2} item xs={12}>
                                <CreateAdvancedSearches key={2} dashboardConfigured={true} filteredDashboardPermissions={filteredDashboardPermissions} />
                            </Grid>
                        )}
                    </Grid>
                )}
            </AppContainer>
        );
    }
}

Dashboard.displayName = "Dashboard";

Dashboard = connect(
    (state, ownProps) => ({
        authState: state.auth,
        dashboardPermissions: getdashboardPermissions(state, ownProps),
        page: getPageId(state, ownProps),
    }),
    null
)(Dashboard);

export default withStyles(styles)(withRouter(Dashboard));
