import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
 
import IconButton from "@material-ui/core/IconButton";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepLabel";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from "@material-ui/icons/Visibility";
import Close from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import AppContainerApplication from "../../common/AppContainerApplication";
import TextField from "../../common/TextField";
import RadioGroup from "../../common/RadioGroup";
import Select from "../../common/Select";
import CheckboxGroup from "../../common/CheckboxGroup";
import ReactSelect from "../../common/ReactSelect";
import FileInput from "../../common/FileInput";
import Checkbox from "../../common/Checkbox";
import HelpLabel from "../../common/HelpLabel";
import { createSelector } from "../../common/orm";
import states from "../../common/statesmn.json";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import BaseMapControlApplication from "../esri/BaseMapControlApplication";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ApplicationRecord, ApplicationRecordStatus, RoseauPermit, RoseauCulvert, RoseauAttachment } from "../../page/models";

const styles = (theme) => ({
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8,
        marginTop: 2,
        marginBottom: 2,
    },
    centerAlign: {
        textAlign: "center",
    },
    centerAlign2: {
        textAlign: "center",
        display: "inline-block",
        width: "100%",
    },
    nowrap: {
        whiteSpace: "nowrap",
    },
    rightAlign: {
        textAlign: "right",
        marginRight: 15,
    },
    flex: {
        flex: 1,
    },
    printDivider: {
        height: 8,
        backgroundColor: "rgba(0, 0, 0, 0.42)",
        marginTop: 16,
        marginBottom: 8,
    },
    dialogMin: {
        minWidth: 400,
    },
    minWidth: {
        minWidth: 600,
    },
    root: {
        flexBasis: "100%",
        maxWidth: "100%",
    },
});

function MAKE_OPTIONS(table) {
    return table.map((row) => ({
        label: row,
        value: row,
    }));
}

const getApplication = createSelector(
    (state, ownProps) => ownProps.match.params["hash"],
    (session, hash) => {
        return session.RoseauPermit.filter((p) => p.hash === hash).toModelArray()[0];
    }
);

class Roseau extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            culvertDialogOpen: false,
            culvertEditing: null,
            formKey: null,
            order: "desc",
            orderBy: null,
            temp_contacts: [],
            attachmentDialogOpen: false,
            attachmentEditing: null,
            attachmentFormKey: null,
            attachmentOrder: "desc",
            attachmentOrderBy: null,
            temp_attachments: [],
            loading: false,
            new_save: false,
            new_save2: false,
            mainFormKey: window.performance.now(),
            entryError: false,
            projectType: [],
            selectLegal: false,
            tempCulverts: [],
            culvertFormKey:null,
            dialogLoading:false,
            createPoint:false,
            drawingFeature:false
      
        };
}

    componentDidMount() {
        const { match, ormRoseauPermitLoadDetailChild, ormRoseauAttachmentLoadDetailChild, ormRoseauCulvertLoadDetailChild } = this.props;
        var component = this;

        if (match.params && match.params.hash) {
            ormRoseauPermitLoadDetailChild(match.params.hash, function (wtf) {
                component.setState({ mainFormKey: window.performance.now(), projectType:wtf[0].project_types });
            });
            ormRoseauCulvertLoadDetailChild(match.params.hash, function (data) {
                component.setState({ tempCulverts: data });
            });
            ormRoseauAttachmentLoadDetailChild(match.params.hash, function (data) {
                component.setState({ temp_attachments: data });
            });
        }
    }

    errorValidator = (values) => {
        const isRequired = (val) => {
            return !val ? "Required" : null;
        };

        var valObj = {
            appl_first_name: isRequired(values.appl_first_name),
            appl_last_name: isRequired(values.appl_last_name),
            appl_phone: isRequired(values.appl_phone),
            project_types: isRequired(values.project_types),
            county: isRequired(values.county),
            project_description: isRequired(values.project_description),
            project_township: isRequired(values.project_township),
            section: isRequired(values.section),
            understand1: isRequired(values.understand1), 
        };

        var alerts = "";
        var ai = 6;
        if (valObj["appl_first_name"] === "Required") {
            alerts += "Applicant First Name (Step 2)\n";
            ai = 1;
        }
        if (valObj["appl_last_name"] === "Required") {
            alerts += "Applicant Last Name (Step 2)\n";
            ai = 1;
        }
        if (valObj["appl_phone"] === "Required") {
            alerts += "Applicant Phone  (Step 2)\n";
            ai = 1;
        }
      

       
        if (valObj["county"] === "Required") {
            alerts += "County (Step 4)\n";
            ai = 2;
        }
        if (valObj["project_township"] === "Required") {
            alerts += "Township/City Name (Step 4)\n";
            ai = 2;
        }
        if (valObj["section"] === "Required") {
            alerts += "Section (Step 4)\n";
            ai = 2;
        }
        if (valObj["project_types"] === "Required") {
            alerts += "Project types (Step 4)\n";
            ai = 3;
        }
        if (valObj["project_description"] === "Required") {
            alerts += "Project description (Step 4)\n";
            ai = 3;
        }
    
        if (valObj["understand1"] === "Required") {
            alerts += "Understand Agreement (Step 7)\n";
            ai = 6;
        }
        if (alerts) {
            alert("The following fields are missing or invalid:\n" + alerts);
            this.setState({ activeStep: ai });
        }

        return valObj;
    };

    errorValidatorAttachment = (values) => {
        const isRequired = (val) => {
            return !val ? "Required" : null;
        };
        var valObj = {
            file: isRequired(values.file),
            name: isRequired(values.name),
        };

        return valObj;
    };

    submitApplication = (values) => {
        const {
            application,
            ormApplicationRecordCreate,
            ormApplicationRecordStatusCreate,
            ormRoseauPermitCreate,
            ormRoseauCulvertCreate,
            ormRoseauAttachmentCreate,
        } = this.props;
        const { tempCulverts, temp_attachments } = this.state;

        const _this = this;
        this.setState({ loading: true });

        // Editing
        if (application) {
            
        } else {
            // New
            ormApplicationRecordCreate({
                application: 13,
            }).then((ar) => {
                ormApplicationRecordStatusCreate({
                    applicationrecord: ar.id,
                    status: "Received",
                });
                ormRoseauPermitCreate({
                    applicationrecord: ar.id,
                    ...values,
                }).then((rpb) => {
                    if (rpb && rpb.error) {
                        this.setState({ entryError: true, loading: false });
                        fetch("/error/post", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                error: JSON.stringify(values),
                                error_info: rpb.error.toString(),
                            }),
                        });
                    } else {
                        var i = 0;
                        var j = 0;

                        tempCulverts.forEach(function (tc) {
                            i++;
                            ormRoseauCulvertCreate({
                                roseaupermit_id: rpb.id,
                                ...tc,
                            }).then((_z) => {
                                j++;
                                if (i === j) _this.setState({ loading: false, new_save: true });
                            });
                        });
                        temp_attachments.forEach(function (tc) {
                            i++;
                            ormRoseauAttachmentCreate({
                                roseaupermit_id: rpb.id,
                                ...tc,
                            }).then((_z) => {
                                j++;
                                if (i === j) _this.setState({ loading: false, new_save: true });
                            });
                        });
                        if (i === 0) {
                            // No attachments or contacts - its finished loading
                            _this.setState({ loading: false, new_save: true });
                        }
                    }
                });
            });
        }
    };
    addCulvert = (values) => {
        var { culvertEditing, tempCulverts } = this.state;

        if (!culvertEditing) {
            tempCulverts.push(values);
            this.setState({ tempCulverts: tempCulverts });
        } else {
            var temp = tempCulverts.filter((tc) => tc !== culvertEditing);
            temp.push(values);
            this.setState({ tempCulverts: temp });
        }

        this.setState({ culvertDialogOpen: false });
    };
    errorValidatorCulvert = (values) => {
        const isRequired = (val) => {
            return !val ? "Required" : null;
        };
        var valObj = {
            name: isRequired(values.name),
        };

        return valObj;
    };
    addAttachment = (values) => {
        var { attachmentEditing, temp_attachments } = this.state;

        if (!attachmentEditing) {
            temp_attachments.push(values);
            this.setState({ temp_attachments: temp_attachments });
        } else {
            var temp = temp_attachments.filter((tc) => tc !== attachmentEditing);
            temp.push(values);
            this.setState({ temp_attachments: temp });
        }

        this.setState({ attachmentDialogOpen: false });
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.state.temp_contacts.sort((a, b) => (b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1))
            : this.state.temp_contacts.sort((a, b) => (a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1));

        this.setState({ order, orderBy });
    };

    handleRequestSort2 = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.state.temp_attachments.sort((a, b) => (b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1))
            : this.state.temp_attachments.sort((a, b) => (a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1));

        this.setState({ order, orderBy });
    };

    handleNext = (index) => {
        const { activeStep } = this.state;
        if (index || index === 0) {
            this.setState({ activeStep: index });
        } else if (activeStep < 6) this.setState({ activeStep: activeStep + 1 });
        else {
            this.formApi.submitForm();
        }
    };
    //save the geometry from the map
    // saveAddress=(geometry, address)=>{
   
    //     this.formApi.setValue("project_address",address.StAddr)
    //     this.formApi.setValue("project_city",address.City) //if city isnt within list it wont show on dropdown
    //     this.formApi.setValue("project_zip",address.Postal)

    //     let geojson = {
    //         type: "Point",
    //         coordinates: [geometry.longitude,geometry.latitude]
    //     };

    //     //check if within boundary
    //     fetch(`
    //         https://services9.arcgis.com/FsMUjHd4ZYdso5QZ/ArcGIS/rest/services/NMCWD_Legal_Boundary/FeatureServer/0/query?where=1=1&objectIds=&time=&geometry=${geometry.longitude},${geometry.latitude}
    //         &geometryType=esriGeometryPoint&inSR=4326&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Meter&relationParam=&returnGeodetic=false&outFields
    //         =&returnGeometry=true&returnCentroid=false&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision
    //         =&outSR=&defaultSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false
    //         &returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false
    //         &returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pgeojson&token=`,
    //         {
    //             method : "GET"

    //         })
    //         .then(resp=> resp.json())
    //         .then(data=>{
    //         if(data.features.length === 0){
    //             this.setState({outOfDistrict:true})
    //         }
    //     })
    //     this.setState({thegeo:geojson})

    // }
    setDialogLoading = (e) =>{
        this.setState({dialogLoading:e})
    }
    //this is triggered from basemapcontrolapplication.js which will fill in the below 4 fields
    setDrawingFeature = (geom) =>{
         
        if(geom){
            this.formApi.setValue('geometry', geom)
            
            if(geom){//so in this case lets get the 4 service fields now and update on the UI    
                //plls_twn, plss_rng, section
                fetch(`https://services7.arcgis.com/cmRyWgQZXN0qaeRX/arcgis/rest/services/RRWD_MS4Front_Map_WFL1/FeatureServer/2/query?geometry=${geom.coordinates[0]},${geom.coordinates[1]}
                    &outFields=*&geometryType=esriGeometryPoint&inSR=4326&returnGeometry=false&f=pjson`,
                {
                    method : "GET"
    
                })
                .then(resp=> resp.json())
                .then(data=>{
                    if(data.features.length === 0){
                    // this.setState({outOfDistrict:true})?
                        console.log("out of district")
                    }else{
                        let res = data.features[0].attributes
                        this.formApi.setValue('plss_rng', res.RANG) //plssrng 
                        this.formApi.setValue('section', res.SECT) //sect  
                         this.formApi.setValue('plss_twp', res.TOWN) //plss twp  
                    }
                })
                //township
                fetch(`https://services7.arcgis.com/cmRyWgQZXN0qaeRX/arcgis/rest/services/RRWD_MS4Front_Map_WFL1/FeatureServer/1/query?geometry=${geom.coordinates[0]},${geom.coordinates[1]}
                  &outFields=*&geometryType=esriGeometryPoint&inSR=4326&returnGeometry=false&f=pjson`,
                {
                  method : "GET"
  
                })
                .then(resp=> resp.json())
                .then(data=>{
                    if(data.features.length === 0){
                    // this.setState({outOfDistrict:true})? https://services7.arcgis.com/cmRyWgQZXN0qaeRX/arcgis/rest/services/RRWD_MS4Front_Map_WFL1/FeatureServer/1
                        console.log("out of district")
                    }else{
                        let res = data.features[0].attributes 
                        this.formApi.setValue('project_township',res.TWP_Name) //township/municipality 

                    }
                })
                   //county
                //    `https://services7.arcgis.com/cmRyWgQZXN0qaeRX/arcgis/rest/services/RRWD_Boundaries/FeatureServer/3/query?geometry=${geom.coordinates[0]},${geom.coordinates[1]}
                //   &outFields=*&geometryType=esriGeometryPoint&inSR=4326&returnGeometry=false&f=pjson`,
                fetch(`https://services7.arcgis.com/cmRyWgQZXN0qaeRX/arcgis/rest/services/RRWD_MS4Front_Map_WFL1/FeatureServer/0/query?geometry=${geom.coordinates[0]},${geom.coordinates[1]}
                  &outFields=*&geometryType=esriGeometryPoint&inSR=4326&returnGeometry=false&f=pjson`,
                {
                  method : "GET"
  
                })
                .then(resp=> resp.json())
                .then(data=>{
                    if(data.features.length === 0){
                    // this.setState({outOfDistrict:true})?     
                        console.log("out of district")
                    }else{
                        let res = data.features[0].attributes 
                        this.formApi.setValue('county',res.CTY_NAME) ///county 

                    }
                })

            }
        }
    }

    render() {
        const { classes, application } = this.props;
        const {
            activeStep,
            attachmentDialogOpen,
            attachmentEditing,
            attachmentFormKey,
            attachmentOrder,
            attachmentOrderBy,
            temp_attachments,
            loading,
            new_save,
            mainFormKey,
            new_save2,
            entryError, 
            order,
            orderBy,
            culvertDialogOpen,
            culvertEditing,
            formKey, 
            projectType,
            selectLegal,
            tempCulverts,
            drawingFeature,
            createPoint,
            dialogLoading,
        } = this.state;

        //this has all parameters for the basemapcontrolapplication
        var layers = [
        {attribution: "", city_id: 52,city_label: "RRWD",id:52, is_basemap: false,
        layer_type: "Web Map", layernumbers: "3", layerurl: null,
        name: "RRWD Area",on_by_default: true,opacity: 1,order: 2,proxyurl: "",show_popup: false, subdomains: "",
        tileddynamic: "Feature Service",token: "",webmap_id: "f923c68fbd43452da17e2963504477d4", wmslayers: "", x: -95.502120, y: 48.643390,
        geocoder_left:[], geocoder_bottom:[], geocoder_right:[],geocoder_top:[], map_level:9 }]
    
        var is_read_only = false;
        if (
            application &&
            application.applicationrecord &&
            (application.applicationrecord.status !== ""
        ))
            is_read_only = true;

        var columnDataAttachments = [
            { id: "actions", numeric: false, label: "", allowSort: false, width: "20px" },
            { id: "name", numeric: false, label: "Name", allowSort: true },
            { id: "_type", numeric: false, label: "Type", allowSort: true },
            { id: "notes", numeric: false, label: "Notes", allowSort: true },
        ];

        var culvertHeader = [
            { id: "actions", numeric: false, label: "", allowSort: false, width: "20px" },
            { id: "name", numeric: false, label: "Culvert Name or ID", allowSort: true },
            { id: "e_size", numeric: false, label: "Existing culvert size", allowSort: true },
            { id: "e_length", numeric: false, label: "Existing culvert length", allowSort: true },
            { id: "e_elevation", numeric: false, label: "Existing culvert elevation", allowSort: true },
            { id: "p_size", numeric: false, label: "Proposed culvert size", allowSort: true },
            { id: "p_length", numeric: false, label: "Proposed culvert length", allowSort: true },
            { id: "p_elevation", numeric: false, label: "Proposed culvert elevation", allowSort: true },
            { id: "additional", numeric: false, label: "Additional information about culvert", allowSort: true },
        ]
 
        if (new_save) {
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "12" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10}>
                            <Typography>
                                This is a confirmation that the permit application has been submitted successfully. A copy of the permit application
                                has been emailed to the primary property owner's email address. Please check your spam and junk folders if you have
                                not received it or contact the district. The district will contact you as the application is being reviewed.
                            </Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }
        if (entryError) {
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "12" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10}>
                            <Typography>
                                This permit application was 'not' successfully saved. Please contact the permit 'managing' staff to alert them of the
                                entry failure.
                            </Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }

        if (new_save2) {
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "12" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10}>
                            <Typography>This is a confirmation that the permit application has been resubmitted successfully.</Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }
        if (activeStep === "PRINT")
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "12" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10}>
                            <Typography>Permit Printed.</Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );

        var attachmentTypes = []
        if (projectType.length > 0){    
            let tiling = ['Tiling Site Map','Tiling Downstream Landowner Notification']
            let surface = ['Surface Drainage Site Map','Surface Drainage Downstream Landowner Notification']
            let culvert = ['Culvert Site Map']
            let bridge = ['Bridge Site Map','Bridge Project Engineers Report','Bridge Detailed Project Construction Plans']
            let wet = ['Wetland/Water Project Site Map','Wetland/Water Project Detailed Construction Plans']
            let road = ['Road Grading detailed project construction plans','Road Grading site plan']
            let dike = ['Dike site plan','Dike detailed construction plans']
            let channel = ['Channel detailed design plans','Channel site map']
            let land= ['Land Development project detailed construction plans','Land Development project site map']
            let stormwater = ['Stormwater Report showing modeling results of pond design','Stormwater Pollution Prevention Plan (SWPPP)']
            let utility = ['Utility project detailed project plans','Utility project site map']
            let other = ['Legacy File','Other']
            if(projectType.find(t=>t.includes("Tiling")) && !attachmentTypes.find(a=> a.includes("Tiling")))attachmentTypes = [...attachmentTypes, ...tiling] 
            if(projectType.find(t=>t.includes("Surface")) && !attachmentTypes.find(a=> a.includes("Surface")))attachmentTypes = [...attachmentTypes, ...surface]
            if(projectType.find(t=>t.includes("Culvert")) && !attachmentTypes.find(a=> a.includes("Culvert")))attachmentTypes = [...attachmentTypes, ...culvert] 
            if(projectType.find(t=>t.includes("Bridge")) && !attachmentTypes.find(a=> a.includes("Bridge")))attachmentTypes = [...attachmentTypes, ...bridge] 
            if(projectType.find(t=>t.includes("Wetland")) && !attachmentTypes.find(a=> a.includes("Wetland")))attachmentTypes = [...attachmentTypes, ...wet] 
            if(projectType.find(t=>t.includes("Road")) && !attachmentTypes.find(a=> a.includes("Road")))attachmentTypes = [...attachmentTypes, ...road] 
            if(projectType.find(t=>t.includes("Dike")) && !attachmentTypes.find(a=> a.includes("Dike")))attachmentTypes = [...attachmentTypes, ...dike] 
            if(projectType.find(t=>t.includes("Channel")) && !attachmentTypes.find(a=> a.includes("Channel")))attachmentTypes = [...attachmentTypes, ...channel] 
            if(projectType.find(t=>t.includes("Land")) && !attachmentTypes.find(a=> a.includes("Land")))attachmentTypes = [...attachmentTypes, ...land] 
            if(projectType.find(t=>t.includes("Stormwater")) && !attachmentTypes.find(a=> a.includes("Stormwater")))attachmentTypes = [...attachmentTypes, ...stormwater]  
            if(projectType.find(t=>t.includes("Utility")) && !attachmentTypes.find(a=> a.includes("Utility")))attachmentTypes = [...attachmentTypes, ...utility]  
            if(!attachmentTypes.find(a=> a.includes("Legacy")))attachmentTypes = [...attachmentTypes, ...other]  
     
        }
        return (
            <AppContainerApplication
                city_id={"53"}
                page_loading={loading}
                printing={activeStep === "PRINT"}>
                <Form
                    getApi={(el) => (this.formApi = el)}
                    defaultValues={application}
                    key={mainFormKey}
                    pure={false}
                    dontValidateOnMount={true}
                    validateOnSubmit={true}
                    validateError={(values) => this.errorValidator(values)}
                    onSubmit={(values) => this.submitApplication(values)}>
                    {(formApi) => (
                        <form onSubmit={formApi.submitForm}>
                            <>
                                {application && application.applicationrecord && application.applicationrecord.status && (
                                    <Grid container spacing={1} style={{ backgroundColor: "#a3a3a3", marginTop: -15 }}>
                                        <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                            <Typography className={classes.centerAlign} variant="h6">
                                                Permit Status: {application && application.applicationrecord.status}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                                <Grid container spacing={1} style={{ padding: "0 12px" }}>
                                    <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                        <Typography className={classes.centerAlign} variant="h6">
                                            Permit Application Form {application && application.permit_number && "#" + application.permit_number}
                                        </Typography>{" "}
                                        <Grid item xs={12} xl={10} className={classes.rightAlign} classes={{ root: classes.root }}></Grid>
                                        <Typography className={classes.centerAlign}>
                                            <b>IMPORTANT:</b> The entire permit application must be filled out at one time and progress will not be
                                            saved so please plan accordingly.
                                        </Typography>
                                        <Typography variant="caption" className={classes.centerAlign2}>
                                            Required Fields are marked with an asterisk (*)
                                        </Typography>
                                    </Grid>
                                    {activeStep !== "PRINT" && (
                                        <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                            <Stepper style={{ padding: 8 }} alternativeLabel nonLinear activeStep={activeStep}>
                                                {[
                                                    "Instructions",
                                                    "Applicant Information",
                                                    "Project Location",
                                                    "Project Type",
                                                    "Project Type Details",
                                                    "Document Upload",
                                                    "Landowner Acknowledgement and Submittal",
                                                ].map((label, index) => {
                                                    return (
                                                        <Step key={label}>
                                                            <StepButton style={{ cursor: "pointer" }} onClick={() => this.handleNext(index)}>
                                                                {label}
                                                            </StepButton>
                                                        </Step>
                                                    );
                                                })}
                                            </Stepper>
                                        </Grid>
                                    )}
                                    {activeStep === 0 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Typography variant="subtitle1">
                                                <b>
                                                    {"1. "}
                                                    <u>Preparation</u>
                                                </b>
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                {`\u2022`} As you work through the online application you will not be able to save your progress for
                                                submittal at a later time.
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                {`\u2022`} Please have all the required information on hand to ensure you are able to submit a
                                                complete application.
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                {`\u2022`} For guidance about the required information, please see the{" "}
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="http://www.roseauriverwd.com/pdf/Policies/rules.pdf"
                                                    >
                                                    Roseau River Watershed District Rules
                                                </a>{" "}
                                            </Typography>
                                            <br />
                                            <Typography variant="subtitle1">
                                                <b>
                                                    {"2. "}
                                                    <u>Submit Application</u>
                                                </b>
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                {`\u2022`} Once you have compiled the required information, complete the permit application and
                                                submit. Again, your progress will not be saved for submittal at a later time.
                                            </Typography>
                                            <br />
                                            <Typography variant="subtitle1">
                                                <b>
                                                    {"3. "}
                                                    <u>Application Review</u>
                                                </b>
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                {`\u2022`} Once your application has been submitted, RRWD staff will notify you within 15 days if more information is required. 
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                {`\u2022`} Once all required information has been received, application will be brought to RRWD Board for approval.
                                            </Typography>
                                        </Grid>
                                    )}
                                    {activeStep === 1 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Typography>Please add contacts associated with the application.</Typography>
                                            <Accordion defaultExpanded>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        Primary Property Owner (required)
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="appl_first_name"
                                                                label="First Name *"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="appl_last_name"
                                                                label="Last Name *"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="appl_affiliation"
                                                                label={
                                                                    <HelpLabel
                                                                        inputLabel="Affiliation"
                                                                        helpText="Please list government unit or private entity that 
                                                                        is applying on behalf of the landowner, if applicable.
                                                                        "
                                                                    />
                                                                }
                                                                fullWidth
                                                            />
                                                        </Grid>
                                               
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="appl_email" label="Email" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="appl_phone" label="Phone *" fullWidth usePhoneNumberFormat />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="appl_mailing_address" label="Address" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="appl_mailing_address2" label="Address 2" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="appl_city" label="City" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Select
                                                                disabled={is_read_only}
                                                                field="appl_state"
                                                                label="State"
                                                                options={states}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="appl_zip" label="Zip" fullWidth />
                                                        </Grid>
                                                
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion defaultExpanded>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        Landowner (if not same as Applicant)
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="landowner_first_name"
                                                                label="First Name"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="landowner_last_name"
                                                                label="Last Name"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="landowner_email"
                                                                label="Email"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="landowner_phone"
                                                                label="Phone"
                                                                fullWidth
                                                                usePhoneNumberFormat
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="landowner_mailing_address" label="Address" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="landowner_mailing_address2" label="Address 2" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="landowner_city" label="City" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Select
                                                                disabled={is_read_only}
                                                                field="landowner_state"
                                                                label="State"
                                                                options={states}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="landowner_zip" label="Zip" fullWidth />
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>{" "}
                                        </Grid>
                                    )}
                                    {activeStep === "PRINT" && (
                                        <>
                                            <div style={{ height: 50 }}></div>
                                            <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                                <Divider className={classes.printDivider} />
                                            </Grid>
                                        </>
                                    )}
                                    {activeStep === 2 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                         
                                         
                                            <Grid container spacing={2}>
                                            <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                                <Typography variant="subtitle1" gutterBottom style={{marginBottom:-7, marginLeft:14}}>
                                                    <b>Instructions:</b> 
                                                </Typography>
                                                <Typography variant="subtitle1" gutterBottom style={{ marginLeft:16}}>
                                                    Step 1: Use the + or - buttons to zoom the map to your permit locations. Alternatively you can type an address or road intersection into the search box to zoom the map to the general area of your permit.
                                                </Typography>
                                                 <Typography variant="subtitle1" gutterBottom style={{  marginLeft:16}}>
                                                 Step 2: Click on the Mapping Tools button labeled "Add a New Permit Location". This will change your mouse cursor to a blue dot. Click on the map to place your location of your permit. For linear projects place it on the approximate mid-point of the project. For other projects place the point on the best place that represents the location of your project.
                                                </Typography>
                                                <Typography variant="subtitle1" gutterBottom style={{  marginLeft:16}}>
                                                Step 3: After you place the point on the map, the Project Location fields will automatically populate. Verify those are the correct information. 
                                                </Typography>
                                                <Typography variant="subtitle1" gutterBottom style={{  marginLeft:16}}>
                                                Step 4: If you need to modify the location of the point location on the map, Click Delete Project Location button and repeat Step 2. Otherwise click the Next button to proceed to the Project Type step. 
                                                </Typography> 
                                            </Grid>
                                            <Grid item xs={12} xl={10} style={{ marginLeft: 15, marginRight:15 }} classes={{ root: classes.root }}>
                                                <BaseMapControlApplication
                                                    geometry={application && application.geometry?application.geometry:null}
                                                    // saveAddress={this.saveAddress}
                                                    disableSearch={application && application.geometry?true:false}
                                                    viewOnly
                                                    application={application}
                                                    setDialogLoading={this.setDialogLoading} 
                                                    // address={address}
                                                    // city={city}
                                                    // zip={zip} 
                                                    setDrawingFeature={this.setDrawingFeature}
                                                    drawingFeature={drawingFeature}
                                                    createPoint={createPoint}
                                                    setPoint={this.setPoint}
                                                    layers={layers}
                                                    cityID={"53"}  
                                                /> 
                                                
                                                {dialogLoading && ( 
                                                    <CircularProgress
                                                        size={72}
                                                        style={{ display: "table", marginLeft: "auto", marginRight: "auto", zIndex:90000 }}
                                                    /> 
                                                )}
                                            <Grid item xs={12}>
                                                <br />
                                                <hr />
                                                <br />
                                            </Grid>
                                            </Grid> 
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        <b>Project Location (County, Township and Section is required)</b>
                                                    </Typography>
                                                </Grid>
                                            
                                                <Grid item xs={6}>
                                                    <Select
                                                        disabled={is_read_only}
                                                        field="county"
                                                        label="County *"
                                                        options={MAKE_OPTIONS(["Beltrami","Kittson","Lake of the Woods","Marshall","Roseau"])}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Select
                                                        disabled={is_read_only}
                                                        field="project_township"
                                                        label="Township/City Name *"
                                                        options={MAKE_OPTIONS([
                                                            "America","Beaver","Beltrami Forest","Blooming Valley","Caribou","Cedarbend","Clear River","Dieter","Elkwood","Enstrom",
                                                            "Falun","Golden Valley","Grimstad","Jadis","Lake","Linsell","Malung","Mickinock","Moose","Moose River","Norland",
                                                            "North Beltrami","Palmville","Pohitz","Poplar Grove","Reine","Ross","Soler","Spruce","Spruce Valley","Stafford",
                                                            "Thief Lake","Unorganized 11","Unorganized 14","Unorganized 47","City of Roseau"
                                                            ])}
                                                        fullWidth
                                                    />
                                                </Grid>   
                                                <Grid item xs={6}>
                                                    <Select
                                                        disabled={is_read_only}
                                                        field="plss_twp"
                                                        label="PLSS Twp"
                                                        options={MAKE_OPTIONS(["157","158","159","160","161","162","163","164"])}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Select
                                                        disabled={is_read_only}
                                                        field="plss_rng"
                                                        label="PLSS Rng"
                                                        options={MAKE_OPTIONS(["34","35","36","37","38","39","40","41","42","43","44","45"])}
                                                        fullWidth
                                                    />
                                                </Grid>
                                             
                                                <Grid item xs={6}>
                                                    <Select
                                                        disabled={is_read_only}
                                                        field="section"
                                                        label="Section *"
                                                        options={MAKE_OPTIONS([
                                                            "1",
                                                            "2",
                                                            "3",
                                                            "4",
                                                            "5",
                                                            "6",
                                                            "7",
                                                            "8",
                                                            "9",
                                                            "10",
                                                            "11",
                                                            "12",
                                                            "13",
                                                            "14",
                                                            "15",
                                                            "16",
                                                            "17",
                                                            "18",
                                                            "19",
                                                            "20",
                                                            "21",
                                                            "22",
                                                            "23",
                                                            "24",
                                                            "25",
                                                            "26",
                                                            "27",
                                                            "28",
                                                            "29",
                                                            "30",
                                                            "31",
                                                            "32",
                                                            "33",
                                                            "34",
                                                            "35",
                                                            "36",
                                                        ])}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Select
                                                        disabled={is_read_only}
                                                        field="quarter_section"
                                                        label="Primary Quarter Section"
                                                        options={MAKE_OPTIONS(["NW", "NE", "SW", "SE"])}
                                                        fullWidth
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                     {activeStep === 3 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                                <CheckboxGroup
                                                    field="project_types"
                                                    label="Select Project Types: (Check all that apply)"
                                                    required
                                                    row={true}
                                                    disabled={is_read_only}
                                                    eventHandle={(e) => {
                                                        this.setState({projectType:e})
                                                    }}
                                                    options={MAKE_OPTIONS([
                                                        "Tiling","Surface Drainage (New Ditch or Improvement)","Culvert Installation / Removal / Modification","Bridge Installation / Removal / Modification",
                                                        "Wetland Restoration / Water Retention Related Structures / Water and Sediment Control Basins","Dike / Levee",
                                                        "Channel Stabilization / Restoration / Erosion Control / Shoreland Projects","Land Development","Utility Installations"
                                                    ])}
                                                />
                                            <Grid item xs={12}>
                                                    <br />
                                                    <hr />
                                                    <br />
                                            </Grid>
                                            <Grid container spacing={2}>
                                                    <TextField
                                                        field="project_description"
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Provide description of proposed project *:"
                                                                helpText="Applicant must provide a detailed project description. Application will be considered <u>incomplete</u> until adequate information is provided."
                                                            />
                                                        }
                                                        multiline
                                                        rows={8}
                                                        disabled={is_read_only}
                                                        fullWidth
                                                    />
                                           
                                            </Grid>
                                        </Grid>
                                    )}
                                    {activeStep === "PRINT" && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Divider className={classes.printDivider} />
                                        </Grid>
                                    )}
                                    {activeStep === 4 && projectType.length === 0 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Typography variant="subtitle1" gutterBottom style={{textAlign: "center", textDecoration: "underline",fontWeight: "bold"}}>
                                                No Project Types have been selected. Refer to Step 4 and select a 'Project Type'
                                            </Typography>
                                        </Grid>
                                    )}
                                    {activeStep === 4 && projectType.length > 0 && projectType.find(t=>t==="Tiling") && (   
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                           <Accordion defaultExpanded>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        Tiling
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="til_water_issu"
                                                                label="Describe water related issues that make this work necessary"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="til_rec_water"
                                                                label="Describe receiving waters"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <RadioGroup
                                                                field="til_land_ass"
                                                                disabled={is_read_only}
                                                                fullWidth
                                                                options={[
                                                                    { label: "Yes", value: "Yes" },
                                                                    { label: "No", value: "No" },
                                                                ]}
                                                                alignment={true}
                                                                label="Land is assessed to legal ditch system"
                                                                eventHandle={e=>{
                                                                    this.setState({selectLegal:e === "Yes"? true : false})
                                                                }}
                                                            />
                                                        </Grid> 
                                                        {selectLegal && (
                                                            <Grid item xs={6}>
                                                                <Select
                                                                    // disabled={selectLegal }
                                                                    field="til_legal_ditch"
                                                                    label="Select legal ditch system"
                                                                    options={MAKE_OPTIONS(["Roseau County Ditch No. 8","Roseau County Ditch No. 16","State Ditch No. 51","Watershed Ditch No. 3"])}
                                                                    fullWidth
                                                                />
                                                            </Grid> 
                                                        )}
                                                        <Grid item xs={6}>
                                                            <RadioGroup
                                                                field="til_olet"
                                                                disabled={is_read_only}
                                                                fullWidth
                                                                options={[
                                                                    { label: "Yes", value: "Yes" },
                                                                    { label: "No", value: "No" },
                                                                ]}
                                                                alignment={true}
                                                                label="Tile outlets across property not owned by landowner or applicant"
                                                            />
                                                        </Grid> 
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="project_dname" label="Project Designer Name" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="project_dphone" label="Project Designer Phone" fullWidth usePhoneNumberFormat/>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="project_demail" label="Project Designer Email" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="project_iname" label="Project Installer Name" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="project_iphone" label="Project Installer Phone" fullWidth usePhoneNumberFormat/>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="project_iemail" label="Project Installer Email" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="addinfo" label="Additional Information about Tiling Project" fullWidth />
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )}
                                    {activeStep === 4 && projectType.length > 0 && projectType.find(t=>t==="Surface Drainage (New Ditch or Improvement)") && (   
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                           <Accordion defaultExpanded>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        Surface Drainage (New Ditch or Improvement)
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <Select
                                                                disabled={is_read_only}
                                                                field="sur_drainage"
                                                                label="Surface Drainage Project involves:"
                                                                options={MAKE_OPTIONS(["Construction of new ditch","Improvement of existing ditch"])}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="sur_depth"
                                                                label="Existing ditch depth (max 50)"
                                                                fullWidth
                                                                units="feet"
                                                                useNumberFormat3
                                                                maxValue={50}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="sur_width"
                                                                label="Existing ditch width (max 1000)"
                                                                fullWidth
                                                                units="feet"
                                                                useNumberFormat3
                                                                maxValue={1000}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Select
                                                                disabled={is_read_only}
                                                                field="sur_slopes"
                                                                label="Existing ditch side slopes"
                                                                options={MAKE_OPTIONS(["3H:1V","4H:1V","5H:1V","6H:1V"])}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="sur_pdepth"
                                                                label="Proposed ditch depth (max 50)"
                                                                fullWidth
                                                                units="feet"
                                                                useNumberFormat3
                                                                maxValue={50}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="sur_pwidth"
                                                                label="Proposed ditch width (max 1000)"
                                                                fullWidth
                                                                units="feet"
                                                                useNumberFormat3
                                                                maxValue={1000}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Select
                                                                disabled={is_read_only}
                                                                field="sur_pslopes"
                                                                label="Proposed ditch side slopes"
                                                                options={MAKE_OPTIONS(["3H:1V","4H:1V","5H:1V","6H:1V"])}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="sur_how_grade"
                                                                label="How will grade-line of ditch be established?"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="sur_spoil"
                                                                label="Where will spoil be placed?"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Select
                                                                disabled={is_read_only}
                                                                field="sur_outlet"
                                                                label="Outlet is:"
                                                                options={MAKE_OPTIONS(["Natural Stream / River","Roadside Ditch","Legal Ditch","Applicants Field","Other"])}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="sur_outlet_other"
                                                                label="If is Outlet Other, Explain"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <RadioGroup
                                                                field="sur_ditch_outlet"
                                                                disabled={is_read_only}
                                                                fullWidth
                                                                options={[
                                                                    { label: "Yes", value: "Yes" },
                                                                    { label: "No", value: "No" },
                                                                ]}
                                                                alignment={true}
                                                                label="Ditch outlets across property not owned by landowner or applicant"
                                                            />
                                                        </Grid> 
                                                        <Grid item xs={6}>
                                                            <RadioGroup
                                                                field="sur_land_ass"
                                                                disabled={is_read_only}
                                                                fullWidth
                                                                options={[
                                                                    { label: "Yes", value: "Yes" },
                                                                    { label: "No", value: "No" },
                                                                ]}
                                                                alignment={true}
                                                                label="Land is assessed to legal ditch system"
                                                            />
                                                        </Grid> 
                                                        <Grid item xs={6}>
                                                            <Select
                                                                disabled={is_read_only}
                                                                field="sur_legal_ditch"
                                                                label="Legal ditch system"
                                                                options={MAKE_OPTIONS(["Roseau County Ditch No. 8","Roseau County Ditch No. 16","State Ditch No. 51","Watershed Ditch No. 3"])}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="sur_addinfo" label="Additional Information about Surface Drainage Project" fullWidth />
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )}

                                    {activeStep === 4 && projectType.length > 0 && projectType.find(t=>t==="Culvert Installation / Removal / Modification") && (   
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                           <Accordion defaultExpanded>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        Culvert Installation
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 27, backgroundColor: "f6f0f0" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <Select
                                                                disabled={is_read_only}
                                                                field="cul_involves"
                                                                label="Culvert Project involves:"
                                                                options={MAKE_OPTIONS(["removal of existing culverts","installation of new culverts", "modification of existing culverts"])}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="cul_num"
                                                                label="Number of culverts installed, removed or modified: (minimum 0, maximum 100)"
                                                                fullWidth
                                                                units="count"
                                                                useNumberFormat3
                                                                maxValue={100}
                                                                minValue={0}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="cul_info"
                                                                label="Additional Information for Culvert Project"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}> 
                                                            <RadioGroup
                                                                disabled={is_read_only}
                                                                field="culroad_involves"
                                                                label="Does Project Involve Road Grading that will modify elevations?"
                                                                fullWidth
                                                                options={[
                                                                    { label: "Yes", value: "Yes" },
                                                                    { label: "No", value: "No" },
                                                                ]}
                                                                alignment={true}
                                                                />
                                                        </Grid> 
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="culroad_elev"
                                                                label="If Project modifies road elevations, please describe proposed elevations?"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                                <AccordionDetails style={{ paddingBottom: 27, backgroundColor: "f6f0f0" }}>
                                                    <Grid container spacing={2}>
                                                        <Paper className={classes.root}>
                                                            <Button
                                                                fullWidth
                                                                disabled={is_read_only}
                                                                className={classes.gutterBottom}
                                                                gutterBottom={true}
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => {
                                                                        this.setState({
                                                                            culvertDialogOpen: true,
                                                                            culvertEditing: null,
                                                                            culvertFormKey: window.performance.now(),
                                                                        })
                                                                }}>
                                                                <AddIcon className={classes.iconAdd} />
                                                                &nbsp;&nbsp;&nbsp;Create New
                                                            </Button>   
                                                            <Table className={classes.table}>
                                                                <EnhancedTableHead
                                                                    order={order}
                                                                    orderBy={orderBy}
                                                                    onRequestSort={this.handleRequestSort}
                                                                    columnData={culvertHeader}
                                                                />
                                                                <TableBody>
                                                                    {(() => {
                                                                        if (tempCulverts.length < 1) {
                                                                            return (
                                                                                <TableRow>
                                                                                    <CustomTableCell colSpan={culvertHeader.length} className={classes.centerAlign}>
                                                                                        No Records Available
                                                                                    </CustomTableCell>
                                                                                </TableRow>
                                                                            );
                                                                        }

                                                                        return tempCulverts.map((n) => (
                                                                            <TableRow hover key={ n.id }>
                                                                                <CustomTableCell className={ classes.nowrap }>
                                                                                    <Tooltip title="Edit">
                                                                                        {/* EDIT RECORD will trigger createRepeatableField class*/ }
                                                                                        <Button
                                                                                            onClick={ () => {
                                                                                                this.setState({
                                                                                                    culvertEditing: n,
                                                                                                    culvertDialogOpen:true,
                                                                                                    culvertFormKey: window.performance.now()
                                                                                                });
                                                                                            } }
                                                                                         
                                                                                            className={ classes.deleteWidth }>
                                                                                            <EditIcon color="primary" />
                                                                                        </Button>
                                                                                    </Tooltip>

                                                                                    <Tooltip title="Delete">
                                                                                        <Button
                                                                                            disabled={is_read_only}
                                                                                            onClick={() =>
                                                                                                this.setState({ tempCulverts: tempCulverts.filter((tc) => tc !== n)})
                                                                                            }
                                                                                            className={ classes.deleteWidth }>
                                                                                            <DeleteIcon color="inherit" />
                                                                                            
                                                                                        </Button>
                                                                                    </Tooltip>
                                                                                </CustomTableCell>
                                                                                {/* PRINTING OF THE DATA IN THE TABLE */ }
                                                                                <CustomTableCell>{n.name}</CustomTableCell>
                                                                                <CustomTableCell>{n.e_size}</CustomTableCell>
                                                                                <CustomTableCell>{n.e_length}</CustomTableCell>
                                                                                <CustomTableCell>{n.e_elevation}</CustomTableCell>
                                                                                <CustomTableCell>{n.p_size}</CustomTableCell>
                                                                                <CustomTableCell>{n.p_length}</CustomTableCell>
                                                                                <CustomTableCell>{n.p_elevation}</CustomTableCell>
                                                                                <CustomTableCell>{n.additional}</CustomTableCell> 
                                                                            </TableRow>
                                                                        ));
                                                                    })()}
                                                                </TableBody>
                                                            </Table>
                                                        </Paper>
                                                    </Grid> 
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )}
                                    {activeStep === 4 && projectType.length > 0 && projectType.find(t=>t==="Bridge Installation / Removal / Modification") && (   
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                           <Accordion defaultExpanded>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        Bridge Installation / Removal / Modification
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}> 
                                                            <RadioGroup
                                                                disabled={is_read_only}
                                                                field="brroad_involves"
                                                                label="Does Project Involve Road Grading that will modify elevations?"
                                                                fullWidth
                                                                options={[
                                                                    { label: "Yes", value: "Yes" },
                                                                    { label: "No", value: "No" },
                                                                ]}
                                                                alignment={true}
                                                                />
                                                        </Grid> 
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="brroad_elev"
                                                                label="If Project modifies road elevations, please describe proposed elevations?"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Select
                                                                disabled={is_read_only}
                                                                field="br_involves"
                                                                label="Bridge Project involves:"
                                                                options={MAKE_OPTIONS(["removal of existing bridge","installation of new bridge", "modification of existing bridge"])}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="br_ex1width"
                                                                label="Existing bridge 1 width (max 200)"
                                                                fullWidth
                                                                units="feet"
                                                                useNumberFormat3 
                                                                maxValue={200}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="br_ex1len"
                                                                label="Existing bridge 1 length (max 5000)"
                                                                fullWidth
                                                                units="feet"
                                                                useNumberFormat3 
                                                                maxValue={5000}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="br_ex1elev"
                                                                label="Existing bridge 1 elevation (max 1500)"
                                                                fullWidth
                                                                units="feet"
                                                                useNumberFormat3
                                                                maxValue={1500} 
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="br_p1width"
                                                                label="Proposed bridge 1 width (max 200)"
                                                                fullWidth
                                                                units="feet"
                                                                useNumberFormat3
                                                                maxValue={200} 
                                                            />
                                                        </Grid>
                                                    
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="br_p1len"
                                                                label="Proposed bridge 1 length (max 5000)"
                                                                fullWidth
                                                                units="feet"
                                                                useNumberFormat3
                                                                maxValue={5000} 
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="br_p1elev"
                                                                label="Proposed bridge 1 elevation (max 1500)"
                                                                fullWidth
                                                                units="feet"
                                                                useNumberFormat3 
                                                                maxValue={1500}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="br_ex2width"
                                                                label="Existing bridge 2 width (max 200)"
                                                                fullWidth
                                                                units="feet"
                                                                useNumberFormat3 
                                                                maxValue={200}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="br_ex2len"
                                                                label="Existing bridge 2 length (max 5000)"
                                                                fullWidth
                                                                units="feet"
                                                                useNumberFormat3 
                                                                maxValue={5000}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="br_ex2elev"
                                                                label="Existing bridge 2 elevation (max 1500)"
                                                                fullWidth
                                                                units="feet"
                                                                useNumberFormat3 
                                                                maxValue={1500}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="br_p2width"
                                                                label="Proposed bridge 2 width (max 200)"
                                                                fullWidth
                                                                units="feet"
                                                                useNumberFormat3 
                                                                maxValue={200}
                                                            />
                                                        </Grid>
                                                    
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="br_p2len"
                                                                label="Proposed bridge 2 length (max 5000)"
                                                                fullWidth
                                                                units="feet"
                                                                useNumberFormat3 
                                                                maxValue={5000}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="br_p2elev"
                                                                label="Proposed bridge 2 elevation (max 1500)"
                                                                fullWidth
                                                                units="feet"
                                                                useNumberFormat3 
                                                                maxValue={1500}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="br_info"
                                                                label="Additional Information for Bridge Proposed Project"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                    </Grid> 
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )} 

                                    {activeStep === 4 && projectType.length > 0 && projectType.find(t=>t==="Wetland Restoration / Water Retention Related Structures / Water and Sediment Control Basins") && (   
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                           <Accordion defaultExpanded>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        Wetland Restoration / Water Retention Related Structures / Water and Sediment Control Basins
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <RadioGroup
                                                                field="wet_proj"
                                                                disabled={is_read_only}
                                                                fullWidth
                                                                options={[
                                                                    { label: "Yes", value: "Yes" },
                                                                    { label: "No", value: "No" },
                                                                ]}
                                                                alignment={true}
                                                                label="Project is mitigation for another project"
                                                            />
                                                        </Grid> 
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="wet_desc"
                                                                label="If yes, describe project"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <RadioGroup
                                                                disabled={is_read_only}
                                                                field="wroad_involves"
                                                                label="Does Project Involve Road Grading that will modify elevations?"
                                                                fullWidth
                                                                options={[
                                                                    { label: "Yes", value: "Yes" },
                                                                    { label: "No", value: "No" },
                                                                ]}
                                                                alignment={true}
                                                            />
                                                        </Grid> 
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="wroad_elev"
                                                                label="If Project modifies road elevations, please describe proposed elevations?"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Select
                                                                disabled={is_read_only}
                                                                field="wet_dat"
                                                                label="Datum used for plan development"
                                                                options={MAKE_OPTIONS(["Local","NGVD29", "NAVD88"])}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                      
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="wet_op_plan"
                                                                label="Describe operation plan"
                                                                fullWidth
                                                            />
                                                        </Grid> 
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="wet_dname"
                                                                label="Project Designer Name"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="wet_dphone"
                                                                label="Project Designer Phone"
                                                                fullWidth
                                                                usePhoneNumberFormat
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="wet_demail"
                                                                label="Project Designer Email"
                                                                fullWidth
                                                            />
                                                        </Grid> 
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="wet_info"
                                                                label="Additional Information for Wetland or Water Project"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )}
                                    {activeStep === 4 && projectType.length > 0 && projectType.find(t=>t==="Road Grading") && (   
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                           <Accordion defaultExpanded>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        Road Grading
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <ReactSelect
                                                                label="Road Grading project involves:"
                                                                field="road_involves"
                                                                options={MAKE_OPTIONS([
                                                                    "Roadway elevations will be modified","Project involves removal / installation or modification of culverts",
                                                                    "Project involves removal / installation or modification of bridge",
                                                                    "Project involves wetland restoration / water retention structures or water and sediment control basins"
                                                                ])}
                                                                disabled={is_read_only}
                                                                isMulti={true}
                                                                height="auto"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                      
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="road_info"
                                                                label="Additional Information for Road Grading project"
                                                                fullWidth
                                                            />
                                                        </Grid> 
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )}
                                      {activeStep === 4 && projectType.length > 0 && projectType.find(t=>t==="Dike / Levee") && (   
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                           <Accordion defaultExpanded>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        Dike / Levee
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <Select
                                                                disabled={is_read_only}
                                                                field="dike_involves"
                                                                label="Dike / Levee Project Involves:"
                                                                options={MAKE_OPTIONS(["New Dike","Modification of Existing Dike" ])}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <RadioGroup
                                                                field="dike_includes"
                                                                disabled={is_read_only}
                                                                fullWidth
                                                                options={[
                                                                    { label: "Yes", value: "Yes" },
                                                                    { label: "No", value: "No" },
                                                                ]}
                                                                alignment={true}
                                                                label="Project includes construction of ring dike"
                                                            />
                                                        </Grid> 
                                                        <Grid item xs={6}>
                                                            <Select
                                                                disabled={is_read_only}
                                                                field="dike_ring"
                                                                label="Ring dike will tie into:"
                                                                options={MAKE_OPTIONS(["Dike","Roadway", "High Ground", "Other"])}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                      
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="dike_other"
                                                                label="If Other, please describe:"
                                                                fullWidth
                                                            />
                                                        </Grid> 
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="dike_purpose"
                                                                label="Describe purpose of proposed dike/levee"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="dike_elevation"
                                                                label="Describe how elevation of dike was determined"
                                                                fullWidth
                                                                useNumberFormat3 
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Select
                                                                disabled={is_read_only}
                                                                field="dike_datum"
                                                                label="Datum used for plan development"
                                                                options={MAKE_OPTIONS(["Local","NGVD29", "NAVD88"])}
                                                                fullWidth
                                                            />
                                                        </Grid> 
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="dike_len"
                                                                label="Dike Length (max 20000)"
                                                                fullWidth
                                                                units="feet"
                                                                useNumberFormat3
                                                                maxValue={20000}
                                                            />
                                                        </Grid> 
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="dike_twidth"
                                                                label="Top Width (max 50)"
                                                                fullWidth
                                                                units="feet"
                                                                useNumberFormat3
                                                                maxValue={50}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="dike_slopeint"
                                                                label="Side Slopes Interior"
                                                                fullWidth
                                                                units="H:1V"
                                                            />
                                                        </Grid> 
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="dike_slopeext"
                                                                label="Side Slopes Exterior"
                                                                fullWidth
                                                                units="H:1V"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="dike_maxheight"
                                                                label="Maximum height"
                                                                fullWidth
                                                                units="feet tall"
                                                                useNumberFormat3      
                                                            />
                                                        </Grid> 
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="dike_maxelev"
                                                                label="Maximum Elevation"
                                                                fullWidth
                                                                units="feet msl"
                                                                useNumberFormat3 
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="dike_minheight"
                                                                label="Minimum height"
                                                                fullWidth
                                                                units="feet tall"
                                                                useNumberFormat3 
                                                            />
                                                        </Grid> 
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="dike_minelev"
                                                                label="Minimum elevation"
                                                                fullWidth
                                                                units="feet msl"
                                                                useNumberFormat3 
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <RadioGroup
                                                                field="dike_affect"
                                                                disabled={is_read_only}
                                                                fullWidth
                                                                options={[
                                                                    { label: "Yes", value: "Yes" },
                                                                    { label: "No", value: "No" },
                                                                ]}
                                                                alignment={true}
                                                                label="Dike will flood or adversely affect adjacent, upstream, or downstream landowners"
                                                            />
                                                        </Grid>
                                                      
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="dike_impact"
                                                                label="If yes, describe how impacts will be addressed"
                                                                fullWidth
                                                            />
                                                        </Grid> 
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="dike_info"
                                                                label= "Additional Information for Dike / Levee project"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )}       
                                    {activeStep === 4 && projectType.length > 0 && projectType.find(t=>t==="Channel Stabilization / Restoration / Erosion Control / Shoreland Projects") && (   
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                           <Accordion defaultExpanded>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        Channel Stabilization / Restoration / Erosion Control / Shoreland Projects
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <ReactSelect
                                                                label="Channel Stabilization / Restoration / Erosion Control / Shoreland Project Involves:"
                                                                field="ch_involves"
                                                                options={MAKE_OPTIONS([
                                                                    "Project includes channel or streambank stabilization",
                                                                    "Project includes river or stream restoration",
                                                                    "Project lake shore stabilization"
                                                                ])}
                                                                disabled={is_read_only}
                                                                isMulti={true}
                                                                height="auto"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                      
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="ch_info"
                                                                label="Additional Information for Channel Stabilization / Restoration / Erosion Control / Shoreland Project"
                                                                fullWidth
                                                            />
                                                        </Grid> 
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )}
                                    
                                    {activeStep === 4 && projectType.length > 0 && projectType.find(t=>t==="Land Development") && (   
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                           <Accordion defaultExpanded>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        Land Development
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="l_prjacres"
                                                                label="Acres of project site:"
                                                                fullWidth
                                                                units="acres"
                                                                useNumberFormat3 
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="l_distacres"
                                                                label="Acres of land disturbance"
                                                                fullWidth
                                                                units="acres"
                                                                useNumberFormat3 
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <RadioGroup
                                                                field="l_stormsewer"
                                                                disabled={is_read_only}
                                                                fullWidth
                                                                options={[
                                                                    { label: "Yes", value: "Yes" },
                                                                    { label: "No", value: "No" },
                                                                ]}
                                                                alignment={true}
                                                                label="Project is a municipal storm sewer project"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <RadioGroup
                                                                field="l_sansewer"
                                                                disabled={is_read_only}
                                                                fullWidth
                                                                options={[
                                                                    { label: "Yes", value: "Yes" },
                                                                    { label: "No", value: "No" },
                                                                ]}
                                                                alignment={true}
                                                                label="Project is a municipal sanitary sewer or lagoon project"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <RadioGroup
                                                                field="l_runoff"
                                                                disabled={is_read_only}
                                                                fullWidth
                                                                options={[
                                                                    { label: "Yes", value: "Yes" },
                                                                    { label: "No", value: "No" },
                                                                ]}
                                                                alignment={true}
                                                                label="Project design incorporates stormwater ponding or other runoff reduction methods"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="l_explain"
                                                                label="If not yes, explain why:"
                                                                fullWidth 
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="l_info"
                                                                label="Additional Information for Land Development Project"
                                                                fullWidth
                                                            />
                                                        </Grid> 
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )}
                                    {activeStep === 4 && projectType.length > 0 && projectType.find(t=>t==="Utility Installations") && (   
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                           <Accordion defaultExpanded>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        Utility Installation
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <RadioGroup
                                                                field="u_buried"
                                                                disabled={is_read_only}
                                                                fullWidth
                                                                options={[
                                                                    { label: "Yes", value: "Yes" },
                                                                    { label: "No", value: "No" },
                                                                ]}
                                                                alignment={true}
                                                                label="Utility is buried"
                                                            />
                                                        </Grid>
                                                       
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="u_selected"
                                                                label="If yes, what is depth of cover over top of utility? (minimum depth is 48 inches below waterways and ditches)"
                                                                fullWidth
                                                                units="inches"
                                                                useNumberFormat3
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="u_info"
                                                                label="Additional Information for Utility Installation Project"
                                                                fullWidth
                                                            />
                                                        </Grid> 
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )}
                    
                                    {activeStep === "PRINT" && (
                                        <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                            <Divider className={classes.printDivider} />
                                        </Grid>
                                    )}
                                    {activeStep === 5 && ( 
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Typography variant="subtitle1">Attachments</Typography>
                                            <Typography gutterBottom>
                                                
                                                Please attached all electronic files associated with your applications. If document is not available for upload, 
                                                it shall be emailed to the RRWD at <a
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                href='mailto:rrwd@mncable.net'>
                                                                rrwd@mncable.net
                                                            </a>{" "}. Application will not be reviewed or considered complete until appropriate documents have been submitted. 
                                                Below is the list of expected documents and the information those documents should contain
                                            </Typography>
                                            <Button
                                                onClick={() =>
                                                    this.setState({
                                                        attachmentDialogOpen: true,
                                                        attachmentEditing: null,
                                                        attachmentFormKey: window.performance.now(),
                                                    })
                                                }
                                                disabled={is_read_only}
                                                variant="contained"
                                                color="primary">
                                                Add Attachment
                                            </Button>
                                            <Table style={{ marginTop: 8 }}>
                                                <EnhancedTableHead
                                                    columnData={columnDataAttachments}
                                                    order={attachmentOrder}
                                                    orderBy={attachmentOrderBy}
                                                    onRequestSort={this.handleRequestSort2}
                                                />
                                                <TableBody>
                                                    {temp_attachments.map((n,idx) => {
                                                        return (
                                                            <TableRow key={idx} hover>
                                                                <CustomTableCell className={classes.nowrap}>
                                                                    {n.file.includes("https") && (
                                                                        <Tooltip title="Open Link">
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={() => {
                                                                                    window.open(n.file);
                                                                                }}
                                                                                className={classes.deleteWidth}>
                                                                                <ViewIcon color="primary" />
                                                                            </Button>
                                                                        </Tooltip>
                                                                    )}
                                                                    <Tooltip title="Delete">
                                                                        <Button
                                                                            color="primary"
                                                                            onClick={() =>
                                                                                this.setState({
                                                                                    temp_attachments: temp_attachments.filter((tc) => tc !== n),
                                                                                })
                                                                            }
                                                                            disabled={is_read_only}
                                                                            className={classes.deleteWidth}>
                                                                            <DeleteIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </CustomTableCell>
                                                                <CustomTableCell>{n.name}</CustomTableCell>
                                                                <CustomTableCell>{n._type}</CustomTableCell>
                                                                <CustomTableCell>{n.notes}</CustomTableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                    {temp_attachments.length < 1 && (
                                                        <TableRow>
                                                            <CustomTableCell colSpan={columnDataAttachments.length} className={classes.centerAlign}>
                                                                No Attachments Added
                                                            </CustomTableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                         
                                    )}
                                    {activeStep === 5 && attachmentTypes.length > 0 && attachmentTypes.find(a=>a.includes("Tiling")) && ( 
                                         <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Accordion expanded>
                                                <AccordionSummary style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                    <b>Tiling Documents to Upload:</b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            1.) Site Map must include: <br/> 
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            a.) Location of tile lines.
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            b.) Size of tile.
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            c.) Location of outlets(s).
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            d.) Location of lift station(s).
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            e.) Location of surface inlet(s).
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            f.) Location of other special features
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            g.) Receiving waters.
                                                            <br/>
                                                        </Typography>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            2.) Downstream Landowner Notification, if applicable. 
                                                        </Typography>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )}
                                    {activeStep === 5 && attachmentTypes.length > 0 && attachmentTypes.find(a=>a.includes("Surface")) && ( 
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Accordion expanded>
                                                <AccordionSummary style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        <b>Surface Drainage (New Ditch or Improvement) Documents to Upload:</b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            1.) Site Map must include:<br/> 
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            a.) Location of existing and proposed ditch.
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            b.) Location of spoil pile.
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            c.) Location of outlet.
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            d.) Location of other special features.
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            e.) Receiving waters.     <br/>
                                                        </Typography>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                             2.) Downstream Landowner Notification, if applicable. 
                                                        </Typography>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )}
                                    {activeStep === 5 && attachmentTypes.length > 0 && attachmentTypes.find(a=>a.includes("Culvert")) && ( 
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Accordion expanded>
                                                <AccordionSummary style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        <b>Culvert Installation Documents to Upload:</b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            1.) Site Map must include:<br/> 
                                                        </Typography> 
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            a.) Location of existing and proposed culverts .
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            b.) Size of existing and proposed culverts.   <br/>
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            c.) Location of upstream and downstream culverts and bridges.   <br/>
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            d.) Size of upstream and downstream culverts and bridges.   <br/>
                                                        </Typography>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                             2.) Downstream Landowner Notification, if applicable. 
                                                        </Typography>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )}
                                    {activeStep === 5 && attachmentTypes.length > 0 && attachmentTypes.find(a=>a.includes("Bridge")) && ( 
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Accordion expanded>
                                                <AccordionSummary style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        <b>Bridge Installation Documents to Upload:</b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            1.) Site Map must include:<br/> 
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            a.) Location of existing and proposed culverts.
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            b.) Size of existing and proposed culverts.
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            c.) Location of upstream and downstream culverts and bridges.
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            d.) Size of upstream and downstream culverts and bridges.
                                                        </Typography>
                                               
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            2.) Hydraulic analysis showing existing and proposed hydraulic conditions. 
                                                        </Typography>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            3.) Size, type, location of adjacent upstream and downstream structures. 
                                                        </Typography>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            4.) Maximum previously observed highwater elevation at site and time frame
                                                        </Typography>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            5.) Detailed project construction plans with profile, cross sections, plan views, details. 
                                                        </Typography>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )}
                                    {activeStep === 5 && attachmentTypes.length > 0 && attachmentTypes.find(a=>a.includes("Wetland")) && ( 
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Accordion expanded>
                                                <AccordionSummary style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        <b>Wetland Restoration Documents to Upload:</b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            1.) Detailed project construction plans.<br/> 
                                                        </Typography>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            2.) Site Map must include:<br/> 
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            a.) Location of proposed wetland basins, water retention areas.
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            b.) Locations of ditch plugs, scrapes, embankments, other related features.
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            c.) Property lines.
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            d.) Maximum pool elevation boundaries.
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            e.) Top of structure elevation.   <br/>
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            f.) Location of proposed tile lines.
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            g.) Location of outlet structure(s).
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            h.) Location of other special features.
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            i.) Receiving waters.   <br/>
                                                        </Typography>
                                                       
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )}
                                    {activeStep === 5 && attachmentTypes.length > 0 && attachmentTypes.find(a=>a.includes("Road")) && ( 
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Accordion expanded>
                                                <AccordionSummary style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        <b>Road Grading Documents to Upload:</b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            1.) Site Map must include:<br/> 
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            a.) Location of existing and proposed ditch.
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            b.) Location of spoil pile.
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            c.) Location of outlet(s).
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            d.) Location of existing and proposed culverts and bridges.
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            e.) Size of existing and proposed culverts and brides.   <br/>
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            f.) Location of upstream and downstream culverts and bridges.   <br/>
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            g.) Size of upstream and downstream culverts and bridges.   <br/>
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            h.) Location of proposed wetland basins, water retention areas.   <br/>
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            i.) Locations of ditch plugs, scrapes, embankments, other related features.   <br/>
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            j.) Property lines.   <br/>
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            k.) Maximum pool elevation boundaries.   <br/>
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            l.) Location of proposed tile lines.   <br/>
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            m.) Location of other special features.   <br/>
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            n.) Receiving waters.  <br/>
                                                        </Typography>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                             2.) Downstream Landowner Notification, if applicable. 
                                                        </Typography>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )}
                                    {activeStep === 5 && attachmentTypes.length > 0 && attachmentTypes.find(a=>a.includes("Dike")) && ( 
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Accordion expanded>
                                                <AccordionSummary style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        <b>Dike Documents to Upload:</b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            1.) Detailed construction plans.<br/> 
                                                        </Typography>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            2.) Site Map must include:<br/> 
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            a.) Location of proposed dike.
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            b.) Elevation of proposed dike.
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            c.) Location of other special features.
                                                        </Typography>
                                                     
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )}
                                    {activeStep === 5 && attachmentTypes.length > 0 && attachmentTypes.find(a=>a.includes("Channel")) && ( 
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Accordion expanded>
                                                <AccordionSummary style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        <b>Channel Stabilization Documents to Upload:</b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            1.) Detailed project plans.<br/> 
                                                        </Typography>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            2.) Site Map, must include:<br/> 
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            a.) Location of proposed work.
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            b.) Location of other special features.
                                                        </Typography> 
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )}
                                    {activeStep === 5 && attachmentTypes.length > 0 && attachmentTypes.find(a=>a.includes("Land Dev")) && ( 
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Accordion expanded>
                                                <AccordionSummary style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        <b>Land Documents to Upload:</b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            1.) Detailed construction plans.<br/> 
                                                        </Typography>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            2.) Site Map, must include:<br/> 
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            a.) Location of proposed work.
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            b.) Location of other special features.
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            c.) Receiving waters.
                                                        </Typography>  
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            3.) Stormwater Report showing modeling results of pond design.<br/> 
                                                        </Typography>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            4.) Stormwater Pollution Prevention Plan (SWPPP).<br/> 
                                                        </Typography> 
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )}
                                    {activeStep === 5 && attachmentTypes.length > 0 && attachmentTypes.find(a=>a.includes("Stormwater")) && ( 
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Accordion expanded>
                                                <AccordionSummary style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        <b>Stormwater Documents to Upload:</b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            1.) Site Map must include:<br/> 
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            a.) Location of existing and proposed ditch.
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            b.) Location of spoil pile.
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            c.) Location of outlet.
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            d.) Location of other special features.
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            e.) Receiving waters.     <br/>
                                                        </Typography>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                             2.) Downstream Landowner Notification, if applicable. 
                                                        </Typography>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )}
                                    {activeStep === 5 && attachmentTypes.length > 0 && attachmentTypes.find(a=>a.includes("Utility")) && ( 
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Accordion expanded>
                                                <AccordionSummary style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        <b>Utility Documents to Upload:</b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            1.) Detailed project plans.<br/> 
                                                        </Typography>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            2.) Site Map, must include:<br/> 
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            a.) Location of proposed work.
                                                        </Typography>
                                                        <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                            b.) Location of other special features.
                                                        </Typography> 
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )}
                                   
                                    {activeStep === "PRINT" && (
                                        <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                            <Divider className={classes.printDivider} />
                                        </Grid>
                                    )}
                                    {activeStep === 6 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Typography variant="subtitle1" gutterBottom>
                                                <b>I understand:</b>
                                            </Typography>
                                            <table>
                                                {" "}
                                                <tr>
                                                    <td>
                                                        <Typography gutterBottom style={{marginBottom:3, marginLeft:3}}>
                                                            1. Submitting this application in no way relieves the applicant or landowner from any 
                                                            responsibility or liability resulting from the construction, operation, or failure of the project.
                                                        </Typography> 
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Typography gutterBottom style={{marginBottom:3, marginLeft:3}}>
                                                            2. The project may be field reviewed prior to the Board Meeting. 
                                                            The applicant grants permission to the RRWD and their representatives to review the work area within the permit application.
                                                        </Typography> 
                                                    </td>
                                                </tr> 
                                                <tr>
                                                    <td>
                                                        <Typography gutterBottom style={{marginBottom:3, marginLeft:3}}>
                                                            3. The application must be considered complete a minimum of 10 days prior to the Regular Board
                                                            Meeting to be considered. RRWD staff will contact you within 15 days if additional information is required.
                                                        </Typography> 
                                                    </td>
                                                </tr> 
                                                <tr>
                                                    <td>
                                                        <Typography gutterBottom style={{marginBottom:3, marginLeft:3}}>
                                                            4. The requirements of the Permit Rules of the RRWD. (A copy of the rules can be found at <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="http://www.roseauriverwd.com/pdf/Policies/rules.pdf"
                                                    >
                                                    Roseau River Watershed District Rules
                                                </a>).
                                                        </Typography> 
                                                    </td>
                                                </tr> 
                                                <tr>
                                                    <td>
                                                        <Typography gutterBottom style={{marginBottom:3, marginLeft:3}}>
                                                            5. This permit does not relieve the applicant of any requirements of other permits which may be necessary 
                                                            from any other permitting agency.
                                                        </Typography> 
                                                    </td>
                                                </tr> 
                                                <tr>
                                                    <td>
                                                        <Typography gutterBottom style={{marginBottom:3, marginLeft:3}}>
                                                            6. I acknowledge by submitting this permit application is equivalent to my manual/handwritten signature.
                                                        </Typography> 
                                                    </td>
                                                </tr>  
                                            </table>
                                            <table>
                                                <tr>
                                                    <td style={{ whiteSpace: "nowrap", paddingRight: 16 }}>
                                                        <Checkbox disabled={is_read_only} label="I AGREE *" field="understand1" />
                                                    </td>
                                                    <td>
                                                        <Typography gutterBottom style={{marginBottom:0}}>
                                                            In checking this box, I acknowledge the above statements.
                                                        </Typography> 
                                                    </td>
                                                </tr> 
                                            </table>
                                        </Grid>
                                    )}
                                    {activeStep !== "PRINT" && (
                                        <Grid item xs={12} xl={10} className={classes.rightAlign} classes={{ root: classes.root }}>
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={() => this.setState({ activeStep: activeStep - 1 })}
                                                className={classes.button}>
                                                Back
                                            </Button>
                                            <Button
                                                onClick={() => this.handleNext()}
                                                disabled={is_read_only && activeStep === 5}
                                                variant="contained"
                                                color="primary"
                                                style={{ marginLeft: 16 }}>
                                                {activeStep === 6 ? "Submit Permit Application" : "Next"}
                                            </Button>
                                            {/* <Button
                                                style={{ marginLeft: 16 }}
                                                onClick={() => {
                                                    return (
                                                        // this.setState({ activeStep: "PRINT" }),
                                                        window.open("/reports/application/" + application.hash)
                                                    );
                                                }}
                                                variant="contained"
                                                color="primary">
                                                Print
                                            </Button> */}
                                        </Grid>
                                    )}
                                </Grid>
                            </>
                        </form>
                    )}
                </Form>
                <Dialog open={attachmentDialogOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.flex}>
                            Attachment
                        </Typography>
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({ attachmentDialogOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <DialogContentText>
                            <Form
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                key={attachmentFormKey}
                                defaultValues={attachmentEditing}
                                validateError={this.errorValidatorAttachment}
                                onSubmit={(values) => this.addAttachment(values)}>
                                {(formApi) => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography gutterBottom>
                                                    Only these file types can be directly uploaded as an attachment. All other file types should be
                                                    put into a .zip file and then uploaded. Direct supported file types are: PDF (.pdf), Microsoft
                                                    Word (.doc, .docx), Microsoft Excel (.xls, .xlsx), Images (.png, .jpg, .jpeg, .tiff, .bmp),
                                                    Microsoft Powerpoint (.ppt, .pptx), Zip files (.zip).
                                                </Typography>
                                                <FileInput field="file" id="file_upl" fullWidth label="File *" />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField field="name" label="Name *" fullWidth />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Select
                                                    field="_type"
                                                    label="Type"
                                                    options={MAKE_OPTIONS(["Site Map", "Downstream Landownder Notification", "Engineer's Report", "Detailed Construction Plans",
                                                            "Stormwater Report Showing MOdeling Results of Pond Design", "Stormwater Pollution Prevention Plan (SWPPP)", "Detailed Project Plans",
                                                            "Photo", "Other"])}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField field="notes" label="Notes" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth onClick={() => this.setState({ attachmentDialogOpen: false })}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog open={culvertDialogOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.flex}>
                            Add Culvert
                        </Typography>
 
                        
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({ culvertDialogOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <Toolbar>
                        <Typography variant="h7" className={classes.flex} style={{marginTop:-10}}>
                            Note: If existing culvert is arch pipe or box culvert please include dimensions in Additional Information.
                        </Typography>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <DialogContentText>
                            <Form
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                key={formKey}
                                defaultValues={culvertEditing}
                                validateError={this.errorValidatorCulvert}
                                onSubmit={(values) => this.addCulvert(values)}>
                                {(formApi) => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <TextField disabled={is_read_only}  field="name" label="Culvert Name or ID" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField  disabled={is_read_only} field="e_size" label="Existing culvert size" fullWidth units="inches" useNumberFormat3/>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField  disabled={is_read_only} field="e_length" label="Existing culvert length" fullWidth units="feet" useNumberFormat3 />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField  disabled={is_read_only} field="e_elevation" label="Existing culvert elevation" fullWidth units="feet" useNumberFormat3/>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField  disabled={is_read_only} field="p_size" label="Proposed culvert size" fullWidth units="inches" useNumberFormat3/>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField  disabled={is_read_only} field="p_length" label="Proposed culvert length" fullWidth units="feet" useNumberFormat3/>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField  disabled={is_read_only} field="p_elevation" label="Proposed culvert elevation" fullWidth units="feet" useNumberFormat3/>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField  disabled={is_read_only} field="additional" label="Additional information about culvert" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <RadioGroup
                                                    disabled={is_read_only}
                                                    field="cul_road_involves"
                                                    label="Does Project Involve Road Grading that will modify elevations?"
                                                    fullWidth
                                                    options={[
                                                        { label: "Yes", value: "Yes" },
                                                        { label: "No", value: "No" },
                                                    ]}
                                                    alignment={true}
                                                />
                                            </Grid> 
                                            <Grid item xs={6}>
                                                <TextField
                                                    disabled={is_read_only}
                                                    field="cul_road_elev"
                                                    label="If Project modifies road elevations, please describe proposed elevations?"
                                                    fullWidth
                                                />
                                            </Grid>                                     
                                            <Grid item xs={6}/>
                                            <Grid item xs={6}/>
                                            <Grid item xs={6}>
                                                <Button  fullWidth onClick={() => this.setState({ culvertDialogOpen: false })}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button  disabled={is_read_only} fullWidth type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </AppContainerApplication>
        );
    }
}

Roseau = connect(
    (state, ownProps) => ({
        application: getApplication(state, ownProps),
        authState: state.auth, // Admins will have an authstate
    }),
    {
        ...ApplicationRecord.actions,
        ...ApplicationRecordStatus.actions,
        ...RoseauPermit.actions,
        ...RoseauCulvert.actions,
        ...RoseauAttachment.actions
    }
)(Roseau);

export default withStyles(styles)(withRouter(Roseau));
