import React, { Component } from "react";
import { connect } from "react-redux";
import { Form as Formm } from "react-form";
import { withRouter } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Close from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import Delete from "@material-ui/icons/Delete";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Edit from "@material-ui/icons/Edit";

import drag from "../common/icons/dnd.png";
import BreadCrumbs from "../common/BreadCrumbs";
import AppContainer from "../common/AppContainer";
import TextField from "../common/TextField";
import Checkbox from "../common/Checkbox";
import Select from "../common/Select";
import HelpLabel from "../common/HelpLabel";
import { createSelector } from "../common/orm";
import WarningDialog from "../common/WarningDialog";
import { ContactField, ContactRecordField } from "../page/models";

const getContactFields = createSelector((schema) => {
    return schema.ContactField.all().orderBy("order").toRefArray();
});

const styles = (theme) => ({
    italics: {
        fontStyle: "italic",
    },
    fieldButton: {
        display: "block",
        backgroundColor: "#74A94C",
        color: "white",
        borderRadius: 10,
        minWidth: 200,
        margin: 8,
    },
    minWidth: {
        minWidth: 600,
    },
    flex: {
        flex: 1,
    },
    expandedMargin: {
        paddingRight: "60px",
    },
    titlePanel: {
        minHeight: "auto !important",
    },
    redText: {
        color: "#f00f0f",
    },
    overridetooltip: {
        fontWeight: 80,
        fontSize: 12,
    },
});

//fields styling section for groups
const getItemStyle2 = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 8,
    margin: `0 0 8px 0`,
    color: "primary",
    fontSize: "18px",
    borderRadius: 5,
    textAlign: "left",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 2px, rgba(0, 0, 0, 0.25) 0px 2px 2px",

    // change background of fields when dragging
    background: isDragging ? "lightgrey" : "lightgrey",

    // styles we need to apply on draggables
    ...draggableStyle,
});
// background color on "fields child card" section when dragging
const getListStyle2 = (isDraggingOver) => ({
    background: isDraggingOver ? "#4e4e4e" : "",
    padding: 5,
    borderRadius: 5,
    marginBottom: isDraggingOver ? 16 : 0,
});

class ContactConfig extends Component {
    state = {
        loading: false,
        fieldToOpen: {},
    };

    createContactField = (order) => {
        const { ormContactFieldCreate, authState /*ormContactRecordFieldReload*/ } = this.props;
        this.setState({ loading: true });

        ormContactFieldCreate({
            city_id: authState.user.city_id,
            order: order,
            // field_name: temp,
            // field_type: "Text",
        }).then((foo) => {
            // Need to reload the record fields that were created in the contactfield viewset (nonglobal fields are loaded when the record is created)
            //ormContactRecordFieldReload().then(() => {
            let fieldToOpen = {};
            fieldToOpen["field"] = foo;
            fieldToOpen["open"] = true;
            return this.setState({ loading: false, fieldToOpen });
            //});
        });
    };
    setfieldToOpen = () => {
        this.setState({ fieldToOpen: {} });
    };

    render() {
        const { classes, contact_fields, ormContactFieldUpdate, ormContactFieldDelete, ormContactFieldUpdateLocalOnly } = this.props;
        const { loading, fieldToOpen } = this.state;

        return (
            <AppContainer authenticated>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5">Contacts Configuration</Typography>
                        <Typography className={classes.italics}>Use the tools on this module to build your master contacts form</Typography>
                        <BreadCrumbs
                            style={{ marginLeft: -8 }}
                            allPageConfig={"/contacts"}
                            firstCrumbName="Master Contact List"
                            formName="Contact Field Configuration"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Accordion defaultExpanded>
                            <AccordionSummary
                                className={classes.titlePanel}
                                classes={{ root: classes.expandedMargin }}
                                expandIcon={<ExpandMoreIcon />}
                                style={{
                                    backgroundColor: "#ddd",
                                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 0px, rgba(0, 0, 0, 0.2) 0px 1px 1px",
                                }}>
                                <Typography variant="h5" style={{ fontSize: 18 }}>
                                    Contact Fields
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    <Grid item xs={12}>
                                        {contact_fields.length < 1 && (
                                            <Typography style={{ textAlign: "center" }} variant="h5">
                                                No Contact Fields Defined
                                            </Typography>
                                        )}
                                        <FieldButtons
                                            ormContactFieldUpdate={ormContactFieldUpdate}
                                            ormContactFieldDelete={ormContactFieldDelete}
                                            ormContactFieldUpdateLocalOnly={ormContactFieldUpdateLocalOnly}
                                            classes={classes}
                                            contact_fields={contact_fields}
                                            fieldToOpen={fieldToOpen}
                                            setfieldToOpen={this.setfieldToOpen}
                                        />
                                        {loading && (
                                            <CircularProgress size={32} style={{ display: "table", marginLeft: "auto", marginRight: "auto" }} />
                                        )}
                                        {!loading && (
                                            <Button
                                                onClick={() => this.createContactField(contact_fields.length)}
                                                variant="contained"
                                                color="primary"
                                                fullWidth>
                                                <AddCircleOutlineIcon />
                                                &nbsp;&nbsp;Add New Contact Field
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

//buttons of each group
class FieldButtons extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            fieldDialog: false,
            fieldState: null,
            disableDrag: false,
            field: null,
        };
        this.returnDialog = this.returnDialog.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    deleteContactField = (field) => {
        // const { ormContactFieldDelete } = this.props;
        // ormContactFieldDelete(field.id);
        this.setState({ field, deleteDialogOpen: true });
    };

    returnDialog() {
        this.setState({ fieldDialog: false, fieldState: null, disableDrag: false });
    }

    openDialog(f) {
        this.setState({ fieldDialog: true, fieldState: f, disableDrag: true });
        this.props.setfieldToOpen(); //is called when we first create or open a field and we want to clear state in above component (groupcontainer) so rerenders dont double open
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const { ormContactFieldUpdate, contact_fields } = this.props;

        contact_fields.forEach(function (g) {
            // If the destination is greater than the original position then shift all items between them down 1
            // result.source.index = 0
            // result.destination.index = 2
            if (result.destination.index > result.source.index) {
                if (g.order > result.source.index && g.order <= result.destination.index) {
                    ormContactFieldUpdate({
                        id: g.id,
                        order: g.order - 1,
                    });
                }
            }
            // If the destination is less than the original position then shift all items between them up 1
            // result.source.index = 2
            // result.destination.index = 0
            else if (result.destination.index < result.source.index) {
                if (g.order >= result.destination.index && g.order < result.source.index) {
                    ormContactFieldUpdate({
                        id: g.id,
                        order: g.order + 1,
                    });
                }
            }
        });

        ormContactFieldUpdate({
            id: result.draggableId,
            order: result.destination.index,
        });
    }

    render() {
        const { classes, contact_fields, ormContactFieldUpdate, ormContactFieldUpdateLocalOnly, ormContactFieldDelete, fieldToOpen } = this.props;
        const { fieldDialog, fieldState, disableDrag, field, deleteDialogOpen } = this.state;
        if (fieldToOpen && fieldToOpen["open"] && !fieldDialog) this.openDialog(fieldToOpen["field"]); //creating a new field route to opening editing to setup as we want users to fill out right away

        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="field_list" type="field_list" key="field_list-">
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef} style={getListStyle2(snapshot.isDraggingOver)}>
                            {contact_fields.map((f, index) => (
                                <Draggable key={f.id} draggableId={f.id} index={f.order} isDragDisabled={disableDrag}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle2(snapshot.isDragging, provided.draggableProps.style)}
                                            variant="contained"
                                            className={classes.fieldButton}>
                                            <table style={{ width: "100%", marginBottom: -10, marginTop: -8 }}>
                                                <tr>
                                                    <td style={{ width: "100%" }}>
                                                        {!f.field_type && (
                                                            <Typography style={{ textAlign: "center" }} className={classes.redText}>
                                                                Drag to Rearrange or Click Edit to Modify Field
                                                            </Typography>
                                                        )}
                                                        {f.field_type && (
                                                            <table>
                                                                <tr>
                                                                    <td>
                                                                        <img
                                                                            style={{
                                                                                width: "1.6em",
                                                                                height: "1.6em",
                                                                                verticalAlign: "middle",
                                                                                marginRight: 11,
                                                                                marginLeft: 6,
                                                                            }}
                                                                            src={drag}
                                                                            alt="fireSpot"
                                                                        />
                                                                    </td>
                                                                    <td style={{ whiteSpace: "nowrap" }}>
                                                                        <Typography
                                                                            style={{
                                                                                color:
                                                                                    f.field_name &&
                                                                                    f.field_name.includes("New Contact Field Configure Me")
                                                                                        ? "red"
                                                                                        : "#262626",
                                                                            }}>
                                                                            {f.field_type}
                                                                        </Typography>
                                                                    </td>
                                                                    <td>
                                                                        <div
                                                                            style={{
                                                                                margin: "-5px 8px",
                                                                                backgroundColor: "#262626",
                                                                                width: 2,
                                                                                height: 50,
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td style={{ width: "100%" }}>
                                                                        <Typography
                                                                            style={{
                                                                                color:
                                                                                    f.field_name &&
                                                                                    f.field_name.includes("New Contact Field Configure Me")
                                                                                        ? "red"
                                                                                        : "#262626",
                                                                            }}>
                                                                            {f.field_name}
                                                                        </Typography>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        )}
                                                    </td>
                                                    <td style={{ whiteSpace: "nowrap" }}>
                                                        <Tooltip title="Edit Field">
                                                            <IconButton
                                                                color="primary"
                                                                onClick={(e) => {
                                                                    this.openDialog(f);
                                                                }}>
                                                                <Edit />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete Field">
                                                            <IconButton
                                                                onClick={() => {
                                                                    this.deleteContactField(f);
                                                                }}>
                                                                <Delete />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    )}
                                </Draggable>
                            ))}{" "}
                            <WarningDialog
                                confirmAction={() => {
                                    ormContactFieldDelete(field.id);
                                    // Reduce the order for every field above this field
                                    // fields.forEach(function (f) {
                                    //     if (f.order > field.order) {
                                    //         ormFieldUpdate({
                                    //             id: f.id,
                                    //             order: f.order - 1,
                                    //         });
                                    //     }
                                    // });
                                    this.setState({ deleteDialogOpen: false });
                                }}
                                cancelAction={() => this.setState({ deleteDialogOpen: false })}
                                open={deleteDialogOpen}
                                confirmText="Delete Contact Field?"
                                title={`Delete ${field ? field.field_name : " "} Contact Field?`}
                                text="Are you sure you wish to permanently delete this Contact Field?"
                            />
                            <AddField
                                classes={classes}
                                open={fieldDialog}
                                field={fieldState}
                                contact_fields={contact_fields}
                                returnDialog={this.returnDialog}
                                ormContactFieldUpdate={ormContactFieldUpdate}
                                ormContactFieldUpdateLocalOnly={ormContactFieldUpdateLocalOnly}
                            />
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }
}

class AddField extends Component {
    updateField(values) {
        const { ormContactFieldUpdate, field, returnDialog } = this.props;

        ormContactFieldUpdate({
            id: field.id,
            ...values,
        });
        returnDialog();
    }

    errorValidator = (values) => {
        const isRequired = (val) => {
            return !val ? "Required" : null;
        };
        return {
            field_name: isRequired(values.field_name),
            field_type: isRequired(values.field_type),
        };
    };

    applyOther = (value) => {
        const { field, ormContactFieldUpdateLocalOnly } = this.props;
        ormContactFieldUpdateLocalOnly({
            id: field.id,
            field_type: value,
        });
        this.setState({ foo: "bar" });
    };

    render() {
        const { classes, returnDialog, open, field, contact_fields } = this.props;

        var field_find = {};
        if (field) field_find = contact_fields.find((cf) => cf.id === field.id);

        function MAKE_OPTIONS(table) {
            return table.map((row) => ({
                label: row,
                value: row,
            }));
        }

        const standardTypes = [
            "Text", "Currency", "Date", "Drop Down", "Email", "Multi Select", 
            "Numeric", "Phone Number", "Radio", "Time", "Yes/No"
        ];

        return (
            <Dialog open={open} classes={{ paper: classes.minWidth }}>
                <Toolbar>
                    <Typography variant="h5" className={classes.flex}>
                        Edit Field
                    </Typography>
                    <IconButton onClick={returnDialog}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <DialogContentText>
                        <Formm
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            validateError={this.errorValidator}
                            defaultValues={field}
                            onSubmit={(values) => this.updateField(values)}>
                            {(formApi) => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                field="field_name"
                                                fullWidth
                                                label={
                                                    <HelpLabel inputLabel="Name" helpText="Label that appears on the Form in the user interface" />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                field="help_description"
                                                fullWidth
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Help Description"
                                                        helpText="Enter the description for this data type to be used as a help guide"
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Select
                                                field="field_type"
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Type"
                                                        helpText="Type of input that appears on the form and value type stored in the database"
                                                    />
                                                }
                                                options={MAKE_OPTIONS(standardTypes)}
                                                fullWidth
                                                eventHandle={this.applyOther}
                                            />
                                        </Grid>
                                        {(field_find.field_type === "Drop Down" || field_find.field_type === "Radio" || field_find.field_type === "Multi Select") && (
                                            <Grid item xs={12}>
                                                <Tooltip
                                                    title="Make sure all entries values are seperated by a comma ' , ' There shouldnt be a comma in the 'beginning' or 'end' of the list"
                                                    enterDelay={250}
                                                    placement={"bottom"}
                                                    classes={{
                                                        tooltip: classes.overridetooltip,
                                                    }}>
                                                    <TextField
                                                        field="values_csv"
                                                        label="Enter Drop Down Values in comma seperated form (ex. 10,20,30)"
                                                        fullWidth
                                                    />
                                                </Tooltip>
                                            </Grid>
                                        )}
                                        {(field_find.field_type === "Drop Down" || field_find.field_type === "Radio" || field_find.field_type === "Multi Select") && (
                                            <Grid item xs={12}>
                                                <Tooltip
                                                    title="Make sure the default value matches only one of the drop down values"
                                                    enterDelay={250}
                                                    placement={"bottom"}
                                                    classes={{
                                                        tooltip: classes.overridetooltip,
                                                    }}>
                                                    <TextField field="default_value" label="Default Value?" fullWidth />
                                                </Tooltip>
                                            </Grid>
                                        )}
                                         {field_find.field_type === "Yes/No" && (
                                            <Grid item xs={12}>
                                                <Select
                                                    field="default_value"
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Default Value"
                                                            helpText="Select the default value for the Yes/No field type. This value will be used for every initial contact added to a record."
                                                        />
                                                    }
                                                    options={MAKE_OPTIONS(['Yes', 'No'])}
                                                    fullWidth
                                              
                                                />
                                            </Grid>
                                        )}
                                        {(field_find.field_type === "Date" || field_find.field_type === "Time") && (
                                            <Grid item xs={12}>
                                                <Checkbox field="autopopulate" label={"Use Current " + field_find.field_type + " as Default"} />
                                            </Grid>
                                       )}
                                        <Grid item xs={12}>
                                            <Checkbox field="is_global">
                                                <HelpLabel
                                                    inputLabel="Is Global"
                                                    helpText="If Global is set checked then the field will not be able to modified on an individual basis."
                                                />
                                            </Checkbox>
                                            <Checkbox field="is_column">
                                                <HelpLabel
                                                    inputLabel="Show in Primary Table"
                                                    helpText="This contact field will be shown as a column in the main primary record table."
                                                />
                                            </Checkbox>
                                            <Button type="submit" variant="contained" color="primary" style={{ width: "100%" }}>
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formm>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}
ContactConfig.displayName = "ContactConfig";

ContactConfig = connect(
    (state, ownProps) => ({
        contact_fields: getContactFields(state, ownProps),
        authState: state.auth,
    }),
    {
        ...ContactField.actions,
        ...ContactRecordField.actions,
    }
)(ContactConfig);

export default withStyles(styles)(withRouter(ContactConfig));
