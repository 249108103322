/*****************************************************************************
 * This component is the "edit" users page. It encapsulates the top "Stepper"
 * for "editing" a new user. Component calls the following outside components:
 * TextFieldWrapper.js & UserPermission.js. NOTE: This component is used in conjunction
 * with create.js for permissions when a new user is added. This is due to needing
 * an ID before one can set the permissions.
 *
 * The administrator of the city will have access to create a new user and define their user permissions
 *
 * Created by: Robert Gautier/Joe J.
 * Date: 4/2/19
 *****************************************************************************/

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import AppContainer from "../common/AppContainer";
import MuiTextFieldWrapper from "./TextFieldWrapper";
import { User } from "./models";
import UserPermissions from "./UserPermissions";
import * as navActions from "../common/actions"; //use actions as props

//select all user objects by ID
const getUser = User.selectByUrlId();

const styles = (theme) => ({
    root: {
        width: "100%",
        marginTop: "30px",
        marginBottom: 30,
    },
    button: {
        marginRight: theme.spacing(1),
        color: "white",
        backgroundColor: "#75bafa",
        //tie hover into button
        "&:hover": {
            backgroundColor: "#3C8ac7",
        },
    },

    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
});

function getSteps() {
    return ["User Information", "User Permissions", "Review User and Submit"];
}

class Create extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userPosition: 0,
            submit: "false",
            activeStep: 0,
            formField: false,
        };

        if (props.location.search === "?new=true") {
            this.state["activeStep"] = 1;
            this.state.formField = true;
        }
    }

    isStepOptional = (step) => step === 1;

    continueStep = () => {
        this.setState({ activeStep: this.state.activeStep + 1 });
    };

    //move forward with the stepper, pass value back to parent "create.js" to keep track of state
    handleNext = () => {
        if (this.activeStepper) {
            this.activeStepper.formApi.submitForm();
        } else {
            this.setState({ activeStep: this.state.activeStep + 1 });
        }
    };

    //move backward with the stepper, pass value back to parent "create.js" to keep track of state
    handleBack = () => {
        this.setState({
            activeStep: this.state.activeStep - 1,
        });
    };

    //revert to Manage page after 3 seconds, this is applicable for creating a "new" user or "editing" a user
    revertToManage = () => {
        const { history } = this.props;
        setTimeout(() => {
            history.push("/adm/manage");
        }, 2000); //go back to adm/manage page after 2 seconds
    };

    //top stepper on the page, called in function to clean up render
    stepper = (classes, steps, activeStep) => {
        if (activeStep === steps.length) {
            this.revertToManage();
        }
        return (
            <div style={{ marginTop: "1%" }}>
                {/* OUTPUT BASED ON IF WE ARE CREATING A NEW USER OR EXISTING */}
                <Typography variant="h5" gutterBottom>
                    {this.state.formField ? "Creating a New User" : "Editing a User"}
                </Typography>

                {/* TOP HEADER STEPPER STARTING OF CODE */}
                <div className={classes.root}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const props = {};
                            const labelProps = {};

                            //step label on the screen
                            return (
                                <Step key={label} {...props}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <div>
                        {activeStep === steps.length ? (
                            <div>
                                <Typography className={classes.instructions}>
                                    {this.state.formField ? `All steps completed - New User saved!` : ` All steps completed - Edited User saved!`}
                                </Typography>
                            </div>
                        ) : (
                            <div>
                                <br />

                                <Button disabled={activeStep === 0} onClick={this.handleBack} className={classes.button}>
                                    Back
                                </Button>
                                <Button onClick={this.handleNext} className={classes.button}>
                                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { classes } = this.props;
        const steps = getSteps();
        const { activeStep } = this.state;
        const { useInfo } = this.props;

        //mui calls the component to be loaded for the bottom half of the screen. Passes ability to continue stepping
        const mui = () => {
            switch (activeStep) {
                //first step enter user info, continueStep is a function that will be called from child "TextFieldWrapper"
                //useInfo is only when editUser is choosen in manage.js which passes over users info
                case 0:
                    return (
                        <MuiTextFieldWrapper
                            onRef={(el) => (this.activeStepper = el)}
                            continueStep={this.continueStep}
                            useInfo={useInfo}
                            edit={true}
                        />
                    );
                //User Permissions
                case 1:
                    return <UserPermissions useInfo={useInfo} />;

                //Review before submission
                case 2:
                    return (
                        <div>
                            <MuiTextFieldWrapper
                                onRef={(el) => (this.activeStepper = el)}
                                continueStep={this.continueStep}
                                useInfo={useInfo}
                                edit={true}
                            />
                            <br />
                            <UserPermissions />
                        </div>
                    );
                default:
                    return <div />;
            }
        };

        return (
            <AppContainer authenticated>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {/* START OF STEPPER */}
                        {this.stepper(classes, steps, activeStep)}

                        {/* START OF BOTTOM PAGE WRAPPER */}
                        {/*Mui calls the above mui() method in render, this loads the "TextFieldWrapper", "User Permissions" and Review components on the bottom of page*/}
                        <div>{mui()}</div>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}
Create.displayName = "Create";
// This connects the redux-orm "allUsers" function and allows this component to have the props "user"
// The second part loads all orm actions into this pages props for both Page and Section: ormPageCreate, ormSectionCreate, orPageUpdate, etc.
Create = connect(
    (state, ownProps) => ({
        useInfo: getUser(state, ownProps), //component wide state prop "user" gets derived info from database
    }),
    {
        ...navActions,
    } //nav actions which can use functions from this component.
)(Create);

export default withStyles(styles)(withRouter(Create));
