import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import AppContainer from "../common/AppContainer";
import { createSelector } from "../common/orm";

import { connect } from "react-redux";
import * as authActions from "./actions";
import * as navActions from "../common/actions";

const getUsername = createSelector(
    (state, ownProps) => ownProps.match.params["username"],
    (session, username) => {
        return username;
    }
);


class KeycloakRedirect extends Component {
    componentDidMount() {
        const { username, authLoginKeycloak, history, location, setMapSettings } = this.props;
        if (username) {
            var cook = document.cookie;
            var splitCookie = cook.split(";");
            splitCookie.forEach((c) => {
                document.cookie = `${c}; path=/; expires=Wed, 12 Oct 2000 08:50:17 UTC`;
            });
            //from actions.js file
            authLoginKeycloak(username).then(() => {
                if (this.props.authState.user) {
                    setMapSettings({
                        lat: null,
                        lng: null,
                        zoom: null,
                    });
                    if (this.props.authState.user.redirect) {
                        history.push(this.props.authState.user.redirect.replace("#", "") + "?old=True");
                    } else if (location.search) {
                        history.push(location.search.replace("?return=", ""));
                    } else {
                        if (this.props.authState.user.default_dashboard) {
                            if (this.props.authState.user.city_id === 32) history.push("/page/249/798/table"); //table direct route for LA only
                            else history.push("/dashboard/" + this.props.authState.user.default_dashboard);
                        } else {
                            if (this.props.authState.user.city_id === 32) history.push("/page/249/798/table"); //table direct route for LA only
                            else history.push("/dashboard");
                        }
                    }
                } else {
                    history.push("/")
                }
            });
        }
    }

    render() {
        return (
            <AppContainer>
                <Grid container justify="center">
                    <Grid item xs={12} sm={10} md={6}>
                        <CircularProgress
                            size={80}
                            style={{ display: "table", marginLeft: "auto", marginRight: "auto" }}
                        />
                        <Typography
                            variant="h6"
                            style={{
                                display: "table", marginLeft: "auto", marginRight: "auto"
                            }}>
                            Redirecting... Please wait
                        </Typography>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}
KeycloakRedirect.displayName = "KeycloakRedirect";
KeycloakRedirect = connect((state, ownProps) => ({ 
    authState: state.auth,
    username: getUsername(state, ownProps) 
}), {
    ...authActions,
    ...navActions,
})(KeycloakRedirect);

export default withRouter(KeycloakRedirect);
