import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";

import ViewIcon from "@material-ui/icons/Visibility";

import AppContainer from "../common/AppContainer";
import EnhancedTableHead from "../common/EnhancedTableHead";
import CustomTableCell from "../common/TableCell";
import { Application } from "../page/models";

const styles = (theme) => ({
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8,
        marginTop: 2,
        marginBottom: 2,
    },
    centerAlign: {
        textAlign: "center",
    },
    nowrap: {
        whiteSpace: "nowrap",
    },
});

const getAllApplications = Application.selectAll();

class Home extends Component {
    state = {
        order: "desc",
        orderBy: null,
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.props.applications.sort((a, b) => (b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1))
            : this.props.applications.sort((a, b) => (a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1));

        this.setState({ order, orderBy });
    };

    render() {
        const { applications, classes } = this.props;
        const { orderBy, order } = this.props;

        var columnData = [
            { id: "view", numeric: false, label: "View", allowSort: false, width: "30px" },
            { id: "application_name", numeric: false, label: "Application Name", allowSort: true },
            { id: "page_label", numeric: false, label: "Module", allowSort: true },
            { id: "notes", numeric: false, label: "Notes", allowSort: true },
        ];

        return (
            <AppContainer authenticated>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Public Application List</Typography>
                        <Typography>
                            This module shows a list of public applications that have been configured in your account. Public applications are setup
                            and configured by the software vendor and are considered a custom module. Changes to any application forms need to be made
                            by the vendor. Please contact the vendor for any changes.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Table className={classes.table}>
                            <EnhancedTableHead columnData={columnData} order={order} orderBy={orderBy} onRequestSort={this.handleRequestSort} />
                            <TableBody>
                                {applications.map((n) => {
                                    return (
                                        <TableRow hover>
                                            <CustomTableCell className={classes.nowrap}>
                                                <Tooltip title="View">
                                                    <Button
                                                        color="primary"
                                                        component={Link}
                                                        to={"/applications/" + n.id}
                                                        className={classes.deleteWidth}>
                                                        <ViewIcon color="primary" />
                                                    </Button>
                                                </Tooltip>
                                            </CustomTableCell>
                                            <CustomTableCell>{n.application_name}</CustomTableCell>
                                            <CustomTableCell>{n.page_label}</CustomTableCell>
                                            <CustomTableCell>{n.notes}</CustomTableCell>
                                        </TableRow>
                                    );
                                })}
                                {applications.length < 1 && (
                                    <TableRow>
                                        <CustomTableCell colSpan={columnData.length} className={classes.centerAlign}>
                                            No Applications Found - Contact MS4Front Support to Configure
                                        </CustomTableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

Home = connect((state, ownProps) => ({
    applications: getAllApplications(state, ownProps),
    authState: state.auth,
}))(Home);

export default withStyles(styles)(withRouter(Home));
