/****************************************************************************************
 *  This component calls the expansionpanelmaprecords.js and basemapcontrol.js          *
 *  It is the parent container of those files and is the main route from react-router   *
 *                                                                                      *
 *  Author: Robert Gautier /Joe J                                                       *
 *  Created on: 7/19                                                                    *
 *  Rev A 1/6/2020                                                                      *
 ****************************************************************************************/

import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import { IconButton } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";

import AppContainer from "../../common/AppContainer";
import BaseMapControl from "../../common/esrimap/BaseMapControl";
import Snackbar from "../../common/Snackbar";
import { createSelector } from "../../common/orm";
import ExpansionPanelMapRecord from "./ExpansionPanelMapRecord";
import { RecordSection, RecordField } from "../models";
import FormUpload from "../../common/FormUpload";

const styles = (theme) => ({
    mapContainer: {
        height: "100%",
        margin: "0 !important", // Overwrite natural margins/widths so map takes up full screen
        width: "100% !important",
        [theme.breakpoints.down("960")]: {
            paddingLeft: "48px !important",
        },
    },
    height: {
        height: "100%",
        marginTop: "0 !important",
    },
    iconbutton: {
        margin: "0 15px",
    },
    heightGrid: {
        height: "100%",
    },
    floatLeft: {
        float: "left",
    },
    floatRight: {
        float: "right",
    },
    flex: {
        flex: 1,
    },
    progress: {
        marginLeft: "auto",
        marginRight: "auto",
        display: "table",
        marginTop: 16,
    },
});

const getRecordSectionID = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["recordSectionId"]),
    (session, recordSection) => {
        return recordSection;
    }
);

const getPermission = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["pageId"]),
    (session, pageID) => {
        return session.Permission.filter((p) => p.page === pageID).toRefArray();
    }
);

const getPage = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["pageId"]),
    (session, pageID) => {
        return session.Page.withId(pageID);
    }
);

const getParentRecord = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["parentRecordId"]),
    (session, pr) => {
        return session.RecordSection.filter((rs) => rs.parentrecord === pr).toRefArray();
    }
);

// Get the page object + section/group/field template definitions
const getPageIdMap = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["pageId"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (session, pageID) => {
        return session.Page.filter((page) => page.id === pageID)
            .toModelArray()
            .map((page) => ({
                sections: page.sections
                    .all()
                    .orderBy("id")
                    .toModelArray()
                    .map((section) => ({
                        _forms: section.forms
                            .all()
                            .orderBy("id")
                            .toModelArray()
                            .map((form) => ({
                                _groups: form.groups
                                    .all()
                                    .orderBy("id")
                                    .toModelArray()
                                    .map((group) => ({
                                        _fields: group.fields.all().orderBy("id").toRefArray(),
                                        ...group.ref,
                                    })),
                                ...form.ref, // take all groups as a reference and tie in each field
                            })),
                        ...section.ref, // take all groups as a reference and tie in each field
                    })),
                ...page.ref,
            }))[0];
    }
);

// Get the parent record along with all recordsections associated with the parent
const getParentRecordMap = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["parentRecordId"]), //get parent record id
    (session, parentRecordId) => {
        return session.RecordSection.filter((rs) => rs.parentrecord === parentRecordId)
            .all()
            .orderBy("section")
            .toRefArray();
        // .toModelArray()
        // .map((parentRecord) => ({
        //     recordsections: parentRecord.recordSection.all().orderBy("section").toRefArray(),
        //     ...parentRecord.ref,
        // }))[0];
    }
);

const getRecordSectionMap = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["recordSectionId"]),
    (session, recordSection) => {
        // JJ: We have the specific recordsection from ormRecordSectionLoadDetailChild in ExpansionPanel
        return session.RecordSection.withId(recordSection);
    }
);

class MapRecord extends Component {
    constructor(props) {
        super(props);
        this.state = {
            save: false, //controls popup
            loadingFields: false,
            nullFields: false,
            open: false,
            isSuccess: true,
            missingCount: 0,
            loadingFeatures: false,
            loadingComplete: false, 
        };
    }

    componentDidMount() {
        this.getLabel();
    }

    componentDidUpdate(prevProps) {
        const { recordSectionID } = this.props;
        if (prevProps.recordSectionID !== recordSectionID) this.setState({ loadingComplete: false });
        this.getLabel();
    }

    componentWillUnmount() {
        this.setState({ calculationInfo: {}, actCalcFields: [] });
    }

    getLabel = () => {
        const { authState, ormRecordSectionLoadDetailChild, match } = this.props;
        const { loadingFeatures, loadingComplete } = this.state;
        var component = this;

        // If you load this page from a bookmark it doesn't have an authstate right away
        if (!authState || !authState.user) return;

        // Get every recordsection associated with the parent record
        // We need all these in this specific instance to draw on the map
        if (!loadingFeatures) {
            if (match.params.parentRecordId && !loadingComplete) {
                this.setState({ loadingFeatures: true });
                ormRecordSectionLoadDetailChild(
                    match.params.parentRecordId,
                    function () {
                        component.setState({ loadingFeatures: false, loadingComplete: true });
                    },
                    "RecordSectionMultiple",
                    "true"
                );
            }
        }
    };

    setSave = (e) => {
        this.setState({ save: e });
    };

    setSuccess = (e, missingCount) => {
        this.setState({ isSuccess: e, missingCount });
    };

    setLoading = (e) => {
        this.setState({ loadingFields: e });
    };

    setNull = (e) => {
        this.setState({ nullFields: e });
    };

    render() {
        const { save, isSuccess, missingCount, loadingFields, nullFields, open, loadingFeatures, loadingComplete } = this.state;
        const {
            classes,
            history,
            authState,
            page,
            permission,
            parentRecord,
            pageMap,
            parentrecordMap,
            recordsectionMap,
            ormRecordFieldCreate,
            ormRecordSectionCreate,
            ormRecordSectionUpdateLocalOnly,
            ormRecordSectionUpdate,
            ormRecordSectionMapUpdate,
            ormRecordFieldLoadDetailChild

        } = this.props;

        if (!page) return ""; // throw new Error("Invalid Page ID");

        if (authState.user) {
            var readOnly;
            var perm = permission.find((p) => {
                if (p.user === authState.user.id) {
                    return p;
                }
                return null;
            });
            if (perm) readOnly = perm.readonly || perm.readonlyexternal ? true : false;
            if (readOnly === undefined) readOnly = false;
        }

        return (
            <AppContainer authenticated>
                <Grid container className={classes.mapContainer}>
                    <Grid item xs={12} style={{ marginTop: -4 }}>
                        <Grid container spacing={0}>
                            <Grid item xs={6} style={{ paddingLeft: 16 }}>
                                <Typography variant="h5">
                                    {page.name} {parentRecord.length > 0 && parentRecord[0].parentinfo && " - " + parentRecord[0].parentinfo.label}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: "right" }}>
                                {parentRecord.length > 0 && (
                                    <>
                                        {page && (
                                            <Tooltip className={classes.hideSmall} title={`Dashboard`}>
                                                <IconButton className={classes.iconbutton} component={Link} to={"/dashboard/" + page.id}>
                                                    <Icon>timeline</Icon>
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        {parentRecord.length > 0 && (
                                            <Tooltip title={"Table View"}>
                                                <IconButton
                                                    className={classes.iconbutton}
                                                    component={Link}
                                                    to={"/page/" + page.id + "/" + parentRecord[0].section + "/table"} //route to section table view of all records for that page
                                                >
                                                    <Icon>view_headline </Icon>
                                                </IconButton>
                                            </Tooltip>
                                        )}

                                        <Tooltip title={`${page.name} Map`}>
                                            <IconButton
                                                className={classes.iconbutton}
                                                component={Link}
                                                to={"/map/" + page.id + "/section/" + 0 + "/advancedsearch/" + 1}>
                                                <Icon>map</Icon>
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title={"Search"}>
                                            <IconButton className={classes.iconbutton} component={Link} to={"/page/" + page.id + "/advancedsearch"}>
                                                <Icon>search</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                )}
                                {authState.user && authState.user.is_city_admin === true && (
                                    <Tooltip title="Module Configuration">
                                        <IconButton
                                            disabled={readOnly}
                                            className={classes.iconbutton}
                                            component={Link}
                                            to={"/page/" + page.id + "/config"} //route to page config
                                        >
                                            <Icon>settings</Icon>
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    {(loadingFeatures || !loadingComplete) && (
                        <Grid item xs={12} className={classes.heightGrid}>
                            <CircularProgress size={90} className={classes.progress} />
                            <Typography variant="h6" style={{ textAlign: "center" }}>
                                Loading... Please Wait
                            </Typography>
                        </Grid>
                    )}
                    {!loadingFeatures && loadingComplete && (
                        <>
                            <Grid item xs={9} className={classes.heightGrid}>
                                <Snackbar
                                    snackbarOpen={save}
                                    handleSnackbarClose={() => this.setState({ save: false })}
                                    isSuccess={isSuccess}
                                    missing={missingCount}
                                    advSearch={false}
                                    operationType="record"
                                />
                                {this.sideform && (
                                    <BaseMapControl
                                        setNull={this.setNull}
                                        setLoading={this.setLoading}
                                        pageMap={pageMap}
                                        parentrecordMap={parentrecordMap}
                                        recordsectionMap={recordsectionMap}
                                        ormRecordFieldCreate={ormRecordFieldCreate}
                                        ormRecordSectionCreate={ormRecordSectionCreate}
                                        ormRecordSectionUpdateLocalOnly={ormRecordSectionUpdateLocalOnly}
                                        ormRecordSectionUpdate={ormRecordSectionUpdate}
                                        ormRecordFieldLoadDetailChild={ormRecordFieldLoadDetailChild}
                                        ormRecordSectionMapUpdate={ormRecordSectionMapUpdate}
                                        readOnly={readOnly}
                                        sideform={this.sideform}//allows us to trigger submitting of expansionpanelmaprecord.js form--(i.e updating the recordsection).
                                    />
                                )}
                            </Grid>
                            <Grid item xs={3} className={classes.heightGrid}>
                                {/* SHOWS THE RECORD FIELDS ON THE SCREEN--subcomponent with reference to basemapcontrol.js */}
                                <ExpansionPanelMapRecord
                                    nullFields={nullFields}
                                    loadingFields={loadingFields}
                                    readOnly={readOnly}
                                    save={this.setSave}
                                    onRef={(el) => {
                                        this.sideform = el; //this ref allows us access to the methods in this component--basemapcontrol.js/mapediting.js specifically call submitting of the form
                                        this.setState({ foo: "bar" }); // Trigger rerender
                                    }}
                                    setSuccess={this.setSuccess}
                                    saveStatus={save}
                                />
                            </Grid>
                        </>
                    )}
                    <FormUpload
                        page={page}
                        authState={authState}
                        classes={classes}
                        open={open}
                        returnDialog={() => {
                            this.setState({ open: false });
                        }}
                        section={parentRecord.length > 0 ? parentRecord.section : null}
                        history={history}
                    />
                </Grid>
            </AppContainer>
        );
    }
}
MapRecord.displayName = "MapRecord";

MapRecord = connect(
    (state, ownProps) => ({
        authState: state.auth, //component wide state prop "authState" gets derived info from database
        page: getPage(state, ownProps),
        parentRecord: getParentRecord(state, ownProps),
        permission: getPermission(state, ownProps),
        pageMap: getPageIdMap(state, ownProps),
        parentrecordMap: getParentRecordMap(state, ownProps),
        recordsectionMap: getRecordSectionMap(state, ownProps),
        recordSectionID: getRecordSectionID(state, ownProps),
    }),
    {
        ...RecordSection.actions,
        ...RecordField.actions,
    }
)(MapRecord);

export default withStyles(styles)(withRouter(MapRecord));
