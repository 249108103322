/******************************************************************************
 * Advanced filter component uses all fields from the parent and user selected section
 * component interfaces with AdvancedRecordTable for rendering to the user.
 * This is a child component
 *
 * Author: Robert Gautier
 * Date: 11/19/19
 *******************************************************************************/

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form as Formm } from "react-form";

import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";

import WarningDialog from "../../common/WarningDialog";
import Snackbar from "../../common/Snackbar";
import TextField from "../../common/TextField";
import Select from "../../common/Select";
import { createSelector } from "../../common/orm";
import { Filter, AdvancedSearch, AdvancedFilter, AdvancedTableFields } from "../models";
import { User } from "../../adm/models";
import ReactSelect from "../../common/ReactSelect";
import DatePicker from "../../common/DatePicker";
import HelpLabel from "../../common/HelpLabel";
import Checkbox from "../../common/Checkbox";
import RadioGroup from "../../common/RadioGroup";

const styles = (theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(17),
        fontWeight: theme.typography.fontWeightRegular,
        paddingLeft: 15,
    },
    iconAdd: {
        backgroundColor: "#34aa20",
        color: "white",
        height: "25px",
        width: "25px",
        paddingTop: "0px",
    },
    disable: {
        height: "33px",
        width: "33px",
        paddingTop: "4px",
        marginTop: 33,
        [theme.breakpoints.up("lg")]: {
            marginRight: 40,
        },
        [theme.breakpoints.down("md")]: {
            marginRight: 16,
        },
    },
    delete: {
        // backgroundColor: "lightgray",
        height: "1.7em",
        width: "1.7em",
        paddingTop: "4px",
        marginTop: "34px",
        paddingRight: 19,

        [theme.breakpoints.down("1782")]: {
            paddingRight: 0,
        },
    },
    deleteIcon: {
        paddingLeft: 5,
        [theme.breakpoints.down("1782")]: {
            marginLeft: -22,
        },
    },
    expandedMargin: {
        marginTop: "12px",
    },
    titlePanel: {
        minHeight: "auto !important",
    },
    progress: {
        marginLeft: "auto",
        marginRight: "auto",
        display: "table",
        marginTop: 16,
    },
});

const allUsers = User.selectAll();

const getSections = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (session, _pageId) => {
        return session.Section.filter((sect) => sect.page === _pageId)
            .toModelArray()
            .map((section) => ({
                advancedSearch: section.advancedsearch.all().orderBy("id").toRefArray(),
                ...section.ref,
            })); //result function uses session.ModelName.filter to match pageID with section.page (.page is page_id in section model)
    } //toRefArray returns a plain JavaScript object which are references to the DB
);

const allFields = createSelector((schema) => {
    return schema.Field.all().orderBy("id").toRefArray();
});

const getPermission = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, actPage) => {
        return session.Permission.filter((p) => p.page === actPage).toRefArray();
    }
);
const getAdvancedSearchID = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["advID"]),
    (session, advS) => {
        return session.AdvancedSearch.filter((r) => r.id === advS)
            .orderBy("id")
            .toRefArray();
    } //toRefArray returns a plain JavaScript object which are references to the DB
);

const getAdvancedSearchWFilters = createSelector(
    // (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (state, ownProps) => parseInt(ownProps.match.params["advID"]),
    (session, advID) => {
        return session.AdvancedSearch.filter((r) => r.id === advID)
            .toModelArray()
            .map((advancedSearch) => ({
                advancedFilter: advancedSearch.advancedfilter.all().orderBy("id").toRefArray(),
                ...advancedSearch.ref,
            }));
    } //toRefArray returns a plain JavaScript object which are references to the DB
);

const getAdvancedFilters = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["advID"]),
    (session, adv_ID) => {
        return session.AdvancedFilter.filter((r) => r.advancedsearch_id === adv_ID)
            .orderBy("id")
            .toRefArray();
    } //toRefArray returns a plain JavaScript object which are references to the DB
);

const getPageFields = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (session, actPage) => {
        return session.Section.filter((sect) => sect.page === actPage)
            .orderBy("id")
            .toModelArray()
            .map((section) => ({
                form: section.forms //form.groups is FK linkage
                    .all()
                    .orderBy("id")
                    .toModelArray()
                    .map((form) => ({
                        groups: form.groups //form.groups is FK linkage
                            .all()
                            .orderBy("id")
                            .toModelArray()
                            .map((group) => ({
                                fields: group.fields.all().orderBy("id").toRefArray(),
                                ...group.ref, // take all groups as a reference and tie in each field
                            })),
                        ...form.ref, // take all forms as a reference and tie in each field
                    })),
                ...section.ref,
            }));
    } //toRefArray returns a plain JavaScript object which are references to the DB
);

class AdvancedFilters extends Component {
    constructor(props) {
        super(props);
        const { pagefields, advancedsearch, advancedsearchID, sections } = this.props;

        var allFields = [];
        let allFormFields = [];

        /*if (authState.user.city_id === 10 && advancedsearch[0].section !== parent.id)*/ var allForms = pagefields; //beta for crwd multisections

        //get all fields, setup object so that is can be read into reactSelect as an optGroup.
        //ex. obj ({label: labelName, options:[{key:value}]), label is groupName with options being an array of fields.
        allForms.forEach((c) => {
            c.form.forEach((f) => {
                var _f = f;
                let temparray = [];
                f.groups.forEach((g) => {

                    g.fields.forEach((k) => {
                        var _g = g
                        var _form = _f; //dont allow time datatypes
                        if (k.type !== "Time" && k.type !== 'Signature') {
                            temparray.push({ label: `${k.name} (${_form.name} / ${_g.name})`, value: k.id, type: k.type, lastrecord:k.last_record_parent_table }); //push 'props' that are same 'name' as label and value for optgroup--select.js.These are the items
                            allFields.push({
                                id: k.id,
                                type: k.type,
                                parent: c.parent,
                                values_csv: k.values_csv,
                                lastrecord: k.last_record_parent_table,
                                userdropdown: k.user_dropdown,
                            });
                        }
                    });
                });
                let form = { label: f.name + " (" + c.name + ")", options: temparray, section: c.id }; //push values into a new array with items. We know have all props for "select.js" optGroup case
                allFormFields.push(form);
            }); 
        });

        let temp = [];
        advancedsearch.forEach((as) => {
            as.advancedFilter.forEach((af) => {
                if (af.advancedsearch === advancedsearchID[0].id) {
                    if(af){// && af.field){//noncontactfields
                        temp.push({ id: af.id, fieldid: af.field, section: as.section, enabled:af.enabled});
                    }
                    // else{//contactFilterStuff if we decide to use in future
                    //     temp.push({ id: af.id, fieldid: af.contact_field, section: as.section, enabled:af.enabled});
                    // }
                    
                }
            });
        });

        //get all section names
        var getSectionNames = sections
            .sort((a, b) => (a.order < b.order ? -1 : a.order > b.order ? 1 : 0))
            .map((sec) => {
                return sec;
            });

        this.state = {
            formFields: [...new Set(allFormFields)],
            shownFilters: [...new Set(temp)],
            sectionNames: [...new Set(getSectionNames)],
            error: false,
            filterState: null,
            loadingRecords: false,
            justFields: allFields,
            warningOpen: false,
            filterToDelete: null,
            filterName: null,
            filterExpanded: false, 
            message:"",
            editableby:""
        };

        // I don't even... use time for form key. This new key on a form from an orm causes the page to rerender
        this.formKey = window.performance.now();
    }
    typingTimer; //dummy var for setting a delay on saving in saveQueryText function
    
    componentDidMount() {
        const { advancedsearchID, page, authState } = this.props;
 
        var load = window.location.href;
        if (load.includes("&=load")) {
            this.setLoadingRecords(true);

            if (load.includes("&view")) {
                this.setState({ filterExpanded: true });
            } else {
                // set loading on table as we are automatically loading
                this.props.autoLoadingRecords(true);
            }
            try {
                const res = fetch(`page/${page[0].id}/advancedsearch/${advancedsearchID[0].id}`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Token " + authState.user.auth_token,
                    },
                }).then((e) => {
                    if (e.status === 200) {
                        e.json().then((data) => {
                            this.props.filteredData(data.message);
                        });
                        this.setLoadingRecords(false);
                        //turn off table loading
                        if (!load.includes("&view")) this.props.autoLoadingRecords(false);
                    } else {
                        this.setLoadingRecords(false);
                    }
                });
                console.log(res);
            } catch {
                this.setLoadingRecords(false);
            }
        } else {
            this.setState({ filterExpanded: true });
        }
    }

    // Delay upon saving text field
    // This removes the "jumping"
    saveQueryText = (value, id, editableby) => {
        const { ormAdvancedFilterUpdatePublic, authState, ormAdvancedSearchUpdatePublic, advancedsearchID } = this.props;

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function () {
            //one sec delay for saving, used for keypressing delays 
            ormAdvancedFilterUpdatePublic({
                id: id, query_text: value 
            }).then((v)=>{
                ormAdvancedSearchUpdatePublic({ id: advancedsearchID[0].id, 
                    editcreatorid: editableby !== "All Except External Read Only Users" ? authState.user.id: null}) 
            });
        }, 1000);
    };
    saveQueryText2 = (value, id, editableby) => {
        const { ormAdvancedFilterUpdatePublic, authState, ormAdvancedSearchUpdatePublic, advancedsearchID } = this.props;

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function () {
            //one sec delay for saving, used for keypressing delays
            ormAdvancedFilterUpdatePublic({
                id: id, query_text2: value 
            }).then((v)=>{
                ormAdvancedSearchUpdatePublic({ id: advancedsearchID[0].id, 
                    editcreatorid: editableby !== "All Except External Read Only Users" ? authState.user.id: null}) 
            });
        }, 1000);
    };

    saveNameText = (value, id, editableby) => {
        const { ormAdvancedSearchUpdate, authState } = this.props;

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(function () {
            //one sec delay for saving, used for keypressing delays
            ormAdvancedSearchUpdate({ id: id, name: value, editcreatorid: editableby !== "All Except External Read Only Users" ? authState.user.id: null });
        }, 1000);
    };

    defaultValues = () => {
        const { advancedsearch, fields/*, contact_fields*/ } = this.props;

        var defaultValues = {}; //needs to be an obj for form

        if (advancedsearch) {
            advancedsearch.forEach((filt) => {
                filt.advancedFilter.forEach((filter, index) => {
                    //get field name as advancedFilter table only has FK
                    let actField = fields.find((f) => (f.id === filter.field ? f.name : null));
              
                    if (actField) defaultValues["field_" + filter.id] = filter.fieldname;

                    defaultValues["filter_type_" + filter.id] = filter.filter_type;
                    defaultValues["query_text_" + filter.id] = filter.query_text;
                    defaultValues["query_text2_" + filter.id] = filter.query_text2; //used for second date
                    defaultValues["zero_records_" + filter.id] = filter.zero_records; //used for second date
                    defaultValues["enabled_" + filter.id] = filter.enabled;
                     
                });
                defaultValues["name_" + filt.id] = filt.name;
                defaultValues["section_id_" + filt.id] = filt.section;
                defaultValues["and_or_" + filt.id] = filt.and_or;
                defaultValues["existing"] = null;
                defaultValues["last_record_" + filt.id] = filt.last_record;
            });
        }

        return defaultValues;
    };

    MAKE_OPTIONS = (type) => {
        var table;

        if (["Text"].includes(type)) {
            table = [
                "Contains",
                "Does Not Contain",
                "Equal",
                "Not Equal",
                "Include",
                "Does Not Include",  
                "Is Empty",
                "Is Not Empty",
                "Is in List",
                "Is Not in List",

            ];
        }
        else if (["Yes/No", "Email", "Radio", "Phone Number", "Primary Mirror"].includes(type)) {
            table = [
                "Contains",
                "Does Not Contain",
                "Equal",
                "Not Equal",
                "Include",
                "Does Not Include", 
                // "Is Null",
                // "Is Not Null",
                "Is Empty",
                "Is Not Empty",
            ];
        }
        else if (["Multi Select", "Drop Down"].includes(type)) {
            table = [
                "Contains",
                "Does Not Contain",
                "Equal",
                "Not Equal",
                "Include",
                "Does Not Include",
                "Is in List",
                "Is Not in List",
                // "Is Null",
                // "Is Not Null",
                "Is Empty",
                "Is Not Empty",
            ];
        }
        else if (["Related Mirror"].includes(type)) {
            table = [
                "Contains",
                "Does Not Contain",
                "Equal",
                "Not Equal",
                "Include",
            ];
        } 
        else if (type === "Date") {
            table = [
                "Is",
                // "Is Null",
                "Is Empty",
                "Is Not",
                "Is Before",
                "Empty Or Is Before",
                "Is After",
                "Empty Or Is After",
                "Is Between",
                "Is Within the Last",
                "Is Before the Last",
                "Is Within the Next",
                "Is After the Next",
                "Is Within the Current",
                "Is After the Current",
                "Is Before the Current",
            ];
        } else if (["Currency", "Numeric", "Calculated", "Incremental"].includes(type)) {
            table = [
                "Equal",
                "Not Equal",
                "Include",
                "Does Not Include",
                "Is Empty",
                "Is Not Empty",
                "Greater Than",
                "Greater Than or Equal",
                "Less Than",
                "Less Than or Equal",
                "Between",
                "Not Between",
            ];
        }

        if (!table) table = type; //date selection cases
        return table.map((row) => ({
            label: row,
            value: row,
        }));
    };

    MAKE_OPTIONS_SECTIONS = (table) => {
        return table.map((sec) => ({
            label: sec.name,
            value: sec.id,
        }));
    };

    //update the parent/section fields to be just the parent and section selected, OCCURS after selecting section
    getParentSectionFormFields = (sectionID) => {
        const { pagefields } = this.props;

        var allFields = [];
        let allFormFields = [];

        /*if (authState.user.city_id === 10 && sectionID !== parent.id)*/ var allForms = pagefields; //beta for crwd--show all fields if we are on a child sectino

        //get all fields, setup object so that is can be read into reactSelect as an optGroup.
        //ex. obj ({label: labelName, options:[{key:value}]), label is groupName with options being an array of fields.
        allForms.forEach((c) => {
            c.form.forEach((f) => {
                var _f = f;
                let temparray = [];
                f.groups.forEach((g) => {
                    g.fields.forEach((k) => {
                        var _g = g
                        var _form = _f;
                        if (k.type !== "Time") {
                            //dont allow time datatypes
                            temparray.push({ label: `${k.name} (${_form.name} / ${_g.name})`, value: k.id, type: k.type }); //push 'props' that are same 'name' as label and value for optgroup--select.js.These are the items
                            allFields.push({
                                id: k.id,
                                type: k.type,
                                parent: c.parent,
                                values_csv: k.values_csv,
                                lastrecord: k.last_record_parent_table,
                                userdropdown: k.user_dropdown,
                            }); //update fields list
                        }
                    });
                });
                let form = { label: f.name + " (" + c.name + ")", options: temparray, parent: c.parent, section: f.section }; //push values into a new array with items. We know have all props for "select.js" optGroup case
                allFormFields.push(form);
            });
        });
        //pass up to send to advanced search settings for initial form fields loading properly
        this.props.getFormFields(allFormFields);
        this.setState((state) => ({
            formFields: [...new Set(allFormFields)], 
            justFields: allFields,
        }));
    };
    setLoadingRecords = (e) => {
        this.setState({ loadingRecords: e });
    };
    //value function for dropdown datatype. This is the options property.
    dropDownValues = (table, valuesCsv) => {
        let tempArr = [];

        //has numbers with commas escapes for thousand values
        if (table && table.values_csv.includes("\\,")) {
            let str = table.values_csv;
            let wtf = "";
            let shouldPass = false;
            for (let i = 0; i < str.length; i++) {
                if (str[i] === "\\") {
                    shouldPass = true;
                } else if (shouldPass) {
                    //record this comma as & before it
                    wtf = wtf + str[i];
                    shouldPass = false;
                } else if (str[i] === ",") {
                    //not & before it so record selection
                    tempArr.push(wtf);
                    wtf = "";
                } else {
                    wtf = wtf + str[i];
                    if (i === str.length - 1) {
                        //last selection save
                        tempArr.push(wtf);
                    }
                }
            }
        } else {
            //no numbers with & escape characters so just split
            tempArr = table.values_csv.split(",");
        }

        if (valuesCsv){
            return tempArr
        }

        return tempArr.map((row) => ({
            label: row,
            value: row,
        }));
    };
    // we check what options should be shown on the UI for the filter type
    filterOptions = (advS, advF, type, disabled, editableby) => {
        const { ormAdvancedFilterUpdate, user, advancedsearchID, authState, ormAdvancedSearchUpdate } = this.props;
        const { justFields } = this.state;

        var searchText;
        const isbetween = ["Between", "Not Between"];
        //Determine the search term windows to display based on "Filter Type"
        if (isbetween.includes(advF.filter_type)) {
            searchText = "Search Start Term";
        } else {
            if(type && type.type === 'Text' && (advF.filter_type === 'Is Not in List' || advF.filter_type === 'Is in List')){
                searchText = advF.filter_type === 'Is Not in List' ? "Search Term: seperate values to exclude with a comma (ex. lake,river)": "Search Term: seperate values to include with a comma (ex. lake,river)" ;
            }else{
                searchText = "Search Term";
            }
           
        }

        if (type && type.type !== "Date")
            switch (advF.filter_type) {
                case "Equal":
                case "Not Equal":
                case "Greater Than":
                case "Greater Than or Equal":
                case "Less Than":
                case "Less Than or Equal":
                    return (
                        <>
                            {(type.type === "Text" ||
                                type.type === "Phone Number" ||
                                type.type === "Email" ||
                                type.type === "Currency" ||
                                type.type === "Incremental" ||
                                type.type === "Numeric" ||
                                type.type === "Calculated") && (
                                <Grid item xs={2} style={{ marginTop: 16 }}>
                                    <TextField
                                        key={"query_text_" + advF.id}
                                        field={"query_text_" + advF.id}
                                        label={searchText}
                                        disabled={disabled || !advF.enabled}
                                        fullWidth
                                        inputRef={(input) => (this["query_text_" + advF.id] = input)}
                                        eventHandle={(value) => {
                                            this.saveQueryText(value, advF.id, editableby);
                                        }}
                                    />
                                </Grid>
                            )}
                            {(type.type === "Multi Select" || type.type === "Drop Down") && (
                                <Grid item xs={2} xl={2} style={{ marginTop: 16 }}>
                                    <Select
                                        key={"query_text_" + advF.id}
                                        field={"query_text_" + advF.id}
                                        options={this.MAKE_OPTIONS(type.userdropdown ? user.map((u) => u.name) : type.values_csv.split(","))}
                                        label="Select Value"
                                        fullWidth
                                        disabled={disabled || !advF.enabled}
                                        eventHandle={(value) => {
                                            ormAdvancedFilterUpdate({ id: advF.id, query_text: value });
                                            ormAdvancedSearchUpdate({ id: advancedsearchID[0].id, 
                                                editcreatorid: editableby !== "All Except External Read Only Users" ? authState.user.id: null})
                                            this.formKey = window.performance.now();
                                        }}
                                    />   
                                    {/* <ReactSelect
                                               key={"query_text_" + advF.id}
                                                // label="Field"
                                                label={
                                                    <HelpLabel
                                                        inputLabel={"Field"}
                                                        helpText={`${advF.fieldname}`}
                                                    />
                                                }
                                                field={"query_text_" + advF.id}
                                                options={this.dropDownValues( type )}
                                                isMulti={true}
                                                disabled={disabled || !advF.enabled}
                                                eventHandle={(value) => {
                                                    ormAdvancedFilterUpdate({ id: advF.id, query_text: value });
                                                    this.formKey = window.performance.now();
                                                }}
                                                valuesCsv={this.dropDownValues( type )}
                                            />  */}
                                </Grid>
                            )}
                            {(type.type === "Yes/No" || type.type === "Radio") && (
                                <Grid item xs={2} xl={2} style={{ marginTop: "2%", whiteSpace: "nowrap" }}>
                                    <RadioGroup
                                        key={"query_text_" + advF.id}
                                        field={"query_text_" + advF.id}
                                        options={this.MAKE_OPTIONS(type.type === "Yes/No" ? ["Yes", "No"] : type.values_csv.split(","))}
                                        alignment={true}
                                        // label={type.type}
                                        disabled={disabled || !advF.enabled}
                                        eventHandle={(value) => {
                                            ormAdvancedFilterUpdate({ id: advF.id, query_text: value });
                                            ormAdvancedSearchUpdate({ id: advancedsearchID[0].id, 
                                                editcreatorid: editableby !== "All Except External Read Only Users" ? authState.user.id: null})
                                            this.formKey = window.performance.now();
                                        }}
                                    />
                                </Grid>
                            )}
                        </>
                    );
                case "Contains":
                case "Does Not Contain":
                case "Include":
                case "Does Not Include":
                    return (
                        <>
                            {(type.type === "Text" ||
                                type.type === "Phone Number" ||
                                type.type === "Email" ||
                                type.type === "Currency" ||
                                type.type === "Incremental" ||
                                type.type === "Numeric" ||
                                type.type === "Calculated" ||
                                type.type === "Multi Select" ||
                                type.type === "Drop Down" ) && (
                                <Grid item xs={2} style={{ marginTop: 16 }}>
                                    <TextField
                                        key={"query_text_" + advF.id}
                                        field={"query_text_" + advF.id}
                                        label={searchText}
                                        disabled={disabled || !advF.enabled}
                                        fullWidth
                                        inputRef={(input) => (this["query_text_" + advF.id] = input)}
                                        eventHandle={(value) => {
                                            this.saveQueryText(value, advF.id, editableby);
                                        }}
                                    />
                                </Grid>
                            )}
                            {(type.type === "Yes/No" || type.type === "Radio") && (
                                <Grid item xs={2} xl={2} style={{ marginTop: "2%", whiteSpace: "nowrap" }}>
                                    <RadioGroup
                                        key={"query_text_" + advF.id}
                                        field={"query_text_" + advF.id}
                                        options={this.MAKE_OPTIONS(type.type === "Yes/No" ? ["Yes", "No"] : type.values_csv.split(","))}
                                        alignment={true}
                                        // label={type.type}
                                        disabled={disabled || !advF.enabled}
                                        eventHandle={(value) => {
                                            ormAdvancedFilterUpdate({ id: advF.id, query_text: value });
                                            ormAdvancedSearchUpdate({ id: advancedsearchID[0].id, 
                                                editcreatorid: editableby !== "All Except External Read Only Users" ? authState.user.id: null})
                                            this.formKey = window.performance.now();
                                        }}
                                    />
                                </Grid>
                            )}
                        </>
                    );
                case "Between":
                case "Not Between":
                    return (
                        <>
                            <Grid item xs={2} style={{ marginTop: 16 }}>
                                <TextField
                                    key={"query_text_" + advF.id}
                                    field={"query_text_" + advF.id}
                                    label={searchText}
                                    fullWidth
                                    disabled={disabled || !advF.enabled}
                                    inputRef={(input) => (this["query_text_" + advF.id] = input)}
                                    eventHandle={(value) => {
                                        this.saveQueryText(value, advF.id, editableby);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2} style={{ marginTop: 16 }}>
                                <TextField
                                    key={"query_text2_" + advF.id}
                                    field={"query_text2_" + advF.id}
                                    label="Search End Term"
                                    fullWidth
                                    disabled={disabled || !advF.enabled}
                                    inputRef={(input) => (this["query_text2_" + advF.id] = input)}
                                    eventHandle={(value) => {
                                        this.saveQueryText2(value, advF.id, editableby);
                                    }}
                                />
                            </Grid>
                        </>
                    );

                case "Is in List":
                case "Is Not in List":
                        
                    if(type.type === "Text"){
                        return(
                            <Grid item xs={4} style={{ marginTop: 16 }}>
                                <TextField
                                    key={"query_text_" + advF.id}
                                    field={"query_text_" + advF.id}
                                    label={searchText}
                                    disabled={disabled || !advF.enabled}
                                    fullWidth
                                    inputRef={(input) => (this["query_text_" + advF.id] = input)}
                                    eventHandle={(value) => {
                                        this.saveQueryText(value, advF.id, editableby);
                                    }}
                                />
                            </Grid>
                    )}else{ 
                        let tempArr = [];
                        //has numbers with commas escapes for thousand values
                        if (type.values_csv.includes("\\,")) {
                            let str = type.values_csv;
                            let wtf = "";
                            let shouldPass = false;
                            for (let i = 0; i < str.length; i++) {
                                if (str[i] === "\\") {
                                    shouldPass = true;
                                } else if (shouldPass) {
                                    //record this comma as & before it
                                    wtf = wtf + str[i];
                                    shouldPass = false;
                                } else if (str[i] === ",") {
                                    //not & before it so record selection
                                    tempArr.push(wtf);
                                    wtf = "";
                                } else {
                                    wtf = wtf + str[i];
                                    if (i === str.length - 1) {
                                        //last selection save
                                        tempArr.push(wtf);
                                    }
                                }
                            }
                        } else {
                            //no numbers with & escape characters so just split
                            tempArr = type.values_csv.split(",");
                        }
                        return (  
                            <Grid item xs={4} xl={4} style={{ marginTop: 16 }}>
                                <ReactSelect
                                    key={"query_text_" + advF.id}
                                    label="Select Value"
                                    field={"query_text_" + advF.id}
                                    options={this.dropDownValues(type)} 
                                    isMulti={true}
                                    disabled={disabled || !advF.enabled}
                                    eventHandle={(value) => {
                                        if (value === " ") value = null
                                        ormAdvancedFilterUpdate({ id: advF.id, query_text: value });
                                        ormAdvancedSearchUpdate({ id: advancedsearchID[0].id, 
                                            editcreatorid: editableby !== "All Except External Read Only Users" ? authState.user.id: null})
                                        this.formKey = window.performance.now();
                                    }}
                                    valuesCsv={tempArr} //values for select field removing the \, possibilities
                                />  
                            </Grid>  
                        );
                    }
                case "Is Empty":
                case "Is Not Empty":
                    break;
                default:
            }

        //used for only showing 'has 0 records' check box
        var isParent = justFields.find((s) => s.id === advF.field);
        // date cases as all switch cases werent above
        switch (advF.filter_type) {
            case "Is":
            case "Is Not":
            case "Is Before":
            case "Empty Or Is Before":
            case "Is or is Before":
            case "Is After":
            case "Empty Or Is After":
            case "Is or is After":
                return (
                    <>
                        <Grid
                            item
                            xs={2}
                            style={{
                                marginTop: 16,
                                marginRight: "-3%",
                            }}>
                            <DatePicker
                                key={"query_text_" + advF.id}
                                field={"query_text_" + advF.id}
                                label="Start Date"
                                autoPopulate={true}
                                advancedSearch={true}
                                disabled={disabled || !advF.enabled}
                                eventHandle={(date) => {
                                    ormAdvancedFilterUpdate({ id: advF.id, query_text: date });
                                    ormAdvancedSearchUpdate({ id: advancedsearchID[0].id, 
                                        editcreatorid: editableby !== "All Except External Read Only Users" ? authState.user.id: null})
                                    this.formKey = window.performance.now();
                                }}
                            />
                        </Grid>
                        {!isParent.parent && advS.and_or === "AND" && !advS.last_record && (
                            <Grid
                                item
                                xs={2}
                                style={{
                                    marginTop: "1.5%",
                                    paddingLeft: "3%",
                                    marginRight: "-4%",
                                    whiteSpace: "nowrap",
                                }}>
                                <Checkbox
                                    field={"zero_records_" + advF.id}
                                    // disabled={disabled}
                                    eventHandle={(value) => {
                                        ormAdvancedFilterUpdate({ id: advF.id, zero_records: value });
                                        ormAdvancedSearchUpdate({ id: advancedsearchID[0].id, 
                                            editcreatorid: editableby !== "All Except External Read Only Users" ? authState.user.id: null})
                                        this.formKey = window.performance.now();
                                    }}>
                                    <HelpLabel
                                        inputLabel="Has 'No' Records?"
                                        helpText={`Search will query the database, only primary records that have 0 results from 'this' field will be returned. This field is only available on related 'date' sections

                                    Note: a primary field must be included 'after' this field for proper results.
                                    
                                    `}
                                    />
                                </Checkbox>
                            </Grid>
                        )}
                    </>
                );
            case "Is Between":
                return (
                    <>
                        <Grid
                            item
                            xs={2}
                            style={{
                                marginTop: 16,
                                marginRight: "-7%",
                            }}>
                            <DatePicker
                                key={"query_text_" + advF.id}
                                field={"query_text_" + advF.id}
                                label="Start Date"
                                disabled={disabled || !advF.enabled}
                                autoPopulate={true}
                                advancedSearch={true}
                                eventHandle={(date) => {
                                    ormAdvancedFilterUpdate({ id: advF.id, query_text: date });
                                    ormAdvancedSearchUpdate({ id: advancedsearchID[0].id, 
                                        editcreatorid: editableby !== "All Except External Read Only Users" ? authState.user.id: null})
                                    this.formKey = window.performance.now();
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            style={{
                                marginTop: 16,
                                marginLeft: "6%",
                            }}>
                            <DatePicker
                                key={"query_text2_" + advF.id}
                                field={"query_text2_" + advF.id}
                                autoPopulate={true}
                                label="End Date"
                                disabled={disabled || !advF.enabled}
                                advancedSearch={true}
                                eventHandle={(date) => {
                                    // if (date !== "Invalid Date") {
                                    ormAdvancedFilterUpdate({ id: advF.id, query_text2: date });
                                    ormAdvancedSearchUpdate({ id: advancedsearchID[0].id, 
                                        editcreatorid: editableby !== "All Except External Read Only Users" ? authState.user.id: null})
                                    this.formKey = window.performance.now();
                                    // }
                                }}
                            />
                        </Grid>
                        {!isParent.parent && advS.and_or === "AND" && !advS.last_record && (
                            <Grid
                                item
                                xs={2}
                                style={{
                                    marginTop: "2%",
                                    paddingLeft: "1%",
                                    marginRight: "-7%",
                                }}>
                                <Checkbox
                                    field={"zero_records_" + advF.id}
                                    // disabled={disabled}
                                    eventHandle={(value) => {
                                        ormAdvancedFilterUpdate({ id: advF.id, zero_records: value });
                                        ormAdvancedSearchUpdate({ id: advancedsearchID[0].id, 
                                            editcreatorid: editableby !== "All Except External Read Only Users" ? authState.user.id: null})
                                        this.formKey = window.performance.now();
                                    }}>
                                    <HelpLabel
                                        inputLabel="Has 'No' Records?"
                                        helpText={`Search will query the database, only primary records that have 0 results from 'this' field will be returned. This field is only available on related 'date' sections

                                    Note: a primary field must be included 'after' this field for proper results.
                                    
                                    `}
                                    />
                                </Checkbox>
                            </Grid>
                        )}
                    </>
                );
            case "Is Within the Last":
            case "Is Before the Last":
            case "Is Within the Next":
            case "Is After the Next":
                var variableDateOptions = ["Days", "Weeks", "Months", "Years"];

                return (
                    <>
                        <Grid item xs={2} style={{ marginTop: 16 }}>
                            <TextField
                                key={"query_text_" + advF.id}
                                field={"query_text_" + advF.id}
                                label={
                                    <HelpLabel
                                        inputLabel="Value"
                                        helpText="This value must be a whole number (ex. 3, 4, etc). It can not be a decimal number (ex. 3.8, etc)."
                                    />
                                }
                                disabled={disabled || !advF.enabled}
                                fullWidth
                                inputRef={(input) => (this["query_text_" + advF.id] = input)}
                                eventHandle={(value) => {
                                    value = parseInt(value); //will take away decimals
                                    this.saveQueryText(value, advF.id, editableby);
                                }}
                            />
                        </Grid>
                        <Grid item xs={2} xl={2} style={{ marginTop: 16 }}>
                            <Select
                                key={"query_text2_" + advF.id}
                                field={"query_text2_" + advF.id}
                                options={this.MAKE_OPTIONS(variableDateOptions)}
                                label="Timeline"
                                fullWidth
                                disabled={disabled || !advF.enabled}
                                eventHandle={(value) => {
                                    ormAdvancedFilterUpdate({ id: advF.id, query_text2: value });
                                    ormAdvancedSearchUpdate({ id: advancedsearchID[0].id, 
                                        editcreatorid: editableby !== "All Except External Read Only Users" ? authState.user.id: null})
                                    this.formKey = window.performance.now();
                                }}
                            />
                        </Grid>
                        {!isParent.parent && advS.and_or === "AND" && !advS.last_record && (
                            <Grid
                                item
                                xs={2}
                                style={{
                                    marginTop: "2%",
                                    paddingLeft: "1%",
                                    marginRight: "-7%",
                                }}>
                                <Checkbox
                                    field={"zero_records_" + advF.id}
                                    // disabled={disabled}
                                    eventHandle={(value) => {
                                        ormAdvancedFilterUpdate({ id: advF.id, zero_records: value });
                                        ormAdvancedSearchUpdate({ id: advancedsearchID[0].id, 
                                            editcreatorid: editableby !== "All Except External Read Only Users" ? authState.user.id: null})
                                        this.formKey = window.performance.now();
                                    }}>
                                    <HelpLabel
                                        inputLabel="Has 'No' Records?"
                                        helpText={`Search will query the database, only primary records that have 0 results from 'this' field will be returned. This field is only available on related 'date' sections

                                    Note: a primary field must be included 'after' this field for proper results.
                                    
                                    `}
                                    />
                                </Checkbox>
                            </Grid>
                        )}
                    </>
                );
            case "Is Within the Current":
            case "Is Before the Current":
            case "Is After the Current":
                var anchoredDateOptions = ["Day", "Week", "Month", "Quarter", "Year"];
                return (
                    <>
                        <Grid item xs={2} xl={2} style={{ marginTop: 16 }}>
                            <Select
                                key={"query_text_" + advF.id}
                                field={"query_text_" + advF.id}
                                options={this.MAKE_OPTIONS(anchoredDateOptions)}
                                label="Predefined Period"
                                fullWidth
                                disabled={disabled || !advF.enabled}
                                eventHandle={(value) => {
                                    ormAdvancedFilterUpdate({ id: advF.id, query_text: value });
                                    ormAdvancedSearchUpdate({ id: advancedsearchID[0].id, 
                                        editcreatorid: editableby !== "All Except External Read Only Users" ? authState.user.id: null})
                                    this.formKey = window.performance.now();
                                }}
                            />
                        </Grid>
                        {!isParent.parent && advS.and_or === "AND" && !advS.last_record && (
                            <Grid
                                item
                                xs={2}
                                style={{
                                    marginTop: "1.5%",
                                    paddingLeft: "2%",
                                    marginRight: "-5%",
                                    whiteSpace: "nowrap",
                                }}>
                                <Checkbox
                                    field={"zero_records_" + advF.id}
                                    // disabled={disabled}
                                    eventHandle={(value) => {
                                        ormAdvancedFilterUpdate({ id: advF.id, zero_records: value });
                                        ormAdvancedSearchUpdate({ id: advancedsearchID[0].id, 
                                            editcreatorid: editableby !== "All Except External Read Only Users" ? authState.user.id: null})
                                        this.formKey = window.performance.now();
                                    }}>
                                    <HelpLabel
                                        inputLabel="Has 'No' Records?"
                                        helpText={`If checked, this option will return results when Related records do not have a record within the specified Date search criteria. This is useful for generating results for when an activity, such as an inspection, must occur every week, month, year, etc. Results are returned where Primary records do not have a Related record in the selected section.

                                        The Related Date field must be followed by a Primary record field. Example: “Record ID” is not null."
                                    
                                    `}
                                    />
                                </Checkbox>
                            </Grid>
                        )}
                    </>
                );
            default:
        }
    };

    getUpdatedEnabledFields = () => {
        const {advancedsearch, advancedsearchID/*,contact_fields*/} = this.props;

        let temp = [];
        advancedsearch.forEach((as) => {
            as.advancedFilter.forEach((af) => {
                if (af.advancedsearch === advancedsearchID[0].id) {
                    //record and repeatablefields
                    if (af){// && af.field){
                        temp.push({ id: af.id, fieldid: af.field, section: as.section, enabled:af.enabled});
                    }
                    //contactFilterStuff if we decide to use in future
                    // else{//contactfields

                    //     let contact = contact_fields.find(f=>(f.id === af.contact_field ? f: null))
                    //     if(contact) temp.push({ id: af.id, fieldid: contact.id, section: as.section, enabled:af.enabled});
                    // }
                    
                }
            });
        });
        // this.setState({shownFilters: [...new Set(temp)]})
        this.setState({shownFilters: temp})

    }

    checkFieldsWithSection = (input) => {
        const { shownFilters,  formFields } = this.state;
        // const { sections } = this.props;
        var hasSectionField = false //hasParentField = false //, hasLastRecord = false;
        
        if (input === 'checkall') {
            formFields.forEach((ff) => {
                return ff.options.find((op) => {
                    return shownFilters.find((sf) => {
                        return sf.fieldid === op.value && ff.section === sf.section && sf.enabled ? (hasSectionField = true) : null;
                    });
                });
            });
         
            return hasSectionField
       
        }

    };

    render() {
        const {
            classes,
            page,  
            advancedsearchID,
            advancedsearch,
            ormAdvancedSearchUpdate, 
            ormAdvancedFilterCreate,
            ormAdvancedFilterDelete,
            // ormAdvancedFilterUpdatePublic, 
            ormAdvancedSearchUpdatePublic,
            ormAdvancedFilterUpdate,
            ormAdvancedFilterThenUpdate,
            authState,
            sections,
            permission,
        } = this.props;
        const {
            formFields,
            shownFilters,
            sectionNames,
            error,
            loadingRecords,
            justFields,
            warningOpen,
            filterToDelete,
            filterName,
            filterExpanded, 
            message,
        } = this.state;

        //used for 'most recent chk box'
        var sect = sections.find((s) => {
            return s.id === advancedsearchID[0].section;
        });
        var userPermission = permission.find((p) => p.user === authState.user.id);
        var disabled; //weather or not to show user having edit previliges
        advancedsearch.forEach((a) => {
            if (
                (a.editableby === "Creator" && parseInt(a.editcreatorid) === authState.user.id) ||
                (a.editableby === "Admin" && authState.user.is_city_admin.toString() === "true") ||
                a.editableby === "All" ||
                (a.editableby === "All Except External Read Only Users" && !userPermission.readonlyexternal)
            ) {
                return (disabled = false);
            } else {
                return (disabled = true);
            }
        });

        //figure out who edited it last
        var editableby = '', lastupdated = null; 
        advancedsearch.forEach((a) => {
            if(a.editableby !== "All Except External Read Only Users"){
                a.editcreatorid !== ''? editableby = a.editcreatorid : editableby = "MS4Front" 
            }else{//'All except external read only users case
                editableby = a.editableby
            }
            let date = new Date(a.lastupdated);
            lastupdated = " " + date.toLocaleDateString() 
        });
        if (parseInt(editableby)){
            editableby = permission.find(p=>p.user === parseInt(editableby)? p.user_label:null) 
            if(editableby !== '' && typeof(editableby) === 'object')editableby = editableby.user_label
        }

        return (
            <>
                <Formm
                    key={this.formKey}
                    validateOnSubmit={true}
                    defaultValues={this.defaultValues()}
                    getApi={(el) => (this.formApi = el)}
                    //onSubmit={values => this.updateFields(values)}
                >
                    {(formApi) => (
                        <form onSubmit={formApi.submitForm}>
                            <Accordion
                                expanded={filterExpanded}
                                onChange={(e) => {
                                    this.setState({ filterExpanded: !filterExpanded });
                                }}
                                style={{ marginBottom: "15px" }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    style={{ backgroundColor: "#ddd" }}
                                    classes={{ root: classes.expandedMargin }}>
                                    <Grid container>
                                        <Grid item xs={12} style={{display:"inline-flex"}}>
                                            <Typography className={classes.heading} style={{ paddingLeft: "0px", fontSize: "1.15rem", whiteSpace: "nowrap" }}>
                                                Search Name:
                                            </Typography>
                                            <TextField
                                                key={"name_" + advancedsearchID[0].id}
                                                style={{
                                                    width: "20%",
                                                    backgroundColor: "#ddd",
                                                    marginTop: "-2px",
                                                    marginBottom: "-6px",
                                                    marginLeft: "14px",
                                                }}
                                                field={"name_" + advancedsearchID[0].id}
                                                placeholder="Enter in name of Search"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                                disabled={disabled}
                                                inputRef={(input) => (this["search_name_input"] = input)}
                                                eventHandle={(value) => {
                                                    this.saveNameText(value, advancedsearchID[0].id, editableby); //used for delay in saving
                                                }}
                                            />
                                            <Typography
                                                className={classes.heading}
                                                style={{ paddingLeft: "20px", fontSize: "1.15rem", whiteSpace: "nowrap" }}>
                                                Section to Search:
                                            </Typography>
                                            <Select
                                                key={"section_id_" + advancedsearchID[0].id}
                                                style={{
                                                    width: "20%",
                                                    marginTop: "-7px",
                                                    marginBottom: "-6px",
                                                    marginLeft: "14px",
                                                }}
                                                field={"section_id_" + advancedsearchID[0].id}
                                                options={this.MAKE_OPTIONS_SECTIONS(sectionNames)}
                                                label
                                                fullWidth
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                                eventHandle={(value) => {
                                                    ormAdvancedSearchUpdate({ id: advancedsearchID[0].id, section: value, editcreatorid: editableby !== "All Except External Read Only Users" ? authState.user.id: null});

                                                    this.getParentSectionFormFields(value); //this method updates field values based on section selected
                                                }}
                                            />
                                            <Typography className={classes.heading} style={{ paddingLeft: "20px", fontSize: "1.15rem", float: "right" }}>
                                                And/Or
                                            </Typography>
                                            <Select
                                                style={{
                                                    width: "10%",
                                                    marginTop: "-7px",
                                                    marginBottom: "-6px",
                                                    marginLeft: "14px",
                                                }}
                                                label
                                                key={"and_or_" + advancedsearchID[0].id}
                                                field={"and_or_" + advancedsearchID[0].id}
                                                options={this.MAKE_OPTIONS(["AND", "OR"])}
                                                fullWidth
                                                disabled={disabled}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                                eventHandle={(value) => {
                                                    ormAdvancedSearchUpdate({ id: advancedsearchID[0].id, and_or: value, editcreatorid: editableby !== "All Except External Read Only Users" ? authState.user.id: null });

                                                    this.formKey = window.performance.now();
                                                }}
                                            />
                                            {sect && !sect.parent && (
                                                <div style={{ whiteSpace: "nowrap" }}>
                                                    <Checkbox
                                                        field={"last_record_" + advancedsearchID[0].id}
                                                        disabled={disabled}
                                                        onClick={(e) => e.stopPropagation()}
                                                        eventHandle={(value) => {
                                                            ormAdvancedSearchUpdate({ id: advancedsearchID[0].id, last_record: value,
                                                                editcreatorid: editableby !== "All Except External Read Only Users" ? authState.user.id: null});
                                                            this.formKey = window.performance.now();
                                                        }}
                                                        style={{
                                                            width: "15%",
                                                            marginTop: -7,
                                                            marginBottom: -6,
                                                            marginLeft: 30,
                                                        }}>
                                                        <HelpLabel
                                                            inputLabel="Use Most Recent?"
                                                            helpText="<b>This option serves two purposes:</b> <br />

                                                            1.) Search the most recent 'related' section record. 
                                                            <li>This option is <u>enabled</u> when <u>recordfields</u> (non-repeatable group fields) have their field configuration settings set to <u>'Show most recent in primary table'</u>. </li>
                                                            <li>Repeatable fields (i.e.repeatable group fields) do not need this option selected and will be searched by the last record if the 'Use Most Recent' check box is selected. </li> 
                                                        
                                                            2.) Selecting this option with <u>other</u> related 'repeatablefields/recordfields' <u>result table fields</u> from another section will result in the first or last record being displayed (i.e. unchecked = first record, checked = last record). 
                                                            <br />
                                                            Note -- bringing in other fields from other related sections should be used sparingly.
                                                            "
                                                        />
                                                    </Checkbox>
                                                </div>
                                            )}
                                         </Grid>
                                         {editableby !== 'All Except External Read Only Users' &&(
                                            <>
                                                <Grid item xs={12} style={{marginTop:4}}>
                                                    <Typography className={classes.heading} style={{ paddingLeft: "0px", fontSize: ".90rem", whiteSpace: "nowrap" }}>
                                                        {`Last Updated by: ${editableby}`}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography className={classes.heading} style={{ paddingLeft: "0px", fontSize: ".90rem", whiteSpace: "nowrap" }}>
                                                        {`Last Updated: ${lastupdated}`}
                                                    </Typography>
                                                </Grid>
                                            </> 
                                         )}
                                      
                                    </Grid>
                                   
                                </AccordionSummary>
                              
                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            {/* FILTERS RENDERING ON EXPANSION PANEL */}
                                            {advancedsearch.map((advS) => {
                                                if (advS.customized && advS.customized !== 15) {
                                                    return "This Saved Search has been set up by Houston Engineering. In order to edit the filters please contact us.";

                                                }
                                                return advS.advancedFilter.map((advF, index) => {
                                                    var thisFilter, type;
                                                    if (shownFilters.length > 0 && (thisFilter = shownFilters.find((f) => f.id === advF.id))) {
                                                        type = justFields.find((f) => f.id === thisFilter.fieldid); //get the 'datatype' for showing proper drop down below
                                                        return (
                                                            <Grid
                                                                key={advF.id}
                                                                container
                                                                spacing={3}
                                                                alignItems="center"
                                                                style={{ marginTop: -13, marginBottom: -15, borderBottom: ".1px solid lightgray" }}>
                                                                {userPermission && userPermission.fullaccess === true && (
                                                                    <Grid
                                                                        item
                                                                        xs={1}
                                                                        style={{
                                                                            maxWidth: "2%",
                                                                        }}>
                                                                        <Tooltip title="Permanently Delete Filter">
                                                                            <IconButton
                                                                                disabled={disabled}
                                                                                className={classes.delete}
                                                                                onClick={(e) => {
                                                                                    this.setState({
                                                                                        warningOpen: true,
                                                                                        filterToDelete: advF.id,
                                                                                        filterName: advF.fieldname,
                                                                                        editableby:editableby
                                                                                    });
                                                                                }}>
                                                                                <DeleteIcon
                                                                                    className={classes.deleteIcon}
                                                                                    style={{
                                                                                        color: "#333333",
                                                                                        width: "1.3em",
                                                                                        height: "1.3em",
                                                                                        marginTop: 6,
                                                                                    }}
                                                                                />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                )}
                                                                   {userPermission && userPermission.fullaccess === true && (
                                                                    <Grid
                                                                        item
                                                                        xs={1}
                                                                        style={{
                                                                            maxWidth: "2%",
                                                                        }}>
                                                                        <Tooltip title="Is Filter Active?">
                                                                            <Checkbox
                                                                                field={"enabled_" + advF.id}
                                                                                disabled={disabled}
                                                                                onClick={(e) => e.stopPropagation()}
                                                                                eventHandle={(value) => {
                                                                                    var _this = this;
                                                                                    if (value === false){
                                                                                        //disabled
                                                                                        this.formApi.setValue("enabled_" + advF.id, false) 
                                                                                        ormAdvancedFilterThenUpdate({
                                                                                            id: advF.id,
                                                                                            enabled: false, 
                                                                                        }).then((v)=>{
                                                                                            _this.formKey = window.performance.now();
                                                                                            _this.getUpdatedEnabledFields()
                                                                                        }); 
                                                                                        ormAdvancedSearchUpdate({ id: advancedsearchID[0].id, editcreatorid: editableby !== "All Except External Read Only Users" ? authState.user.id: null})
                                                                                    }
                                                                                    else {
                                                                                        this.formApi.setValue("enabled_" + advF.id, true) 
                                                                                        //active  
                                                                                        ormAdvancedFilterThenUpdate({
                                                                                            id: advF.id,
                                                                                            enabled: true, 
                                                                                        }).then((v)=>{
                                                                                            _this.formKey = window.performance.now();
                                                                                            _this.getUpdatedEnabledFields()
                                                                                        });
                                                                                        ormAdvancedSearchUpdate({ id: advancedsearchID[0].id, editcreatorid: editableby !== "All Except External Read Only Users" ? authState.user.id: null})
                                                                                           
                                                                                        
                                                                                }  
                                                                                }}
                                                                                style={{
                                                                                    width: "15%",
                                                                                    marginTop: 26,
                                                                                    marginBottom: -6,
                                                                                    marginLeft: 16,
                                                                                }}>
                                                                            
                                                                            </Checkbox>
                                                                        </Tooltip>
                                                                
                                                                    </Grid>
                                                                )}

                                                                <Grid item xs={4} xl={3} style={{ marginTop: 14, marginLeft: 10 }}>
                                                            
                                                                    <ReactSelect
                                                                        key={"field_" + advF.id}
                                                                        // label="Field"
                                                                        label={
                                                                            <HelpLabel
                                                                                inputLabel={"Field"}
                                                                                helpText={`${advF.fieldname}`}
                                                                            />
                                                                        }
                                                                        field={"field_" + advF.id}
                                                                        options={formFields}
                                                                        multi={false}
                                                                        disabled={disabled || !advF.enabled}
                                                                        eventHandle={(value) => {
                                                                            if (value === " "){
                                                                                //clear filter as field was deleted 
                                                                                ormAdvancedFilterUpdate({
                                                                                    id: advF.id,
                                                                                    field: null,
                                                                                    fieldname: null,
                                                                                    filter_type: null,
                                                                                    query_text: null,
                                                                                    query_text2: null,
                                                                                })
                                                                                ormAdvancedSearchUpdatePublic({ id: advancedsearchID[0].id, 
                                                                                    editcreatorid: editableby !== "All Except External Read Only Users" ? authState.user.id: null}) 
                                                                             
                                                                            }else {
                                                                                //contactFilterStuff if we decide to use in future
                                                                                // if (typeof(value) !== 'object' &&  value.includes("c_")){
                                                                                //     let name = "";
                                                                                //     contact_fields.forEach((k) => {
                                                                                //         if ("c_" + k.id === value)
                                                                                //         name = k.field_name
                                                                                //      });
                                                                            
                                                                                //     //contact field
                                                                                //     ormAdvancedFilterUpdate({
                                                                                //         id: advF.id,
                                                                                //         fieldname: name,
                                                                                //         contact_field: parseInt(value.replace("c_", "")),
                                                                                //     });  
                                                                                // } else {

                                                                                    //non-contact field  
                                                                                    ormAdvancedFilterUpdate({
                                                                                        id: advF.id,
                                                                                        fieldname: value.label,
                                                                                        field: value.value,
                                                                                    })
                                                                                    ormAdvancedSearchUpdatePublic({ id: advancedsearchID[0].id, 
                                                                                        editcreatorid: editableby !== "All Except External Read Only Users" ? authState.user.id: null}) 
                                                                                 
                                                                                
                                                                            }

                                                                            
                                                                            let section = ""
                                                                            formFields.forEach((ff) => {
                                                                                return ff.options.find((op) => {
                                                                                    return  op.value === value.value ? (section = ff.section) : null
                                                                                });
                                                                            });
                                                                    

                                                                            //update the shown filters so we know the "data type"
                                                                            let newFilters = shownFilters.filter((f) => {
                                                                                if (f.id === advF.id) {
                                                                                    if (value === " ") {
                                                                                        f.fieldid = null;
                                                                                        return f;
                                                                                    } else {
                                                                                        return (f.fieldid = value.value, f.section = section);
                                                                                    }
                                                                                } else return f;
                                                                            });

                                                                            //contactFilterStuff if we decide to use in future
                                                                            // if (typeof(value) !== 'object' && value.includes("c_")) {
                                                                            //     //rerendering of window or reopening search add contacts column fields if available
                                                                            //     let temparray = [];
                                                                            //     contact_fields.forEach((k) => {
                                                                            //         if ("c_" + k.id === value)
                                                                            //         newFilters.push({ id: advF.id, fieldid: k.id, enabled:true});
                                                                            //      });
                                                                              
                                                                            // }
                                                                        

                                                                            //this is the new filters to show on UI
                                                                            this.setState({ shownFilters: newFilters });

                                                                            this.formKey = window.performance.now();
                                                                        }}
                                                                    />  
                                                                </Grid>
                                                                {type && (
                                                                    <Grid item xs={2} xl={2} style={{ marginTop: 16 }}>
                                                                        <Select
                                                                            key={"filter_type_" + advF.id}
                                                                            field={"filter_type_" + advF.id}
                                                                            options={this.MAKE_OPTIONS(type.type)}
                                                                            label="Filter Type"
                                                                            fullWidth
                                                                            disabled={disabled || !advF.enabled}
                                                                            eventHandle={(value) => { 
                                                                                ormAdvancedFilterThenUpdate({
                                                                                    id: advF.id,
                                                                                    filter_type: value 
                                                                                }).then((v)=>{ 
                                                                                        this.formKey = window.performance.now();
                                                                                });
                                                                                ormAdvancedSearchUpdatePublic({ id: advancedsearchID[0].id, 
                                                                                        editcreatorid: editableby !== "All Except External Read Only Users" ? authState.user.id: null}) 
                                                                                
                                                                            }} //manually trigger a save
                                                                        />
                                                                    </Grid>
                                                                )}

                                                                {this.filterOptions(advS, advF, type, disabled, editableby)}
                                                            </Grid>
                                                        );
                                                    } else return null;
                                                });
                                            })}
                                            {/* STANDARD ADD A FILTER TO LIST */}
                                            {userPermission && userPermission.fullaccess === true && (
                                                <Grid container spacing={2} alignItems="center" style={{ marginTop: 23 }}>
                                                    <Grid item xs={5} lg={4} xl={3}> 
                                                        <Button
                                                            fullWidth
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={disabled || advancedsearchID[0].customized || shownFilters.length > 9}
                                                            onClick={(e) => {
                                                                ormAdvancedFilterCreate({
                                                                    advancedsearch_id: advancedsearchID[0].id,//get just section for FK creation
                                                                }).then((adv) => {
                                                                    shownFilters.push({ id: adv.id, fieldid: null, enabled:true }); // show on form after creation
                                                                    this.formApi.setValue("enabled_" + adv.id, true) 
                                                                    this.setState({ shownFilters }); 
                                                                    this.formKey = window.performance.now(); //update active checkbox
                                                                }); 
                                                                
                                                            }}>
                                                            <IconButton className={classes.iconAdd}>
                                                                <AddIcon style={{ marginTop: 8 }} />
                                                            </IconButton>
                                                            &nbsp;&nbsp;&nbsp;Add New Filter
                                                        </Button>
                                                         <Typography className={classes.heading} style={{ display:'flex', fontSize: "1.15rem", justifyContent: "center" }}>
                                                            Limit 10 Filters
                                                        </Typography> 
                                                    </Grid>
                                                  
                                                </Grid>
                                            )}

                                            <Grid
                                                container
                                                spacing={2}
                                                alignItems="center"
                                                style={{
                                                    marginTop: userPermission.fullaccess === false ? 23 : 11,
                                                    marginLeft: userPermission.fullaccess === false ? 0 : -9,
                                                    paddingBottom: userPermission.fullaccess === false ? 10 : 12,
                                                }}>
                                                <Grid item xs={5} lg={4} xl={3}>
                                                    <Button
                                                        fullWidth
                                                        variant="contained"
                                                        color="default"
                                                        style={{ marginTop: 4, backgroundColor: "#ee8018", color: "white" }}
                                                        onClick={(e) => {
                                                            this.setLoadingRecords(true);
                                                                try {
                                                                    const res = fetch(`page/${page[0].id}/advancedsearch/${advancedsearchID[0].id}`, {
                                                                        method: "POST",
                                                                        headers: {
                                                                            "Content-Type": "application/json",
                                                                            Authorization: "Token " + authState.user.auth_token,
                                                                        },
                                                                    }).then((e) => {
                                                                        if (e.status === 200) {
                                                                            e.json().then((data) => {
                                                                                this.props.filteredData(data.message);
                                                                            });
                                                                            this.setLoadingRecords(false);
                                                                        }else if (e.status === 400) { 
                                                                            e.json().then((data) => {
                                                                                this.setState({ error: true, message:data[0] });
                                                                                this.setLoadingRecords(false);
                                                                            });
                                                                        }else if (e.status === 401) { 
                                                                            this.setState({ error: true});
                                                                            this.setLoadingRecords(false); 
                                                                            this.props.history.push("/") 
                                                                            this.props.authLogout();
                                                                        }
                                                                        else {
                                                                            this.setState({ error: true });
                                                                            this.setLoadingRecords(false);
                                                                        }
                                                                    });
                                                                    console.log(res);
                                                                } catch {
                                                                    this.setLoadingRecords(false);
                                                                }
                                                                
                                                            }
                                                        }>
                                                        &nbsp;&nbsp; Search
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                                {error && (
                                    <Snackbar //display snack bar when query fails will be called for any query backend server codes that are !200
                                        snackbarOpen={error}
                                        isSuccess={false}
                                        handleSnackbarClose={() => this.setState({ error: false })} //prop gets called after auto duration on snackbar
                                        missing={"query"}
                                        advSearch={true}
                                        theError={message !== ''? message: "Verify all query fields are properly filled in as zero results returned from the search."}
                                    />
                                )}
                                {loadingRecords && (
                                    <>
                                        <CircularProgress size={80} className={classes.progress} />
                                        <Typography variant="h6" style={{ textAlign: "center" }}>
                                            Retrieving Saved Search Records. Please Wait...
                                        </Typography>
                                    </>
                                )}
                              
                                <WarningDialog
                                    confirmAction={() => {
                                        ormAdvancedFilterDelete(filterToDelete).then(()=>{
                                            var sF = shownFilters.filter((item) => {
                                            //get new array without id for saving
                                            return item.id !== filterToDelete;
                                            });  
                                            this.setState({
                                                shownFilters: sF,
                                                warningOpen: false,
                                                filterToDelete: null,
                                                editableby:""
                                            });
                                        }) 
                                        ormAdvancedSearchUpdatePublic({ id: advancedsearchID[0].id, 
                                            editcreatorid: editableby !== "All Except External Read Only Users" ? authState.user.id: null}) 
                                      
                                    }}
                                    cancelAction={(e) => {
                                        this.setState({ warningOpen: false, deleteSelected: false });
                                    }}
                                    confirmText="Yes"
                                    cancelText="No"
                                    open={warningOpen}
                                    title={`Remove ${filterName ? `'${filterName}'` : "Filter"} from the Search?`}
                                    text={"You are about to permanently delete this filter. Are you sure you want to remove the filter?"}
                                /> 
                            </Accordion>
                        </form>
                    )}
                </Formm>
            </>
        );
    }
}
AdvancedFilters.displayName = "AdvancedFilters";

AdvancedFilters = connect(
    (state, ownProps) => ({
        sections: getSections(state, ownProps),
        fields: allFields(state, ownProps),
        pagefields: getPageFields(state, ownProps),
        advancedfilters: getAdvancedFilters(state, ownProps),
        advancedsearchID: getAdvancedSearchID(state, ownProps),
        advancedsearch: getAdvancedSearchWFilters(state, ownProps),
        user: allUsers(state),
        permission: getPermission(state, ownProps),
    }),
    { ...Filter.actions, ...AdvancedSearch.actions, ...AdvancedFilter.actions, ...AdvancedTableFields.actions }
)(AdvancedFilters);

export default withStyles(styles)(withRouter(AdvancedFilters));
