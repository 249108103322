import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import { withStyles } from "@material-ui/core";

import Header from "./Header";
import Navigation from "./Navigation";
import NotReady from "./NotReady";
import WarningDialog from "./WarningDialog";
import NavigationMobile from "./NavigationMobile";

const styles = (theme) => ({
    appContainer: {
        [theme.breakpoints.up("md")]: {
            // The parent container of the header, sidebar, content
            paddingTop: 80, // 64 = Height of Header; + 16 padding
            height: "calc(100% - 80px)", // Height 100% - padding; This is set so the dashboard map can be 100%
        },
        [theme.breakpoints.down("md")]: {
            height: "calc(100%)",
        },
    },
    contentContainer: {
        // The sibling container to the header, sidebar that contains the main content
        transition: theme.transitions.create("padding", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        "& > div:first-child, & > form:first-child": {
            // Set the padding to the footer on the first child for the content container
            paddingBottom: 60, // 32 = Height of Footer; + 8 padding + 20 extra
        },
        // The spacing on Material UI's grid works great when spacing between grid items, however the container itself is not spaced
        "& > div:first-child, & > form div:first-child": {
            width: "calc(100% - 40px)",
            marginTop: -12, // Assuming spacing = 24 on parent grid container
            marginLeft: 20,
            marginRight: 20,
            marginBottom: 0,
            [theme.breakpoints.down("960")]: {
                marginLeft: 41,
                paddingLeft: 15,
                paddingRight: 15,
            },
        },
    },
    backgroundColor: {
        backgroundColor: "#f0f0f0",
    },
    contentContainerOpen: {
        [theme.breakpoints.up("md")]: {
            [theme.breakpoints.up("lg")]: {
                paddingLeft: 325, // 325 = Width of Sidebar
            },
            [theme.breakpoints.down("md")]: {
                paddingLeft: 275, // 325 = Width of Sidebar
            },
        },
    },
    contentContainerClosed: {
        [theme.breakpoints.up("md")]: {
            paddingLeft: theme.spacing(7), // Width of Closed Sidebar
        },
    },
    height: {
        height: "100%",
    },
    mobileNavigation: {
        [theme.breakpoints.up("960")]: {
            display: "none",
        },
    },
});

class AppContainer extends Component {
    state = {
        warningDialog: false,
    };

    setWarningDialog = () => {
        this.setState({ warningDialog: true });
    };

    render() {
        const { children, classes, authenticated, navState, authState, synced } = this.props;
        const { warningDialog } = this.state;

        return authenticated ? ( // Logged In
            <div className={classNames(classes.appContainer, classes.backgroundColor)}>
                {/* authenticated shows top menu app */}
                <div className={classes.mobileNavigation}>
                    <NavigationMobile warningDialog={this.setWarningDialog} />
                </div>
                <Header authenticated />
                <Navigation warningDialog={this.setWarningDialog} />
                <div
                    className={classNames(
                        classes.contentContainer,
                        classes.height,
                        navState.navigationOpen ? classes.contentContainerOpen : classes.contentContainerClosed
                    )}>
                    {authState.user ? !synced ? children : <NotReady message="Loading..." /> : <NotReady storeReady={navState.storeReady} />}
                    {warningDialog && (
                        //only for summaryreport button in navigation
                        <WarningDialog
                            noActions={true}
                            noDeleteButtons
                            onClose={() => this.setState({ warningDialog: false })}
                            open={warningDialog}
                            title={`Summary Reports have not been Created`}
                            text="Contact MS4Front support staff to ask about adding summary reports to your account."
                        />
                    )}
                </div>
            </div>
        ) : (
            // Public
            <div className={classNames(classes.appContainer, classes.backgroundColor)}>
                <Header /> {/* no props for authenticated doesnt show top menu app */}
                <div className={classes.height}>{children}</div>
            </div>
        );
    }
}
AppContainer.displayName = "AppContainer";

AppContainer = connect((state) => ({
    navState: state.nav,
    authState: state.auth,
}))(AppContainer);

export default withStyles(styles)(AppContainer);
