import React, { Component } from "react";
import { FormField } from "react-form";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";

import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
    input: {
        display: "none",
    },
    inline: {
        display: "inline",
        marginLeft: 16,
    },
    linkColor: {
        color: "#2b6dad",
        textDecoration: "none",
        "&:hover": {
            backgroundColor: "#F9EBC8",
        },
    },
    red: {
        color: "red",
    },
    button: {
        marginTop: 10,
        position: "absolute",
    },
});

// See https://react-form.js.org/#/custom-input
class FileInputWrapper extends Component {
    constructor(props, context) {
        super(props, context);

        var val = this.props.fieldApi.getValue();
        this.state = {
            displayLabel: val,
            useUrl: val ? true : false
        };
    }

    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    handleChange = (e) => {
        const { customaccept } = this.props;
        let value = e.target.files[0];
        if (!value) return;

        var accepted_types;
        if (customaccept) accepted_types = customaccept.replaceAll(".", "").split(",");
        else accepted_types = ["application/hcp","pdf", "tiff", "tif", "png", "jpg", "jpeg", "jpe", "bmp", "ppt", "pptx", "mp4", "mov", "3gp", "msg", "zip", "rar", "xls", "xlsx", "xlb", "doc", "docx", "txt", "rtf", "hcp"];
        var re = /(?:\.([^.]+))?$/;
        var ext = re.exec(value.name)[1].toLowerCase();
        if (!accepted_types.includes(ext)) {
            alert("Invalid File Type. Please upload as a .zip file.");
            return;
        }
        if (value.size > 70000000) {
            alert("File Size is limited to under 70MB.");
            return;
        }
 

        const { fieldApi, eventHandle } = this.props;
        const { setValue } = fieldApi;

        this.setState({ displayLabel: value.name, useUrl: false, base64Loading: true });
        
        //we have to recreate custom files to set the 'type' and then on the backend change the extension. 
        //this way we can save pretty much any file type if coded. Refer to serilaizers.py-->class base64--> to_internal_value function--> file_type if/elif code... as we set the extensions there 
        if (ext === 'hcp'){ 
            value = new File([e.target.files[0]], value.name, {type: 'application/hcp'}) 
        } 

        this.getBase64(value).then((data) => {
            setValue(data);
            this.setState({ base64Loading: false });
            if (eventHandle) {
                eventHandle(value.name);
            }
        });
    };

    removeFile = () => {
        const { fieldApi, eventHandle } = this.props;
        const { setValue } = fieldApi;

        this.setState({ displayLabel: null, useUrl: false });
        setValue(null);
        if (eventHandle) {
            eventHandle(null);
        }
    };

    render() {
        const { classes, label, children, id, fieldApi, customaccept, ...rest } = this.props;
        const { displayLabel, base64Loading, useUrl } = this.state;
        const { getError, getWarning, getSuccess } = fieldApi;

        const error = getError(),
            warning = getWarning(),
            success = getSuccess(),
            hasError = !!error,
            errorText = error || warning || success;

        return (
            <>
                <div style={{ display: "inline" }}>
                    <Typography gutterBottom>{label || children}</Typography>
                    <input
                        accept={customaccept ? customaccept : "application/hcp,.pdf,.doc,.docx,.xls,.xlsx,.png,.jpg,.jpeg,.tiff,.bmp,.ppt,.pptx,.zip,.hcp,.msg"}
                        className={classes.input}
                        id={id}
                        type="file"
                        onChange={this.handleChange}
                        {...rest}
                    />
                    <label htmlFor={id}>
                        {base64Loading && <Typography className={classes.button}>Please wait...</Typography>}
                        
                        <Button
                            disabled={rest.readOnly || base64Loading}
                            raised
                            component="span"
                            className={hasError && !displayLabel && classes.red}>
                            {useUrl ? "Reupload New File" : "Select File"}
                        </Button>
                    </label>
                    <Typography className={classes.inline}>
                        {useUrl && (
                            <>
                                <a
                                    className={classes.linkColor}
                                    href={displayLabel}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    View File
                                </a>
                            </>
                        )}
                        {!useUrl && displayLabel && (
                            <span>{displayLabel}</span>
                        )}

                    </Typography>
                </div>
                {hasError && !displayLabel && (
                    <FormHelperText style={{ display: "inline" }} error={true}>
                        {errorText}
                    </FormHelperText>
                )}
            </>
        );
    }
}
FileInputWrapper.displayName = "FileInputWrapper";
const FileInput = FormField(FileInputWrapper);

export default withStyles(styles)(FileInput);
