import React, { Component } from "react";
import { FormField } from "react-form";

import { ColorPicker } from "material-ui-color";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";

const styles = (theme) => ({
    label: {
        margin: 0,
    },
    innerLabel: {
        width: "100%",
    },
    bootstrapInput: {
        borderRadius: 4,
        border: "1px solid #979797",
        padding: "6px 8px",
        width: "calc(100% - 24px)",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderColor: theme.palette.secondary.light,
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
        backgroundColor: "#fff",
        fontSize: "15px",
        fontWeight: 500,
        color: "#262626 !important",
    },
});

class ColorPickerWrapper extends Component {
    handleChange = (value) => {
        const { fieldApi, eventHandle } = this.props;
        const { setValue } = fieldApi;

        if (typeof value.raw === "string")
            setValue(value.raw);
        else
            setValue("#" + value.hex);

        if (eventHandle) {
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(function () {
                if (typeof value.raw === "string") {
                    eventHandle(value.raw, this);
                }
                else {
                    eventHandle("#" + value.hex, this);
                }
            }, 2000);
        }
    };

    render() {
        const { fieldApi, label, classes, ...rest } = this.props;
        const { getValue, getError } = fieldApi;
        var error = getError(),
            hasError = !!error;

        const value = getValue() || false;

        // FIXME: can't seem to overwrite default style to use the same style as our other text fields
        let InputProps = {};
        InputProps["classes"] = {
            input: classes.bootstrapInput,
        };

        return (
            <div>
                <FormControlLabel
                    control={<ColorPicker value={value} onChange={this.handleChange} InputProps={InputProps} disableTextfield {...rest} />}
                    label={label}
                    labelPlacement="top"
                    classes={{
                        labelPlacementTop: classes.label,
                        label: classes.innerLabel,
                    }}
                />
                {hasError && <FormHelperText style={{ marginTop: -1, color: "red", marginLeft: 13 }}>Required</FormHelperText>}
            </div>
        );
    }
}
ColorPickerWrapper.displayName = "ColorPickerWrapper";
const ColorPickerForm = FormField(ColorPickerWrapper);

export default withStyles(styles)(ColorPickerForm);
