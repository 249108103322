import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Prompt, Link } from "react-router-dom";
import { Form } from "react-form";
import { addBusinessDays, eachDayOfInterval, addDays, getWeekOfMonth, isSunday, isMonday, isFriday, isSaturday } from "date-fns";
import { withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import TextField from "../../common/TextField";
import AppContainer from "../../common/AppContainer";
import Switch from "../../common/Switch";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import Close from "@material-ui/icons/Close";
import { Checkbox as MuiCheckbox } from '@material-ui/core';
import Apps from "@material-ui/icons/Apps";
import Toolbar from "@material-ui/core/Toolbar";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import { IconButton } from "@material-ui/core";
import Save from "@material-ui/icons/Save";
import CloudDownload from "@material-ui/icons/CloudDownload";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowForward from "@material-ui/icons/ArrowForward";

import EditIcon from "@material-ui/icons/Edit";
import PdfIcon from "@material-ui/icons/PictureAsPdfTwoTone";
import ImageIcon from "@material-ui/icons/ImageTwoTone";
import PowerpointIcon from "@material-ui/icons/SlideshowTwoTone";
import MovieIcon from "@material-ui/icons/MovieTwoTone";
import FolderIcon from "@material-ui/icons/FolderTwoTone";
import EmailIcon from "@material-ui/icons/EmailTwoTone";
import FileIcon from "@material-ui/icons/AttachFileTwoTone";

import HelpLabel from "../../common/HelpLabel";
import { evaluate } from "mathjs";

import Select from "../../common/Select";
import ReactSelect from "../../common/ReactSelect";
import ReactSelectAsync from "../../common/ReactSelectAsync";
import EnhancedTextField from "../../common/EnhancedTextField";
import { createSelector } from "../../common/orm";
import WarningDialog from "../../common/WarningDialog";
import FileInput from "../../common/FileInput";
import { RecordField, RecordSection, AttachmentSection, ParentRecord, RepeatableTable, RepeatableField } from "../models";
import SectionTabHeader from "./SectionTabHeader";
import Snackbar from "../../common/Snackbar";
import BaseMapControl from "../../common/esrimap/BaseMapControl";
import DatePicker from "../../common/DatePicker";
import TimePicker from "../../common/TimePicker";
import RadioGroup from "../../common/RadioGroup";
import { User } from "../../adm/models";
import TopButtons from "./TopButtons";
import Checkbox from "../../common/Checkbox";
import RepeatTable from "./RepeatableTable";
import MirrorRepeatableTable from "./MirrorRepeatableTable"; 

const styles = (theme) => ({
    root: {
        width: "100%",
        overflowX: "auto",
    },
    heading2: {
        fontSize: theme.typography.pxToRem(17),
        fontWeight: theme.typography.fontWeightRegular,
        display: "flex",
    },
    expandedMargin: {
        marginTop: "12px",
    },
    titlePanel: {
        minHeight: "auto !important",
    },
    floatLeft: {
        float: "left",
    },
    floatRight: {
        float: "right",
    },

    flex: {
        flex: 1,
    },
    gutterBottom: {
        marginBottom: ".6em",
    },
    iconbutton: {
        float: "right",
        fontSize: 32,
        marginRight: 27,
    },
    icon: {
        float: "right",
        fontSize: 32,
    },
    progress: {
        marginLeft: "auto",
        marginRight: "auto",
        display: "table",
        marginTop: 16,
        zIndex: 3,
    },
    height: {
        height: "500px",
        marginTop: "12px !important",
        marginBottom: "12px !important",
    },
    attachmentEdit: {
        minWidth: 30,
        width: 30,
        float: "right",
        padding: 0,
    },
    nowrap: {
        whiteSpace: "nowrap",
        //textAlign: "center",
        paddingRight: "0px !important",
    },
    linkColor: {
        color: "#2b6dad",
        textDecoration: "none",
        "&:hover": {
            backgroundColor: "#F9EBC8",
        },
    },
    formOutput: {
        //for expansionpanels with section tab header
        [theme.breakpoints.up(957)]: {
            paddingLeft: 29,
            paddingTop: 50,
        },
        [theme.breakpoints.down(957)]: {
            paddingRight: 10,
            paddingLeft: 64,
            paddingTop: 70,
        },
        [theme.breakpoints.down(944)]: {
            paddingRight: 10,
            paddingLeft: 64,
            paddingTop: 39,
        },
        [theme.breakpoints.down(572)]: {
            paddingRight: 10,
            paddingLeft: 64,
            paddingTop: 46,
        },
    },

    attachmentNameTitle: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: 13,
    },
    attachmentCaption: {
        display: "block",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    attachmentBorder: {
        borderBottom: "1px solid lightgray",
        borderLeft: "1px solid lightgray",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.07)",
        },
    },
    iconSize: {
        opacity: "50%",
        height: 50,
        width: "95%",
        marginLeft: "auto",
        marginRight: "auto",
        border: "1px solid transparent",
        "&:hover": {
            opacity: "100%",
            cursor: "pointer",
            border: "1px dashed",
        },
    },
    imageContainer: {
        textAlign: "left",
    },
    iconSize2: {
        opacity: "65%",
        maxHeight: 75,
        maxWidth: "99%",
        //width:"95%",
        marginLeft: "auto",
        marginRight: "auto",
        border: "1px solid transparent",
        "&:hover": {
            opacity: "100%",
            cursor: "pointer",
            border: "1px dashed",
        },
    },
    deleteButton: {
        backgroundColor: "#8b0000",
        "&:hover": {
            backgroundColor: "#3f0000",
        },
    },
});

const allUsers = User.selectAll();

const getPermission = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["pageId"]),
    (session, pageID) => {
        // Get all permissions related to the page id
        return session.Permission.filter((p) => p.page === pageID).toRefArray();
    }
);

const getPage = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["pageId"]),
    (session, pageID) => {
        // Get the specific page we are on
        return session.Page.withId(pageID);
    }
);
const getSections = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["pageId"]),
    (session, pageID) => {
        return session.Section.filter((s) => s.page === pageID)
            .orderBy("id")
            .toRefArray();
    }
);
const getSection = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["sectionId"]),
    (session, sectionId) => {
        // Get the specific section we are on
        return session.Section.withId(sectionId);
    }
);

const findParentRecordId = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["parentRecordId"]),
    (session, parentRecord) => {
        // Get the parent record section
        return parentRecord;
    }
);

const getRecordSection = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["recordSectionId"]),
    (session, recordSection) => {
        // JJ: We have the specific recordsection from ormRecordSectionLoadDetailChild in Accordion
        return session.RecordSection.withId(recordSection);
    }
);

const getRecordSectionFeeds = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["recordSectionId"]),
    (session, rsid) => {
        return session.RecordSection.filter((rs) => rs.id === rsid).toRefArray();
    }
);
const getRecordSectionID = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["recordSectionId"]),
    (session, recordSection) => {
        return recordSection;
    }
);

const getAttachments = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["recordSectionId"]),
    (session, recordSection) => {
        // Get all attachments related to the record section will include copied images as well
        return session.AttachmentSection.filter((a) => a.recordsection === recordSection)
            .orderBy("id", "desc")
            .toRefArray();
    }
);

const getRecordField = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["recordSectionId"]),
    (session, recordSection) => {
        // Get all record fields related to the record section
        return session.RecordField.filter((r) => r && r.recordsection === recordSection)
            .all()
            .orderBy("id")
            .toModelArray();
    }
);

const getForms = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["sectionId"]),
    (session, sectionId) => {
        // Get all forms, groups, and fields related to the section we are on
        return session.Form.filter((form) => form.section === sectionId)
            .orderBy("id")
            .toModelArray()
            .map((form) => ({
                groups: form.groups
                    .all()
                    .orderBy("order")
                    .toModelArray()
                    .map((group) => ({
                        fields: group.fields.all().orderBy("order").toRefArray(),
                        ...group.ref,
                    })),
                ...form.ref,
            }));
    }
);

const getRepeatableTables = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["recordSectionId"]),
    (session, recordSection) => {
        // Get all record fields related to the record section
        return session.RepeatableTable.filter((r) => r.recordsection === recordSection)
            .all()
            .orderBy("group_id")
            .orderBy("id", "desc")
            .toModelArray()
            .map((repeatableTable) => ({
                repeatablefields: repeatableTable.repeatableField.all().orderBy("id").toRefArray(),
                ...repeatableTable.ref,
            }));
    }
);
const getMirrorRepeatableTables = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["recordSectionId"]),
    (state, ownProps) => parseInt(ownProps.match.params["parentRecordId"]),
    (session, recordSection, parentRecord) => {
        // Get all repeatable fields related to the repeatabletable
        return session.RepeatableTable.filter((r) => r.recordsection !== recordSection && r.parentrecord === parentRecord)
            .all()
            .orderBy("group_id")
            .orderBy("id", "desc")
            .toModelArray()
            .map((repeatableTable) => ({
                repeatablefields: repeatableTable.repeatableField.all().orderBy("id").toRefArray(),
                ...repeatableTable.ref,
            }));
    }
);

//used for getting the fields of the parent when 'Primary Mirror' types are used
const getParentFields = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["pageId"]),
    (session, pageId) => {
        const parentsection = session.Section.filter((s) => s.parent === true && s.page === pageId).toRefArray();

        return session.Form.filter((form) => form.section === parentsection[0].id)
            .orderBy("id")
            .toModelArray()
            .map((form) => ({
                groups: form.groups
                    .all()
                    .orderBy("order")
                    .toModelArray()
                    .map((group) => ({
                        fields: group.fields.all().orderBy("order").toRefArray(),
                        ...group.ref,
                    })),
                ...form.ref,
            }));
    }
);

const getTemplates = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["sectionId"]),
    (session, sectionId) => {
        // Get all templates associated with the section we are on
        return session.LetterTemplate.filter((l) => l.section === sectionId)
            .orderBy("name")
            .toModelArray();
    }
);

const getParentSection = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["pageId"]),
    (session, pageId) => {
        // A list of all sections within the page that are the parent (should be only 1)
        return session.Section.filter((sect) => sect.page === pageId && sect.parent).toRefArray();
    }
);

class ExpansionPanelFormRecord extends Component {
    constructor(props) {
        super(props);

        this.state = {
            save: false, //controls snackbar popup
            snackbarOpen: false,
            missingCount: 0,
            isSuccess: null,
            attachment:false,
            loadingFields: false, // Loading RecordFields
            loadingRS: false, // Loading RecordSection
            loadingRSFinished: false,
            loadingAttachment: false,
            deleteDialogOpen: false,
            deleteDialogText: null,
            deleteDialogTitle: null,
            deleteDialogConfirmAction: null,
            deletingFields: false,
            actCalcFields: [],
            calculationInfo: {},
            loadedRecordTable: false,
            loadedMirrorFields: false,
            counter: -1,
            editDialogOpen: false,
            attachmentToEdit: null,
            unsavedFields: false,
            touchedFields: [],
            dontRender: false,
            hideFields: [],
            hideDropDownFields: [],
            loadLogicFields: true,
            isLastRepeatableRecord: false,
            lastRepeatableTableData: null,
            attachmentFilter: null,
            attachmentTypeFilter: null,
            sort_field: true,
            sort_asc: true,
            selectedAttachments: [],
        };
        this.formApi = {};
        ExpansionPanelFormRecord.displayName = "ExpansionPanelFormRecordx";
    }

    updateRecordField = (values, fromButton, formApi) => {
        const {
            ormRecordFieldUpdate,
            page,
            parentRecordId,
            section,
            history,
            recordsection,
            recordSectionID,
            recordfields,
            ormParentRecordUpdateLocalOnly,
            ormRecordSectionUpdateLocalOnly,
            ormRecordSectionLoadDetailChild,
        } = this.props;
        const { missingCount, touchedFields } = this.state;

        const original_values = Object.entries(values);

        var calc = []; //get all calc fields for updating them as well as they might not have been touched
        recordfields.forEach((f) => {
            if (f.field.type === "Calculated") {
                calc.push("f_" + f.id);
            }
        });

        //get rid of null values
        Object.keys(values).forEach((key) => {
            if (calc.find((f) => {return f === key;})){//we will save all calc fields for every group as we dont know if they changed                                      
                return;    
            } else {
                //we will delete all keys that were not touched
                !touchedFields.find((f) => f === key) && delete values[key];
            }
        });

        const newValues = Object.entries(values);

        //remove id from key so that we only have a number for placing into the table
        newValues.forEach(function (f) {
            //save the key/value pair in the recordField table
            ormRecordFieldUpdate({
                id: f[0].replace("f_", ""),
                userinput: f[1],
            });
        });

        // Update the parentrecord label if this is a parent
        // The actual update on the DB is applied to the backend but should update the local value
        if (section.parent) {
            //we look for append_to_header field types and if so look to see if we have a value that has been entered
            var append = recordfields.find((f) => (f.field.append_to_header === true ? f : null));
            if (append) {
                var hasAppend = null;
                Object.keys(values).forEach((key) => {
                    return key.replace("f_", "") === append.id.toString() ? (hasAppend = values[key]) : null;
                });
                append = " (" + append.userinput + ")";
            }
            recordfields.forEach((f) => {
                if (f.field.identification) {
                    var val = original_values.find((n) => n[0].replace("f_", "") === f.id.toString());
                    let appendVal = hasAppend ? " (" + hasAppend + ")" : null;
                    if (val) {
                        if (appendVal && append) {
                            //value has been entered so update store with correct value
                            ormParentRecordUpdateLocalOnly({ 
                                id: parentRecordId,
                                parent_label: val[1] + appendVal,
                            });
                        } else if (append) {
                            //append_to_header was previously changed but not changed now so just update store to same
                            ormParentRecordUpdateLocalOnly({
                                id: parentRecordId,
                                parent_label: val[1] + append,
                            });
                        } else if (val) {
                            //append_to_header isnt being used on another field so just update store with id value
                            ormParentRecordUpdateLocalOnly({
                                id: parentRecordId,
                                parent_label: val[1],
                            });
                        }  
                    }
                }

            });

            if (section.id === 1589){// used for map updating drawing for crwd/district studies module with update to fields that are sync'd to arcgis
                setTimeout(()=>{ 
                    ormRecordSectionLoadDetailChild(recordSectionID);
                    this.loadRecordFields(true)
                },3000) 
            }
            
        } else {
            //update child to update parent recordsection for any 'related' mirror fields
            if (recordsection && recordsection.parentinfo.parent_id)
                setTimeout(() => {
                    ormRecordSectionUpdateLocalOnly({
                        id: recordsection.parentinfo.parent_id,
                        has_children_loaded: false,
                    });
                }, 1000);
        }

        const comp = this;
        this.setState({ unsavedFields: false });
        setTimeout(function () {
            comp.setState({ unsavedFields: false });
        }, 100);

        if (fromButton === "true") {
            // Edit Map Location Button
            history.push("/page/" + page.id + "/parentrecord/" + parentRecordId + "/recordsection/" + recordSectionID + "/map");
        } else if (fromButton === "false") {
            //occurs on last form save
            setTimeout(() => {
                if (missingCount === 0) {
                    this.setState(() => ({
                        save: true,
                        isSuccess: true,
                        dontRender: false,
                        unsavedFields: false,
                        touchedFields: [],
                    }));
                } else
                    this.setState(() => ({
                        save: true,
                        isSuccess: false,
                        dontRender: false,
                        unsavedFields: false,
                    })); //dont save popup as we have required missing
            }, 200);

            setTimeout(() => {
                //reset counter incase of multiple saves on errors
                this.setState({ missingCount: 0 });
            }, 6000);
        }
    };

    errorValidator = (values) => {
        const { recordfields, forms } = this.props;
        var valObj = {};

        var p = {}; //strip f_ off values keys
        Object.keys(values).forEach((k) => {
            var o = k.replace("f_", "");
            p[o] = values[k];
        });

        const validateEmail = (email) => {
            var re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !re.test(email) ? "Invalid Email Address" : null;
        };

        var findRequired = []; //find 'is_required' fields of form
        forms.forEach((f) => {
            f.groups.forEach((g) => {
                if (!g.repeatable)
                    //dont check for repeatable as that is done when saving a repeatable record
                    g.fields.forEach((fi) => {
                        if (fi.is_required) {
                            recordfields.find((p) => {
                                return p.field.id === fi.id ? findRequired.push("f_" + p.id) : null;
                            });
                        }
                        if (fi.type === "Email") {
                            var _f = recordfields.find((p) => {
                                return p.field.id === fi.id
                            });
                            if(_f){
                                if (values["f_" + _f.id])
                                    valObj["f_" + _f.id] = validateEmail(values["f_" + _f.id]);
                                else
                                    valObj["f_" + _f.id] = null
                            }
                            
                        }
                    });
            });
        });
        var thisMissingCount = 0;
        Object.keys(values).forEach((k) => {
            findRequired.find((f) => {
                if (f === k && !values[k]) {
                    thisMissingCount += 1;
                    return (valObj[f] = "Required Field");
                } else if (f === k) return (valObj[f] = null);
                else return null;
            });
        });

        if (thisMissingCount > 0) {
            this.setState((prevState) => ({
                isSuccess: false,
                save: true,
                willSave: false,
                missingCount: prevState.missingCount + thisMissingCount, //add to the missing count
            }));
        }

        return valObj;
    };

    saveRecordFieldsBeforeMapRouting = () => {
        const { classes } = this.props;
        const { unsavedFields } = this.state;
        if (unsavedFields)
            //we dont want unsaved fields warning to come up as we will just save any touched fields
            this.setState(() => ({
                unsavedFields: false,
            }));
        setTimeout(() => {
            //need for state change above
            this.submit(true);
        }, 200);

        return (
            <CircularProgress size={90} style={{ display: "table", marginLeft: "auto", marginRight: "auto" }} className={classes.progress}>
                <Typography variant="h6" style={{ textAlign: "center" }}>
                    Loading... Please Wait
                </Typography>
            </CircularProgress>
        );
    };

    defaultValues = (id) => {
        const { recordfields, forms, recordsection } = this.props;

        //get all fields for to check for autopopulate on date
        var allFields = [];
        var actualForm = forms.find((f) => {
            return f.id === recordsection.form.id;
        });
        actualForm.groups.forEach((g) => {
            return g.fields.forEach((f) => {
                allFields.push(f);
            });
        });

        //setup default values for FORM. defaultValues is setup with an obj of k/v pairs (i.e. 2816: NY, 2817: 55303 etc.)
        //this is setup to allow the "field" parameter of TextField (below) to place the ID values in the correct spot on the 'expansionpanel'
        var defaultValues = {};
        // setTimeout(() => {
        recordfields.forEach((rf) => {
            if (rf.group.id === id) {
                if (rf.mirrorfield) {
                    defaultValues["f_" + rf.id] = rf.mirrorfield;
                } else {
                    defaultValues["f_" + rf.id] = rf.userinput; //needs to be a string for form
                }
            }
        });
        // }, 1000);

        return defaultValues;
    };

    handleWindowBeforeUnload = (event) => {
        event.preventDefault();
        return (event.returnValue = "Are you sure you want to exit?");
    };

    addAttachment = (values, formApi) => {
        const { recordSectionID, ormAttachmentSectionCreate, ormAttachmentSectionLoadDetailChild, section, recordsection } = this.props;
        this.setState({ loadingAttachment: true });
        window.addEventListener("beforeunload", this.handleWindowBeforeUnload);

        //we are on a child section chk if we need to create another record in the parent
        if (!section.parent) {
            if (values.mirrorparent === true)
                //create in parent
                ormAttachmentSectionCreate({
                    name: values.name,
                    file: values.file,
                    _type: values._type,
                    notes: values.notes,
                    mirrorparent: values.mirrorparent,
                    recordsection: recordsection.parentinfo.parent_id,
                    section_name: section.name,
                })//we assume if it saves in child below it would have saved here also. 
                
            //create in child rs
            ormAttachmentSectionCreate({
                name: values.name,
                file: values.file,
                _type: values._type,
                notes: values.notes,
                mirrorparent: values.mirrorparent,
                recordsection: recordSectionID,
            }).then((e) => {
                if(!e.id){
                    this.setState({isSuccess:false})
                }else{
                    this.setState({isSuccess:true})
                }
                this.setState({ loadingAttachment: false, editDialogOpen: false, save:true,  attachment:true});
                ormAttachmentSectionLoadDetailChild(recordSectionID);
                window.removeEventListener("beforeunload", this.handleWindowBeforeUnload);
            });
            formApi.resetAll();
        } else {
            //were in a parent so just create there
            ormAttachmentSectionCreate({
                name: values.name,
                file: values.file,
                _type: values._type,
                notes: values.notes,
                mirrorparent: values.mirrorparent,
                recordsection: recordSectionID,
            }).then((e) => {
                if(!e.id){
                    this.setState({isSuccess:false})
                }else{
                    this.setState({isSuccess:true})
                }
                this.setState({ loadingAttachment: false, editDialogOpen: false, save:true,  attachment:true});
            
                ormAttachmentSectionLoadDetailChild(recordSectionID);
                window.removeEventListener("beforeunload", this.handleWindowBeforeUnload);
            });
            formApi.resetAll();
        }
    };

    attachmentValidator = (values) => {
        const isRequired = (val) => {
            return !val ? "Required" : null;
        };
        var valObj = {
            name: isRequired(values.name),
            file: isRequired(values.file),
        };
        return valObj;
    };

    createAttachment = () => {
        this.setState({
            editDialogOpen: true,
            attachmentToEdit: null,
        });
    };

    editAttachment = (n) => {
        this.setState({
            editDialogOpen: true,
            attachmentToEdit: n,
        });
    };

    saveUpdatedAttachment = (values) => {
        const { recordsection, ormAttachmentSectionUpdate, ormAttachmentSectionCreate, section } = this.props;
        const { attachmentToEdit } = this.state;

        //we are on a child section chk if we need to create a copy record in the parent
        if (!section.parent) {
            if (values.mirrorparent === true && !attachmentToEdit.mirrorparent) {
                // //create in parent
                ormAttachmentSectionCreate({
                    name: values.name,
                    file: values.file,
                    _type: values._type,
                    notes: values.notes,
                    mirrorparent: values.mirrorparent,
                    recordsection: recordsection.parentinfo.parent_id,
                    section_name: section.name,
                }).then((a) => {
                    //update child rs only if parent was updated
                    ormAttachmentSectionUpdate({
                        id: attachmentToEdit.id,
                        name: values.name,
                        _type: values._type,
                        notes: values.notes,
                        mirrorparent: values.mirrorparent,
                    });
                });
            } else {
                //update child rs
                ormAttachmentSectionUpdate({
                    id: attachmentToEdit.id,
                    name: values.name,
                    _type: values._type,
                    notes: values.notes,
                    mirrorparent: values.mirrorparent,
                    file: values.file,
                });
            }
        } else {
            //were in a parent so just update
            ormAttachmentSectionUpdate({
                id: attachmentToEdit.id,
                name: values.name,
                _type: values._type,
                notes: values.notes,
                file: values.file,
            });
        }
        this.setState({ editDialogOpen: false });
    };

    attachmentDefaultValues = () => {
        const { attachments } = this.props;
        const { attachmentToEdit } = this.state;
        if (attachmentToEdit) var obj = attachments.find((a) => a.id === attachmentToEdit.id);

        return obj;
    };

    deleteAttachment = (n) => {
        this.setState({
            deleteDialogOpen: true,
            deleteDialogTitle: "Delete Attachment",
            deleteDialogConfirmAction: n.id,
            deleteDialogText: `Are you sure you wish to delete: ${n.name} ?`,
        });
    };

    deleteRecord = () => {
        const { parentRecordId, parentSection, section, recordSectionID } = this.props;

        //parent case delete
        if (parentSection[0].id === section.id) {
            this.setState({
                deleteDialogOpen: true,
                deleteDialogTitle: "Delete Primary Record Section",
                deleteDialogConfirmAction: parentRecordId,
                deleteDialogText:
                    "WARNING: You are about to permanently delete this record and all records associated with this primary record. Are you sure you wish to continue?",
            });
        } else {
            this.setState({
                deleteDialogOpen: true,
                deleteDialogTitle: "Delete Related Record Section",
                deleteDialogConfirmAction: recordSectionID,
                deleteDialogText: "WARNING: You are about to permanently delete this record. Are you sure you wish to continue?",
            });
        }
    };

    //map and attachments
    formOutputGrid = (readOnly) => {
        const { classes, recordsection, attachments, authState, section, allSections } = this.props;
        const { loadingAttachment, editDialogOpen, attachmentToEdit, attachmentFilter, attachmentTypeFilter, sort_field, sort_asc, selectedAttachments } = this.state;

        //find the attachment only when we are editing
        if (editDialogOpen && attachmentToEdit)
            var thisAttachment = attachments.find((a) => {
                return a.id === attachmentToEdit.id;
            });

        //kinda messy but need logic to determine parent/child attachment columns
        //and what to show for options
        let hasParentAttachment = false;
        allSections.forEach((s) => {
            if (s.parent && s.has_attachments) {
                hasParentAttachment = true;
            }
        });
        //custom styling for expansion panel container
        let containerTop;
        if (window.innerWidth + 13 < 585) containerTop = -24;
        else if (window.innerWidth + 13 < 959) containerTop = 20;
        else if (window.innerWidth + 13 < 585) containerTop = 0;
        //expansionpanelpadding for smaller screens on the two grids below--not a fan of but need to know if sectiontabheader has icons as the dynamic height
        let expansionTop = {
            paddingTop: allSections.find((s) => (s.icon !== "" || s.icon !== null ? s : null))
                ? window.innerWidth >= 949 && window.innerWidth <= 1193
                    ? 73
                    : 58
                : window.innerWidth >= 949 && window.innerWidth <= 1191
                ? 35
                : 30,
        };

        var attachments_filtered = attachments;
        if (attachmentFilter) {
            attachments_filtered = attachments.filter(
                (a) =>
                    (a.name && a.name.toUpperCase().includes(attachmentFilter.toUpperCase())) ||
                    (a.notes && a.notes.toUpperCase().includes(attachmentFilter.toUpperCase())) ||
                    (a._type && a._type.toUpperCase().includes(attachmentFilter.toUpperCase()))
            );
        }
        if (attachmentTypeFilter === "Photo") {
            attachments_filtered = attachments_filtered.filter(
                (n) => n.file_extension === ".png" || n.file_extension === ".jpg" || n.file_extension === ".jpeg" || n.file_extension === ".jpe"
            );
        } else if (attachmentTypeFilter === "Document") {
            attachments_filtered = attachments_filtered.filter(
                (n) => n.file_extension !== ".png" && n.file_extension !== ".jpg" && n.file_extension !== ".jpeg" && n.file_extension !== ".jpe"
            );
        }

        if (sort_field) {
            if (sort_asc) attachments_filtered.sort((a, b) => (b["id"] < a["id"] ? -1 : 1));
            else attachments_filtered.sort((a, b) => (a["id"] < b["id"] ? -1 : 1));
        } else if (!sort_field) {
            if (sort_asc) attachments_filtered.sort((a, b) => (b["name"].toUpperCase() < a["name"].toUpperCase() ? -1 : 1));
            else attachments_filtered.sort((a, b) => (a["name"].toUpperCase() < b["name"].toUpperCase() ? -1 : 1));
        }

        // If the section does not have geometry or attachments take up the full screen
        // else print the geo or attachements on the UI
        if (!recordsection.section.has_geometry && !recordsection.section.has_attachments) {
            return (
                <Grid container spacing={0} style={{ marginTop: containerTop }}>
                    <Grid item xs={12} className={classes.formOutput} style={expansionTop}>
                        {this.formOutput(readOnly)}
                    </Grid>
                </Grid>
            );
        } else {
            return (
                <Grid container spacing={0} style={{ marginTop: containerTop }}>
                    <Grid item xs={12} xl={8} className={classes.formOutput} style={expansionTop}>
                        {this.formOutput(readOnly)}
                    </Grid>
                    <Grid item xs={12} xl={4} className={classes.formOutput} style={expansionTop}>
                        {recordsection.section.has_geometry && (
                            <BaseMapControl
                                geometry={recordsection.geometry}
                                saveRecordFieldBeforeMapRouting={this.saveRecordFieldsBeforeMapRouting}
                                viewOnly
                            />
                        )}
                        {recordsection.section.has_attachments && (
                            <Accordion style={{ backgroundColor: "#fff", margin: "12px 0" }} defaultExpanded>
                                <AccordionSummary
                                    className={classes.titlePanel}
                                    classes={{ root: classes.expandedMargin }}
                                    expandIcon={<ExpandMoreIcon />}
                                    style={{ backgroundColor: "#ddd" }}>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <Typography className={classes.heading2} style={{ marginTop: 10 }}>
                                                Attachments
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8} style={{ textAlign: "right" }}>
                                            <Tooltip
                                                title={
                                                    !attachmentTypeFilter
                                                        ? "Filter Only Photos"
                                                        : attachmentTypeFilter === "Photo"
                                                        ? "Filter Only Documents"
                                                        : "Remove Filter"
                                                }>
                                                <IconButton
                                                    style={{ padding: 0, marginRight: 16, marginTop: 10 }}
                                                    type="button"
                                                    color="primary"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (!attachmentTypeFilter) this.setState({ attachmentTypeFilter: "Photo" });
                                                        else if (attachmentTypeFilter === "Photo")
                                                            this.setState({ attachmentTypeFilter: "Document" });
                                                        else if (attachmentTypeFilter === "Document") this.setState({ attachmentTypeFilter: null });
                                                    }}>
                                                    <Apps />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Download All or Selected Attachments">
                                                <IconButton
                                                    disabled={readOnly}
                                                    style={{ padding: 0, marginRight: 16, marginTop: 10 }}
                                                    type="button"
                                                    color="primary"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (selectedAttachments.length > 0) {
                                                            window.open("/attachments/record/" + recordsection.id + "/" + authState.user.auth_token + "/" + selectedAttachments.toString());
                                                        } else {
                                                            window.open("/attachments/record/" + recordsection.id + "/" + authState.user.auth_token);
                                                        }
                                                    }}>
                                                    <CloudDownload />
                                                </IconButton>
                                            </Tooltip>
                                            <Button
                                                disabled={readOnly}
                                                variant="contained"
                                                color="primary"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.createAttachment();
                                                }}
                                                style={{ float: "right" }}>
                                                Add Attachment&nbsp;&nbsp;&nbsp;
                                                <FileIcon />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails style={{ padding: 8 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4} style={{ borderBottom: "1px solid lightgray" }}>
                                            <Form>
                                                {(formApi) => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <TextField
                                                            field="att_search"
                                                            placeholder="Search"
                                                            filterInput
                                                            fullWidth
                                                            style={{ marginTop: 4 }}
                                                            eventHandle={(val) => {
                                                                this.setState({ attachmentFilter: val });
                                                            }}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        </Grid>
                                        <Grid item xs={8} style={{ borderBottom: "1px solid lightgray" }}>
                                            <Form defaultValues={{ sort_field: true, sort_asc: true }}>
                                                {(formApi) => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <Switch
                                                            field="sort_field"
                                                            name="sort_field"
                                                            label="Sort Options"
                                                            labelNo="Name"
                                                            labelYes="Date"
                                                            style={{ marginTop: -8, marginBottom: -8, float: "left", width: "auto" }}
                                                            eventHandle={(val) => {
                                                                this.setState({ sort_field: val });
                                                            }}
                                                        />
                                                        <Switch
                                                            field="sort_asc"
                                                            name="sort_asc"
                                                            labelNo="Asc"
                                                            labelYes="Desc"
                                                            style={{ marginBottom: -8, marginTop: 14, width: "auto", marginLeft: 24 }}
                                                            eventHandle={(val) => {
                                                                console.log(val);
                                                                this.setState({ sort_asc: val });
                                                            }}
                                                        />
                                                    </form>
                                                )}
                                            </Form>
                                        </Grid>
                                        {attachments.length === 0 && (
                                            <Grid item xs={12}>
                                                <Typography style={{ textAlign: "center" }}>No Attachments Added</Typography>
                                            </Grid>
                                        )}
                                        {attachments_filtered.map((n, i) => {
                                            return (
                                                <Grid item xs={12} md={6} lg={4} xl={12} className={classes.attachmentBorder}>
                                                    <Grid container spacing={1} style={{ alignItems: "center" }}>
                                                        <Grid
                                                            xs={4}
                                                            className={
                                                                (n.file_extension === ".png" ||
                                                                    n.file_extension === ".jpg" ||
                                                                    n.file_extension === ".jpeg" ||
                                                                    n.file_extension === ".jpe") &&
                                                                classes.imageContainer
                                                            }>
                                                            <Tooltip title="Open Attachment">
                                                                <a href={n.file} target="_blank" rel="noopener noreferrer">
                                                                    {n.file_extension === ".tiff" ||
                                                                    n.file_extension === ".tif" ||
                                                                    n.file_extension === ".bmp" ? (
                                                                        <ImageIcon className={classes.iconSize} />
                                                                    ) : n.file_extension === ".png" ||
                                                                      n.file_extension === ".jpg" ||
                                                                      n.file_extension === ".jpeg" ||
                                                                      n.file_extension === ".jpe" ? (
                                                                        <img alt={n.name} className={classes.iconSize2} src={n.file} />
                                                                    ) : n.file_extension === ".pdf" ? (
                                                                        <PdfIcon className={classes.iconSize} />
                                                                    ) : n.file_extension === ".ppt" || n.file_extension === ".pptx" ? (
                                                                        <PowerpointIcon className={classes.iconSize} />
                                                                    ) : n.file_extension === ".mp4" ||
                                                                      n.file_extension === ".mov" ||
                                                                      n.file_extension === ".3gp" ? (
                                                                        <MovieIcon className={classes.iconSize} />
                                                                    ) : n.file_extension === ".zip" || n.file_extension === ".rar" ? (
                                                                        <FolderIcon className={classes.iconSize} />
                                                                    ) : n.file_extension === ".msg" ? (
                                                                        <EmailIcon className={classes.iconSize} />
                                                                    ) : n.file_extension === ".xls" ||
                                                                      n.file_extension === ".xlsx" ||
                                                                      n.file_extension === ".xlb" ? (
                                                                        <svg
                                                                            className={classes.iconSize}
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="1em"
                                                                            height="1em"
                                                                            preserveAspectRatio="xMidYMid meet"
                                                                            viewBox="0 0 512 512">
                                                                            <path
                                                                                fill="currentColor"
                                                                                d="M453.547 273.449H372.12v-40.714h81.427v40.714zm0 23.264H372.12v40.714h81.427v-40.714zm0-191.934H372.12v40.713h81.427V104.78zm0 63.978H372.12v40.713h81.427v-40.713zm0 191.934H372.12v40.714h81.427V360.69zm56.242 80.264c-2.326 12.098-16.867 12.388-26.58 12.796H302.326v52.345h-36.119L0 459.566V52.492L267.778 5.904h34.548v46.355h174.66c9.83.407 20.648-.291 29.197 5.583c5.991 8.608 5.41 19.543 5.817 29.43l-.233 302.791c-.29 16.925 1.57 34.2-1.978 50.892zm-296.51-91.256c-16.052-32.57-32.395-64.909-48.39-97.48c15.82-31.698 31.408-63.512 46.937-95.327c-13.203.64-26.406 1.454-39.55 2.385c-9.83 23.904-21.288 47.169-28.965 71.888c-7.154-23.323-16.634-45.774-25.3-68.515c-12.796.698-25.592 1.454-38.387 2.21c13.493 29.78 27.86 59.15 40.946 89.104c-15.413 29.081-29.837 58.57-44.785 87.825c12.737.523 25.475 1.047 38.212 1.221c9.074-23.148 20.357-45.424 28.267-69.038c7.096 25.359 19.135 48.798 29.023 73.051c14.017.99 27.976 1.862 41.993 2.676zM484.26 79.882H302.326v24.897h46.53v40.713h-46.53v23.265h46.53v40.713h-46.53v23.265h46.53v40.714h-46.53v23.264h46.53v40.714h-46.53v23.264h46.53v40.714h-46.53v26.897H484.26V79.882z"
                                                                            />
                                                                        </svg>
                                                                    ) : n.file_extension === ".txt" ||
                                                                      n.file_extension === ".rtf" ||
                                                                      n.file_extension === ".doc" ||
                                                                      n.file_extension === ".docx" ? (
                                                                        <svg
                                                                            className={classes.iconSize}
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="1em"
                                                                            height="1em"
                                                                            preserveAspectRatio="xMidYMid meet"
                                                                            viewBox="0 0 512 512">
                                                                            <path
                                                                                fill="currentColor"
                                                                                d="M488.877 52.447H302.306V5.92h-34.779L0 52.563v406.99l265.957 46.527h36.349v-46.353h174.59c9.887-.465 20.879.291 29.37-5.757c6.804-10.41 5.06-23.438 5.641-35.186V75.012c1.221-13.26-9.77-23.903-23.03-22.565zm-294.862 282.59c-9.712 5.06-24.252-.233-35.767.581c-7.735-38.5-16.75-76.768-23.67-115.443c-6.805 37.57-15.645 74.79-23.438 112.128c-11.166-.581-22.39-1.28-33.615-2.035c-9.655-51.18-20.995-102.01-30.01-153.305c9.945-.465 19.948-.872 29.893-1.221c5.99 37.047 12.795 73.919 18.03 111.024c8.2-38.036 16.574-76.071 24.717-114.106c11.05-.64 22.1-1.105 33.15-1.687c7.735 39.257 15.644 78.455 24.019 117.537c6.572-40.361 13.841-80.607 20.879-120.91c11.631-.407 23.263-1.047 34.836-1.745c-13.143 56.355-24.659 113.176-39.024 169.182zm290.212 97.069H302.306v-36.527h151.21v-23.263h-151.21v-29.079h151.21v-23.263h-151.21v-29.08h151.21v-23.262h-151.21v-29.08h151.21V215.29h-151.21v-29.08h151.21v-23.263h-151.21v-29.079h151.21v-23.263h-151.21v-30.71h181.921v352.21z"
                                                                            />
                                                                        </svg>
                                                                    ) : (
                                                                        <FileIcon className={classes.iconSize} />
                                                                    )}
                                                                </a>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid xs={7}>
                                                            <Tooltip title={n.name}>
                                                                <Typography className={classes.attachmentNameTitle}>{n.name}</Typography>
                                                            </Tooltip>
                                                            <Tooltip title={"Type: " + n._type}>
                                                                <Typography variant="caption" className={classes.attachmentCaption}>
                                                                    {n._type && "Type: " + n._type}
                                                                </Typography>
                                                            </Tooltip>
                                                            <Tooltip
                                                                title={"Date Uploaded: " + n.formatted_upload_date + " File Size: " + n.file_size}>
                                                                <Typography variant="caption" className={classes.attachmentCaption}>
                                                                    {n.formatted_upload_date && "Date Uploaded: " + n.formatted_upload_date}
                                                                    &nbsp;&nbsp;
                                                                    {n.file_size && "File Size: " + n.file_size}
                                                                </Typography>
                                                            </Tooltip>
                                                            <Tooltip title={"Notes: " + n.notes}>
                                                                <Typography variant="caption" className={classes.attachmentCaption}>
                                                                    {n.notes && n.notes}
                                                                </Typography>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <MuiCheckbox style={{paddingRight: 0, float: "right" }} id={"_c" + n.id} color="primary" onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    selectedAttachments.push(n.id);
                                                                    this.setState({selectedAttachments: selectedAttachments});
                                                                } else {
                                                                    const index = selectedAttachments.indexOf(n.id);
                                                                    if (index > -1) {
                                                                        selectedAttachments.splice(index, 1); 
                                                                    }
                                                                    this.setState({selectedAttachments: selectedAttachments});
                                                                }
                                                            }} />
                                                            <Tooltip title="Edit or Delete Attachment">
                                                                <Button
                                                                    disabled={readOnly}
                                                                    onClick={() => this.editAttachment(n)}
                                                                    className={classes.attachmentEdit}>
                                                                    <EditIcon color="primary" />
                                                                </Button>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Dialog open={editDialogOpen}>
                            <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                                <Typography variant="h5" className={classes.flex}>
                                    {attachmentToEdit ? "Edit" : "Add"} Attachment
                                </Typography>
                                <IconButton onClick={() => this.setState({ editDialogOpen: false })}>
                                    <Close />
                                </IconButton>
                            </Toolbar>
                            <DialogContent>
                                <DialogContentText>
                                    <Form
                                        dontValidateOnMount="true"
                                        validateError={this.attachmentValidator}
                                        defaultValues={this.attachmentDefaultValues()}
                                        onSubmit={(values, e, formApi3) => {
                                            if (attachmentToEdit) this.saveUpdatedAttachment(values);
                                            else this.addAttachment(values, formApi3);
                                        }}>
                                        {(formApi3) => (
                                            <form onSubmit={formApi3.submitForm}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography>File Attachment: </Typography>
                                                        <Typography variant="caption">Limit 70MB</Typography>
                                                        <FileInput
                                                            field="file"
                                                            id="file"
                                                            fullWidth
                                                            eventHandle={(e) => {
                                                                if (!attachmentToEdit) formApi3.setValue("name", e);
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField field="name" label="Attachment Name" fullWidth />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField field="notes" label="Notes" multiline rows={6} fullWidth />
                                                    </Grid>
                                                    {/* 
                                                        Joe: I don't really understand what all this mirror stuff is for (and how it relates to type) 
                                                        but I left that part of the form pretty similar for both new and edits.
                                                        attachmentToEdit = Editing Dialog
                                                        !attachmentToEdit = New Dialog
                                                        I think some can be combined?
                                                    */}
                                                    {!attachmentToEdit && (
                                                        <>
                                                            {recordsection.section.attachment_types && (
                                                                <Grid item xs={12}>
                                                                    <Select
                                                                        field="_type"
                                                                        options={this.dropDownValues(
                                                                            recordsection.section.attachment_types.trim().split(",")
                                                                        )}
                                                                        label={
                                                                            <HelpLabel
                                                                                inputLabel="File Type"
                                                                                helpText="Select the file type that will be saved. This can be custom configured in the section configuration"
                                                                            />
                                                                        }
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                            )}
                                                            {!section.parent && hasParentAttachment && (
                                                                <Grid item xs={12}>
                                                                    <Checkbox field="mirrorparent">
                                                                        <HelpLabel
                                                                            inputLabel="Copy Attachment into Primary Record?"
                                                                            helpText="A seperate copy of this 'Attachment' file will be created and visible in the primary record associated with this record. Deleting the attachment file in this record or in the primary record will not affect the other record."
                                                                        />
                                                                    </Checkbox>
                                                                </Grid>
                                                            )}
                                                        </>
                                                    )}
                                                    {attachmentToEdit && (
                                                        <>
                                                            {(!section.parent ||
                                                                (section.parent && thisAttachment && !thisAttachment.mirrorparent)) && (
                                                                //only show types with parent attachment if its not a copy from a child
                                                                <Grid item xs={12}>
                                                                    <Select
                                                                        field="_type"
                                                                        options={this.dropDownValues(
                                                                            recordsection.section.attachment_types.trim().split(",")
                                                                        )}
                                                                        label={
                                                                            <HelpLabel
                                                                                inputLabel="File Type"
                                                                                helpText="Select the file type that will be saved. This can be custom configured in the section configuration"
                                                                            />
                                                                        }
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                            )}
                                                            {!section.parent &&
                                                                hasParentAttachment &&
                                                                thisAttachment &&
                                                                !thisAttachment.mirrorparent && (
                                                                    //only allow copying of image on a child section and only if it hasnt been already copied
                                                                    <Grid item xs={12}>
                                                                        <Checkbox field="mirrorparent">
                                                                            <HelpLabel
                                                                                inputLabel="Copy Attachment into Primary Record?"
                                                                                helpText="A separate copy of this 'Attachment' will be created in the primary record. Deleting of either images (i.e. from the primary or related record) will not affect the other record."
                                                                            />
                                                                        </Checkbox>
                                                                    </Grid>
                                                                )}
                                                            {section.parent && thisAttachment && thisAttachment.mirrorparent && (
                                                                //show that its already been copied
                                                                <Grid item xs={12}>
                                                                    <Typography
                                                                        variant="h5"
                                                                        className={classes.flex}
                                                                        style={{ fontSize: 17, color: "red" }}>
                                                                        {thisAttachment.section_name
                                                                            ? `Attachment copied from a '${thisAttachment.section_name}' related record.`
                                                                            : `Attachment copied from a related record.`}
                                                                    </Typography>
                                                                </Grid>
                                                            )}
                                                            {!section.parent && thisAttachment && thisAttachment.mirrorparent && (
                                                                //show that its already been copied
                                                                <Grid item xs={12}>
                                                                    <Typography
                                                                        variant="h5"
                                                                        className={classes.flex}
                                                                        style={{ fontSize: 17, color: "red" }}>
                                                                        Attachment already copied to primary record.
                                                                    </Typography>
                                                                </Grid>
                                                            )}
                                                        </>
                                                    )}
                                                    {attachmentToEdit && (
                                                        <>
                                                            <Grid item xs={6}>
                                                                <TextField field="formatted_upload_date" label="Date Uploaded" fullWidth disabled />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField field="file_size" label="File Size" fullWidth disabled />
                                                            </Grid>
                                                        </>
                                                    )}
                                                    {loadingAttachment && <CircularProgress size={45} className={classes.progress} />}
                                                    {!loadingAttachment && (
                                                        <Grid item xs={6}>
                                                            <Button
                                                                type="submit"
                                                                variant="contained"
                                                                color="primary"
                                                                className={classes.button}
                                                                fullWidth>
                                                                <Save />
                                                                &nbsp;&nbsp;&nbsp; Save
                                                            </Button>
                                                        </Grid>
                                                    )}
                                                    {attachmentToEdit && (
                                                        <Grid item xs={6}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => this.deleteAttachment(attachmentToEdit)}
                                                                className={classes.deleteButton}
                                                                fullWidth>
                                                                <DeleteIcon />
                                                                &nbsp;&nbsp;&nbsp; Delete Attachment
                                                            </Button>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </form>
                                        )}
                                    </Form>
                                </DialogContentText>
                            </DialogContent>
                        </Dialog>
                    </Grid>
                </Grid>
            );
        }
    };
    
    manualCalculate = (fc, value) => {
        const { actCalcFields, calculationInfo } = this.state;
 
        var thisCalculation = calculationInfo.find(f=>f.name === fc.fieldname)  
        
        if (thisCalculation) { 
            var calculation = [], newActCalcFields = actCalcFields, actvalue = "", temp = {}; 

            let theFormula = thisCalculation.calculation //grab the calc array
            theFormula.forEach(calcField=>{
                var foundfield = calcField.match(/^\[[a-zA-Z0-9._\s]+\]+$/); //has brackets

                if (foundfield && foundfield.length > 0) {
                    let removedbracketsfield = foundfield[0].replace(/[^-+*/\w\s]/gi, ""); //remove brackets from found fields
                    removedbracketsfield = removedbracketsfield.replaceAll("\n"," ") //replace bs new lines
             
                    // eslint-disable-next-line
                    var dummy = newActCalcFields.find((afield) => {
                        //chk state for field name
                        if (afield.fieldname === removedbracketsfield) {
                            if (afield.fieldname === fc.fieldname || (fc.field && afield.fieldname === fc.field.name)) {
                                // eliminate "" in database to zero as we cant calculate a blank string
                                if (actvalue === "") actvalue = 0;

                                temp = { ...afield }; //clone
                                temp["userinput"] = actvalue; //set new value
                                return calculation.push(actvalue);
                            } else {
                                //nonmatch eliminate "" in database to zero as we cant calculate a blank string
                                if (afield.userinput === "" || afield.userinput === undefined) {
                                    afield.userinput = 0;
                                }
                                var removeUnits = afield.userinput.toString().replace(/[^0-9.]/g, ""); //remove any units with userinput that was already saved
                                return calculation.push(removeUnits); 
                            }
                        } else {
                            return null;
                        }
                    });
                } else {
                    //operator/static values in calculation formula push
                    calculation.push(calcField);
                }
            })
            let manual = 'manual'
            var result = this.eval(calculation, thisCalculation, manual, evaluate);  //evaluate from its inputs
                
            //recreate updated input calc fields for state
            newActCalcFields = actCalcFields.map((af) => {
                if (af.id === fc.id) {
                    let t = {...af}; //clone
                    t["userinput"] = result; //set new value
                    return t;
                }else {
                    return af; //return non-touched field
                }
            });
        }
       
        this.setState({ actCalcFields: newActCalcFields }, ()=>{
              this.calculate(thisCalculation, result) //now update values in other calc fields that maybe usiung this field
        }); 
        
    };

    calculate = (fc, value) => {
        const { actCalcFields, calculationInfo } = this.state;

        var actvalue = value.toString().split(",").join(""); //remove comma
        actvalue = actvalue.replace(/[^0-9.]/g, ""); //remove any units with field

        if ((actCalcFields.length > 0 && actvalue !== "" && actvalue !== "-" && actvalue.match(/^[0-9.-]+$/)) || fc.type === "Calculated") {
            //check that not blank input field and no characters
            var calculation = [];
            var temp = {};
            var newActCalcFields = actCalcFields,
                newCalcState = [];

            calculationInfo.forEach((c, i) => {
                
                //dont update manual calculation fields here we only do that in the manualCalculate function, but we need to update input fields value into state and calc info
                if (c.manual_calculation){
                    newCalcState = [];
                    calculationInfo.forEach((ci) => {
                        ci.id === c.id ? newCalcState.push(c) : newCalcState.push(ci);
                    });
                    //recreate updated calc input fields for state
                    newActCalcFields = newActCalcFields.map((af) => {
                        if (af.id === fc.id) {
                            let t = {...af}; //clone
                            t["userinput"] = value; //set new value
                            return t;
                        } else {
                            return af; //return non-touched field
                        }
                    });
                    return this.setState({ calculationInfo: newCalcState, actCalcFields: newActCalcFields }) 
                } 

                //loop through user saved calculation string and remove brackets
                return c.calculation.forEach((calcfield, index) => {
                    var foundfield = calcfield.match(/^\[[a-zA-Z0-9._\s]+\]+$/); //has brackets

                    if (foundfield && foundfield.length > 0) {
                        let removedbracketsfield = foundfield[0].replace(/[^-+*/\w\s]/gi, ""); //remove brackets from found fields
                        removedbracketsfield = removedbracketsfield.replaceAll("\n"," ") //replace bs new lines
                        if (removedbracketsfield === "Internal_ID") {
                            calculation.push(newActCalcFields[0].recordsection.id);
                        } else {
                            // eslint-disable-next-line
                            var dummy = newActCalcFields.find((afield) => {
                                //chk state for field name
                                if (afield.fieldname === removedbracketsfield) {
                                    if (afield.fieldname === fc.fieldname || (fc.field && afield.fieldname === fc.field.name)) {
                                         
                                        // eliminate "" in database to zero as we cant calculate a blank string
                                        if (actvalue === "") actvalue = 0;

                                        temp = { ...afield }; //clone
                                        temp["userinput"] = actvalue; //set new value
                                        return calculation.push(actvalue);
                                    } else {
                                        //nonmatch eliminate "" in database to zero as we cant calculate a blank string
                                        if (afield.userinput === "" || afield.userinput === undefined) {
                                            afield.userinput = 0;
                                        }
                                    
                                        var removeUnits = afield.userinput.toString().replace(/[^0-9.]/g, ""); //remove any units with userinput that was already saved
                                        return calculation.push(removeUnits);
                                   
                                    }
                                } else {
                                    return null;
                                }
                            });
                        }
                    } else {
                        //operator/static values in calculation formula push
                        calculation.push(calcfield);
                    }

                    //route to 'eval'method as this is the end of the calculation formula so evaluate it
                    if (index === c.calculation.length - 1) {
                        var result = this.eval(calculation, c, actvalue, evaluate);
                        calculation = []; //next calc array

                        //recreate updated calc fields for state
                        newActCalcFields = newActCalcFields.map((af) => {
                            if (af.id === temp.id) {
                                let t = { ...temp }; //clone
                                t["userinput"] = temp.userinput; //set new value
                                return t;
                            } else if (af.id === c.recordfield_id) {
                                let t = { ...af }; //clone
                                t["userinput"] = result; //set new value
                                return t;
                            } else {
                                return af; //return non-touched field
                            }
                        });

                        c.recordfield_userinput = result; //update calculation recordfield

                        newCalcState = [];
                        calculationInfo.forEach((ci) => {
                            ci.id === c.id ? newCalcState.push(c) : newCalcState.push(ci);
                        });
                    }
                });
            });
            this.setState({ calculationInfo: newCalcState, actCalcFields: newActCalcFields });
        }
    };

    //helper method to evaluate calculation fields--made to handle 2 or more calc fields, called from 'calculate' method
    eval = (calculation, c, value, evaluate) => {
        const { recordfields } = this.props;
        const { touchedFields } = this.state;

        var resultString = ""; //build calculation string from the above array. This will be ONE string (i.e "field1 + 2...")
        calculation.forEach((f) => {
            resultString += f;
        });

        let dontSkip = true;
        //custom skip for ccwd if review fee is 0 we dont want to recalculate
        if ((c.id === 25019 && calculation[2] === "0")) dontSkip = false;

        //evaluate the calculation formula
        if (resultString && dontSkip) {
            resultString = resultString.replace(/,/g, ""); //remove commas from numerical before evaluating

            var result = evaluate(resultString);

            if (c.measurement_unit === "Percent") {
                result = result * 100;
            }
            // if (c.measurement_unit === "Numeric") result = round(result, 3);

            if (c.decimals >= 0) result = result.toFixed(c.decimals);
            if (result === "Infinity" || result === "NaN") {
                //we dont want to store this garbage in db
                result = "";
            }
            var id = this.findFormIdx(`f_${c.recordfield_id}`);
            //set the calc field on the formAPI to the new value
            if (id) this.formApi[id].setValue(`f_${c.recordfield_id}`, result);

            //Rockcounty update another field based on field value
            if(c.id === 40318){
                let approvedpoints = recordfields.find(f=>f.field.id === 40319 ? f : null)
                if (id && approvedpoints) this.formApi[id].setValue(`f_${approvedpoints.id}`, result >= 41 ? "Yes": "No");
                
                //manual set touched
                if (touchedFields && !touchedFields.find((r) => r === "f_" + approvedpoints.id)) {
                    touchedFields.push("f_" + approvedpoints.id);
                    this.setState({ touchedFields });
                }
            } 
            return result; 

        }

        //chk not blank, not has numbers and chars, or chars only in fields else throw error
        if (value === "" || value.match(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/) || value.match(/^[a-zA-Z]+$/)) {
            if (value !== 'manual')this.setState({ snackbarOpen: true });
            
        }
    };

    //check if there are calc datatypes if so set state for 'calculations' and 'allfields'
    checkForCalcFields = () => {
        const { forms, recordfields } = this.props;
        const { actCalcFields } = this.state;

        var hascalcfield = false;
        var calculationInfo = []; //includes field id, name, type, calculation, recordfield id and recordfield username this is used matching up fields
        var allfields = [];

        //check for cal field datatype
        forms.forEach((f) => {
            f.groups.forEach((g) => {
                g.fields.forEach((fi) => {
                    if (fi.type === "Calculated") {
                        hascalcfield = true;
                        return;
                    }
                });
            });
        });

        //create two objects. One is calculation info
        if (hascalcfield) {
            forms.forEach((f) => {
                f.groups.forEach((g) => {
                    g.fields.forEach((fi) => {
                        var rf = recordfields.find((f) => f.field.id === fi.id);
                        if (rf !== undefined && fi.type === "Calculated") {
                            var temp = {};
                            temp = { ...fi }; //clone field props to a new object
                            temp["calculation"] = fi.default_value.split(",").map((t) => {
                                var i = t.trim();
                                return i;
                            });
                            temp["recordfield_id"] = rf.id;
                            temp["recordfield_userinput"] = rf.userinput;
                            calculationInfo.push(temp);
                        }

                        if (rf) {
                            //push all fields into an array for removing non-calc'd below
                            rf["fieldname"] = fi.name;
                            rf["type"] = fi.type;
                            rf["calculation"] = calculationInfo["calculation"];
                            allfields.push(rf);
                        }
                    });
                });
            });

            //save actual fields used in calculation, save calculation field with custom attachment props, cleaned up field names.
            if (allfields && actCalcFields.length === 0) {
                this.setState((s) => {
                    return { actCalcFields: allfields, calculationInfo };
                });
            }
        }
    };

    //this will autopopulate a date field based on the settings set in the field configuation (ex. add or subtract from another set fields date)
    autoPopulateAnotherDate = (value, groupField) => {
        const { recordfields } = this.props;
        const { touchedFields } = this.state;

        if (value !== "Invalid Date" && value !== "" && value !== "Required") {
            //find the field to update below
            var rf = recordfields.find((f) => f.field.id === groupField.id);

            var date = new Date(value);

            var endDate;
            //check if we are to skip weekends
            if (groupField.skip_weekends) {
                //we will add just business days to the enddate skipping weekends
                endDate = addBusinessDays(date, parseInt(groupField.values_csv));

                if (groupField.skip_holidays) {
                    date = this.skip_holiday(date, endDate);
                } else {
                    //not checking holidays so reset back to the new date value
                    date = endDate;
                }
            } else if (groupField.skip_holidays && !groupField.skip_weekends) {
                //we chk if we want to just skip holidays and not weekends
                endDate = addDays(date, parseInt(groupField.values_csv));

                //now skip holidays
                date = this.skip_holiday(date, endDate);
            } else {
                //not skip_holidays and not skip_weekends
                date.setDate(date.getDate() + parseInt(groupField.values_csv));
            }

            // now we are going to format the date
            var formatDate =
                (date.getMonth() + 1 < 10 ? "0" : "") +
                (date.getMonth() + 1) +
                "/" +
                ((date.getDate() < 10 ? "0" : "") + date.getDate()) +
                "/" +
                date.getFullYear();

            let id = this.findFormIdx(`f_${rf._fields.id}`);
            if (id) this.formApi[id].setValue(`f_${rf._fields.id}`, formatDate);

            touchedFields.push(`f_${rf._fields.id}`);
            this.setState({ touchedFields });
        }
    };

    skip_holiday = (date, endDate) => {
        //get all day objects in the interval from start to end date
        var result = eachDayOfInterval({ start: date, end: endDate });

        var dynamicHolidays = {
            // keys are formatted as month,week,day
            "0,3,1": "Martin Luther King, Jr. Day", //3rd Mon in Jan
            "1,3,1": "President's Day", //3rd Mon in Feb
            "4,5,1": "Memorial Day", //Last Mon in May
            "8,1,1": "Labor Day", //1st Mon in Sept
            // "9,2,1": "Columbus Day", //2nd Mon in Oct
            "10,4,4": "Thanksgiving Day", //4th Thurs in Nov
            "10,4,5": "Black Friday", //4th Thurs in Nov
            // "2,2,0": "Daylight Savings Time Begins", //2nd Sun in Mar
            // "3,3,3": "Administrative Professionals Day", //3rd Wed in Apr
            // "4,2,0": "Mother's Day", //2nd Sun in May (in the US, but not all countries)
            // "5,3,0": "Father's Day", //3rd Sun in Jun (in the US, but not all countries)
            // "6,4,0": "Parents Day", //4th Sun of Jul
            //"8,1,0": "Grandparents Day",               //Technically this is the 1st Sun AFTER Labor Day
            //  so can't be represented perfectly this way
            // "8,5,0": "Gold Star Mothers Day", //Last Sun in Sep
        };
        var staticHolidays = {
            "0,1": "New Years Day", //
            "5,19": "Juneteenth",
            "6,4": "Independance Day",
            "10,11": "Veterans Day",
            // "11,24": "Christmas Eve", //xmas eve
            "11,25": "Christmas Day", //
        };

        //dynamic martin luther king day week change
        let jan1st = new Date(date.getFullYear(), 0, 1);
        let issunday = isSunday(jan1st);
        let ismonday = isMonday(jan1st);
        if (!issunday && !ismonday) {
            delete dynamicHolidays["0,3,1"];
            dynamicHolidays["0,4,1"] = "Martin Luther King, Jr. Day";
        }
        //dynamic presidents day week change
        let feb1st = new Date(date.getFullYear(), 1, 1);
        issunday = isSunday(feb1st);
        ismonday = isMonday(feb1st);
        if (!issunday && !ismonday) {
            delete dynamicHolidays["1,3,1"];
            dynamicHolidays["1,4,1"] = "Presidents Day";
        }
        //dynamic memorialday as it changes from week 5 to 6 only when 1st is on a saturday
        let may1st = new Date(date.getFullYear(), 4, 1);
        let issaturday = isSaturday(may1st);
        if (issaturday) {
            delete dynamicHolidays["4,5,1"];
            dynamicHolidays["4,6,1"] = "Memorial Day";
        }
        //dynamic labor day week change
        let sept1st = new Date(date.getFullYear(), 8, 1);
        issunday = isSunday(sept1st);
        ismonday = isMonday(sept1st);
        if (!issunday && !ismonday) {
            delete dynamicHolidays["8,1,1"];
            dynamicHolidays["8,2,1"] = "Labor Day";
        }

        //dynamic thanksgiving week change changes on friday/sat for 1st of month
        let nov1st = new Date(date.getFullYear(), 10, 1);
        let isfriday = isFriday(nov1st);
        issaturday = isSaturday(nov1st);
        if (isfriday || issaturday) {
            delete dynamicHolidays["10,4,4"];
            dynamicHolidays["10,5,4"] = "Thanksgiving Day";
        }

        //now we will check holidays with the array of days and add additional as needed
        var addDays = 0;
        result.forEach((obj) => {
            if (obj) {
                let weekOfMonth = getWeekOfMonth(obj);
                let thisDate = obj.getMonth() + "," + weekOfMonth + "," + obj.getDay();
                let staticDates = obj.getMonth() + "," + obj.getDate();
                //we are going to check for dynamic holidays within the result objects of dates & add days if we see one
                Object.keys(dynamicHolidays).forEach((k) => {
                    if (thisDate === k) {
                        addDays += 1;
                    }
                });
                Object.keys(staticHolidays).forEach((k) => {
                    if (staticDates === k) {
                        addDays += 1;
                    }
                });
            }
        });

        //TODO: check one last time to make sure date that is now calculated is not another holiday date
        var newDate = addBusinessDays(endDate, addDays);
        return newDate;
    };

    //this is to update the non-repeatable field as the summing of the repeatable are sent to this method
    // we also will recalculate as another cal field could be used.
    setField = (repeatSum, record) => {
        const { counter, actCalcFields, isLastRepeatableRecord } = this.state;
        const { ormRecordFieldUpdate } = this.props;
        if (record && counter === 0) {
            var id = this.findFormIdx(`f_${record.id}`);
            //set the calc field on the form to the new value
            if (id) this.formApi[id].setValue("f_" + record.id, isLastRepeatableRecord ? 0 : repeatSum[record.field.name]);

            this.setState({ counter: counter + 1 });
            //need to update rf as the actCalcFields needs to update for any cal field to properly calculate
            ormRecordFieldUpdate({ id: record.id, userinput: isLastRepeatableRecord ? 0 : repeatSum[record.field.name] });
            setTimeout(() => {
                actCalcFields.forEach((f) => {
                    if (f.id === parseInt(record.id)) {
                        this.calculate(record, isLastRepeatableRecord ? 0 : repeatSum[record.field.name]);
                    }
                });
            }, 500);
            setTimeout(() => {
                if (isLastRepeatableRecord) this.setState({ isLastRepeatableRecord: false, lastRepeatableTableData: null });
            }, 600);
        }
    };

    //prop reset used for putting calculations of repeatable into non-repeatable fields, also handles last repeatablerecord deletion to update any outside fields
    resetCounter = (isLastRepeatableRecord, lastRepeatableTableData, custom) => {
        this.setState({ counter: 0, isLastRepeatableRecord, lastRepeatableTableData});

        if(custom){ // custom for crwd cap projects/bmp main new/ to update the 'current year cost' field to use with the calculation, seems silly but calculation is done on backend so need to make sure if they manually update an input field that it uses new recordfield value
            this.setState({ actCalcFields: []});
            this.checkForCalcFields() 
        }  
    };

    //find the formAPI idx based on recordfield
    findFormIdx = (field) => {
        //find the formAPI idx based on recordfield
        var id = null;
        Object.entries(this.formApi).forEach(([k, v]) => {
            Object.keys(v.values).forEach((val) => {
                if (val === field) id = k;
            });
        });
        return id;
    };

    submit = (val) => {
        this.setState({ dontRender: true });

        const keys = Object.keys(this.formApi);
        keys.forEach((k, idx) => {
            if (idx === keys.length - 1) {
                //map edit button--val === true
                //if we are not routing to map val === undefinded we will send false to trigger snackbar of saved//
                val = val ? "true" : "false";
                this.formApi[k].submitForm(val);
            } else {
                this.formApi[k].submitForm();
            }
        });
    };
    //This method controls the 'Form' output onto the screen for filling out a form record
    // it will find the form that was selected and render it to the screen
    formOutput = (readOnly) => {
        const {
            classes,
            recordfields,
            forms,
            parentRecordId,
            ormParentRecordDelete,
            ormRecordSectionDelete,
            ormAttachmentSectionDelete,
            section,
            page,
            repeatableTable,
            mirrorRepeatableTable,
            allUsers,
            recordSectionID,
            ormRecordSectionUpdateLocalOnly,
            recordsection,
        } = this.props;
        const {
            snackbarOpen,
            deleteDialogConfirmAction,
            deleteDialogOpen,
            deleteDialogText,
            deleteDialogTitle,
            counter,
            save,
            lastRepeatableTableData,
        } = this.state;

        var arr = [];
        if (counter === 0) var repeatSum = {};
        if (repeatSum && Object.keys(repeatSum).length > 0) this.setState({ counter: counter + 1 });

        return (
            <>
                {/****** RETURN THE EXPANSION PANELS ON THE MONITOR, INCLUDES THE GROUPS WITH FIELDS FOR EACH SECTION************/}
                {forms.map((i) => {
                    return i.groups.map((k, index) => {
                        var groups,
                            _this = this;

                        const { form, id } = _this.props.recordsection;
                        groups = recordfields.find((f) => f.group.id === k.id); //only allow groups that are apart of recordfield

                        if ((groups !== undefined || k.form === form.id) && !k.mirror_repeatabletable) {
                            //get just the repeatable table id & field id's for THAT group
                            var repeatabletablefields = [];
                            repeatableTable.forEach((f) => {
                                return f.group === k.id ? repeatabletablefields.push(f) : null;
                            });
                            //grab last repeatable table data (passed in from repeatabletable)to update any outside fields as when last record is deleted there isnt any fields to update the calc recordsfields
                            if (repeatabletablefields.length === 0 && lastRepeatableTableData) {
                                var isLastRepeatRecord = false;
                                lastRepeatableTableData.forEach((f) => {
                                    return f.group === k.id ? repeatabletablefields.push(f) : null;
                                });
                                if (repeatabletablefields.length > 0) isLastRepeatRecord = true;
                            }

                            //look for repeatable that are using 'SUMMING' to be put in an outside form field within this section
                            var r = [];
                            repeatabletablefields.forEach((f, i) => {
                                return (
                                    (r[i] = f),
                                    f.repeatablefields.forEach((rf, j) => {
                                        return k.fields.find((p) => {
                                            //find repeatable fields
                                            var sum = {};
                                            return p.id === rf.field && p.type !== "Drop Down"
                                                ? ((r[i].repeatablefields[j]["field"] = p.id),
                                                  (r[i].repeatablefields[j]["values_csv"] = p.values_csv),
                                                  counter === 0 //only do once for putting calculations (SUMMING) of repeatable into non-repeatable fields, also runs when repeatable table record is deleted
                                                      ? p.values_csv &&
                                                        //is field w value in array? if not add
                                                        !arr.find((f) => {
                                                            return Object.keys(f).toString() === p.values_csv;
                                                        })
                                                          ? rf.userinput //if value isnt undefinded/null assign to the value summation field
                                                              ? ((sum[p.values_csv] = parseFloat(rf.userinput)),
                                                                (repeatSum[p.values_csv] = parseFloat(rf.userinput)),
                                                                arr.push(sum),
                                                                (sum = {}),
                                                                //--------------------------RCWD CUSTOM OUTSIDE x2 FIELDs OTHER CALCULATION STUFF-----------v
                                                                //we use total collected and put into 'total variance fee' and 'total application fee'
                                                                p.id === 16824
                                                                    ? r[i].repeatablefields.find((a, b) => {
                                                                          return a.field === 16825 && a.userinput === "variance fee"
                                                                              ? ((repeatSum["Total Variance Fee"] = parseFloat(rf.userinput)),
                                                                                (sum["Total Variance Fee"] = parseFloat(rf.userinput)),
                                                                                arr.push(sum),
                                                                                arr.push((sum["Total Application Fee"] = 0)),
                                                                                (repeatSum["Total Application Fee"] = 0))
                                                                              : null;
                                                                      })
                                                                    : null,
                                                                (sum = {}),
                                                                p.id === 16824
                                                                    ? r[i].repeatablefields.find((a, b) => {
                                                                          return a.field === 16825 && a.userinput === "application fee"
                                                                              ? ((repeatSum["Total Application Fee"] = parseFloat(rf.userinput)),
                                                                                (sum["Total Application Fee"] = parseFloat(rf.userinput)),
                                                                                arr.push(sum),
                                                                                arr.push((sum["Total Variance Fee"] = 0)),
                                                                                (repeatSum["Total Variance Fee"] = 0))
                                                                              : null;
                                                                      })
                                                                    : null)
                                                              : //^-------------------------------RCWD------------------------------------------------^
                                                                ((sum[p.values_csv] = 0), (repeatSum[p.values_csv] = 0), arr.push(sum)) //value is null so assign 0 for allowing summing to occur
                                                          : // arr.push(sum))
                                                          p.values_csv && arr.length > 0
                                                          ? arr.find((a, b) => {
                                                                return Object.keys(arr[b]).toString() === p.values_csv
                                                                    ? rf.userinput //use users input if there is a value for standard outside field calculations
                                                                        ? ((repeatSum[Object.keys(arr[b]).toString()] =
                                                                              parseFloat(repeatSum[p.values_csv]) + parseFloat(rf.userinput)),
                                                                          //------------------RCWD CUSTOM OUTSIDE FIELD OTHER CALCULATION STUFF----------------------v
                                                                          rf.field === 16824 //amount field
                                                                              ? r[i].repeatablefields.find((a, b) => {
                                                                                    return a.field === 16825 && a.userinput === "variance fee" //find type for this record
                                                                                        ? "Total Variance Fee" in repeatSum //check if key in obj and add to prior else just assign
                                                                                            ? (repeatSum["Total Variance Fee"] =
                                                                                                  parseFloat(repeatSum["Total Variance Fee"]) +
                                                                                                  parseFloat(rf.userinput))
                                                                                            : (repeatSum["Total Variance Fee"] = parseFloat(
                                                                                                  rf.userinput
                                                                                              ))
                                                                                        : null;
                                                                                })
                                                                              : null,
                                                                          rf.field === 16824 //amount field with application fee update
                                                                              ? r[i].repeatablefields.find((a, b) => {
                                                                                    return a.field === 16825 && a.userinput === "application fee"
                                                                                        ? "Total Application Fee" in repeatSum
                                                                                            ? (repeatSum["Total Application Fee"] =
                                                                                                  parseFloat(repeatSum["Total Application Fee"]) +
                                                                                                  parseFloat(rf.userinput))
                                                                                            : (repeatSum["Total Application Fee"] = parseFloat(
                                                                                                  rf.userinput
                                                                                              ))
                                                                                        : null;
                                                                                })
                                                                              : null)
                                                                        : //^-------------------------------RCWD------------------------------------------------^
                                                                          (repeatSum[Object.keys(arr[b]).toString()] = //dont add empty string value as field is blank
                                                                              parseFloat(repeatSum[p.values_csv]) + 0)
                                                                    : null;
                                                            })
                                                          : null
                                                      : null)
                                                : null;
                                        });
                                    })
                                );
                            });

                            // JJ: I have no clue what occured above but we need Surety Balance inside of repeatSum in order to automatically save the record when repeatable table is edited
                            if (repeatSum && "Credit Total" in repeatSum) {
                                repeatSum["Surety Balance"] = repeatSum["Credit Total"] - repeatSum["Debit Total"];
                            }

                            //reassign
                            repeatabletablefields = r;
                            return (
                                <Form
                                    key={k.id}
                                    getApi={(el) => (this.formApi[k.id] = el)}
                                    validateOnSubmit="true"
                                    dontValidateOnMount="true"
                                    formDidUpdate={(e) => {
                                        const { unsavedFields, touchedFields } = this.state;
                                        if (!unsavedFields && touchedFields.length > 0) this.setState({ unsavedFields: true });
                                    }}
                                    defaultValues={this.defaultValues(k.id)}
                                    validateError={this.errorValidator}
                                    onSubmit={(values, fromStepper, formApi) => this.updateRecordField(values, fromStepper, formApi)}
                                    pure={false}>
                                    {(formApi) => (
                                        <form onSubmit={formApi.submitForm}>
                                            <Accordion style={{ backgroundColor: "#fff" }} defaultExpanded={k.default_expanded}>
                                                <AccordionSummary
                                                    className={classes.titlePanel}
                                                    classes={{ root: classes.expandedMargin }}
                                                    expandIcon={<ExpandMoreIcon />}
                                                    style={{ backgroundColor: "#ddd" }}>
                                                    <Grid container>
                                                        {index === 0 && (
                                                            <>
                                                                <Grid item xs={9}>
                                                                    <Typography className={classes.heading2}>{k.name}</Typography>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <Grid container>
                                                                        {section.parent && (
                                                                            <Grid item xs={6} style={{ textAlign: "left" }}>
                                                                                {_this.props.recordsection.previous_record_url && (
                                                                                    <Tooltip title="Previous Record">
                                                                                        <Link
                                                                                            onClick={(e) => {
                                                                                                e.stopPropagation();
                                                                                                // When cycling through records the fields don't reload unless has_children_loaded is reset
                                                                                                ormRecordSectionUpdateLocalOnly({
                                                                                                    id: recordSectionID,
                                                                                                    has_children_loaded: false,
                                                                                                    has_repeatable_loaded: false,
                                                                                                });
                                                                                            }}
                                                                                            style={{
                                                                                                padding: 0,
                                                                                                marginRight: 16,
                                                                                                color: "#3c8ac7",
                                                                                            }}
                                                                                            to={_this.props.recordsection.previous_record_url.form}>
                                                                                            <ArrowBack />
                                                                                        </Link>
                                                                                    </Tooltip>
                                                                                )}
                                                                                {_this.props.recordsection.next_record_url && (
                                                                                    <Tooltip title="Next Record">
                                                                                        <Link
                                                                                            onClick={(e) => {
                                                                                                e.stopPropagation();
                                                                                                // When cycling through records the fields don't reload unless has_children_loaded is reset
                                                                                                ormRecordSectionUpdateLocalOnly({
                                                                                                    id: recordSectionID,
                                                                                                    has_children_loaded: false,
                                                                                                    has_repeatable_loaded: false,
                                                                                                });
                                                                                            }}
                                                                                            style={{
                                                                                                padding: 0,
                                                                                                marginRight: 16,
                                                                                                color: "#3c8ac7",
                                                                                            }}
                                                                                            to={_this.props.recordsection.next_record_url.form}>
                                                                                            <ArrowForward />
                                                                                        </Link>
                                                                                    </Tooltip>
                                                                                )}
                                                                            </Grid>
                                                                        )}
                                                                        <Grid item xs={section.parent ? 6 : 12} style={{ textAlign: "right" }}>
                                                                            <Tooltip title="Save Record">
                                                                                <IconButton
                                                                                    disabled={readOnly}
                                                                                    style={{ padding: 0, marginRight: 16 }}
                                                                                    type="button"
                                                                                    color="primary"
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();

                                                                                        this.submit();
                                                                                    }}>
                                                                                    <Save />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            <Tooltip title="Delete Record">
                                                                                <IconButton
                                                                                    disabled={readOnly}
                                                                                    style={{ padding: 0, marginRight: 16 }}
                                                                                    color="primary"
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        this.deleteRecord();
                                                                                    }}>
                                                                                    <DeleteIcon />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        )}
                                                        {index !== 0 && (
                                                            <Grid item xs={12}>
                                                                <Typography className={classes.heading2}>{k.name}</Typography>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </AccordionSummary>

                                                <AccordionDetails>
                                                    {/* FIELDS FOR EACH GROUP */}

                                                    <Grid container spacing={1}>
                                                        {/* Map out record fields for each group as they are NOT repeatable*/}
                                                        {!k.repeatable &&
                                                            k.fields.map((field) => {
                                                                var record = recordfields.find((f) => f.field.id === field.id); //get only matching fields for that group.
                                                                if (record !== undefined) {
                                                                    return (
                                                                        <Grid item xs={12} md={6} xl={4} key={field.id}>
                                                                            {/* CHECK ARROW FUNCTION FOR TYPE */}
                                                                            {field && record && k && this.checkType(field, record, readOnly, k.id)}
                                                                            {repeatSum && // update sum of repeatable into non-repeatable field
                                                                                repeatSum.hasOwnProperty(field.name) &&
                                                                                this.setField(repeatSum, record)}
                                                                        </Grid>
                                                                    );
                                                                } else {
                                                                    return null;
                                                                }
                                                            })}

                                                        {k.repeatable && (
                                                            // show the repeatable tables with fields
                                                            <Grid item xs={12}>
                                                                <RepeatTable
                                                                    parentRS={recordsection ? recordsection.parentinfo.parent_id : null}
                                                                    resetCounter={this.resetCounter}
                                                                    section={section.id}
                                                                    isParent={section.parent}
                                                                    showRepeatableTable={true}
                                                                    pageID={page.id}
                                                                    groupName={k.name}
                                                                    groupFields={k.fields}
                                                                    repeatableTableFields={isLastRepeatRecord ? [] : repeatabletablefields}
                                                                    recordSection={id}
                                                                    allUsers={allUsers}
                                                                    rowsPerPage_={section.repeatable_rowsperpage}
                                                                    applicationReadOnly={recordsection? recordsection.application_read_only: false} //nmc permits to lockout whole section
                                                                />
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </form>
                                    )}
                                </Form>
                            );
                        } else if (k.mirror_repeatabletable) {
                            let cleanedData = mirrorRepeatableTable.filter((m) => k.mirror_repeatablegroup_ids.includes(m.group));
                            return <MirrorRepeatableTable tableData={cleanedData} group={k} />;
                        }
                        return null;
                    });
                })}

                <Grid item xs={12} style={{ marginTop: 16 }}>
                    <Tooltip title="Save Record">
                        <Button
                            disabled={readOnly}
                            type="button"
                            onClick={(e) => {
                                this.submit();
                            }}
                            variant="contained"
                            color="primary"
                            style={{ marginRight: 20 }}>
                            <i className="material-icons">save</i>
                            {save && (
                                <CircularProgress size={29} style={{ display: "inline-block", color: "#fff", marginLeft: -27, marginTop: "-7%" }} />
                            )}
                        </Button>
                    </Tooltip>
                </Grid>
                {snackbarOpen && ( //check for cal errors
                    <Snackbar
                        snackbarOpen={true}
                        handleSnackbarClose={() => this.setState({ snackbarOpen: false })}
                        isSuccess={false}
                        missing="a numerical value for calculation"
                        attachment={false} //err
                        advSearch={false}
                    />
                )}
                <WarningDialog
                    confirmAction={() => {
                        const { section, page, history } = this.props;
                        const { deleteDialogTitle } = this.state;
                        if (deleteDialogTitle === "Delete Primary Record Section") {
                            ormParentRecordDelete(deleteDialogConfirmAction);
                            history.push("/page/" + page.id + "/" + section.id + "/table");
                        } else if (deleteDialogTitle === "Delete Related Record Section") {
                            ormRecordSectionDelete(deleteDialogConfirmAction);
                            history.push("/page/" + page.id + "/parentrecord/" + parentRecordId + "/section/" + section.id + "/form");
                        } else {
                            ormAttachmentSectionDelete(deleteDialogConfirmAction);
                            this.setState({ deleteDialogOpen: false, editDialogOpen: false });
                        }
                    }}
                    cancelAction={() => this.setState({ deleteDialogOpen: false })}
                    open={deleteDialogOpen}
                    title={deleteDialogTitle}
                    text={deleteDialogText}
                />
            </>
        );
    };

    //check the type of datatype
    checkType = (groupfield, fc, readOnly, formidx) => {
        const { parentRecordId } = this.props;
        var isReadOnly;
        if (groupfield.is_read_only || readOnly || fc.recordsection.application_read_only) isReadOnly = true;
        if (groupfield.manual_calculation) isReadOnly = false;

        const { actCalcFields, hideFields, hideDropDownFields } = this.state;
        const { parentFields, forms, recordfields } = this.props;

        //hide logic flag for hidden fields for yes/no logic
        var yesNoHide = false, dropFieldHide = false;
        if (hideFields.length > 0) {
            let c = hideFields.find((f) => {
                return groupfield.name === f ? f : null;
            });
            if (c) yesNoHide = true;
        }
        //hide logic flag for hidden fields for dropdown/multiselect logic
        if (hideDropDownFields.length > 0) {
            let c = hideDropDownFields.find((f) => {
                return groupfield.name === f ? f : null;
            });
            if (c) dropFieldHide = true;
        }

        //child sections that have 'Primary Mirror' type get the field from the parent so we know the 'Primary Data Type"
        if (groupfield.type === "Primary Mirror") {
            parentFields.forEach((g) => {
                return g.groups.forEach((f) => {
                    return f.fields.forEach((fi) => {
                        return fi.name === fc.field.values_csv ? ((groupfield.primaryMirror = true), (groupfield.type = fi.type)) : null;
                    });
                });
            });
        }

        var chkforCalcFlag = false;
        var calcType;
        if(actCalcFields)
            actCalcFields.forEach((f) => {
                if (f.id === fc.id) {
                    chkforCalcFlag = true;
                }
                //set type of calc type (i.e currency or numeric)
                if (f.type === "Currency") {
                    calcType = "Currency";
                } else if (f.type === "Numeric") {
                    return calcType === "Currency" ? "" : (calcType = "Numeric");
                }
                else if (f.type === "Drop Down" && f.field.calc_jsonfield) {
                    return calcType = "Numeric";
                }
            });

        if ((groupfield.type === "Date" && !yesNoHide) || (groupfield.type === "Date" && dropFieldHide)) {
            return (
                <DatePicker
                    field={"f_" + fc.id}
                    expansionPanel={groupfield.description_desktop ? true : false} //for custom styling of label
                    label={
                        groupfield.description_desktop ? (
                            <HelpLabel expansionPanel={true} inputLabel={groupfield.name} helpText={groupfield.description_input} />
                        ) : (
                            groupfield.name
                        )
                    }
                    autoPopulateAnotherDate={groupfield.auto_populate_another_date}
                    required={groupfield.is_required}
                    disabled={isReadOnly||dropFieldHide}
                    fullWidth
                    eventHandle={(value) => {
                        const { touchedFields } = this.state;
                        //manual set touched as wasnt working in component before so keep it now in state
                        if (!touchedFields.find((r) => r === "f_" + fc.id)) {
                            touchedFields.push("f_" + fc.id);
                            this.setState({ touchedFields });
                        }

                        if (value === "Required" && groupfield.is_required) {
                            this.formApi[formidx].setError("Required Field");
                            return this.formApi[formidx].setValue(`f_${fc.id}`, "");
                        }
                        if (value === "find other date") {
                            let fieldToCalcFrom;
                            let fieldToUpdate;
                            //check for field to autopopulate
                            forms.forEach((f) => {
                                f.groups.forEach((g) => {
                                    g.fields.forEach((fi) => {
                                        if (fi.name === fc.field.default_value) {
                                            fieldToCalcFrom = fi;
                                            fieldToUpdate = fc.field;
                                            let rf = recordfields.find((f) => f.field.id === fieldToCalcFrom.id);
                                            if (rf) this.autoPopulateAnotherDate(rf.userinput, fieldToUpdate);
                                        }
                                    });
                                });
                            });
                        }
                        //check for other autopopulate fields
                        else if (value) {
                            let field;
                            //check for field to autopopulate
                            forms.forEach((f) => {
                                f.groups.forEach((g) => {
                                    g.fields.forEach((fi) => {
                                        if (fi.default_value === fc.field.name) {
                                            field = fi;
                                            var rf = recordfields.find((f) => f.field.id === field.id);
                                            if (rf) this.autoPopulateAnotherDate(value, field);
                                        }
                                    });
                                });
                            });
                        }
                    }}
                />
            );
        }
        if ((groupfield.type === "Time" && !yesNoHide) || (groupfield.type === "Time" && dropFieldHide)) {
            return (
                <TimePicker
                    field={"f_" + fc.id}
                    expansionPanel={groupfield.description_desktop ? true : false} //for custom styling of label
                    label={
                        groupfield.description_desktop ? (
                            <HelpLabel expansionPanel={true} inputLabel={groupfield.name} helpText={groupfield.description_input} />
                        ) : (
                            groupfield.name
                        )
                    }
                    autoPopulate={groupfield.auto_populate}
                    required={groupfield.is_required}
                    disabled={isReadOnly||dropFieldHide}
                    fullWidth
                    eventHandle={(value) => {
                        const { touchedFields } = this.state;
                        //manual set touched as wasnt working in component before so keep it now in state
                        if (!touchedFields.find((r) => r === "f_" + fc.id)) {
                            touchedFields.push("f_" + fc.id);
                            this.setState({ touchedFields });
                        }
                    }}
                />
            );
        }

        if ((groupfield.type === "Signature" && !yesNoHide) || (groupfield.type === "Signature" && dropFieldHide)) {
            return (
                <>
                    <Typography style={{ marginTop: -8 }}>{groupfield.name}</Typography>
                    <img
                        src={fc.userinput ? "/media/" + fc.userinput : "/media/No-Image-Available.jpg"}
                        alt={groupfield.name}
                        style={{ maxWidth: "99%", border: "1px solid #979797", borderRadius: 4 }}
                    />
                </>
            );
        }
        if ((groupfield.type === "Text" && !yesNoHide) || (groupfield.type === "Text" && dropFieldHide)) {
            return (
                <TextField
                    key={"f_" + fc.id}
                    expansionPanel={groupfield.description_desktop ? true : false}
                    label={
                        groupfield.description_desktop ? (
                            <HelpLabel expansionPanel={true} inputLabel={groupfield.name} helpText={groupfield.description_input} />
                        ) : (
                            groupfield.name
                        )
                    }
                    field={"f_" + fc.id} //fc.id is an arrayid with userinput from DB. This is displayed on the screen
                    multiline={true}
                    units={groupfield.measurement_unit}
                    disabled={isReadOnly || dropFieldHide}
                    required={groupfield.is_required}
                    fullWidth
                    margin="none"
                    eventHandle={(value) => {
                        const { touchedFields } = this.state;
                        //manual set touched as wasnt working in component before so keep it now in state
                        if (!touchedFields.find((r) => r === "f_" + fc.id)) {
                            touchedFields.push("f_" + fc.id);
                            this.setState({ touchedFields });
                        }
                    }}
                />
            );
        }
      
        if ((groupfield.type === "Currency" && !yesNoHide) || (groupfield.type === "Currency" && dropFieldHide)) {
            return (
                <TextField
                    expansionPanel={groupfield.description_desktop ? true : false} //for custom styling of label
                    label={
                        groupfield.description_desktop ? (
                            <HelpLabel expansionPanel={true} inputLabel={groupfield.name} helpText={groupfield.description_input} />
                        ) : (
                            groupfield.name
                        )
                    }
                    field={"f_" + fc.id} //fc.id is an arrayid with userinput from DB. This is displayed on the screen
                    disabled={isReadOnly || dropFieldHide}
                    required={groupfield.is_required}
                    useTextFormat={true}
                    fullWidth
                    eventHandle={(value) => {
                        const { touchedFields } = this.state;
                        //manual set touched as wasnt working in component before so keep it now in state
                        if (!touchedFields.find((r) => r === "f_" + fc.id)) {
                            touchedFields.push("f_" + fc.id);
                            this.setState({ touchedFields });
                        }
                        if (chkforCalcFlag && value === null) value = "0"; //trigger a recalc as calc value could have an incorrect value from before
                        return chkforCalcFlag === true ? this.calculate(fc, value) : "";
                    }}
                    margin="none"
                />
            );
        }
        if ((groupfield.type === "Phone Number" && !yesNoHide) || (groupfield.type === "Phone Number" && dropFieldHide)) {
            return (
                <TextField
                    expansionPanel={groupfield.description_desktop ? true : false} //for custom styling of label
                    label={
                        groupfield.description_desktop ? (
                            <HelpLabel expansionPanel={true} inputLabel={groupfield.name} helpText={groupfield.description_input} />
                        ) : (
                            groupfield.name
                        )
                    }
                    field={"f_" + fc.id} //fc.id is an arrayid with userinput from DB. This is displayed on the screen
                    disabled={isReadOnly||dropFieldHide}
                    required={groupfield.is_required}
                    fullWidth
                    margin="none"
                    usePhoneNumberFormat={true}
                    eventHandle={(value) => {
                        const { touchedFields } = this.state;
                        //manual set touched as wasnt working in component before so keep it now in state
                        if (!touchedFields.find((r) => r === "f_" + fc.id)) {
                            touchedFields.push("f_" + fc.id);
                            this.setState({ touchedFields });
                        }
                    }}
                />
            );
        }
        if ((groupfield.type === "Email" && !yesNoHide ) || (groupfield.type === "Email" && dropFieldHide)) {
            return (
                <TextField
                    type="email"
                    expansionPanel={groupfield.description_desktop ? true : false} //for custom styling of label
                    label={
                        groupfield.description_desktop ? (
                            <HelpLabel expansionPanel={true} inputLabel={groupfield.name} helpText={groupfield.description_input} />
                        ) : (
                            groupfield.name
                        )
                    }
                    field={"f_" + fc.id} //fc.id is an arrayid with userinput from DB. This is displayed on the screen
                    pattern=".+@globex.com"
                    disabled={isReadOnly || dropFieldHide}
                    fullWidth
                    required={groupfield.is_required}
                    margin="none"
                    eventHandle={(value) => {
                        const { touchedFields } = this.state;
                        //manual set touched as wasnt working in component before so keep it now in state
                        if (!touchedFields.find((r) => r === "f_" + fc.id)) {
                            touchedFields.push("f_" + fc.id);
                            this.setState({ touchedFields });
                        }
                    }}
                />
            );
        }
        if ((groupfield.type === "Numeric" && !yesNoHide ) || (groupfield.type === "Numeric" && dropFieldHide)) {
            return (
                <TextField
                    restrictValue={groupfield.restrict_value}
                    thousandSeperator={groupfield.thousands_seperator}
                    useNumberFormat
                    decimals={groupfield.decimals}
                    identification={groupfield.identification}
                    expansionPanel={groupfield.description_desktop ? true : false} //for custom styling of label
                    label={
                        groupfield.description_desktop ? (
                            <HelpLabel expansionPanel={true} inputLabel={groupfield.name} helpText={groupfield.description_input} />
                        ) : (
                            groupfield.name
                        )
                    }
                    field={"f_" + fc.id} //fc.id is an arrayid with userinput from DB. This is displayed on the screen
                    units={groupfield.measurement_unit}
                    disabled={isReadOnly || dropFieldHide}
                    required={groupfield.is_required}
                    eventHandle={(value) => {
                        const { touchedFields } = this.state;
                        //manual set touched as wasnt working in component before so keep it now in state
                        if (!touchedFields.find((r) => r === "f_" + fc.id)) {
                            touchedFields.push("f_" + fc.id);
                            this.setState({ touchedFields });
                        }
                        if (chkforCalcFlag && value === null) value = "0"; //trigger a recalc as calc value could have an incorrect value from before
                        return chkforCalcFlag === true ? this.calculate(fc, value) : "";
                    }}
                    fullWidth
                    margin="none"
                />
            );
        }
        if ((groupfield.type === "Yes/No" && !yesNoHide) || (groupfield.type === "Yes/No" && dropFieldHide)) {
            return (
                <RadioGroup
                    field={"f_" + fc.id}
                    name={fc.id}
                    disabled={isReadOnly||dropFieldHide}
                    required={groupfield.is_required}
                    fullWidth
                    options={[
                        { label: "Yes", value: "Yes" },
                        { label: "No", value: "No" },
                    ]}
                    alignment={true}
                    expansionPanel={groupfield.description_desktop ? true : false} //for custom styling of label
                    label={
                        groupfield.description_desktop ? (
                            <HelpLabel expansionPanel={true} inputLabel={groupfield.name} helpText={groupfield.description_input} />
                        ) : (
                            groupfield.name
                        )
                    }
                    eventHandle={(value) => {
                        const { touchedFields } = this.state;
                        //manual set touched as wasnt working in component before so keep it now in state
                        if (!touchedFields.find((r) => r === "f_" + fc.id)) {
                            touchedFields.push("f_" + fc.id);
                            this.setState({ touchedFields });
                        }
                        if (groupfield.hide_field && groupfield.fields_to_hide) {
                            var updatedHideFields = [];
                            if (value === "Yes") {
                                //check for logic fields
                                let fieldsToShow = groupfield.fields_to_hide.split(",");
                                if (hideFields.length > 0) {
                                    updatedHideFields = hideFields;
                                    fieldsToShow.forEach((f) => {
                                        updatedHideFields = updatedHideFields.filter((s) => {
                                            return s === f ? null : s;
                                        });
                                    });
                                    let _this = this;
                                    let copyTouchedFields = touchedFields;
                                    //now we want to set all default values back incase yes was selected again
                                    forms.forEach((fo) => {
                                        return fo.groups.forEach((fg) => {
                                            return fg.fields.forEach((f) => {
                                                return hideFields.forEach((hf) => {
                                                    if (hf === f.name) {
                                                        recordfields.forEach((rf) => {
                                                            if (rf.field.id === f.id) {
                                                                let defaultVal = rf.field.default_value ? rf.field.default_value.toString() : "0";
                                                                _this.formApi[fg.id].setValue(`f_${rf.id}`, defaultVal);

                                                                if (rf.field.type === "Numeric" || rf.field.type === "Currency")
                                                                    setTimeout(() => _this.calculate(rf, defaultVal), 5); //we will reset default value to rerun calculations, timeout for form to update
                                                                if (!copyTouchedFields.find((tf) => tf === `f_${rf.id}`)) {
                                                                    touchedFields.push(`f_${rf.id}`);
                                                                }
                                                            }
                                                        });
                                                    } else return null;
                                                });
                                            });
                                        });
                                    });
                                }
                            } else {
                                //add back to list
                                let fieldsToShow = groupfield.fields_to_hide.split(",");
                                updatedHideFields = fieldsToShow.concat(hideFields);

                                //event to set all attached hidden to null for the database
                                let copyTouchedFields = touchedFields;
                                let _this = this;
                                forms.forEach((fo) => {
                                    return fo.groups.forEach((fg) => {
                                        return fg.fields.forEach((f) => {
                                            return updatedHideFields.forEach((hf) => {
                                                if (hf === f.name) {
                                                    recordfields.forEach((rf) => {
                                                        if (rf.field.id === f.id) {
                                                            let defaultVal = rf.field.default_value ? rf.field.default_value.toString() : "0";
                                                            _this.formApi[fg.id].setValue(`f_${rf.id}`, defaultVal);

                                                            if (rf.field.type === "Numeric" || rf.field.type === "Currency")
                                                                setTimeout(() => _this.calculate(rf, "0"), 5); //run calculations to zero this field out, timeout for form to update
                                                            if (!copyTouchedFields.find((tf) => tf === `f_${rf.id}`)) {
                                                                touchedFields.push(`f_${rf.id}`);
                                                            }
                                                        }
                                                    });
                                                } else return null;
                                            });
                                        });
                                    });
                                });
                            } //now update state (willcontain yes and no situations)
                            this.setState((prevState) => ({
                                hideFields: updatedHideFields,
                                touchedFields,
                            }));
                        }
                    }}
                />
            );
        }
        if ((groupfield.type === "Drop Down" && !yesNoHide) || (groupfield.type === "Multi Select" && !yesNoHide) || (groupfield.type === "Drop Down" && dropFieldHide) || (groupfield.type === "Multi Select" && dropFieldHide)) {
            var label;
            if (groupfield.is_required) {
                label = groupfield.name.concat(" *");
            } else {
                label = groupfield.name;
            }

            if (groupfield.values_csv && !groupfield.user_dropdown) {
                let tempArr = [];

                //has numbers with commas escapes for thousand values
                if (groupfield.values_csv.includes("\\,")) {
                    let str = groupfield.values_csv;
                    let wtf = "";
                    let shouldPass = false;

                    for (let i = 0; i < str.length; i++) {
                        if (str[i].includes("\\")) {
                            wtf = wtf + "\\"; // + str[i];
                            shouldPass = true;
                        } else if (shouldPass) {
                            //record this comma as \ before it
                            wtf = wtf + str[i];
                            shouldPass = false;
                        } else if (str[i] === ",") {
                            //not \ before it so record selection
                            tempArr.push(wtf);
                            wtf = "";
                        } else {
                            wtf = wtf + str[i];
                            if (i === str.length - 1) {
                                //last selection save
                                tempArr.push(wtf);
                            }
                        }
                    }
                } else {
                    //no numbers with & escape characters so just split
                    tempArr = groupfield.values_csv.split(",");
                }
                                      
                return (
                    <ReactSelect
                        field={"f_" + fc.id}
                        options={this.dropDownValues(tempArr)}
                        disabled={isReadOnly||dropFieldHide}
                        expansionPanel={groupfield.description_desktop ? true : false} //for custom styling of label
                        label={
                            groupfield.description_desktop ? (
                                <HelpLabel expansionPanel={true} inputLabel={label} helpText={groupfield.description_input} />
                            ) : ( label )
                        }
                        type={groupfield.type}
                        primaryMirror={groupfield.primaryMirror}
                        valuesCsv={tempArr}
                        isMulti={groupfield.type === "Drop Down" ? false : true}
                        required={groupfield.is_required}
                        height="auto"
                        fullWidth
                        eventHandle={(value) => {
                            const { touchedFields, hideDropDownFields } = this.state;
                            //manual set touched as wasnt working in component before so keep it now in state
                            if (!touchedFields.find((r) => r === "f_" + fc.id)) {
                                touchedFields.push("f_" + fc.id);
                                this.setState({ touchedFields });
                            }
                          
                            if(groupfield.type === 'Drop Down' && groupfield.calc_jsonfield){    
                                let findKey;
                                let results = JSON.parse(groupfield.calc_jsonfield)
                                let hideFieldsFound = [] 
                                for (const key in results){//see if value matched a k/v pair
                                    if(key === value){
                                        findKey = results[key];
                                        break;
                                    }  
                                }   

                                if (findKey){//this updates another field from the jsonformula, also unhides a field based on the set/dropdown value 
                                    for (let i in findKey){ 
                                        let key = Object.keys(findKey[i])[0]
                                        let val = Object.values(findKey[i])[0]
                                        let rf = recordfields.find(f=>f.field.id === parseInt(key)) //find the recordfield to update
                                        
                                        if(val === 'unhide'){
                                            if (hideFieldsFound.length > 0){//need to do this incase the json is updating > 1 field otherwise mutation issues will occur on hideDropDownFields
                                                hideFieldsFound = hideFieldsFound.filter(d=>d!==rf.field.name) 
                                            }else{
                                                hideFieldsFound = hideDropDownFields.filter(d=>d!==rf.field.name)// so we have a calcjson field that is set to unhide and the value selected matched so lets unhide those other fields
                                            }
                                        }else{
                                            let id = this.findFormIdx(`f_${rf.id}`);
                                            //set outside field to the new value
                                            if (id) this.formApi[id].setValue("f_" + rf.id, val); 

                                            if (chkforCalcFlag && val !== null && id && rf.field.type !== 'Text'){ //trigger a recalc as calc value could have an incorrect value from before--might need timeout to make sure field is updated first 
                                               setTimeout(()=>{
                                                    this.calculate(rf, val) 
                                               },150)
                                            } 
                                        }

                                       //we do this to update the other json text fields with a save
                                        if (!touchedFields.find((r) => r === "f_" + rf.id)) {
                                            touchedFields.push("f_" + rf.id);
                                            this.setState({ touchedFields });
                                        } 
                                    }

                                    //but we have to check one more time to hide dropdown selections that might have 'unhide' keys as their values
                                    //and this specific value wasnt selected but this value DID trigger some other field
                                    for (const key in results){ 
                                        if(key !== value){ 
                                            for (let i of results[key]){  
                                        
                                                let rf = recordfields.find(f=>f.field.id === parseInt(Object.keys(i)[0])) //find the recordfield to update 
                                                if(Object.values(i)[0] === 'unhide'){
                                                    let id = this.findFormIdx(`f_${rf.id}`);
                                                    hideFieldsFound.push(rf.field.name)// so lets hide field again
                                                    if (id) this.formApi[id].setValue("f_" + rf.id, 0); //and set back to zero 
                                                    hideFieldsFound = [...new Set([...hideFieldsFound ,...hideDropDownFields])]; //but bring back over other fields that were hidden as could be on another field

                                                }
                                                //not sure if this is needed but not supporting at the moment
                                                //else if (chkforCalcFlag && id && rf.field.type !== "Text"){//trigger a recalc as calc value could have an incorrect value from before 
                                                //     this.calculate(rf, 0)                                            
                                                // } 
                                                if (!touchedFields.find((r) => r === "f_" + rf.id)) {//we do this to update the other json text fields with a save
                                                    touchedFields.push("f_" + rf.id);
                                                    this.setState({ touchedFields });
                                                } 
                                            }
                                        }
                                    }
                                    
                                  
                        
                                }else{//clear fields to 0 & rehide hidden ones as there wasnt a json formula value selected within the drop down that was supposed to do something
                                
                                    for (const key in results){ 
                                        // results[key].forEach((i)=>{
                                        for (let i of results[key]){       

                                            let rf = recordfields.find(f=>f.field.id === parseInt(Object.keys(i)[0] )) //find the recordfield to update 
                                            let id = this.findFormIdx(`f_${rf.id}`); 
                                            if (id) this.formApi[id].setValue("f_" + rf.id, 0); //set outside field to the new value

                                            if(Object.values(i)[0] === 'unhide'){ 
                                                hideFieldsFound.push(rf.field.name)// so lets hide field again 
                                            }else if (chkforCalcFlag && id && rf.field.type !== "Text"){//trigger a recalc as calc value could have an incorrect value from before 
                                                this.calculate(rf, 0)                                            
                                            }

                                            if (rf.field.type === "Text"){//we do this to update the other json text fields with a save
                                                if (!touchedFields.find((r) => r === "f_" + rf.id)) {
                                                    touchedFields.push("f_" + rf.id);
                                                    this.setState({ touchedFields });
                                                }
                                            } 
                                        }
                                    }
                                    hideFieldsFound = [...new Set([...hideFieldsFound ,...hideDropDownFields])];//rehide those fields local and state fields
                                
                                } 
                                this.setState((prevState) => ({
                                    hideDropDownFields: hideFieldsFound,
                                    touchedFields 
                                }))
                            }
                            else if(groupfield.type === 'Multi Select' && groupfield.calc_jsonfield){    
                                let findKey;
                                let results = JSON.parse(groupfield.calc_jsonfield)
                                let hideFieldsFound = [] 
                                let split = value.split(',')
                               
                                for (const idx in split){ 
                                    for (const key in results){//see if value matched a k/v pair
                                        if(key === split[idx]){
                                            findKey = results[key];
                                            break;
                                        }  
                                    }   

                                    if (findKey){//this updates another field from the jsonformula, also unhides a field based on the set/dropdown value 
                                        for (let i in findKey){ 
                                            let key = Object.keys(findKey[i])[0]
                                            let val = Object.values(findKey[i])[0]
                                            let rf = recordfields.find(f=>f.field.id === parseInt(key)) //find the recordfield to update
                                            
                                            if(val === 'unhide'){
                                                if (hideFieldsFound.length > 0){//need to do this incase the json is updating > 1 field otherwise mutation issues will occur on hideDropDownFields
                                                    hideFieldsFound = hideFieldsFound.filter(d=>d!==rf.field.name) 
                                                }else{
                                                    hideFieldsFound = hideDropDownFields.filter(d=>d!==rf.field.name)// so we have a calcjson field that is set to unhide and the value selected matched so lets unhide those other fields
                                                }
                                            }else{
                                                let id = this.findFormIdx(`f_${rf.id}`);
                                                //set outside field to the new value
                                                if (id) this.formApi[id].setValue("f_" + rf.id, val); 

                                                if (chkforCalcFlag && val !== null && id && rf.field.type !== 'Text'){ //trigger a recalc as calc value could have an incorrect value from before--might need timeout to make sure field is updated first 
                                                    this.calculate(rf, val) 
                                                } 
                                            }

                                            if (rf.field.type === "Text"){//we do this to update the other json text fields with a save
                                                if (!touchedFields.find((r) => r === "f_" + rf.id)) {
                                                    touchedFields.push("f_" + rf.id);
                                                    this.setState({ touchedFields });
                                                }
                                            } 
                                        }

                                        //but we have to check one more time to hide dropdown selections that might have 'unhide' keys as their values for THIS field
                                        //and this specific value wasnt selected but this value DID trigger some other field
                                        for (let key in results){ 
                                            if(!value.includes(key)){  
                                                for (let i of results[key]){  

                                                    let rf = recordfields.find(f=>f.field.id === parseInt(Object.keys(i)[0])) //find the recordfield to update 
                                                    
                                                    if(Object.values(i)[0] === 'unhide'){//set the other field back to 0 
                                                        let id = this.findFormIdx(`f_${rf.id}`); 
                                                        if (id) this.formApi[id].setValue("f_" + rf.id, 0); 
                                                        hideFieldsFound.push(rf.field.name)// so lets hide field again
                                                        hideFieldsFound = [...new Set([...hideFieldsFound ,...hideDropDownFields])]; //but bring back over other fields that were hidden as could be on another field
                                                    }  
                                                    //not sure if this is needed but not supporting at the moment
                                                    //else if (chkforCalcFlag && id && rf.field.type !== "Text"){//trigger a recalc as calc value could have an incorrect value from before 
                                                    //     this.calculate(rf, 0)                                            
                                                    // } 
                                                    if (rf.field.type === "Text"){//we do this to update the other json text fields with a save
                                                        if (!touchedFields.find((r) => r === "f_" + rf.id)) {
                                                            touchedFields.push("f_" + rf.id);
                                                            this.setState({ touchedFields });
                                                        }
                                                    } 
                                                }
                                            }
                                        }
                        
                                    }else{//clear fields to 0 & rehide hidden ones as there wasnt a json formula value selected within the drop down that was supposed to do something
                                    
                                        for (const key in results){  
                                            for (let i of results[key]){      
                                                let rf = recordfields.find(f=>f.field.id === parseInt(Object.keys(i)[0])) //find the recordfield to update
                                                let id = this.findFormIdx(`f_${rf.id}`);
                                              
                                                //set outside field to the new value
                                                if (id) this.formApi[id].setValue("f_" + rf.id, 0);

                                                if(Object.values(i)[0] === 'unhide'){ 
                                                    hideFieldsFound.push(rf.field.name)// so lets hide field again 
                                                }else if (chkforCalcFlag && id && rf.field.type !== "Text"){//trigger a recalc as calc value could have an incorrect value from before 
                                                    this.calculate(rf, 0)                                            
                                                }

                                                if (rf.field.type === "Text"){//we do this to update the other json text fields with a save
                                                    if (!touchedFields.find((r) => r === "f_" + rf.id)) {
                                                        touchedFields.push("f_" + rf.id);
                                                        this.setState({ touchedFields });
                                                    }
                                                } 
                                            }
                                        }
                                        hideFieldsFound = [...new Set([...hideFieldsFound ,...hideDropDownFields])];//rehide those fields local and state fields 
                                    }
                                }
                                this.setState((prevState) => ({
                                    hideDropDownFields: hideFieldsFound,
                                    touchedFields 
                                }))
                            }
                        }}
                    />
                );
 
            } else if (groupfield.user_dropdown) {
                //userdropdown gets all users into list
                const { allUsers } = this.props;
                const nameUsers = [];
                allUsers.sort((a, b) => {
                    return a["name"] < b["name"] ? -1 : 1;
                });
                allUsers.forEach((u) => {
                    return u.is_active && !(u.username.includes("heitest") || u.username.includes("ms4permit")) ? nameUsers.push(u.name) : null;
                });

                return (
                    <ReactSelect
                        expansionPanel={groupfield.description_desktop ? true : false} //for custom styling of label
                        label={
                            groupfield.description_desktop ? (
                                <HelpLabel expansionPanel={true} inputLabel={label} helpText={groupfield.description_input} />
                            ) : (
                                label
                            )
                        }
                        primaryMirror={true} //this is set to bypass in reactselect.js the closest senario, this is due to users might not be in the list as 'is_active' above on prior records but we want to show what was previously saved
                        field={"f_" + fc.id}
                        options={this.dropDownValues(nameUsers)}
                        disabled={isReadOnly||dropFieldHide}
                        isMulti={groupfield.type === "Drop Down" ? false : true}
                        required={groupfield.is_required}
                        //={groupfield.auto_populate ? authState.user.name : null}
                        height="auto"
                        fullWidth
                        eventHandle={(value) => {
                            const { touchedFields } = this.state;
                            //manual set touched as wasnt working in component before so keep it now in state
                            if (!touchedFields.find((r) => r === "f_" + fc.id)) {
                                touchedFields.push("f_" + fc.id);
                                this.setState({ touchedFields });
                            }
                        }}
                    />
                );
            } else if (groupfield.populate_from_another_section) {
                if (groupfield.populate_field) {
                    return (
                        <ReactSelectAsync
                            field={"f_" + fc.id}
                            expansionPanel={groupfield.description_desktop ? true : false} //for custom styling of label
                            label={
                                groupfield.description_desktop ? (
                                    <HelpLabel expansionPanel={true} inputLabel={label} helpText={groupfield.description_input} />
                                ) : (
                                    label
                                )
                            }
                            disabled={isReadOnly||dropFieldHide}
                            isMulti={groupfield.type === "Drop Down" ? false : true}
                            required={groupfield.is_required}
                            height="auto"
                            fullWidth
                            dropDownAutoLoad={true}
                            groupfield={groupfield}
                            parentRecordId={parentRecordId}
                            parentSection={parentFields[0].section}
                            eventHandle={(value) => {
                                const { touchedFields } = this.state;
                                //manual set touched as wasnt working in component before so keep it now in state
                                if (!touchedFields.find((r) => r === "f_" + fc.id)) {
                                    touchedFields.push("f_" + fc.id);
                                    this.setState({ touchedFields });
                                }
                            }}
                        />
                    );
                }
            }
        }

        if ((groupfield.type === "Radio" && !yesNoHide) || (groupfield.type === "Radio" && dropFieldHide)) {
            if (groupfield.values_csv) {
                let tempArr = [];
                let options = [];
                tempArr = groupfield.values_csv.split(",");
                tempArr.forEach((t) => {
                    options.push({ label: t, value: t });
                });
                if (fc.userinput && !tempArr.find((f) => f === fc.userinput)) {
                    options.push({ label: "[LEGACY DATA] - " + fc.userinput, value: fc.userinput, color: "red" });
                }
                return (
                    <RadioGroup
                        field={"f_" + fc.id}
                        name={fc.id}
                        disabled={isReadOnly||dropFieldHide}
                        required={groupfield.is_required}
                        fullWidth
                        options={options}
                        alignment={true}
                        expansionPanel={groupfield.description_desktop ? true : false} //for custom styling of label
                        label={
                            groupfield.description_desktop ? (
                                <HelpLabel expansionPanel={true} inputLabel={groupfield.name} helpText={groupfield.description_input} />
                            ) : (
                                groupfield.name
                            )
                        }
                        eventHandle={(value) => {
                            const { touchedFields } = this.state;
                            //manual set touched as wasnt working in component before so keep it now in state
                            if (!touchedFields.find((r) => r === "f_" + fc.id)) {
                                touchedFields.push("f_" + fc.id);
                                this.setState({ touchedFields });
                            }
                        }}
                    />
                );
            }
        }

        if ((groupfield.type === "Calculated" && !yesNoHide) || (groupfield.type === "Calculated" && dropFieldHide)) {
            return (
                <TextField
                    type="calculated"
                    field={"f_" + fc.id}
                    expansionPanel={groupfield.description_desktop ? true : false} //for custom styling of label
                    label={
                        groupfield.description_desktop ? (
                            <>
                                <HelpLabel expansionPanel={true} inputLabel={groupfield.name} helpText={groupfield.description_input} />
                               
                                {groupfield.manual_calculation && (
                                    <Tooltip title='Recalculate this field? Note: numeric/currency or other calculated fields attached to this field will not automatically update until calculate is pressed. Pressing calculate will also disregard any manual entries added into this field.'> 
                                        <Button variant="contained" color="primary" style={{paddingBottom:10,marginLeft:8,  
                                            marginBottom:8,padding:10, lineHeight:0, width:'auto', fontSize:15}} onClick={(e)=>{
                                                e.stopPropagation()
                                                this.manualCalculate(fc) 
                                            }}>
                                            Calculate
                                        </Button> 
                                    </Tooltip>
                                )}         
                            </>
                        ) : (<>
                            {groupfield.name}
                            {groupfield.manual_calculation  && (
                                <Tooltip title='Recalculate this field? Note: numeric/currency or other calculated fields attached to this field will not automatically update until calculate is pressed. Pressing calculate will also disregard any manual entries added into this field.'> 
                                    <Button variant="contained" color="primary" style={{paddingBottom:10,marginLeft:8,  
                                        marginBottom:8,padding:10, lineHeight:0, width:'auto', fontSize:15}} onClick={(e)=>{
                                            e.stopPropagation()
                                            this.manualCalculate(fc) 
                                        }}>
                                        Calculate
                                    </Button> 
                                </Tooltip>
                            )}</>
                        )
                    }
                    disabled={isReadOnly||dropFieldHide}
                    required={groupfield.is_required}
                    useTextFormat={groupfield.measurement_unit === "Currency" ? true : false}
                    useNumberFormat={groupfield.measurement_unit !== "Currency" ? true : false}
                    decimals={groupfield.decimals}
                    fullWidth
                    margin="none"
                    thousandSeperator={groupfield.measurement_unit === "Numeric"? true:false}
                    units={groupfield.measurement_unit === "Percent" ? "%" : ""}
                />
            );
        }
        if ((groupfield.type === "Incremental" && !yesNoHide) || (groupfield.type === "Incremental" && dropFieldHide)) {
            return (
                <TextField
                    type="incremental"
                    field={"f_" + fc.id}
                    expansionPanel={groupfield.description_desktop ? true : false} //for custom styling of label
                    label={
                        groupfield.description_desktop ? (
                            <HelpLabel expansionPanel={true} inputLabel={groupfield.name} helpText={groupfield.description_input} />
                        ) : (
                            groupfield.name
                        )
                    }
                    disabled={true}
                    required={groupfield.is_required}
                    fullWidth
                    margin="none"
                />
            );
        }
        if ((groupfield.type === "Dynamic Label" && !yesNoHide) || (groupfield.type === "Dynamic Label" && dropFieldHide)) {
            return (
                <EnhancedTextField
                    expansionPanel={groupfield.description_desktop ? true : false} //for custom styling of label
                    label={
                        groupfield.description_desktop ? (
                            <HelpLabel expansionPanel={true} inputLabel={groupfield.name} helpText={groupfield.description_input} />
                        ) : (
                            groupfield.name
                        )
                    }
                    field={"f_" + fc.id} //fc.id is an arrayid with userinput from DB. This is displayed on the screen
                    multiline={true}
                    disabled={true}
                    fullWidth
                    hyperlink={groupfield.hyperlink}
                    margin="none"
                />
            );
        }
        if ((groupfield.type === "Related Mirror" && !yesNoHide) || (groupfield.type === "Related Mirror" && dropFieldHide)) {
            return (
                <EnhancedTextField
                    expansionPanel={groupfield.description_desktop ? true : false} //for custom styling of label
                    label={
                        groupfield.description_desktop ? (
                            <HelpLabel expansionPanel={true} inputLabel={groupfield.name} helpText={groupfield.description_input} />
                        ) : (
                            groupfield.name
                        )
                    }
                    field={"f_" + fc.id} //fc.id is an arrayid with userinput from DB. This is displayed on the screen
                    multiline={true}
                    disabled={true}
                    fullWidth
                    // hyperlink={groupfield.hyperlink}
                    margin="none"
                />
            );
        }
    };

    //value function for dropdown datatype. This is the options property.
    dropDownValues = (table) => {
        return table.map((row) => ({
            label: row,
            value: row,
        }));
    };

    loadRecordFields = (from_mount) => {
        const {
            authState,
            recordsection,
            recordSectionID,
            ormRecordFieldLoadDetailChild,
            ormAttachmentSectionLoadDetailChild,
            ormRecordSectionUpdateLocalOnly,
            ormRecordSectionLoadDetailChild,
            section,
            history,
            page,
        } = this.props;
        const { loadingFields, loadingRS /*, loadingRSFinished*/ } = this.state;

        const _this = this;

        // If you load this page from a bookmark it doesn't have an authstate right away
        if (!authState || !authState.user) return;

        // We are editing an actual form (recordSectionID) but the recordsection is empty
        // Probably because they came from the advanced search or saved a bookmark or some other method other than the table
        // loadingRSFinished added originally for #1574 but broke navigation in certain circumstances
        if (!loadingRS /*&& !loadingRSFinished*/) {
            if (recordSectionID && !recordsection) {
                // Load the record section
                this.setState({ loadingRS: true });
                ormRecordSectionLoadDetailChild(recordSectionID, function (data) {
                    _this.setState({ loadingRS: false, loadingRSFinished: true });
                    if (data.length === 0) {
                        // Record does not exist - redirect to dashboard
                        history.push("/dashboard/" + page.id);
                    }
                });
            }
        }

        // Only do this for the fields view - not the table
        if (!loadingFields) {
            // and do it only once per recordsection
            if (recordsection && (!recordsection.has_children_loaded || from_mount)) {
                this.setState({ loadingFields: true });

                ormRecordFieldLoadDetailChild(recordSectionID, function () {
                    ormRecordSectionUpdateLocalOnly({
                        id: recordSectionID,
                        has_children_loaded: true,
                    });
                    _this.setState({ loadingFields: false });
                });
                if (section.has_attachments) ormAttachmentSectionLoadDetailChild(recordSectionID);
            }
        }
    };

    loadRepeatableTables = () => {
        const {
            ormRepeatableTableLoadDetailChild,
            ormRepeatableFieldLoadDetailChild, 
            forms,
            recordsection,
            recordSectionID,
            ormRecordSectionUpdateLocalOnly,
            ormRepeatableTableLoadMirrorRepeatable,
            ormRepeatableFieldLoadMirrorRepeatable,
            // parentRecordId
        } = this.props;
        const { loadedRecordTable, loadedMirrorFields } = this.state;

        if (recordsection) {
            //load repeatable tables and fields that have groups set
            let formID = recordsection.form.id;
            let thisForm = forms.find((f) => f.id === formID);
            var hasRepeatable = thisForm.groups.filter((f) => f.repeatable);
            var hasMirrorRepeatable = thisForm.groups.filter((f) => f.mirror_repeatabletable);
        }

        //load backend tables only if this is a repeatablesubform, this allows the store to be populated
        if (
            // section &&
            hasRepeatable &&
            recordsection /*&& repeatableTable.length === 0 */ &&
            (!recordsection.has_repeatable_loaded || !loadedRecordTable)
        ) {
            ormRepeatableTableLoadDetailChild(recordsection.id) 
            ormRepeatableFieldLoadDetailChild(recordsection.id);
           
            this.setState({ loadedRecordTable: true });
            ormRecordSectionUpdateLocalOnly({
                id: recordSectionID,
                has_repeatable_loaded: true,
            });

            if (hasMirrorRepeatable && hasMirrorRepeatable[0] && !loadedMirrorFields) {
                let section = hasMirrorRepeatable[0].mirror_repeatablesection;
                let groupids = hasMirrorRepeatable[0].mirror_repeatablegroup_ids;
                //We have a repeatabletable in this child section that is mirroring another repeatable table in another child so get its data
                this.setState({ loadedMirrorFields: true });
                ormRepeatableTableLoadMirrorRepeatable(parseInt(section), parseInt(recordsection._fields.parentrecord), groupids, "rt");
                ormRepeatableFieldLoadMirrorRepeatable(parseInt(section), parseInt(recordsection._fields.parentrecord), groupids, "rf");
            }
        }
    };

    componentDidMount() {
        this.loadRecordFields(true);
        this.loadRepeatableTables();
        const compo = this;
        setTimeout(function () {
            compo.setState({ unsavedFields: false });
        }, 1000);
    }

    componentWillUpdate(prevProps) {
        if (prevProps.recordSectionID !== this.props.recordSectionID) {
            this.formApi = {}; //update formAPi so dont carry indexs from other rs' cant put in componentdidupdate as the form is too late to update
            this.setState({ calculationInfo: {}, actCalcFields: [] });
        }
    }
    componentDidUpdate(prevProps) {
        this.loadRecordFields();
        this.loadRepeatableTables();

        const { recordfields, forms, recordSectionID } = this.props;
        const { touchedFields, calculationInfo, hideFields, hideDropDownFields, loadLogicFields } = this.state;

        //used to just updated daily feed only on rs changes
        if (prevProps.recordSectionID !== recordSectionID) {
            if (touchedFields.length === 0) {
                //need to reset logic (hidefields) seperately
                this.setState({ touchedFields: [], hideFields: [], hideDropDownFields: [], loadLogicFields: true, loadMirrorFields: true });
            }
        }
        if (prevProps.recordSectionID !== recordSectionID || (loadLogicFields && recordfields)) {
            //check for logic fields this occurs on section changes and refresh of page. refresh of page doesnt have recordfields in componentdidmount
            let hideFieldsFound = [];
            let hideDropFields = []
            recordfields.forEach((f) => {
                if ((f.field.type === "Yes/No" && f.field.fields_to_hide && f.userinput === "No") || ((f.field.type === 'Drop Down' || f.field.type === 'Multi Select') && f.field.hide_field && f.field.fields_to_hide)) {
                    let temp = f.field.fields_to_hide.split(",");
                    if (f.field.type === 'Yes/No'){
                        temp.forEach((v) => {
                        hideFieldsFound.push(v);
                        });
                    }else if(f.field.type === 'Drop Down' && f.field.hide_field){//dropdown hidefield logic for showing fields properly on a render 
                        let findKey;
                        let results = JSON.parse(f.field.calc_jsonfield)
                        for (const key in results){
                            if(key !== f.userinput){ //so we want the key if it dont match the dropdown
                                findKey = results[key];
                                break;
                            } 
                        }
                        if (findKey){ 
                            findKey.forEach(i=>{
                                let key = Object.keys(i)[0]
                                let val = Object.values(i)[0] //look for fields to hide based on unhide keyword
                                //now we will hide or not hide based on value of this dropdown
                                if (val === 'unhide'){
                                    let rf = recordfields.find(f=>f.field.id === parseInt(key)) //find the recordfield to hide
                                    hideDropFields.push(rf.field.name); 
                                } 
                            })

                            //ok check if total hidden is length of f.field.fields_to_hide. This can occur if we are using a hidden field that we want to hide that is interfaced with a calculated field that is matching two field values.
                            // if(f.field.fields_to_hide && f.field.fields_to_hide.split(',').length > 0 ){
                            //     f.field.fields_to_hide.split(',').forEach(v=>{
                            //         if(hideDropFields.find(f=>f!==v?f:null) || hideDropFields.length === 0){
                            //             hideDropFields.push(v)                                        
                            //         }
                            //     })
                            // }
                        } 
                    }else if(f.field.type === 'Multi Select' && f.field.hide_field){//multi hidefield logic for showing fields properly on a render 
                        let findKey;
                        let results = JSON.parse(f.field.calc_jsonfield)  
                            for (const key in results){
                                if(!f.userinput.includes(key)){ //so we want the key if it dont match the dropdown
                                    findKey = results[key];
                                    break;
                                }  
                            } 
                            if (findKey){
                                findKey.forEach(i=>{
                                    let key = Object.keys(i)[0]
                                    let val = Object.values(i)[0] //look for fields to hide
                                    //now we will hide or not hide based on value of this field
                                    if (val === 'unhide'){
                                        let rf = recordfields.find(f=>f.field.id === parseInt(key)) //find the recordfield to hide
                                        hideDropFields.push(rf.field.name);
                                    } 
                                }) 
                                
                          //ok check if total hidden is length of f.field.fields_to_hide. This can occur if we are using a hidden field that we want to hide that is interfaced with a calculated field that is matching two field values.
                            // if(f.field.fields_to_hide && f.field.fields_to_hide.split(',').length > 0 ){
                            //     f.field.fields_to_hide.split(',').forEach(v=>{
                            //         if(hideDropFields.find(f=>f!==v?f:null) || hideDropFields.length === 0){
                            //             hideDropFields.push(v)                                        
                            //         }
                            //     })
                            // }
                            }  
                    }
                }
            });
            if (hideFields.length === 0 && hideFieldsFound.length > 0)
                this.setState((prevState) => ({
                    hideFields: hideFieldsFound,
                    loadLogicFields: false,
                }));
            if (hideDropDownFields.length === 0 && hideDropFields.length > 0)
                this.setState((prevState) => ({
                    hideDropDownFields: hideDropFields,
                    loadLogicFields: false,
                }));
        }
        //need for routing from map/form for calc
        let c = recordfields.find((f) => {
            return f.field.type === "Calculated" ? f : null;
        }); //get calc fields
        if (JSON.stringify(calculationInfo) === "{}" && c && forms.length > 0 /* && recordfields.length > 0*/)
            setTimeout(() => {
                //need for updating calc fields in other sections to make sure recordfields are updated in store first
                this.checkForCalcFields();
            }, 1000);
    }
    shouldComponentUpdate(nextProps, nextState) {
        //will only not render when saving to prevent countless renders
        let shouldUpdate = !nextState.dontRender || nextProps.recordsection !== this.props.recordsection ? true : false;

        return shouldUpdate;
    }

    render() {
        const { classes, section, page, recordsection, authState, templates, permission } = this.props;
        const { save, loadingFields, loadingRS, deletingFields, isSuccess, missingCount, unsavedFields, attachment  } = this.state;

        if (!page) return "";
        // throw new Error("Invalid Page ID");

        if (authState.user) {
            var readOnly;
            var perm = permission.find((p) => {
                if (p.user === authState.user.id) {
                    return p;
                }
                return null;
            });
            if (perm) readOnly = perm.readonly || perm.readonlyexternal ? true : false;
            if (readOnly === undefined) readOnly = false;
        }

        return (
            <AppContainer authenticated>
                <Prompt
                    when={unsavedFields}
                    message="You have entered data that has not been saved in our system yet. Do you want to leave without finishing?"
                />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <TopButtons
                                perm={perm}
                                section={section}
                                page={page}
                                authState={authState}
                                readOnly={readOnly}
                                templates={templates}
                                recordsection={recordsection}
                                showPageName={true}
                            />

                            {section && <SectionTabHeader value={section.id} />}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ width: "99.5%" }}>
                    <Grid item xs={12}>
                        <Grid item xs={12} /*className={classes.formOutput}*/>
                            {(loadingFields || loadingRS) && (
                                <>
                                    <CircularProgress size={90} className={classes.progress} style={{ marginTop: 80 }} />
                                    <Typography variant="h6" style={{ textAlign: "center", zIndex: 3 }}>
                                        Loading... Please Wait
                                    </Typography>
                                </>
                            )}
                            {!loadingFields && !loadingRS && recordsection && this.formOutputGrid(readOnly)}
                            <Snackbar
                                snackbarOpen={save}
                                handleSnackbarClose={() => (
                                    //eslint-disable-next-line
                                    this.setState({ save: false}),
                                    //below is stupid but to prevent a quick message change in snackbar when it removes from screen
                                    setTimeout(()=>{
                                        this.setState({attachment:false})
                                    },200))
                                }  /*calculationInfo: {}, actCalcFields: []*/ //removed for logic calcs being cleared
                                 
                                isSuccess={isSuccess}
                                missing={missingCount}
                                // snackbarMessage="saved"
                                attachment={attachment}
                                advSearch={false}
                                operationType={attachment? 'attachment': 'record'}
                            />
                            {deletingFields && (
                                <>
                                    <CircularProgress size={90} className={classes.progress} />
                                    <Typography
                                        variant="h6"
                                        style={{ display: "inline-block", marginLeft: "40%", verticalAlign: "center", zIndex: 3 }}>
                                        Deleting Record... Please Wait
                                    </Typography>
                                </>
                            )}
                          
                        </Grid>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}
ExpansionPanelFormRecord.displayName = "ExpansionPanelFormRecord";

ExpansionPanelFormRecord = connect(
    (state, ownProps) => ({
        authState: state.auth,
        allUsers: allUsers(state),
        permission: getPermission(state, ownProps),
        page: getPage(state, ownProps),
        section: getSection(state, ownProps),
        allSections: getSections(state, ownProps),
        parentSection: getParentSection(state, ownProps),
        parentRecordId: findParentRecordId(state, ownProps),
        recordsection: getRecordSection(state, ownProps),
        attachments: getAttachments(state, ownProps),
        recordfields: getRecordField(state, ownProps),
        forms: getForms(state, ownProps),
        templates: getTemplates(state, ownProps),
        repeatableTable: getRepeatableTables(state, ownProps),
        mirrorRepeatableTable: getMirrorRepeatableTables(state, ownProps),
        recordSectionID: getRecordSectionID(state, ownProps),
        parentFields: getParentFields(state, ownProps),
        recordsectionfeeds: getRecordSectionFeeds(state, ownProps),
    }),
    {
        ...ParentRecord.actions,
        ...RecordField.actions,
        ...RecordSection.actions,
        ...AttachmentSection.actions,
        ...RepeatableTable.actions,
        ...RepeatableField.actions,
    }
)(ExpansionPanelFormRecord);

export default withStyles(styles)(withRouter(ExpansionPanelFormRecord));
