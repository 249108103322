import React from "react";
import { Form as Formm } from "react-form";
import classNames from "classnames";
import { loadModules } from "esri-loader";
import { arcgisToGeoJSON } from "@esri/arcgis-to-geojson-utils";

import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AddLocation from "@material-ui/icons/AddLocation";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import DeleteForever from "@material-ui/icons/DeleteForever";
import Cancel from "@material-ui/icons/Cancel";

import WarningDialog from "../../common/WarningDialog";
import TextField from "../../common/TextField";

const styles = (theme) => ({
    root: { zIndex: "999", width: "268px", backgroundColor: "#fff" },
    buttons: {
        width: 236,
        margin: "0px 16px 16px 16px",
        height: 54,
    },
    blueColor: {
        backgroundColor: "#3c8ac7",
    },
    whiteText: {
        color: "white",
    },
    rightMargin: {
        marginRight: "0px !important",
    },
    deleteToggled: {
        border: "2px solid red",
        backgroundColor: "#005d96",
    },
});

class MapEditing extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            drawActive: false, // Used to prevent map interaction on top of the expansion panels
            latLong: false, // Whether the lat/lon form is opened
            editing: false, // If we are performing an edit
            editToggled: false, // Whether the edit button is active or not
            drawingFeature: false, // Whether the draw button is active or not
            deleteAllDialog: false, // The delete all geometries dialog window
            deleteToggled: false, // Whether the delete single geometry button is active or not
        };

        var _this = this;
        // Add a new drawing layer for each section
        props.page.sections
            .filter((s) => s.has_geometry && s.id === _this.props.recordsection.section.id)
            .forEach(function (s, i) {
                _this.featureGroups = new _this.props.GraphicsLayer();

                loadModules(["esri/widgets/Sketch/SketchViewModel"], { css: true }).then(([SketchViewModel]) => {
                    _this.sketchVM = new SketchViewModel({
                        layer: _this.featureGroups,
                        view: _this.props.view,
                        updateOnGraphicClick: false,
                        // updateSymbol when the edit is active
                        updatePolygonSymbol: {
                            type: "simple-fill",
                            style: "solid",
                            color: "rgba(255, 0, 0, 0.2)",
                            outline: {
                                style: "dash",
                                color: "rgba(255, 0, 0, 1)",
                                width: 1,
                            },
                        },
                        updatePointSymbol: {
                            type: "simple-marker",
                            color: "red",
                            width: 20,
                            outline: {
                                color: [255, 255, 255],
                                width: 2,
                            },
                        },
                        updatePolylineSymbol: {
                            type: "simple-line",
                            color: "red",
                            width: 5,
                            cap: "round",
                            join: "round",
                        },
                        // symbols are when drawing
                        polygonSymbol: {
                            type: "simple-fill",
                            style: "solid",
                            color: "rgba(0, 0, 255, 0.2)",
                            outline: {
                                color: "rgba(0, 0, 255, 1)",
                                width: 1,
                            },
                        },
                        pointSymbol: {
                            type: "simple-marker",
                            color: "blue",
                            width: 20,
                            outline: {
                                color: [255, 255, 255],
                                width: 2,
                            },
                        },
                        polylineSymbol: {
                            type: "simple-line",
                            color: "blue",
                            width: 5,
                            cap: "round",
                            join: "round",
                        },
                    });

                    // Save the drawing while creating
                    _this.sketchVM.on("create", function (event) {
                        if (event.state === "complete") {
                            _this.setState({ drawingFeature: false });
                            var geom, geojson, converted_geom;
                            let hasDefaultService = _this.props.sideform.props.recordfields.find(rf=>rf.field.default_value_service) //used below to only recall recordfields if true in update
                            var rS = _this.props.recordsection;
                            if (i === 0 || !rS.geometry) {
                                // Parent creation or updating child
                                geom = event.graphic.geometry;

                                var rs_val;
                                rS.columns.forEach(function (col) {
                                    if (col.is_id) rs_val = col.value;
                                });
                                event.graphic.attributes = {
                                    id: rS.id,
                                    value: rs_val,
                                    section_name: rS.section.name,
                                };

                                // Children will always be a single graphic - this is much easier
                                // Just convert to 4326 (latlon) and then convert to geojson
                                if (i !== 0 || _this.featureGroups.graphics.items.length < 2) {
                                    converted_geom = _this.props.webMercatorUtils.webMercatorToGeographic(geom);
                                    geojson = arcgisToGeoJSON(converted_geom);
                                } else {
                                    // Support multi-shapes for parents
                                    // esri doesn't have multilines/multipolygon types so this is knida hacky
                                    converted_geom = [];
                                    var type;
                                    // First get a array of latlong geometries in geojson
                                    _this.featureGroups.graphics.items.forEach(function (graph) {
                                        type = graph.geometry.type; // know what type we are using
                                        converted_geom.push(arcgisToGeoJSON(_this.props.webMercatorUtils.webMercatorToGeographic(graph.geometry)));
                                    });
                                    if (type === "polygon") {
                                        type = "MultiPolygon";
                                    } else if (type === "polyline") {
                                        type = "MultiLineString";
                                    } else {
                                        type = "MultiPoint";
                                    }
                                    geojson = {
                                        type: type,
                                        coordinates: converted_geom.map((cg) => cg.coordinates),
                                    };
                                }
                                
                                //update geo and refresh fields IF there is a default_value_service property being set
                                _this.props.ormRecordSectionMapUpdate({
                                    id: rS.id,
                                    geometry: geojson,
                                }).then(()=>{
                                    if(hasDefaultService){
                                        let this_ = _this;
                                        this_.props.sideform.setState({loadingFields:true})
                                        this_.props.ormRecordFieldLoadDetailChild(rS.id, ()=> {
                                            this_.props.ormRecordSectionUpdateLocalOnly({
                                                id: rS.id,
                                                has_children_loaded: true
                                            });
                                            this_.props.sideform.setState({loadingFields:false})
                                        });
                                    }
    
                                })
                             
                            }
                        }
                    });

                    // Saving the drawing edit
                    // This has to be done here instead of directly in the save button because clicking outside of a graphic acts like a save
                    _this.sketchVM.on("update", function (event) {
                        if (event.state === "complete" && !event.aborted) {
                            _this.setState({ editing: false });
                            var geom, converted_geom, geojson, type;
                            let hasDefaultService = _this.props.sideform.props.recordfields.find(rf=>rf.field.default_value_service) //used below to only recall recordfields if true in update

                            if (i !== 0 || _this.featureGroups.graphics.items.length < 2) {
                                geom = event.graphics[0].geometry;
                                converted_geom = _this.props.webMercatorUtils.webMercatorToGeographic(geom);
                                geojson = arcgisToGeoJSON(converted_geom);
                            } else {
                                converted_geom = [];
                                // First get a array of latlong geometries in geojson
                                _this.featureGroups.graphics.items.forEach(function (graph) {
                                    type = graph.geometry.type; // know what type we are using
                                    converted_geom.push(arcgisToGeoJSON(_this.props.webMercatorUtils.webMercatorToGeographic(graph.geometry)));
                                });
                                if (type === "polygon") {
                                    type = "MultiPolygon";
                                } else if (type === "polyline") {
                                    type = "MultiLineString";
                                } else {
                                    type = "MultiPoint";
                                }
                                geojson = {
                                    type: type,
                                    coordinates: converted_geom.map((cg) => cg.coordinates),
                                };
                            }
                            
                            //we dont update the default_value_service fields here (i.e. as in the create above) as users want to be able to override them supposedly in 
                            //modules.py/recordsection/save method so I wont handle updating
                            _this.props.ormRecordSectionMapUpdate({
                                id: event.graphics[0].attributes.id,
                                geometry: geojson,
                                //has_children_loaded: false
                            }).then(() => {
                                if(hasDefaultService){
                                    let this_ = _this;
                                    this_.props.sideform.setState({loadingFields:true})
                                    this_.props.ormRecordFieldLoadDetailChild(event.graphics[0].attributes.id, ()=> {
                                        this_.props.ormRecordSectionUpdateLocalOnly({
                                            id: event.graphics[0].attributes.id,
                                            has_children_loaded: true
                                        });
                                        this_.props.sideform.setState({loadingFields:false})
                                    });
                                }
                            });
                        }
                    });
                });
            });
    }

    // Update border to red when deleting
    colorSectionsIconsForDelete = (active) => {
        const { view, _getPolygonGrpahic, _getLineGrpahic, _getPointGrapicWeb, Graphic } = this.props;

        const _this = this;
        var graphicsCopy = [...this.featureGroups.graphics.items]; // Copy the array of graphics because removing them from the original screws up the array
        graphicsCopy.forEach(function (graphic) {
            // Why TF do you have to remove and add a new graphic just to update the color
            _this.featureGroups.remove(graphic);
            var temp;
            if (graphic.geometry.type === "polygon") {
                temp = _getPolygonGrpahic(active ? "red" : "blue", graphic.geometry.rings, view, Graphic, graphic.attributes);
            } else if (graphic.geometry.type === "polyline") {
                temp = _getLineGrpahic(active ? "red" : "blue", graphic.geometry.paths, view, Graphic, graphic.attributes);
            } else {
                temp = _getPointGrapicWeb(active ? "red" : "blue", graphic.geometry.x, graphic.geometry.y, view, Graphic, graphic.attributes);
            }
            _this.featureGroups.add(temp);
        });
    };

    // create marker with lat/log coordinates from user
    // Ex: 43.647752,-94.079618
    latCreateMarker = (event) => {
        const { recordsection, ormRecordSectionUpdate, webMercatorUtils, _getPointGrapicWeb, Graphic, view } = this.props;

        var coord = event.lat
            .split(/\s/) //remove whitespace
            .join("") //join string back together
            .split(",") //seperate commas
            .reverse(); //swap long/lat

        var rs_val;
        recordsection.columns.forEach(function (col) {
            if (col.is_id) rs_val = col.value;
        });

        var coordxy = webMercatorUtils.lngLatToXY(coord[0], coord[1]);
        var temp = _getPointGrapicWeb("blue", coordxy[0], coordxy[1], view, Graphic, {
            id: recordsection.id,
            section_name: recordsection.section.name,
            value: rs_val,
        });

        if (this.featureGroups.graphics.items.length === 0) {
            ormRecordSectionUpdate({
                id: recordsection.id,
                geometry: {
                    type: "Point",
                    coordinates: [parseFloat(coord[0]), parseFloat(coord[1])],
                },
                //has_children_loaded: false
            });
            this.featureGroups.add(temp);
        } else {
            this.featureGroups.add(temp);
            var converted_geom = [];
            this.featureGroups.graphics.items.forEach(function (graph) {
                converted_geom.push(arcgisToGeoJSON(webMercatorUtils.webMercatorToGeographic(graph.geometry)));
            });
            ormRecordSectionUpdate({
                id: recordsection.id,
                geometry: {
                    type: "MultiPoint",
                    coordinates: converted_geom.map((cg) => cg.coordinates),
                },
                //has_children_loaded: false
            });
        }

        view.when(() => {
            view.goTo(this.featureGroups.graphics.items);
        });
    };

    componentDidUpdate(prevProps) {
        const { recordsection } = this.props;

        if (prevProps.recordsection.id !== recordsection.id) {
            this.featureGroups.removeAll();
            this.populateFeatures();
        }
    }

    componentDidMount() {
        const { map, view } = this.props;
        const _this = this;

        map.add(this.featureGroups);

        this.populateFeatures();

        // Click Event
        view.on("click", function (event) {
            view.hitTest(event).then(function (response) {
                response.results.forEach((x) => {
                    // Can't do anything without id attributes - This should rarely if ever happen on drawing graphics layer
                    // It's probably a reference layer
                    if (!x.graphic.attributes || !x.graphic.attributes["id"] || _this.state.editing) return;

                    // We are currently deleting - Remove geometry from orm and graphic from layer
                    if (_this.state.deleteToggled) {
                        // Check we are deleting geometry only colored red (that should be the only layer allowed to be deleted; kinda hacky)
                        if (x.graphic.symbol.color.r === 255) {
                            if (x.graphic.layer.graphics.items.length <= 1) {
                                // If there's only 1 geometry left just delete it
                                _this.props.ormRecordSectionUpdate({
                                    id: x.graphic.attributes["id"],
                                    geometry: null,
                                });
                                x.graphic.layer.remove(x.graphic);
                                _this.setState({ deleteToggled: false });
                                _this.colorSectionsIconsForDelete(false);
                                return; // Don't do anything else
                            } else {
                                // Parent Layer
                                var id = x.graphic.attributes["id"];
                                var geometry = null;

                                var converted_geom = [];
                                var type;
                                // Check for other graphics in the layer and use them to update geojson if present
                                x.graphic.layer.graphics.items.forEach(function (graph) {
                                    // Don't add the graphic we just deleted
                                    // x.graphic.layer is nulll if we remove it above
                                    if (graph !== x.graphic) {
                                        type = graph.geometry.type; // know what type we are using
                                        converted_geom.push(arcgisToGeoJSON(_this.props.webMercatorUtils.webMercatorToGeographic(graph.geometry)));
                                    }
                                });
                                if (type === "polygon") {
                                    type = "MultiPolygon";
                                } else if (type === "polyline") {
                                    type = "MultiLineString";
                                } else {
                                    type = "MultiPoint";
                                }
                                geometry = {
                                    type: type,
                                    coordinates: converted_geom.map((cg) => cg.coordinates),
                                };
                                _this.props.ormRecordSectionUpdate({
                                    id: id,
                                    geometry: geometry,
                                    //has_children_loaded: false
                                });
                                x.graphic.layer.remove(x.graphic);
                                return; // Don't do anything else
                            }
                        }
                    }

                    // Check we are working with colored blue (active layer; kinda hacky)
                    if (x.graphic.symbol.color.b === 255) {
                        // We are currently editing -
                        if (_this.state.editToggled) {
                            _this.setState({ editing: true });

                            // The default tool for polygons/lines is called "transform" but we don't really want that one
                            var tool = "move";
                            if (x.graphic.geometry.type !== "point") tool = "reshape";
                            _this.sketchVM.update([x.graphic], {
                                enableScaling: false,
                                tool: tool,
                            });
                        }
                    }
                });
            });
        });
    }

    populateFeatures = () => {
        const { recordsection, page, webMercatorUtils, Graphic, _getPolygonGrpahic, _getLineGrpahic, _getPointGrapicWeb, parentrecord, view } =
            this.props;
        const _this = this;

        // Populate Features
        if (!parentrecord) return;

        parentrecord.forEach(function (rS) {
            if (rS.geometry && rS.id === recordsection.id) {
                var sectionName = page.sections.find((s) => s.id === rS.section).name;
                var geometry = rS.geometry;
                var coord;
                var temp;
                var rs_val;
                var coords = [];
                rS.columns.forEach(function (col) {
                    if (col.is_id) rs_val = col.value;
                });
                if (geometry.type === "Polygon") {
                    geometry.coordinates[0].forEach((x) => {
                        coords.push(webMercatorUtils.lngLatToXY(x[0], x[1]));
                    });
                    temp = _getPolygonGrpahic("blue", coords, view, Graphic, { id: rS.id, section_name: sectionName, value: rs_val });
                    _this.featureGroups.add(temp);
                } else if (geometry.type === "MultiPolygon") {
                    geometry.coordinates.forEach(function (c) {
                        // for each polygon
                        coords = [];
                        c[0].forEach((x) => {
                            coords.push(webMercatorUtils.lngLatToXY(x[0], x[1]));
                        });
                        temp = _getPolygonGrpahic("blue", coords, view, Graphic, { id: rS.id, section_name: sectionName, value: rs_val });
                        _this.featureGroups.add(temp);
                    });
                } else if (geometry.type === "LineString") {
                    geometry.coordinates.forEach((x) => {
                        coords.push(webMercatorUtils.lngLatToXY(x[0], x[1]));
                    });
                    temp = _getLineGrpahic("blue", coords, view, Graphic, { id: rS.id, section_name: sectionName, value: rs_val });
                    _this.featureGroups.add(temp);
                } else if (geometry.type === "MultiLineString") {
                    geometry.coordinates.forEach((x) => {
                        // for each line
                        coords = [];
                        x.forEach((l) => {
                            coords.push(webMercatorUtils.lngLatToXY(l[0], l[1]));
                        });
                        temp = _getLineGrpahic("blue", coords, view, Graphic, { id: rS.id, section_name: sectionName, value: rs_val });
                        _this.featureGroups.add(temp);
                    });
                } else if (geometry.type === "MultiPoint") {
                    geometry.coordinates.forEach(function (c) {
                        coord = webMercatorUtils.lngLatToXY(c[0], c[1]);
                        temp = _getPointGrapicWeb("blue", coord[0], coord[1], view, Graphic, { id: rS.id, section_name: sectionName, value: rs_val });
                        _this.featureGroups.add(temp);
                    });
                } else {
                    coord = webMercatorUtils.lngLatToXY(geometry.coordinates[0], geometry.coordinates[1]);
                    temp = _getPointGrapicWeb(rS.section === recordsection.section.id ? "blue" : "gray", coord[0], coord[1], view, Graphic, {
                        id: rS.id,
                        section_name: sectionName,
                        value: rs_val,
                    });
                    _this.featureGroups.add(temp);
                }

                // If its multitype zoom to the entire layer
                if (geometry.type === "MultiPolygon" || geometry.type === "MultiLineString" || geometry.type === "MultiPoint") {
                    view.when(() => {
                        view.goTo(_this.featureGroups.graphics.items);
                    })
                }
                view.when(() => {
                    view.goTo({
                        target: temp, 
                        zoom: 14
                    });
                })

                _this.setState({ foo: "bar" }); // needs to re-render expansion panel after loading geom
            }
        });
    };

    _onDeleted = () => {
        const { ormRecordSectionUpdate } = this.props;

        if (this.featureGroups) {
            this.featureGroups.graphics.items.forEach(function (g) {
                ormRecordSectionUpdate({
                    id: g.attributes.id,
                    geometry: null,
                });
            });
            this.featureGroups.removeAll();
        }
    };

    render() {
        const { classes, page, readOnly, recordsection, view, sideform } = this.props;
        const { latLong, editToggled, drawingFeature, deleteAllDialog, deleteToggled } = this.state;

        return page.sections
            .filter((s) => s.has_geometry && s.id === recordsection.section.id)
            .map((n, index) => {
                return (
                    <Paper>
                        <Accordion className={classes.root} expanded={true}>
                            <AccordionSummary className={classNames(classes.rightMargin, classes.blueColor)}>
                                <Typography variant="subtitle1" className={classes.whiteText}>
                                    Mapping Tools {n.geometry_type && "(" + n.geometry_type + ")"}
                                </Typography>
                            </AccordionSummary>
                            {latLong && (
                                <Formm
                                    dontValidateOnMount={true}
                                    validateOnSubmit={true}
                                    onSubmit={(values) => {
                                        this.setState({ latLong: false });
                                        this.latCreateMarker(values);
                                    }}>
                                    {(formApi) => (
                                        <form onSubmit={formApi.submitForm}>
                                            <Grid container spacing={3} style={{ padding: "8px 16px 16px" }}>
                                                <Grid item xs={12}>
                                                    <TextField field="lat" label="Latitude/Longitude" fullWidth placeholder="Ex: 43.647,-94.079" />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button type="submit" variant="contained" color="primary" fullWidth>
                                                        Create Coord Marker
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        onClick={() => {
                                                            this.setState({ latLong: false });
                                                        }}>
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    )}
                                </Formm>
                            )}
                            {!latLong && (
                                <Grid container>
                                    <Grid item xs={12} style={{ marginTop: 12 }}>
                                        <Tooltip title="Add new map geometry by clicking on the map. To add multiple map geometries, click the Draw New Geometry button for each new geometry.">
                                            <div>
                                                <Button
                                                    disabled={
                                                        readOnly ||
                                                        editToggled ||
                                                        deleteToggled ||
                                                        (n.geometry_type === "Point" && this.featureGroups.graphics.length > 0)
                                                    }
                                                    variant="contained"
                                                    color="primary"
                                                    className={classNames(classes.buttons, drawingFeature && classes.deleteToggled)}
                                                    onClick={() => {
                                                        if (drawingFeature) {
                                                            this.setState({ formDialogStatus: false, drawingFeature: false });
                                                            this.sketchVM.cancel();
                                                        } else {
                                                            this.setState({
                                                                formDialogStatus: true,
                                                                drawingFeature: true,
                                                                editToggled: false,
                                                                deleteToggled: false,
                                                            });
                                                            if (!n.geometry_type || n.geometry_type === "Point" || n.geometry_type === "MultiPoint")
                                                                this.sketchVM.create("point");
                                                            else if (n.geometry_type === "Line") this.sketchVM.create("polyline");
                                                            else if (n.geometry_type === "Polygon") this.sketchVM.create("polygon");
                                                            if (sideform) {
                                                                sideform.formApi.submitForm();
                                                            }
                                                        }
                                                    }}>
                                                    <Typography variant="subtitle1">
                                                        {drawingFeature
                                                            ? "Stop Drawing"
                                                            : "Draw New " + (n.geometry_type ? n.geometry_type : "Geometry")}
                                                    </Typography>
                                                    <AddCircleOutline style={{ marginLeft: 20 }} />
                                                </Button>
                                            </div>
                                        </Tooltip>
                                        {(!n.geometry_type || n.geometry_type === "Point" || n.geometry_type === "MultiPoint") && (
                                            <Tooltip title="Add a new point location by entering latitude and longitude coordinates.">
                                                <div>
                                                    <Button
                                                        disabled={
                                                            readOnly ||
                                                            editToggled ||
                                                            deleteToggled ||
                                                            drawingFeature ||
                                                            (n.geometry_type === "Point" && this.featureGroups.graphics.length > 0)
                                                        }
                                                        variant="contained"
                                                        color="primary"
                                                        className={classes.buttons}
                                                        onClick={() => {
                                                            this.setState({ latLong: true });
                                                            if (sideform) {
                                                                sideform.formApi.submitForm();
                                                            }
                                                        }}>
                                                        <Typography disabled={readOnly} variant="subtitle1">
                                                            Add Point by Lat/Long Values
                                                        </Typography>
                                                        <AddLocation style={{ marginLeft: 20 }} />
                                                    </Button>
                                                </div>
                                            </Tooltip>
                                        )}
                                        {!this.state.editing && (
                                            <Tooltip
                                                title={
                                                    this.featureGroups.graphics.length === 0
                                                        ? "The button isn’t available if no geometries have been defined for the record."
                                                        : "Click the Edit Geometry button to start edit. Select the geometry feature to edit and it will turn red when active. Click the point of vertices to move them. After editing click the Save Edit button to save the new geometries. After finishing map geometry edits, click the Stop Editing or Cancel Edit button."
                                                }>
                                                <div>
                                                    <Button
                                                        disabled={
                                                            readOnly || this.featureGroups.graphics.length === 0 || deleteToggled || drawingFeature
                                                        }
                                                        variant="contained"
                                                        color="primary"
                                                        className={classNames(classes.buttons, editToggled && classes.deleteToggled)}
                                                        onClick={() => {
                                                            if (editToggled) {
                                                                view.popup.autoOpenEnabled = true;
                                                            } else {
                                                                view.popup.autoOpenEnabled = false;
                                                            }
                                                            this.setState({ editToggled: !editToggled, drawingFeature: false, deleteToggled: false });
                                                        }}>
                                                        <Typography variant="subtitle1">{editToggled ? "Stop Editing" : "Edit Geometry"}</Typography>
                                                        <i className="material-icons" style={{ marginLeft: 20 }}>
                                                            edit
                                                        </i>
                                                    </Button>
                                                </div>
                                            </Tooltip>
                                        )}
                                        {this.state.editing && (
                                            <div>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.buttons}
                                                    onClick={() => {
                                                        this.setState({ editing: false });
                                                        if (this.sketchVM._operationHandle) {
                                                            while (this.sketchVM._operationHandle.canUndo())
                                                                // Why is there no undo all?
                                                                this.sketchVM.undo();
                                                        }
                                                        this.sketchVM.cancel();
                                                    }}>
                                                    <Typography variant="subtitle1">Cancel Edit</Typography>
                                                    <Cancel style={{ marginLeft: 20 }} />
                                                </Button>
                                                <Button
                                                    disabled={readOnly}
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.buttons}
                                                    onClick={() => {
                                                        this.sketchVM.complete();
                                                    }}>
                                                    <Typography variant="subtitle1">Save Edit</Typography>
                                                    <i class="material-icons" style={{ marginLeft: "20px" }}>
                                                        save
                                                    </i>
                                                </Button>
                                            </div>
                                        )}
                                        <Tooltip
                                            title={
                                                this.featureGroups.graphics.length === 0
                                                    ? "After clicking Deleting Geometry, click the mapped geometries shown on the map to delete them. When completed deleting geometries, click the Stop Deleting button. The button isn’t available if no geometries have been defined for the record."
                                                    : "After clicking Deleting Geometry, click the mapped geometries shown on the map to delete them. When completed deleting geometries, click the Stop Deleting button."
                                            }>
                                            <div>
                                                <Button
                                                    disabled={readOnly || this.featureGroups.graphics.length === 0 || editToggled || drawingFeature}
                                                    variant="contained"
                                                    color="primary"
                                                    className={classNames(classes.buttons, deleteToggled && classes.deleteToggled)}
                                                    onClick={(e) => {
                                                        if (deleteToggled) {
                                                            view.popup.autoOpenEnabled = true;
                                                        } else {
                                                            view.popup.autoOpenEnabled = false;
                                                        }
                                                        this.setState({ editToggled: false, deleteToggled: !deleteToggled, drawingFeature: false });
                                                        // Set the border of the active layer
                                                        this.colorSectionsIconsForDelete(!deleteToggled);
                                                    }}>
                                                    <Typography variant="subtitle1">{deleteToggled ? "Stop Deleting" : "Delete Geometry"}</Typography>
                                                    <i className="material-icons" style={{ marginLeft: "20px" }}>
                                                        delete
                                                    </i>
                                                </Button>
                                            </div>
                                        </Tooltip>
                                        <Tooltip
                                            title={
                                                this.featureGroups.graphics.length === 0
                                                    ? "This button clears all mapped geometries of the selected record. The button isn’t available if no geometries have been defined for the record."
                                                    : "This button clears all mapped geometries of the selected record."
                                            }>
                                            <div>
                                                <Button
                                                    disabled={
                                                        readOnly ||
                                                        this.featureGroups.graphics.length === 0 ||
                                                        editToggled ||
                                                        deleteToggled ||
                                                        drawingFeature
                                                    }
                                                    variant="contained"
                                                    color="primary"
                                                    className={classNames(classes.buttons)}
                                                    onClick={(e) => {
                                                        this.setState({ deleteAllDialog: true });
                                                    }}>
                                                    <Typography variant="subtitle1">Delete All Geometries</Typography>
                                                    <DeleteForever style={{ marginLeft: 20 }} />
                                                </Button>
                                            </div>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            )}
                        </Accordion>

                        <WarningDialog
                            confirmAction={() => {
                                this._onDeleted();
                                this.setState({ deleteAllDialog: false });
                            }}
                            cancelAction={() => this.setState({ deleteAllDialog: false })}
                            open={deleteAllDialog}
                            title="Delete All Geometries?"
                            confirmText="Proceed"
                            text="This will remove all geometry features for this record. Do you wish to proceed?"
                        />
                    </Paper>
                );
            });
    }
}
MapEditing.displayName = "MapEditing";

export default withStyles(styles)(MapEditing);
