import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Close from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Form as Formm } from "react-form";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import TimelineIcon from "@material-ui/icons/Timeline";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import drag from "../../common/icons/dnd.png";
import Checkbox from "../../common/Checkbox";
import WarningDialog from "../../common/WarningDialog";
import ColorPicker from "../../common/ColorPicker";
import { createSelector } from "../../common/orm";
import { RecordSection, PageAnalytics } from "../models";
import ReactSelect from "../../common/ReactSelect";
import HelpLabel from "../../common/HelpLabel";
import Select from "../../common/Select";
import TextField from "../../common/TextField";
import Switch from "../../common/Switch";

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: "auto",
    },
    root1: {
        width: "100%",
        overflowX: "auto",
        backgroundColor: "#f0f0f0",
        minWidth: 596,
    },
    scroller: {
        width: "100%",
        overflowX: "hidden",
    },
    fullWidth: {
        flexGrow: 1,
        maxWidth: "none",
        flexBasis: 0,
        flexShrink: 1,
    },

    dialogMin: {
        minWidth: 600,
        minHeight: 650,
        padding: 0,
    },
    flex: {
        flex: 1,
    },
    maxHeight: {
        minWidth: 678,
        minHeight: 760,
    },
    table: {
        minWidth: 500,
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        margin: "0 8px 0 0",
    },
    centerAlign: {
        textAlign: "center",
    },
    minWidth2: {
        minWidth: 605,
        minheight: 685,
    },
});
const getGeometry = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, pageID) => {
        return session.Section.filter((sect) => sect.page === pageID && sect.has_geometry).count() > 0;
    }
);

const getPageAnalytics = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["advID"]),
    (session, advID) => {
        return session.AdvancedSearch.filter((a) => a.id === advID)
            .toModelArray()
            .map((advancedsearch) => ({
                pageanalytics: advancedsearch.pageanalytics.all().orderBy("order").toRefArray(),
                ...advancedsearch.ref,
            }));
    }
);

const getAllContactFields = createSelector((schema) => {
    return schema.ContactField.all().orderBy("order").toModelArray();
});

//fields styling section for groups
const getItemStyle2 = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 1,
    margin: `6px 14px 6px 14px`,
    color: "primary",
    fontSize: "18px",
    borderRadius: 5,
    textAlign: "left",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 2px, rgba(0, 0, 0, 0.25) 0px 2px 2px",

    // change background of fields when dragging
    background: isDragging ? "lightgrey" : "lightgrey",

    // styles we need to apply on draggables
    ...draggableStyle,
});

// background color on "fields child card" section when dragging
const getListStyle2 = (isDraggingOver) => ({
    background: isDraggingOver ? "#4e4e4e" : "",
    borderRadius: 5,
    marginBottom: isDraggingOver ? 80 : 30,
});

class AdvancedSearchSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: 0,
            modifiedFields: null,
            open_analytic: false,
            analyticToEdit: null,
            analyticFields: null,
            analyticFields2: null,
            chartOptions: false,
            chart_Type: "",
            statType: ["Count", "Average", "Sum", "Min", "Max"],
            key: window.performance.now(),
            fieldType: null,
            deleteDialogOpen: false,
            analyticToDelete: null,
            showCards: false,
        };
    }

    saveSettings = (e) => {
        const {
            ormAdvancedGroupCreate,
            ormAdvancedGroupUpdate,
            ormAdvancedTableFieldsCreate,
            ormAdvancedTableFieldsDelete,
            ormAdvancedTableFieldsUpdate,
            ormAdvancedSearchUpdate,
            advancedsearchID,
            advancedtablefields,
            authState,
            advancedgroups,
            page,
            history,
        } = this.props;

        const group = advancedgroups.find((g) => {
            return g.name === e.group_name;
        });

        if (e.geo_color)
            for (const i in e) {
                if (i === "geo_color") {
                    ormAdvancedSearchUpdate({
                        id: advancedsearchID[0].id,
                        geo_color: e[i],
                    });
                }
            }

        // all fields for statistics all are deleted so clear all 'selection is empty'
        if (e.columnField === "") {
            if (advancedtablefields.length > 0) {
                advancedtablefields.forEach((f) => {
                    ormAdvancedTableFieldsDelete(f.id);
                });
                this.props.handleDialog(); //close dialog
            }
        }
        //all columns to show on the table allows deletion and addition of columns
        if (e.columnField) {
            //get all field ids and put into one array split by commas
            let cols = e.columnField.split(",");

            //delete from DB if not selected anymore, search current db values
            if (advancedtablefields.length > 0) {
                advancedtablefields.forEach((f) => {
                    var found = cols.find((field) => {
                        return parseInt(field) === f.field || parseInt(field.replace("c_", "")) === f.contact_field;
                    });
                    if (!found) {
                        ormAdvancedTableFieldsDelete(f.id);
                    }
                });

                this.props.handleDialog(); //close dialog
            }

            //remove stat fields from cols as we dont want to double keys in db
            if (e.statisticField) var stat = e.statisticField.split(",");
            if (stat) {
                for (var i = 0; i < cols.length; i++) {
                    for (var s = 0; s < stat.length; s++) {
                        if (stat[s] === cols[i]) {
                            cols.splice(cols.indexOf(stat[s]), 1);
                        }
                    }
                }
            }

            //create a record in DB if its not there
            cols.forEach((fieldID, idx) => {
                if (fieldID.includes("c_")) {
                    var contactField = fieldID.replace("c_", "");
                    let found = advancedtablefields.find((f) => {
                        return f.contact_field === parseInt(contactField);
                    });
                    //if not in database create else skip
                    if (!found) {
                        ormAdvancedTableFieldsCreate({
                            advancedsearch_id: advancedsearchID[0].id,
                            contact_field_id: parseInt(contactField),
                            order: idx,
                        });
                    } else {
                        //we update 'ordering' again to match current layout
                        ormAdvancedTableFieldsUpdate({
                            id: found.id,
                            contact_field_id: parseInt(contactField),
                            order: idx,
                        });
                    }
                } else {
                    let found = advancedtablefields.find((f) => {
                        return f.field === parseInt(fieldID);
                    });
                    //if not in database create else skip
                    if (!found) {
                        ormAdvancedTableFieldsCreate({
                            advancedsearch_id: advancedsearchID[0].id,
                            field_id: parseInt(fieldID),
                            order: idx,
                        });
                    } else {
                        //we update 'ordering' again to match current layout
                        ormAdvancedTableFieldsUpdate({
                            id: found.id,
                            field_id: parseInt(fieldID),
                            order: idx,
                        });
                    }
                }
            });
        }

        // fields for statistics all are deleted so clear all 'selection is empty'
        if (e.statisticField === "") {
            if (advancedtablefields.length > 0) {
                advancedtablefields.forEach((f) => {
                    ormAdvancedTableFieldsUpdate({ id: f.id, statistics: false });
                });
                this.props.handleDialog(); //close dialog
            }
        }

        // will be hit for >= 1 field for statistics
        if (e.statisticField) {
            let stat = e.statisticField.split(",");

            //delete from DB if not selected anymore, search current db values
            if (advancedtablefields.length > 0) {
                advancedtablefields.forEach((f) => {
                    let found = stat.find((field) => {
                        return parseInt(field) === f.field;
                    });
                    if (!found) {
                        ormAdvancedTableFieldsUpdate({ id: f.id, statistics: false });
                    }
                });
                this.props.handleDialog(); //close dialog
            }
            //create a record in DB if its not there
            stat.forEach((fieldID) => {
                let found = advancedtablefields.find((f) => {
                    return f.field === parseInt(fieldID);
                });

                //if not in database create else update
                if (!found) {
                    ormAdvancedTableFieldsCreate({
                        advancedsearch_id: advancedsearchID[0].id,
                        field_id: fieldID,
                        statistics: true,
                        order: advancedtablefields.length,
                    });
                } else if (!found.statistics)
                    ormAdvancedTableFieldsUpdate({ advancedsearch_id: advancedsearchID[0].id, id: found.id, field_id: fieldID, statistics: true });
            });
        }

        //permissions
        if (e.g_name) {
            // new group name added and assigned to this search
            ormAdvancedGroupCreate({
                page: page[0].id,
                name: e.g_name,
                expansion_color: e.expansion_color,
                expansion_font_color: e.expansion_font_color,
            }).then((g) => {
                //group already created
                ormAdvancedSearchUpdate({
                    id: advancedsearchID[0].id,
                    advancedgroup: g.id,
                    accessby: e.accessby,
                    accesscreatorid: e.accessby !== 'All Including External Read Only Users' ? authState.user.id : null,
                    editableby: e.editableby,
                    editcreatorid: e.editableby !== 'All Except External Read Only Users' ? authState.user.id : null,
                    dashboard: e.dashboard,
                    geometriesonmap: e.geometriesonmap,
                    showlatloncolumns:e.showlatloncolumns
                });
                this.props.handleDialog();
            });
        } else if (group) {
            //group already created just update params
            ormAdvancedGroupUpdate({
                id: group.id,
                expansion_color: e.expansion_color,
                expansion_font_color: e.expansion_font_color,
            });
            ormAdvancedSearchUpdate({
                id: advancedsearchID[0].id,
                advancedgroup: group.id,
                accessby: e.accessby,
                accesscreatorid: e.accessby !== 'All Including External Read Only Users' ? authState.user.id : null,
                editableby: e.editableby,
                editcreatorid:e.editableby !== 'All Except External Read Only Users' ? authState.user.id : null,
                dashboard: e.dashboard,
                geometriesonmap: e.geometriesonmap,
                showlatloncolumns: e.showlatloncolumns
            });

            this.props.handleDialog();

            history.push("/page/" + page[0].id + "/advancedsearch/" + advancedsearchID[0].id);
        }
        this.props.handleDialog();
    };

    //page analytic saving of all fields
    saveAnalytic = (values) => {
        const { analyticToEdit } = this.state;
        const { ormPageAnalyticsUpdate } = this.props;

        values.field = parseInt(values.field);
        if (values.goal_value === " ") values.goal_value = 0;
        if (values.field === " ") values.field = 0;
        if (values.field2 === " ") values.field2 = 0;

        //delete these keys as are only saved on selection of field for analytic
        values.field2_type = undefined;
        values.field_type = undefined;

        ormPageAnalyticsUpdate({ id: analyticToEdit.id, ...values });
        this.setState({ open_analytic: false });
    };

    errorValidator = (values) => {};

    //page analytics fields for defaults
    defaultAnalytic = (e) => {
        const { advancedsearch, advancedtablefields, allFields } = this.props;
        const { analyticToEdit, modifiedFields, statType, chartOptions, analyticFields, analyticFields2 } = this.state;
        var obj = {};
        var defaultValues = [];

        //get default value of fields for react-selects for analytics
        if (e === "AnalyticField" || e === "AnalyticField2") {
            //we update the analytic fields if they changed on the columns & then only push the selected
            let temp = [];
            advancedtablefields.forEach((f) => {
                allFields.forEach((j) => {
                    j.form.forEach((k) => {
                        k.groups.forEach((g) => {
                            g.fields.forEach((fi) => {
                                if (fi.id === f.field) {
                                    if (e === "AnalyticField") {
                                        if (fi.id === analyticToEdit.field) defaultValues.push({ label: fi.name, value: f.field, type: fi.type });
                                        temp.push({ label: fi.name, value: f.field, type: fi.type });
                                    } else if (e === "AnalyticField2") {
                                        if (fi.id === analyticToEdit.field2) defaultValues.push({ label: fi.name, value: f.field, type: fi.type });
                                        if (fi.type === "Date") temp.push({ label: fi.name, value: f.field, type: fi.type });
                                    }
                                }
                            });
                        });
                    });
                });
            });
            if (e === "AnalyticField") {
                if (temp.length !== analyticFields.length) this.setState({ analyticFields: temp });
            } else if (temp.length !== analyticFields2.length) this.setState({ analyticFields2: temp });

            return defaultValues;
        }

        // get all other analytic fields
        advancedsearch.forEach((f) => {
            f.pageanalytics.forEach((p) => {
                if (analyticToEdit && p.id === analyticToEdit.id) obj = { ...p };
            });
        });

        //set gauge options to be seen or not
        if (obj.chart === true && !chartOptions) {
            this.setState({ chartOptions: true });
            if (obj.chart_type === "Gauge") {
                this.setState({ chart_Type: "Gauge" });
            } else if (obj.chart_type === "Time Series") {
                this.setState({ chart_Type: "Time Series", fieldType: obj.field_type });
            }
        } else if (obj.chart === false && chartOptions) {
            this.setState({ chartOptions: false });
        }

        //set the statistic types that can be selected based on field type
        if (obj.field) {
            var field;
            modifiedFields.forEach((mf) => {
                mf.options.find((f) => {
                    return f.value === obj.field ? (field = f) : "";
                });
            });

            if (
                field &&
                field.type !== "Incremental" &&
                field.type !== "Numeric" &&
                field.type !== "Calculated" &&
                field.type !== "Currency" &&
                statType.length !== 1
            ) {
                this.setState({ statType: ["Count"] });
            } else if (
                field &&
                (field.type === "Incremental" || field.type === "Numeric" || field.type === "Calculated" || field.type === "Currency") &&
                statType.length === 1
            ) {
                this.setState({ statType: ["Count", "Average", "Sum", "Min", "Max"] });
            }
        }

        return obj;
    };

    defaultValues = (e) => {
        const { advancedtablefields, advancedsearchID, advancedgroups } = this.props;
        const { modifiedFields, showCards } = this.state;

        var obj = {};
        var defaultValues = [];

        //get all database fields that were previously saved, this runs for both ReactSelects with their default calls
        if (e === "columnField" || e === "statisticField") {
            // eslint-disable-next-line
            advancedtablefields.forEach((f) => {
                // eslint-disable-next-line
                modifiedFields.find((j) => {
                    // eslint-disable-next-line
                    j.options.find((k) => {
                        if ((k.value === f.field || k.value === "c_" + f.contact_field) && e === "columnField") {
                            defaultValues.push(k);
                        } else if (k.value === f.field && f.statistics && e === "statisticField") {
                            defaultValues.push(k);
                        }
                    });
                });
            });
            // if(advancedsearchID && advancedsearchID.length > 0){
            //     defaultValues.push({"showlatloncolumns":advancedsearchID[0].showlatloncolumns})
            // }
            return defaultValues;
        }
        var group = advancedgroups.find((g) => {
            return g.id === advancedsearchID[0].advancedgroup;
        });

        obj["group_name"] = group.name;
        obj["expansion_color"] = group.expansion_color;
        obj["expansion_font_color"] = group.expansion_font_color;
        obj["editableby"] = advancedsearchID[0].editableby;
        obj["accessby"] = advancedsearchID[0].accessby;
        obj["dashboard"] = advancedsearchID[0].dashboard;
        obj["geo_color"] = advancedsearchID[0].geo_color;
        obj["analytics_view"] = advancedsearchID[0].analytics_view;
        obj["geometriesonmap"] = advancedsearchID[0].geometriesonmap;
        obj["showlatloncolumns"] = advancedsearchID[0].showlatloncolumns;
        if (advancedsearchID[0].analytics_view && !showCards) this.setState({ showCards: true });

        return obj;
    };

    componentDidMount() {
        // //get all forms & fields that are for parent and user chosen section

        const { allFields, /* sections, advancedsearchID, */ contact_fields, advancedtablefields } = this.props;
        const { modifiedFields } = this.state;
        //find parent section
        // var parent = sections.find((s) => {
        //     if (s.parent) {
        //         return s;
        //     } else return null;
        // });
        // var allForms = allFields.filter((p) => {
        //     if (p.id === parent.id || p.id === advancedsearchID[0].section || p.is_master_contact_form) {
        //         return p;
        //     } else return null;
        // });
        // //get all forms for parent and user chosen section
        var allFormFields = [];

        /*if (authState.user.city_id === 10 && advancedsearch[0].section !== parent.id)*/ var allForms = allFields; //beta for crwd multisections

        //get all fields, setup object so that is can be read into reactSelect as an optGroup.
        //ex. obj ({label: labelName, options:[{key:value}]), label is groupName with options being an array of fields.
        allForms.forEach((c) => {
            if (c.form.length > 0) {
                c.form.forEach((f) => {
                    let temparray = [];
                    f.groups.forEach((g) => {
                        g.fields.forEach((k) => {
                            temparray.push({ label: k.name + " (" + g.name + ")", value: k.id, type: k.type }); //push 'props' that are same 'name' as label and value for optgroup--select.js.These are the items
                        });
                    });
                    let form = { label: f.name + " (Section " + c.name + ")", options: temparray }; //push values into a new array with items. We know have all props for "select.js" optGroup case
                    allFormFields.push(form);
                });
            }
            if (c.is_master_contact_form) {
                //rerendering of window or reopening search add contacts column fields if available
                let temparray = [];
                contact_fields.forEach((k) => {
                    temparray.push({ label: k.field_name, value: "c_" + k.id });
                });
                let form = { label: "Contacts (Section " + c.name + ")", options: temparray };
                allFormFields.push(form);
            }
        });

        if (modifiedFields === null || modifiedFields.length !== allFormFields.length) {
            //get only the selected columns for page analytics as we do not want users to be trying to grab non-queried fields
            var analyticfields = [];
            var analyticFields2 = [];
            advancedtablefields.forEach((f) => {
                // eslint-disable-next-line
                allFormFields.find((j) => {
                    // eslint-disable-next-line
                    j.options.find((k) => {
                        if (k.value === f.field) {
                            analyticfields.push(k);
                        }
                        if (k.value === f.field && k.type === "Date") analyticFields2.push(k);
                    });
                });
            });

            //initially and update when section is chosen to update all forms with fields
            this.setState({
                modifiedFields: [...new Set(allFormFields)],
                analyticFields: [...new Set(analyticfields)],
                analyticFields2: [...new Set(analyticFields2)],
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { advancedsearchID, formFields } = this.props;
        const { modifiedFields } = this.state;

        //update only on initial selection of advancedfilter section to make sure we have all forms fields/sections
        if (advancedsearchID && advancedsearchID[0].section && formFields && formFields.length !== modifiedFields.length)
            this.setState({ modifiedFields: formFields, key: window.performance.now() });
    }

    //value function for dropdown datatype. This is the options property.
    dropDownValues = (table) => {
        return table.map((row) => ({
            label: row,
            value: row,
        }));
    };
    dropDownColorValues = (table) => {
        return table.map((row) => ({
            label: row.color,
            value: `${row.color},${row.hex}`,
        }));
    };

    chartType = (type) => {
        return type.map((thistype) => ({
            label: thistype,
            value: thistype,
        }));
    };

    timeSeries = (type) => {
        return type.map((t) => ({
            label: t,
            value: t,
        }));
    };

    //update color of expansion bar if a prior group was choosen
    relay = (e) => {
        const { advancedgroups } = this.props;

        const found = advancedgroups.find((g) => {
            return g.name === e.target.value;
        });
        this.formApi.setValue("group_name", e.target.value);
        this.formApi.setValue("expansion_color", found.expansion_color);
    };

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const { ormPageAnalyticsUpdate, advancedsearch } = this.props;
        // // analytics
        if (result.destination.droppableId === "analytics_list") {
            const pageanalytics = advancedsearch[0].pageanalytics;
            // Set the order of analytics moved between starting and ending position
            pageanalytics.forEach(function (p) {
                // If the destination is greater than the original position then shift all items between them down 1
                // result.source.index = 0
                // result.destination.index = 2
                if (result.destination.index > result.source.index) {
                    if (p.order > result.source.index && p.order <= result.destination.index) {
                        ormPageAnalyticsUpdate({
                            id: p.id,
                            order: p.order - 1,
                        });
                    }
                }
                // If the destination is less than the original position then shift all items between them up 1
                // result.source.index = 2
                // result.destination.index = 0
                else if (result.destination.index < result.source.index) {
                    if (p.order >= result.destination.index && p.order < result.source.index) {
                        ormPageAnalyticsUpdate({
                            id: p.id,
                            order: p.order + 1,
                        });
                    }
                }
            });
            // Set the order of analytics moved
            ormPageAnalyticsUpdate({
                id: result.draggableId,
                order: result.destination.index,
            });
        }
    };

    render() {
        const {
            classes,
            open,
            advancedgroups,
            advancedsearchID,
            ormPageAnalyticsCreate,
            advancedsearch,
            ormAdvancedSearchUpdate,
            ormPageAnalyticsDelete,
            ormPageAnalyticsUpdate,
            authState,
            has_geometry,
            sections,
        } = this.props;
        const {
            value,
            modifiedFields,
            open_analytic,
            analyticFields,
            analyticFields2,
            showCards,
            chartOptions,
            analyticToEdit,
            analyticToDelete,
            statType,
            chart_Type,
            key,
            deleteDialogOpen,
        } = this.state;

        const edit_access = ["Creator", "Admin", "All", "All Except External Read Only Users"];
        const accessibility = ["Creator", "Admin", "All", "All Including External Read Only Users"];
        const chartTypes = ["None", "Gauge", "Time Series"];
        const timeSeriesType = ["Bar", "Line"];
        const timeToIncrement = ["Daily", "Weekly", "Monthly", "Quarterly", "Annually"];
        const tableData = advancedsearch;
        let isPointGeo = false
        if(sections){
            isPointGeo = sections.find(s=>s.parent && s.geometry_type === 'Point'? s:null)

        }
        return (
            <div className={classes.root}>
                <Dialog open={open} classes={{ paper: classes.maxHeight /*: classes.minWidth*/ }}>
                    <Toolbar style={{ backgroundColor: "#d3d3d3", marginBottom: -8 }}>
                        <Typography variant="h5" className={classes.flex}>
                            Saved Search Settings
                        </Typography>
                        <IconButton onClick={this.props.handleDialog}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <DialogContentText>
                            <Formm
                                key={key}
                                getApi={(el) => (this.formApi = el)} //allows use of this.formApi ref external of form
                                // dontValidateOnMount="true"
                                validateOnSubmit="true"
                                defaultValues={this.defaultValues()}
                                onSubmit={(values) => this.saveSettings(values)}>
                                {(formApi) => (
                                    <form onSubmit={formApi.submitForm}>
                                        <div>
                                            <AppBar position="static" color="default">
                                                <Tabs
                                                    value={value}
                                                    onChange={(event, value) => {
                                                        this.setState({ value });
                                                    }}
                                                    variant="scrollable"
                                                    indicatorColor="primary"
                                                    textColor="primary"
                                                    classes={{ scroller: classes.scroller }}>
                                                    <Tab
                                                        classes={{ root: classes.fullWidth }}
                                                        icon={<ViewHeadlineIcon />}
                                                        label={"Result Table Display"}
                                                        value={0}
                                                    />
                                                    <Tab
                                                        classes={{ root: classes.fullWidth }}
                                                        icon={<TimelineIcon />}
                                                        label={"Statistic Fields"}
                                                        value={1}
                                                    />
                                                    <Tab
                                                        classes={{ root: classes.fullWidth }}
                                                        icon={<ShowChartIcon />}
                                                        label={"Module Analytics"}
                                                        value={2}
                                                    />
                                                    <Tab
                                                        classes={{ root: classes.fullWidth }}
                                                        icon={<SupervisorAccountIcon />}
                                                        label={"View Settings"}
                                                        value={3}
                                                    />
                                                </Tabs>
                                            </AppBar>
                                        </div>
                                        {value === 0 && (
                                            <Grid container style={{ paddingTop: 20, paddingLeft: 36 }}>
                                                <Grid item xs={11}>
                                                    <Typography variant="h5" 
                                                      
                                                        className={classes.flex}><>
                                                            Column Fields  
                                                            <HelpLabel expansionPanel={true}  helpText={"The <b>selection</b> ordering of the fields will follow the same order within the table results of the search.<br/><br/>\
                                                            "} /></>
                                                         
                                                    </Typography>
                                                    {/* default values are passed to the reactSelect component for rendering*/}
                                                    <ReactSelect
                                                        key={this.formKey}
                                                     
                                                        field="columnField"
                                                        defaultValue={this.defaultValues("columnField")}
                                                        options={modifiedFields}
                                                        isMulti
                                                        menuIsOpen={true} //keep menu always open
                                                    />
                                                    <div style={{ marginTop: 324 }}>
                                                        <Typography variant="h7" className={classes.flex} fullWidth>
                                                            Note: selected 'column' fields will be removed from the list as they are selected.
                                                        </Typography>
                                                    </div>
                                                    <div style={{ marginTop: 5, color: "red" }}>
                                                        <Typography variant="h7" className={classes.flex}>
                                                            To consolidate table column results by name, select all desired form fields with a common
                                                            name.{" "}
                                                        </Typography>
                                                    </div>
                                                    {(isPointGeo && (
                                                        <Grid xs={5} item>
                                                            <Switch
                                                                field="showlatloncolumns"
                                                                name="showlatloncolumns"
                                                                label={
                                                                    <HelpLabel
                                                                        inputLabel="Show Lat/Lon fields in an xls download?"
                                                                        helpText="Select 'Yes' to include lat and lon fields within download of the xls table results. This option will only return 'primary' section lat/lon map values and the section geometry configuration must be set as 'Point'. Note: this feature is not compatible with table result fields that are master contacts."
                                                                    />
                                                                }
                                                            />
                                                        </Grid>
                                                    ) 
                                                    )}
                                                   
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        size="large"
                                                        style={{ marginTop: 29, width: "21%", float: "right" }}>
                                                        Save
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        )}
                                        {value === 1 && (
                                            <Grid container style={{ paddingTop: 20, paddingLeft: 36 }}>
                                                <Grid item xs={11}>
                                                    <Typography variant="h5" className={classes.flex}>
                                                        Statistic Fields
                                                    </Typography>
                                                    <ReactSelect
                                                        key={this.formKey}
                                                        field="statisticField"
                                                        defaultValue={this.defaultValues("statisticField")}
                                                        options={modifiedFields}
                                                        isMulti
                                                        menuIsOpen={true} //keep menu always open
                                                    />
                                                    <div style={{ marginTop: 324 }}>
                                                        <Typography variant="h7" className={classes.flex}>
                                                            Note: statistic fields can 'only' be Numeric, Currency or Calculated field 'data types'
                                                            for proper statistic calculations.
                                                        </Typography>
                                                    </div>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        size="large"
                                                        style={{ marginTop: 109, width: "21%", float: "right" }}>
                                                        Save
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        )}
                                        {value === 2 && (
                                            <Grid container style={{ paddingTop: 20, paddingLeft: 36 }}>
                                                <Grid xs={11} style={{ marginLeft: 14 }}>
                                                    {<br></br>}

                                                    <Switch
                                                        field="analytics_view"
                                                        name="repeatable"
                                                        label={
                                                            <HelpLabel
                                                                key={key}
                                                                inputLabel="Include Card(s) on Module Dashboard?"
                                                                helpText={`If Yes, select fields to appear and also identify the Statistic Type along with other options below. Cards are displayed on the Dashboard.
                                                                                                    
                                                                                                    The fields available for Cards are limited to those fields selected in the "Results Display Setting" section of the settings to appear as columns in the results table.`}
                                                            />
                                                        }
                                                        eventHandle={(e) => {
                                                            ormAdvancedSearchUpdate({ id: advancedsearchID[0].id, analytics_view: e });
                                                            this.setState({ showCards: e });
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item xs={11}>
                                                    {!showCards && (
                                                        <>
                                                            <br></br>
                                                            <Typography
                                                                variant="h5"
                                                                style={{ color: "red", fontSize: 18, textDecoration: "underline", marginLeft: 12 }}>
                                                                Cards will not be shown on the Dashboard.
                                                            </Typography>
                                                        </>
                                                    )}
                                                    {showCards && (
                                                        <>
                                                            <Button
                                                                fullWidth
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={(e) => {
                                                                    const order = this.props.advancedsearch[0].pageanalytics.length;
                                                                    ormPageAnalyticsCreate({ advancedsearch_id: advancedsearchID[0].id, order }).then(
                                                                        (e) => {
                                                                            this.setState({ open_analytic: true, analyticToEdit: e });
                                                                        }
                                                                    );
                                                                }}
                                                                style={{
                                                                    minWidth: 596,
                                                                    marginBottom: 18,
                                                                    marginTop: 17,
                                                                }}>
                                                                <AddIcon />
                                                                &nbsp;&nbsp;&nbsp;Create New
                                                            </Button>

                                                            <DragDropContext onDragEnd={this.onDragEnd}>
                                                                <Droppable droppableId="analytics_list" type="analytics_list" key="analytics_list">
                                                                    {(provided, snapshot) => (
                                                                        <Accordion
                                                                            style={{
                                                                                backgroundColor: snapshot.isDraggingOver ? "#4e4e4e" : "",
                                                                            }}
                                                                            defaultExpanded
                                                                            expanded={true}>
                                                                            <AccordionSummary
                                                                                className={classes.titlePanel}
                                                                                classes={{ root: classes.expandedMargin }}
                                                                                style={{
                                                                                    backgroundColor: "#333",
                                                                                    borderRadius: 3,
                                                                                    boxShadow:
                                                                                        "rgba(0, 0, 0, 0.1) 0px 1px 0px, rgba(0, 0, 0, 0.2) 0px 1px 1px",
                                                                                }}>
                                                                                <table style={{ width: "100%" }}>
                                                                                    {/* Table Header */}
                                                                                    <tr>
                                                                                        <td>
                                                                                            <Typography
                                                                                                variant="h5"
                                                                                                style={{
                                                                                                    fontSize: 16,
                                                                                                    marginLeft: "76%",
                                                                                                    color: "#fff",
                                                                                                    whiteSpace: "nowrap",
                                                                                                }}>
                                                                                                Field Name
                                                                                            </Typography>
                                                                                        </td>
                                                                                        <td>
                                                                                            <Typography
                                                                                                variant="h5"
                                                                                                style={{
                                                                                                    fontSize: 16,
                                                                                                    marginLeft: "42%",
                                                                                                    color: "#fff",
                                                                                                }}>
                                                                                                Card Label Text
                                                                                            </Typography>
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </AccordionSummary>
                                                                            <div
                                                                                ref={provided.innerRef}
                                                                                style={getListStyle2(snapshot.isDraggingOver)}>
                                                                                <Grid container>
                                                                                    <Grid item xs={12}>
                                                                                        <table
                                                                                            style={{
                                                                                                width: "100%",
                                                                                                marginTop: -3,
                                                                                                marginBottom: -3,
                                                                                                fontSize: "initial",
                                                                                            }}>
                                                                                            {tableData.map((t) => {
                                                                                                return t.pageanalytics.map((n) => {
                                                                                                    var fieldName; // eslint-disable-next-line
                                                                                                    modifiedFields.find((j) => {
                                                                                                        // eslint-disable-next-line
                                                                                                        j.options.find((k) => {
                                                                                                            if (k.value === parseInt(n.field)) {
                                                                                                                fieldName = k.label;
                                                                                                            }
                                                                                                        });
                                                                                                    });
                                                                                                    return (
                                                                                                        <Draggable
                                                                                                            key={n.id}
                                                                                                            draggableId={n.id}
                                                                                                            index={n.order}
                                                                                                            isDragDisabled={
                                                                                                                !authState.user.is_city_admin
                                                                                                            }>
                                                                                                            {(provided, snapshot) => (
                                                                                                                <div
                                                                                                                    key={n.id}
                                                                                                                    ref={provided.innerRef}
                                                                                                                    {...provided.draggableProps}
                                                                                                                    {...provided.dragHandleProps}
                                                                                                                    style={getItemStyle2(
                                                                                                                        snapshot.isDragging,
                                                                                                                        provided.draggableProps.style
                                                                                                                    )}
                                                                                                                    className={classes.fieldButton}>
                                                                                                                    <tr
                                                                                                                        hover
                                                                                                                        key={n.id}
                                                                                                                        style={{
                                                                                                                            width: "100%",
                                                                                                                            display: "inline-table",
                                                                                                                        }}>
                                                                                                                        <td
                                                                                                                            className={classes.nowrap}
                                                                                                                            style={{
                                                                                                                                width: "170px",
                                                                                                                            }}>
                                                                                                                            {/* <Tooltip title="Drag to order"> */}
                                                                                                                            <img
                                                                                                                                style={{
                                                                                                                                    width: "1.5em",
                                                                                                                                    height: "1.6em",
                                                                                                                                    verticalAlign:
                                                                                                                                        "middle",
                                                                                                                                    marginRight: 11,
                                                                                                                                    marginLeft: 6,
                                                                                                                                }}
                                                                                                                                src={drag}
                                                                                                                                alt="fireSpot"
                                                                                                                            />
                                                                                                                            {/* </Tooltip> */}
                                                                                                                            <Tooltip title="Edit">
                                                                                                                                <Button
                                                                                                                                    onClick={() => {
                                                                                                                                        this.setState(
                                                                                                                                            {
                                                                                                                                                open_analytic: true,
                                                                                                                                                analyticToEdit:
                                                                                                                                                    n,
                                                                                                                                            }
                                                                                                                                        );
                                                                                                                                        this.props.handleDialog();
                                                                                                                                    }}
                                                                                                                                    className={
                                                                                                                                        classes.deleteWidth
                                                                                                                                    }>
                                                                                                                                    <EditIcon color="primary" />
                                                                                                                                </Button>
                                                                                                                            </Tooltip>
                                                                                                                            <Tooltip title="Delete">
                                                                                                                                <Button
                                                                                                                                    onClick={() =>
                                                                                                                                        this.setState(
                                                                                                                                            {
                                                                                                                                                deleteDialogOpen: true,
                                                                                                                                                analyticToDelete:
                                                                                                                                                    n,
                                                                                                                                            }
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                    className={
                                                                                                                                        classes.deleteWidth
                                                                                                                                    }>
                                                                                                                                    <DeleteIcon color="inherit" />
                                                                                                                                </Button>
                                                                                                                            </Tooltip>
                                                                                                                        </td>
                                                                                                                        <td
                                                                                                                            style={{
                                                                                                                                width: "170px",
                                                                                                                            }}>
                                                                                                                            <Typography>
                                                                                                                                {fieldName
                                                                                                                                    ? fieldName.length >
                                                                                                                                      49
                                                                                                                                        ? fieldName.substring(
                                                                                                                                              0,
                                                                                                                                              50
                                                                                                                                          ) + "..."
                                                                                                                                        : fieldName
                                                                                                                                    : "-"}
                                                                                                                            </Typography>
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            <Typography>
                                                                                                                                {n.card_label
                                                                                                                                    ? n.card_label
                                                                                                                                          .length > 49
                                                                                                                                        ? n.card_label.substring(
                                                                                                                                              0,
                                                                                                                                              50
                                                                                                                                          ) + "..."
                                                                                                                                        : n.card_label
                                                                                                                                    : "-"}
                                                                                                                            </Typography>
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </Draggable>
                                                                                                    );
                                                                                                });
                                                                                            })}
                                                                                        </table>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </div>
                                                                        </Accordion>
                                                                    )}
                                                                </Droppable>
                                                            </DragDropContext>
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <Typography
                                                                        variant="h5"
                                                                        style={{
                                                                            fontSize: 16,
                                                                            marginLeft: 13,
                                                                            color: "#e90909",
                                                                        }}>
                                                                        Drag and drop the analytic cards for re-ordering. This ordering will be shown
                                                                        on the 'modules' dashboard. Note: users must have 'admin' permisions to
                                                                        re-order analytic cards.
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        )}
                                        {value === 3 && (
                                            <Grid container style={{ paddingTop: 24, paddingLeft: 20 }}>
                                                <div
                                                    style={{
                                                        backgroundColor: "#d3d3d3",
                                                        minWidth: 611,
                                                        marginLeft: 10,
                                                        paddingBottom: 17,
                                                        borderRadius: 4,
                                                    }}>
                                                    <Typography
                                                        variant="h6"
                                                        className={classes.flex}
                                                        style={{
                                                            fontSize: 16,

                                                            marginBottom: 7,
                                                            paddingLeft: 12,
                                                            color: "#fff",
                                                            background: "#191919", // "#673ab7",
                                                            borderRadius: 4,
                                                            paddingTop: 4,
                                                            paddingBottom: 4,
                                                        }}>
                                                        Group Display: Choose a New 'or' Existing Group to display this Saved Search
                                                    </Typography>
                                                    <TextField
                                                        field="g_name"
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Create a New Group?"
                                                                helpText="The Saved Search will be grouped under this name. Leave this field blank if you want to place this search within an existing Saved Search Group."
                                                            />
                                                        }
                                                        style={{ marginLeft: "2%", width: "96%" }}
                                                    />
                                                    <Grid item xs={10}>
                                                        <Typography
                                                            variant="h5"
                                                            className={classes.flex}
                                                            style={{ marginLeft: "54%", paddingTop: 5, fontSize: 21 }}>
                                                            (OR)
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <Select
                                                            field="group_name"
                                                            options={this.dropDownValues(
                                                                advancedgroups.map((g) => {
                                                                    return g.name;
                                                                })
                                                            )}
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Select Group?"
                                                                    helpText="This Saved Search will be placed in the selected Group."
                                                                />
                                                            }
                                                            onChange={(e) => this.relay(e)}
                                                            style={{ marginLeft: "2%", minWidth: 588, marginBottom: 19 }}
                                                        />
                                                    </Grid>
                                                    <Typography
                                                        variant="h6"
                                                        className={classes.flex}
                                                        style={{
                                                            fontSize: 16,
                                                            marginBottom: 7,
                                                            paddingLeft: 12,
                                                            color: "#fff",
                                                            background: "#191919",
                                                            paddingTop: 4,
                                                            paddingBottom: 4,
                                                        }}>
                                                        Expansion Bar Color: Select the Color of the Search Bar on the Dashboard
                                                    </Typography>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            xs={5}
                                                            style={{
                                                                marginBottom: 7,
                                                                paddingLeft: 12,
                                                            }}>
                                                            <ColorPicker field="expansion_color" label="Expansion Bar Color" />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={5}
                                                            style={{
                                                                marginBottom: 7,
                                                            }}>
                                                            <ColorPicker field="expansion_font_color" label="Expansion Font Color" />
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                                <Grid item xs={11} style={{ marginLeft: 20, marginTop: 15 }}>
                                                    <Select
                                                        field="editableby"
                                                        options={this.dropDownValues(edit_access)}
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Edit Privileges?"
                                                                helpText="Select the user type that can edit this search.  Note: the 'Creator' selection is the user that selected this setting."
                                                            />
                                                        }
                                                        style={{ marginLeft: -8, minWidth: 595 }}
                                                    />
                                                </Grid>
                                                <Grid item xs={11} style={{ marginLeft: 20, marginTop: 15 }}>
                                                    <Select
                                                        field="accessby"
                                                        options={this.dropDownValues(accessibility)}
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Accessibility?"
                                                                helpText="Select who can view this search. Note: the 'Creator' is the person that 'Saved' the search with this setting being set."
                                                            />
                                                        }
                                                        style={{ marginLeft: -8, minWidth: 595 }}
                                                    />
                                                </Grid>
                                                <Grid item xs={11} style={{ marginLeft: 20, marginTop: 10 }}>
                                                    <div style={{ marginTop: -13 }}>
                                                        <Grid container>
                                                            <Grid xs={5} item>
                                                                <Switch
                                                                    field="dashboard"
                                                                    name="dashboard"
                                                                    label={
                                                                        <HelpLabel
                                                                            inputLabel="Show on Dashboard?"
                                                                            helpText="Select 'Yes' to show this Saved Search on the dashboard."
                                                                        />
                                                                    }
                                                                />
                                                            </Grid>
                                                            {has_geometry && (
                                                                <Grid xs={5} item>
                                                                    <Switch
                                                                        field="geometriesonmap"
                                                                        name="geometriesonmap"
                                                                        label={
                                                                            <HelpLabel
                                                                                inputLabel="Show Geometry on Map?"
                                                                                helpText="Select 'Yes' to show this Saved Search Geometries on the Map."
                                                                            />
                                                                        }
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                        <ColorPicker field="geo_color" label="Map Layer Color" />
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            size="large"
                                                            style={{ marginTop: 5, width: "21%", float: "right" }}>
                                                            Save
                                                        </Button>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </form>
                                )}
                            </Formm>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog open={open_analytic} classes={{ paper: classes.minWidth2 }} disableBackdropClick={true}>
                    <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                        <Typography variant="h5" className={classes.flex}>
                            Card Settings
                        </Typography>
                        <IconButton onClick={() => this.setState({ open_analytic: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent>
                        <DialogContentText>
                            <Formm
                                getApi={(el) => (this.formApi = el)} //allows use of this.formApi ref external of form
                                defaultValues={this.defaultAnalytic()}
                                validateError={this.errorValidator}
                                dontValidateOnMount="true"
                                onSubmit={(values) => this.saveAnalytic(values)}>
                                {(formApi) => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container style={{ paddingTop: 20 }}>
                                            <Grid item xs={12}>
                                                {/* default values are passed to the reactSelect component for rendering*/}
                                                <ReactSelect
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Select Field:"
                                                            helpText={`Choose the field to display a statistic value on the Card. 
                                                            
                                                            Only fields selected as column on the results table are available.`}
                                                        />
                                                    }
                                                    field="field"
                                                    defaultValue={this.defaultAnalytic("AnalyticField")}
                                                    options={analyticFields}
                                                    eventHandle={(e) => {
                                                        return (
                                                            ormPageAnalyticsUpdate({ id: analyticToEdit.id, field: e.value, field_type: e.type }),
                                                            this.setState({ fieldType: e.type })
                                                        );
                                                    }}
                                                />
                                                <TextField
                                                    field="card_label"
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Card Label Text:"
                                                            helpText={"Enter text that will appear as a label alongside the statistical value"}
                                                        />
                                                    }
                                                    style={{ marginTop: 28 }}
                                                    fullWidth
                                                />
                                                <Select
                                                    field="stat_type"
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Statistic Type:"
                                                            helpText={`Choose the statistic to be calculated and displayed on the Card. 
                                                            
                                                            If the selected field is not a Numeric, Currency or Calculated field type, only the Count type will be available.`}
                                                        />
                                                    }
                                                    options={this.dropDownValues(statType)}
                                                    style={{ marginTop: 28 }}
                                                    fullWidth
                                                />
                                                <hr style={{marginTop:17}}/>
                                                <Grid container style={{marginTop:5}}>
                                                    <Grid xs={4}>
                                                        <ColorPicker field="card_header_bgcolor" label="Header Background Color" />
                                                    </Grid> 
                                                    <Grid xs={4}>
                                                        <ColorPicker field="card_header_txtcolor" label="Header Text Color" />
                                                    </Grid>
                                                    <Grid xs={4}>
                                                        <ColorPicker field="stat_color" label="Value Text Color" />
                                                    </Grid>  
                                                </Grid> 
                                                <hr style={{marginTop:17}}/>
                                            
                                                <div style={{ marginBottom: 12 }}>
                                                    <Checkbox
                                                        field="chart"
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Include Chart?"
                                                                helpText="Select yes to include a chart along with the statistical value on the Card."
                                                            />
                                                        }
                                                        eventHandle={(val) => {
                                                            return val === true
                                                                ? ormPageAnalyticsUpdate({ id: analyticToEdit.id, chart: true })
                                                                : (ormPageAnalyticsUpdate({
                                                                      id: analyticToEdit.id,
                                                                      chart: false,
                                                                      chart_type: "None",
                                                                      field2: 0,
                                                                      field2_type: "None",
                                                                      cumulative: false,
                                                                      field_value: "",
                                                                      goal_value: 0,
                                                                      time_increment: "",
                                                                      time_series_type: "Bar",
                                                                  }), //now reset form incase they dont save
                                                                  this.formApi.setValue("chart_type", "None"),
                                                                  this.formApi.setValue("field2", 0),
                                                                  this.formApi.setValue("field2_type", "None"),
                                                                  this.formApi.setValue("cumulative", false),
                                                                  this.formApi.setValue("field_value", ""),
                                                                  this.formApi.setValue("goal_value", 0),
                                                                  this.formApi.setValue("time_increment", ""),
                                                                  this.formApi.setValue("time_series_type", "Bar"),
                                                                  this.setState({ chartOptions: "", chart_Type: "" }));
                                                        }}
                                                    />
                                                </div> 
                                                {chartOptions && (
                                                    <Select
                                                        field="chart_type"
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Chart Type:"
                                                                helpText={
                                                                    "Choose the type of chart to display on the card. Selections are Gauge or Time Series."
                                                                }
                                                            />
                                                        }
                                                        options={this.chartType(chartTypes)}
                                                        fullWidth
                                                        eventHandle={(val) => {
                                                            return (
                                                                ormPageAnalyticsUpdate({ id: analyticToEdit.id, chart_type: val }),
                                                                this.setState({ chart_Type: val })
                                                            );
                                                        }}
                                                    />
                                                )}
                                                {chart_Type === "Gauge" && (
                                                    <>
                                                        <TextField
                                                            field="goal_value"
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Goal Value:"
                                                                    helpText={"Enter a numeric value to set the upper limit on the gauge chart"}
                                                                />
                                                            }
                                                            fullWidth
                                                            style={{ marginTop: 15 }}
                                                        />

                                                        <ColorPicker field="progress_color" label="Chart Color:" />
                                                    </>
                                                )}

                                                {chart_Type === "Time Series" && (
                                                    /*fieldType === "Date" &&*/ <div
                                                        style={{
                                                            backgroundColor: "#d3d3d3",
                                                            paddingLeft: 10,
                                                            marginLeft: -9,
                                                            marginRight: -9,
                                                            paddingRight: 10,
                                                            paddingBottom: 17,
                                                            paddingTop: 16,
                                                            borderRadius: 5,
                                                            zIndex: 10000,
                                                            marginTop: 14,
                                                        }}>
                                                        <Typography
                                                            style={{ fontSize: 13, color: "#da0014", marginBottom: 13 }}
                                                            className={classes.flex}>
                                                            {`Time Series charts must have a Date field from the 'Results Display 
                                                            Settings' in order to select a 'Date Value Field' on the chart.
                                                      `}
                                                        </Typography>
                                                        <ReactSelect
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Select Date Value Field:"
                                                                    helpText={`Choose the date field to use on the time series chart. Only the fields selected to display on the search results table are available.`}
                                                                />
                                                            }
                                                            field={"field2"}
                                                            defaultValue={this.defaultAnalytic("AnalyticField2")}
                                                            options={analyticFields2}
                                                            eventHandle={(e) => {
                                                                return e !== " "
                                                                    ? (ormPageAnalyticsUpdate({
                                                                          id: analyticToEdit.id,
                                                                          field2: e.value,
                                                                          field2_type: e.type,
                                                                      }),
                                                                      this.formApi.setValue("field2_type", e.type))
                                                                    : ormPageAnalyticsUpdate({
                                                                          id: analyticToEdit.id,
                                                                          field2: 0,
                                                                          field2_type: null,
                                                                      });
                                                                // this.setState({ field2Type: e.type });
                                                            }}
                                                        />
                                                        <Select
                                                            field="time_series_type"
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Time Series Type:"
                                                                    helpText={"Choose 'Bar' or 'Time Series' chart"}
                                                                />
                                                            }
                                                            options={this.timeSeries(timeSeriesType)}
                                                            fullWidth
                                                            style={{ marginTop: 15 }}
                                                        />

                                                        <Select
                                                            style={{ marginTop: 15 }}
                                                            field="time_increment"
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Time Increment:"
                                                                    helpText={`Considering the limited chart size on the Module Analytics View, the following guidelines are suggested when choosing the Time Increment for the x-axis.

                                                                        -Daily - Do not display more than a 1 month period.
                                                                        -Weekly - Do not display more than a 3 month period.
                                                                        -Monthly - Do not display more than a 1 year period.
                                                                        -Quarterly - Do not display more than a 1 year period.
                                                                        -Annually - Do not display more than a 10 year period.
                                                                        
                                                                        The start and end time of the x-axis are dynamically determined from the Search Fields. To reduce or extend the time period shown on the x-axis, adjust the Search Field criteria.`}
                                                                />
                                                            }
                                                            options={this.timeSeries(timeToIncrement)}
                                                            fullWidth
                                                        />
                                                        <Checkbox
                                                            field="cumulative"
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Is Chart Cumulative?"
                                                                    helpText="Values will be cumulative on the time series chart if checked."
                                                                />
                                                            }
                                                        />
                                                        <TextField
                                                            style={{ marginTop: 15 }}
                                                            field="goal_value"
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Goal Value:"
                                                                    helpText={
                                                                        "Enter a numeric value to set the upper limit on the bar or time series chart"
                                                                    }
                                                                />
                                                            }
                                                            fullWidth
                                                        />
                                                        <ColorPicker field="progress_color" label="Chart Color:" />
                                                    </div>
                                                )}

                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    style={{ marginTop: 29, width: "21%", float: "right" }}>
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Formm>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <WarningDialog
                    confirmAction={() => {
                        ormPageAnalyticsDelete(analyticToDelete.id);
                        this.setState({ deleteDialogOpen: false });
                    }}
                    cancelAction={() => this.setState({ deleteDialogOpen: false })}
                    open={deleteDialogOpen}
                    title="Delete Analytic Card?"
                    text={`Are you sure you wish to permanently delete '${analyticToDelete ? analyticToDelete.card_label : null}' ?`}
                />
            </div>
        );
    }
}
AdvancedSearchSettings.displayName = "AdvancedSearchSettings";

AdvancedSearchSettings = connect(
    (state, ownProps) => ({
        has_geometry: getGeometry(state, ownProps),
        contact_fields: getAllContactFields(state, ownProps),
        advancedsearch: getPageAnalytics(state, ownProps),
    }),
    { ...RecordSection.actions, ...PageAnalytics.actions }
)(AdvancedSearchSettings);

export default withStyles(styles, { withTheme: true })(withRouter(AdvancedSearchSettings));
