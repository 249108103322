import React, { Component } from "react";
import classNames from "classnames";
import { FormField } from "react-form";

import MuiTextField from "@material-ui/core/TextField";

import { withStyles } from "@material-ui/core";

import HelpLabel from "./HelpLabel";

const styles = (theme) => ({
    bootstrapInput: {
        borderRadius: 4,
        border: "1px solid #979797",
        padding: "6px 8px",
        width: "calc(100% - 24px)",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderColor: theme.palette.secondary.light,
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
        backgroundColor: "#fff",
        fontSize: "15px",
        fontWeight: 500,
        color: "#262626 !important",
    },
    bootstrapInputError: {
        border: "1px solid #f44336",
        borderRadius: 4,
        padding: "6px 8px",
        width: "calc(100% - 24px)",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderColor: theme.palette.secondary.light,
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
        backgroundColor: "#fff",
        fontSize: "15px",
        fontWeight: 500,
        color: "#262626 !important",
    },
    bootstrapSelectError: {
        // Select menus are a div not a input
        border: "1px solid #f44336",
    },
    bootstrapSelectError2: {
        paddingRight: "8px !important",
    },
    selectIcon: {
        right: "8px !important",
    },
    rootLabelShrink: {
        marginLeft: 0,
        marginTop: -2,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
    },
    whiteSpace: {
        whiteSpace: "nowrap",
    },
    disabled: {
        backgroundColor: "#EEEEEE",
    },
    noPadding: {
        padding: 0,
    },
});

// See https://react-form.js.org/#/custom-input
class TextfieldWrapper extends Component {
    render() {
        const {
            fieldApi,
            helperText,
            SelectProps,
            units,
            help,
            classes,
            label,
            disabled,
            unitsVisual,
            useTextFormat, //for currency
            useNumberFormat,
            usePhoneNumberFormat,
            useNumberFormat3,
            children,
            isRequired,
            margin,
            identification,
            isInteger,
            select,
            hyperlink,

            ...rest
        } = this.props;
        const { getValue, getError } = fieldApi;

        const value = getValue() || (SelectProps ? (SelectProps.multiple ? [] : "") : getValue() === 0 ? 0 : ""),
            error = getError();

        let InputProps = {},
            renderValue;

        if (units) {
            if (value && value.split) {
                renderValue = value.split(" ")[0];
            } else {
                renderValue = value;
            }
        } else {
            renderValue = value;
        }

        InputProps["disableUnderline"] = true;
        InputProps["classes"] = {
            input: classNames(!error ? classes.bootstrapInput : classes.bootstrapInputError, disabled && classes.disabled),
            // error: classes.bootstrapInputError,
            root: classes.noPadding,
        };

        return (
            <>
                {!hyperlink && (
                    <MuiTextField
                        value={renderValue.toString()}
                        label={!label && !select ? children : label}
                        disabled={disabled}
                        InputProps={InputProps}
                        margin="none"
                        children={children}
                        InputLabelProps={{
                            shrink: true,
                            classes: {
                                shrink: classes.rootLabelShrink,
                            },
                        }}
                        {...rest}
                    />
                )}

                {hyperlink && (
                    <>
                        <a href={renderValue.toString()} rel="noopener noreferrer" target="_blank">
                            {label && label.props ? label.props.inputLabel : label}
                        </a>
                        {label && label.props ? label.props.helpText && <HelpLabel /*question={label}*/ helpText={label.props.helpText} /> : null}
                    </>
                )}
            </>
        );
    }
}
TextfieldWrapper.displayName = "TextfieldWrapper";
const EnhancedTextField = FormField(TextfieldWrapper);

export default withStyles(styles)(EnhancedTextField);
