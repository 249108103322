import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import DialogContent from "@material-ui/core/DialogContent";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import DialogContentText from "@material-ui/core/DialogContentText";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ViewIcon from "@material-ui/icons/Visibility";
import Close from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit"; 
import AddIcon from "@material-ui/icons/Add";
import Paper from "@material-ui/core/Paper";
import { evaluate } from "mathjs";
import NumberFormat from "react-number-format";

import WarningDialog from "../../common/WarningDialog";
import RadioGroup from "../../common/RadioGroup";
import AppContainerApplication from "../../common/AppContainerApplication";
import TextField from "../../common/TextField";
import Select from "../../common/Select";
import CheckboxGroup from "../../common/CheckboxGroup";  
import FileInput from "../../common/FileInput";
import Checkbox from "../../common/Checkbox";
// import Subquestion from "../../common/Subquestion";
import HelpLabel from "../../common/HelpLabel";
import { createSelector } from "../../common/orm";
// import states from "../../common/statesmn.json";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import { ApplicationRecord, ApplicationRecordStatus, NineMileGrant, NineMileGrantAttachment, NineMileGrantExpense, NineMileGrantAnnualAttachment, 
    NineMileGrantAnnualReporting, NineMileGrantInprogressAttachment, NineMileGrantInprogressExpense,NineMileGrantInprogressReporting  } from "../../page/models";
import BaseMapControlApplication from "../esri/BaseMapControlApplication";
import ReactSelect from "../../common/ReactSelect";

import buffer from "../images/ninemile/buffer.png";


const styles = (theme) => ({
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8,
        marginTop: 2,
        marginBottom: 2,
    },
    centerAlign: {
        textAlign: "center",
    },
    centerAlign2: {
        textAlign: "center",
        display: "inline-block",
        width: "100%",
    },
    nowrap: {
        whiteSpace: "nowrap",
    },
    rightAlign: {
        textAlign: "right",
        marginRight: 15,
    },
    flex: {
        flex: 1,
    },
    printDivider: {
        height: 8,
        backgroundColor: "rgba(0, 0, 0, 0.42)",
        marginTop: 16,
        marginBottom: 8,
    },
    dialogMin: {
        minWidth: 400,
    },
    minWidth: {
        minWidth: 600,
    },
    root: {
        flexBasis: "100%",
        maxWidth: "100%",
    },
});

function MAKE_OPTIONS(table) {
    return table.map((row) => ({
        label: row,
        value: row,
        linkhref: row.linkhref,
        linkname: row,
        help:row.help
    }));
}
function MAKE_OPTIONS_LINK(table) {
    return table.map((row) => ({
        label: row.label,
        value: row.value,
        linkhref: row.linkhref,
        linkname: row.linkname,
        help:row.help
    }));
}


const getApplication = createSelector(
    (state, ownProps) => ownProps.match.params["hash"],
    (session, hash) => {
        return session.NineMileGrant.filter((p) => p.hash === hash).toModelArray()[0] 
      
    }
);

//sucks cant use this as it works intermittently as the hydration of the store doesnt happen always in sync so doing manually in componentdidmount
// const getHistoric = createSelector(
//     (state, ownProps) => ownProps.match.params["hash"],
//     (session, hash) => {
//         return session.NineMileGrant.filter((p) => p.hash === hash).toModelArray()
//         .map((ninemilegrant)=>({
//             ninemilegrantinprogressreporting:ninemilegrant.ninemilegrantinprogressreporting
//             .all()
//             .orderBy('id','desc')
//             .toModelArray()
//             .map((ninemilegrantinprogressreporting) => ({   
//                 ninemilegrantinprogressexpense: ninemilegrantinprogressreporting.ninemilegrantinprogressexpense
//                 .all()
//                 .orderBy("id")
//                 .toRefArray(),
            
//                //pull both models as seperate keys
//                 ninemilegrantinprogressattachment: ninemilegrantinprogressreporting.ninemilegrantinprogressattachment
//                 .all()
//                 .orderBy("id")
//                 .toRefArray(),  
//                 ...ninemilegrantinprogressreporting.ref,
//             })),
//             ninemilegrantannualreporting:ninemilegrant.ninemilegrantannualreporting
//             .all()
//             .orderBy('id','desc')
//             .toModelArray()
//             .map((ninemilegrantannualreporting)=>({ 
//                //pull both models as seperate keys
//                 ninemilegrantannualattachment: ninemilegrantannualreporting.ninemilegrantannualattachment
//                 .all()
//                 .orderBy("id")
//                 .toRefArray(), 
//                 ...ninemilegrantannualreporting.ref,
//             })),
//             ...ninemilegrant.ref
//         }))
      
//     }
// );

class NineMileGrant_ extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0, 
            formKey: null,
            order: "desc",
            orderBy: null,
            temp_contacts: [],
            attachmentDialogOpen: false,
            attachmentEditing: null,
            attachmentFormKey: null,
            attachmentOrder: "desc",
            attachmentOrderBy: null,
            temp_attachments: [],
            loading: false,
            new_save: false,
            new_save2: false,
            mainFormKey: null,
            entryError: false,
            thegeo:null,
            primaryContact:false,
            dialogLoading:false,
            alerts:"",
            outOfDistrict:false,
            address:null,
            city:null,
            zip:null,
            createPoint:false,
            hasAddress:true,
            waterbody:false,
            projectType:[],
            attachmentType:null,
            expenseEditing:null,
            tempExpenses:[],
            reimbursementEditing:null,
            tempReimbursements:[],
            reimbursementDialogOpen:false,
            reimbursementFormKey: null,
            expenseDialogOpen:false,
            expenseFormKey:null,
            calculation: {"requested": 0, "budget": 0},
            consentFromOwner:false,
            saveDialogOpen:false, 
            submittedSave:false,
            saveInfo:{owner_first_name:null, owner_last_name:null, owner_email:null}, 
            incompleteSave:false, 
            maintenceText: "I agree to maintain this project for five years following installation.",
            maxFunds:0,
            alertOpen:false,
            priorErrorsToClear:{},
            mainExplain:false,
            showProgressExtra:false,
            temp_reimbursement_attachments: [],
            attachmentReimbursementEditing: null,
            attachmentReimbursementDialogOpen:false,
            attachmentReimbursementFormKey: null, 
            new_save3:false,
            new_save4:false, 
            appData:null, 
            historicInprogressReporting:[],
            historicInprogressExpenses:[],
            historicInprogressAttachment:[],
            historicAnnualReporting:[],
            historicAnnualAttachment:[]
     
        };
    } 
    componentDidUpdate(prevProps, newProps){
        console.log("hit", prevProps, newProps)
    }

    componentDidMount() {
        const { match, ormNineMileGrantLoadDetailChild, ormNineMileGrantAttachmentLoadDetailChild,ormNineMileGrantExpenseLoadDetailChild, 
            ormNineMileGrantInprogressReportingLoadDetailChild, ormNineMileGrantInprogressExpenseLoadDetailChild, ormNineMileGrantInprogressAttachmentLoadDetailChild,
            ormNineMileGrantAnnualReportingLoadDetailChild, ormNineMileGrantAnnualAttachmentLoadDetailChild} = this.props;
        var component = this;
        // const {temp_attachments} = this.state
        // let newAttachments = temp_attachments
        
        if (match.params && match.params.hash) {
       
            ormNineMileGrantLoadDetailChild(match.params.hash, function (hmm) {
               
                let maintenceText = hmm[0].requested_funds <= 5000 ? "I agree to maintain this project for five years following installation."
                 : "I agree to maintain this project for ten years following installation." 

                //custom text for help bubble under financial 'requested funds field
                let tempFunds = 0
                let grantType = hmm[0].grant_type
                if (hmm[0].rules && (hmm[0].rules === 'Native Habitat Restoration' || hmm[0].rules === 'Native Planting')){
                    if (grantType){
                        if(grantType === 'Residential'){
                            tempFunds = 3000.00
                        }else if (grantType === 'School' || grantType === 'Nonprofit' || grantType === 'Business' || grantType === 'City'){
                            tempFunds = 10000.00
                        }else if (grantType === 'Association'){
                            tempFunds = 5000.00
                        } 
                    } 
                }else{ //all other grant types
                    if (grantType){
                        if(grantType === 'Residential'){
                            tempFunds = 5000.00
                        }else if (grantType === 'School' || grantType === 'City' || grantType === 'Nonprofit' || grantType === 'Business'){
                            tempFunds = 50000.00
                        }else if (grantType === 'Association'){
                            tempFunds = 20000.00
                        }   
                    }    
                } 

                component.setState({appData:hmm[0], projectType:hmm[0].rules, waterbody: hmm[0].wetland_present, maintenceText, mainExplain:hmm[0].main_explain , maxFunds:tempFunds, mainFormKey: window.performance.now() })
            });
            ormNineMileGrantExpenseLoadDetailChild(match.params.hash, function (data) {
                component.setState({ tempExpenses: data });
            });
            ormNineMileGrantAttachmentLoadDetailChild(match.params.hash, function (data) { 
                // data.forEach(att=>{ // we do this as we need the images that were saved previously 'id' key to not resave them again. 
                //         let temp = {}
                //         temp['id'] = 'blabla'
                //         temp['file'] = att.file
                //         temp['name'] = att.name
                //         temp['_type'] = att._type
                //         newAttachments.push(temp) 
                //     }) 
                component.setState({ temp_attachments: data });
            })  
            ormNineMileGrantInprogressReportingLoadDetailChild(match.params.hash, function (data) { 
                let tempReimburse = {}
                let totalmatch = 0 
                let totalreimb = 0 
                let totalhrs = 0  
                data.forEach(att=>{ // we do this as we need what were saved previously 'id' key to not resave them again. 
                    if(totalmatch === 0)totalmatch = att.total_match 
                    if(totalreimb === 0)totalreimb = att.total_reimbursement   
                    if(totalhrs === 0)totalhrs = att.total_hrs     
                }) 
                if(data.length > 0){
                    tempReimburse['id'] = -1
                    tempReimburse['description'] = 'Total Prior Reimbursement Requests'
                    tempReimburse['requested_amount'] = totalreimb
                    tempReimburse['match'] = totalmatch
                    tempReimburse['labor_match'] = totalhrs    
                    component.setState((bla)=>({ tempReimbursements: [tempReimburse], historicInprogressReporting:data}));
                } 

                setTimeout(()=>{
                component.formApi.setValue('total_hrs',totalreimb) 
                component.formApi.setValue('total_reimbursement',totalreimb) 
                component.formApi.setValue('total_match',totalmatch)  
                },1100)
                 
            })
            ormNineMileGrantInprogressExpenseLoadDetailChild(match.params.hash, function (data) {  
                component.setState({ historicInprogressExpenses: data });  
            })
            ormNineMileGrantInprogressAttachmentLoadDetailChild(match.params.hash, function (data) {  
                component.setState({ historicInprogressAttachment: data });  
            }) 
            ormNineMileGrantAnnualReportingLoadDetailChild(match.params.hash, function (data) {  
                component.setState({ historicAnnualReporting: data });  
            })
            ormNineMileGrantAnnualAttachmentLoadDetailChild(match.params.hash, function (data) {  
                component.setState({ historicAnnualAttachment: data });  
            })              
        }
        if (match.params && match.params.print) {
            component.setState({ activeStep: "PRINT" });
        }
    }


    submitApplication = (values, what) => {
        const { 
            ormApplicationRecordCreate,
            ormApplicationRecordStatusCreate,
            ormNineMileGrantCreate,
            ormNineMileGrantAttachmentCreate,
            ormNineMileGrantUpdatePublic,
            ormNineMileGrantExpenseCreate,
            ormNineMileGrantExpenseAppUpdate,
            ormNineMileGrantInprogressReportingCreate, 
            ormNineMileGrantInprogressAttachmentCreate,
            ormNineMileGrantAnnualReportingCreate,
            ormNineMileGrantAnnualAttachmentCreate ,
            ormNineMileGrantInprogressExpenseCreate
        } = this.props;
        const { temp_attachments, thegeo, alerts, tempExpenses, tempReimbursements,temp_reimbursement_attachments, appData  } = this.state;

        const _this = this;
        
        if(!alerts && what !== 'calcresave') this.setState({ loading: true }); //used for attachment alerts to not go into loading
        
        let created = 0
        let totalAttachments = tempExpenses.length + temp_attachments.length 
        let hasntSaved = false

        // Editing from a prior save but hasnt been submitted onto the internal ms4front side
        if (appData) { 
            if(what === "inprogress"){
                let _this = this
                ormNineMileGrantInprogressReportingCreate({
                    ninemilegrant_id:values.id,
                    ...values

                }).then(rec=>{
                    let total = tempReimbursements.length + temp_reimbursement_attachments.length
                    let add = 0
                    tempReimbursements.forEach(r=>{
                        if(r.id === -1){
                            //skip this as its a prior entry
                            total = total - 1
                        }else{
                            ormNineMileGrantInprogressExpenseCreate({
                                ninemilegrantinprogressreporting_id:rec.id,
                                ...r
            
                            }).then(a=>{
                                add += 1
                                if(add === total)_this.setState({ loading: false, new_save3: true });
                            }) 
                        }
                    }) 
                    temp_reimbursement_attachments.forEach(r=>{
                        ormNineMileGrantInprogressAttachmentCreate({
                            ninemilegrantinprogressreporting_id:rec.id,
                            ...r 
                        }).then(a=>{
                            add += 1
                            if(add === total)_this.setState({ loading: false, new_save3: true });
                        }) 
                    })
                    //check for if there werent attachments or expenses just exit
                    if(add === total)_this.setState({ loading: false, new_save3: true });
                })
               


            }else if(what === "yearly"){
                
                let _this = this
                ormNineMileGrantAnnualReportingCreate({
                    ninemilegrant_id:values.id,
                    ...values
                }).then(rec=>{
                    let total = temp_reimbursement_attachments.length
                    let add = 0 
                    temp_reimbursement_attachments.forEach(r=>{
                        ormNineMileGrantAnnualAttachmentCreate({
                            ninemilegrantannualreporting_id:rec.id,
                            ...r 
                        }).then(a=>{
                            add += 1
                            if(add === total)_this.setState({ loading: false, new_save4: true });
                        }) 
                    })
                    //check for if there werent attachments or expenses just exit
                    if(add === total)_this.setState({ loading: false, new_save4: true });
                })


            }else if (what === 'calcresave'){//only when they save an application but didnt fully submit to internal side, if they delete a financial record we resave incase they forget to do so that calc fields stay in sync
                ormNineMileGrantUpdatePublic({
                    id: appData.id,
                    ...values
                })
            }else{//standard save 
                tempExpenses.forEach(function (tc) { 
                    if(!tc.id){//only save if havent been saved b4
                    ormNineMileGrantExpenseCreate({
                        ninemilegrant_id: appData.id,
                        ...tc,
                    }).then(()=>{
                        created++
                        if(created === totalAttachments && !hasntSaved){ // we save status or submit to pending only when all attachments are saved
                            ormApplicationRecordStatusCreate({
                                applicationrecord: appData.apprec.id,
                                status: what === 'Save Not Completed' ? 'Save Not Completed' :"Pending"
                            }).then(statusrecord => {
                                ormNineMileGrantUpdatePublic({
                                    id: appData.id,
                                    ...values
                                })
                                if(what === 'Save Not Completed') _this.setState({ loading: false, incompleteSave: true });
                                else _this.setState({ loading: false, new_save: true });
                                hasntSaved = true
                            })
                        }
                    })
                    }else{
                        ormNineMileGrantExpenseAppUpdate({ 
                            ...tc,
                        }).then(()=>{
                            created++
                            if(created === totalAttachments && !hasntSaved){ //we have to do this as we might have skipped saving a prior attachment not fond of but its needed to be in sync
                                ormApplicationRecordStatusCreate({
                                    applicationrecord: appData.apprec.id,
                                    status: what === 'Save Not Completed' ? 'Save Not Completed' :"Pending"
                                }).then(statusrecord => {
                                    ormNineMileGrantUpdatePublic({
                                        id: appData.id,
                                        ...values
                                    })
                                    if(what === 'Save Not Completed') _this.setState({ loading: false, incompleteSave: true });
                                    else _this.setState({ loading: false, new_save: true });
                                    hasntSaved = true
                                }) 
                            }
                        })
                        
                    }  
                });


                temp_attachments.forEach(function (tc) { 
                    if (!tc.id){//only save if it hasnt been saved before 
                        ormNineMileGrantAttachmentCreate({
                            ninemilegrant_id: appData.id,
                            ...tc,
                        }).then(()=>{
                            created++
                            if(created === totalAttachments && !hasntSaved){ // we save status or submit to pending only when all attachments are saved
                                ormApplicationRecordStatusCreate({
                                    applicationrecord: appData.apprec.id,
                                    status: what === 'Save Not Completed' ? 'Save Not Completed' :"Pending"
                                }).then(statusrecord => {
                                    ormNineMileGrantUpdatePublic({
                                        id: appData.id,
                                        ...values
                                    })
                                    if(what === 'Save Not Completed') _this.setState({ loading: false, incompleteSave: true });
                                    else _this.setState({ loading: false, new_save: true });
                                    hasntSaved = true
                                })
                            }
                        })   
                    }else{
                        created++
                        if(created === totalAttachments && !hasntSaved){ //we have to do this as we might have skipped saving a prior attachment not fond of but its needed to be in sync
                            ormApplicationRecordStatusCreate({
                                applicationrecord: appData.apprec.id,
                                status: what === 'Save Not Completed' ? 'Save Not Completed' :"Pending"
                            }).then(statusrecord => {
                                ormNineMileGrantUpdatePublic({
                                    id: appData.id,
                                    ...values
                                })
                                if(what === 'Save Not Completed') _this.setState({ loading: false, incompleteSave: true });
                                else _this.setState({ loading: false, new_save: true });
                                hasntSaved = true
                            })
                        }
                    }
                }); 


                if (totalAttachments === 0){ //no attachements so save or submit right away
                    ormApplicationRecordStatusCreate({
                        applicationrecord: appData.apprec.id,
                        status: what === 'Save Not Completed' ? 'Save Not Completed' :"Pending"
                    }).then(statusrecord => {
                        ormNineMileGrantUpdatePublic({
                            id: appData.id,
                            ...values
                        })
                        if(what === 'Save Not Completed') _this.setState({ loading: false, incompleteSave: true });
                        else _this.setState({ loading: false, new_save: true });
                    })
                }  
            }
        } else {
            if(!alerts){
                // New
                ormApplicationRecordCreate({
                    application: 14,
                }).then((ar) => { 
                    //we create first even though status wont be set yet until below which 
                    //will trigger the internal creation of the module record
                    ormNineMileGrantCreate({
                        applicationrecord: ar.id,
                        geometry: thegeo ? thegeo : null,
                        ...values,
                    }).then((rpb) => {
                        if (rpb && rpb.error) {
                            this.setState({ entryError: true, loading: false });
                            fetch("/error/post", {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify({
                                    error: JSON.stringify(values),
                                    error_info: rpb.error.toString(),
                                }),
                            });
                        } else { 
                            tempExpenses.forEach(function (tc) {  // i++;
                                ormNineMileGrantExpenseCreate({
                                    ninemilegrant_id: rpb.id,
                                    ...tc,
                                }).then(()=>{
                                    created++
                                    if (created === totalAttachments && !hasntSaved){//we want to make sure all attachments are created before setting the status 
                                        ormApplicationRecordStatusCreate({
                                            applicationrecord: ar.id,
                                            status: what === 'Save Not Completed' ? 'Save Not Completed' :"Pending"
                                        }).then(statusrecord => {
                                            ormNineMileGrantUpdatePublic({
                                                id:rpb.id,
                                                ...values
                                            })  
                                            if(what === 'Save Not Completed') _this.setState({ loading: false, incompleteSave: true });
                                            else _this.setState({ loading: false, new_save: true });
                                            hasntSaved = true
                                        })  
                                    }
                                }) 
                            });
                            temp_attachments.forEach(function (tc) { 
                                ormNineMileGrantAttachmentCreate({
                                    ninemilegrant_id: rpb.id,
                                    ...tc,
                                }).then(()=>{
                                    created++
                                    if (created === totalAttachments && !hasntSaved){ //we want to make sure all attachments are created before setting the status 
                                        ormApplicationRecordStatusCreate({
                                            applicationrecord: ar.id,
                                            status: what === 'Save Not Completed' ? 'Save Not Completed' :"Pending"
                                        }).then(statusrecord => {
                                            ormNineMileGrantUpdatePublic({
                                                id:rpb.id,
                                                ...values
                                            })  
                                            if(what === 'Save Not Completed') _this.setState({ loading: false, incompleteSave: true });
                                            else _this.setState({ loading: false, new_save: true });
                                            hasntSaved = true
                                        })  
                                    } 
                                }) 
                            }); 

                            //no attachments just go save or submit
                            if (totalAttachments === 0){
                                ormApplicationRecordStatusCreate({
                                    applicationrecord: ar.id,
                                    status: what === 'Save Not Completed' ? 'Save Not Completed' :"Pending"
                                }).then(statusrecord => {
                                    ormNineMileGrantUpdatePublic({
                                        id:rpb.id,
                                        ...values
                                    })  
                                    if(what === 'Save Not Completed') _this.setState({ loading: false, incompleteSave: true });
                                    else _this.setState({ loading: false, new_save: true });
                                })  
                            }
                        }
                    });
                });
            }
        }
    };
  
    errorValidator = (values) => { 
        const { submittedSave, waterbody, temp_attachments, tempExpenses, priorErrorsToClear, hasAddress, mainExplain, temp_reimbursement_attachments}  = this.state;

        const isRequired = (val) => {
            return !val ? "Required" : null;
        };      
        const YesisRequired = (val) => {
            return val !== 'Yes' ? "Required" : null;
        };
        const validateEmail = (email) => {
            var re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !re.test(email) ? "Invalid Email Address" : null;
        };
    
        var valObj = {}
        var alerts = "";
        var ai = 8;

        if (!submittedSave){//we are just saving not submitting final so dont validate with this part
            if (!values.secondary_first_name){//property owner wasnt filled out make sure they have prop owner permisson
                if (values.primary_contact){ 
                    valObj = { 
                        owner_first_name: isRequired(values.owner_first_name),
                        owner_last_name: isRequired(values.owner_last_name),
                        owner_mailing_address: isRequired(values.owner_mailing_address),
                        owner_phone: isRequired(values.owner_phone),
                        owner_city: isRequired(values.owner_city),
                        owner_state: isRequired(values.owner_state),
                        owner_zip: isRequired(values.owner_zip),
                        owner_email: validateEmail(values.owner_email), 
                        
                        project_city: isRequired(values.project_city),
                        project_name: isRequired(values.project_name),
                        grant_type: isRequired(values.grant_type),
                        rules: isRequired(values.rules),
                        proposed_work: isRequired(values.proposed_work),  
                        wetland_present: isRequired(values.wetland_present),
                        project_timeline: isRequired(values.project_timeline),
                        ed_share: isRequired(values.ed_share),
                        ed_can_share: isRequired(values.ed_can_share),
                        ed_highlight: isRequired(values.ed_highlight), 
                        signature: isRequired(values.signature), 
                    
                    }
                }else{ //not primary contact so check that they have consent_from_owner
                    valObj = {
                        consent_from_owner: isRequired(values.consent_from_owner),
                        owner_first_name: isRequired(values.owner_first_name),
                        owner_last_name: isRequired(values.owner_last_name),
                        owner_mailing_address: isRequired(values.owner_mailing_address),
                        owner_phone: isRequired(values.owner_phone),
                        owner_city: isRequired(values.owner_city),
                        owner_state: isRequired(values.owner_state),
                        owner_zip: isRequired(values.owner_zip),
                        owner_email: validateEmail(values.owner_email), 
                        project_city: isRequired(values.project_city),
                        project_name: isRequired(values.project_name),
                        grant_type: isRequired(values.grant_type),
                        rules: isRequired(values.rules),
                        proposed_work: isRequired(values.proposed_work),  
                        wetland_present: isRequired(values.wetland_present),
                        project_timeline: isRequired(values.project_timeline),
                        ed_share: isRequired(values.ed_share),
                        ed_can_share: isRequired(values.ed_can_share),
                        ed_highlight: isRequired(values.ed_highlight), 
                        signature: isRequired(values.signature), 
                    }; 
                }
            }else{//property owner was filled out
                valObj = { 
                    owner_first_name: isRequired(values.owner_first_name),
                    owner_last_name: isRequired(values.owner_last_name),
                    owner_mailing_address: isRequired(values.owner_mailing_address),
                    owner_phone: isRequired(values.owner_phone),
                    owner_city: isRequired(values.owner_city),
                    owner_state: isRequired(values.owner_state),
                    owner_zip: isRequired(values.owner_zip),
                    owner_email: validateEmail(values.owner_email),
                     
                    project_city: isRequired(values.project_city),
                    project_name: isRequired(values.project_name),
                    grant_type: isRequired(values.grant_type),
                    rules: isRequired(values.rules),
                    proposed_work: isRequired(values.proposed_work),  
                    wetland_present: isRequired(values.wetland_present), 
                    project_timeline: isRequired(values.project_timeline), 
                    ed_share: isRequired(values.ed_share),
                    ed_can_share: isRequired(values.ed_can_share),
                    ed_highlight: isRequired(values.ed_highlight), 
                    signature: isRequired(values.signature), 
                
                }
            }
            let tempObj = {}, attachmentsNeeded = []
            if (values && values.rules && values.rules[0] === 'Rain Garden'){
                tempObj = {
                    water_curr_flow_to: isRequired(values.water_curr_flow_to),
                    water_flow_from: isRequired(values.water_flow_from),
                    raingarden_depth: isRequired(values.raingarden_depth),
                    raingarden_surf_area_top: isRequired(values.raingarden_surf_area_top),
                    raingarden_surf_area_bottom: isRequired(values.raingarden_surf_area_bottom),
                    direct_raingarden: isRequired(values.direct_raingarden),
                    hydroloic_group: isRequired(values.hydroloic_group),
                    rain_reimbursment: isRequired(values.rain_reimbursment)
                }
                attachmentsNeeded = ['Aerial Image', 'Raingarden Planting'] 
                temp_attachments.forEach(at=>{
                    if(attachmentsNeeded.find(a=>a===at._type)){
                        attachmentsNeeded = attachmentsNeeded.filter(af=> af!==at._type? af:null)
                    }
                })
                Object.assign(valObj,tempObj) //add onto orig object
            }else if(values && values.rules && values.rules[0] === 'Pervious Pavement'){
                tempObj = {
                    pervious_flow_to: isRequired(values.pervious_flow_to),
                    pervious_flow_from: isRequired(values.pervious_flow_from), 
                }
                attachmentsNeeded = ['Pervious Pavement'] 
                temp_attachments.forEach(at=>{
                    if(attachmentsNeeded.find(a=>a===at._type)){
                        attachmentsNeeded = attachmentsNeeded.filter(af=> af!==at._type? af:null)
                    }
                })
                Object.assign(valObj,tempObj)
            }
            else if(values && values.rules && values.rules[0] === 'Shoreline Buffer'){
                tempObj = {
                    buff_protect: isRequired(values.buff_protect),
                    buff_width: isRequired(values.buff_width), 
                    buff_plant: isRequired(values.buff_plant), 
                }
                attachmentsNeeded = ['Shoreline Buffer', 'Planting Layout'] 
                temp_attachments.forEach(at=>{
                    if(attachmentsNeeded.find(a=>a===at._type)){
                        attachmentsNeeded = attachmentsNeeded.filter(af=> af!==at._type? af:null)
                    }
                })
                Object.assign(valObj,tempObj)
            }
            else if(values && values.rules && values.rules[0] === 'Green Roof'){
                tempObj = {
                    gr_sqft: isRequired(values.gr_sqft),
                    gr_plants: isRequired(values.gr_plants), 
                }
                attachmentsNeeded = ['Green Roof'] 
                temp_attachments.forEach(at=>{
                    if(attachmentsNeeded.find(a=>a===at._type)){
                        attachmentsNeeded = attachmentsNeeded.filter(af=> af!==at._type? af:null)
                    }
                })
                Object.assign(valObj,tempObj)
            }else if((values && values.rules && values.rules[0] === 'Native Habitat Restoration') || (values && values.rules && values.rules[0] === 'Native Planting')){
                tempObj = {
                    native_plant: isRequired(values.native_plant),
                    native_area: isRequired(values.native_area), 
                    native_invasive: isRequired(values.native_invasive), 
                }
                attachmentsNeeded = ['Native Planting'] 
                temp_attachments.forEach(at=>{
                    if(attachmentsNeeded.find(a=>a===at._type)){
                        attachmentsNeeded = attachmentsNeeded.filter(af=> af!==at._type? af:null)
                    }
                })
                Object.assign(valObj,tempObj)
            }
            else if((values && values.rules && values.rules[0] === 'Groundwater Conservation')){
                tempObj = {
                    gw_gallons_saved: isRequired(values.gw_gallons_saved),
                    gw_water_collection: isRequired(values.gw_water_collection),  
                }
                Object.assign(valObj,tempObj)
            }
            else if((values && values.rules && values.rules[0] === 'Chloride Reduction')){
                tempObj = {
                    chloride_address: isRequired(values.chloride_address),
                    chloride_reduce: isRequired(values.chloride_reduce),  
                    chloride_outreach: isRequired(values.chloride_outreach),
                    chloride_estimate: isRequired(values.chloride_estimate), 
                    chloride_percentage: isRequired(values.chloride_percentage),  
                }
                Object.assign(valObj,tempObj)
            }
            if (waterbody === 'Yes'){
                tempObj = {}
                tempObj = {
                    waterbody_name: isRequired(values.waterbody_name),
                    waterbody_type: isRequired(values.waterbody_type),
                }
                Object.assign(valObj,tempObj)
            }
            if (hasAddress){
                tempObj = {}
                tempObj = {
                    project_address: isRequired(values.project_address), 
                }
                Object.assign(valObj,tempObj)
            }
            if (!mainExplain){ 
                tempObj = {}
                tempObj = {
                    main_report: YesisRequired(values.main_report),
                    main_maintain: isRequired(values.main_maintain),
                    main_agree: isRequired(values.main_agree),
                }
                Object.assign(valObj,tempObj)
            }  

            if (values && values.apprec && values.apprec.status === 'open'){
                tempObj = {}
                tempObj = {
                    check_made_to: isRequired(values.check_made_to),
                    check_mailed_to: isRequired(values.check_mailed_to),
                    summary: isRequired(values.summary),
                    attachment: temp_reimbursement_attachments.length < 1 ? 'Required' : ''
                } 

                let finalRequest = values.final_request
                if(finalRequest === 'No' && temp_reimbursement_attachments.length < 1){
                    tempObj['attachment'] = 'Required'
                    alerts += `A total of 1 Attachment Files are Required (Step 10)\n`;
                    ai = 9   
                }else if (finalRequest === 'Yes' && temp_reimbursement_attachments.length < 3){ 
                    tempObj['attachment'] = 'Required'
                    alerts += `A total of 3 Attachment Files are Required (Step 10)\n`;
                    ai = 9   
                }
                if (finalRequest === 'Yes'){
                    let t={}
                    t = {
                        describe_project: isRequired(values.describe_project),
                        education: isRequired(values.education),
                        project_outcome: isRequired(values.project_outcome),
                        project_challenges: isRequired(values.project_challenges),
                        project_longevity: isRequired(values.project_longevity),
                    } 
                    tempObj = {...tempObj, ...t}
                }
                Object.assign(valObj,tempObj) 
            }
            if (values && values.apprec && values.apprec.status === 'closed'){
                tempObj = {}
                tempObj = {
                    annual_summary: isRequired(values.annual_summary),
                    summary_main: isRequired(values.summary_main),
                    main_concerns: isRequired(values.main_concerns),
                    comments: isRequired(values.comments),
                    attachment: temp_reimbursement_attachments.length < 3 ? 'Required' : ''
                } 
                if(temp_reimbursement_attachments.length < 3){
                    tempObj['attachment'] = 'Required'
                    alerts += `A total of 3 Attachment Files are Required (Step 11)\n`;
                    ai = 10   
                }
                Object.assign(valObj,tempObj) 
            }
 
            if (valObj["consent_from_owner"] === "Required") {
                alerts += "Consent from Owner is required if not the Property Owner (Step 2)\n";
                ai = 1;
            }
            if (valObj["owner_first_name"] === "Required") {
                alerts += "Applicant First Name (Step 2)\n";
                ai = 1;
            }
            if (valObj["owner_last_name"] === "Required") {
                alerts += "Applicant Last Name (Step 2)\n";
                ai = 1;
            }
            if (valObj["owner_mailing_address"] === "Required") {
                alerts += "Applicant Mailing Address (Step 2)\n";
                ai = 1;
            }
            if (valObj["owner_city"] === "Required") {
                alerts += "Applicant City (Step 2)\n";
                ai = 1;
            }
            if (valObj["owner_state"] === "Required") {
                alerts += "Applicant State (Step 2)\n";
                ai = 1;
            }
            if (valObj["owner_zip"] === "Required") {
                alerts += "Applicant Zip (Step 2)\n";
                ai = 1;
            }
            if (valObj["owner_phone"] === "Required") {
                alerts += "Applicant Phone (Step 2)\n";
                ai = 1;
            }
            if (valObj["owner_email"] === "Invalid Email Address") {
                alerts += "Applicant Email (Step 2)\n";
                ai = 1;
            } 
          
            if (valObj["project_address"] === "Required") {
                alerts += "Project Address (Step 3)\n";
                ai = 2;
            }
            if (valObj["project_city"] === "Required") {
                alerts += "Project City (Step 3)\n";
                ai = 2;
            }
            if (valObj["project_name"] === "Required") {
                alerts += "Project Name (Step 4)\n";
                ai = 3;
            }
            if (valObj["proposed_work"] === "Required") {
                alerts += "Proposed Work (Step 4)\n";
                ai = 3;
            }
    
            if (valObj["grant_type"] === "Required") {
                alerts += "Grant Type(Step 4)\n";
                ai = 3;
            }
            if (valObj["rules"] === "Required") {
                alerts += "Project Type (Step 4)\n";
                ai = 3;
            }     
            if (valObj["wetland_present"] === "Required") {
                alerts += "Project next to a waterbody (Step 4)\n";
                ai = 3;
            }
            if (valObj["waterbody_name"] === "Required") {
                alerts += "Waterbody Name (Step 4)\n";
                ai = 3;
            }
            if (valObj["waterbody_type"] === "Required") {
                alerts += "Waterbody Type (Step 4)\n";
                ai = 3;
            }
            if (valObj["project_timeline"] === "Required") {
                alerts += "Project Timeline (Step 4)\n";
                ai = 3;
            }
            //raingarden
            if (valObj["water_curr_flow_to"] === "Required") {
                alerts += "Water flow to (Step 5)\n";
                ai = 4;
            }
            if (valObj["water_curr_flow_from"] === "Required") {
                alerts += "Water flow from (Step 5)\n";
                ai = 4;
            }
            if (valObj["raingarden_depth"] === "Required") {
                alerts += "Raingarden depth (Step 5)\n";
                ai = 4;
            }
            if (valObj["raingarden_surf_area_top"] === "Required") {
                alerts += "Raingarden top surface area (Step 5)\n";
                ai = 4;
            } if (valObj["raingarden_surf_area_bottom"] === "Required") {
                alerts += "Raingarden bottom surface area (Step 5)\n";
                ai = 4;
            }
            if (valObj["direct_raingarden"] === "Required") {
                alerts += "Direct water from raingarden? (Step 5)\n";
                ai = 4;
            }
            if (valObj["hydroloic_group"] === "Required") {
                alerts += "Hydroloic Group (Step 5)\n";
                ai = 4;
            } 
            if (valObj["rain_reimbursment"] === "Required") {
                alerts += "Reimbursement only to native plants or native cultivars.(Step 5)\n";
                ai = 4;
            }

            //shorelinebuffer
            if (valObj["buff_protect"] === "Required") {
                alerts += "Shoreline buffer protect (Step 5)\n";
                ai = 4;
            }
            if (valObj["buff_plant"] === "Required") {
                alerts += "Plant buffer?(Step 5)\n";
                ai = 4;
            }
            if (valObj["buff_width"] === "Required") {
                alerts += "Buffer width? (Step 5)\n";
                ai = 4;
            }

            //pervious pavement
            if (valObj["pervious_flow_to"] === "Required") {
                alerts += "Pervious flow to (Step 5)\n";
                ai = 4;
            }
            if (valObj["pervious_flow_from"] === "Required") {
                alerts += "Pervious flow from (Step 5)\n";
                ai = 4;
            }
            //green roof
            if (valObj["gr_sqft"] === "Required") {
                alerts += "Green roof sqft (Step 5)\n";
                ai = 4;
            }
            if (valObj["gr_plants"] === "Required") {
                alerts += "Green roof plants (Step 5)\n";
                ai = 4;
            }
             //native planting 
            if (valObj["native_area"] === "Required") {
                alerts += "Size of area (Step 5)\n";
                ai = 4;
            }
            if (valObj["native_invasive"] === "Required") {
                alerts += "Native invasive (Step 5)\n";
                ai = 4;
            } 
            if (valObj["native_plant"] === "Required") {
                alerts += "Reimbursement only to native plants (Step 5)\n";
                ai = 4;
            }
            //groundwater conservation
            if (valObj["gw_gallons_saved"] === "Required") {
                alerts += "Gallons saved (Step 5)\n";
                ai = 4;
            }
            if (valObj["gw_water_collection"] === "Required") {
                alerts += "Groundwater collection (Step 5)\n";
                ai = 4;
            }
            //chloride
            if (valObj["chloride_address"] === "Required") {
                alerts += "Chloride address (Step 5)\n";
                ai = 4;
            }
            if (valObj["chloride_reduce"] === "Required") {
                alerts += "Chloride reduce (Step 5)\n";
                ai = 4;
            } if (valObj["chloride_outreach"] === "Required") {
                alerts += "Chloride outreach (Step 5)\n";
                ai = 4;
            }
            if (valObj["chloride_estimate"] === "Required") {
                alerts += "Chloride estimate (Step 5)\n";
                ai = 4;
            }
            if (valObj["chloride_percentage"] === "Required") {
                alerts += "Chloride percentage? (Step 5)\n";
                ai = 4;
            }
        
            if(attachmentsNeeded.length > 0){
                attachmentsNeeded.forEach(at=>{ 
                    alerts += `${at} File Required (Step 5)\n`;
                    ai = 4
                })
            }
            if(tempExpenses.length === 0){
                valObj['temp_attachment'] = 'Required'
                alerts += `At least one expense required (Step 6)\n`;
                ai = 5
            }else{
                valObj['temp_attachment'] = ''
            }

            if (valObj["ed_share"] === "Required") {
                alerts += "How will you share (Step 7)\n";
                ai = 7
            }
            if (valObj["ed_can_share"] === "Required") {
                alerts += "Can we share you project (Step 7)\n";
                ai = 7
            }
            if (valObj["ed_highlight"] === "Required") {
                alerts += "Education highlight (Step 7)\n";
                ai = 7
            }

            if (valObj["main_report"] === "Required") {
                alerts += "Maintenance report (Step 8)\n";
                ai = 8
            }
            if (valObj["main_maintain"] === "Required") {
                alerts += "Maintain maintain (Step 8)\n";
                ai = 8
            }
            if (valObj["main_agree"] === "Required") {
                alerts += "Maintain agree (Step 8)\n";
                ai = 8
            }
            if (valObj["signature"] === "Required") {
                alerts += "I agree (Step 9)\n";
                ai = 8
            }
            //inprogress
            if (valObj["check_made_to"] === "Required") {
                alerts += "Check made to (Step 10)\n";
                ai = 9
            }
            if (valObj["check_mailed_to"] === "Required") {
                alerts += "Check mailed to (Step 10)\n";
                ai = 9
            }
            if (valObj["summary"] === "Required") {
                alerts += "Summary of activities (Step 10)\n";
                ai = 9
            }
            if (valObj["describe_project"] === "Required") {
                alerts += "Describe project (Step 10)\n";
                ai = 9
            }
            if (valObj["education"] === "Required") {
                alerts += "Education (Step 10)\n";
                ai = 9
            }
            if (valObj["project_outcome"] === "Required") {
                alerts += "Project outcome (Step 10)\n";
                ai = 9
            }
            if (valObj["project_challenges"] === "Required") {
                alerts += "Project challenges (Step 10)\n";
                ai = 9
            }
            if (valObj["project_longevity"] === "Required") {
                alerts += "Project longevity (Step 10)\n";
                ai = 9
            }
             
            //closed
            if (valObj["annual_summary"] === "Required") {
                alerts += "Summary of Project Performance (Step 11)\n";
                ai = 10
            }
            if (valObj["summary_main"] === "Required") {
                alerts += "Summary of Activities (Step 11)\n";
                ai = 10
            }
            if (valObj["main_concerns"] === "Required") {
                alerts += "Maintenance Concerns (Step 11)\n";
                ai = 10
            }
            if (valObj["comments"] === "Required") {
                alerts += "Additional Comments (Step 11)\n";
                ai = 10
            }
        
            if (alerts) {
                this.setState({ activeStep: ai, alerts: alerts, alertOpen:true, priorErrorsToClear:valObj });
            }else{
                this.setState({alerts:alerts, priorErrorsToClear:valObj})
            } 

        }else{//just saving so just check name and emails
            valObj = { 
                owner_first_name: isRequired(values.owner_first_name),
                owner_last_name: isRequired(values.owner_last_name),  
                owner_email: validateEmail(values.owner_email), 
            } 
            alerts = "";
            ai = 0; 
            if (valObj["owner_first_name"] === "Required") {
                alerts += "First Name \n";
                ai = 1;
            }
            if (valObj["owner_last_name"] === "Required") {
                alerts += "Last Name\n";
                ai = 1;
            }
            if (valObj["owner_email"] === "Invalid Email Address") {
                alerts += "Email\n";
                ai = 1;
            }
            if (alerts) { 
                this.setState({ alerts: alerts, activeStep: ai, alertOpen:true });
            }else{
                // occurs if they try to submit first memoized form then save only as has those fields as problems so we need to clear the keys else reactform wont submit
                if(JSON.stringify(priorErrorsToClear) !== '{}'){
                    Object.keys(priorErrorsToClear).forEach(key=>{priorErrorsToClear[key] = null}) 
                    this.setState({alerts:alerts, priorErrorsToClear})
                    valObj = {...priorErrorsToClear}
                }
                
            } 

            this.setState({submittedSave:false})
        }

        return valObj;
    };

    errorValidatorAttachment = (values) => {

        const isRequired = (val) => {
            return !val ? "Required" : null;
        };
        var valObj = {
            file: isRequired(values.file),
            name: isRequired(values.name),
        };

        return valObj;
    };
    errorValidatorExpense = (values) => {
        const {maxFunds, tempExpenses, expenseEditing} = this.state
       
        let temp = [...tempExpenses] //copy
        //we will copy all the table values into a temp array of objs to then use for making sure they didnt go 
        //over the allowed 'Requested' field amount. This will also stop them from adding more
        if (!expenseEditing) { //not editing a rec?
            temp.push(values);
        } else {
            let temp1 = temp.filter((tc) => tc !== expenseEditing); 
            temp1.push(values); 
            temp = [...temp1] //copy as its new
        }

        //get the array of object values to recalc for requested validation
        let requested = 0  
        var val = 0 
        for (const obj of temp){ 
            for (const key in obj){ 
                val = obj[key] ? obj[key]: 0 
                if(key === 'nmcwd_grant'){ 
                    requested = evaluate(`${val} + ${requested}`)
                } 
            }
        }  

        const isRequired = (val) => {
            return !val ? "Required" : null;
        };
        const checkTotal = (val) => { 
            if(maxFunds === 0){
                return "Error: verify that a 'project type' and 'grant type' is selected on step 4 before adding expenses."
            }else{
                return requested > maxFunds ? `Total requested expenses can not exceed ${maxFunds}.` : null;
            }
           
        };
        var valObj = { 
            item_name: isRequired(values.item_name),
            nmcwd_grant: checkTotal(requested)
        };

        return valObj;
    };
    errorValidatorReimbursement = (values) => {
        const {appData } = this.state
        
        const checkTotal = (val) => { 
            if(val){ 
                let priorRequested = this.formApi.getValue('total_reimbursement')
                return (parseFloat(priorRequested) + parseFloat(val)) > appData.approved_funds ? `You were awarded a $${appData.approved_funds} cost share grant. You cannot request reimbursement for more than this amount.` : null;
            } 
        };

        var valObj = { 
            requested_amount: checkTotal(values.requested_amount), 
        };

        return valObj;
    };
   
    addAttachment = (values) => {
        var { attachmentEditing, temp_attachments, attachmentType } = this.state;

        //for images to show under correct areas
        if (attachmentType && attachmentType === 'Aerial Image'){
            values['_type'] = 'Aerial Image'
        }
        else if (attachmentType && attachmentType === 'Raingarden Planting'){
            values['_type'] = 'Raingarden Planting'
        }
        else if (attachmentType && attachmentType === 'Shoreline Buffer'){
            values['_type'] = 'Shoreline Buffer'
        }
        else if (attachmentType && attachmentType === 'Planting Layout'){
            values['_type'] = 'Planting Layout'
        }
        else if (attachmentType && attachmentType === 'Native Planting'){
            values['_type'] = 'Native Planting'
        }
        else if (attachmentType && attachmentType === 'Pervious Pavement'){
            values['_type'] = 'Pervious Pavement'
        }
        else if (attachmentType && attachmentType === 'Chloride Reduction'){
            values['_type'] = 'Chloride Reduction'
        }
        else if (attachmentType && attachmentType === 'Green Roof'){
            values['_type'] = 'Green Roof'
        }

        if (!attachmentEditing) {
            temp_attachments.push(values);
            this.setState({ temp_attachments: temp_attachments });
        } else {
            var temp = temp_attachments.filter((tc) => tc !== attachmentEditing);
            temp.push(values);
            this.setState({ temp_attachments: temp });
        }
          
        this.setState({ attachmentDialogOpen: false, attachmentType:null });
    };

    addReimbursementAttachment = (values) => {
        var { attachmentReimbursementEditing, temp_reimbursement_attachments} = this.state;

        if (!attachmentReimbursementEditing) {
            temp_reimbursement_attachments.push(values);
            this.setState({ temp_reimbursement_attachments});
        } else {
            var temp = temp_reimbursement_attachments.filter((tc) => tc !== attachmentReimbursementEditing);
            temp.push(values);
            this.setState({ temp_reimbursement_attachments: temp });
        }
          
        this.setState({ attachmentReimbursementDialogOpen: false });
    };
  
    addExpense = (values) => {
        var { expenseEditing, tempExpenses } = this.state;
       
        let temp = [...tempExpenses]
 
        // on first filter wont see but will just push it so it gets added.
   
        let temp1 = temp.filter((tc) => tc !== expenseEditing); 
        temp1.push(values);
        this.setState({ tempExpenses: temp1 });

      
        this.calculateRepeatable()
        this.setState({ expenseDialogOpen: false });
    };

    //only for inprogress step #10
    addReimbusement = (values) => {
        var { reimbursementEditing, tempReimbursements} = this.state;
       
        let temp = [...tempReimbursements]
 

        let temp1 = temp.filter((tc) => tc !== reimbursementEditing); 
        temp1.push(values);
        this.setState({ tempReimbursements: temp1 });
      
        this.calculateReimbursements()
        this.setState({ reimbursementDialogOpen: false });
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.state.tempExpenses.sort((a, b) => (b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1))
            : this.state.tempExpenses.sort((a, b) => (a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1));

        this.setState({ order, orderBy });
    };

    handleRequestSort2 = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.state.temp_attachments.sort((a, b) => (b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1))
            : this.state.temp_attachments.sort((a, b) => (a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1));

        this.setState({ order, orderBy });
    };

    handleNext = (index) => {
        const { activeStep, appData } = this.state;
       

        if (index || index === 0) {
            this.setState({ activeStep: index, dialogLoading: index === 2 && activeStep !== 2 ? true : false });
        } else if (activeStep < 8 || (activeStep === 8 && appData && appData.apprec.status !== 'Save Not Completed')){ //if on step 8 again and there is an application just proceed to next step
            this.setState({ activeStep: activeStep + 1 }); 
        }else if(activeStep === 8) {//first applying
            this.formApi.submitForm();
        }else if (activeStep === 9){//inprogress reporting
            this.formApi.submitForm("inprogress");
        }else{
            this.formApi.submitForm("yearly");//yearly
        }
    };

    //save the geometry from the map
    saveAddress=(geometry, address)=>{
   
        this.formApi.setValue("project_address",address.StAddr)
        this.formApi.setValue("project_city",address.City) //if city isnt within list it wont show on dropdown
        this.formApi.setValue("project_zip",address.Postal)
    
        let geojson = {
            type: "Point",
            coordinates: [geometry.longitude,geometry.latitude]
        };

        //check if within boundary
        fetch(`
            https://services9.arcgis.com/FsMUjHd4ZYdso5QZ/ArcGIS/rest/services/NMCWD_Legal_Boundary/FeatureServer/0/query?where=1=1&objectIds=&time=&geometry=${geometry.longitude},${geometry.latitude}
            &geometryType=esriGeometryPoint&inSR=4326&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Meter&relationParam=&returnGeodetic=false&outFields
            =&returnGeometry=true&returnCentroid=false&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision
            =&outSR=&defaultSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false
            &returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false
            &returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pgeojson&token=`,
            {
                method : "GET"

            })
            .then(resp=> resp.json())
            .then(data=>{
               if(data.features.length === 0){
                this.setState({outOfDistrict:true})
               }
        })
        this.setState({thegeo:geojson})
  
    }

    //check address and city for proper geometry point
    checkAddress = (e) =>{ 
        const {formApi} = this;
   
        let city = formApi.getValue("project_city")
        let address = formApi.getValue("project_address")
        let zip = formApi.getValue("project_zip")
        if (city && address && zip) this.setState({city,address, zip, createPoint:true})

    }

    setPoint=()=>{
        this.setState({createPoint:false})
    }

    setDialogLoading = (e) =>{
        this.setState({dialogLoading:e})
    }
 
    calculateReimbursements = () =>{
        const { tempReimbursements } = this.state
      
        let requested = 0 
        let match = 0
        let laborMatch  = 0 
        let val = null 
        //get the array of object values to recalc
        for (const obj of tempReimbursements){ 
            for (const key in obj){ 
                val = obj[key] ? obj[key]: 0
              
                if(key === 'requested_amount'){
                    requested = evaluate(`${val} + ${requested}`)
                }
                else if(key === 'match'){
                    match = evaluate(`${val} + ${match}`)
                }
                else if(key === 'labor_match'){ //total hrs
                    laborMatch = evaluate(`${val} + ${laborMatch}`)
                }      
            }
        }
        //match field which is total hours *$20/hr   
        match = evaluate(`(${laborMatch} * 20) + ${match}`) 

        this.formApi.setValue('total_hrs',laborMatch) 
        this.formApi.setValue('total_reimbursement',requested) 
        this.formApi.setValue('total_match',match)  
    } 

    //sum nmc field with the match field for every repeatable expense line item
    calculateRepeatable = () =>{
        const { tempExpenses } = this.state
     
        let percent = 0
        let requested = 0 
        let match = 0
        let laborMatch  = 0
        let projBudget = 0
        var val = 0
        //get the array of object values to recalc
        for (const obj of tempExpenses){ 
            for (const key in obj){ 
                val = obj[key] ? obj[key]: 0
              
                if(key === 'nmcwd_grant'){
                    requested = evaluate(`${val} + ${requested}`)
                }
                else if(key === 'match'){
                    match = evaluate(`${val} + ${match}`)
                }
                else if(key === 'labor_match'){ //total hrs
                    laborMatch = evaluate(`${val} + ${laborMatch}`)
                }       
            }
        }
   
        //total project budget calculate 
        projBudget = evaluate(`${requested} + ${match} + (${laborMatch} * 20)`) 
    

       //match field which is total hours *$20/hr   
        match = evaluate(`(${laborMatch} * 20) + ${match}`) 
        
        
        percent = evaluate(`${match} / ${projBudget}`)   
        if(isNaN(percent)) percent = 0
        this.formApi.setValue('requested_funds',requested)
        this.formApi.setValue('project_budget',projBudget)
        this.formApi.setValue('match_dollars',match) 
        this.formApi.setValue('match_percent',percent.toFixed(2)) 
  
    }           

    defaultValues =()=>{
        const {appData} = this.state
        
        let obj = {} 
        obj = {...appData}
        if(appData && appData.apprec.status === 'open'){ // add inprogress reporting keys as we are on that step   
            obj['check_made_to'] = '' 
            obj['check_mailed_to'] = ''
            obj['summary'] = ''
            obj['final_request'] = 'No'
            obj['describe_project'] = '' 
            obj['project_outcome'] = ''
            obj['project_challenges'] = ''
            obj['project_longevity'] = '' 
            obj['total_reimbursement'] = ''
            obj['total_match'] = ''
            obj['attachment'] = ''

        }
        else if(appData && appData.apprec.status === 'closed'){ // add closed reporting keys as we are on that step   
            obj['annual_summary'] = '' 
            obj['summary_main'] = ''
            obj['main_concerns'] = ''
            obj['comments'] = ''  
            obj['attachment'] = ''
        }
        return obj
    }

  
   

    render() {
        const { classes, ormNineMileGrantAttachmentDelete, ormNineMileGrantExpenseDelete} = this.props;
        const {
            activeStep,
            attachmentDialogOpen,
            attachmentEditing,
            attachmentFormKey, 
            temp_attachments,
            loading,
            new_save,
            mainFormKey,
            new_save2,
            new_save3,
            new_save4,
            entryError,
            primaryContact,
            dialogLoading,
            outOfDistrict,
            city,
            address,
            zip,
            createPoint,
            hasAddress,
            waterbody,
            projectType,
            attachmentType,
            expenseDialogOpen,
            tempExpenses,
            expenseEditing,
            expenseFormKey,
            order,
            orderBy,
            consentFromOwner,
            saveDialogOpen, 
            saveInfo,
            incompleteSave,
            maintenceText,
            maxFunds,
            alertOpen,  
            alerts,
            mainExplain,
            tempReimbursements,
            reimbursementEditing,
            reimbursementDialogOpen,
            reimbursementFormKey,
            showProgressExtra,
            temp_reimbursement_attachments,  
            attachmentReimbursementDialogOpen,
            attachmentReimbursementEditing,
            attachmentReimbursementFormKey, 
            appData,
            historicInprogressReporting,
            historicInprogressExpenses,
            historicInprogressAttachment,
            historicAnnualReporting,
            historicAnnualAttachment
        } = this.state

        //this has all parameters for the basemapcontrolapplication
        var layers = [{attribution: "", city_id: 14,city_label: "Nine Mile Creek Watershed District",id: 22, is_basemap: false,
                        layer_type: "ArcGIS", layernumbers: "0", layerurl: "https://services9.arcgis.com/FsMUjHd4ZYdso5QZ/arcgis/rest/services/NMCWD_Legal_Boundary/FeatureServer/0",
                        name: "NMCWD Boundary",on_by_default: true,opacity: 1,order: 0,proxyurl: "",show_popup: false, subdomains: "",
                        tileddynamic: "Feature Service",token: "",webmap_id: null, wmslayers: "", x: -93.44004, y: 44.875283,
                        geocoder_left:[], geocoder_bottom:[], geocoder_right:[],geocoder_top:[], map_level:11 },
                        {attribution: "", city_id: 14,city_label: "Nine Mile Creek Watershed District",id: 22, is_basemap: false,
                        layer_type: "ArcGIS", layernumbers: "1", layerurl: "https://services9.arcgis.com/FsMUjHd4ZYdso5QZ/ArcGIS/rest/services/Adopt_a_Creek_2023_Map_WFL1/FeatureServer/3",
                        name: "City Boundary",on_by_default: true,opacity: 1,order: 1,proxyurl: "",show_popup: false, subdomains: "",
                        tileddynamic: "Feature Service",token: "",webmap_id: null, wmslayers: "", x: -93.44004, y: 44.875283,
                        geocoder_left:[], geocoder_bottom:[], geocoder_right:[],geocoder_top:[], map_level:11 } ]

        var is_read_only = false;
        var inprogressReportingReadOnly = false
        var annualReportingReadOnly = false;
        if (
            appData &&
            appData.apprec &&
            (   appData.apprec.status === "Incomplete" ||
                appData.apprec.status === "Complete and Under Review" ||
                appData.apprec.status === "Pending" ||
                appData.apprec.status === "Turned Down" ||
                appData.apprec.status === "Approved" ||
                appData.apprec.status === "open" ||
                appData.apprec.status === "closed" 
            )
        )
            is_read_only = true;

        if(appData && appData.apprec.status === "open"){
            annualReportingReadOnly = true
        }else if(appData && appData.apprec.status === 'closed' && appData.main_status === 'active'){
            inprogressReportingReadOnly = true
        }else if(appData && appData.apprec.status === 'closed' && appData.main_status === 'complete'){
            inprogressReportingReadOnly = true
            annualReportingReadOnly = true
        }


        var columnDataAttachments = [
            { id: "actions", numeric: false, label: "", allowSort: false, width: "20px" },
            { id: "name", numeric: false, label: "Name", allowSort: true },
            { id: "_type", numeric: false, label: "Type", allowSort: true },
            { id: "notes", numeric: false, label: "Notes", allowSort: true },
        ];
        var columnReimbursementAttachments = [
            { id: "actions", numeric: false, label: "", allowSort: false, width: "20px" },
            { id: "name", numeric: false, label: "Name", allowSort: true }, 
            { id: "notes", numeric: false, label: "Notes", allowSort: true },
        ];
        var expenseHeader = [
            { id: "actions", numeric: false, label: "", allowSort: false, width: "20px" },
            { id: "item_name", numeric: false, label: "Item Description/task", allowSort: true },
            { id: "nmcwd_grant", numeric: false, label: "Requested Funds ($)", allowSort: true },
            { id: "match", numeric: false, label: "Monetary Match ($)", allowSort: true },  
            { id: "labor_match", numeric: false, label: "Labor Match (hrs)", allowSort: true },  

        ]
        var reimbursementHeader = [
            { id: "actions", numeric: false, label: "", allowSort: false, width: "20px" },
            { id: "item_name", numeric: false, label: "Item Description/task", allowSort: true },
            { id: "nmcwd_grant", numeric: false, label: "Requested Funds ($)", allowSort: true },
            { id: "match", numeric: false, label: "Monetary Match ($)", allowSort: true },   
            { id: "labor_match", numeric: false, label: "Labor Match (hrs)", allowSort: true }, 
        ]
     
        if (incompleteSave) {
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "12" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10}>
                            <Typography>
                                This is a confirmation that the grant application has been saved, but not yet submitted to Nine Mile Creek Watershed District. A copy of the grant application
                                has been emailed to the applicant's email address which will include a link to finish the application later.
                                Please check your spam and junk folders if you have not received it or contact the District. 
                            </Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }
        
        if (new_save) {
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "12" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10}>
                            <Typography>
                                This is a confirmation that the grant application has been submitted successfully. A copy of the grant application
                                has been emailed to the applicant's email address. Please check your spam and junk folders if you have
                                not received it or contact the district. The district will contact you as the application is being reviewed.
                            </Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }
        if (entryError) {
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "12" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10}>
                            <Typography>
                                This grant application was 'not' successfully saved. Please contact the grant 'managing' staff to alert them of the
                                entry failure.
                            </Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }

        if (new_save2) {
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "12" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10}>
                            <Typography>This is a confirmation that the grant application has been resubmitted successfully.</Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }
        if (new_save3) {
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "12" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10}>
                            <Typography>This is a confirmation that the grant reimbursement has been submitted successfully.</Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }
        if (new_save4) {
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "12" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10}>
                            <Typography>This is a confirmation that the grants annual report has been submitted successfully.</Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }
        if (activeStep === "PRINT")
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "12" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10}>
                            <Typography>Grant Printed.</Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
   
        let options = [{
            value: "Rain Garden", 
            label: "Rain Garden",
        },{
            value: "Shoreline Buffer", 
            label: "Shoreline Buffer",
        },{
            value: "Pervious Pavement", 
            label: "Pervious Pavement",
        },{
            value: "Native Habitat Restoration", 
            label: "Native Habitat Restoration",
            help: "Native Habitat Restoration projects involve the removal of invasive species.",
        },{
            value: "Native Planting", 
            label: "Native Planting",
            help: "Native plantings are projects that establish native habitat, but do not involve invasive species removal.",
        },{
            value: "Green Roof", 
            label: "Green Roof",
        },{
            value: "Groundwater Conservation", 
            label: "Groundwater Conservation",
        },{
            value: "Chloride Reduction", 
            label: "Chloride Reduction",
            help:"**Optional** to upload supporting documents"
        },{
            value: "Other", 
            label: "Other",
        }]
        
        var reportingSteps = []
        if(appData && appData.apprec.status === 'open'){
            reportingSteps = ["Instructions","Contact Info","Project Location","Project Details","Project Type",
                "Financial","Education","Maintenance","Review and Submit", "In-Progress Reporting",
            ]
        } else if(appData && appData.apprec.status === 'closed' && (appData.main_status === 'active' || appData.main_status === 'complete')){
            reportingSteps = ["Instructions","Contact Info","Project Location","Project Details","Project Type",
                "Financial","Education","Maintenance","Review and Submit", "In-Progress Reporting","Annual Reporting",
            ]
        } else{
            reportingSteps = ["Instructions","Contact Info","Project Location","Project Details","Project Type",
            "Financial","Education","Maintenance","Review and Submit", 
        ]
        }   

        return (
            <AppContainerApplication
                city_id={"14"} //window.location.origin.includes("ms4prod") ? "45" : "2"}
                page_loading={loading}
                printing={activeStep === "PRINT"}>
                {activeStep === 2 && (//DUMB but did this way as clicking on seach on map would bubble form to trigger submit so pulled out
                <> 
                    {appData && appData.apprec && appData.apprec.status && (
                        <Grid container spacing={1} style={{ backgroundColor: "#a3a3a3", marginTop: -15 }}>
                            <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                <Typography className={classes.centerAlign} variant="h6">
                                    Grant Status: {appData && appData.apprec.status}{appData && appData.apprec.status === 'open'? " - fill out progress report step 10": ""}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                            <Typography className={classes.centerAlign} variant="h6">
                                Grant Application Form {appData && appData.grant_number && "#" + appData.grant_number}
                            </Typography>{" "}
                            <Grid item xs={12} xl={10} className={classes.rightAlign} classes={{ root: classes.root }}></Grid>
                         
                            <Typography variant="caption" className={classes.centerAlign2}>
                                Required fields are marked with an asterisk (*)
                            </Typography>
                        </Grid> 
                        <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                            <Stepper style={{ padding: 8 }} alternativeLabel nonLinear activeStep={activeStep}>
                                {reportingSteps.map((label, index) => {
                                    return (
                                        <Step key={label}>
                                            <StepButton style={{ cursor: "pointer" }} onClick={() => this.handleNext(index)}>
                                                {label}
                                            </StepButton>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </Grid>  
                        {!appData && (
                            <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                <Typography variant="subtitle1" gutterBottom style={{marginBottom:-7}}>
                                    Type in an address within the search widget to auto populate the project site address and city.<b>{" "} Note: valid addresses are within the boundary area shown on the map.</b>
                                </Typography>
                            </Grid>
                        )}
                    </Grid>

                    <Grid item xs={12} xl={10} style={{ marginLeft: 15, marginRight:15 }} classes={{ root: classes.root }}>
                        <BaseMapControlApplication
                            geometry={appData && appData.geometry?appData.geometry:null}
                            saveAddress={this.saveAddress}
                            disableSearch={appData && appData.geometry?true:false}
                            viewOnly
                            application={appData}
                            setDialogLoading={this.setDialogLoading}
                            address={address}
                            city={city}
                            zip={zip}
                            createPoint={createPoint}
                            setPoint={this.setPoint}
                            layers={layers}
                            cityID={"14"}
                        /> 
                        
                        {dialogLoading && ( 
                            <CircularProgress
                                size={72}
                                style={{ display: "table", marginLeft: "auto", marginRight: "auto", zIndex:90000 }}
                            /> 
                        )}
                    </Grid> 
                
                    </>
                )}   
                <Form
                    getApi={(el) => (this.formApi = el)}
                    defaultValues={this.defaultValues()}
                    key={mainFormKey}
                    dontValidateOnMount={true}
                    validateOnSubmit={true}
                    validateError={(values) => this.errorValidator(values)}
                    onSubmit={(values, e) => this.submitApplication(values, e)}>
                    {(formApi) => (
                        <form onSubmit={formApi.submitForm}>
                            <>
                                {appData && appData.apprec && appData.apprec.status && activeStep !== 2 && (
                                    <Grid container spacing={1} style={{ backgroundColor: "#a3a3a3", marginTop: -15 }}>
                                        <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                            <Typography className={classes.centerAlign} variant="h6">
                                                Grant Status: {appData && appData.apprec.status}{appData && appData.apprec.status === 'open'? " - fill out progress report step 10": ""}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                                <Grid container spacing={1} style={{ padding: "0 12px" }}>
                                {activeStep !== "PRINT" && activeStep !== 2 &&  (
                                    <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                        <Typography className={classes.centerAlign} variant="h6">
                                            Grant Application Form {appData && appData.grant_number && "#" + appData.grant_number}
                                        </Typography>{" "}
                                        <Grid item xs={12} xl={10} className={classes.rightAlign} classes={{ root: classes.root }}></Grid>
                                     
                                        <Typography variant="caption" className={classes.centerAlign2}>
                                            Required fields are marked with an asterisk (*)
                                        </Typography>
                                    </Grid>
                                )}
                                    {activeStep !== "PRINT" && activeStep !== 2 &&  (
                                        <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                            <Stepper style={{ padding: 8 }} alternativeLabel nonLinear activeStep={activeStep}>
                                                {reportingSteps.map((label, index) => {
                                                    return (
                                                        <Step key={label}>
                                                            <StepButton style={{ cursor: "pointer" }} onClick={() => this.handleNext(index)}>
                                                                {label}
                                                            </StepButton>
                                                        </Step>
                                                    );
                                                })}
                                            </Stepper>
                                        </Grid>
                                    )}
                                    {activeStep === 0 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Typography variant="subtitle1">
                                                <b>
                                                    {"1. "}
                                                    <u>Preparation</u>
                                                </b>
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                {`\u2022`} Please read the {" "}<a
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            href="https://www.ninemilecreek.org/wp-content/uploads/Guidelines-Cost-Share-Grants.pdf">
 
                                                            Cost Share Grant Guidelines
                                                        </a>{" "}before you start your application. 
                                                The guidelines provide important information about the District’s cost share grants.
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                {`\u2022`} You do not need to fill out this cost share grant 
                                                application in one sitting—you can save your progress after step 2 and a link with your partially filled form will be emailed to you.  
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                {`\u2022`} There are several required fields throughout this application which are marked with an asterisk (*), you will not be able to submit your form until they are filled out.
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>  
                                                {`\u2022`} If you have trouble filling out this form, reach out to Lizzy for help {" "} 
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="mailto:eboor@ninemilecreek.org">
                                                    eboor@ninemilecreek.org
                                                </a>{" "}; 952-248-0410  
                                            </Typography>
                                            <br />
                                            <Typography variant="subtitle1">
                                                <b>
                                                    {"2. "}
                                                    <u>Submittal</u>
                                                </b>
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                {`\u2022`} Applications will be reviewed monthly by a grant committee after which you will be notified if your project was selected for funding.
                                            </Typography>
                                            <br />  
                                        </Grid>
                                    )}
                                    {activeStep === 1 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Typography>Please add contacts associated with the application.</Typography>
                                            <Accordion defaultExpanded>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        Applicant
                                                    </Typography>
                                                    
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="owner_first_name"
                                                                label="First Name *"
                                                                fullWidth
                                                                eventHandle={(e)=>{
                                                                let t = saveInfo
                                                                t['owner_first_name'] = e
                                                                this.setState({saveInfo:t})
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="owner_last_name"
                                                                label="Last Name *"
                                                                fullWidth
                                                                eventHandle={(e)=>{
                                                                    let t = saveInfo
                                                                    t['owner_last_name'] = e
                                                                    this.setState({saveInfo:t})
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="owner_organization"
                                                                label="Organization"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="owner_title"
                                                                label="Title"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="owner_mailing_address"
                                                                label="Mailing Address *"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                         
                                                            <Select
                                                                disabled={is_read_only}
                                                                field="owner_city"
                                                                label="City *"
                                                                options={ MAKE_OPTIONS([
                                                                    "Bloomington","Eden Prairie","Edina","Hopkins","Minnetonka","Richfield"
                                                                ])}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Select
                                                                disabled={is_read_only}
                                                                field="owner_state"
                                                                label="State *"
                                                                options={ [{
                                                                    "label": "Minnesota",
                                                                    "value": "MN"
                                                                }]}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="owner_zip" label="Zip *" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="owner_phone" label="Phone *" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField 
                                                                disabled={is_read_only} 
                                                                field="owner_email" 
                                                                label="Email *" 
                                                                fullWidth    
                                                                eventHandle={(e)=>{
                                                                    let t = saveInfo
                                                                    t['owner_email'] = e
                                                                    this.setState({saveInfo:t})
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion defaultExpanded>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        Property Owner
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <Checkbox disabled={is_read_only}  label="Property owner same as applicant?" field="primary_contact" eventHandle={(e)=>{
                                                                this.setState({primaryContact:e, consentFromOwner:e}) 
                                                                this.formApi.setValue('secondary_first_name', '')
                                                                this.formApi.setValue('secondary_last_name', '')
                                                                this.formApi.setValue('secondary_organization', '')
                                                                this.formApi.setValue('secondary_title', '')
                                                                this.formApi.setValue('secondary_mailing_address', '')
                                                                this.formApi.setValue('secondary_state', '')
                                                                this.formApi.setValue('secondary_zip', '')
                                                                this.formApi.setValue('secondary_phone', '')
                                                                this.formApi.setValue('secondary_email', '')
                                                                this.formApi.setValue('secondary_city', '')
                                                            }}/>   
                                                        </Grid> 
                                                        <Grid item xs={6}>
                                                        {!consentFromOwner && ( 
                                                            <Grid item xs={6}>
                                                                <Checkbox disabled={is_read_only}        
                                                                label="I have permission from the property owner to apply on their behalf? *"
                                                                field="consent_from_owner"
                                                                />
                                                            
                                                            </Grid>   
                                                        )}
                                                        {consentFromOwner &&(
                                                        <Grid item xs={6}>
                                                         </Grid>   
                                                        )}
                                                        </Grid>   
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only || primaryContact}
                                                                field="secondary_first_name"
                                                                label="First Name"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only || primaryContact}
                                                                field="secondary_last_name"
                                                                label="Last Name"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only  || primaryContact}
                                                                field="secondary_organization"
                                                                label="Organization"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                         <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only  || primaryContact}
                                                                field="secondary_title"
                                                                label="Title"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only  || primaryContact}
                                                                field="secondary_mailing_address"
                                                                label="Mailing Address"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Select
                                                                disabled={is_read_only || primaryContact}
                                                                field="secondary_city"
                                                                label="City "
                                                                options={ MAKE_OPTIONS([
                                                                    "Bloomington","Eden Prairie","Edina","Hopkins","Minnetonka","Richfield"
                                                                ])}
                                                                fullWidth
                                                            />
                                                          
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Select
                                                                disabled={is_read_only  || primaryContact}
                                                                field="secondary_state"
                                                                label="State"
                                                                options={  [{
                                                                    "label": "Minnesota",
                                                                    "value": "MN"
                                                                }]}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only  || primaryContact} field="secondary_zip" label="Zip" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only  || primaryContact} field="secondary_phone" label="Phone" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only  || primaryContact} field="secondary_email" label="Email" fullWidth />
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>{" "} 
                                            
                                        </Grid>
                                    )}
                                    {activeStep === "PRINT" && (
                                        <>
                                            <div style={{ height: 50 }}></div>
                                            <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                                <Divider className={classes.printDivider} />
                                            </Grid>
                                        </>
                                    )}
                                    {activeStep === 2 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}> 
                                            <Typography variant="subtitle1" gutterBottom>
                                                <b>Please Provide Project Information</b>
                                            </Typography>
                                            <Grid container spacing={2}> 
                                                <Grid container spacing={2} style={{ padding: 10 }}>
                                                    <Grid item xs={6}>
                                                        <Checkbox disabled={is_read_only} label="Project doesn't have an address?" field="doesnt_have_address" eventHandle={(e)=>{
                                                            this.setState({hasAddress:!e})
                                                        }} />   
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                    </Grid>
                                                    {hasAddress &&(<>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                label={
                                                                    <HelpLabel
                                                                        inputLabel="Project Address. *"
                                                                        helpText="Address of site.
                                                                        "
                                                                    />
                                                                }
                                                                field="project_address"
                                                                disabled={is_read_only}
                                                                fullWidth
                                                            
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Select
                                                                disabled={is_read_only}
                                                                field="project_city"
                                                                label="City Name. *"
                                                                options={MAKE_OPTIONS([
                                                                    "Bloomington","Eden Prairie","Edina","Hopkins","Minnetonka","Richfield", "Minneapolis"
                                                                ])}
                                                                fullWidth
                                                                
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                label="Project Zip." 
                                                                field="project_zip"
                                                                disabled={is_read_only}
                                                                fullWidth
                                                            
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}> 
                                                        </Grid>
                                                    </>  
                                                    )}
                                                    {!hasAddress &&(
                                                        <>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    label={
                                                                        <HelpLabel
                                                                            inputLabel="Street Name. "
                                                                            helpText="If your project is occurring along a street, please include the name here.
                                                                            "
                                                                        />
                                                                    }
                                                                    field="project_address"
                                                                    disabled={is_read_only}
                                                                    fullWidth
                                                                
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Select
                                                                    disabled={is_read_only}
                                                                    field="project_city"
                                                                    label="City Name. *"
                                                                    options={MAKE_OPTIONS([
                                                                        "Bloomington","Eden Prairie","Edina","Hopkins","Minnetonka","Richfield","Minneapolis"
                                                                    ])}
                                                                    fullWidth
                                                                    
                                                                />
                                                            </Grid>     
                                                            <Grid item xs={6}>
                                                            <TextField
                                                                    label={
                                                                        <HelpLabel
                                                                            inputLabel="Starting Roadway."
                                                                            helpText="If your project is occurring along a street, please include the bounding crossroads of the project.
                                                                            "
                                                                        />
                                                                    }
                                                                    field="roadway1"
                                                                    disabled={is_read_only}
                                                                    fullWidth
                                                                
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                            <TextField
                                                                    label={
                                                                        <HelpLabel
                                                                            inputLabel="Ending Roadway."
                                                                            helpText="If your project is occurring along a street, please include the bounding crossroads of the project.
                                                                            "
                                                                        />
                                                                    }
                                                                    field="roadway2"
                                                                    disabled={is_read_only}
                                                                    fullWidth
                                                                
                                                                />
                                                            </Grid>
                                                            
                                                        </>
                                                    )}
                                                    <Grid item xs={6}>
                                                        <Button
                                                            onClick={() => this.checkAddress()}
                                                            disabled={is_read_only}
                                                            variant="contained"
                                                            color="primary"
                                                            style={{ marginLeft: 16 }}>
                                                            Verify Address
                                                        </Button>
                                                    </Grid>
                                                </Grid>   
                                            </Grid>
                                        </Grid>
                                    )}
                                    {activeStep === "PRINT" && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Divider className={classes.printDivider} />
                                        </Grid>
                                    )}   
                                    {activeStep === 3 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15, marginTop:5 }} classes={{ root: classes.root }}>  
                                            <Grid container spacing={2}>   
                                                <Grid item xs={6}>
                                                    <TextField
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Project Name*"
                                                                helpText="Please name your project; ex: Johnson Pocket Planting.
                                                                "
                                                            />
                                                        }
                                                        field="project_name"
                                                        disabled={is_read_only}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6}style={{paddingTop:13}}>
                                                    <ReactSelect
                                                        label="Grant Type *"
                                                        field="grant_type"
                                                        options={MAKE_OPTIONS([
                                                            "Residential",
                                                            "School",
                                                            "Nonprofit",
                                                            "Business",
                                                            "City",
                                                            "Association", 
                                                        ])}
                                                        disabled={is_read_only}
                                                        // isMulti={true}
                                                        height="auto"
                                                        fullWidth
                                                        eventHandle={grantType=>{
                                                            let tempFunds = 0
                                                            let rules = this.formApi.getValue('rules')
                                                            if (rules && (rules[0] === 'Native Habitat Restoration' || rules[0] === 'Native Planting')){
                                                                if (grantType){
                                                                    if(grantType === 'Residential'){
                                                                        tempFunds = 3000.00
                                                                    }else if (grantType === 'School' || grantType === 'Nonprofit' || grantType === 'Business' || grantType === 'City'){
                                                                        tempFunds = 10000.00
                                                                    }else if (grantType === 'Association'){
                                                                        tempFunds = 5000.00
                                                                    } 
                                                                } 
                                                            }else{ //all other grant types
                                                                if (grantType){
                                                                    if(grantType === 'Residential'){
                                                                        tempFunds = 5000.00
                                                                    }else if (grantType === 'School' || grantType === 'City' || grantType === 'Nonprofit' || grantType === 'Business'){
                                                                        tempFunds = 50000.00
                                                                    }else if (grantType === 'Association'){
                                                                        tempFunds = 20000.00
                                                                    }   
                                                                }    
                                                            }
                                                            this.setState({maxFunds: tempFunds}) 
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <br></br> 
                                            <CheckboxGroup
                                                field="rules"
                                                label="Project Type (select one) *"
                                                row={true}
                                                width={205}
                                                disabled={is_read_only}
                                                onlyAllowOne={true}
                                                options={MAKE_OPTIONS_LINK(options)} 
                                                eventHandle={(e) => {  
                                                    let tempFunds = 0
                                                    let grantType = this.formApi.getValue('grant_type')
                                                    if (e[0] === 'Native Habitat Restoration' || e[0] === 'Native Planting'){
                                                        if (grantType){
                                                            if(grantType === 'Residential'){
                                                                tempFunds = 3000.00
                                                            }else if (grantType === 'School' || grantType=== 'Nonprofit' || grantType=== 'Business' || grantType === 'City'){
                                                                tempFunds = 10000.00
                                                            }else if (grantType[0] === 'Association'){
                                                                tempFunds = 5000.00
                                                            } 
                                                        } 
                                                    }else{ //all other grant types
                                                        if (grantType){
                                                            if(grantType === 'Residential'){
                                                                tempFunds = 5000.00
                                                            }else if (grantType === 'School' || grantType === 'City' || grantType === 'Nonprofit' || grantType === 'Business'){
                                                                tempFunds = 50000.00
                                                            }else if (grantType === 'Association'){
                                                                tempFunds = 20000.00
                                                            }   
                                                        }    
                                                    } 
                                                    this.setState({projectType:e, maxFunds: tempFunds}) 
                                                }}
                                            /> 
                                            <br></br>
                                             <Grid item xs={12}>
                                                    <TextField
                                                        field="proposed_work"
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Proposed Work *"
                                                                helpText="Please describe your proposed project. Consider the following questions while answering: Why are you doing the project? How will the 
                                                                project either improve water quality, protect groundwater, or preserve/protect/restore native plant and wildlife habitats?
                                                                "
                                                            />
                                                        }
                                                        multiline
                                                        rows={8}
                                                        disabled={is_read_only}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <br></br>
                                                <Grid container spacing={2}>     
                                                    <Grid item xs={6}>
                                                        <RadioGroup
                                                            field="wetland_present"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                            options={[
                                                                { label: "Yes", value: "Yes" },
                                                                { label: "No", value: "No" }
                                                            ]}
                                                            alignment={true}
                                                            label="Is the project next to a waterbody? *"
                                                            eventHandle={(e)=>{
                                                                this.setState({waterbody:e})
                                                                if (e === "No") this.formApi.setValue('waterbody_type',"")
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}></Grid>
                                                    {waterbody === "Yes" &&(<>
                                                        <Grid item xs={6}>
                                                            <ReactSelect
                                                                label="Waterbody Type *"
                                                                field="waterbody_type"
                                                                options={MAKE_OPTIONS([
                                                                    "Nine Mile Creek",
                                                                    "Lake",
                                                                    "Wetland",
                                                                    "Stormwater Pond",
                                                                    "Pond", 
                                                                ])}
                                                                disabled={is_read_only}
                                                                isMulti={true}
                                                                height="auto"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                field="waterbody_name"
                                                                label="Waterbody Name *" 
                                                                disabled={is_read_only}
                                                                fullWidth
                                                            />
                                                      </Grid></>
                                                    )}
                                                </Grid>
                                                <br></br>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        field="project_timeline"
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Provide a project timeline with the major activities of your project *"
                                                                helpText="Native habitat restoration and native plantings have two years to complete their projects. All other projects have one year completion timelines.
                                                                "
                                                            />
                                                        }
                                                        multiline
                                                        rows={8}
                                                        disabled={is_read_only}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <br></br>
                                     
                                        </Grid>
                                     
                                    )}
                                    {activeStep === "PRINT" && (
                                        <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                            <Divider className={classes.printDivider} />
                                        </Grid>
                                    )}
                                    {activeStep === 4 && projectType.length === 0 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Typography variant="subtitle1" gutterBottom style={{textAlign: "center", textDecoration: "underline",fontWeight: "bold"}}>
                                                No Project Types have been selected. Refer to Step 4 and select a 'Project Type'
                                            </Typography>
                                        </Grid>
                                    )}
                                    {activeStep === 4 && projectType.length === 1 && projectType.find(t=>t==="Other") && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Typography variant="subtitle1" gutterBottom style={{textAlign: "center", textDecoration: "underline",fontWeight: "bold"}}>
                                                Project type 'other' was selected. No additional information is needed on this page
                                            </Typography>
                                        </Grid>
                                    )}
                                    {activeStep === 4 && projectType.length > 0 && projectType.find(t=>t==="Rain Garden") && (
                                        <Grid container spacing={2} style={{marginTop:10, marginLeft:15}}>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle1"><b><u>Rain Garden</u></b></Typography>          
                                            </Grid>
                                            <Grid item xs={12}> 
                                                <Checkbox disabled={is_read_only || mainExplain}  field="rain_reimbursment"  label={"Our raingarden grants will only reimburse you for native plants or native cultivars. Check this box to indicate you will only request reimbursement for native plants or native cultivars. *"}/>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <ReactSelect
                                                    label={ 
                                                        <Typography variant="subtitle1" gutterBottom >
                                                            Where does the water currently <b>flow to</b> that you plan to redirect to the raingarden? You may select more than one option. * 
                                                        </Typography> 
                                                    }
                                                    field="water_curr_flow_to"
                                                    options={MAKE_OPTIONS([
                                                        "Storm drain",
                                                        "Pond or other water body",
                                                        "Green space (grass/garden/forest etc.)",
                                                        "Impervious surface (driveway/street etc.)", 
                                                    ])}
                                                    disabled={is_read_only}
                                                    isMulti={true}
                                                    height="auto"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <ReactSelect 
                                                    label={
                                                        <Typography variant="subtitle1" gutterBottom >
                                                            Where does the water <b>flow from</b> that will enter the raingarden? You may select more than one option. * 
                                                        </Typography>    
                                                    } 
                                                    field="water_flow_from"
                                                    options={MAKE_OPTIONS([
                                                        "Green space (grass/garden/forest etc.)",
                                                        "Roof",
                                                        "Other impervious surface (driveway/street etc.)"
                                                    ])}
                                                    disabled={is_read_only}
                                                    isMulti={true}
                                                    height="auto"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <br></br>
                                            <Grid item xs={12}>
                                                <TextField
                                                    field="direct_raingarden"
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="How will you direct the water to the raingarden? *"
                                                            helpText="For example, the water might flow to the raingarden from downspouts, through pipes, or over the landscape.”
                                                            "
                                                        />
                                                    }
                                                    multiline
                                                    rows={8}
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <br></br>
                                            <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>

                                                <Typography variant="subtitle1"><b>Aerial Attachments *</b></Typography>
                                                <Typography gutterBottom>
                                                    Provide an attachment showing an aerial image of your site. 
                                                    Label the area of the raingarden, and mark areas which will drain to the raingarden (this includes pavement, greenspace, and roof areas). *  
                                                </Typography>
                                                <Grid container spacing={2} style={{ padding: 10 }}>
                                                    <Grid item xs={2} style={{display:"grid", paddingLeft:0,height:46}}>
                                                        <Button
                                                            onClick={() =>
                                                                this.setState({
                                                                    attachmentDialogOpen: true,
                                                                    attachmentEditing: null,
                                                                    attachmentFormKey: window.performance.now(),
                                                                    attachmentType: "Aerial Image"
                                                                })
                                                            }
                                                            // disabled={is_read_only}
                                                            variant="contained"
                                                            color="primary">
                                                            Add Attachment
                                                        </Button>  
                                                    </Grid> 
                                                </Grid> 
                                                <Table style={{ marginTop: 8 }}>
                                                    <EnhancedTableHead
                                                        columnData={columnDataAttachments} 
                                                    />
                                                    <TableBody>
                                                        {temp_attachments.map((n) => 
                                                            {return n._type === 'Aerial Image' && (
                                                                <TableRow hover>
                                                                    <CustomTableCell className={classes.nowrap}>
                                                                        {n.file.includes("https") && (
                                                                            <Tooltip title="Open Link">
                                                                                <Button
                                                                                    color="primary"
                                                                                    onClick={() => {
                                                                                        window.open(n.file);
                                                                                    }}
                                                                                    className={classes.deleteWidth}>
                                                                                    <ViewIcon color="primary" />
                                                                                </Button>
                                                                            </Tooltip>
                                                                        )}
                                                                        <Tooltip title="Delete">
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={() => {
                                                                                return(this.setState({
                                                                                        temp_attachments: temp_attachments.filter((tc) => tc !== n)
                                                                                    }),
                                                                                    ormNineMileGrantAttachmentDelete(n.id))
                                                                                    
                                                                                }
                                                                                }
                                                                                // disabled={is_read_only}
                                                                                className={classes.deleteWidth}>
                                                                                <DeleteIcon color="primary" />
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </CustomTableCell>
                                                                    <CustomTableCell>{n.name}</CustomTableCell>
                                                                    <CustomTableCell>{n._type}</CustomTableCell>
                                                                    <CustomTableCell>{n.notes}</CustomTableCell>
                                                                </TableRow>
                                                            
                                                            )})} 
                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                            <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                            <br></br>
                                            </Grid>
                                         
                                             
                                            <Grid item xs={12} style={{marginTop:-25}}>
                                                <TextField
                                                    field="hydroloic_group" 
                                                    label={
                                                        <Typography gutterBottom style={{paddingTop:10}}>What is the hydrologic group of the soil present at the location of the raingarden? Use the Hennepin County Natural Resource Interactive Map to find the answer. If the map says cut-and-fill or does not indicate a hydrologic group, report this. 
                                                            Because D soils have poor infiltration, raingardens on D soils without underdrains are not likely to be funded. {" "}
                                                            <a
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                href="https://gis.hennepin.us/naturalresources/">
                                                                Hennepin County Natural Resource Interactive Map *
                                                            </a>
                                                        </Typography>
                                                    }
                                                             
                                                    multiline
                                                    rows={8}
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="How deep is the raingarden in feet? *" 
                                                    field="raingarden_depth"
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="What is the surface area of the top of the raingarden in square feet? *" 
                                                    field="raingarden_surf_area_top"
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="What is the surface area of the bottom of the raingarden in square feet? *" 
                                                    field="raingarden_surf_area_bottom"
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>  
                                                <Typography variant="subtitle1"><b>Raingarden Planting Attachments *</b></Typography>
                                                <Typography gutterBottom>
                                                    Provide an attachment of your raingarden planting layout. The layout can be made by-hand or electronically. Note: Only plants native to MN are eligible for reimbursement. Other plants can be used, but costs must be claimed in the matching funds *  
                                                </Typography>
                                                <Grid container spacing={2} style={{ padding: 10 }}>
                                                    <Grid item xs={2} style={{display:"grid", paddingLeft:0,height:46}}>
                                                        <Button
                                                            onClick={() =>
                                                                this.setState({
                                                                    attachmentDialogOpen: true,
                                                                    attachmentEditing: null,
                                                                    attachmentFormKey: window.performance.now(),
                                                                    attachmentType: "Raingarden Planting"
                                                                })
                                                            }
                                                            // disabled={is_read_only}
                                                            variant="contained"
                                                            color="primary">
                                                            Add Attachment
                                                        </Button>  
                                                    </Grid> 
                                                </Grid> 
                                                <Table style={{ marginTop: 8 }}>
                                                    <EnhancedTableHead
                                                        columnData={columnDataAttachments} 
                                                    />
                                                    <TableBody>
                                                        {temp_attachments.map((n) => 
                                                            {return n._type === 'Raingarden Planting' && (
                                                                <TableRow hover>
                                                                    <CustomTableCell className={classes.nowrap}>
                                                                        {n.file.includes("https") && (
                                                                            <Tooltip title="Open Link">
                                                                                <Button
                                                                                    color="primary"
                                                                                    onClick={() => {
                                                                                        window.open(n.file);
                                                                                    }}
                                                                                    className={classes.deleteWidth}>
                                                                                    <ViewIcon color="primary" />
                                                                                </Button>
                                                                            </Tooltip>
                                                                        )}
                                                                        <Tooltip title="Delete">
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={() => {
                                                                                return(this.setState({
                                                                                        temp_attachments: temp_attachments.filter((tc) => tc !== n)
                                                                                    }),
                                                                                    ormNineMileGrantAttachmentDelete(n.id))
                                                                                    
                                                                                }
                                                                                }
                                                                                // disabled={is_read_only}
                                                                                className={classes.deleteWidth}>
                                                                                <DeleteIcon color="primary" />
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </CustomTableCell>
                                                                    <CustomTableCell>{n.name}</CustomTableCell>
                                                                    <CustomTableCell>{n._type}</CustomTableCell>
                                                                    <CustomTableCell>{n.notes}</CustomTableCell>
                                                                </TableRow> 
                                                            )})}
                                                     
                                                    </TableBody>
                                                </Table>
                                                </Grid>
                                                <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                                <br></br>
                                                </Grid>
                                            {projectType.length > 1 && (
                                                <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                                    <Divider className={classes.printDivider} />
                                                </Grid>
                                            )}
                                        </Grid>
                                    
                                    )}  
                                    {activeStep === 4 && projectType.length > 0 && projectType.find(t=>t==="Shoreline Buffer") && ( //this isnt needed 100% as still works as will only be one but was a multi case b4 and I dont want to recode all the types 
                                        <Grid container spacing={2} style={{marginTop:10, marginLeft:15}}>
                                             <Grid item xs={12}>
                                                <Typography variant="subtitle1"><b><u>Shoreline Buffer</u></b></Typography>
                                             </Grid>     
                                            <Grid item xs={12}>
                                                <TextField
                                                    field="buff_protect" 
                                                    label="Describe the waterbody or wetland that this buffer will protect. *"
                                                    multiline
                                                    rows={8}
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    field="buff_width"
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="What is the average width (ft) and length (ft) of the proposed buffer? *"
                                                            helpText="See picture below for guidance"
                                                        />
                                                    }
                                                    multiline
                                                    rows={8}
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} style={{textAlign:"center"}}> 
                                                {/*eslint-disable-next-line*/}
                                                <img
                                                    src={buffer}
                                                    alt="Buffer Image Not Available"
                                                    style={{ maxWidth: "99%", border: "1px solid black", borderRadius: 4, backgroundColor:"lightgray" }}
                                                />
                                                <Typography gutterBottom>
                                                   <u>Shoreline Buffer Example</u> 
                                                </Typography>
                                            </Grid>
                                                                                  
                                            <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>  
                                                <Typography variant="subtitle1"><b>Shoreline Buffer Attachments *</b></Typography>
                                                <Grid item xs={12} style={{textAlign:"left"}}> 
                                                <Typography gutterBottom>
                                                    Provide an aerial photo or map with location of the buffer, waterbody and slope contours shown. 
                                                    The <a
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href="https://gis.hennepin.us/naturalresources/">
                                                        Hennepin County Natural Resource Interactive Map
                                                    </a> {" "}is one source to provide this aerial photo. Follow the directions for finding elevations and grade changes on this <a
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href="http://www.ninemilecreek.org/wp-content/uploads/Using-the-Hennepin-County-Natural-Resources-Interactive-Map.pdf">
                                                        guide.
                                                    </a> 
                                                </Typography>
                                            </Grid>   
                                                <Grid container spacing={2} style={{ padding: 10 }}>
                                                    <Grid item xs={2} style={{display:"grid", paddingLeft:0,height:46}}>
                                                        <Button
                                                            onClick={() =>
                                                                this.setState({
                                                                    attachmentDialogOpen: true,
                                                                    attachmentEditing: null,
                                                                    attachmentType: "Shoreline Buffer",
                                                                    attachmentFormKey: window.performance.now()
                                                                })
                                                            }
                                                            // disabled={is_read_only}
                                                            variant="contained"
                                                            color="primary">
                                                            Add Attachment
                                                        </Button>  
                                                    </Grid> 
                                                </Grid> 
                                                <Table style={{ marginTop: 8 }}>
                                                    <EnhancedTableHead
                                                        columnData={columnDataAttachments} 
                                                    />
                                                    <TableBody>
                                                        {temp_attachments.map((n) => 
                                                            {return n._type === 'Shoreline Buffer' && (
                                                                <TableRow hover>
                                                                    <CustomTableCell className={classes.nowrap}>
                                                                        {n.file.includes("https") && (
                                                                            <Tooltip title="Open Link">
                                                                                <Button
                                                                                    color="primary"
                                                                                    onClick={() => {
                                                                                        window.open(n.file);
                                                                                    }}
                                                                                    className={classes.deleteWidth}>
                                                                                    <ViewIcon color="primary" />
                                                                                </Button>
                                                                            </Tooltip>
                                                                        )}
                                                                        <Tooltip title="Delete">
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={() => {
                                                                                return(this.setState({
                                                                                        temp_attachments: temp_attachments.filter((tc) => tc !== n)
                                                                                    }),
                                                                                    ormNineMileGrantAttachmentDelete(n.id))
                                                                                    
                                                                                }
                                                                                }
                                                                                // disabled={is_read_only}
                                                                                className={classes.deleteWidth}>
                                                                                <DeleteIcon color="primary" />
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </CustomTableCell>
                                                                    <CustomTableCell>{n.name}</CustomTableCell>
                                                                    <CustomTableCell>{n._type}</CustomTableCell>
                                                                    <CustomTableCell>{n.notes}</CustomTableCell>
                                                                </TableRow> 
                                                            )})}
                                                     
                                                    </TableBody>
                                                </Table>
                                                </Grid>
                                                <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                                <br></br>
                                                
                                            </Grid>
                                        
                                            <Grid item xs={6} style={{ marginLeft: 15 }} >
                                                <ReactSelect
                                                    label="How will you plant your buffer? *"
                                                    field="buff_plant"
                                                    options={MAKE_OPTIONS([
                                                        "Seeding",
                                                        "Live Plants",
                                                        "Both", 
                                                    ])}
                                                    disabled={is_read_only}
                                                    isMulti={true}
                                                    height="auto"
                                                    fullWidth
                                                />
                                            </Grid>
                                          
                                            <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>  
                                                <Typography variant="subtitle1"><b>Planting Layout Attachments *</b></Typography>
                                                <Grid item xs={12} style={{textAlign:"left"}}> 
                                                    <Typography gutterBottom>
                                                        Provide a planting layout. This layout can be made by-hand or electronically. Only plants native to Minnesota are eligible for reimbursement. 
                                                        Other plants can be used but costs can only be claimed as matching funds. Ensure your planting layout indicates the aquatic and upland sides of the buffer. 
                                                    </Typography>
                                                </Grid>
                                                <Grid container spacing={2} style={{ padding: 10 }}>
                                                    <Grid item xs={2} style={{display:"grid", paddingLeft:0,height:46}}>
                                                        <Button
                                                            onClick={() =>
                                                                this.setState({
                                                                    attachmentDialogOpen: true,
                                                                    attachmentEditing: null,
                                                                    attachmentFormKey: window.performance.now(),
                                                                    attachmentType: "Planting Layout"
                                                                })
                                                            }
                                                            // disabled={is_read_only}
                                                            variant="contained"
                                                            color="primary">
                                                            Add Attachment
                                                        </Button>  
                                                    </Grid> 
                                                </Grid> 
                                                <Table style={{ marginTop: 8 }}>
                                                    <EnhancedTableHead
                                                        columnData={columnDataAttachments} 
                                                    />
                                                    <TableBody>
                                                        {temp_attachments.map((n) => 
                                                            {return n._type === 'Planting Layout' && (
                                                                <TableRow hover>
                                                                    <CustomTableCell className={classes.nowrap}>
                                                                        {n.file.includes("https") && (
                                                                            <Tooltip title="Open Link">
                                                                                <Button
                                                                                    color="primary"
                                                                                    onClick={() => {
                                                                                        window.open(n.file);
                                                                                    }}
                                                                                    className={classes.deleteWidth}>
                                                                                    <ViewIcon color="primary" />
                                                                                </Button>
                                                                            </Tooltip>
                                                                        )}
                                                                        <Tooltip title="Delete">
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={() => {
                                                                                return(this.setState({
                                                                                        temp_attachments: temp_attachments.filter((tc) => tc !== n)
                                                                                    }),
                                                                                    ormNineMileGrantAttachmentDelete(n.id))
                                                                                    
                                                                                }
                                                                                }
                                                                                // disabled={is_read_only}
                                                                                className={classes.deleteWidth}>
                                                                                <DeleteIcon color="primary" />
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </CustomTableCell>
                                                                    <CustomTableCell>{n.name}</CustomTableCell>
                                                                    <CustomTableCell>{n._type}</CustomTableCell>
                                                                    <CustomTableCell>{n.notes}</CustomTableCell>
                                                                </TableRow> 
                                                            )})}
                                                     
                                                    </TableBody>
                                                </Table>
                                                </Grid>
                                                <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                                <br></br>
                                            </Grid>
                                            {projectType.length > 1 && (
                                            <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                                <Divider className={classes.printDivider} />
                                            </Grid>
                                            )}
                                        </Grid>
                                    
                                    )}
                                    {activeStep === 4 && projectType.length > 0 && projectType.find(t=>t==="Native Habitat Restoration" || t === 'Native Planting') && (
                                        <Grid container spacing={2} style={{marginTop:10, marginLeft:15}}> 
                                             <Grid item xs={12}>
                                                <Typography variant="subtitle1"><b><u>Native Habitat Restoration or Native Planting</u></b></Typography>
                                            </Grid>     
                                            <Grid item xs={12}>
                                                <TextField
                                                    field="native_invasive" 
                                                    label="Are there any invasive species and/or non-native species in the area to be restored? If yes, what are they and how will you remove them from the restoration area to prepare for planting? *"
                                                    multiline
                                                    rows={8}
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Checkbox disabled={is_read_only} label="Our habitat restoration and native planting grants 
                                                will only reimburse you for plants native to Minnesota. Check this box to indicate you will only request reimbursement for native plants. *" field="native_plant" />   
                                            </Grid>
                                                                                         
                                            <Grid item xs={12} xl={10}  classes={{ root: classes.root }}>  
                                                <Typography variant="subtitle1"><b>Planting Attachments *</b></Typography>
                                                 <Typography gutterBottom>
                                                    Please attach an aerial image with your proposed planting layout. This layout can be made by-hand or electronically.
                                                </Typography>
                                                <Grid container spacing={2} style={{ padding: 10 }}>
                                                    <Grid item xs={2} style={{display:"grid", paddingLeft:0,height:46}}>
                                                        <Button
                                                            onClick={() =>
                                                                this.setState({
                                                                    attachmentDialogOpen: true,
                                                                    attachmentEditing: null,
                                                                    attachmentType: "Native Planting",
                                                                    attachmentFormKey: window.performance.now()
                                                                })
                                                            }
                                                            disabled={is_read_only}
                                                            variant="contained"
                                                            color="primary">
                                                            Add Attachment
                                                        </Button>  
                                                    </Grid> 
                                                </Grid> 
                                                <Table style={{ marginTop: 8 }}>
                                                    <EnhancedTableHead
                                                        columnData={columnDataAttachments} 
                                                    />
                                                    <TableBody>
                                                        {temp_attachments.map((n) => 
                                                            {return n._type === 'Native Planting' && (
                                                                <TableRow hover>
                                                                    <CustomTableCell className={classes.nowrap}>
                                                                        {n.file.includes("https") && (
                                                                            <Tooltip title="Open Link">
                                                                                <Button
                                                                                    color="primary"
                                                                                    onClick={() => {
                                                                                        window.open(n.file);
                                                                                    }}
                                                                                    className={classes.deleteWidth}>
                                                                                    <ViewIcon color="primary" />
                                                                                </Button>
                                                                            </Tooltip>
                                                                        )}
                                                                        <Tooltip title="Delete">
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={() => {
                                                                                return(this.setState({
                                                                                        temp_attachments: temp_attachments.filter((tc) => tc !== n)
                                                                                    }),
                                                                                    ormNineMileGrantAttachmentDelete(n.id))
                                                                                    
                                                                                }
                                                                                }
                                                                                // disabled={is_read_only}
                                                                                className={classes.deleteWidth}>
                                                                                <DeleteIcon color="primary" />
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </CustomTableCell>
                                                                    <CustomTableCell>{n.name}</CustomTableCell>
                                                                    <CustomTableCell>{n._type}</CustomTableCell>
                                                                    <CustomTableCell>{n.notes}</CustomTableCell>
                                                                </TableRow> 
                                                            )})}
                                                     
                                                    </TableBody>
                                                </Table>
                                                </Grid>
                                                <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                                <br></br>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    field="native_area"
                                                    label="What is the size of your project area? Please provide units in your answer (square ft or acres) *" 
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid> 
                                            <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                                <br></br>
                                            </Grid>
                                            {projectType.length > 1 && (
                                            <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                                <Divider className={classes.printDivider} />
                                            </Grid>
                                            )}
                                        </Grid>
                                    )}
                                     {activeStep === 4 && projectType.length > 0 && projectType.find(t=>t==="Pervious Pavement") && (
                                        <Grid container spacing={2} style={{marginTop:10, marginLeft:15}}>
                                             <Grid item xs={12}>
                                                <Typography variant="subtitle1"><b><u>Pervious Pavement</u></b></Typography>      
                                            </Grid>
                                            <Grid item xs={6}>
                                                <ReactSelect
                                                    label={ 
                                                        <Typography variant="subtitle1" gutterBottom >
                                                            <i>Where does the water currently flow to that you plan to capture with the pervious paver system? *</i>
                                                        </Typography> 
                                                    }
                                                    field="pervious_flow_to"
                                                    options={MAKE_OPTIONS([
                                                        "Storm drain",
                                                        "Pond or other water body",
                                                        "Green space (grass/garden/forest etc.)",
                                                        "Impervious surface (driveway/street etc.)", 
                                                    ])}
                                                    disabled={is_read_only}
                                                    isMulti={true}
                                                    height="auto"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <ReactSelect 
                                                    label={ 
                                                        <Typography variant="subtitle1" gutterBottom >
                                                            <i>Where does the water flow from that will enter the pervious paver system? *</i>
                                                         </Typography>
                                                    } 
                                                    field="pervious_flow_from"
                                                    options={MAKE_OPTIONS([    
                                                        "Storm drain",
                                                        "Pond or other water body",
                                                        "Green space (grass/garden/forest etc.)",
                                                        "Impervious surface (driveway/street etc.)", 
                                                    ])}
                                                    disabled={is_read_only}
                                                    isMulti={true}
                                                    height="auto"
                                                    fullWidth
                                                />
                                            </Grid>
                                            
                                            <Grid item xs={12} style={{textAlign:"left"}}> 
                                                <Typography gutterBottom>
                                                    Provide an attachment of a cross-section plan of the pervious pavers/pavement system. It should include:
                                                        <ul>
                                                            <li>
                                                                layers of substrate
                                                            </li>
                                                            <li>
                                                                material in each layer
                                                            </li>
                                                            <li>
                                                            	depth of each layer
                                                            </li>
                                                            <li>
                                                                the diameter of rocks in each layer
                                                            </li>
                                                            <li>
                                                                native soils
                                                            </li>
                                                            <li>
                                                                type of pervious paver used
                                                            </li>
                                                            <li>
                                                                curbs, if applicable
                                                            </li>
                                                            <li>
                                                                drainpipe, if applicable, and the pipe’s diameter 
                                                            </li>
                                                        </ul> 
                                                </Typography>
                                            </Grid>                                            
                                            <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>  
                                                <Typography variant="subtitle1"><b>Pervious Pavement Attachments *</b></Typography>
                                                 
                                                <Grid container spacing={2} style={{ padding: 10 }}>
                                                    <Grid item xs={2} style={{display:"grid", paddingLeft:0,height:46}}>
                                                        <Button
                                                            onClick={() =>
                                                                this.setState({
                                                                    attachmentDialogOpen: true,
                                                                    attachmentEditing: null,
                                                                    attachmentType: "Pervious Pavement",
                                                                    attachmentFormKey: window.performance.now()
                                                                })
                                                            }
                                                            // disabled={is_read_only}
                                                            variant="contained"
                                                            color="primary">
                                                            Add Attachment
                                                        </Button>  
                                                    </Grid> 
                                                </Grid> 
                                                <Table style={{ marginTop: 8 }}>
                                                    <EnhancedTableHead
                                                        columnData={columnDataAttachments} 
                                                    />
                                                    <TableBody>
                                                        {temp_attachments.map((n) => 
                                                            {return n._type === 'Pervious Pavement' && (
                                                                <TableRow hover>
                                                                    <CustomTableCell className={classes.nowrap}>
                                                                        {n.file.includes("https") && (
                                                                            <Tooltip title="Open Link">
                                                                                <Button
                                                                                    color="primary"
                                                                                    onClick={() => {
                                                                                        window.open(n.file);
                                                                                    }}
                                                                                    className={classes.deleteWidth}>
                                                                                    <ViewIcon color="primary" />
                                                                                </Button>
                                                                            </Tooltip>
                                                                        )}
                                                                        <Tooltip title="Delete">
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={() => {
                                                                                return(this.setState({
                                                                                        temp_attachments: temp_attachments.filter((tc) => tc !== n)
                                                                                    }),
                                                                                    ormNineMileGrantAttachmentDelete(n.id))
                                                                                    
                                                                                }
                                                                                }
                                                                                // disabled={is_read_only}
                                                                                className={classes.deleteWidth}>
                                                                                <DeleteIcon color="primary" />
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </CustomTableCell>
                                                                    <CustomTableCell>{n.name}</CustomTableCell>
                                                                    <CustomTableCell>{n._type}</CustomTableCell>
                                                                    <CustomTableCell>{n.notes}</CustomTableCell>
                                                                </TableRow> 
                                                            )})}
                                                     
                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                                <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                                    <br></br>
                                                </Grid>
                                            {projectType.length > 1 && (
                                            <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                                <Divider className={classes.printDivider} />
                                            </Grid>
                                            )}
                                        </Grid>
                                    )}
                                    {activeStep === 4 && projectType.length > 0 && projectType.find(t=>t==="Green Roof") && ( 
                                        <Grid container spacing={2} style={{marginTop:10, marginLeft:15}}> 
                                             <Grid item xs={12}>
                                                <Typography variant="subtitle1"><b><u>Green Roof</u></b></Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    field="gr_sqft" 
                                                    label={"What is the proposed roof’s area? (sq ft) *"} 
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid>  
                                            <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>  
                                                <Typography variant="subtitle1"><b>Green Roof Attachments *</b></Typography>
                                                <Typography gutterBottom>
                                                    Please provide a cross section attachment of the proposed roof material.
                                                </Typography>
                                                <Grid container spacing={2} style={{ padding: 10 }}>
                                                    <Grid item xs={2} style={{display:"grid", paddingLeft:0,height:46}}>
                                                        <Button
                                                            onClick={() =>
                                                                this.setState({
                                                                    attachmentDialogOpen: true,
                                                                    attachmentEditing: null,
                                                                    attachmentType: "Green Roof",
                                                                    attachmentFormKey: window.performance.now()
                                                                })
                                                            }
                                                            // disabled={is_read_only}
                                                            variant="contained"
                                                            color="primary">
                                                            Add Attachment
                                                        </Button>  
                                                    </Grid> 
                                                </Grid> 
                                                <Table style={{ marginTop: 8 }}>
                                                    <EnhancedTableHead
                                                        columnData={columnDataAttachments} 
                                                    />
                                                    <TableBody>
                                                        {temp_attachments.map((n) => 
                                                            {return n._type === 'Green Roof' && (
                                                                <TableRow hover>
                                                                    <CustomTableCell className={classes.nowrap}>
                                                                        {n.file.includes("https") && (
                                                                            <Tooltip title="Open Link">
                                                                                <Button
                                                                                    color="primary"
                                                                                    onClick={() => {
                                                                                        window.open(n.file);
                                                                                    }}
                                                                                    className={classes.deleteWidth}>
                                                                                    <ViewIcon color="primary" />
                                                                                </Button>
                                                                            </Tooltip>
                                                                        )}
                                                                        <Tooltip title="Delete">
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={() => {
                                                                                return(this.setState({
                                                                                        temp_attachments: temp_attachments.filter((tc) => tc !== n)
                                                                                    }),
                                                                                    ormNineMileGrantAttachmentDelete(n.id))
                                                                                    
                                                                                }
                                                                                }
                                                                                // disabled={is_read_only}
                                                                                className={classes.deleteWidth}>
                                                                                <DeleteIcon color="primary" />
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </CustomTableCell>
                                                                    <CustomTableCell>{n.name}</CustomTableCell>
                                                                    <CustomTableCell>{n._type}</CustomTableCell>
                                                                    <CustomTableCell>{n.notes}</CustomTableCell>
                                                                </TableRow> 
                                                            )})}
                                                     
                                                    </TableBody>
                                                </Table>
                                            </Grid> 
                                            <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                                <br></br>
                                            </Grid>      
                                            <Grid item xs={12}>
                                                <TextField
                                                    field="gr_plants" 
                                                    label={"List the plants that will be utilized in the roof planting. *"}
                                                    multiline
                                                    rows={8}
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid> 
                                            {projectType.length > 1 && (
                                                <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                                    <Divider className={classes.printDivider} />
                                                </Grid>
                                            )}
                                        </Grid>
                                    )}
                                    {activeStep === 4 && projectType.length > 0 && projectType.find(t=>t==="Groundwater Conservation") && ( 
                                        <Grid container spacing={2} style={{marginTop:10, marginLeft:15}}> 
                                             <Grid item xs={12}>
                                                <Typography variant="subtitle1"><b><u>Groundwater Conservation</u></b></Typography>
                                            </Grid>     
                                            <Grid item xs={6}>
                                                <TextField
                                                    field="gw_gallons_saved" 
                                                    label={"How many gallons of water will this project save annually? *"} 
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    field="gw_water_collection" 
                                                    label={"Please describe the water collection or conservation method being used. *"}
                                                    multiline
                                                    rows={8}
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid>
                                            {projectType.length > 1 && (
                                                <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                                    <Divider className={classes.printDivider} />
                                                </Grid>
                                            )} 
                                        </Grid>
                                    )}
                                    {activeStep === 4 && projectType.length > 0 && projectType.find(t=>t==="Chloride Reduction") && ( 
                                        <Grid container spacing={2} style={{marginTop:10, marginLeft:15}}> 
                                             <Grid item xs={12}>
                                                <Typography variant="subtitle1"><b><u>Chloride Reduction</u></b></Typography>
                                            </Grid>     
                                            <Grid item xs={12}>
                                                <TextField
                                                    field="chloride_address" 
                                                    label={"Which source(s) of chloride does this project address (municipal applications, school district applications, private contractor applications; applications to roads, sidewalks, etc.)? * "} 
                                                    disabled={is_read_only}
                                                    fullWidth
                                                    multiline
                                                    rows={8}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    field="chloride_reduce" 
                                                    label={"How does this project propose to reduce chloride use? *"}
                                                    multiline
                                                    rows={8}
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    field="chloride_outreach" 
                                                    label={"Describe any outreach and training that will accompany the project. Examples include staff training in use of new equipment, signage, residential education, etc. *"} 
                                                    disabled={is_read_only}
                                                    fullWidth
                                                    multiline
                                                    rows={8}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    field="chloride_estimate" 
                                                    label={"Provide an estimate of how much chloride is currently being applied to the designated area per winter season and an estimated reduction based on this project’s activities. * "}
                                                    multiline
                                                    rows={8}
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid>
                                            
                                            <Grid item xs={12}>
                                                <TextField
                                                    field="chloride_percentage" 
                                                    label={
                                                        <Typography gutterBottom>
                                                        What percentage of the chloride reduction activities will occur in the Nine Mile Creek Watershed District? District boundaries can be found here {" "}<a
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            href="https://www.ninemilecreek.org/are-you-in-the-watershed/">
                                                            Nine Mile District Boundaries
                                                        </a>. *  
                                                        </Typography>
                                                    } 
                                                    multiline
                                                    rows={4}
                                                    disabled={is_read_only}
                                                    fullWidth
                                                />
                                            </Grid>   
                                                <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>  
                                                <Typography variant="subtitle1"><b>Chloride Reduction Attachments</b>**Optional** to upload supporting documents</Typography>
                                                <Grid item xs={12} style={{textAlign:"left"}}> 
                                               
                                            </Grid>   
                                                <Grid container spacing={2} style={{ padding: 10 }}>
                                                    <Grid item xs={2} style={{display:"grid", paddingLeft:0,height:46}}>
                                                        <Button
                                                            onClick={() =>
                                                                this.setState({
                                                                    attachmentDialogOpen: true,
                                                                    attachmentEditing: null,
                                                                    attachmentType: "Chloride Reduction",
                                                                    attachmentFormKey: window.performance.now()
                                                                })
                                                            }
                                                            // disabled={is_read_only}
                                                            variant="contained"
                                                            color="primary">
                                                            Add Attachment
                                                        </Button>  
                                                    </Grid> 
                                                </Grid> 
                                                <Table style={{ marginTop: 8 }}>
                                                    <EnhancedTableHead
                                                        columnData={columnDataAttachments} 
                                                    />
                                                    <TableBody>
                                                        {temp_attachments.map((n) => 
                                                            {return n._type === 'Chloride Reduction' && (
                                                                <TableRow hover>
                                                                    <CustomTableCell className={classes.nowrap}>
                                                                        {n.file.includes("https") && (
                                                                            <Tooltip title="Open Link">
                                                                                <Button
                                                                                    color="primary"
                                                                                    onClick={() => {
                                                                                        window.open(n.file);
                                                                                    }}
                                                                                    className={classes.deleteWidth}>
                                                                                    <ViewIcon color="primary" />
                                                                                </Button>
                                                                            </Tooltip>
                                                                        )}
                                                                        <Tooltip title="Delete">
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={() => {
                                                                                return(this.setState({
                                                                                        temp_attachments: temp_attachments.filter((tc) => tc !== n)
                                                                                    }),
                                                                                    ormNineMileGrantAttachmentDelete(n.id))
                                                                                    
                                                                                }
                                                                                }
                                                                                // disabled={is_read_only}
                                                                                className={classes.deleteWidth}>
                                                                                <DeleteIcon color="primary" />
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </CustomTableCell>
                                                                    <CustomTableCell>{n.name}</CustomTableCell>
                                                                    <CustomTableCell>{n._type}</CustomTableCell>
                                                                    <CustomTableCell>{n.notes}</CustomTableCell>
                                                                </TableRow> 
                                                            )})}
                                                     
                                                    </TableBody>
                                                </Table>
                                                </Grid>
                                                <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                                <br></br>
                                                
                                            </Grid>
                                            {projectType.length > 1 && (
                                                <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                                    <Divider className={classes.printDivider} />
                                                </Grid>
                                            )}    
                                        </Grid>
                                    )}
                                    {activeStep === 5 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Accordion defaultExpanded>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                    <b><u>Financial</u></b> 
                                                    </Typography>
                                                </AccordionSummary> 
                                                <AccordionDetails style={{paddingTop:25,paddingBottom:55, backgroundColor: "f6f0f0" }}>
                                                    <Grid container spacing={2}>
                                                        <Paper className={classes.root}>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            Fill out the line item budget table below. Your requested funds, total project budget, and match requirements will automatically be calculated in the fields below. Check out{" "}<a
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            href="https://www.youtube.com/watch?v=vJyXXqw2a2I"> 
                                                            this video
                                                        </a>{" "}to help you fill out the table.
                                                        </Typography>
                                                            <Button
                                                                fullWidth
                                                                disabled={is_read_only}
                                                                className={classes.gutterBottom}
                                                                gutterBottom={true}
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => {
                                                                        this.setState({
                                                                            expenseDialogOpen: true,
                                                                            expenseEditing: {'item_name':null,'nmcwd_grant':null,'match':null,'labor_match':null},
                                                                            expenseFormKey: window.performance.now(),
                                                                        })
                                                                }}>
                                                                <AddIcon className={classes.iconAdd} />
                                                                &nbsp;&nbsp;&nbsp;Add Budget Line Item 
                                                            </Button>   
                                                            <Table className={classes.table}>
                                                                <EnhancedTableHead
                                                                    order={order}
                                                                    orderBy={orderBy}
                                                                    onRequestSort={this.handleRequestSort}
                                                                    columnData={expenseHeader}
                                                                />
                                                                <TableBody>
                                                                    {(() => {
                                                                        if (tempExpenses.length < 1) {
                                                                            return (
                                                                                <TableRow>
                                                                                    <CustomTableCell colSpan={expenseHeader.length} className={classes.centerAlign}>
                                                                                        No Records Available
                                                                                    </CustomTableCell>
                                                                                </TableRow>
                                                                            );
                                                                        } 
                                                                        return tempExpenses.map((n) => (
                                                                            <TableRow hover key={ n.id }>
                                                                                <CustomTableCell className={ classes.nowrap }>
                                                                                    <Tooltip title="Edit">
                                                                                        {/* EDIT RECORD will trigger createRepeatableField class*/ }
                                                                                        <Button
                                                                                            onClick={ () => {
                                                                                                this.setState({
                                                                                                    expenseEditing: n,
                                                                                                    expenseDialogOpen:true,
                                                                                                    expenseFormKey: window.performance.now()
                                                                                                });
                                                                                            } }
                                                                                         
                                                                                            className={ classes.deleteWidth }>
                                                                                            <EditIcon color="primary" />
                                                                                        </Button>
                                                                                    </Tooltip>

                                                                                    <Tooltip title="Delete">
                                                                                        <Button
                                                                                            disabled={is_read_only}
                                                                                            onClick={() =>{
                                                                                                
                                                                                                this.setState(()=>{
                                                                                                  return {tempExpenses: tempExpenses.filter((tc) => tc !== n)}
                                                                                                },()=>this.calculateRepeatable() 
                                                                                                )

                                                                                                setTimeout(()=>{ 
                                                                                                    if(n.id){//prior saved record
                                                                                                        ormNineMileGrantExpenseDelete(n.id) 
                                                                                                        this.setState({submittedSave:true},()=>{//we will already have prior validation fields filled it so wont stop them and will auto save fields
                                                                                                            this.formApi.submitForm("calcresave")})
                                                                                                        }
                                                                                                },300)

                                                                                             }}
                                                                                            className={ classes.deleteWidth }>
                                                                                            <DeleteIcon color="inherit" />
                                                                                            
                                                                                        </Button>
                                                                                    </Tooltip>
                                                                                </CustomTableCell>
                                                                                {/* PRINTING OF THE DATA IN THE TABLE */ } 
                                                                                <CustomTableCell>{n.item_name}</CustomTableCell>  
                                                                                <CustomTableCell>
                                                                                    <NumberFormat
                                                                                        prefix="$"
                                                                                        mask="_"
                                                                                        displayType={"text"}
                                                                                        value={n.nmcwd_grant}
                                                                                        thousandSeparator={true}
                                                                                    />
                                                                                </CustomTableCell>
                                                                                <CustomTableCell>
                                                                                    <NumberFormat
                                                                                        prefix="$"
                                                                                        mask="_"
                                                                                        displayType={"text"}
                                                                                        value={n.match}
                                                                                        thousandSeparator={true}
                                                                                    />
                                                                                </CustomTableCell> 
                                                                                <CustomTableCell>
                                                                                    <NumberFormat
                                                                                        prefix=""
                                                                                        mask="_"
                                                                                        displayType={"text"}
                                                                                        value={n.labor_match}
                                                                                        thousandSeparator={true}
                                                                                    />
                                                                                </CustomTableCell>   
                                                                            </TableRow>
                                                                        ));
                                                                    })()}
                                                                </TableBody>
                                                            </Table>
                                                        </Paper>
                                                    </Grid> 
                                                </AccordionDetails>
                                                <AccordionDetails style={{ paddingBottom: 27, backgroundColor: "f6f0f0" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                            useTextFormat={true}    
                                                            field="requested_funds" 
                                                            disabled={true}
                                                            fullWidth
                                                            eventHandle={(e)=>{
                                                                if (e <= 5000){
                                                                    this.setState({maintenceText:"I agree to maintain this project for five years following installation."})
                                                                }else {
                                                                    this.setState({maintenceText:"I agree to maintain this project for ten years following installation."})
                                                                }
                                                             }}
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Requested Funds"
                                                                    helpText={`${maxFunds !== 0 ? `Maximum allowed grant request is $${maxFunds} `: ""}`}
                                                                />
                                                            } 
                                                            />
                                                    </Grid>
                                                    <Grid item xs={6}> 
                                                        <TextField
                                                            useTextFormat={true}   
                                                            field="project_budget"
                                                            disabled={true}
                                                            fullWidth
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Total project budget ($)"
                                                                    helpText="Time you spend working on your project can be used as part of your match. Every hour of work is credited $20.  
                                                                    Example tasks include planting, site prep, time spent on education related to the project, project maintenance during project establishment, etc.
                                                                    "
                                                                />
                                                            } 
                                                        />
                                                    </Grid>
                                                   
                                                    <Grid item xs={6}> 
                                                        <TextField
                                                            useTextFormat={true}                                                            
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Match ($)"
                                                                    helpText="Labor and monetary match provided towards the total project budget.
                                                                    "
                                                                />
                                                            }
                                                            field="match_dollars"
                                                            disabled={true}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}> 
                                                        <TextField
                                                            NumberFormatCustom2 
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Match %"
                                                                    helpText={projectType.find(p=>p==="Native Habitat Restoration" || p==="Native Planting")? "Match must be 50% or greater": "Match must be 25% or greater"}
 
                                                                />
                                                            }
                                                            field="match_percent"
                                                            disabled={true}
                                                            fullWidth
                                                            decimals={2}
                                                        />
                                                    </Grid>  
                                                </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )}
                                    {activeStep === 6 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>              
                                            <Typography variant="subtitle1" gutterBottom>
                                                <b><u>Education</u></b>
                                            </Typography> 
                                            <Grid container spacing ={2}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        label="How will you share the results of your project and inform others about your project’s benefits? *"
                                                        field="ed_share" 
                                                        multiline
                                                        rows={8}
                                                        disabled={is_read_only}
                                                        fullWidth 
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <RadioGroup
                                                        field="ed_can_share"
                                                        disabled={is_read_only}
                                                        fullWidth
                                                        options={[
                                                            { label: "Yes", value: "Yes" },
                                                            { label: "No", value: "No" }, 
                                                        ]}
                                                        alignment={true}
                                                        label="May we share your project with the community on our website, social and other media? *" 
                                                        
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <RadioGroup
                                                        field="ed_highlight"
                                                        disabled={is_read_only}
                                                        fullWidth
                                                        options={[
                                                            { label: "Yes", value: "Yes" },
                                                            { label: "No", value: "No" }, 
                                                        ]}
                                                        alignment={true}
                                                        label="Could we highlight your project on a tour or training event with prior notice and agreement? *"
                                                        
                                                    />
                                                </Grid>
                                            </Grid> 
                                        </Grid>
                                    )}
                                    {activeStep === 7 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>              
                                            <Typography variant="subtitle1" gutterBottom>
                                                <b><u>Maintenance</u></b>
                                            </Typography> 
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} style={{textAlign:"left", marginBottom:-24}}>  
                                                    <Grid item xs={6}> 
                                                        <Checkbox disabled={is_read_only || mainExplain}  field="main_agree"  label={maintenceText + " *" }/>
                                                    </Grid>
                                                    <Grid item xs={6} style={{marginTop:10}}>
                                                        <RadioGroup
                                                            field="main_report"
                                                            disabled={is_read_only || mainExplain}
                                                            fullWidth
                                                            options={[
                                                                { label: "Yes", value: "Yes" },
                                                                { label: "No", value: "No" }, 
                                                            ]}
                                                            alignment={true}
                                                            label="I will submit a brief yearly report to the Nine Mile Creek Watershed District during the maintenance period. *"   
                                                        />
                                                    </Grid> 
                                                </Grid> 
                                                <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                                    <br></br>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        label="Explain how the project will be maintained during the maintenance agreement period and identify who will perform the maintenance. *"
                                                        field="main_maintain" 
                                                        multiline
                                                        rows={10}
                                                        disabled={is_read_only || mainExplain}
                                                        fullWidth 
                                                    />
                                                </Grid> 
                                                <Grid item xs={12} style={{textAlign:"left", marginBottom:-24}}>  
                                                    <Grid item xs={6}> 
                                                        <Checkbox disabled={is_read_only}  field="main_explain"  
                                                        eventHandle={(e)=> {
                                                            this.setState({mainExplain:e})
                                                            // this.formApi.setValue("main_agree","")
                                                            // this.formApi.setValue("main_report",false)
                                                            // this.formApi.setValue("main_maintain","")
                                                        }}
                                                        label={"Not applicable (most projects require a maintenance agreement please receive confirmation from NMCWD staff before checking this box)" }/>
                                                    </Grid> 
                                                </Grid> 
                                                 
                                            </Grid> 
                                        </Grid>
                                    )}
                                    {activeStep === "PRINT" && (
                                        <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                            <Divider className={classes.printDivider} />
                                        </Grid>
                                    )}
                                    {activeStep === 8 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Typography variant="subtitle1" gutterBottom>
                                                 <br></br>
                                            </Typography>
                                            <table>
                                                {" "}
                                                <tr>
                                                    <td style={{ whiteSpace: "nowrap", paddingRight: 16 }}>
                                                        <Checkbox disabled={is_read_only} label="I AGREE *" field="signature" />
                                                    </td>
                                                    <td>
                                                        <Typography gutterBottom>
                                                        I hereby apply for NMCWD cost-share assistance with the proposed activities described in this form and have submitted supporting information necessary for NMCWD to determine whether to provide cost-share funding. 
                                                        The information submitted and statements made concerning this application are true and correct to the best of my knowledge. I understand that any activities undertaken prior to complete execution of a 
                                                        cost-share agreement with NMCWD will not be eligible for reimbursement. I further represent that I possess the authority and necessary property-use rights to undertake the proposed activities 
                                                        or I am acting as the duly authorized agent of the property owner. By submitting this application, 
                                                        I authorize NMCWD and its agents, employees, and contractors to enter the property that is the site of the proposed activities to perform inspections of the site and activities occurring thereon.
                                                        </Typography>
                                                    </td>
                                                </tr> 
                                            </table>
                                        </Grid>
                                    )}
                                    {activeStep === 9 && (
                                      <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                        <Accordion defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                <b><u>In-Progress Reporting</u></b> 
                                                </Typography>
                                            </AccordionSummary> 
                                            <AccordionDetails style={{paddingTop:25,paddingBottom:55, backgroundColor: "f6f0f0" }}>
                                                <Grid container spacing={2}>
                                                    <Paper className={classes.root}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                    Payments on cost share grants are reimbursement based. NMCWD can reimburse funds throughout the grant period, as requested by the grantee, up to the final 10% of the grant. 
                                                    The final 10% of the grant is reimbursed upon successful completion of the project and approval of a final reimbursement submission.
                                                    </Typography>
                                                        <Button
                                                            fullWidth
                                                            disabled={inprogressReportingReadOnly}
                                                            className={classes.gutterBottom}
                                                            gutterBottom={true}
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => {
                                                                    this.setState({
                                                                        reimbursementDialogOpen: true,
                                                                        reimbursementEditing: {'description':null,'requested_amount':null,'match':null,'labor_match':null},
                                                                        reimbursementFormKey: window.performance.now(),
                                                                    })
                                                            }}>
                                                            <AddIcon className={classes.iconAdd} />
                                                            &nbsp;&nbsp;&nbsp;Add Reimbursement Line Item 
                                                        </Button>   
                                                        <Table className={classes.table}>
                                                            <EnhancedTableHead
                                                                order={order}
                                                                orderBy={orderBy}
                                                                onRequestSort={this.handleRequestSort}
                                                                columnData={reimbursementHeader}
                                                            />
                                                            <TableBody>
                                                                {(() => {
                                                                    if (tempReimbursements.length < 1) {
                                                                        return (
                                                                            <TableRow>
                                                                                <CustomTableCell colSpan={expenseHeader.length} className={classes.centerAlign}>
                                                                                    No Records Available
                                                                                </CustomTableCell>
                                                                            </TableRow>
                                                                        );
                                                                    } 
                                                                    return tempReimbursements.map((n) => (
                                                                        <TableRow hover key={ n.id }>
                                                                            <CustomTableCell className={ classes.nowrap }>
                                                                                <Tooltip title="Edit">
                                                                                    {/* EDIT RECORD will trigger createRepeatableField class*/ }
                                                                                    <Button
                                                                                        onClick={ () => {
                                                                                            this.setState({
                                                                                                reimbursementEditing: n,
                                                                                                reimbursementDialogOpen:true,
                                                                                                reimbursementFormKey: window.performance.now()
                                                                                            });
                                                                                        } }
                                                                                        disabled={n.id === -1 ? true : false}
                                                                                        className={ classes.deleteWidth }>
                                                                                        <EditIcon color="primary" />
                                                                                    </Button>
                                                                                </Tooltip>

                                                                                <Tooltip title="Delete">
                                                                                    <Button
                                                                                        // disabled={inprogressReportingReadOnly}
                                                                                        onClick={() =>{
                                                                                            this.setState(()=>{
                                                                                                return {tempReimbursements: tempReimbursements.filter((tc) => tc !== n)}
                                                                                            },()=>this.calculateReimbursements() )

                                                                                        }}
                                                                                        disabled={n.id === -1 ? true : false || inprogressReportingReadOnly}
                                                                                        className={ classes.deleteWidth }>
                                                                                        <DeleteIcon color="inherit" />
                                                                                        
                                                                                    </Button>
                                                                                </Tooltip>
                                                                            </CustomTableCell>
                                                                            {/* PRINTING OF THE DATA IN THE TABLE */ } 
                                                                            <CustomTableCell>{n.description}</CustomTableCell>   
                                                                            <CustomTableCell>
                                                                                <NumberFormat
                                                                                    prefix="$"
                                                                                    mask="_"
                                                                                    displayType={"text"}
                                                                                    value={n.requested_amount}
                                                                                    thousandSeparator={true}
                                                                                />
                                                                            </CustomTableCell> 
                                                                            <CustomTableCell>
                                                                                <NumberFormat
                                                                                    prefix=""
                                                                                    mask="_"
                                                                                    displayType={"text"}
                                                                                    value={n.match}
                                                                                    thousandSeparator={true}
                                                                                />
                                                                            </CustomTableCell>
                                                                            <CustomTableCell>
                                                                                <NumberFormat
                                                                                    prefix=""
                                                                                    mask="_"
                                                                                    displayType={"text"}
                                                                                    value={n.labor_match}
                                                                                    thousandSeparator={true}
                                                                                />
                                                                            </CustomTableCell>      
                                                                        </TableRow>
                                                                    ));
                                                                })()}
                                                            </TableBody>
                                                        </Table>
                                                    </Paper>
                                                </Grid> 
                                            </AccordionDetails>
                                            <AccordionDetails style={{ paddingBottom: 27, backgroundColor: "f6f0f0" }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                        useTextFormat={true}    
                                                        field="total_reimbursement" 
                                                        disabled={true}
                                                        fullWidth 
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Total Requested Funds" 
                                                                helpText="Total funds requested from the Watershed District. This total includes past reimbursement requests."
                                                            />
                                                        } 
                                                        />
                                                </Grid>
                                                <Grid item xs={6}> 
                                                    <TextField
                                                        useTextFormat={true}   
                                                        field="total_match"
                                                        disabled={true}
                                                        fullWidth
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Total Match"
                                                                helpText="Labor and monetary match provided. This total includes past reimbursement requests.
                                                                "
                                                            />
                                                        } 
                                                    />
                                                </Grid> 
                                                
                                            </Grid>
                                            </AccordionDetails>
                                            <AccordionDetails style={{ paddingBottom: 27, backgroundColor: "f6f0f0" }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                        // useTextFormat={true}    
                                                        field="check_made_to" 
                                                        disabled={inprogressReportingReadOnly}
                                                        fullWidth 
                                                        label= "Who should the reimbursement check be made out to? *"  
                                                        />
                                                </Grid>
                                                <Grid item xs={6}> 
                                                    <TextField
                                                        // useTextFormat={true}   
                                                        field="check_mailed_to"
                                                        disabled={inprogressReportingReadOnly}
                                                        fullWidth
                                                        label= "Where should reimbursement check be mailed? *" 
                                                    />
                                                </Grid> 
                                                <Grid item xs={12}>
                                                    <TextField field="summary"  
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Summary of Major Activities *"
                                                                helpText="Provide a short overview of Cost Share activities. Include dates and time periods during
                                                                which activities were completed and who was involved."
                                                            />
                                                        }  
                                                        disabled={inprogressReportingReadOnly}
                                                        multiline rows={2} 
                                                        fullWidth/>  
                                                </Grid>
                                                <Grid item xs={6}> 
                                                    {/* <Checkbox disabled={inprogressReportingReadOnly} 
                                                        eventHandle={(e)=>{
                                                        this.setState({showProgressExtra: e})
                                                    }} field="final_request"  label="Is this your final reimbursement request?"/> */}
                                                     <RadioGroup
                                                        field="final_request"
                                                        disabled={inprogressReportingReadOnly}
                                                        fullWidth
                                                        options={[
                                                            { label: "Yes", value: "Yes" },
                                                            { label: "No", value: "No" }, 
                                                        ]}
                                                        eventHandle={(e)=>{
                                                            this.setState({showProgressExtra: e})
                                                        }}
                                                        alignment={true}
                                                        label="Is this your final reimbursement request?" 
                                                        
                                                    />
                                                </Grid>
                                                <Grid item xs={6}/> 
                                                {showProgressExtra === 'Yes' && (
                                                    <>
                                                    <Grid item xs={12}>
                                                        <TextField field="describe_project"  
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Describe how the project addressed one or more of the goals of the Cost Share Program. *"
                                                                    helpText={`
                                                                    • Improve water quality or increase the capacity of the watershed to store water 
                                                                    • Preserve, protect, and restore native plant and wildlife habitats 
                                                                    • Protect and preserve groundwater quality and quantity`}
                                                                />
                                                            }  
                                                            disabled={inprogressReportingReadOnly}
                                                            multiline rows={3} 
                                                            fullWidth/>  
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField field="education"   
                                                            label="Describe how the project provided education value regarding the project’s environmental. *" 
                                                            disabled={inprogressReportingReadOnly}
                                                            multiline rows={3} 
                                                            fullWidth/>  
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField field="project_outcome"  
                                                            label={"Describe the outcomes of the project. Describe what makes you most proud about the project. *"} 
                                                            disabled={inprogressReportingReadOnly}
                                                            multiline rows={3} 
                                                            fullWidth/>  
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField field="project_challenges"  
                                                            label="Describe any changes that had to be made to original plans due to site conditions,
                                                            regulatory processes, etc. and any challenges with implementing the project and its impacts. Indicate any ways in which Nine Mile Creek staff could have better assisted you in
                                                            addressing the challenges. *"  
                                                            disabled={inprogressReportingReadOnly}
                                                            multiline rows={3} 
                                                            fullWidth/>  
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField field="project_longevity"  
                                                            label="How were the results of the project shared and with whom? What education and
                                                            outreach was done about the project and its impacts?
                                                            What will the long-term impact of the project be?
                                                            Describe any follow-up projects that will occur because of the Cost Share grant. *
                                                            "
                                                            
                                                            disabled={inprogressReportingReadOnly}
                                                            multiline rows={3} 
                                                            fullWidth/>  
                                                </Grid>
                                                </>
                                                )}
                                            <br></br>
                                            <Grid item xs={12} xl={10}  classes={{ root: classes.root }}> 
                                                <Typography variant="subtitle1"><b>Reimbursement Attachments *</b></Typography>
                                                <Typography gutterBottom>
                                                    1.) Provide at least {this.formApi.getValue('final_request') === 'No' ? 1: 3} high resolution photos of the project. If applicable, please include before, during, and after pictures.  <br></br>
                                                    2.) Please attach all  receipts for items in the reimbursement request. 
                                                </Typography>
                                                <Grid container spacing={2} style={{ padding: 10 }}>
                                                    <Grid item xs={2} style={{display:"grid", paddingLeft:0,height:46}}>
                                                        <Button
                                                            onClick={() =>
                                                                this.setState({
                                                                    attachmentReimbursementDialogOpen: true,
                                                                    attachmentReimbursementEditing: null,
                                                                    attachmentReimbursementFormKey: window.performance.now(), 
                                                                })
                                                            }
                                                            disabled={inprogressReportingReadOnly}
                                                            variant="contained"
                                                            color="primary">
                                                            Add Attachment
                                                        </Button>  
                                                    </Grid> 
                                                </Grid> 
                                                <Table style={{ marginTop: 8 }}>
                                                    <EnhancedTableHead
                                                        columnData={columnReimbursementAttachments} 
                                                    />
                                                    <TableBody>
                                                        {!inprogressReportingReadOnly && temp_reimbursement_attachments.map((n) =>  
                                                            <TableRow hover>
                                                                <CustomTableCell className={classes.nowrap}>
                                                                    {/* {n.file.includes("https") && ( */}
                                                                        <Tooltip title="Open Link">
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={() => { //show user what they uploaded if they forgot
                                                                                    var a = document.createElement("a");
                                                                                    a.href = n.file;
                                                                                    a.download = n.name;
                                                                                    document.body.appendChild(a);
                                                                                    a.click();
                                                                                    a.remove();
                                                                                }}
                                                                                className={classes.deleteWidth}>
                                                                                <ViewIcon color="primary" />
                                                                            </Button>
                                                                        </Tooltip>
                                                                    {/* )} */}
                                                                    <Tooltip title="Delete">
                                                                        <Button
                                                                            color="primary"
                                                                            onClick={() => {
                                                                            return(this.setState({
                                                                                    temp_reimbursement_attachments: temp_reimbursement_attachments.filter((tc) => tc !== n)
                                                                                })) 
                                                                                
                                                                            }
                                                                            }
                                                                            // disabled={is_read_only}
                                                                            className={classes.deleteWidth}>
                                                                            <DeleteIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </CustomTableCell>
                                                                <CustomTableCell>{n.name}</CustomTableCell> 
                                                                <CustomTableCell>{n.notes}</CustomTableCell>
                                                            </TableRow> 
                                                        )} 
                                                    </TableBody>
                                                </Table>   
                                                <Grid item xs={12} style={{display:'none'}}>
                                                    <TextField
                                                    useTextFormat={true}    
                                                    field="total_hrs" 
                                                    disabled={true}
                                                    fullWidth 
                                                    label="Total hrs" 
                                                    />
                                                </Grid>
                                                </Grid>
                                            </Grid> 
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    )}  
                                    {activeStep === 10 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                          <Accordion defaultExpanded>
                                              <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                  <Typography variant="subtitle1" gutterBottom>
                                                  <b><u>Annual Reporting</u></b> 
                                                  </Typography>
                                              </AccordionSummary> 
                                              <AccordionDetails style={{paddingTop:25,paddingBottom:20, backgroundColor: "f6f0f0" }}>
                                                  <Grid container spacing={2}> 
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            As part of your Cost Share Maintenance Agreement with the District, you are required to provide
                                                            yearly reports on the condition of your project for {appData.requested_funds <= 5000 ? 5 : 10} years. 
                                                            Please fill out the questions below.
                                                        </Typography>  
                                                  </Grid> 
                                              </AccordionDetails> 
                                              <AccordionDetails style={{ paddingBottom: 27, backgroundColor: "f6f0f0" }}> 
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <TextField field="annual_summary"  
                                                            label={"Summary of Project Performance *" 
                                                            }  
                                                            disabled={annualReportingReadOnly}
                                                            multiline rows={3} 
                                                            fullWidth/>  
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField field="summary_main"   
                                                            label="Summary of Maintenance Activities *" 
                                                            disabled={annualReportingReadOnly}
                                                            multiline rows={3} 
                                                            fullWidth/>  
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField field="main_concerns"  
                                                            label={"Do you have any maintenance concerns *"} 
                                                            disabled={annualReportingReadOnly}
                                                            multiline rows={3} 
                                                            fullWidth/>  
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField field="comments"  
                                                            label="Additional Comments *"  
                                                            disabled={annualReportingReadOnly}
                                                            multiline rows={3} 
                                                            fullWidth/>  
                                                    </Grid> 
                                              <br></br>
                                              <Grid item xs={12} xl={10}  classes={{ root: classes.root }}> 
                                                  <Typography variant="subtitle1"><b>Annual Attachments *</b></Typography>
                                                  <Typography gutterBottom>
                                                      1.) Provide at least three high resolution photos of the project. <br></br>
                                                      2.) Include a photo of each phase of the project, if applicable (before, during, after). 
                                                  </Typography>
                                                  <Grid container spacing={2} style={{ padding: 10 }}>
                                                      <Grid item xs={2} style={{display:"grid", paddingLeft:0,height:46}}>
                                                          <Button
                                                              onClick={() =>
                                                                  this.setState({
                                                                      attachmentReimbursementDialogOpen: true,
                                                                      attachmentReimbursementEditing: null,
                                                                      attachmentReimbursementFormKey: window.performance.now(), 
                                                                  })
                                                              }
                                                              disabled={annualReportingReadOnly}
                                                              variant="contained"
                                                              color="primary">
                                                              Add Attachment
                                                          </Button>  
                                                      </Grid> 
                                                  </Grid> 
                                                  <Table style={{ marginTop: 8 }}>
                                                      <EnhancedTableHead
                                                          columnData={columnReimbursementAttachments} 
                                                      />
                                                      <TableBody>
                                                          {temp_reimbursement_attachments.map((n) =>  
                                                              <TableRow hover>
                                                                  <CustomTableCell className={classes.nowrap}>
                                                                      {/* {n.file.includes("https") && ( */}
                                                                          <Tooltip title="Open Link">
                                                                              <Button
                                                                                  color="primary"
                                                                                  onClick={() => { //show user what they uploaded if they forgot
                                                                                      var a = document.createElement("a");
                                                                                      a.href = n.file;
                                                                                      a.download = n.name;
                                                                                      document.body.appendChild(a);
                                                                                      a.click();
                                                                                      a.remove();
                                                                                  }}
                                                                                  className={classes.deleteWidth}>
                                                                                  <ViewIcon color="primary" />
                                                                              </Button>
                                                                          </Tooltip>
                                                                      {/* )} */}
                                                                      <Tooltip title="Delete">
                                                                          <Button
                                                                              color="primary"
                                                                              onClick={() => {
                                                                              return(this.setState({
                                                                                      temp_reimbursement_attachments: temp_reimbursement_attachments.filter((tc) => tc !== n)
                                                                                  })) 
                                                                                  
                                                                              }
                                                                              }
                                                                              // disabled={is_read_only}
                                                                              className={classes.deleteWidth}>
                                                                              <DeleteIcon color="primary" />
                                                                          </Button>
                                                                      </Tooltip>
                                                                  </CustomTableCell>
                                                                  <CustomTableCell>{n.name}</CustomTableCell> 
                                                                  <CustomTableCell>{n.notes}</CustomTableCell>
                                                              </TableRow> 
                                                          )} 
                                                      </TableBody>
                                                  </Table>
                                                  </Grid>
                                              </Grid>
                                              </AccordionDetails>
                                          </Accordion>
                                      </Grid>
                                      )}
                                    {activeStep !== "PRINT" && (
                                        <Grid item xs={12} xl={10} className={classes.rightAlign} classes={{ root: classes.root }}>
                                            {activeStep <= 8 && (  
                                                <Button
                                                disabled={activeStep <= 1 || is_read_only}
                                                onClick={() => {
                                                    let fname = this.formApi.getValue('owner_first_name')
                                                    let lname = this.formApi.getValue('owner_last_name')
                                                    let em = this.formApi.getValue('owner_email')
                                                    let saveObj = {owner_first_name:fname, owner_last_name:lname, owner_email:em}
                                                    this.setState({saveDialogOpen:true, saveInfo:saveObj})
                                                }}
                                                variant="contained"
                                                color="primary" 
                                                style={{ marginRight: 16 }}>
                                                Save and Resume Later
                                            </Button>
                                            )}  
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={() => this.setState({ activeStep: activeStep - 1 })}
                                                // className={classes.button}
                                                >
                                                Back
                                            </Button>
                                            {/* {activeStep <= 8  && ( */}
                                                <Button
                                                    onClick={() => this.handleNext()}
                                                    disabled={appData && (appData.apprec.status === 'open' || appData.apprec.status === 'closed') ?false: is_read_only} //&& activeStep === 6}
                                                    variant="contained"
                                                    color="primary"
                                                    style={{ marginLeft: 16 }}>
                                                    {activeStep === 8 && (!appData || (appData && appData.apprec.status === 'Save Not Completed')) ? "Submit Grant Application" : "Next"}
                                                </Button> 
                                            {/* )} */}
                                            {activeStep === 9 && (
                                                 
                                                <Button
                                                    onClick={() => this.handleNext()}
                                                    disabled={inprogressReportingReadOnly }
                                                    variant="contained"
                                                    color="primary"
                                                    style={{ marginLeft: 16 }}> 
                                                     Submit Reimbursement Request
                                                </Button> 
                                            )}
                                            {activeStep === 10 && (
                                                 
                                                 <Button
                                                     onClick={() => this.handleNext()}
                                                     disabled={annualReportingReadOnly}
                                                     variant="contained"
                                                     color="primary"
                                                     style={{ marginLeft: 16 }}>
                                                     Submit Annual Reporting
                                                 </Button> 
                                             )}
                                        </Grid>
                                    )}
                                </Grid>
                                <Dialog open={saveDialogOpen} classes={{ paper: classes.minWidth }}>
                                    <Toolbar style={{ backgroundColor: "lightgray" }}>
                                        <Typography variant="h6" className={classes.flex}>
                                            Save Progress and Resume Later? 
                                        </Typography>
                                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({ saveDialogOpen: false })}>
                                            <Close />
                                        </IconButton>
                                    </Toolbar>
                                    <Toolbar>
                                        <Typography variant="h7" className={classes.flex}>
                                            Note: An email will be sent to the below email address where a link will be provided to resume this application. Keep in mind the application will not be processed until its fully filled out and submitted (i.e. step 9).
                                        </Typography>
                                    </Toolbar>
                                    <DialogContent className={classes.dialogMin}>
                                        <DialogContentText>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        disabled={is_read_only}
                                                        field="owner_first_name"
                                                        label="First Name *"
                                                        fullWidth
                                                        eventHandle={(e)=>
                                                            this.formApi.setValue('owner_first_name', e)}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        disabled={is_read_only}
                                                        field="owner_last_name"
                                                        label="Last Name *"
                                                        fullWidth
                                                        eventHandle={(e)=>
                                                        this.formApi.setValue('owner_last_name', e)}
                                                    />
                                                </Grid> 
                                                <Grid item xs={12}>
                                                    <TextField disabled={is_read_only} field="owner_email" label="Email *" fullWidth   eventHandle={(e)=>
                                                        this.formApi.setValue('owner_email', e)} />
                                                </Grid>
                                            
                                                <Grid item xs={6}>
                                                    <Button  fullWidth onClick={() => this.setState({ saveDialogOpen: false})}>
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button  disabled={is_read_only} fullWidth type="submit" variant="contained" color="primary"
                                                    onClick={()=>{
                                                        this.setState({submittedSave:true},()=>{
                                                        this.formApi.submitForm("Save Not Completed")})
                                                    }}>
                                                        Submit
                                                    </Button>
                                                </Grid>
                                            </Grid> 
                                        </DialogContentText>
                                    </DialogContent>
                                </Dialog>
                            </>
                        </form>
                    )}
                </Form>
               
                {(activeStep === 9 && appData && (appData.apprec.status === 'open' || inprogressReportingReadOnly === true)) && (
                    <Grid item xs={12} xl={10} style={{ marginLeft: 15, marginRight:15 }} classes={{ root: classes.root }}>
                    
                    <Typography style={{marginLeft:15}} variant="subtitle1" gutterBottom>
                        <b><u>Historical Reimbursements for this Application</u></b> 
                    </Typography>
                    {historicInprogressReporting && historicInprogressReporting.length === 0 && (
                           <Typography style={{marginLeft:15}} variant="subtitle1" gutterBottom>
                            <b>0 Reimbursements for this Application</b> 
                            </Typography>
                    )}  

                    {historicInprogressReporting && historicInprogressReporting.map((r)=>{ 
                        return(
                            <Form
                            dontValidateOnMount={true} 
                            key={r.id}
                            defaultValues={r} 
                            >
                            {(formApi1) => (
                            <form onSubmit={formApi1.submitForm}>
                                <br></br>
                                    <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                    <Accordion defaultExpanded={false}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                            <Typography variant="subtitle1" gutterBottom>
                                            <b><u>Reimbursement Request on {new Date(r.date_created.replaceAll('-','/')).toLocaleString().split(',')[0]}</u></b> 
                                            </Typography>
                                        </AccordionSummary> 
                                        <AccordionDetails style={{paddingTop:25,paddingBottom:55, backgroundColor: "f6f0f0" }}>
                                            <Grid container spacing={2}>
                                                <Paper className={classes.root}>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Payments on cost share grants are reimbursement based. NMCWD can reimburse funds
                                                    throughout the grant period, as requested by the grantee, up to the final 10% of the grant.
                                                    The final 10% of the grant is reimbursed upon successful completion of the project and
                                                    submission and approval of a final report.
                                                </Typography> 
                                                    <Table className={classes.table}>
                                                        <EnhancedTableHead
                                                            order={order}
                                                            orderBy={orderBy}
                                                            onRequestSort={this.handleRequestSort}
                                                            columnData={reimbursementHeader}
                                                        />
                                                        <TableBody>
                                                            {(() => {
                                                                if (historicInprogressExpenses.filter((x)=>{return x.ninemilegrantinprogressreporting === r.id ? x: null}).length < 1) {
                                                                    return (
                                                                        <TableRow>
                                                                            <CustomTableCell colSpan={expenseHeader.length} className={classes.centerAlign}>
                                                                                No Records Available
                                                                            </CustomTableCell>
                                                                        </TableRow>
                                                                    );
                                                                } 
                                                                let t = historicInprogressExpenses.filter((x)=>{return x.ninemilegrantinprogressreporting === r.id ? x: null})
                                                                return t.map((n) => (
                                                                    <TableRow hover key={ n.id }>
                                                                        <CustomTableCell className={ classes.nowrap }>
                                                                         
                                                                        </CustomTableCell>
                                                                        {/* PRINTING OF THE DATA IN THE TABLE */ } 
                                                                        <CustomTableCell>{n.description}</CustomTableCell>   
                                                                        <CustomTableCell>
                                                                            <NumberFormat
                                                                                prefix="$"
                                                                                mask="_"
                                                                                displayType={"text"}
                                                                                value={n.requested_amount}
                                                                                thousandSeparator={true}
                                                                            />
                                                                        </CustomTableCell> 
                                                                        <CustomTableCell>
                                                                            <NumberFormat
                                                                                prefix=""
                                                                                mask="_"
                                                                                displayType={"text"}
                                                                                value={n.match}
                                                                                thousandSeparator={true}
                                                                            />
                                                                        </CustomTableCell>  
                                                                        <CustomTableCell>
                                                                                <NumberFormat
                                                                                    prefix=""
                                                                                    mask="_"
                                                                                    displayType={"text"}
                                                                                    value={n.labor_match}
                                                                                    thousandSeparator={true}
                                                                                />
                                                                            </CustomTableCell>    
                                                                    </TableRow>
                                                                ));
                                                            })()}
                                                        </TableBody>
                                                    </Table>
                                                </Paper>
                                            </Grid> 
                                        </AccordionDetails>
                                        <AccordionDetails style={{ paddingBottom: 27, backgroundColor: "f6f0f0" }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <TextField
                                                    useTextFormat={true}    
                                                    field="total_reimbursement" 
                                                    disabled={true}
                                                    fullWidth 
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Total Requested Funds" 
                                                            helpText="Total funds requested from the Watershed District. This total includes past reimbursement requests."
                                                        />
                                                    } 
                                                    />
                                            </Grid>
                                            <Grid item xs={6}> 
                                                <TextField
                                                    useTextFormat={true}   
                                                    field="total_match"
                                                    disabled={true}
                                                    fullWidth
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Total Match"
                                                            helpText="Labor and monetary match provided. This total includes past reimbursement requests.
                                                            "
                                                        />
                                                    } 
                                                />
                                            </Grid> 
                                            
                                        </Grid>
                                        </AccordionDetails>
                                        <AccordionDetails style={{ paddingBottom: 27, backgroundColor: "f6f0f0" }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <TextField   
                                                    field="check_made_to" 
                                                    disabled={true}
                                                    fullWidth 
                                                    label= "Who should the reimbursement check be made out to? *"  
                                                    />
                                            </Grid>
                                            <Grid item xs={6}> 
                                                <TextField 
                                                    field="check_mailed_to"
                                                    disabled={true}
                                                    fullWidth
                                                    label= "Where should reimbursement check be mailed? *" 
                                                />
                                            </Grid> 
                                            <Grid item xs={12}>
                                                <TextField field="summary"  
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Summary of Major Activities *"
                                                            helpText="Provide a short overview of Cost Share activities. Include dates and time periods during
                                                            which activities were completed and who was involved."
                                                        />
                                                    }  
                                                    disabled={true}
                                                    multiline rows={2} 
                                                    fullWidth/>  
                                            </Grid>
                                            <Grid item xs={6}> 
                                                <RadioGroup
                                                    field="final_request"
                                                    disabled={true}
                                                    fullWidth
                                                    options={[
                                                        { label: "Yes", value: "Yes" },
                                                        { label: "No", value: "No" }, 
                                                    ]}
                                                    eventHandle={(e)=>{
                                                        this.setState({showProgressExtra: e})
                                                    }}
                                                    alignment={true}
                                                    label="Is this your final reimbursement request?" 
                                                    
                                                />
                                            </Grid>
                                            <Grid item xs={6}/> 
                                            {r.final_request === 'Yes' && (
                                                <>
                                                <Grid item xs={12}>
                                                    <TextField field="describe_project"  
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Describe how the project addressed one or more of the goals of the Cost Share Program"
                                                                helpText={`
                                                                • Improve water quality or increase the capacity of the watershed to store water 
                                                                • Preserve, protect, and restore native plant and wildlife habitats 
                                                                • Protect and preserve groundwater quality and quantity`}
                                                            />
                                                        }  
                                                        disabled={true}
                                                        multiline rows={3} 
                                                        fullWidth/>  
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField field="education"   
                                                        label="Describe how the project provided education value regarding the project’s environmental" 
                                                        disabled={true}
                                                        multiline rows={3} 
                                                        fullWidth/>  
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField field="project_outcome"  
                                                        label={"Describe the outcomes of the project. Describe what makes you most proud about the project."} 
                                                        disabled={true}
                                                        multiline rows={3} 
                                                        fullWidth/>  
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField field="project_challenges"  
                                                        label="Describe any changes that had to be made to original plans due to site conditions,
                                                        regulatory processes, etc. and any challenges with implementing the project and its impacts. Indicate any ways in which Nine Mile Creek staff could have better assisted you in
                                                        addressing the challenges."  
                                                        disabled={true}
                                                        multiline rows={3} 
                                                        fullWidth/>  
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField field="project_longevity"  
                                                        label="How were the results of the project shared and with whom? What education and
                                                        outreach was done about the project and its impacts?
                                                        What will the long-term impact of the project be?
                                                        Describe any follow-up projects that will occur because of the Cost Share grant.
                                                        "
                                                        
                                                        disabled={true}
                                                        multiline rows={3} 
                                                        fullWidth/>  
                                            </Grid>
                                            </>
                                            )}
                                        <br></br>
                                        <Grid item xs={12} xl={10}  classes={{ root: classes.root }}> 
                                            <Typography variant="subtitle1"><b>Reimbursement Attachments *</b></Typography>
                                            <Typography gutterBottom>
                                                1.) Provide at least 1 high resolution photos of the project. If applicable, please include before, during, and after pictures. <br></br>
                                                2.) Please attach all receipts for items in the reimbursement request. 
                                            </Typography>
                                            <Grid container spacing={2} style={{ padding: 10 }}>
                                                    
                                            </Grid> 
                                            <Table style={{ marginTop: 8 }}>
                                                <EnhancedTableHead
                                                    columnData={columnReimbursementAttachments} 
                                                />
                                                <TableBody> 
                                                    {historicInprogressAttachment.filter((x)=>{return x.ninemilegrantinprogressreporting === r.id ? x: null}).map((n) =>  
                                                        <TableRow hover>
                                                            <CustomTableCell className={classes.nowrap}> 
                                                                    <Tooltip title="Open Link">
                                                                        <Button
                                                                            color="primary"
                                                                            onClick={() => { //show user what they uploaded if they forgot
                                                                                var a = document.createElement("a");
                                                                                a.target='_blank'
                                                                                a.href = n.file;
                                                                                a.download = n.name;
                                                                                document.body.appendChild(a);
                                                                                a.click();
                                                                                a.remove();
                                                                            }}
                                                                            className={classes.deleteWidth}>
                                                                            <ViewIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip> 
                                                            </CustomTableCell>
                                                            <CustomTableCell>{n.name}</CustomTableCell> 
                                                            <CustomTableCell>{n.notes}</CustomTableCell>
                                                        </TableRow> 
                                                    )} 
                                                </TableBody>
                                            </Table>
                                            </Grid>
                                        </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </form>
                        )}
                    </Form>)})
                } 
                </Grid>
                )}   
                {(activeStep === 10 && appData && (appData.apprec.status === 'closed' || annualReportingReadOnly === true)) && (
                    <Grid item xs={12} xl={10} style={{ marginLeft: 15, marginRight:15 }} classes={{ root: classes.root }}>
                    <Typography style={{marginLeft:15}} variant="subtitle1" gutterBottom>
                        <b><u>Historical Annual Reports for this Application</u></b> 
                    </Typography>   

                    {historicAnnualReporting && historicAnnualReporting.length === 0 && (
                        <Typography style={{marginLeft:15}} variant="subtitle1" gutterBottom>
                        <b>0 Annual Reports for this Application</b> 
                        </Typography>
                    )}
           
                    {historicAnnualReporting && historicAnnualReporting.map((r)=>{     
                        return(
                            <Form
                            dontValidateOnMount={true} 
                            key={r.id}
                            defaultValues={r} 
                            >
                            {(formApi2) => (
                            <form onSubmit={formApi2.submitForm}>
                                <br></br>
                                    <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                    <Accordion defaultExpanded={false}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                            <Typography variant="subtitle1" gutterBottom>
                                            <b><u>Annual Report from {new Date(r.date_created.replaceAll('-','/')).toLocaleString().split(',')[0]}</u></b> 
                                            </Typography>
                                        </AccordionSummary>  
                                        <AccordionDetails style={{ paddingBottom: 27, backgroundColor: "f6f0f0" }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <TextField field="annual_summary"  
                                                        label={"Summary of Project Performance *" 
                                                        }  
                                                        disabled={true}
                                                        multiline rows={3} 
                                                        fullWidth/>  
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField field="summary_main"   
                                                        label="Summary of Maintenance Activities *" 
                                                        disabled={true}
                                                        multiline rows={3} 
                                                        fullWidth/>  
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField field="main_concerns"  
                                                        label={"Do you have any maintenance concerns *"} 
                                                        disabled={true}
                                                        multiline rows={3} 
                                                        fullWidth/>  
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField field="comments"  
                                                        label="Additional Comments *"  
                                                        disabled={true}
                                                        multiline rows={3} 
                                                        fullWidth/>  
                                                </Grid>  
                                            <br></br>
                                            <Grid item xs={12} xl={10}  classes={{ root: classes.root }}> 
                                                <Typography variant="subtitle1"><b>Reimbursement Attachments *</b></Typography>
                                                <Typography gutterBottom>
                                                    1.) Provide at least three high resolution photos of the project.  <br></br>
                                                    2.) Include a photo of each phase of the project, if applicable (before, during, after). 
                                                </Typography>
                                                <Grid container spacing={2} style={{ padding: 10 }}>
                                                        
                                                </Grid> 
                                                <Table style={{ marginTop: 8 }}>
                                                    <EnhancedTableHead
                                                        columnData={columnReimbursementAttachments} 
                                                    />
                                                    <TableBody>
                                                        {historicAnnualAttachment.filter(a=>{return a.ninemilegrantannualreporting ===  r.id ? a: null}).map((n) =>  
                                                            <TableRow hover>
                                                                <CustomTableCell className={classes.nowrap}> 
                                                                        <Tooltip title="Open Link">
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={() => { //show user what they uploaded if they forgot
                                                                                    var a = document.createElement("a");
                                                                                    a.target='_blank'
                                                                                    a.href = n.file;
                                                                                    a.download = n.name;
                                                                                    document.body.appendChild(a);
                                                                                    a.click();
                                                                                    a.remove();
                                                                                }}
                                                                                className={classes.deleteWidth}>
                                                                                <ViewIcon color="primary" />
                                                                            </Button>
                                                                        </Tooltip> 
                                                                </CustomTableCell>
                                                                <CustomTableCell>{n.name}</CustomTableCell> 
                                                                <CustomTableCell>{n.notes}</CustomTableCell>
                                                            </TableRow> 
                                                        )} 
                                                    </TableBody>
                                                </Table>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </form>
                        )}
                    </Form>)})} 
                </Grid>
                )}
                <Dialog open={attachmentDialogOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar style={{ backgroundColor: "lightgray" }}>
                        <Typography variant="h6" className={classes.flex}>
                            Attachment
                        </Typography>
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({ attachmentDialogOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <DialogContentText>
                            <Form
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                key={attachmentFormKey}
                                defaultValues={attachmentEditing}
                                validateError={this.errorValidatorAttachment}
                                onSubmit={(values) => this.addAttachment(values)}>
                                {(formApi) => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            {(attachmentType === 'Aerial Image' || attachmentType === 'Raingarden Planting' || attachmentType === 'Planting Layout' || 
                                            attachmentType === 'Shoreline Buffer' || attachmentType === 'Native Planting' || attachmentType === 'Pervious Pavement' || attachmentType === 'Green Roof'|| attachmentType === 'Chloride Reduction') && (
                                                <>
                                                <Grid item xs={12}>
                                                    <Typography gutterBottom>
                                                        Only these file types can be directly uploaded as an attachment. All other file types should be
                                                        put into a .zip file and then uploaded. Direct supported file types are: PDF (.pdf), Microsoft
                                                        Word (.doc, .docx), Microsoft Excel (.xls, .xlsx), Images (.png, .jpg, .jpeg, .tiff, .bmp),
                                                        Microsoft Powerpoint (.ppt, .pptx), Zip files (.zip).
                                                    </Typography>
                                            
                                                  <br/>
                                                  <FileInput field="file" id="file_upl" fullWidth label="File *" />
                                              </Grid>
                                                <Grid item xs={12}>
                                                    <TextField field="name" label="Name *" fullWidth />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField field="notes" label="Notes" fullWidth />
                                                </Grid>
                                                </>
                                            )}  
                                            <Grid item xs={6}>
                                                <Button fullWidth onClick={() => this.setState({ attachmentDialogOpen: false, attachmentType:null })}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth type="submit" variant="contained" color="primary"> 
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog open={attachmentReimbursementDialogOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar style={{ backgroundColor: "lightgray" }}>
                        <Typography variant="h6" className={classes.flex}>
                            Attachment
                        </Typography>
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({ attachmentReimbursementDialogOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <DialogContentText>
                            <Form
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                key={attachmentReimbursementFormKey}
                                defaultValues={attachmentReimbursementEditing}
                                // validateError={this.errorValidatorAttachment}
                                onSubmit={(values) => this.addReimbursementAttachment(values)}>
                                {(formApi) => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            <>
                                                <Grid item xs={12}>
                                                    <Typography gutterBottom>
                                                        Only these file types can be directly uploaded as an attachment. All other file types should be
                                                        put into a .zip file and then uploaded. Direct supported file types are: PDF (.pdf), Microsoft
                                                        Word (.doc, .docx), Microsoft Excel (.xls, .xlsx), Images (.png, .jpg, .jpeg, .tiff, .bmp),
                                                        Microsoft Powerpoint (.ppt, .pptx), Zip files (.zip).
                                                    </Typography>
                                            
                                                  <br/>
                                                  <FileInput field="file" id="file_upl" fullWidth label="File *" />
                                              </Grid>
                                                <Grid item xs={12}>
                                                    <TextField field="name" label="Name *" fullWidth />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField field="notes" label="Notes" fullWidth />
                                                </Grid>
                                                </> 
                                            <Grid item xs={6}>
                                                <Button fullWidth onClick={() => this.setState({ attachmentReimbursementDialogOpen: false })}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth type="submit" variant="contained" color="primary"> 
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog open={expenseDialogOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar style={{ backgroundColor: "lightgray" }}>
                        <Typography variant="h6" className={classes.flex}>
                            Add Budget Line Item
                        </Typography>
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({ expenseDialogOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <Toolbar>
                        <Typography variant="h7" className={classes.flex} style={{marginTop:-10}}>
                            Note: Please fill in each expense item.
                        </Typography>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <DialogContentText>
                            <Form
                                // getApi={(el) => (this.formApi = el)}
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                key={expenseFormKey}
                                defaultValues={expenseEditing}
                                validateError={this.errorValidatorExpense}
                                onSubmit={(values) => this.addExpense(values)}>
                                {(formApi) => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <TextField  fullWidth disabled={is_read_only} field="item_name" 
                                                   label={
                                                        <HelpLabel
                                                            inputLabel="Item Description/task *"
                                                            helpText="Line item or task required for project"
                                                        />
                                                    }  
                                                    multiline rows={2}/>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField useTextFormat disabled={is_read_only}  field="nmcwd_grant" 
                                                   label={
                                                        <HelpLabel
                                                            inputLabel="Requested Funds ($)"
                                                            helpText="Cost of item covered by grant funding"
                                                        />
                                                    }   
                                                    fullWidth   
                                                   />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField  useTextFormat disabled={is_read_only} field="match" 
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Monetary Match ($)"
                                                            helpText="Cost of item covered by applicant"
                                                        />
                                                    }   
                                                fullWidth  
                                              />
                                            </Grid> 
                                            <Grid item xs={6}>
                                                <TextField  disabled={is_read_only} field="labor_match" 
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Labor Match (hrs)"
                                                            helpText="In-kind labor provided by the applicant; $20/hour of labor"
                                                        />
                                                    }    
                                                    useNumberFormat
                                                fullWidth  
                                             />
                                            </Grid>  
                                            <Grid item xs={6}/>
                                            <Grid item xs={6}/>
                                            <Grid item xs={6}>
                                                <Button  fullWidth onClick={() => this.setState({ expenseDialogOpen: false })}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button  disabled={is_read_only} fullWidth type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog> 
                {/* Step 10 only */}
                <Dialog open={reimbursementDialogOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar style={{ backgroundColor: "lightgray" }}>
                        <Typography variant="h6" className={classes.flex}>
                            Add Reimbursement Line Item
                        </Typography>
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({ reimbursementDialogOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <Toolbar>
                        <Typography variant="h7" className={classes.flex} style={{marginTop:-10}}>
                            Note: Please fill in each reimbursement item.
                        </Typography>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <DialogContentText>
                            <Form
                                // getApi={(el) => (this.formApi = el)}
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                key={reimbursementFormKey}
                                defaultValues={reimbursementEditing}
                                validateError={this.errorValidatorReimbursement}
                                onSubmit={(values) => this.addReimbusement(values)}>
                                {(formApi) => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <TextField  fullWidth disabled={inprogressReportingReadOnly} field="description" 
                                                   label={
                                                        <HelpLabel
                                                            inputLabel="Item Description *"
                                                            helpText="Line item or task required for project"
                                                        />
                                                    }  
                                                    multiline rows={2}/>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField useTextFormat disabled={inprogressReportingReadOnly}  field="requested_amount" 
                                                   label={
                                                        <HelpLabel
                                                            inputLabel="Requested Funds ($)"
                                                            helpText="Cost of item covered by grant funding"
                                                        />
                                                    }   
                                                    fullWidth   
                                                   />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField  useTextFormat disabled={inprogressReportingReadOnly} field="match" 
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Monetary Match ($)"
                                                            helpText="Cost of item covered by applicant"
                                                        />
                                                    }   
                                                fullWidth  
                                              />
                                            </Grid> 
                                            <Grid item xs={6}>
                                                <TextField useNumberFormat disabled={inprogressReportingReadOnly} field="labor_match" 
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Labor Match (hrs)"
                                                            helpText="In-kind labor provided by the applicant; $20/hour of labor"
                                                        />
                                                    }   
                                                fullWidth  
                                              />
                                            </Grid>  
                                            <Grid item xs={6}/>
                                            <Grid item xs={6}/>
                                            <Grid item xs={6}>
                                                <Button  fullWidth onClick={() => this.setState({ reimbursementDialogOpen: false })}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button  disabled={inprogressReportingReadOnly} fullWidth type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog> 
                 <WarningDialog
                    confirmAction={() => {
                        this.setState({ outOfDistrict: false });
                   
                    }}
                    cancelAction={(e) => {
                        this.setState({ outOfDistrict: false});
                    }}
                    noDeleteButtons
                    confirmText="Ok" 
                    open={outOfDistrict}
                    title="Confirm Address is within District Boundaries"
                    text={
                        "For sites located within close proximity to the approximate NMCWD boundary identified on this map, the applicant must verify the legal property boundary with District staff."
                    }
                />
                <Dialog open={alertOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                        <Typography variant="h6" className={classes.flex}>
                            Cost sharing grant required fields
                        </Typography>
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({ alertOpen: false, alerts:"" })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <DialogContentText>       
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography> <b><u>You must fill out the following required fields before submitting your application.</u></b></Typography>
                                    {alerts.split('\n').map((v,ix)=>{
                                        let idx = ix + 1
                                        if(v)return <Typography>{idx}{".) "}{v}</Typography>
                                        else return null
                                    })}
                                </Grid>    
                                <Grid item xs={12}>
                                    <Button fullWidth onClick={(e) => { 
                                            this.setState({ alertOpen: false})}}>
                                        Continue
                                    </Button>
                                </Grid>   
                            </Grid>   
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </AppContainerApplication>
        );
    }
}

NineMileGrant_ = connect(
    (state, ownProps) => ({
        application: getApplication(state, ownProps),
        // historic: getHistoric(state, ownProps),
        authState: state.auth, // Admins will have an authstate
    }),
    {
        ...ApplicationRecord.actions,
        ...ApplicationRecordStatus.actions,
        ...NineMileGrant.actions,
        ...NineMileGrantAttachment.actions, 
        ...NineMileGrantExpense.actions,
        ...NineMileGrantInprogressReporting.actions,
        ...NineMileGrantInprogressExpense.actions, 
        ...NineMileGrantInprogressAttachment.actions,
        ...NineMileGrantAnnualReporting.actions,
        ...NineMileGrantAnnualAttachment.actions,  
    }
)(NineMileGrant_);

export default withStyles(styles)(withRouter(NineMileGrant_));
