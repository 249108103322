import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Form } from "react-form";

import ReactSelectAsync from "./ReactSelectAsync";

const styles = (theme) => ({
    largeMap: {
        width: 270
    },
});

class MapSearch extends Component {
    render() {
        const { authState, view, classes } = this.props;
        return (
            <Form validateOnSubmit={false}>
                {(formApi) => (
                    <form onSubmit={formApi.submitForm} className={classes.largeMap}>
                        <ReactSelectAsync
                            authState={authState}
                            placeholder="Find MS4Front record"
                            eventHandle={(searchvalue) => {
                                if (searchvalue !== "") {
                                    view.when(() => {
                                        view.goTo({
                                            target: [searchvalue.x, searchvalue.y],
                                            zoom: 15,
                                        });
                                    });
                                }
                            }}
                        />
                    </form>
                )}
            </Form>
        );
    }
}

export default withStyles(styles)(MapSearch);
