import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form as Formm } from "react-form";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TableBody from "@material-ui/core/TableBody";
import Tooltip from "@material-ui/core/Tooltip";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Table from "@material-ui/core/Table";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NumberFormat from "react-number-format";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import TablePaginationActionsWrapped from "../../common/Paginator";
import { Field } from "../models";

// CSS in JS styles
const styles = (theme) => ({
    table: {
        minWidth: 500,
        paddingTop: "10px",
    },

    flex: {
        flex: 1,
    },
    minWidth: {
        minWidth: 600,
    },

    root: {
        width: "100%",
        overflowX: "auto",
        backgroundColor: "#f0f0f0",
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        margin: "0 8px 0 0",
    },
    centerAlign: {
        textAlign: "center",
        fontWeight: "400px",
    },
    nowrap: {
        whiteSpace: "nowrap",
    },
    //view table & s
    icon: {
        width: "32px",
        marginRight: "7%",
        height: "32px",
        padding: "0px",
    },

    button: {
        float: "right",
        marginBottom: "7px",
    },

    statistics: {
        paddingLeft: "10px",
        fontWeight: 500,
        width: "15%",
        display: "inline-block",
        color: "#262626",
    },
    statistictable: {
        width: "100%",
        paddingTop: 10,
        paddingBottom: 10,
        borderRadius: 3,
    },
    ellipsis: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 15,
        WebkitBoxOrient: "vertical",
    },
});
const allFields = Field.selectAll();

class MirrorRepeatableTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            order: "asc",
            orderBy: null,
            deleteDialogOpen: false,
            deleteDialogText: null,
            deleteDialogConfirmAction: null,
            parent: null,
            formDialogStatus: false,
            parentrecord: null,
            pageStart: 0,
            rowsPerPage: 25,
            tableDialog: false,
            filteredFields: null,
            excel_is_Open: false,
            report_selection: false,
            report_selection_rs: null,
            batch_loading: false,
            autoLoadingRecords: false,
            formFields: null,
        };
    }

    handleChangePage = (event, page) => {
        this.setState({ pageStart: page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value, pageStart: 0 });
    };

    render() {
        const { classes, tableData, group, fields } = this.props;
        const { order, orderBy, rowsPerPage, pageStart } = this.state;

        //lets get the groups to find the fields from the other section for the columns
        let groups = [];
        tableData.forEach((t) => (groups.find((g) => g === t.group) ? null : groups.push(t.group)));
        let foundFields = [];
        fields.forEach((f) => groups.find((g) => (g === f.group ? foundFields.push(f) : null)));
        foundFields = foundFields.sort((a, b) => {
            return a.order < b.order ? -1 : 1;
        });
        var columnRecord = [];
        columnRecord.push({ id: "actions", label: "", allowSort: false }); //push the first action area to be blank in the header on the table to allow for alignment
        foundFields.forEach((f) =>
            columnRecord.find((cr) => cr.label === f.name) ? null : columnRecord.push({ id: f.id, label: f.name, allowSort: false })
        );

        return (
            <Formm
                key={this.formKey}
                dontValidateOnMount={true}
                validateOnSubmit={true}
                getApi={(el) => (this.formApi = el)}
                onSubmit={(values) => this.updateFields(values)}>
                {(formApi) => (
                    <form onSubmit={formApi.submitForm}>
                        <Accordion defaultExpanded={true} style={{ marginBottom: "15px", marginTop: 15 }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                style={{ backgroundColor: "#ddd" }}
                                classes={{ root: classes.expandedMargin }}>
                                <Grid container>
                                    <Grid item xs={8}>
                                        <Typography
                                            className={classes.heading}
                                            style={{
                                                paddingLeft: "0px",
                                                fontSize: "1.15rem",
                                            }}>
                                            {group.name + " (mirrored from another section)"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                <Grid item xs={12}>
                                    {/* TABLE SECTION */}
                                    <Paper className={classes.root}>
                                        <Table className={classes.table}>
                                            <EnhancedTableHead
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={this.handleRequestSort}
                                                columnData={columnRecord}
                                            />
                                            <TableBody>
                                                {(() => {
                                                    if (tableData.length < 1) {
                                                        return (
                                                            <TableRow>
                                                                <CustomTableCell
                                                                    colSpan={columnRecord.length}
                                                                    className={classes.centerAlign}
                                                                    style={{ paddingBottom: 8 }}>
                                                                    No Records Found
                                                                </CustomTableCell>
                                                            </TableRow>
                                                        );
                                                    }
                                                    if (tableData) {
                                                        return tableData
                                                            .slice(pageStart * rowsPerPage, pageStart * rowsPerPage + rowsPerPage)
                                                            .map((n) => {
                                                                return (
                                                                    <TableRow style={{ height: 38 }} hover /*key={n.id}*/>
                                                                        <CustomTableCell />
                                                                        {/* PRINTING OF THE DATA IN THE TABLE */}
                                                                        {columnRecord.map((column, index) => {
                                                                            //dont print 0 as that is margin action location
                                                                            if (index !== 0) {
                                                                                var found =
                                                                                    n.repeatablefields &&
                                                                                    n.repeatablefields.find((actCol) => {
                                                                                        let temp = [];
                                                                                        foundFields.forEach((f) =>
                                                                                            f.name === column.label ? temp.push(f) : null
                                                                                        );

                                                                                        if (temp.find((v) => v.id === actCol.field)) {
                                                                                            return actCol.userinput;
                                                                                        }
                                                                                        return null;
                                                                                    });
                                                                                if (found) {
                                                                                    if (found.type === "Phone Number") {
                                                                                        return (
                                                                                            <CustomTableCell key={index + column.label}>
                                                                                                <NumberFormat
                                                                                                    format="+1 (###) ###-####"
                                                                                                    allowEmptyFormatting
                                                                                                    mask="_"
                                                                                                    displayType={"text"}
                                                                                                    value={found.userinput}
                                                                                                />
                                                                                            </CustomTableCell>
                                                                                        );
                                                                                    } else if (
                                                                                        found.type === "Numeric" ||
                                                                                        found.type === "Calculated"
                                                                                    ) {
                                                                                        return (
                                                                                            <CustomTableCell key={index + column.label}>
                                                                                                <NumberFormat
                                                                                                    allowEmptyFormatting
                                                                                                    mask="_"
                                                                                                    displayType={"text"}
                                                                                                    decimalScale={found.decimals}
                                                                                                    value={found.userinput}
                                                                                                />
                                                                                            </CustomTableCell>
                                                                                        );
                                                                                    } else {
                                                                                        if (found.userinput.length > 250) {
                                                                                            return (
                                                                                                <Tooltip title={found.userinput}>
                                                                                                    <CustomTableCell key={index + column.label}>
                                                                                                        <div className={classes.ellipsis}>
                                                                                                            {found.userinput}
                                                                                                        </div>
                                                                                                    </CustomTableCell>
                                                                                                </Tooltip>
                                                                                            )
                                                                                        } else {
                                                                                            return (
                                                                                                <CustomTableCell key={index + column.label}>
                                                                                                    {found.userinput}
                                                                                                </CustomTableCell>
                                                                                            )
                                                                                        }
                                                                                    }
                                                                                } else {
                                                                                    return <CustomTableCell>{"-"}</CustomTableCell>;
                                                                                }
                                                                            } else return null;
                                                                        })}
                                                                    </TableRow>
                                                                );
                                                            });
                                                    }
                                                })()}
                                            </TableBody>
                                            {tableData && tableData.length > 8 && (
                                                <TableFooter>
                                                    <TableRow>
                                                        <TablePagination
                                                            colSpan={columnRecord.length}
                                                            count={tableData.length}
                                                            rowsPerPage={rowsPerPage}
                                                            page={pageStart}
                                                            onChangePage={this.handleChangePage}
                                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                            ActionsComponent={TablePaginationActionsWrapped}
                                                        />
                                                    </TableRow>
                                                </TableFooter>
                                            )}
                                        </Table>
                                    </Paper>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </form>
                )}
            </Formm>
        );
    }
}
MirrorRepeatableTable.displayName = "MirrorRepeatableTable";

// This connects the parentRecord tables to allow for deletion ormParentRecordDelete,
MirrorRepeatableTable = connect((state, ownProps) => ({ fields: allFields(state) }), null)(MirrorRepeatableTable);

export default withStyles(styles)(withRouter(MirrorRepeatableTable));
