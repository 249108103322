/* This file contains action functions
 *
 *  Example use would be to "Importing" the needed function or "all"
 *  (ex. import * as navActions from "../common/actions";)
 *
 ****************************************************************************************************************
 *  Then use "...navActions" as the props within the connect statement
 *  EXAMPLE Create = connect(
 *    (state, ownProps) => ({
 *        useInfo: getUser(state, ownProps) //component wide state prop "user" gets derived info from database
 *    }),
 *    {
 *        ...navActions       //nav actions which can use functions from this component.
 *    }
 *    )(Create);
 *
 ****************************************************************************************************************
 * With the above example one can call the functions from this FILE in the component. The below code example reverts the UI to the /adm/manage/ page
 *
 *
 ****************************************************************************************************************/

//create constant variables
export const NAV_TOGGLE_NAVIGATION = "NAV_TOGGLE_NAVIGATION";
export const NAV_TOGGLE_PAGES = "NAV_TOGGLE_PAGES";
export const NAV_TOGGLE_REPORTS = "NAV_TOGGLE_REPORTS";
export const NAV_TOGGLE_CONFIG = "NAV_TOGGLE_CONFIG";
export const DASHBOARD_SELECTOR = "DASHBOARD_SELECTOR";
export const MAP_SETTINGS = "MAP_SETTINGS";
export const TAB_REF = "TAB_REF";
export const SW_CHECKING = "SW_CHECKING";
export const SW_REGISTERED = "SW_REGISTERED";
export const SW_INSTALLED = "SW_INSTALLED";
export const SW_UPDATE = "SW_UPDATE";
export const SW_ERROR = "SW_ERROR";
export const SW_DISABLED = "SW_DISABLED";
export const STORE_READY = "STORE_READY";

//create the action, with additional props if needed in below methods (map, button, tabref, etc)
export function navToggleNavigation() {
    return {
        type: NAV_TOGGLE_NAVIGATION,
    };
}

export function navTogglePages() {
    return {
        type: NAV_TOGGLE_PAGES,
    };
}

export function navToggleReports() {
    return {
        type: NAV_TOGGLE_REPORTS,
    };
}

export function navToggleConfig() {
    return {
        type: NAV_TOGGLE_CONFIG,
    };
}

export function setMapSettings(settings) {
    return {
        type: MAP_SETTINGS,
        map: settings,
    };
}

//create the action function + pass props/payload next goes to reducers,js
export function setTabRef(tabref) {
    return {
        type: TAB_REF,
        tabref: tabref,
    };
}

export function dashToggleMapTable(button) {
    return {
        type: DASHBOARD_SELECTOR,
        button: button,
    };
}

export function swChecking() {
    return { type: SW_CHECKING };
}

export function swRegistered() {
    return { type: SW_REGISTERED };
}

export function swInstalled() {
    return { type: SW_INSTALLED };
}

export function swUpdate() {
    return { type: SW_UPDATE };
}

export function swError(error) {
    return { type: SW_ERROR, error: error };
}

export function swDisabled(reason) {
    return { type: SW_DISABLED, reason: reason };
}

export function storeReady() {
    return { type: STORE_READY };
}
