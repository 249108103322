import React from "react";
import SubquestionIcon from "./icons/Subquestion";
import Grid from "@material-ui/core/Grid";

//contains dropdown look to a component
const Subquestion = ({ component }) => (
    <Grid container>
        <Grid item xs={1}>
            <SubquestionIcon />
        </Grid>
        <Grid item xs={11}>
            {component}
        </Grid>
    </Grid>
);

export default Subquestion;
