/******************************************************************************
 * This file encapsulates the maprecords for the selected section from List.js.
 * It lays out each group with the corrosponding fields for each group
 *
 * Author: Robert Gautier
 * Date: 4/29/19
 *******************************************************************************/

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Prompt, Link } from "react-router-dom";
import { Form } from "react-form";
import { Scrollbars } from "react-custom-scrollbars";

import { withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import HelpOutline from "@material-ui/icons/HelpOutline";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Save from "@material-ui/icons/Save";
import Description from "@material-ui/icons/Description";
import { evaluate } from "mathjs";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowForward from "@material-ui/icons/ArrowForward";
import { addBusinessDays, eachDayOfInterval, addDays, getWeekOfMonth, isMonday, isFriday, isSaturday, isSunday } from "date-fns";
// import ReactSelectAsync from "../../common/ReactSelectAsync";
import HelpLabel from "../../common/HelpLabel";
import WarningDialog from "../../common/WarningDialog";
import TimePicker from "../../common/TimePicker";
import TextField from "../../common/TextField";
import EnhancedTextField from "../../common/EnhancedTextField";
import { createSelector } from "../../common/orm";
import { ParentRecord, RecordField, RecordSection } from "../models";
import DatePicker from "../../common/DatePicker";
import RadioGroup from "../../common/RadioGroup";
import ReactSelect from "../../common/ReactSelect";
import { User } from "../../adm/models";
import Snackbar from "../../common/Snackbar";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Toolbar from "@material-ui/core/Toolbar";

const styles = (theme) => ({
    heading2: {
        fontSize: theme.typography.pxToRem(17),
        fontWeight: theme.typography.fontWeightRegular,
        display: "flex",
    },
    progress: {
        marginLeft: "auto",
        marginRight: "auto",
        display: "table",
        marginTop: 16,
    },
    nullMessage: {
        textAlign: "center",
        padding: 16,
        backgroundColor: "white",
    },
    supportIcon: {
        width: "2em",
        height: "2em",
        color: theme.palette.primary.main,
    },
    titlePanel: {
        minHeight: "auto !important",
    },
    buttons: {
        [theme.breakpoints.down("lg")]: {
            flexBasis: "16%",
            maxWidth: "16%",
        },
        [theme.breakpoints.up("xl")]: {
            flexBasis: "8%",
            maxWidth: "8%",
        },
    },
});

const getRecordSectionID = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["recordSectionId"]),
    (session, recordSection) => {
        return recordSection;
    }
);

const getParentRecordID = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["parentRecordId"]),
    (session, parentrecordid) => {
        return parentrecordid;
    }
);

const allUsers = User.selectAll();

// Get the page object + section/group/field template definitions
const getPageId = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["pageId"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (session, pageID) => {
        return [session.Page.filter((page) => page.id === pageID).first()].map((page) => ({
            sections: page.sections
                .all()
                .orderBy("id")
                .toModelArray()
                .map((section) => ({
                    _forms: section.forms
                        .all()
                        .orderBy("id")
                        .toModelArray()
                        .map((form) => ({
                            _groups: form.groups
                                .all()
                                .orderBy("order")
                                .toModelArray()
                                .map((group) => ({
                                    _fields: group.fields.all().orderBy("order").toRefArray(),
                                    ...group.ref,
                                })),
                            ...form.ref, // take all groups as a reference and tie in each field
                        })),
                    ...section.ref, // take all groups as a reference and tie in each field
                })),
            ...page.ref,
        }))[0];
    }
);
//for calc
const getForms = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["recordSectionId"]), //get sectionID from URL
    (session, recordsection) => {
        // JJ: We have the specific recordsection from ormRecordSectionLoadDetailChild in Accordion
        var foundrecordsection = session.RecordSection.withId(recordsection);

        //return function, match PK & FK from form & section table then match fields
        if (!foundrecordsection) return null;
        return session.Form.filter((form) => form.section === foundrecordsection.section.id)
            .orderBy("id")
            .toModelArray()
            .map((form) => ({
                groups: form.groups //takes form.groups from group model & return queryset as groups
                    .all() //get all and order by "order"
                    .orderBy("order")
                    .toModelArray()
                    .map((group) => ({
                        fields: group.fields.all().orderBy("order").toRefArray(),
                        ...group.ref, // tie in each field from the groups table
                    })),
                ...form.ref,
            }));
    }
);

//used for getting the fields of the parent when 'Primary Mirror' types are used
const getParentFields = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["pageId"]),
    (session, pageId) => {
        const parentsection = session.Section.filter((s) => s.parent === true && s.page === pageId).toRefArray();

        return session.Form.filter((form) => form.section === parentsection[0].id)
            .orderBy("id")
            .toModelArray()
            .map((form) => ({
                groups: form.groups
                    .all()
                    .orderBy("order")
                    .toModelArray()
                    .map((group) => ({
                        fields: group.fields.all().orderBy("order").toRefArray(),
                        ...group.ref,
                    })),
                ...form.ref,
            }));
    }
);

// Get the specific recordsection we are looking for
const getRecordSection = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["recordSectionId"]),
    (session, recordSection) => {
        // JJ: We have the specific recordsection from ormRecordSectionLoadDetailChild in Accordion
        return session.RecordSection.withId(recordSection);
    }
);

// Get the recordfields associated with the recordsection
const getRecordField = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["recordSectionId"]),
    (session, recordSection) => {
        return session.RecordField.filter((r) => r && r.recordsection === recordSection).toModelArray();
    }
);

/****************************************MAIN COMPONENT*********************************************/
class ExpansionPanelMapRecord extends Component {
    state = {
        loadingFields: false,
        loadingRS: false,
        loadingRSFinished: false,
        nullFields: false,
        actCalcFields: [],
        calculationInfo: {},
        snackbarOpen: false,
        deletingFields: false,
        DialogOpen: false,
        DialogText: null,
        DialogTitle: null,
        DialogConfirmAction: null,
        formRoute: false,
        unsavedFields: false,
        hideFields: [],
        hideDropDownFields: [],
        loadLogicFields: true,
        rfsaving: false, 
        // key: window.performance.now() //dont use with form as doesnt work with proper map field updates
    };

    updateRecordField = (values, e, formApi) => {
        const {
            // ormRecordFieldUpdate, // setSuccess,
            ormRecordFieldMapUpdate,
            save,
            recordsection,
            recordfields,
            ormParentRecordUpdateLocalOnly,
            history,
            page, 
            parentrecordid,
            ormRecordSectionLoadDetailChild,
            
        } = this.props;
        const { formRoute } = this.state;

        const original_values = Object.entries(values);

        //get rid of null values
        // JJ: See ticket #1650. Ideally updateRecordField here and in ExpansionPanelFormRecord should be practically identical? 
        // Why the differences? ExpansionPanelFormRecord works better
        //Object.keys(values).forEach((key) => (values[key] === null || values[key] === "") && delete values[key]);

        var calc = {}; //get all calc fields for updating them as well as they might not have been touched
        var hasCalc = false;
        recordfields.forEach((f) => {
            if (f.field.type === "Numeric" || f.field.type === "Calculated" || f.field.type === "Currency") {
                calc["f_" + f.id] = "";
            }
            if (f.field.type === "Calculated") hasCalc = true;
        });

        // Get fields that have been touched
        var touched_fields = formApi.getTouched();
        Object.keys(values).forEach((key) => {
            //check for calc fields flag has been set
            if (hasCalc) {
                if (!(key in touched_fields)) {
                    if (!(key in calc)) {
                        delete values[key];
                    }
                }
                //otherwise there isnt calc fields so only save touched
            }
        });
        this.setState({ rfsaving: true });
        const newValues = Object.entries(values);
        var counter = 0;
        var _this = this;

        //remove id from key so that we only have a number for placing into the table
        newValues.forEach(function (f, idx) {
            //save the key/value pair in the recordField table
            var __this = _this;
            ormRecordFieldMapUpdate(
                {
                    id: f[0].replace("f_", ""),
                    userinput: f[1],
                },
                (e) => {
                    var comp = __this;
                    if (e.status === 200) {
                        counter++;
                        if (counter === newValues.length && formRoute) {
                            comp.setState({ unsavedFields: false, actCalcFields: [], calculationInfo: {}, rfsaving: false });
                            save(true);
                            
                            if(page.id === 521){//crwd dist studies delay to update other fields on backend
                                setTimeout(()=>{
                                    ormRecordSectionLoadDetailChild(recordsection.id, (e)=>{ 
                                        setTimeout(()=>{
                                            history.push(
                                            "/page/" +
                                                page.id +
                                                "/parentrecord/" +
                                                parentrecordid +
                                                "/section/" +
                                                recordsection.section.id +
                                                "/recordsection/" +
                                                recordsection.id +
                                                "/form"
                                            );  
                                        },750)
                                          
                                    })
                                            
                                },1500)  
                            }else{
                                 history.push(
                                "/page/" +
                                    page.id +
                                    "/parentrecord/" +
                                    parentrecordid +
                                    "/section/" +
                                    recordsection.section.id +
                                    "/recordsection/" +
                                    recordsection.id +
                                    "/form"
                                );
                            }
                          
                           
                           
                        } else if (counter === newValues.length && !formRoute) {
                            comp.setState({ unsavedFields: false, actCalcFields: [], calculationInfo: {}, rfsaving: false });
                            // save(true);
                        }
                    } else {
                        comp.setState({ unsavedFields: true, rfsaving: false });
                    }
                }
            );
        });

        // Update the parentrecord label if this is a parent
        // The actual update on the DB is applied to the backend but should update the local value
        if (recordsection.section.parent) {
            //we look for append_to_header field types and if so look to see if we have a value that has been entered

            var append = recordfields.find((f) => (f.field.append_to_header === true ? f : null));
            if (append) {
                var hasAppend = null;
                Object.keys(values).forEach((key) => {
                    return key.replace("f_", "") === append.id.toString() ? (hasAppend = values[key]) : null;
                });
                append = " (" + append.userinput + ")";
            }
            recordfields.forEach((f, ix) => {
                if (f.field.identification) {
                    var val = original_values.find((n) => n[0].replace("f_", "") === f.id.toString());
                    let appendVal = hasAppend ? " (" + hasAppend + ")" : null;
                    if (val) {
                        if (appendVal && append) {
                            //value has been entered so update store with correct value
                            ormParentRecordUpdateLocalOnly({
                                id: parentrecordid,
                                parent_label: val[1] + appendVal,
                            });
                        } else if (append) {
                            //append_to_header was previously changed but not changed now so just update store to same
                            ormParentRecordUpdateLocalOnly({
                                id: parentrecordid,
                                parent_label: val[1] + append,
                            });
                        } else if (val) {
                            //append_to_header isnt being used on another field so just update store with id value
                            ormParentRecordUpdateLocalOnly({
                                id: parentrecordid,
                                parent_label: val[1],
                            });
                        }  
                    }
                }
              
                if (recordfields.length - 1 === ix && newValues.length === 0) this.setState({ rfsaving: false }); //turn off saving dialog as no fields been touched
            });  
 
            // if (page.id === 521){
            //     setTimeout(()=>{ //crwd dist studies module only--to load back in the new geometry so when they route it shows properly on map
            //         ormRecordSectionLoadDetailChild(recordsection.id);
            //     },3500)   
            // }
        }
    };

    errorValidator = (values) => {
        const { recordfields, forms, save, setSuccess } = this.props;
        const { formRoute } = this.state;
        var valObj = {};

        var findRequired = []; //find 'is_required' fields of form
        forms.forEach((f) => {
            f.groups.forEach((g) => {
                if (!g.repeatable)
                    //dont check for repeatable as that is done when rfsaving a repeatable record
                    g.fields.forEach((fi) => {
                        if (fi.is_required) {
                            recordfields.find((p) => {
                                return p.field.id === fi.id ? findRequired.push("f_" + p.id) : null;
                            });
                        }
                    });
            });
        });

        var missingCount = 0;
        Object.keys(values).forEach((k) => {
            findRequired.find((f) => {
                if (f === k && !values[k]) {
                    missingCount += 1;
                    return (valObj[f] = "Required Field");
                } else if (f === k) {
                    return (valObj[f] = null);
                } else return null;
            });
        });

        if (missingCount > 0) {
            // valObj["dummykey"] = "error";
            if (formRoute) {
                this.setState({
                    DialogOpen: true,
                    DialogTitle: "Continue without Saving?",
                    DialogText: "Are you sure you want to leave this page and lose any unsaved changes?",
                });
            } else {
                setSuccess(false, missingCount);
                save(true);
            }
        }

        return valObj;
    };

    defaultValues = () => {
        const { recordfields, forms, recordsection } = this.props;

        //get all fields for to check for autopopulate on date
        var allFields = [];
        var actualForm = forms.find((f) => {
            return f.id === recordsection.form.id;
        });
        actualForm.groups.forEach((g) => {
            return g.fields.forEach((f) => {
                allFields.push(f);
            });
        });

        //setup default values for FORM. defaultValues is setup with an array of k/v pairs (i.e. 2816: NY, 2817: 55303 etc.)
        //this is setup to allow the "field" parameter of TextField (below) to place the ID values in the correct spot on the 'expansionpanel'
        var defaultValues = {};
        recordfields.forEach(function (rf) {
            if (rf.mirrorfield) {
                defaultValues["f_" + rf.id] = rf.mirrorfield;
            } else {
                defaultValues["f_" + rf.id] = rf.userinput; //needs to be a string for form
            }
        });

        return defaultValues;
    };
    manualCalculate = (fc, value) => {
        const { actCalcFields, calculationInfo } = this.state;

        var thisCalculation = calculationInfo.find(f=>f.name === fc.fieldname) 
  
        if (thisCalculation) {
      
            var calculation = [], newActCalcFields = actCalcFields, actvalue = "", temp = {}; 

            let theFormula = thisCalculation.calculation
            theFormula.forEach(calcField=>{
                var foundfield = calcField.match(/^\[[a-zA-Z0-9._\s]+\]+$/); //has brackets

                if (foundfield && foundfield.length > 0) {
                    let removedbracketsfield = foundfield[0].replace(/[^-+*/\w\s]/gi, ""); //remove brackets from found fields
                    removedbracketsfield = removedbracketsfield.replaceAll("\n"," ") //replace bs new lines
              
                    // eslint-disable-next-line
                    var dummy = newActCalcFields.find((afield) => {
                        //chk state for field name
                        if (afield.fieldname === removedbracketsfield) {
                            if (afield.fieldname === fc.fieldname || (fc.field && afield.fieldname === fc.field.name)) {
                                // eliminate "" in database to zero as we cant calculate a blank string
                                if (actvalue === "") actvalue = 0;

                                temp = { ...afield }; //clone
                                temp["userinput"] = actvalue; //set new value
                                return calculation.push(actvalue);
                            } else {
                                //nonmatch eliminate "" in database to zero as we cant calculate a blank string
                                if (afield.userinput === "" || afield.userinput === undefined) {
                                    afield.userinput = 0;
                                }
                                var removeUnits = afield.userinput.toString().replace(/[^0-9.]/g, ""); //remove any units with userinput that was already saved
                                return calculation.push(removeUnits); 
                            }
                        } else {
                            return null;
                        }
                    });
                   
                } else {
                    //operator/static values in calculation formula push
                    calculation.push(calcField);
                }
            })
            let manual = 'manual'
            var result = this.eval(calculation, thisCalculation, manual, evaluate);  //evaluate from its inputs
                
            //recreate updated input calc fields for state
            newActCalcFields = actCalcFields.map((af) => {
                if (af.id === fc.id) {
                    let t = {...af}; //clone
                    t["userinput"] = result; //set new value
                    return t;
                }else {
                    return af; //return non-touched field
                }
            });
        }
       
        this.setState({ actCalcFields: newActCalcFields }, ()=>{
              this.calculate(thisCalculation, result) //now update values in other calc fields that maybe usiung this field
        }); 
        
    };


    calculate = (fc, value) => {
        const { actCalcFields, calculationInfo } = this.state;
        var actvalue = value.split(",").join(""); //remove commas within value
        actvalue = actvalue.replace(/[^0-9.]/g, ""); //remove any units with field

        if (actCalcFields.length > 0 && actvalue !== "" && actvalue !== "-" && actvalue.match(/^[0-9.-]+$/)) {
           
            //check that not blank input field and no characters 
            var calculation = [], temp = {}, 
            newActCalcFields = actCalcFields, newCalcState = [];

            calculationInfo.forEach((c, i) => {
                //dont update manual calculation fields here we only do that in the manualCalculate function, but we need to update internal field value into state and calc info
                if (c.manual_calculation){
                    newCalcState = [];
                    calculationInfo.forEach((ci) => {
                        ci.id === c.id ? newCalcState.push(c) : newCalcState.push(ci);
                    });
                    //recreate updated calc input fields for state
                    newActCalcFields = newActCalcFields.map((af) => {
                        if (af.id === fc.id) {
                            let t = {...af}; //clone
                            t["userinput"] = value; //set new value
                            return t;
                        } else {
                            return af; //return non-touched field
                        }
                    });
                    return this.setState({ calculationInfo: newCalcState, actCalcFields: newActCalcFields }) 
                } 
    
                //loop through user saved calculation string and remove brackets
                return c.calculation.forEach((calcfield, index) => {
                    var foundfield = calcfield.match(/^\[[a-zA-Z0-9._\s]+\]+$/); //has brackets

                    if (foundfield && foundfield.length > 0) {
                        let removedbracketsfield = foundfield[0].replace(/[^-+*/\w\s]/gi, ""); //remove brackets from found fields
                        removedbracketsfield = removedbracketsfield.replaceAll("\n"," ") //replace bs new line
                        if (removedbracketsfield === "Internal_ID") {
                            calculation.push(newActCalcFields[0].recordsection.id);
                        } else {
                            // eslint-disable-next-line
                            var dummy = newActCalcFields.find((afield) => {
                                //chk state for field name
                                if (afield.fieldname === removedbracketsfield) {
                                    if (afield.fieldname === fc.fieldname) {
                                        //match
                                        temp = { ...afield }; //clone
                                        temp["userinput"] = actvalue; //set new value
                                        return calculation.push(actvalue);
                                    } else {
                                        //nonmatch, eliminate "" in database to zero
                                        if (afield.userinput === "") afield.userinput = 0;
                                        return calculation.push(afield.userinput);
                                    }
                                } else {
                                    return null;
                                }
                            });
                        }
                    } else {
                        //operator/static values in calculation formula push
                        calculation.push(calcfield);
                    }

                    //route to 'eval'method as this is the end of the calculation formula so evaluate it
                    if (index === c.calculation.length - 1) {
                        var result = this.eval(calculation, c, actvalue, evaluate);
                        calculation = []; //next calc array

                        //recreate updated calc fields for state
                        newActCalcFields = newActCalcFields.map((af) => {
                            if (af.id === temp.id) {
                                let t = { ...temp }; //clone
                                t["userinput"] = temp.userinput; //set new value
                                return t;
                            } else if (af.id === c.recordfield_id) {
                                let t = { ...af }; //clone
                                t["userinput"] = result; //set new value
                                return t;
                            } else {
                                return af; //return non-touched field
                            }
                        });

                        c.recordfield_userinput = result; //update calculation recordfield

                        newCalcState = [];
                        calculationInfo.forEach((ci) => {
                            ci.id === c.id ? newCalcState.push(c) : newCalcState.push(ci);
                        });
                    }
                });
            });
            this.setState({ calculationInfo: newCalcState, actCalcFields: newActCalcFields });
        }
    };

    //helper method to evaluate calculation fields--made to handle 2 or more calc fields, called from 'calculate' method
    eval = (calculation, c, value, evaluate) => {
        var resultString = ""; //build calculation string from the above array. This will be ONE string (i.e "field1 + 2...")
        calculation.forEach((f) => {
            resultString += f;
        });

        //evaluate the calculation formula
        if (resultString) {
            resultString = resultString.replace(/,/g, ""); //remove commas from numerical before evaluating
            var result = evaluate(resultString);

            if (c.measurement_unit === "Percent") {
                result = result * 100;
            } 

            if (c.decimals >= 0) result = result.toFixed(c.decimals);
            if (result === "Infinity" || result === "NaN") {
                result = "";
            }
            //set the calc field on the form to the new value
            this.formApi.setValue(`f_${c.recordfield_id}`, result);
            return result;
        }

        //chk not blank, not has numbers and chars, or chars only in fields else throw error
        if (value === "" || value.match(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/) || value.match(/^[a-zA-Z]+$/)) {
            if (value !== 'manual')this.setState({ snackbarOpen: true });
        }
    };

    //check if there are calc datatypes if so set state for 'calculations' and 'allfields'
    checkForCalcFields = () => {
        const { forms, recordfields } = this.props;
        const { actCalcFields } = this.state;

        var hascalcfield = false;
        var calculationInfo = []; //includes field id, name, type, calculation, recordfield id and recordfield username this is used matching up fields
        var allfields = [];

        //check for cal field datatype
        forms.forEach((f) => {
            f.groups.forEach((g) => {
                g.fields.forEach((fi) => {
                    if (fi.type === "Calculated") {
                        hascalcfield = true;
                        return;
                    }
                });
            });
        });

        //create two objects. One is calculation info
        if (hascalcfield) {
            forms.forEach((f) => {
                f.groups.forEach((g) => {
                    g.fields.forEach((fi) => {
                        var rf = recordfields.find((f) => f.field.id === fi.id);
                        if (rf !== undefined && fi.type === "Calculated") {
                            var temp = {};
                            temp = { ...fi }; //clone field props to a new object
                            temp["calculation"] = fi.default_value.split(",").map((t) => {
                                var i = t.trim();
                                return i;
                            });
                            temp["recordfield_id"] = rf.id;
                            temp["recordfield_userinput"] = rf.userinput;
                            calculationInfo.push(temp);
                        }

                        if (rf) {
                            //push all fields into an array for removing non-calc'd below
                            rf["fieldname"] = fi.name;
                            rf["type"] = fi.type;
                            rf["calculation"] = calculationInfo["calculation"];

                            allfields.push(rf);
                        }
                    });
                });
            });

            //save actual fields used in calculation, save calculation field with custom attachment props, cleaned up field names.
            if (allfields && actCalcFields.length === 0) {
                this.setState((s) => {
                    return { actCalcFields: allfields, calculationInfo };
                });
            }
        }
    };

    //this will autopopulate another date field based on the settings set in the field configuation (ex. add or subtract from a set fields date)
    autoPopulateAnotherDate = (value, groupField) => {
        const { recordfields } = this.props;

        if (value !== "Invalid Date" && value !== "" && value !== "Required") {
            var rf = recordfields.find((f) => f.field.id === groupField.id);

            var date = new Date(value);
            var endDate;
            //check if we are to skip weekends
            if (groupField.skip_weekends) {
                //we will add just business days to the enddate skipping weekends
                endDate = addBusinessDays(date, parseInt(groupField.values_csv));

                if (groupField.skip_holidays) {
                    date = this.skip_holiday(date, endDate);
                } else {
                    //not checking holidays so reset back to the new date value
                    date = endDate;
                }
            } else if (groupField.skip_holidays && !groupField.skip_weekends) {
                //we chk if we want to just skip holidays and not weekends
                endDate = addDays(date, parseInt(groupField.values_csv));

                //now skip holidays
                date = this.skip_holiday(date, endDate);
            } else {
                //not skip_holidays and not skip_weekends
                date.setDate(date.getDate() + parseInt(groupField.values_csv));
            }

            // now we are going to format the date
            var formatDate =
                (date.getMonth() + 1 < 10 ? "0" : "") +
                (date.getMonth() + 1) +
                "/" +
                ((date.getDate() < 10 ? "0" : "") + date.getDate()) +
                "/" +
                date.getFullYear();
            this.formApi.setValue(`f_${rf._fields.id}`, formatDate);
            this.formApi.setTouched(`f_${rf._fields.id}`, true);
        }
    };

    skip_holiday = (date, endDate) => {
        //get all day objects in the interval from start to end date
        var result = eachDayOfInterval({ start: date, end: endDate });

        var dynamicHolidays = {
            // keys are formatted as month,week,day
            "0,3,1": "Martin Luther King, Jr. Day", //3rd Mon in Jan
            "1,3,1": "President's Day", //3rd Mon in Feb
            "4,5,1": "Memorial Day", //Last Mon in May
            "8,1,1": "Labor Day", //1st Mon in Sept
            // "9,2,1": "Columbus Day", //2nd Mon in Oct
            "10,4,4": "Thanksgiving Day", //4th Thurs in Nov
            "10,4,5": "Black Friday", //4th Thurs in Nov
            // "2,2,0": "Daylight Savings Time Begins", //2nd Sun in Mar
            // "3,3,3": "Administrative Professionals Day", //3rd Wed in Apr
            // "4,2,0": "Mother's Day", //2nd Sun in May (in the US, but not all countries)
            // "5,3,0": "Father's Day", //3rd Sun in Jun (in the US, but not all countries)
            // "6,4,0": "Parents Day", //4th Sun of Jul
            //"8,1,0": "Grandparents Day",               //Technically this is the 1st Sun AFTER Labor Day
            //  so can't be represented perfectly this way
            // "8,5,0": "Gold Star Mothers Day", //Last Sun in Sep
        };
        var staticHolidays = {
            "0,1": "New Years Day", //
            "5,19": "Juneteenth",
            "6,4": "Independance Day",
            "10,11": "Veterans Day",
            // "11,24": "Christmas Eve", //xmas eve
            "11,25": "Christmas Day", //
        };

        //dynamic martin luther king day week change
        let jan1st = new Date(date.getFullYear(), 0, 1);
        let issunday = isSunday(jan1st);
        let ismonday = isMonday(jan1st);
        if (!issunday && !ismonday) {
            delete dynamicHolidays["0,3,1"];
            dynamicHolidays["0,4,1"] = "Martin Luther King, Jr. Day";
        }
        //dynamic presidents day week change
        let feb1st = new Date(date.getFullYear(), 1, 1);
        issunday = isSunday(feb1st);
        ismonday = isMonday(feb1st);
        if (!issunday && !ismonday) {
            delete dynamicHolidays["1,3,1"];
            dynamicHolidays["1,4,1"] = "Presidents Day";
        }
        //dynamic memorialday as it changes from week 5 to 6 only when 1st is on a saturday
        let may1st = new Date(date.getFullYear(), 4, 1);
        let issaturday = isSaturday(may1st);
        if (issaturday) {
            delete dynamicHolidays["4,5,1"];
            dynamicHolidays["4,6,1"] = "Memorial Day";
        }
        //dynamic labor day week change
        let sept1st = new Date(date.getFullYear(), 8, 1);
        issunday = isSunday(sept1st);
        ismonday = isMonday(sept1st);
        if (!issunday && !ismonday) {
            delete dynamicHolidays["8,1,1"];
            dynamicHolidays["8,2,1"] = "Labor Day";
        }

        //dynamic thanksgiving week change changes on friday/sat for 1st of month
        let nov1st = new Date(date.getFullYear(), 10, 1);
        let isfriday = isFriday(nov1st);
        issaturday = isSaturday(nov1st);
        if (isfriday || issaturday) {
            delete dynamicHolidays["10,4,4"];
            dynamicHolidays["10,5,4"] = "Thanksgiving Day";
        }

        //now we will check holidays with the array of days and add additional as needed
        var addDays = 0;
        result.forEach((obj) => {
            if (obj) {
                let weekOfMonth = getWeekOfMonth(obj);
                let thisDate = obj.getMonth() + "," + weekOfMonth + "," + obj.getDay();
                let staticDates = obj.getMonth() + "," + obj.getDate();
                //we are going to check for dynamic holidays within the result objects of dates & add days if we see one
                Object.keys(dynamicHolidays).forEach((k) => {
                    if (thisDate === k) {
                        addDays += 1;
                    }
                });
                Object.keys(staticHolidays).forEach((k) => {
                    if (staticDates === k) {
                        addDays += 1;
                    }
                });
            }
        });

        var newDate = addBusinessDays(endDate, addDays);
        return newDate;
    };
    //output the group fields on the screen
    fieldOutput = (n, readOnly) => {
        const { recordfields } = this.props;
        const { snackbarOpen } = this.state;
        return (
            <Grid container spacing={1}>
                {/* {n._groups.map(group => { */}
                {n._fields.map((field) => {
                    var fc = recordfields.find((f) => f.field.id === field.id);

                    //find the first instance of a field match if id within recordField table

                    if (fc !== undefined) {
                        return (
                            <Grid item xs={12}>
                                {/* CHECK ARROW FUNCTION FOR DATATYPE */}
                                {field && fc && this.checkType(field, fc, readOnly)}
                                {snackbarOpen && ( //check for calculation errors popup
                                    <Snackbar
                                        snackbarOpen={true}
                                        handleSnackbarClose={() => this.setState({ snackbarOpen: false })}
                                        isSuccess={false}
                                        missing="a numerical value for calculation"
                                        attachment={false} //err
                                        advSearch={true}
                                    />
                                )}
                            </Grid>
                        );
                    } else {
                        return null;
                    }
                })}
            </Grid>
        );
    };

    //check the type of datatype
    checkType = (groupfield, fc, readOnly) => {
        var isReadOnly;
        if (groupfield.is_read_only || readOnly) isReadOnly = true;
        if (groupfield.manual_calculation) isReadOnly = false;

        const { actCalcFields, hideFields, hideDropDownFields } = this.state;
        const { parentFields, forms, recordfields } = this.props;

        //hide logic flag for hidden fields
        var yesNoHide = false, dropFieldHide = false;
        if (hideFields.length > 0) {
            let c = hideFields.find((f) => {
                return groupfield.name === f ? f : null;
            });
            if (c) yesNoHide = true;
        }
        //hide logic flag for hidden fields for dropdown/multiselect logic
        if (hideDropDownFields.length > 0) {
            let c = hideDropDownFields.find((f) => {
                return groupfield.name === f ? f : null;
            });
            if (c) dropFieldHide = true;
        }

        //child sections that have 'Primary Mirror' type get the field from the parent so we know the 'Primary Data Type"
        if (groupfield.type === "Primary Mirror") {
            parentFields.forEach((g) => {
                return g.groups.forEach((f) => {
                    return f.fields.forEach((fi) => {
                        return fi.name === fc.field.values_csv ? (groupfield.type = fi.type) : null;
                    });
                });
            });
        }

        var chkforCalcFlag = false;
        var calcType;
        actCalcFields.forEach((f) => {
            if (f.id === fc.id) {
                chkforCalcFlag = true;
            }
            //set type of calc type (i.e currency or numeric)
            if (f.type === "Currency") {
                calcType = "Currency";
            } else if (f.type === "Numeric") {
                return calcType === "Currency" ? "" : (calcType = "Numeric");
            } else if (f.type === "Drop Down" && f.field.calc_jsonfield) {
                return calcType = "Numeric";
            }
        });

        if ((groupfield.type === "Date" && !yesNoHide) || (groupfield.type === "Date" && dropFieldHide))  {
            return (
                <DatePicker
                    field={"f_" + fc.id}
                    expansionPanel={groupfield.description_desktop ? true : false} //for custom styling of label
                    label={
                        groupfield.description_desktop ? (
                            <HelpLabel expansionPanel={true} inputLabel={groupfield.name} helpText={groupfield.description_input} />
                        ) : (
                            groupfield.name
                        )
                    }
                    autoPopulateAnotherDate={groupfield.auto_populate_another_date}
                    eventHandle={(value) => {
                        if (value === "Required" && groupfield.is_required) {
                            this.formApi.setError("Required Field");
                            return this.formApi.setValue(`f_${fc.id}`, "");
                        }
                        if (value === "find other date") {
                            let fieldToCalcFrom;
                            let fieldToUpdate;
                            //check for field to autopopulate
                            forms.forEach((f) => {
                                f.groups.forEach((g) => {
                                    g.fields.forEach((fi) => {
                                        if (fi.name === fc.field.default_value) {
                                            fieldToCalcFrom = fi;
                                            fieldToUpdate = fc.field;
                                            let rf = recordfields.find((f) => f.field.id === fieldToCalcFrom.id);
                                            if (rf) this.autoPopulateAnotherDate(rf.userinput, fieldToUpdate);
                                        }
                                    });
                                });
                            });
                        }
                        //check for other autopopulate fields
                        else if (value) {
                            let field;
                            //check for field to autopopulate
                            forms.forEach((f) => {
                                f.groups.forEach((g) => {
                                    g.fields.forEach((fi) => {
                                        if (fi.default_value === fc.field.name) {
                                            field = fi;
                                            var rf = recordfields.find((f) => f.field.id === field.id);
                                            if (rf) this.autoPopulateAnotherDate(value, field);
                                        }
                                    });
                                });
                            });
                        }
                    }}
                    required={groupfield.is_required}
                    disabled={isReadOnly||dropFieldHide}
                    fullWidth
                />
            );
        }
        if ((groupfield.type === "Time" && !yesNoHide) || (groupfield.type === "Time" && dropFieldHide)) {
            return (
                <TimePicker
                    field={"f_" + fc.id}
                    expansionPanel={groupfield.description_desktop ? true : false} //for custom styling of label
                    label={
                        groupfield.description_desktop ? (
                            <HelpLabel expansionPanel={true} inputLabel={groupfield.name} helpText={groupfield.description_input} />
                        ) : (
                            groupfield.name
                        )
                    }
                    autoPopulate={groupfield.auto_populate}
                    required={groupfield.is_required}
                    disabled={isReadOnly||dropFieldHide}
                    fullWidth
                />
            );
        }

        if ((groupfield.type === "Signature" && !yesNoHide) || (groupfield.type === "Signature" && dropFieldHide))  {
            return (
                <>
                    <Typography style={{ marginTop: -8 }}>{groupfield.name}</Typography>
                    <img
                        src={fc.userinput ? "/media/" + fc.userinput : "/media/No-Image-Available.jpg"}
                        alt={groupfield.name}
                        style={{ maxWidth: "99%", border: "1px solid #979797", borderRadius: 4 }}
                    />
                </>
            );
        }

        if ((groupfield.type === "Text" && !yesNoHide) || (groupfield.type === "Text" && dropFieldHide))  {
            return (
                <TextField
                    expansionPanel={groupfield.description_desktop ? true : false}
                    label={
                        groupfield.description_desktop ? (
                            <HelpLabel expansionPanel={true} inputLabel={groupfield.name} helpText={groupfield.description_input} />
                        ) : (
                            groupfield.name
                        )
                    }
                    field={"f_" + fc.id}
                    multiline={true}
                    units={groupfield.measurement_unit}
                    disabled={isReadOnly||dropFieldHide}
                    required={groupfield.is_required}
                    fullWidth
                    margin="none"
                />
            );
        }

        if ((groupfield.type === "Currency" && !yesNoHide) || (groupfield.type === "Currency" && dropFieldHide))  {
            return (
                <TextField
                    expansionPanel={groupfield.description_desktop ? true : false} //for custom styling of label
                    label={
                        groupfield.description_desktop ? (
                            <HelpLabel expansionPanel={true} inputLabel={groupfield.name} helpText={groupfield.description_input} />
                        ) : (
                            groupfield.name
                        )
                    }
                    field={"f_" + fc.id}
                    disabled={isReadOnly||dropFieldHide}
                    required={groupfield.is_required}
                    useTextFormat={true}
                    fullWidth
                    eventHandle={(value) => {
                        if (chkforCalcFlag && value === null) value = "0"; //trigger a recalc as calc value could have an incorrect value from before
                        return chkforCalcFlag === true ? this.calculate(fc, value) : "";
                    }}
                    margin="none"
                />
            );
        }
        if ((groupfield.type === "Phone Number" && !yesNoHide) || (groupfield.type === "Phone Number" && dropFieldHide)) {
            return (
                <TextField
                    expansionPanel={groupfield.description_desktop ? true : false} //for custom styling of label
                    label={
                        groupfield.description_desktop ? (
                            <HelpLabel expansionPanel={true} inputLabel={groupfield.name} helpText={groupfield.description_input} />
                        ) : (
                            groupfield.name
                        )
                    }
                    field={"f_" + fc.id} //fc.id is an arrayid with userinput from DB. This is displayed on the screen
                    disabled={isReadOnly||dropFieldHide}
                    required={groupfield.is_required}
                    fullWidth
                    margin="none"
                    usePhoneNumberFormat={true}
                />
            );
        }
        if ((groupfield.type === "Email" && !yesNoHide) || (groupfield.type === "Email" && dropFieldHide))  {
            return (
                <TextField
                    type="email"
                    expansionPanel={groupfield.description_desktop ? true : false} //for custom styling of label
                    label={
                        groupfield.description_desktop ? (
                            <HelpLabel expansionPanel={true} inputLabel={groupfield.name} helpText={groupfield.description_input} />
                        ) : (
                            groupfield.name
                        )
                    }
                    field={"f_" + fc.id}
                    pattern=".+@globex.com"
                    disabled={isReadOnly||dropFieldHide}
                    fullWidth
                    required={groupfield.is_required}
                    margin="none"
                />
            );
        }
        if ((groupfield.type === "Numeric" && !yesNoHide) || (groupfield.type === "Numeric" && dropFieldHide))  {
            return (
                <TextField
                    restrictValue={groupfield.restrict_value}
                    thousandSeperator={groupfield.thousands_seperator}
                    useNumberFormat
                    expansionPanel={groupfield.description_desktop ? true : false} //for custom styling of label
                    label={
                        groupfield.description_desktop ? (
                            <HelpLabel expansionPanel={true} inputLabel={groupfield.name} helpText={groupfield.description_input} />
                        ) : (
                            groupfield.name
                        )
                    }
                    decimals={groupfield.decimals}
                    field={"f_" + fc.id}
                    units={groupfield.measurement_unit}
                    disabled={isReadOnly||dropFieldHide}
                    required={groupfield.is_required}
                    eventHandle={(value) => {
                        if (chkforCalcFlag && value === null) value = "0"; //trigger a recalc as calc value could have an incorrect value from before
                        return chkforCalcFlag === true ? this.calculate(fc, value) : "";
                    }}
                    fullWidth
                    margin="none"
                />
            );
        }
        if ((groupfield.type === "Yes/No" && !yesNoHide) || (groupfield.type === "Yes/No" && dropFieldHide))  {
            return (
                <RadioGroup
                    field={"f_" + fc.id}
                    name={fc.id}
                    disabled={isReadOnly||dropFieldHide}
                    required={groupfield.is_required}
                    fullWidth
                    options={[
                        { label: "Yes", value: "Yes" },
                        { label: "No", value: "No" },
                    ]}
                    alignment={true}
                    expansionPanel={groupfield.description_desktop ? true : false} //for custom styling of label
                    label={
                        groupfield.description_desktop ? (
                            <HelpLabel expansionPanel={true} inputLabel={groupfield.name} helpText={groupfield.description_input} />
                        ) : (
                            groupfield.name
                        )
                    }
                    eventHandle={(value) => {
                        if (groupfield.hide_field && groupfield.fields_to_hide) {
                            var updatedHideFields = [];
                            if (value === "Yes") {
                                //check for logic fields
                                let fieldsToShow = groupfield.fields_to_hide.split(",");
                                if (hideFields.length > 0) {
                                    updatedHideFields = hideFields;
                                    fieldsToShow.forEach((f) => {
                                        updatedHideFields = updatedHideFields.filter((s) => {
                                            return s === f ? null : s;
                                        });
                                    });

                                    hideFields.forEach((hf) => {
                                        recordfields.forEach((rf) => {
                                            return rf.field.name === hf ? this.formApi.setValue(`f_${rf.id}`, rf.field.default_value) : null;
                                        });
                                    });
                                }
                            } else {
                                //add back to list
                                let _this = this;
                                let fieldsToShow = groupfield.fields_to_hide.split(",");
                                updatedHideFields = fieldsToShow.concat(hideFields);
                                if (updatedHideFields.length > 0) {
                                    updatedHideFields.forEach((hf) => {
                                        recordfields.forEach((rf) => {
                                            return rf.field.name === hf ? _this.formApi.setValue(`f_${rf.id}`, " ") : null;
                                        });
                                    });
                                }
                            } //now update state (willcontain yes and no situations)
                            this.setState((prevState) => ({
                                hideFields: updatedHideFields,
                            }));
                        }
                    }}
                />
            );
        }
        if ((groupfield.type === "Drop Down" && !yesNoHide) || (groupfield.type === "Multi Select" && !yesNoHide) || (groupfield.type === "Drop Down" && dropFieldHide) || (groupfield.type === "Multi Select" && dropFieldHide)) {
            var label;
            if (groupfield.is_required) {
                label = groupfield.name.concat(" *");
            } else {
                label = groupfield.name;
            }

            if (groupfield.values_csv && !groupfield.user_dropdown) {
                let tempArr = [];

                //has numbers with commas escapes for thousand values
                if (groupfield.values_csv.includes("\\,")) {
                    let str = groupfield.values_csv;
                    let wtf = "";
                    let shouldPass = false;
                    for (let i = 0; i < str.length; i++) {
                        if (str[i].includes("\\")) {
                            wtf = wtf + "\\"; // + str[i];
                            shouldPass = true;
                        } else if (shouldPass) {
                            //record this comma as \ before it
                            wtf = wtf + str[i];
                            shouldPass = false;
                        } else if (str[i] === ",") {
                            //not \ before it so record selection
                            tempArr.push(wtf);
                            wtf = "";
                        } else {
                            wtf = wtf + str[i];
                            if (i === str.length - 1) {
                                //last selection save
                                tempArr.push(wtf);
                            }
                        }
                    }
                } else {
                    //no numbers with & escape characters so just split
                    tempArr = groupfield.values_csv.split(",");
                }
                return (
                    <ReactSelect
                        field={"f_" + fc.id}
                        options={this.dropDownValues(tempArr)}
                        disabled={isReadOnly||dropFieldHide}
                        expansionPanel={groupfield.description_desktop ? true : false} //for custom styling of label
                        label={
                            groupfield.description_desktop ? (
                                <HelpLabel expansionPanel={true} inputLabel={label} helpText={groupfield.description_input} />
                            ) : (
                                label
                            )
                        }
                        type={groupfield.type}
                        isMulti={groupfield.type === "Drop Down" ? false : true}
                        required={groupfield.is_required}
                        height="auto"
                        fullWidth
                        valuesCsv={tempArr}
                        eventHandle={(value) => {
                          
                            if(groupfield.type === 'Drop Down' && groupfield.calc_jsonfield){    
                                let findKey;
                                let results = JSON.parse(groupfield.calc_jsonfield)
                                let hideFieldsFound = []
                                
                                for (const key in results){//see if value matched a k/v pair
                                    if(key === value){
                                        findKey = results[key];
                                        break;
                                    }  
                                }   

                                if (findKey){//this updates another field from the jsonformula, also unhides a field based on the set/dropdown value 
                                    findKey.forEach(i=>{ 
                                        let val = Object.values(i)[0]
                                        let rf = recordfields.find(f=>f.field.id === parseInt(Object.keys(i)[0])) //find the recordfield to update
                                         
                                        if(val === 'unhide'){
                                            if (hideFieldsFound.length > 0){//need to do this incase the json is updating > 1 field otherwise mutation issues will occur on hideFields
                                                hideFieldsFound = hideFieldsFound.filter(d=>d!==rf.field.name) 
                                            }else{
                                                hideFieldsFound = hideDropDownFields.filter(d=>d!==rf.field.name)// so we have a calcjson field that is set to unhide and the value selected matched so lets unhide those other fields
                                            }
                                        }else{ 
                                            //set outside field to the new value
                                            this.formApi.setValue("f_" + rf.id, val); 

                                            if (chkforCalcFlag && val !== null && rf.field.type !== 'Text'){ //trigger a recalc as calc value could have an incorrect value from before--might need timeout to make sure field is updated first 
                                                this.calculate(rf, val) 
                                            } 
                                        }
                                    })

                                    //but we have to check one more time to hide dropdown selections that might have 'unhide' keys as their values
                                    //and this specific value wasnt selected but this value DID trigger some other field
                                    for (const key in results){ 
                                        if(key !== value){
                                            // results[key].forEach((i)=>{
                                            for (let i of results[key]){     
                                                let rf = recordfields.find(f=>f.field.id === parseInt(Object.keys(i)[0])) //find the recordfield to update
                                                    
                                                if(Object.values(i)[0] === 'unhide'){ 
                                                    hideFieldsFound.push(rf.field.name)// so lets hide field again
                                                    this.formApi.setValue("f_" + rf.id, 0); //and set back to zero 
                                                    hideFieldsFound = [...new Set([...hideFieldsFound ,...hideDropDownFields])]; //but bring back over other fields that were hidden as could be on another field

                                                }
                                                //not sure if this is needed but not supporting at the moment
                                                //else if (chkforCalcFlag && id && rf.field.type !== "Text"){//trigger a recalc as calc value could have an incorrect value from before 
                                                //     this.calculate(rf, 0)                                            
                                                // }
                                            }
                                        }
                                    } 
                        
                                }else{//clear fields to 0 & rehide hidden ones as there wasnt a json formula value selected within the drop down that was supposed to do something 
                                    for (const key in results){ 
                                        // results[key].forEach((i)=>{
                                        for (let i of results[key]){     
                                            let rf = recordfields.find(f=>f.field.id === parseInt(Object.keys(i)[0])) //find the recordfield to update
                                         
                                            //set outside field to the new value 
                                            this.formApi.setValue("f_" + rf.id, 0);

                                            if(Object.values(i)[0] === 'unhide'){ 
                                                hideFieldsFound.push(rf.field.name)// so lets hide field again 
                                            }else if (chkforCalcFlag && rf.field.type !== "Text"){//trigger a recalc as calc value could have an incorrect value from before 
                                                this.calculate(rf, 0)                                            
                                            }
                                        }
                                    }
                                    hideFieldsFound = [...new Set([...hideFieldsFound ,...hideDropDownFields])];//rehide those fields local and state fields
                                } 
                                this.setState((prevState) => ({
                                    hideDropDownFields: hideFieldsFound
                                }))
                            
                            }else if(groupfield.type === 'Multi Select' && groupfield.calc_jsonfield){    
                                let findKey;
                                let results = JSON.parse(groupfield.calc_jsonfield)
                                let hideFieldsFound = []
                                let split = value.split(',')
                               
                                for (const idx in split){ 
                                    for (const key in results){//see if value matched a k/v pair
                                        if(key === split[idx]){
                                            findKey = results[key];
                                            break;
                                        }  
                                    }   

                                    if (findKey){//this updates another field from the jsonformula, also unhides a field based on the set/dropdown value 
                                    
                                        for (let i in findKey){   
                                            let val = Object.values(findKey[i])[0]
                                             //eslint-disable-next-line
                                            let rf = recordfields.find(f=>f.field.id === parseInt(Object.keys(findKey[i])[0])) //find the recordfield to update
                                            
                                            if(val === 'unhide'){
                                                if (hideFieldsFound.length > 0){//need to do this incase the json is updating > 1 field otherwise mutation issues will occur on hideFields
                                                    hideFieldsFound = hideFieldsFound.filter(d=>d!==rf.field.name) 
                                                }else{
                                                    hideFieldsFound = hideDropDownFields.filter(d=>d!==rf.field.name)// so we have a calcjson field that is set to unhide and the value selected matched so lets unhide those other fields
                                                }
                                            }else{
                                                //set outside field to the new value
                                                this.formApi.setValue("f_" + rf.id, val); 

                                                if (chkforCalcFlag && val !== null && rf.field.type !== 'Text'){ //trigger a recalc as calc value could have an incorrect value from before--might need timeout to make sure field is updated first 
                                                    this.calculate(rf, val) 
                                                } 
                                            }
                                        }

                                        //but we have to check one more time to hide dropdown selections that might have 'unhide' keys as their values
                                        //and this specific value wasnt selected but this value DID trigger some other field
                                        for (const key in results){ 
                                            if(!value.includes(key)){ 
                                                for (let i of results[key]){    
                                                    let rf = recordfields.find(f=>f.field.id === parseInt(Object.keys(i)[0] )) //find the recordfield to update 
                                             
                                                    if(Object.values(i)[0] === 'unhide'){
                                                       this.formApi.setValue("f_" + rf.id, 0);//set back to zero  
                                                       hideFieldsFound.push(rf.field.name)//hide field again 
                                                       hideFieldsFound = [...new Set([...hideFieldsFound ,...hideDropDownFields])]; //but bring back over other fields that were hidden as could be on another field

                                                    }  
                                                    //not sure if this is needed but not supporting at the moment
                                                    //else if (chkforCalcFlag && id && rf.field.type !== "Text"){//trigger a recalc as calc value could have an incorrect value from before 
                                                    //     this.calculate(rf, 0)                                            
                                                    // }
                                                }
                                            }
                                        }
                                    
                            
                                    }else{//clear fields to 0 & rehide hidden ones as there wasnt a json formula value selected within the drop down that was supposed to do something
                                    
                                        for (const key in results){  
                                            for (let i of results[key]){       
                                                let rf = recordfields.find(f=>f.field.id === parseInt(Object.keys(i)[0])) //find the recordfield to update
                                                 
                                                //set outside field to the new value
                                                this.formApi.setValue("f_" + rf.id, 0);

                                                if(Object.values(i)[0] === 'unhide'){ 
                                                    hideFieldsFound.push(rf.field.name)// so lets hide field again 
                                                }else if (chkforCalcFlag && rf.field.type !== "Text"){//trigger a recalc as calc value could have an incorrect value from before 
                                                    this.calculate(rf, 0)                                            
                                                }
                                            }
                                        }
                                        hideFieldsFound = [...new Set([...hideFieldsFound ,...hideDropDownFields])];//rehide those fields local and state fields 
                                    }
                                }
                                this.setState((prevState) => ({
                                    hideDropDownFields: hideFieldsFound
                                }))
                            }
                        }} 
                    />
                );
  
            } else if (groupfield.user_dropdown) {
                //userdropdown gets all users into list
                const { allUsers } = this.props;
                const nameUsers = [];
                allUsers.sort((a, b) => {
                    return a["name"] < b["name"] ? -1 : 1;
                });
                allUsers.forEach((u) => {
                    return u.is_active && !(u.username.includes("heitest") || u.username.includes("ms4permit")) ? nameUsers.push(u.name) : null;
                });

                return (
                    <ReactSelect
                        expansionPanel={groupfield.description_desktop ? true : false} //for custom styling of label
                        label={
                            groupfield.description_desktop ? (
                                <HelpLabel expansionPanel={true} inputLabel={label} helpText={groupfield.description_input} />
                            ) : (
                                label
                            )
                        }
                        primaryMirror={true} //this is set to bypass in reactselect.js the closest senario, this is due to users might not be in the list as 'is_active' above on prior records but we want to show what was previously saved
                        field={"f_" + fc.id}
                        options={this.dropDownValues(nameUsers)}
                        disabled={isReadOnly}
                        isMulti={groupfield.type === "Drop Down" ? false : true}
                        required={groupfield.is_required}
                        height="auto"
                        fullWidth
                    />
                );
            } else if (groupfield.populate_from_another_section) {
                //THIS IS FOR CHILD SECTION TO PULL FROM ANOTHER CHILD SECTION OR THE PARENT
                //DIDNT INTEGRATE AS NOT SURE IT WILL BE USED BUT WILL NEED ParentRecordID prop to use
                // if (groupfield.populate_field) {
                //     return (
                //         <ReactSelectAsync
                //             field={"f_" + fc.id}
                //             label={groupfield.description_desktop ? <HelpLabel inputLabel={label} helpText={groupfield.description_input} /> : label}
                //             disabled={isReadOnly}
                //             isMulti={groupfield.type === "Drop Down" ? false : true}
                //             required={groupfield.is_required}
                //             //={groupfield.auto_populate ? authState.user.name : null}
                //             height="auto"
                //             fullWidth
                //             dropDownAutoLoad={true}
                //             groupfield={groupfield}
                //             parentRecordId={parentRecordId}
                //             parentSection={parentFields[0].section}
                //         />
                //     );
                // }
            }
        }

        if ((groupfield.type === "Radio" && !yesNoHide) || (groupfield.type === "Radio" && dropFieldHide)) { 
            if (groupfield.values_csv) {
                let tempArr = [];
                let options = [];
                tempArr = groupfield.values_csv.split(",");
                tempArr.forEach((t) => {
                    options.push({ label: t, value: t });
                });

                if (fc.userinput && !tempArr.find((f) => f === fc.userinput)) {
                    options.push({ label: "[LEGACY DATA]" + fc.userinput, value: fc.userinput, color: "red" });
                }
                return (
                    <RadioGroup
                        field={"f_" + fc.id}
                        name={fc.id}
                        disabled={isReadOnly||dropFieldHide}
                        required={groupfield.is_required}
                        fullWidth
                        options={options}
                        alignment={true}
                        expansionPanel={groupfield.description_desktop ? true : false} //for custom styling of label
                        label={
                            groupfield.description_desktop ? (
                                <HelpLabel expansionPanel={true} inputLabel={groupfield.name} helpText={groupfield.description_input} />
                            ) : (
                                groupfield.name
                            )
                        }
                    />
                );
            }
        } 
        if ((groupfield.type === "Calculated" && !yesNoHide) || (groupfield.type === "Calculated" && dropFieldHide)) {
            return (
                <TextField
                    type="calculated"
                    field={"f_" + fc.id}
                    expansionPanel={groupfield.description_desktop ? true : false} //for custom styling of label
                    label={
                        groupfield.description_desktop ? (
                            <>
                                <HelpLabel expansionPanel={true} inputLabel={groupfield.name} helpText={groupfield.description_input} />
                               
                                {groupfield.manual_calculation && (
                                    <Tooltip title='Recalculate this field? Note: numeric/currency or other calculated fields attached to this field will not automatically update until calculate is pressed. Pressing calculate will also disregard any manual entries added into this field.'> 
                                        <Button variant="contained" color="primary" style={{paddingBottom:10,marginLeft:8,  
                                            marginBottom:8,padding:10, lineHeight:0, width:'auto', fontSize:15}} onClick={(e)=>{
                                                e.stopPropagation()
                                                this.manualCalculate(fc) 
                                            }}>
                                            Calculate
                                        </Button> 
                                    </Tooltip>
                                )}         
                            </>
                        ) : (<>
                            {groupfield.name}
                            {groupfield.manual_calculation  && (
                                <Tooltip title='Recalculate this field? Note: numeric/currency or other calculated fields attached to this field will not automatically update until calculate is pressed. Pressing calculate will also disregard any manual entries added into this field.'> 
                                    <Button variant="contained" color="primary" style={{paddingBottom:10,marginLeft:8,  
                                        marginBottom:8,padding:10, lineHeight:0, width:'auto', fontSize:15}} onClick={(e)=>{
                                            e.stopPropagation()
                                            this.manualCalculate(fc) 
                                        }}>
                                        Calculate
                                    </Button> 
                                </Tooltip>
                            )}</>
                        )
                    }
                    disabled={isReadOnly||dropFieldHide}
                    required={groupfield.is_required}
                    useTextFormat={groupfield.measurement_unit === "Currency" ? true : false}
                    useNumberFormat={groupfield.measurement_unit !== "Currency" ? true : false}
                    decimals={groupfield.decimals}
                    fullWidth
                    margin="none"
                    thousandSeperator={groupfield.measurement_unit === "Numeric"? true:false}
                    units={groupfield.measurement_unit === "Percent" ? "%" : ""}
                />
            );
        }
        if ((groupfield.type === "Incremental" && !yesNoHide) || (groupfield.type === "Incremental" && dropFieldHide)) {
            return (
                <TextField
                    type="incremental"
                    field={"f_" + fc.id}
                    expansionPanel={groupfield.description_desktop ? true : false} //for custom styling of label
                    label={
                        groupfield.description_desktop ? (
                            <HelpLabel expansionPanel={true} inputLabel={groupfield.name} helpText={groupfield.description_input} />
                        ) : (
                            groupfield.name
                        )
                    }
                    disabled={true}
                    required={groupfield.is_required}
                    fullWidth
                    margin="none"
                />
            );
        }
        if ((groupfield.type === "Dynamic Label" && !yesNoHide) || (groupfield.type === "Dynamic Label" && dropFieldHide)) { 
            return (
                <EnhancedTextField
                    expansionPanel={groupfield.description_desktop ? true : false} //for custom styling of label
                    label={
                        groupfield.description_desktop ? (
                            <HelpLabel expansionPanel={true} inputLabel={groupfield.name} helpText={groupfield.description_input} />
                        ) : (
                            groupfield.name
                        )
                    }
                    field={"f_" + fc.id} //fc.id is an arrayid with userinput from DB. This is displayed on the screen
                    multiline={true}
                    // units={groupfield.measurement_unit}
                    // disabled={isReadOnly}
                    // required={groupfield.is_required}
                    fullWidth
                    hyperlink={groupfield.hyperlink}
                    margin="none"
                />
            );
        }
        if ((groupfield.type === "Related Mirror" && !yesNoHide) || (groupfield.type === "Related Mirror" && dropFieldHide)) {
            return (
                <EnhancedTextField
                    expansionPanel={groupfield.description_desktop ? true : false} //for custom styling of label
                    label={
                        groupfield.description_desktop ? (
                            <HelpLabel expansionPanel={true} inputLabel={groupfield.name} helpText={groupfield.description_input} />
                        ) : (
                            groupfield.name
                        )
                    }
                    field={"f_" + fc.id} //fc.id is an arrayid with userinput from DB. This is displayed on the screen
                    multiline={true}
                    disabled={true}
                    fullWidth
                    // hyperlink={groupfield.hyperlink}
                    margin="none"
                />
            );
        }
    };

    //value function for dropdown datatype. This is the options property.
    dropDownValues = (table) => {
        return table.map((row) => ({
            label: row,
            value: row,
        }));
    };
    // custom scrollbar
    renderThumb() {
        return <div style={{ backgroundColor: "#3c8ac7" }} />;
    }

    deleteRecord = (n) => {
        const { recordsection } = this.props;
        if (recordsection.section.parent) {
            this.setState({
                DialogOpen: true,
                DialogTitle: "Delete Primary Record Section",
                DialogConfirmAction: recordsection.parentrecord.id,
                DialogText:
                    "WARNING: This will remove all attached records associated with this primary record. Are you sure you wish to permanently this?",
            });
        } else {
            this.setState({
                DialogOpen: true,
                DialogTitle: "Delete Related Record Section",
                DialogConfirmAction: recordsection.id,
                DialogText: "WARNING: This will remove the recordsection permanently. Are you sure you wish to delete this?",
            });
        }
    };

    componentWillReceiveProps(props) {
        this.setState({ loadingFields: props.loadingFields, nullFields: props.nullFields });
    }
    componentDidMount() {
        const { calculationInfo } = this.state;
        const { forms, recordfields } = this.props;

        //need for routing from form for calc
        let c = recordfields.find((f) => {
            return typeof f.id === "string" ? f : null;
        });  
        if (JSON.stringify(calculationInfo) === "{}" && !c && forms.length > 0 && recordfields.length > 0) this.checkForCalcFields(); //check for calculated field
        const compo = this;
        setTimeout(function () {
            compo.setState({ unsavedFields: false });
        }, 100);
        this.props.onRef(this);//link reference for triggering of "THIS" form below which occurs in maprecord/basemapcontrol/mapediting.js
    }
    componentWillUnmount() {
        this.props.onRef(undefined); //remove
    }

    componentDidUpdate(prevProps) {
        const {
            recordsection,
            ormRecordFieldLoadDetailChild,
            ormRecordSectionUpdateLocalOnly,
            ormRecordSectionLoadDetailChild,
            recordfields,
            forms,
            authState,
            recordSectionID,
            history,
            page
        } = this.props;
        const { loadingFields, calculationInfo, loadingRS, hideFields, hideDropDownFields, loadLogicFields, loadingRSFinished } = this.state;
        const _this = this;

        // If you load this page from a bookmark it doesn't have an authstate right away
        if (!authState || !authState.user) return;

        if (!loadingRS && !loadingRSFinished) {
            if (recordSectionID && !recordsection) {
                // Load the record section
                this.setState({ loadingRS: true });
                ormRecordSectionLoadDetailChild(recordSectionID, function (data) {
                    _this.setState({ loadingRS: false, loadingRSFinished: true });
                    if (data.length === 0) { // Record does not exist - redirect to dashboard
                        history.push("/dashboard/" + page.id)
                    }
                });
            }
        }

        // Only do this for the fields view - not the table
        if (!loadingFields) {
            // and do it only once per recordsection
            if (recordsection && !recordsection.has_children_loaded) {
                this.setState({ loadingFields: true });

                ormRecordFieldLoadDetailChild(recordsection.id, function () {
                    ormRecordSectionUpdateLocalOnly({
                        id: recordsection.id,
                        has_children_loaded: true,
                    });
                    _this.setState({ loadingFields: false });
                });
            }
        
        }

        // check for logic fields this occurs on section changes and refresh of page.
        if (prevProps.recordSectionID !== recordSectionID || (loadLogicFields && recordfields)) {
            let hideFieldsFound = [];
            let hideDropFields = []
            recordfields.forEach((f) => {
                if ((f.field.type === "Yes/No" && f.field.fields_to_hide && f.userinput === "No") || (f.field.type === 'Drop Down' && f.field.hide_field && f.field.fields_to_hide) || (f.field.type === 'Multi Select' && f.field.hide_field && f.field.fields_to_hide)) {
                    let temp = f.field.fields_to_hide.split(",");
                    if (f.field.type === 'Yes/No'){
                        temp.forEach((v) => {
                        hideFieldsFound.push(v);
                        });
                    }else if(f.field.type === 'Drop Down' && f.field.hide_field){//dropdown hidefield logic for showing fields properly on a render 

                        var findKey;
                        let results = JSON.parse(f.field.calc_jsonfield)
                        for (const key in results){
                            if(key !== f.userinput){ //so we want the key if it dont match the dropdown
                                findKey = results[key];
                                break;
                            } 
                        }
      
                        if (findKey){
                            findKey.forEach(i=>{
                                let key = Object.keys(i)[0]
                                let val = Object.values(i)[0] //look for fields to hide
                                //now we will hide or not hide based on value of this dropdown
                                if (val === 'unhide'){
                                    let rf = recordfields.find(f=>f.field.id === parseInt(key)) //find the recordfield to hide
                                    hideDropFields.push(rf.field.name);
                                } 
                            })
                        } 
                    }else if(f.field.type === 'Multi Select' && f.field.hide_field){//multi hidefield logic for showing fields properly on a render 
                        let findKey;
                        let results = JSON.parse(f.field.calc_jsonfield)  
                            for (const key in results){
                                if(!f.userinput.includes(key)){ //so we want the key if it dont match the dropdown
                                    findKey = results[key];
                                    break;
                                }  
                            }
                            if (findKey){
                                findKey.forEach(i=>{
                                    let key = Object.keys(i)[0]
                                    let val = Object.values(i)[0] //look for fields to hide
                                    //now we will hide or not hide based on value of this field
                                    if (val === 'unhide'){
                                        let rf = recordfields.find(f=>f.field.id === parseInt(key)) //find the recordfield to hide
                                        hideDropFields.push(rf.field.name);
                                    } 
                                })
                            }  
                    }
                }
            });
            if (hideFields.length === 0 && hideFieldsFound.length > 0)
                this.setState((prevState) => ({
                    hideFields: hideFieldsFound,
                    loadLogicFields: false,
                }));
            if (hideDropDownFields.length === 0 && hideDropFields.length > 0)
                this.setState((prevState) => ({
                    hideDropDownFields: hideDropFields,
                    loadLogicFields: false,
                }));
        }

        if (forms) {
            //need for routing from form for calc
            let c = recordfields.find((f) => {
                return typeof f.id === "string" ? f : null;
            });
            if (JSON.stringify(calculationInfo) === "{}" && !c && forms.length > 0 && recordfields.length > 0) this.checkForCalcFields(); //check for calculated field
        }
    }

    render() {
        const { 
            classes,
            page,
            recordsection,
            readOnly,
            ormParentRecordDelete,
            recordfields,
            saveStatus,
            ormRecordSectionUpdateLocalOnly,
            recordSectionID,
        } = this.props;
        const {
            loadingFields,
            nullFields,
            DialogTitle,
            DialogConfirmAction,
            DialogOpen,
            deletingFields,
            DialogText,
            formRoute,
            unsavedFields,
            loadingRS,
            rfsaving} = this.state;

        if (recordsection && page) {
            var actForm;
            actForm = page.sections.find((s) => s.id === recordsection.section.id)._forms.find((f) => f.id === recordsection.form.id);
        }
        if (nullFields && !loadingFields) {
            return (
                <Grid container spacing={0} className={classes.nullMessage}>
                    <Grid item xs={12}>
                        <HelpOutline className={classes.supportIcon} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">Draw or select a feature to edit their attributes.</Typography>
                    </Grid>
                </Grid>
            );
        }
        if (loadingFields || loadingRS) {
            return (
                <Grid container spacing={0} className={classes.nullMessage}>
                    <Grid item xs={12}>
                        <CircularProgress size={45} className={classes.progress} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" style={{ textAlign: "center" }}>
                            Loading... Please Wait
                        </Typography>
                    </Grid>
                </Grid>
            );
        }

        if (deletingFields) {
            return (
                <>
                    <CircularProgress size={90} className={classes.progress} />
                    <Typography variant="h6" style={{ display: "inline-block", marginLeft: "16%", verticalAlign: "center", zIndex: 1 }}>
                        Deleting Record... Please Wait
                    </Typography>
                </>
            );
        }

        if (recordsection && recordfields) {
            return (
                // {/*********** TOP HEADER INCLUDES LINKS FOR BROWSING RECORDS ******************/}
                <>
                    <Prompt
                        when={unsavedFields}
                        message="You have entered data that has not been saved in our system yet. Do you want to leave without finishing?"
                    />
                    <Scrollbars
                        renderThumbVertical={this.renderThumb}
                        renderThumbHorizontal={this.renderThumb}
                        style={{ right: "1px" }}
                        autoHide
                        autoHideTimeout={1000}
                        autoHideDuration={200}>
                        <Form
                            // style={{ overflowY: "auto" }}
                            key={recordsection.id}
                            dontValidateOnMount="false"
                            formDidUpdate={() => {
                                this.setState({ unsavedFields: true });
                            }}
                            getApi={(el) => (this.formApi = el)} //allows remote access
                            validateOnSubmit="true"
                            defaultValues={this.defaultValues()}
                            validateError={this.errorValidator}
                            onSubmit={(values, fromStepper, formApi) => this.updateRecordField(values, fromStepper, formApi)}>
                            {(formApi) => (
                                <form onSubmit={formApi.submitForm}>
                                    {/****** RETURN THE EXPANSION PANELS FOR EDITING A RECORD, INCLUDES THE GROUPS WITH FIELDS FOR EACH SECTION***********/}
                                    {actForm &&
                                        actForm._groups.map((n, index) => {
                                            return (
                                                <Paper style={{ backgroundColor: " #dddddd" }}>
                                                    {index === 0 && (
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={recordsection.next_record_url || recordsection.previous_record_url ? 9 : 12}>
                                                                <>
                                                                    {readOnly && (
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            fullWidth
                                                                            style={{
                                                                                marginLeft: 3,
                                                                                paddingBottom: 2,
                                                                                marginBottom: -15,
                                                                                fontSize: 16,
                                                                            }}
                                                                            // className={classes.buttons}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                // this.setState({ formRoute: true }); //use for error check of dialog popup

                                                                                setTimeout(() => {
                                                                                    var comp = this;
                                                                                    comp.props.history.push(
                                                                                        "/page/" +
                                                                                            page.id +
                                                                                            "/parentrecord/" +
                                                                                            recordsection.parentrecord_id +
                                                                                            "/section/" +
                                                                                            recordsection.section.id +
                                                                                            "/recordsection/" +
                                                                                            recordsection.id +
                                                                                            "/form"
                                                                                    );
                                                                                }, 20);
                                                                            }}>
                                                                            <Tooltip title="Return to Form View ">
                                                                                <div style={{ display: "flex" }}>
                                                                                    <Description />
                                                                                    <Typography variant="subtitle1">
                                                                                        &nbsp;&nbsp;&nbsp;Return to Form View
                                                                                    </Typography>
                                                                                </div>
                                                                            </Tooltip>
                                                                        </Button>
                                                                    )}
                                                                    {!readOnly && (
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            fullWidth
                                                                            style={{
                                                                                marginLeft: 3,
                                                                                paddingBottom: 2,
                                                                                marginBottom: -15,
                                                                                fontSize: 16,
                                                                            }}
                                                                            // className={classes.buttons}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                this.setState({ formRoute: true }); //use for error check of dialog popup

                                                                                setTimeout(() => {
                                                                                    var comp = this;
                                                                                    comp.formApi.submitForm();
                                                                                }, 20);
                                                                            }}>
                                                                            <Tooltip title="Return to Form View ">
                                                                                <div style={{ display: "flex" }}>
                                                                                    <Description />
                                                                                    <Typography variant="subtitle1">
                                                                                        &nbsp;&nbsp;&nbsp;Save and Return to Form View
                                                                                    </Typography>
                                                                                </div>
                                                                            </Tooltip>
                                                                        </Button>
                                                                    )}
                                                                </>
                                                            </Grid>
                                                            {(recordsection.next_record_url || recordsection.previous_record_url) && (
                                                                <Grid style={{ textAlign: "right", marginTop: 8 }} item xs={3}>
                                                                    {recordsection.previous_record_url && (
                                                                        <Tooltip title="Previous Record">
                                                                            <Link
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    // When cycling through records the fields don't reload unless has_children_loaded is reset
                                                                                    ormRecordSectionUpdateLocalOnly({
                                                                                        id: recordSectionID,
                                                                                        has_children_loaded: false,
                                                                                    });
                                                                                }}
                                                                                style={{ padding: 0, marginRight: 16, color: "#3c8ac7" }}
                                                                                to={this.props.recordsection.previous_record_url.map}>
                                                                                <ArrowBack />
                                                                            </Link>
                                                                        </Tooltip>
                                                                    )}
                                                                    {recordsection.next_record_url && (
                                                                        <Tooltip title="Next Record">
                                                                            <Link
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    // When cycling through records the fields don't reload unless has_children_loaded is reset
                                                                                    ormRecordSectionUpdateLocalOnly({
                                                                                        id: recordSectionID,
                                                                                        has_children_loaded: false,
                                                                                    });
                                                                                }}
                                                                                style={{ padding: 0, marginRight: 16, color: "#3c8ac7" }}
                                                                                to={this.props.recordsection.next_record_url.map}>
                                                                                <ArrowForward />
                                                                            </Link>
                                                                        </Tooltip>
                                                                    )}
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    )}
                                                    <Accordion defaultExpanded={n.default_expanded}>
                                                        <AccordionSummary
                                                            className={classes.titlePanel}
                                                            expandIcon={index === 0 ? null : <ExpandMoreIcon />}
                                                            style={{
                                                                backgroundColor: "#ddd",
                                                                border: index !== 0 ? ".1px solid lightslategrey" : "none",
                                                                borderRadius: 3,
                                                            }}>
                                                            <Grid container>
                                                                {index === 0 && (
                                                                    <>
                                                                        <Grid item xs={7} lg={8} xl={10}>
                                                                            <Typography className={classes.heading2} style={{ maxWidth: "74%" }}>
                                                                                {n.name}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs="auto" xl={1} className={classes.buttons}>
                                                                            <Tooltip title="Save Record">
                                                                                <IconButton
                                                                                    disabled={readOnly}
                                                                                    style={{ padding: "0px 6px 0px 0px" }}
                                                                                    type="button"
                                                                                    color="primary"
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        this.formApi.submitForm();
                                                                                    }}>
                                                                                    <Save />
                                                                                    {saveStatus && (
                                                                                        <CircularProgress
                                                                                            size={28}
                                                                                            style={{
                                                                                                marginLeft: -3,
                                                                                                position: "fixed",
                                                                                                marginTop: 2,
                                                                                                color: "#fff",
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </Grid>
                                                                        <Grid item xs="auto" xl={1}>
                                                                            <Tooltip title="Delete Record">
                                                                                <IconButton
                                                                                    disabled={readOnly}
                                                                                    style={{ padding: 0 }}
                                                                                    color="primary"
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        this.deleteRecord();
                                                                                    }}>
                                                                                    <DeleteIcon />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </Grid>
                                                                    </>
                                                                )}
                                                                {index !== 0 && (
                                                                    <Grid item xs={12}>
                                                                        <Typography className={classes.heading2}>{n.name}</Typography>
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            {n.repeatable && (
                                                                <Grid
                                                                    container
                                                                    spacing={0}
                                                                    className={classes.nullMessage}
                                                                    style={{ width: "363%", marginLeft: -15 }}>
                                                                    <Grid item xs={12}>
                                                                        <HelpOutline className={classes.supportIcon} />
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="body1">
                                                                            Repeatable tables are not supported in the map view. Route to the 'form'
                                                                            to view the repeatable table
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            )}
                                                            {/* FIELDS FOR EACH GROUP */}
                                                            {!n.repeatable && this.fieldOutput(n, readOnly)}
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </Paper>
                                            );
                                        })}
                                </form>
                            )}
                        </Form>
                        <WarningDialog
                            confirmAction={() => {
                                const { page, recordsection, history, ormRecordSectionDelete } = this.props;

                                if (DialogTitle === "Delete Primary Record Section") {
                                    this.setState({ deletingFields: true, DialogOpen: false });
                                    ormParentRecordDelete(DialogConfirmAction);

                                    history.push("/page/" + page.id + "/" + recordsection.section.id + "/table");
                                } else if (DialogTitle === "Delete Related Record Section") {
                                    this.setState({ deletingFields: true, DialogOpen: false });

                                    ormRecordSectionDelete(DialogConfirmAction);

                                    history.push("/page/" + page.id + "/" + page.sections[0].id + "/table");
                                } else if (DialogText === "Are you sure you want to leave this module and lose any unsaved changes?") {
                                    this.setState({ DialogOpen: false });
                                    history.push(
                                        "/page/" +
                                            page.id +
                                            "/parentrecord/" +
                                            recordsection.parentrecord.id +
                                            "/section/" +
                                            recordsection.section.id +
                                            "/recordsection/" +
                                            recordsection.id +
                                            "/form"
                                    );
                                }
                            }}
                            cancelAction={() => {
                                this.setState({ DialogOpen: false });
                                setTimeout(() => {
                                    //needed to not change the cancelText and confirmText right away to delete
                                    this.setState({ formRoute: false });
                                }, 100);
                            }}
                            cancelText={formRoute ? "Cancel" : null}
                            confirmText={formRoute ? "Yes" : null}
                            open={DialogOpen}
                            title={DialogTitle}
                            text={DialogText}
                        />
                        {rfsaving && (
                            <Dialog open={rfsaving}>
                                <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                                    <Typography variant="h5" className={classes.flex}>
                                        Saving Record Fields
                                    </Typography>
                                </Toolbar>
                                <DialogContent style={{ minWidth: 400 }}>
                                    <DialogContentText>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                                <CircularProgress size={90} className={classes.progress} />
                                                <Typography variant="h6" style={{ textAlign: "center" }}>
                                                    Please Wait...
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </DialogContentText>
                                </DialogContent>
                            </Dialog>
                        )}
                    </Scrollbars>
                </>
            );
        }
        return "";
    }
}
ExpansionPanelMapRecord.displayName = "ExpansionPanelMapRecord";

ExpansionPanelMapRecord = connect(
    (state, ownProps) => ({
        page: getPageId(state, ownProps),

        recordsection: getRecordSection(state, ownProps),
        recordfields: getRecordField(state, ownProps),
        allUsers: allUsers(state),
        authState: state.auth,
        forms: getForms(state, ownProps),
        parentFields: getParentFields(state, ownProps),
        recordSectionID: getRecordSectionID(state, ownProps),
        parentrecordid: getParentRecordID(state, ownProps),
    }),
    // AKA mapDispatchToProps these allow for ormParentRecordCreate, ormRecordSectionCreate etc.
    {
        ...ParentRecord.actions,
        ...RecordField.actions,
        ...RecordSection.actions,
    }
)(ExpansionPanelMapRecord);

export default withStyles(styles)(withRouter(ExpansionPanelMapRecord));
