/*****************************************************************************
 * This component is the "create" users page. It encapsulates the top "Stepper"
 * for "creating" a new user. Component calls the following outside components:
 * TextFieldWrapper.js & UserPermission.js & utilizes edit.js due to this class creating a userID first
 * The administrator of the city will have access to create a new user and define their user permissions
 *
 * Created by: Robert Gautier/Joe J.
 * Date: 3/2019
 *****************************************************************************/

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import AppContainer from "../common/AppContainer";
import MuiTextFieldWrapper from "./TextFieldWrapper";
//import UserPermissions from "./UserPermissions";
import { User } from "./models";

const getUser = User.selectByUrlId();

const styles = (theme) => ({
    root: {
        width: "100%",
        marginTop: "30px",
        marginBottom: 30,
    },
    button: {
        marginRight: theme.spacing(1),
        color: "white",
        backgroundColor: "#75bafa",
        //tie hover into button
        "&:hover": {
            backgroundColor: "#3C8ac7",
        },
    },

    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        fontSize: "16px",
    },
});

function getSteps() {
    return ["User Information", "User Permissions", "Review User and Submit"];
}

class Create extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userPosition: 0,
            submit: "false",
            activeStep: 0,
            userId: "",
        };
    }

    isStepOptional = (step) => step === 1;

    //continueStep triggered from child component "TextFieldWrapper" when errorValidation is true
    //when called will revert to adm/edit page due to new users need to have a user ID before we can allow permissions
    continueStep = (user) => {
        const { history } = this.props;
        history.push("/adm/edit/" + user.id + "?new=true"); //go to edit permissions page & include userid with true flag
    };

    //move forward with the stepper, pass value back to parent "create.js" to keep track of state
    handleNext = (el) => {
        if (this.activeStepper) {
            this.activeStepper.formApi.submitForm(); //call the formAPI method to trigger the
            this.setState({ userId: this.useInfo });
        }
    };

    //move backward with the stepper, pass value back to parent "create.js" to keep track of state
    handleBack = () => {
        this.setState({
            activeStep: this.state.activeStep - 1,
        });
    };

    //top stepper on the page, called in function to clean up render
    stepper = (classes, steps, activeStep) => {
        return (
            <div>
                <Typography variant="h6" gutterBottom>
                    Creating a New User
                </Typography>

                <div className={classes.root}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const props = {};
                            const labelProps = {};

                            //step label on the screen
                            return (
                                <Step key={label} {...props}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <div>
                        {/* BUTTONS ON STEPPER */}
                        {activeStep === steps.length ? (
                            <div>
                                <Typography className={classes.instructions}>All steps completed - user created!</Typography>
                            </div>
                        ) : (
                            <div>
                                <br />

                                <Button disabled={activeStep === 0} onClick={this.handleBack} className={classes.button}>
                                    Back
                                </Button>
                                <Button variant="raised" onClick={this.handleNext} className={classes.button}>
                                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { classes } = this.props;
        const steps = getSteps();
        const { activeStep } = this.state;
        return (
            <AppContainer authenticated>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {/* CALL THE TOP STEPPER FOR THE PAGE */}
                        {this.stepper(classes, steps, activeStep)}

                        {/* START OF BOTTOM PAGE WRAPPER, this loads the "TextFieldWrapper" */}
                        <MuiTextFieldWrapper onRef={(el) => (this.activeStepper = el)} continueStep={this.continueStep} create={true} />
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}
Create.displayName = "Create";
//state is state from store, ownProps= are props from this component if we wanted to use any type of filtering
//in this example ownProps are not being used and thus can be removed.
Create = connect((state, ownProps) => ({
    useInfo: getUser(state, ownProps), //use "getUser" from above which selects by URLiD. "useInfo" is the state component that can be used within this create.js component
}))(Create);

export default withStyles(styles)(withRouter(Create));
