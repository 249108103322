import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import Toolbar from "@material-ui/core/Toolbar";
import Close from "@material-ui/icons/Close";
import StepLabel from "@material-ui/core/StepLabel";
import LockIcon from "@material-ui/icons/Lock";

import AppContainerApplication from "../../common/AppContainerApplication";
import TextField from "../../common/TextField";
import ViewIcon from "@material-ui/icons/Visibility";
import states from "../../common/statesmn.json";
import Subquestion from "../../common/Subquestion";
import Select from "../../common/Select";
import FileInput from "../../common/FileInput";
import RadioGroup from "../../common/RadioGroup";
import Checkbox from "../../common/Checkbox";
import CheckboxGroup from "../../common/CheckboxGroup";
import { createSelector } from "../../common/orm";
import {
    ApplicationRecord,
    ApplicationRecordStatus,
    CrwdStewardshipGrant,
    CrwdStewardshipGrantAttachment,
    AttachmentSection,
} from "../../page/models";

const styles = (theme) => ({
    centerAlign: {
        textAlign: "center",
    },
    centerAlign2: {
        textAlign: "center",
        display: "inline-block",
        width: "100%",
    },
    rightAlign: {
        textAlign: "right",
        marginRight: 25,
    },
    printDivider: {
        height: 8,
        backgroundColor: "rgba(0, 0, 0, 0.42)",
        marginTop: 16,
        marginBottom: 8,
    },
    flex: {
        flex: 1,
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        margin: "0 8px 0 0",
    },
    main: {
        [theme.breakpoints.up("md")]: {
            maxWidth: "100%",
            flexBasis: "100%",
        },
    },
    root: {
        flexBasis: "100%",
        maxWidth: "100%",
    },
    btnroot: {
        color: "#9e9e9e",
    },
});

function MAKE_OPTIONS(table) {
    return table.map((row) => ({
        label: row,
        value: row,
    }));
}

const getApplication = createSelector(
    (state, ownProps) => ownProps.match.params["hash"],
    (session, hash) => {
        return session.CrwdStewardshipGrant.filter((p) => p.hash === hash).toModelArray()[0];
    }
);

class CrwdStwdshipGrantComp extends Component {
    state = {
        activeStep: 0,
        loading: false,
        new_save: false,
        mainFormKey: null,
        whatthef: null,
        attachmentOrder: "desc",
        attachmentOrderBy: null,
        temp_attachments: [],
        attachmentDialogOpen: false,
        attachmentEditing: null,
        attachmentFormKey: null,
        attachmentType: null,
        cancel_decline: false,
        // TODO Read from load
        display_project_type_other: false,
        display_project_installed_other: false,
        display_how_heard_other: false,
        display_other_race: false,
        display_review_Decision_decline: false,
        display_review_Decision_proceed: false,
        display_hired: false,
        display_edits: false,
        display_installer: false,
    };

    maxChecked = (value, field, element) => {
        if (value.length >= 1) {
            var dis = [];
            element.props.options.forEach(function (row) {
                if (!value.includes(row.value)) dis[row.value] = true;
            });

            element.setState({ disabled: dis });
        } else {
            element.setState({ disabled: [] });
        }
    };

    addAttachment = (values) => {
        var { attachmentEditing, temp_attachments, attachmentType } = this.state;

        if (attachmentType === "Applicant Construction Designs") {
            let count = 1;
            temp_attachments.forEach((a) => (a._type === "Applicant Construction Designs" ? count++ : null));
            values["name"] = `Design Attachment # ${count}`;
        }
        if (attachmentType === "Reimbursement Material") {
            let count = 1;
            temp_attachments.forEach((a) => (a._type === "Reimbursement Material" ? count++ : null));
            values["name"] = `Reimbursement Attachment # ${count}`;
        }
        //still add to array for table showing for all attachments
        values["_type"] = attachmentType;
        if (!attachmentEditing) {
            temp_attachments.push(values);
            this.setState({ temp_attachments: temp_attachments });
        } else {
            var temp = temp_attachments.filter((tc) => tc !== attachmentEditing);
            temp.push(values);
            this.setState({ temp_attachments: temp });
        }

        this.setState({ attachmentDialogOpen: false });
    };

    componentDidMount() {
        const { match, ormCrwdStewardshipGrantLoadDetailChild, ormCrwdStewardshipGrantAttachmentLoadDetailChild} = this.props;
      
        var component = this;

        if (match.params && match.params.hash) {
            ormCrwdStewardshipGrantLoadDetailChild(match.params.hash, function (hmm) {
                if (
                    (hmm[0] && hmm[0].apprec.status === "Canceled/Declined") ||
                    (hmm[0] && hmm[0].apprec.status === "Ineligible (No CWP)") ||
                    (hmm[0] && hmm[0].apprec.status === "Ineligible (CWP Sent)")
                ) {
                    component.setState({ activeStep: 0 });
                } else if (!hmm[0] || hmm[0].apprec.status === "Initial Inquiry") {
                    component.handleNext(1);
                } else if (hmm[0].apprec.status === "CWP, Application & Program Materials Sent") {
                    component.handleNext(2);
                } else if (hmm[0].apprec.status === "Proceed") {
                    component.handleNext(2);
                } else if (hmm[0].apprec.status === "Application Received and Designs Requested") {
                    component.handleNext(3);
                } else if (hmm[0].apprec.status === "Application Received and Applicant Designs") {
                    component.handleNext(4);
                } else if (hmm[0].apprec.status === "CRWD Design Review Required") {
                    component.handleNext(4);
                } else if (hmm[0].apprec.status === "Applicant Design Edits Required") {
                    component.handleNext(4);
                } else if (hmm[0].apprec.status === "Applicant Design Review Required") {
                    if (hmm[0].design_services === "Yes") {
                        component.handleNext(5);
                    } else {
                        component.handleNext(6);
                    }
                } else if (hmm[0].apprec.status === "Grant Agreement Complete") {
                    component.handleNext(6);
                } else if (hmm[0].apprec.status === "Installer Info Submitted") {
                    component.handleNext(6);
                } else if (hmm[0].apprec.status === "Closeout Inspection Complete") {
                    component.handleNext(7);
                } else if (hmm[0].apprec.status === "Reimbursement Request" || hmm[0].apprec.status === "Processing Reimbursement") {
                    component.handleNext(7);
                } else if (hmm[0].apprec.status === "More Information Needed") {
                    component.handleNext(7);
                }

                component.setState({
                    whatthef: hmm[0],
                    display_review_Decision_proceed: hmm[0].review_decision === "Proceed to Application" ? true : false,
                    display_review_Decision_decline: hmm[0].review_Decision_decline !== "" ? true : false,
                    display_installer: hmm[0].project_installer === "Hired Installer" ? true : false,
                    display_hired: hmm[0].designer_plans === "Hired Designer" ? true : false,
                    display_edits: hmm[0].crwd_plans === "Edits Requested" ? true : false,
                    mainFormKey: window.performance.now(),
                });
            });
            ormCrwdStewardshipGrantAttachmentLoadDetailChild(match.params.hash, function (data) {
                component.setState({ temp_attachments: data });
            });
        }
    
        if (match.params && match.params.print) {
            component.setState({ activeStep: "PRINT" });
        }
    }

    errorValidator = (values, temp) => {
        const { activeStep } = this.state;
        const isRequired = (val) => {
            if (!val || val.length === 0) return "Required";
            if (typeof val === "string" && !val.trim()) return "Required";
            return null;
        };
        const validateEmail = (email) => {
            var re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !re.test(email) ? "Invalid Email Address" : null;
        };

        var valObj = {};
        if (activeStep === 1) {
            valObj = {
                participant_first_name: isRequired(values.participant_first_name),
                participant_last_name: isRequired(values.participant_last_name),
                participant_title: isRequired(values.participant_title),
                participant_street: isRequired(values.participant_street),
                participant_city: isRequired(values.participant_city),
                participant_state: isRequired(values.participant_state),
                participant_email: validateEmail(values.participant_email),
                participant_zip: isRequired(values.participant_zip),
                participant_phone: isRequired(values.participant_phone),
                why_interested: isRequired(values.why_interested),
                project_type: isRequired(values.project_type),
                project_installed: isRequired(values.project_installed),
                how_heard: isRequired(values.how_heard),
                property_understand: isRequired(values.property_understand)
            };
            // valObj = {};
        } else if (activeStep === 2) {
            if (values.review_decision === "" || values.review_decision === "Decline to Proceed") {
                valObj = {
                    review_decision: isRequired(values.review_decision),
                };
            } else if (values.review_decision === "Proceed to Application") {
                valObj = {
                    review_decision: isRequired(values.review_decision),
                    understand: isRequired(values.understand),
                };
            }
        } else if (activeStep === 3) {
            valObj = {
                design_services: isRequired(values.design_services),
                bmp_ids: isRequired(values.bmp_ids),
                agree_1: isRequired(values.agree_1),
                agree_2: isRequired(values.agree_2),
                agree_3: isRequired(values.agree_3),
                agree_4: isRequired(values.agree_4),
                agree_5: isRequired(values.agree_5),
            };
        } else if (activeStep === 4) {
            valObj = {
                design_services: isRequired(values.design_services),
                bmp_ids: isRequired(values.bmp_ids),
                agree_1: isRequired(values.agree_1),
                agree_2: isRequired(values.agree_2),
                agree_3: isRequired(values.agree_3),
                agree_4: isRequired(values.agree_4),
                agree_5: isRequired(values.agree_5),
            };
        } else if (activeStep === 5) {
            if (values.designer_plans === "Hired Designer") {
                valObj = {
                    designer_plans: isRequired(values.designer_plans),
                    hired_company: isRequired(values.hired_company),
                    hired_first: isRequired(values.hired_first),
                    hired_last: isRequired(values.hired_last),
                    hired_phone: isRequired(values.hired_phone),
                    hired_email: isRequired(values.hired_email),
                };
            } else {
                //do self
                valObj = {
                    designer_plans: isRequired(values.designer_plans),
                    hired_company: null, //HACK
                    hired_first: null,
                    hired_last: null,
                    hired_phone: null,
                    hired_email: null,
                };
            }
        } else if (activeStep === 6) {
            if (values.crwd_plans === "Edits Requested") {
                valObj = {
                    crwd_plans: isRequired(values.crwd_plans),
                    edits_requested: isRequired(values.edits_requested),
                };
            } else {
                //accepted
                valObj = {
                    crwd_plans: isRequired(values.crwd_plans),
                    crwd_edits: null, //clear buffer incase we manually change on desktop to send back to client ans then they decide to not send anything
                };
            }
        } else if (activeStep === 7) {
            if (values.project_installer === "Hired Installer") {
                valObj = {
                    project_installer: isRequired(values.project_installer),
                    installer_company: isRequired(values.installer_company),
                    installer_first: isRequired(values.installer_first),
                    installer_last: isRequired(values.installer_last),
                    installer_phone: isRequired(values.installer_phone),
                    installer_email: isRequired(values.installer_email),
                };
            } else {
                //do self
                valObj = {
                    project_installer: isRequired(values.project_installer),
                    installer_company: null, //HACK to clear error validation from click back and forth on installer or self installed (more than likely due to manually setting step)
                    installer_first: null,
                    installer_last: null,
                    installer_phone: null,
                    installer_email: null,
                };
            }
        } else if (activeStep === 8) {
            valObj = {
                satisfied_overall: isRequired(values.satisfied_overall),
                satisfied_agreement: isRequired(values.satisfied_agreement),
                satisfied_design: isRequired(values.satisfied_design),
                satisfied_communication: isRequired(values.satisfied_communication),
                likely_recommend: isRequired(values.likely_recommend),
            };
        }

        var alerts = "";
        var ai = 1;
        //step2
        if (valObj["participant_first_name"] === "Required") {
            alerts += "First Name (Step 2)\n";
            ai = 1;
        }
        if (valObj["participant_last_name"] === "Required") {
            alerts += "Last Name (Step 2)\n";
            ai = 1;
        }
        if (valObj["participant_title"] === "Required") {
            alerts += "Title (Step 2)\n";
            ai = 1;
        }
        if (valObj["participant_street"] === "Required") {
            alerts += "Street (Step 2)\n";
            ai = 1;
        }
        if (valObj["participant_state"] === "Required") {
            alerts += "State (Step 2)\n";
            ai = 1;
        }
        if (valObj["participant_city"] === "Required") {
            alerts += "City (Step 2)\n";
            ai = 1;
        }
        if (valObj["participant_zip"] === "Required") {
            alerts += "ZIP (Step 2)\n";
            ai = 1;
        }
        if (valObj["participant_email"] === "Required") {
            alerts += "Email (Step 2)\n";
            ai = 1;
        }
        if (valObj["participant_phone"] === "Required") {
            alerts += "Phone (Step 2)\n";
            ai = 1;
        }
        if (valObj["why_interested"] === "Required") {
            alerts += "Why are you interested in a clean water project? (Step 2)\n";
            ai = 1;
        }
        if (valObj["project_type"] === "Required") {
            alerts += "What type of clean water project are you interested in? (Step 2)\n";
            ai = 1;
        }
        if (valObj["project_installed"] === "Required") {
            alerts += "Where on the property would you like the clean water project installed? (Step 2)\n";
            ai = 1;
        }
        if (valObj["how_heard"] === "Required") {
            alerts += "How did you hear about CRWD's Clean Water Project Grant Program?  (Step 2)\n";
            ai = 1;
        }
        if (valObj["property_understand"] === "Required") {
            alerts += "I understand that by submitting this form, I give CRWD permission to access the property ?  (Step 2)\n";
            ai = 1;
        }

        //step3
        if (valObj["review_decision"] === "Required") {
            alerts += "Review Decision  (Step 3)\n";
            ai = 2;
        }
        if (valObj["understand"] === "Required") {
            alerts += "I understand the Clean Water Proposal is a conceptual document and I have not been awarded a grant  (Step 3)\n";
            ai = 2;
        }

        //step4
        if (valObj["design_services"] === "Required") {
            alerts += "Are you requesting design services through CRWD? (Step 4)\n";
            ai = 3;
        }
        if (valObj["bmp_ids"] === "Required") {
            alerts += "List the BMP IDs from your CWP for which you are requesting grant funding (Step 4)\n";
            ai = 3;
        }
        if (valObj["agree_1"] === "Required") {
            alerts +=
                "I understand this is an application for the Clean Water Project Grant Program and I have not been awarded a grant.  (Step 4)\n";
            ai = 3;
        }
        if (valObj["agree_2"] === "Required") {
            alerts += "I have received and read the Guidelines and Policies document(s).  (Step 4)\n";
            ai = 3;
        }
        if (valObj["agree_3"] === "Required") {
            alerts +=
                "I understand the Clean Water Proposal is not a construction document and any construction completed or supplies purchased before a grant agreement is signed do not qualify for grant funding.  (Step 4)\n";
            ai = 3;
        }
        if (valObj["agree_4"] === "Required") {
            alerts += "I understand grant funding will be reimbursed after a project is completed and passed a final inspection by CRWD. (Step 4)\n";
            ai = 3;
        }
        if (valObj["agree_5"] === "Required") {
            alerts +=
                "I understand there is a minimum 10-year operation and maintenance (O&M) requirement for Clean Water Project Grant Projects, and an O&M plan will be provided with the grant agreement. (Step 4)\n";
            ai = 3;
        }
        //step5
        if (valObj["designer_plans"] === "Required") {
            alerts += "Designer plans (Step 5)\n";
            ai = 4;
        }
        if (values.designer_plans === "Hired Designer") {
            if (valObj["hired_company"] === "Required") {
                alerts += "Hired Company (Step 5)\n";
                ai = 4;
            }
            if (valObj["hired_first"] === "Required") {
                alerts += "First Name. (Step 5)\n";
                ai = 4;
            }
            if (valObj["hired_last"] === "Required") {
                alerts += "Last Name (Step 5)\n";
                ai = 4;
            }
            if (valObj["hired_phone"] === "Required") {
                alerts += "Phone (Step 5)\n";
                ai = 4;
            }
            if (valObj["hired_email"] === "Required") {
                alerts += "Email (Step 5)\n";
                ai = 4;
            }
        }
        //step6
        if (valObj["crwd_plans"] === "Required") {
            alerts += "Design review (Step 6)\n";
            ai = 5;
        }
        if (values.crwd_plans === "Edits Requested") {
            if (valObj["edits_requested"] === "Required") {
                alerts += "Edits (Step 6)\n";
                ai = 5;
            }
        }
        //step7
        if (valObj["project_installer"] === "Required") {
            alerts += "Project Installer (Step 7)\n";
            ai = 6;
        }
        if (values.project_installer === "Hired Installer") {
            if (valObj["installer_company"] === "Required") {
                alerts += "Hired Company (Step 7)\n";
                ai = 6;
            }
            if (valObj["installer_first"] === "Required") {
                alerts += "First Name. (Step 7)\n";
                ai = 6;
            }
            if (valObj["installer_last"] === "Required") {
                alerts += "Last Name (Step 7)\n";
                ai = 6;
            }
            if (valObj["installer_phone"] === "Required") {
                alerts += "Phone (Step 7)\n";
                ai = 6;
            }
            if (valObj["installer_email"] === "Required") {
                alerts += "Email (Step 7)\n";
                ai = 6;
            }
        }
        //step 8
        if (valObj["satisfied_overall"] === "Required") {
            alerts += "How satisfied were you with the overall Clean Water Project Grant Program? (Step 8)\n";
            ai = 7;
        }
        if (valObj["satisfied_agreement"] === "Required") {
            alerts += "How satisfied were you with the Clean Water Project Grant Agreement process? (Step 8)\n";
            ai = 7;
        }
        if (valObj["satisfied_design"] === "Required") {
            alerts += "How satisfied were you with the design process? (Step 8)\n";
            ai = 7;
        }
        if (valObj["satisfied_communication"] === "Required") {
            alerts += "How satisfied were you with CRWD's communication throughout the grant process? (Step 8)\n";
            ai = 7;
        }
        if (valObj["likely_recommend"] === "Required") {
            alerts += "How likely are you to recommend the Clean Water Project Grant Program to others? (Step 8)\n";
            ai = 7;
        }

        if (alerts) {
            this.setState({ activeStep: ai });
            alert("You are missing the following required fields:\n" + alerts);
        }

        return valObj;
    };

    submitApplication = (values) => {
        const {
            ormApplicationRecordCreate,
            ormApplicationRecordStatusCreate,
            ormCrwdStewardshipGrantCreate,
            ormCrwdStewardshipGrantAttachmentCreate,
            // ormCrwdStewardshipGrantUpdate,
            ormCrwdStewardshipGrantAppUpdate,
            application,
        } = this.props;
        const { whatthef, temp_attachments, display_review_Decision_decline, display_review_Decision_proceed, activeStep } = this.state;

        const _this = this;
        this.setState({ loading: true });

        // Editing
        if (whatthef) {
            //step3 decline only do once
            if (activeStep === 2) {
                if (display_review_Decision_decline && !display_review_Decision_proceed && application.apprec.status !== "Canceled/Declined") {
                    ormCrwdStewardshipGrantAppUpdate({
                        review_Decision_decline: values.review_Decision_decline,
                        id: whatthef.id,
                    }).then((duh) => {
                        ormApplicationRecordStatusCreate({
                            applicationrecord: application.apprec.id,
                            status: "Canceled/Declined",
                            send_email: "Yes"
                        });
                        _this.setState({ loading: false, cancel_decline: true });
                    });
                } else if (!display_review_Decision_decline && display_review_Decision_proceed && whatthef) {
                    //SUBMIT  for step3 with agreeing to proceed we dont create a status update here
                    let obj = {};
                    obj = whatthef;
                    obj["apprec"]["status"] = "Proceed";
                    ormCrwdStewardshipGrantAppUpdate({
                        review_decision: values.review_decision,
                        understand: values.understand,
                        id: whatthef.id,
                    }).then((duh) => {
                        _this.setState({ whatthef: obj, loading: false });
                        _this.handleNext();
                    });
                } else {
                    this.setState({ loading: false });
                }
            } else if (activeStep === 3) {
                if (values.design_services === "Yes") {
                    ormCrwdStewardshipGrantAppUpdate({
                        id: whatthef.id,
                        design_services: values.design_services,
                        bmp_ids: values.bmp_ids,
                        agree_1: values.agree_1,
                        agree_2: values.agree_2,
                        agree_3: values.agree_3,
                        agree_4: values.agree_4,
                        agree_5: values.agree_5,
                    }).then((duh) => {
                        let comp = _this;
                        ormApplicationRecordStatusCreate({
                            applicationrecord: whatthef.apprec.id,
                            status: "Application Received and Designs Requested",
                            send_email:"Yes"
                        });
                        comp.setState({ loading: false, new_save: true });
                    });
                } else if (values.design_services === "No") {
                    ormCrwdStewardshipGrantAppUpdate({
                        id: whatthef.id,
                        design_services: values.design_services,
                        bmp_ids: values.bmp_ids,
                        agree_1: values.agree_1,
                        agree_2: values.agree_2,
                        agree_3: values.agree_3,
                        agree_4: values.agree_4,
                        agree_5: values.agree_5,
                    }).then((duh) => {
                        ormApplicationRecordStatusCreate({
                            applicationrecord: whatthef.apprec.id,
                            status: "Application Received and Applicant Designs",
                            send_email:"Yes"
                        });
                        let obj = {};
                        obj = whatthef;
                        obj["apprec"]["status"] = "Application Received and Applicant Designs";
                        _this.setState({ loading: false, whatthef: obj });
                        _this.handleNext();
                    });
                }
            } else if (activeStep === 5) {
                ormCrwdStewardshipGrantAppUpdate({
                    id: whatthef.id,
                    designer_plans: values.designer_plans,
                    hired_company: values.hired_company,
                    hired_first: values.hired_first,
                    hired_last: values.hired_last,
                    hired_phone: values.hired_phone,
                    hired_email: values.hired_email,
                }).then((duh) => {
                    let doWeHave = temp_attachments.find((f) => f._type === "Applicant Construction Designs");

                    if (doWeHave && doWeHave.file) {
                        //create the attachments now then save status
                        let filtered = temp_attachments.filter((f) => f._type === "Applicant Construction Designs");
                        filtered.forEach((at, idx) => {
                            ormCrwdStewardshipGrantAttachmentCreate({
                                crwdstewardshipgrant_id: application.id,
                                _type: "Applicant Construction Designs",
                                ...at,
                            }).then((i) => {
                                if (idx + 1 === filtered.length) {
                                    ormApplicationRecordStatusCreate({
                                        applicationrecord: whatthef.apprec.id,
                                        status: "CRWD Design Review Required",
                                        send_email:"Yes"
                                    });
                                }
                            });
                        });
                    } else {
                        //dont have attachments
                        ormApplicationRecordStatusCreate({
                            applicationrecord: whatthef.apprec.id,
                            status: "CRWD Design Review Required",
                            send_email:"Yes"
                        });
                    }

                    let obj = {};
                    obj = whatthef;
                    obj["apprec"]["status"] = "CRWD Design Review Required";
                    _this.setState({ loading: false, new_save: true, whatthef: obj });
                });
            } else if (activeStep === 6) {
                if (values.crwd_plans === "Designs Accepted") {
                    ormCrwdStewardshipGrantAppUpdate({
                        id: whatthef.id,
                        crwd_plans: values.crwd_plans,
                    }).then((duh) => {
                        ormApplicationRecordStatusCreate({
                            applicationrecord: whatthef.apprec.id,
                            status: "Designs Finalized",
                            send_email:"Yes"
                        });
                        _this.setState({ loading: false, new_save: true });
                    });
                } else if (values.crwd_plans === "Edits Requested") {
                    ormApplicationRecordStatusCreate({
                        applicationrecord: whatthef.apprec.id,
                        status: "CRWD Design Edits Required",
                        send_email:"Yes"

                    }).then((duh) => {
                        ormCrwdStewardshipGrantAppUpdate({
                            id: whatthef.id,
                            crwd_plans: values.crwd_plans,
                            crwd_edits: values.crwd_edits,
                            edits_requested: values.edits_requested,
                        });
                        _this.setState({ loading: false, new_save: true });
                    });
                }
            } else if (activeStep === 7) {
                ormCrwdStewardshipGrantAppUpdate({
                    id: whatthef.id,
                    project_installer: values.project_installer,
                    installer_company: values.installer_company,
                    installer_first: values.installer_first,
                    installer_last: values.installer_last,
                    installer_phone: values.installer_phone,
                    installer_email: values.installer_email,
                }).then((duh) => {
                    ormApplicationRecordStatusCreate({
                        applicationrecord: whatthef.apprec.id,
                        status: "Installer Info Submitted",
                        send_email:"Yes"

                    });
                    let obj = {};
                    obj = whatthef;
                    obj["apprec"]["status"] = "Installer Info Submitted";
                    _this.setState({ loading: false, new_save: true, whatthef: obj });
                });
            } else if (activeStep === 8) {
                ormCrwdStewardshipGrantAppUpdate({
                    id: whatthef.id,
                    satisfied_overall: values.satisfied_overall,
                    satisfied_agreement: values.satisfied_agreement,
                    satisfied_design: values.satisfied_design,
                    satisfied_communication: values.satisfied_communication,
                    likely_recommend: values.likely_recommend,
                    satisfied_comments: values.satisfied_comments,
                }).then((duh) => {
                    let doWeHave = temp_attachments.find((f) => f._type === "Reimbursement Material");

                    if (doWeHave && doWeHave.file) {
                        //create the attachments now then save status
                        let filtered = temp_attachments.filter((f) => f._type === "Reimbursement Material");
                        filtered.forEach((at, idx) => {
                            ormCrwdStewardshipGrantAttachmentCreate({
                                crwdstewardshipgrant_id: application.id,
                                _type: "Reimbursement Material",
                                ...at,
                            }).then((i) => {
                                if (idx + 1 === filtered.length) {
                                    ormApplicationRecordStatusCreate({
                                        applicationrecord: whatthef.apprec.id,
                                        status: "Reimbursement Request",
                                        send_email:"Yes"

                                    });
                                }
                            });
                        });
                    } else {
                        //dont have attachments
                        ormApplicationRecordStatusCreate({
                            applicationrecord: whatthef.apprec.id,
                            status: "Reimbursement Request",
                            send_email:"Yes"
                        });
                    }

                    _this.setState({ loading: false, new_save: true });
                });
            }
        } else {
            // New
            ormApplicationRecordCreate({
                application: 8,
            }).then((ar) => {
                ormApplicationRecordStatusCreate({
                    applicationrecord: ar.id,
                    status: "Initial Inquiry",
                    send_email:"Yes"

                });
                ormCrwdStewardshipGrantCreate({
                    applicationrecord: ar.id,
                    ...values,
                }).then((rpb) => {
                    var i = 0;
                    var j = 0;
                    temp_attachments.forEach(function (tc) {
                        i++;
                        ormCrwdStewardshipGrantAttachmentCreate({
                            crwdstewardshipgrant_id: rpb.id,
                            ...tc,
                        }).then((_z) => {
                            j++;
                            if (i === j) {
                                ormCrwdStewardshipGrantAppUpdate({ id: rpb.id });
                                _this.setState({ loading: false, new_save: true });
                            }
                        });
                    });
                    if (i === 0) {
                        // No attachments or contacts - its finished loading
                        ormCrwdStewardshipGrantAppUpdate({ id: rpb.id });
                        _this.setState({ loading: false, new_save: true });
                    }
                });
            });
        }
    };

    handleNext = (index) => {
        const { activeStep } = this.state;

        if (index || index === 0) {
            this.setState({ activeStep: index });
        } else {
            this.setState({ activeStep: activeStep + 1 });
        }
        window.scrollTo(0, 0);
    };
    defaultValues=(v)=>{
        const{application} =this.props
        const {activeStep} = this.state
        var obj = {}

        if (!application && this.formApi && activeStep === 1){
            if (this.formApi.getValue('onsite_visit') !== 'I would like to be onsite for the site visit')
            this.formApi.setValue('onsite_visit',"I do not need to be onsite for the site visit")
        }else{
            obj = application
        }

        return obj
    }

    render() {
        const { classes, ormCrwdStewardshipGrantAttachmentDelete } = this.props;
        const {
            activeStep,
            loading,
            new_save,
            mainFormKey,
            temp_attachments,
            attachmentOrder,
            attachmentOrderBy,
            display_project_type_other,
            display_project_installed_other,
            display_how_heard_other,
            display_other_race,
            display_review_Decision_decline,
            display_review_Decision_proceed,
            display_hired,
            display_edits,
            display_installer,
            attachmentDialogOpen,
            attachmentFormKey,
            attachmentEditing,
            attachmentType,
            whatthef,
            cancel_decline,
        } = this.state;

        if (new_save) {
            return (
                <AppContainerApplication city_id={"10"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} lg={10} xl={8}>
                            {activeStep === 2 && <Typography>This is a confirmation that your Initial Inquiry has been submitted.</Typography>}

                            {activeStep === 3 && <Typography>This is a confirmation that your Grant Application has been submitted.</Typography>}
                            {activeStep === 5 && (
                                <Typography>This is a confirmation that your Clean Water Project Designs has been submitted.</Typography>
                            )}
                            {activeStep === 6 && <Typography>This is a confirmation that your Design Review has been submitted.</Typography>}
                            {activeStep === 7 && <Typography>This is a confirmation that your Installer Information has been submitted.</Typography>}
                            {activeStep === 8 && <Typography>This is a confirmation that your Reimbursement Request has been submitted.</Typography>}
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }
        if (cancel_decline) {
            return (
                <AppContainerApplication city_id={"10"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} lg={10} xl={8}>
                            <Typography>This is a confirmation that you have declined to proceed.</Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }

        var is_read_only = [];
        var step_array = [];

        if (whatthef && whatthef.apprec.status === "Canceled/Declined" && !cancel_decline) this.setState({ cancel_decline: true });
        else if (!whatthef || whatthef.apprec.status === "Initial Inquiry") {
            step_array = [
                "Introduction",
                "Inquiry Form",
                "Clean Water Proposal & Material Review Locked",
                "Grant Application Locked",
                "Applicant Designs Upload Locked",
                "CRWD Designs Locked",
                "Agreement and Installer Information Locked",
                "Reimbursement Request and Feedback Locked",
            ];
        } else if (whatthef.apprec.status === "CWP, Application & Program Materials Sent") {
            step_array = [
                "Introduction",
                "Inquiry Form",
                "Clean Water Proposal & Material Review",
                "Grant Application Locked",
                "Applicant Designs Upload Locked",
                "CRWD Designs Locked",
                "Agreement and Installer Information Locked",
                "Reimbursement Request and Feedback Locked",
            ];
            is_read_only = [0, 1];
        } else if (whatthef.apprec.status === "CWP, Application & Program Materials Sent") {
            step_array = [
                "Introduction",
                "Inquiry Form",
                "Clean Water Proposal & Material Review",
                "Grant Application Locked",
                "Applicant Designs Upload Locked",
                "CRWD Designs Locked",
                "Agreement and Installer Information Locked",
                "Reimbursement Request and Feedback Locked",
            ];
            is_read_only = [0, 1];
        } else if (whatthef.apprec.status === "Proceed") {
            step_array = [
                "Introduction",
                "Inquiry Form",
                "Clean Water Proposal & Material Review",
                "Grant Application",
                "Applicant Designs Upload Locked",
                "CRWD Designs Locked",
                "Agreement and Installer Information Locked",
                "Reimbursement Request and Feedback Locked",
            ];
            is_read_only = [0, 1, 2];
        } else if (whatthef.apprec.status === "Application Received and Designs Requested") {
            step_array = [
                "Introduction",
                "Inquiry Form",
                "Clean Water Proposal & Material Review",
                "Grant Application",
                "Applicant Designs Upload Locked",
                "CRWD Designs Locked",
                "Agreement and Installer Information Locked",
                "Reimbursement Request and Feedback Locked",
            ];
            is_read_only = [0, 1, 2, 3];
        } else if (whatthef.apprec.status === "Application Received and Applicant Designs") {
            step_array = [
                "Introduction",
                "Inquiry Form",
                "Clean Water Proposal & Material Review",
                "Grant Application",
                "Applicant Designs Upload",
                "CRWD Designs Locked",
                "Agreement and Installer Information Locked",
                "Reimbursement Request and Feedback Locked",
            ];
            is_read_only = [0, 1, 2, 3];
        } else if (whatthef.apprec.status === "CRWD Design Review Required") {
            step_array = [
                "Introduction",
                "Inquiry Form",
                "Clean Water Proposal & Material Review",
                "Grant Application",
                "Applicant Designs Upload",
                "CRWD Designs Locked",
                "Agreement and Installer Information Locked",
                "Reimbursement Request and Feedback Locked",
            ];
            is_read_only = [0, 1, 2, 3, 4];
        } else if (whatthef.apprec.status === "Applicant Design Edits Required") {
            step_array = [
                "Introduction",
                "Inquiry Form",
                "Clean Water Proposal & Material Review",
                "Grant Application",
                "Applicant Designs Upload",
                "CRWD Designs Locked",
                "Agreement and Installer Information Locked",
                "Reimbursement Request and Feedback Locked",
            ];
            is_read_only = [0, 1, 2, 3];
        } else if (whatthef.apprec.status === "Applicant Design Review Required") {
            step_array = [
                "Introduction",
                "Inquiry Form",
                "Clean Water Proposal & Material Review",
                "Grant Application",
                whatthef.design_services === "Yes" ? "Applicant Designs Locked" : "Applicant Designs Upload",
                whatthef.design_services === "Yes" ? "CRWD Designs" : "CRWD Designs Locked",
            ];
            if (whatthef.design_services === "No") step_array.push("Agreement and Installer Information");
            else step_array.push("Agreement and Installer Information Locked");
            step_array.push("Reimbursement Request and Feedback Locked");

            is_read_only = [0, 1, 2, 3, 4];
        } else if (whatthef.apprec.status === "Grant Agreement Complete") {
            step_array = [
                "Introduction",
                "Inquiry Form",
                "Clean Water Proposal & Material Review",
                "Grant Application",
                whatthef.design_services === "Yes" ? "Applicant Designs Locked" : "Applicant Designs Upload",
                whatthef.design_services === "Yes" ? "CRWD Designs" : "CRWD Designs Locked",
                "Agreement and Installer Information",
                "Reimbursement Request and Feedback Locked",
            ];
            is_read_only = [0, 1, 2, 3, 4, 5];
        } else if (whatthef.apprec.status === "Installer Info Submitted") {
            step_array = [
                "Introduction",
                "Inquiry Form",
                "Clean Water Proposal & Material Review",
                "Grant Application",
                whatthef.design_services === "Yes" ? "Applicant Designs Locked" : "Applicant Designs Upload",
                whatthef.design_services === "Yes" ? "CRWD Designs" : "CRWD Designs Locked",
                "Agreement and Installer Information",
                "Reimbursement Request and Feedback Locked",
            ];
            is_read_only = [0, 1, 2, 3, 4, 5, 6];
        } else if (whatthef.apprec.status === "Closeout Inspection Complete" || whatthef.apprec.status === "More Information Needed") {
            step_array = [
                "Introduction",
                "Inquiry Form",
                "Clean Water Proposal & Material Review",
                "Grant Application",
                whatthef.design_services === "Yes" ? "Applicant Designs Locked" : "Applicant Designs Upload",
                whatthef.design_services === "Yes" ? "CRWD Designs" : "CRWD Designs Locked",
                "Agreement and Installer Information",
                "Reimbursement Request and Feedback",
            ];
            is_read_only = [0, 1, 2, 3, 4, 5, 6];
        } else if (whatthef.apprec.status === "Reimbursement Request" || whatthef.apprec.status === "Processing Reimbursement") {
            //only used if applicat comes back to see all as readonly
            step_array = [
                "Introduction",
                "Inquiry Form",
                "Clean Water Proposal & Material Review",
                "Grant Application",
                whatthef.design_services === "Yes" ? "Applicant Designs Locked" : "Applicant Designs Upload",
                whatthef.design_services === "Yes" ? "CRWD Designs" : "CRWD Designs Locked",
                "Agreement and Installer Information",
                "Reimbursement Request and Feedback",
            ];
            is_read_only = [0, 1, 2, 3, 4, 5, 6, 7];
        }

        //FOR TESTING TO UNLOCK ALL STEPS, ETC.
        // step_array = [
        //     "Introduction",
        //     "Inquiry Form",
        //     "Clean Water Proposal & Material Review",
        //     "Grant Application",
        //     "Applicant Designs Upload",
        //     "CRWD Designs",
        //     "Agreement and Installer Information",
        //     "Reimbursement Request and Feedback",
        // ];
        // is_read_only = [];

        var columnDataAttachments = [
            { id: "actions", numeric: false, label: "", allowSort: false, width: "25px" },
            { id: "name", numeric: false, label: "Name", allowSort: true },
            { id: "notes", numeric: false, label: "Notes", allowSort: true },
        ];
        if (whatthef && whatthef.apprec.status !== "Initial Inquiry" && step_array.length === 0) {
            return (
                <AppContainerApplication city_id={"10"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} lg={10} xl={8}>
                            <Typography gutterBottom>
                                Welcome to the Capitol Region Watershed District (CRWD) Clean Water Project Grant Portal. If you have submitted your
                                grant please wait for additional contact instructions from CRWD.
                            </Typography>
                            <Typography gutterBottom>
                                Or visit our website to determine if{" "}
                                <a href="https://www.capitolregionwd.org/about-crwd/" target="_blank" rel="noopener noreferrer">
                                    you are in CRWD
                                </a>
                                , and to learn more about our{" "}
                                <a href="https://www.capitolregionwd.org/grants/" target="_blank" rel="noopener noreferrer">
                                    grant programs
                                </a>
                                .
                            </Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }
        var designercontact = "";
        var designercompany = "";
        var staffcontactemail = "";

        if (whatthef && whatthef.contacts && whatthef.contacts.designercontact) {
            designercontact = whatthef.contacts.designercontact === "Joe Lochner" ? "joseph.lochner@co.ramsey.mn.us" : "Other";
            designercompany =
                whatthef.contacts.designercompany === "Ramsey County Soil and Water Conservation Division"
                    ? "Ramsey County Soil and Water Conservation"
                    : "Other";
            staffcontactemail =
                whatthef.contacts.staffcontact === "Alexandra Morrison"
                    ? "amorrison@capitolregionwd.org"
                    : whatthef.contacts.staffcontact === "Rachel Funke"
                    ? "rfunke@capitolregionwd.org"
                    : whatthef.contacts.staffcontact === "Chris Kucek"
                    ? "ckucek@capitolregionwd.org"
                    : whatthef.contacts.staffcontact === "Nate Zwonitzer"
                    ? "nzwonitzer@capitolregionwd.org"
                    : whatthef.contacts.staffcontact === "Lucas Roessler-Caram"
                    ? "lroessler-caram@capitolregionwd.org"
                    : "Other";
        }
      

        return (
            <AppContainerApplication city_id={"10"} page_loading={loading} crwdgrant={true} printing={activeStep === "PRINT"}>
                <Form
                    getApi={(el) => (this.formApi = el)}
                    defaultValues={this.defaultValues()}
                    key={mainFormKey}
                    dontValidateOnMount={true}
                    validateOnSubmit={true}
                    validateError={this.errorValidator}
                    onSubmit={(values) => this.submitApplication(values)}>
                    {(formApi) => (
                        <form onSubmit={formApi.submitForm}>
                            <Grid container spacing={1} style={{ padding: "0 12px" }}>
                                <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                    <Typography className={classes.centerAlign} variant="h6">
                                        Clean Water Project Grant Application Form
                                    </Typography>
                                    <Typography className={classes.centerAlign}>
                                        <b>IMPORTANT:</b> All available pages must be filled out at one time and progress will not be saved so please
                                        plan accordingly.
                                    </Typography>
                                    <Typography variant="caption" className={classes.centerAlign2}>
                                        Required Fields are marked with an asterisk (*)
                                    </Typography>
                                </Grid>
                                {activeStep !== "PRINT" && (
                                    <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                        <Stepper style={{ padding: 8 }} alternativeLabel nonLinear activeStep={activeStep}>
                                            {step_array.map((label, index) => {
                                                if (label !== "" && label.includes("Locked")) {
                                                    return (
                                                        <Tooltip title="This page is not available.">
                                                            <Step key={index}>
                                                                <StepButton
                                                                    disabled
                                                                    className={classes.btnroot}
                                                                    focusVisibleClassName={classes.pointerr}>
                                                                    <StepLabel
                                                                        StepIconComponent={LockIcon}
                                                                        classes={{
                                                                            labelContainer: classes.lessMargin,
                                                                        }}>
                                                                        {label}
                                                                    </StepLabel>
                                                                </StepButton>
                                                            </Step>
                                                        </Tooltip>
                                                    );
                                                } else {
                                                    return (
                                                        <Step key={label}>
                                                            <StepButton style={{ cursor: "pointer" }} onClick={() => this.handleNext(index)}>
                                                                {label}
                                                            </StepButton>
                                                        </Step>
                                                    );
                                                }
                                            })}
                                            {/* <Tooltip title="This page is not available yet.">
                                                <Step key="Example Locked Page">
                                                    <StepButton disabled className={classes.btnroot} focusVisibleClassName={classes.pointerr}>
                                                        <StepLabel
                                                            StepIconComponent={LockIcon}
                                                            classes={{
                                                                labelContainer: classes.lessMargin,
                                                            }}>
                                                            Example Locked Page
                                                        </StepLabel>
                                                    </StepButton>
                                                </Step>
                                            </Tooltip> */}
                                        </Stepper>
                                    </Grid>
                                )}
                                {(activeStep === 0 || activeStep === "PRINT") && (
                                    <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            <b>Introduction</b>
                                        </Typography>
                                        <Typography gutterBottom>
                                            Welcome to the Capitol Region Watershed District (CRWD) Clean Water Project Grant Portal. The Grant Portal
                                            will guide you through the Clean Water Project Grant process and keep you on track to complete your
                                            project. Throughout this process you will be working with CRWD staff and a Ramsey County Soil and Water
                                            Conservation Division Designer to determine grant eligibility and to provide technical assistance.
                                        </Typography>
                                        <Typography gutterBottom>
                                            Visit our website to determine if{" "}
                                            <a href="https://www.capitolregionwd.org/about-crwd/" target="_blank" rel="noopener noreferrer">
                                                you are in CRWD
                                            </a>
                                            , and to learn more about our{" "}
                                            <a href="https://www.capitolregionwd.org/grants/" target="_blank" rel="noopener noreferrer">
                                                grant programs
                                            </a>
                                            .
                                        </Typography>
                                        {/* <Typography gutterBottom>
                                            Questions: <a href="mailto: clundgren@capitolregionwd.org">clundgren@capitolregionwd.org</a> or
                                            651-644-8888 x111
                                        </Typography> */}
                                    </Grid>
                                )}
                                {activeStep === 0 && activeStep !== "PRINT" && (
                                    <Grid item xs={12} xl={10} className={classes.rightAlign} classes={{ root: classes.root }}>
                                        <Button onClick={() => this.handleNext()} variant="contained" color="primary">
                                            Next
                                        </Button>
                                    </Grid>
                                )}
                                {activeStep === "PRINT" && (
                                    <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                        <Divider className={classes.printDivider} />
                                    </Grid>
                                )}
                                {(activeStep === 1 || activeStep === "PRINT") && (
                                    <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                        <Typography gutterBottom>
                                            The first step in the Clean Water Project Grant process is for CRWD to review the site and conduct a site visit if needed. 
                                        </Typography>
                                        <Typography variant="subtitle1" gutterBottom>
                                            <b>Participant Information</b>
                                        </Typography>
                                        <Grid container spacing={1} style={{ marginBottom: 16 }}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    required
                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                    field="participant_first_name"
                                                    label="First Name"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    required
                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                    field="participant_last_name"
                                                    label="Last Name"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}> 
                                                <Select
                                                    required
                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                    field="participant_title"
                                                    label="Title"
                                                    options={MAKE_OPTIONS([
                                                        "Property Owner",
                                                        "Executive Director",
                                                        "President / CEO",
                                                        "Project Manager",
                                                        "Employee",
                                                        "Other", 
                                                    ])}
                                                    fullWidth
                                                />
                                            </Grid>
                                            
                                            <Grid item xs={6}>
                                                {this.formApi.getValue('participant_title') === 'Other' && (
                                                    <TextField
                                                        required
                                                        disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                        field="participant_other"
                                                        label="Other"
                                                        fullWidth
                                                    />
                                                )}
                                            </Grid>
                                            
                                            
                                            <Grid item xs={6}>
                                                <TextField
                                                    required
                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                    field="participant_street"
                                                    label="Street"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    required
                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                    field="participant_city"
                                                    label="City"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Select
                                                    required
                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                    field="participant_state"
                                                    label="State"
                                                    options={states}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                    field="participant_zip"
                                                    label="Zip"
                                                    required
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    required
                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                    field="participant_email"
                                                    label="Email"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                    field="participant_phone"
                                                    label="Phone"
                                                    fullWidth
                                                    required
                                                    usePhoneNumberFormat
                                                />
                                            </Grid>
                                        </Grid>
                                        <Typography variant="subtitle1" gutterBottom>
                                            <b>Project Location (if different than participant address)</b>
                                        </Typography>
                                        <Grid container spacing={1} style={{ marginBottom: 16 }}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                    field="project_street"
                                                    label="Street"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                    field="project_city"
                                                    label="City"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Select
                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                    field="project_state"
                                                    label="State"
                                                    options={states}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                    field="project_zip"
                                                    label="Zip"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                        <Typography variant="subtitle1" gutterBottom>
                                            <b>Secondary Contact (not required)</b>
                                        </Typography>
                                        <Grid container spacing={1} style={{ marginBottom: 16 }}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                    field="sec_first_name"
                                                    label="First Name"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                    field="sec_last_name"
                                                    label="Last Name"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}> 
                                                 <Select
                                                    
                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                    field="sec_participant_title"
                                                    label="Title"
                                                    options={MAKE_OPTIONS([
                                                        "Property Owner",
                                                        "Executive Director",
                                                        "President / CEO",
                                                        "Project Manager",
                                                        "Employee",
                                                        "Other", 
                                                    ])}
                                                    fullWidth
                                                />
                                            </Grid>
                                            
                                            <Grid item xs={6}>
                                                {this.formApi.getValue('sec_participant_title') === 'Other' && (
                                                    <TextField
                                                        disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                        field="sec_participant_other"
                                                        label="Other"
                                                        fullWidth
                                                    /> 
                                                )}
                                            </Grid>
                                           
                                            <Grid item xs={6}>
                                                <TextField
                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                    field="sec_street"
                                                    label="Street"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                    field="sec_city"
                                                    label="City"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                    field="sec_state"
                                                    label="State"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                    field="sec_zip"
                                                    label="Zip"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                    field="sec_email"
                                                    label="Email"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                    field="sec_phone"
                                                    label="Phone"
                                                    fullWidth
                                                    usePhoneNumberFormat
                                                />
                                            </Grid>
                                        </Grid>
                                        <Typography variant="subtitle1" gutterBottom style={{ marginBottom: 10 }}>
                                            <b>Project Questions</b>
                                        </Typography>
                                        <Typography gutterBottom style={{ marginBottom: 10 }}>
                                            Clean water projects are stormwater best management practices (BMPs) that help prevent stormwater
                                            pollution from entering our lakes and the Mississippi River.
                                        </Typography>
                                        <br />
                                        <Grid container spacing={1} style={{ marginBottom: 16 }}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    required
                                                    useCustom2
                                                    label="Why are you interested in a clean water project? (Examples: Protect the river, provide pollinator habitat, improve water resources, fix a drainage issue)"
                                                    field="why_interested"
                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                    fullWidth
                                                    rows={4}
                                                    multiline
                                                />
                                                <CheckboxGroup
                                                    field="project_type"
                                                    label="What type of clean water project are you interested in? (Check all that apply)"
                                                    required
                                                    row={true}
                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                    eventHandle={(e) => {
                                                        if (e.includes("Other")) {
                                                            this.setState({ display_project_type_other: true });
                                                        } else {
                                                            this.setState({ display_project_type_other: false });
                                                        }
                                                    }}
                                                    options={MAKE_OPTIONS([
                                                        "Rain Garden",
                                                        "Cistern/Water Reuse System",
                                                        "Green Roof",
                                                        "Permeable Hard Surface",
                                                        "Shoreline Buffer",
                                                        "Tree Trench",
                                                        "Native Landscaping",
                                                        "Impervious Removal",
                                                        "Unsure",
                                                        "Other",
                                                    ])}
                                                />
                                                {display_project_type_other && (
                                                    <Subquestion
                                                        component={
                                                            <TextField
                                                                disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                                field="project_type_other"
                                                                label="What is the other clean water project you are interested in?"
                                                                fullWidth
                                                            />
                                                        }
                                                    />
                                                )}
                                                <CheckboxGroup
                                                    field="project_installed"
                                                    label="Where on the property would you like the clean water project installed? (Check all that apply)"
                                                    required
                                                    row={true}
                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                    eventHandle={(e) => {
                                                        if (e.includes("Other")) {
                                                            this.setState({ display_project_installed_other: true });
                                                        } else {
                                                            this.setState({ display_project_installed_other: false });
                                                        }
                                                    }}
                                                    options={MAKE_OPTIONS([
                                                        "Boulevard",
                                                        "Front Yard",
                                                        "Back Yard",
                                                        "Side of House",
                                                        "Near Alley",
                                                        "Driveway",
                                                        "Garage Roof",
                                                        "House Roof",
                                                        "Parking Lot",
                                                        "Unsure",
                                                        "Other",
                                                    ])}
                                                />
                                                {display_project_installed_other && (
                                                    <Subquestion
                                                        component={
                                                            <TextField
                                                                disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                                field="project_installed_other"
                                                                label="What is the other property location?"
                                                                fullWidth
                                                            />
                                                        }
                                                    />
                                                )}
                                                <CheckboxGroup
                                                    field="how_heard"
                                                    label="How did you hear about CRWD's Clean Water Project Grant Program? (Check all that apply)"
                                                    required
                                                    row={true}
                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                    eventHandle={(e) => {
                                                        if (e.includes("Other")) {
                                                            this.setState({ display_how_heard_other: true });
                                                        } else {
                                                            this.setState({ display_how_heard_other: false });
                                                        }
                                                    }}
                                                    options={MAKE_OPTIONS([
                                                        "CRWD Website",
                                                        "CRWD Signage",
                                                        "Event",
                                                        "CRWD Presentation",
                                                        "Social Media",
                                                        "Newspaper/Newsletter",
                                                        "District Council",
                                                        "Minnesota Water Steward",
                                                        "Past Grantee/Project",
                                                        "Other",
                                                    ])}
                                                />
                                                {display_how_heard_other && (
                                                    <Subquestion
                                                        component={
                                                            <TextField
                                                                disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                                field="how_heard_other"
                                                                label="What is the other method?"
                                                                fullWidth
                                                            />
                                                        }
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>   
                                        <Typography variant="subtitle1" gutterBottom style={{ marginBottom: 10 }}>
                                            <b>Site Visit Attendance</b> (*required)
                                        </Typography>

                                        <Grid item xs={7}>
                                            <RadioGroup
                                                field="onsite_visit"
                                                options={MAKE_OPTIONS(["I do not need to be onsite for the site visit", "I would like to be onsite for the site visit"])}
                                                alignment={true}
                                                disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                required
                                                label="Please select one of the following to indicate your attendance for the site visit. If you elect to not be onsite for the site visit,
                                                 you are giving CRWD permission to access your property to conduct the site visit without your presence. CRWD will give advanced notice of a site visit. "
                                            />
                                        </Grid>
                                        <br/>
                                        <Typography variant="subtitle1" gutterBottom>
                                            <b>Demographic Information</b>
                                        </Typography>
                                        <Typography gutterBottom>
                                            Your answers below will not be shared outside our office. They will only be used to improve the Clean
                                            Water Project Grant Programs and outreach efforts.
                                        </Typography>
                                        <Grid container spacing={1} style={{ marginBottom: 16 }}>
                                            <Grid item xs={12}>
                                                <CheckboxGroup
                                                    field="age"
                                                    label="What is your age? (Limit to one answer)"
                                                    row={true}
                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                    eventHandle={this.maxChecked}
                                                    options={MAKE_OPTIONS([
                                                        "24 years or less",
                                                        "25-34 years",
                                                        "35-44 years",
                                                        "45-54 years",
                                                        "55-64 years",
                                                        "65-74 years",
                                                        "75 years or above",
                                                    ])}
                                                />
                                                <CheckboxGroup
                                                    field="education"
                                                    label="What is your highest completed level of education? Or, if currently enrolled, indicate current enrollment degree. (Limit to one answer)"
                                                    row={true}
                                                    eventHandle={this.maxChecked}
                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                    options={MAKE_OPTIONS([
                                                        "Some high school, no diploma",
                                                        "High school diploma, or equivalent",
                                                        "Some college, no degree",
                                                        "Trade/technical/vocational training",
                                                        "Associate degree",
                                                        "Bachelor's degree",
                                                        "Advanced education beyond Bachelor's",
                                                    ])}
                                                />
                                                <CheckboxGroup
                                                    field="race"
                                                    label="Check all categories that describe your race and/or ethnicity (Check all that apply)"
                                                    row={true}
                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                    eventHandle={(e) => {
                                                        if (e.includes("Other")) {
                                                            this.setState({ display_other_race: true });
                                                        } else {
                                                            this.setState({ display_other_race: false });
                                                        }
                                                    }}
                                                    options={MAKE_OPTIONS([
                                                        "White or Caucasian",
                                                        "Black or African American",
                                                        "Hispanic or Latino",
                                                        "Asian or Asian American",
                                                        "American Indian or Alaska Native",
                                                        "Middle Eastern or North African",
                                                        "Native Hawaiian or other Pacific Islander",
                                                        "Other",
                                                    ])}
                                                />
                                                {display_other_race && (
                                                    <Subquestion
                                                        component={
                                                            <TextField
                                                                label="What other race, ethnicity, and/or origin?"
                                                                field="other_race"
                                                                disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                                fullWidth
                                                            />
                                                        }
                                                    />
                                                )}
                                                <CheckboxGroup
                                                    field="income"
                                                    label="Before taxes, what is your annual household income? (Limit to one answer)"
                                                    row={true}
                                                    eventHandle={this.maxChecked}
                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                    options={MAKE_OPTIONS([
                                                        "Less than $25,000",
                                                        "$25,000 to $34,999",
                                                        "$35,000 to $49,999",
                                                        "$50,000 to $74,999",
                                                        "$75,000 to $99,999",
                                                        "$100,000 to $124,999",
                                                        "$125,000 or more",
                                                    ])}
                                                />
                                                <Typography variant="subtitle1">
                                                    <b>Attachment (if desired)</b>
                                                </Typography>
                                                <Button
                                                    style={{ marginBottom: 5, marginTop: 5 }}
                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                    onClick={() =>
                                                        this.setState({
                                                            attachmentDialogOpen: true,
                                                            attachmentEditing: null,
                                                            attachmentFormKey: window.performance.now(),
                                                            attachmentType: "Initial Inquiry",
                                                        })
                                                    }
                                                    variant="contained"
                                                    color="primary">
                                                    Add Attachments
                                                </Button>
                                                <Table style={{ marginTop: 8, marginBottom: 16 }}>
                                                    <EnhancedTableHead
                                                        columnData={columnDataAttachments}
                                                        order={attachmentOrder}
                                                        orderBy={attachmentOrderBy}
                                                        onRequestSort={this.handleRequestSort2}
                                                    />
                                                    <TableBody>
                                                        {temp_attachments
                                                            .filter((n) => n._type === "Initial Inquiry")
                                                            .map((n) => {
                                                                var type = n.file.slice(n.file.indexOf("/") + 1, n.file.indexOf(";")); //allow for opening without saving but downloads
                                                                return (
                                                                    <TableRow hover>
                                                                        <CustomTableCell
                                                                            className={classes.nowrap}
                                                                            style={{ display: "inline-flex" }}>
                                                                            {n.file && n.file.includes("https") && (
                                                                                <Tooltip title="Open Link">
                                                                                    <Button
                                                                                        color="primary"
                                                                                        onClick={() => {
                                                                                            window.open(n.file);
                                                                                            // return <a href={n.file}>{n.name}</a>;
                                                                                        }}
                                                                                        className={classes.deleteWidth}>
                                                                                        <ViewIcon color="primary" />
                                                                                    </Button>
                                                                                </Tooltip>
                                                                            )}

                                                                            <Tooltip title="Delete">
                                                                                <Button
                                                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                                                    color="primary"
                                                                                    onClick={() => {
                                                                                        // ormCrwdStewardshipGrantAttachmentDelete(n.id); we dont save on step 1 until they submit as we keep in array
                                                                                        this.setState({
                                                                                            temp_attachments: temp_attachments.filter(
                                                                                                (tc) => tc !== n
                                                                                            ),
                                                                                        });
                                                                                    }}
                                                                                    className={classes.deleteWidth}>
                                                                                    <DeleteIcon color="primary" />
                                                                                </Button>
                                                                            </Tooltip>
                                                                        </CustomTableCell>
                                                                        <CustomTableCell>
                                                                            <a href={n.file} download={`${n.name}.${type}`}>
                                                                                {n.name}
                                                                            </a>
                                                                        </CustomTableCell>
                                                                        <CustomTableCell>{n.notes}</CustomTableCell>
                                                                    </TableRow>
                                                                );
                                                            })}
                                                        {temp_attachments.filter((n) => n._type === "Initial Inquiry").length < 1 && (
                                                            <TableRow>
                                                                <CustomTableCell
                                                                    colSpan={columnDataAttachments.length}
                                                                    className={classes.centerAlign}>
                                                                    No Attachment Documents Added
                                                                </CustomTableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                                <Typography>
                                                    If you are interested in receiving CRWD updates, sign up for{" "}
                                                    <a
                                                        href="https://mailchi.mp/2abc5ba74ff8/capitolregionwd"
                                                        target="_blank"
                                                        rel="noopener noreferrer">
                                                        CRWD's newsletter
                                                    </a>
                                                    .
                                                </Typography>
                                                <Checkbox
                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                    label="I understand that by submitting this form, I give CRWD permission to access the property to conduct a site visit for the purposes of this grant program. *"
                                                    field="property_understand"
                                                    required
                                                /> 
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                {activeStep === 1 && activeStep !== "PRINT" && (
                                    <Grid item xs={12} xl={10} className={classes.rightAlign} classes={{ root: classes.root }}>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={() => {
                                                this.setState({ activeStep: activeStep - 1 });
                                                window.scrollTo(0, 0);
                                            }}
                                            className={classes.button}>
                                            Back
                                        </Button>

                                        <Button
                                            disabled={is_read_only.find((r) => (r === 2 ? true : false))}
                                            onClick={() => {
                                                this.handleNext(activeStep + 1);
                                                if (is_read_only.length === 0 || is_read_only.find((r) => (r === 2 ? true : false)))
                                                    this.formApi.submitForm(activeStep);
                                            }}
                                            variant="contained"
                                            color="primary"
                                            style={{ marginLeft: 16 }}>
                                            {is_read_only.length === 0 || is_read_only.find((r) => (r === 2 ? true : false))
                                                ? "Submit Inquiry"
                                                : "Next"}
                                        </Button>
                                    </Grid>
                                )}
                                {activeStep === "PRINT" && (
                                    <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                        <Divider className={classes.printDivider} />
                                    </Grid>
                                )}
                                {(activeStep === 2 || activeStep === "PRINT") && (
                                    <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                        <Typography gutterBottom>
                                            Please review the Clean Water Proposal (CWP) and the Guidelines and Policies document, and either proceed
                                            to application or decline to proceed.
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            <b>CWP Review</b>
                                        </Typography>{" "}
                                        <Typography gutterBottom>
                                            The CWP provides you with a project cost estimate and a grant award estimate for the clean water
                                            project(s) discussed at your site visit.
                                        </Typography>
                                        {whatthef && whatthef.cwp && (
                                            <a href={`${whatthef.cwp.agreement}`} target="_blank" rel="noopener noreferrer">
                                                Clean Water Proposal (CWP) {/*n.name*/}
                                            </a>
                                        )}
                                        <Typography variant="subtitle1" style={{ marginTop: 16 }}>
                                            <b>Guidelines and Policies Review</b>
                                        </Typography>
                                        <Typography>
                                            The Guidelines and Policies provide you with information on program qualifications, cost-share
                                            requirements, general step-by-step procedure, grantee responsibilities, estimated project timeline and
                                            maintenance requirements.
                                        </Typography>
                                        <Grid container spacing={1} style={{ marginBottom: 16 }}>
                                            <Grid item xs={6}>
                                                <Typography>
                                                    {whatthef && (whatthef.curbcut === 'No' || !whatthef.curbcut) && (
                                                        <a
                                                        href="https://www.capitolregionwd.org/wp-content/uploads/2024/04/Clean-Water-Project-Grant-Guidelines-and-Policies.pdf"
                                                        target="_blank"
                                                        rel="noopener noreferrer">
                                                        Guidelines and Policies Document
                                                        </a>
                                                    )}
                                                    {whatthef && whatthef.curbcut === 'Yes' && (
                                                        <a
                                                        href="https://www.capitolregionwd.org/wp-content/uploads/2024/04/Curb-Cut-Rain-Gardens-Overview.pdf"
                                                        target="_blank"
                                                        rel="noopener noreferrer">
                                                        Guidelines and Policies Document
                                                        </a>
                                                    )}
                                                 
                                                </Typography>

                                                <br />
                                                {designercontact !== "" && (
                                                    <Typography>
                                                        Email {whatthef.contacts.designercontact} at{" "}
                                                        {whatthef.contacts.designercontact !== "Other" ? (
                                                            <a href="mailto: joseph.lochner@co.ramsey.mn.us">joseph.lochner@co.ramsey.mn.us</a>
                                                        ) : (
                                                            ""
                                                        )}
                                                        &nbsp;if you would like to request edits.
                                                    </Typography>
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <RadioGroup
                                                    field="review_decision"
                                                    options={MAKE_OPTIONS(["Proceed to Application", "Decline to Proceed"])}
                                                    alignment={true}
                                                    disabled={is_read_only.find((r) => (r === 2 ? true : false))}
                                                    required
                                                    eventHandle={(e) => {
                                                        if (e.includes("Decline to Proceed")) {
                                                            this.setState({ display_review_Decision_decline: true });
                                                            this.setState({ display_review_Decision_proceed: false });
                                                            this.formApi.setValue("understand", "");
                                                        } else {
                                                            this.setState({ display_review_Decision_decline: false });
                                                            this.setState({ display_review_Decision_proceed: true });
                                                            this.formApi.setValue("review_Decision_decline", "");
                                                        }
                                                    }}
                                                    label={<b>Review Decision</b>}
                                                />
                                                {display_review_Decision_decline && (
                                                    <Subquestion
                                                        component={
                                                            <TextField
                                                                disabled={is_read_only.find((r) => (r === 2 ? true : false))}
                                                                field="review_Decision_decline"
                                                                label="Reason for Declining"
                                                                fullWidth
                                                            />
                                                        }
                                                    />
                                                )}
                                                {display_review_Decision_proceed && (
                                                    <Subquestion
                                                        component={
                                                            <Checkbox
                                                                disabled={is_read_only.find((r) => (r === 2 ? true : false))}
                                                                label="I understand the Clean Water Proposal is a conceptual document and I have not been awarded a grant yet. *"
                                                                field="understand"
                                                                required
                                                            />
                                                        }
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                {activeStep === 2 && activeStep !== "PRINT" && (
                                    <Grid item xs={12} xl={10} className={classes.rightAlign} classes={{ root: classes.root }}>
                                        <Button
                                            disabled={is_read_only.find((r) => (r === 2 ? true : false))}
                                            onClick={() => {
                                                is_read_only.find((r) => (r === 2 ? true : false)) ? this.handleNext() : this.formApi.submitForm();
                                            }}
                                            variant="contained"
                                            color="primary">
                                            Submit
                                        </Button>
                                    </Grid>
                                )}
                                {activeStep === "PRINT" && (
                                    <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                        <Divider className={classes.printDivider} />
                                    </Grid>
                                )}
                                {(activeStep === 3 || activeStep === "PRINT") && (
                                    <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                        <Typography variant="subtitle1">
                                            <b>Grant Application</b>
                                        </Typography>
                                        <Typography gutterBottom>
                                            Please use the information on your Clean Water Proposal (CWP) to answer the following application
                                            questions. You have the option to hire your own landscape designer, complete your own designs, or work
                                            with {designercompany} to complete designs at no additional cost.
                                        </Typography>
                                        <Grid container spacing={1} style={{ marginBottom: 16 }}>
                                            <Grid item xs={12}>
                                                <RadioGroup
                                                    field="design_services"
                                                    options={MAKE_OPTIONS(["Yes", "No"])}
                                                    alignment={true}
                                                    disabled={is_read_only.find((r) => (r === 3 ? true : false))}
                                                    required
                                                    label="Are you requesting design services through CRWD?"
                                                />
                                                <TextField
                                                    style={{ marginTop: 24 }}
                                                    label={
                                                        <span style={{ marginBottom: 10 }}>
                                                            List the BMP IDs from your CWP for which you are requesting grant funding.
                                                            <br />
                                                            <i>
                                                                Minimum grant award is $500. Projects may be combined to meet this minimum
                                                                requirement.
                                                            </i>
                                                        </span>
                                                    }
                                                    useCustom2
                                                    rows={4}
                                                    field="bmp_ids"
                                                    disabled={is_read_only.find((r) => (r === 3 ? true : false))}
                                                    multiline
                                                    required
                                                    fullWidth
                                                />
                                                <Checkbox
                                                    disabled={is_read_only.find((r) => (r === 3 ? true : false))}
                                                    label="I understand this is an application for the Clean Water Project Grant Program and I have not been awarded a grant yet. *"
                                                    field="agree_1"
                                                    required
                                                />
                                                <Checkbox
                                                    disabled={is_read_only.find((r) => (r === 3 ? true : false))}
                                                    label="I have received and read the Guidelines and Policies document(s). *"
                                                    field="agree_2"
                                                    required
                                                />
                                                <Checkbox
                                                    disabled={is_read_only.find((r) => (r === 3 ? true : false))}
                                                    label="I understand the Clean Water Proposal is not a construction document and any construction completed or supplies purchased before a grant agreement is signed do not qualify for grant funding. *"
                                                    field="agree_3"
                                                    required
                                                />
                                                <Checkbox
                                                    disabled={is_read_only.find((r) => (r === 3 ? true : false))}
                                                    label="I understand grant funding will be reimbursed after a project is completed and passed a final inspection by CRWD. *"
                                                    field="agree_4"
                                                    required
                                                />
                                                <Checkbox
                                                    disabled={is_read_only.find((r) => (r === 3 ? true : false))}
                                                    label="I understand there is a minimum 10-year operation and maintenance (O&M) requirement for Clean Water Project Grant Projects, and an O&M plan will be provided with the grant agreement. *"
                                                    field="agree_5"
                                                    required
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                {activeStep === 3 && activeStep !== "PRINT" && (
                                    <Grid item xs={12} xl={10} className={classes.rightAlign} classes={{ root: classes.root }}>
                                        <Button
                                            onClick={() => {
                                                this.setState({ activeStep: activeStep - 1 });
                                                window.scrollTo(0, 0);
                                            }}
                                            className={classes.button}>
                                            Back
                                        </Button>
                                        <Button
                                            disabled={is_read_only.find((r) => (r === 3 ? true : false))}
                                            onClick={() => {
                                                is_read_only.find((r) => (r === 3 ? true : false)) ? this.handleNext() : this.formApi.submitForm();
                                            }}
                                            variant="contained"
                                            color="primary"
                                            style={{ marginLeft: 16 }}>
                                            {is_read_only.find((r) => (r === 3 ? true : false)) ? "Next" : "Submit Application"}
                                        </Button>
                                    </Grid>
                                )}
                                {activeStep === "PRINT" && (
                                    <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                        <Divider className={classes.printDivider} />
                                    </Grid>
                                )}
                                {(activeStep === 4 || activeStep === "PRINT") && (
                                    <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                        <Typography variant="subtitle1">
                                            <b>Applicant Designs Upload</b>
                                        </Typography>
                                        {designercontact !== "" && (
                                            <Typography gutterBottom>
                                                Upload construction designs not developed by CRWD. Designs must meet the CRWD standards for the Clean
                                                Water Project Grant Program. Review the CRWD Construction Plan Sample Designs document below for
                                                design examples that meet CRWD standards. Contact {whatthef.contacts.designercontact} at{" "}
                                                {whatthef.contacts.designercontact !== "Other" ? (
                                                    <a href="mailto: joseph.lochner@co.ramsey.mn.us">joseph.lochner@co.ramsey.mn.us</a>
                                                ) : (
                                                    ""
                                                )}{" "}
                                                for more information.
                                            </Typography>
                                        )}
                                        <Typography gutterBottom>
                                            <a href="/media/CRWD-Construction-Plan-Sample-Designs.pdf" rel="noopener noreferrer" target="_blank">
                                                CRWD Construction Plan Sample Designs
                                            </a>
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            <b>Design Upload *</b>
                                        </Typography>
                                        <Button
                                            style={{ marginBottom: 5, marginTop: 5 }}
                                            onClick={() =>
                                                this.setState({
                                                    attachmentDialogOpen: true,
                                                    attachmentEditing: null,
                                                    attachmentFormKey: window.performance.now(),
                                                    attachmentType: "Applicant Construction Designs",
                                                })
                                            }
                                            disabled={is_read_only.find((r) => (r === 4 ? true : false))}
                                            variant="contained"
                                            color="primary">
                                            Add Design Document
                                        </Button>
                                        <Table style={{ marginTop: 8, marginBottom: 16 }}>
                                            <EnhancedTableHead
                                                columnData={columnDataAttachments}
                                                order={attachmentOrder}
                                                orderBy={attachmentOrderBy}
                                                onRequestSort={this.handleRequestSort2}
                                            />
                                            <TableBody>
                                                {temp_attachments
                                                    .filter((n) => n._type === "Applicant Construction Designs")
                                                    .map((n) => {
                                                        let type = n.file.slice(n.file.indexOf("/") + 1, n.file.indexOf(";")); //allow for opening without saving but downloads
                                                        return (
                                                            <TableRow hover>
                                                                <CustomTableCell className={classes.nowrap} style={{ display: "inline-flex" }}>
                                                                    {n.file && n.file.includes("https") && (
                                                                        <Tooltip title="Open Link">
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={() => {
                                                                                    window.open(n.file);
                                                                                }}
                                                                                className={classes.deleteWidth}>
                                                                                <ViewIcon color="primary" />
                                                                            </Button>
                                                                        </Tooltip>
                                                                    )}
                                                                    <Tooltip title="Delete">
                                                                        <Button
                                                                            disabled={is_read_only.find((r) => (r === 4 ? true : false))}
                                                                            color="primary"
                                                                            onClick={() => {
                                                                                //only delete from db if its already been saved
                                                                                if (n.file && n.file.includes("https"))
                                                                                    ormCrwdStewardshipGrantAttachmentDelete(n.id);
                                                                                this.setState({
                                                                                    temp_attachments: temp_attachments.filter((tc) => tc !== n),
                                                                                });
                                                                            }}
                                                                            className={classes.deleteWidth}>
                                                                            <DeleteIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </CustomTableCell>
                                                                <CustomTableCell>
                                                                    <a href={n.file} download={`${n.name}.${type}`}>
                                                                        {n.name}
                                                                    </a>
                                                                </CustomTableCell>
                                                                <CustomTableCell>{n.notes}</CustomTableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                {temp_attachments.filter((n) => n._type === "Applicant Construction Designs").length < 1 && (
                                                    <TableRow>
                                                        <CustomTableCell colSpan={columnDataAttachments.length} className={classes.centerAlign}>
                                                            No Design Documents Added
                                                        </CustomTableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <RadioGroup
                                                    field="designer_plans"
                                                    options={MAKE_OPTIONS(["Self-designed", "Hired Designer"])}
                                                    alignment={true}
                                                    disabled={is_read_only.find((r) => (r === 4 ? true : false))}
                                                    required
                                                    eventHandle={(e) => {
                                                        if (e.includes("Hired Designer")) {
                                                            this.setState({ display_hired: true });
                                                        } else {
                                                            this.setState({ display_hired: false });
                                                            this.formApi.setValue("hired_company", null);
                                                            this.formApi.setValue("hired_first", null);
                                                            this.formApi.setValue("hired_last", null);
                                                            this.formApi.setValue("hired_phone", null);
                                                            this.formApi.setValue("hired_email", null);
                                                        }
                                                    }}
                                                    label={<b>Project Designer</b>}
                                                />
                                                {display_hired && (
                                                    <Subquestion
                                                        component={
                                                            <>
                                                                <TextField
                                                                    disabled={is_read_only.find((r) => (r === 4 ? true : false))}
                                                                    field="hired_company"
                                                                    label="Company"
                                                                    fullWidth
                                                                    required
                                                                />
                                                                <TextField
                                                                    disabled={is_read_only.find((r) => (r === 4 ? true : false))}
                                                                    field="hired_first"
                                                                    label="Contact First Name"
                                                                    fullWidth
                                                                    required
                                                                />
                                                                <TextField
                                                                    disabled={is_read_only.find((r) => (r === 4 ? true : false))}
                                                                    field="hired_last"
                                                                    label="Contact Last Name"
                                                                    fullWidth
                                                                    required
                                                                />
                                                                <TextField
                                                                    disabled={is_read_only.find((r) => (r === 4 ? true : false))}
                                                                    field="hired_phone"
                                                                    label="Contact Phone Number"
                                                                    fullWidth
                                                                    required
                                                                    usePhoneNumberFormat
                                                                />
                                                                <TextField
                                                                    disabled={is_read_only.find((r) => (r === 4 ? true : false))}
                                                                    field="hired_email"
                                                                    label="Contact Email"
                                                                    fullWidth
                                                                    required
                                                                />
                                                            </>
                                                        }
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                {activeStep === 4 && activeStep !== "PRINT" && (
                                    <Grid item xs={12} xl={10} className={classes.rightAlign} classes={{ root: classes.root }}>
                                        <Button
                                            disabled={is_read_only.find((r) => (r === 4 ? true : false))}
                                            onClick={() => {
                                                //set state synchronous to error chk properly
                                                this.setState(
                                                    { activeStep: 5 },
                                                    () => {
                                                        this.formApi.submitForm();
                                                    },
                                                    2000
                                                );
                                            }}
                                            variant="contained"
                                            color="primary">
                                            Submit Designs
                                        </Button>
                                    </Grid>
                                )}
                                {activeStep === "PRINT" && (
                                    <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                        <Divider className={classes.printDivider} />
                                    </Grid>
                                )}
                                {(activeStep === 5 || activeStep === "PRINT") && (
                                    <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                        <Typography variant="subtitle1">
                                            <b>CRWD Designs</b>
                                        </Typography>{" "}
                                        {whatthef && whatthef.contacts && (
                                            <Typography gutterBottom>
                                                Review construction designs developed for you by CRWD and either request changes or accept designs. Some projects may also receive a construction plan cost estimate 
                                                and materials estimate. If applicable, the cost estimate and materials estimate are available for review below. 
                                                Please do not share the cost estimate with contractors. Email {whatthef.contacts.designercontact} at{" "}
                                                {whatthef.contacts.designercontact !== "Other" ? (
                                                    <a href="mailto: joseph.lochner@co.ramsey.mn.us">joseph.lochner@co.ramsey.mn.us</a>
                                                ) : (
                                                    ""
                                                )}{" "}
                                                with questions.
                                            </Typography>
                                        )}
                                        <Typography variant="subtitle1">
                                            <b>Design Review</b>
                                        </Typography>
                                        {whatthef && whatthef.const_designs && (
                                            <div style={{ marginBottom: 5 }}>
                                                <a href={`${whatthef.const_designs.const_designs}`} target="_blank" rel="noopener noreferrer">
                                                    CRWD Construction Designs {/*n.name*/}
                                                </a>
                                            </div>
                                        )}
                                        {whatthef && whatthef.cost_est && (
                                            <div style={{ marginBottom: 5 }}>
                                                <a href={`${whatthef.cost_est.cost_est}`} target="_blank" rel="noopener noreferrer">
                                                    Cost Estimate {/*n.name*/}
                                                </a>
                                            </div>
                                        )}
                                        {whatthef && whatthef.mat_est && (
                                            <div style={{ marginBottom: 5 }}>
                                                <a href={`${whatthef.mat_est.mat_est}`} target="_blank" rel="noopener noreferrer">
                                                    Materials Estimate{/*n.name*/}
                                                </a>
                                            </div>
                                        )}
                                        <Grid container spacing={1} style={{ marginTop: 8 }}>
                                            <Grid item xs={6}>
                                                <RadioGroup
                                                    field="crwd_plans"
                                                    options={MAKE_OPTIONS(["Designs Accepted", "Edits Requested"])}
                                                    alignment={true}
                                                    disabled={is_read_only.find((r) => (r === 5 ? true : false))}
                                                    required
                                                    eventHandle={(e) => {
                                                        if (e.includes("Edits Requested")) {
                                                            this.setState({ display_edits: true });
                                                        } else {
                                                            this.setState({ display_edits: false });
                                                            this.formApi.setValue("edits_requested", null);
                                                            this.formApi.setValue("crwd_edits", null);
                                                        }
                                                    }}
                                                    label={<b>Review Decision</b>}
                                                />
                                                {display_edits && (
                                                    <Subquestion
                                                        component={
                                                            <>
                                                                <TextField
                                                                    disabled={is_read_only.find((r) => (r === 5 ? true : false))}
                                                                    field="edits_requested"
                                                                    label="Edits Requested"
                                                                    fullWidth
                                                                    required
                                                                    multiline
                                                                    rows={4}
                                                                />
                                                                <FileInput field="crwd_edits" id="crwd_edits" fullWidth label="Upload File" />
                                                            </>
                                                        }
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                {activeStep === 5 && activeStep !== "PRINT" && (
                                    <Grid item xs={12} xl={10} className={classes.rightAlign} classes={{ root: classes.root }}>
                                        <Button
                                            disabled={is_read_only.find((r) => (r === 5 ? true : false))}
                                            onClick={() =>
                                                //set state synchronous to error chk properly
                                                this.setState(
                                                    { activeStep: 6 },
                                                    () => {
                                                        this.formApi.submitForm();
                                                    },
                                                    2000
                                                )
                                            }
                                            variant="contained"
                                            color="primary">
                                            Submit Designs
                                        </Button>
                                    </Grid>
                                )}
                                {activeStep === "PRINT" && (
                                    <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                        <Divider className={classes.printDivider} />
                                    </Grid>
                                )}
                                {(activeStep === 6 || activeStep === "PRINT") && (
                                    <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                        <Typography variant="subtitle1">
                                            <b>Fully Executed Grant Agreement</b>
                                        </Typography>

                                        <Grid container spacing={1} style={{ marginTop: 8 }}>
                                            {whatthef && whatthef.agreement && (
                                                <a href={`${whatthef.agreement.agreement}`} target="_blank" rel="noopener noreferrer">
                                                    Clean Water Project Grant Agreement{/*n.name*/}
                                                </a>
                                            )}
                                            <Grid container spacing={1} style={{ marginTop: 8 }}>
                                                <Grid item xs={6}>
                                                    <RadioGroup
                                                        field="project_installer"
                                                        options={MAKE_OPTIONS(["Self-installed", "Hired Installer"])}
                                                        alignment={true}
                                                        disabled={is_read_only.find((r) => (r === 6 ? true : false))}
                                                        required
                                                        eventHandle={(e) => {
                                                            if (e.includes("Hired Installer")) {
                                                                this.setState({ display_installer: true });
                                                            } else {
                                                                this.setState({ display_installer: false });
                                                                this.formApi.setValue("installer_company", null);
                                                                this.formApi.setValue("installer_first", null);
                                                                this.formApi.setValue("installer_last", null);
                                                                this.formApi.setValue("installer_phone", null);
                                                                this.formApi.setValue("installer_email", null);
                                                            }
                                                        }}
                                                        label={<b>Project Installer</b>}
                                                    />
                                                    {display_installer && (
                                                        <Subquestion
                                                            component={
                                                                <>
                                                                    <TextField
                                                                        disabled={is_read_only.find((r) => (r === 6 ? true : false))}
                                                                        field="installer_company"
                                                                        label="Company"
                                                                        fullWidth
                                                                        required
                                                                    />
                                                                    <TextField
                                                                        disabled={is_read_only.find((r) => (r === 6 ? true : false))}
                                                                        field="installer_first"
                                                                        label="Contact First Name"
                                                                        fullWidth
                                                                        required
                                                                    />
                                                                    <TextField
                                                                        disabled={is_read_only.find((r) => (r === 6 ? true : false))}
                                                                        field="installer_last"
                                                                        label="Contact Last Name"
                                                                        fullWidth
                                                                        required
                                                                    />
                                                                    <TextField
                                                                        disabled={is_read_only.find((r) => (r === 6 ? true : false))}
                                                                        field="installer_phone"
                                                                        label="Contact Phone Number"
                                                                        fullWidth
                                                                        required
                                                                        usePhoneNumberFormat
                                                                    />
                                                                    <TextField
                                                                        disabled={is_read_only.find((r) => (r === 6 ? true : false))}
                                                                        field="installer_email"
                                                                        label="Contact Email"
                                                                        fullWidth
                                                                        required
                                                                    />
                                                                </>
                                                            }
                                                        />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                {activeStep === 6 && activeStep !== "PRINT" && (
                                    <Grid item xs={12} xl={10} className={classes.rightAlign} classes={{ root: classes.root }}>
                                        <Button
                                            disabled={is_read_only.find((r) => (r === 6 ? true : false))}
                                            onClick={() =>
                                                //set state synchronous to error chk properly
                                                this.setState({ activeStep: 7 }, () => {
                                                    this.formApi.submitForm();
                                                })
                                            }
                                            variant="contained"
                                            color="primary">
                                            Submit Installer Information
                                        </Button>
                                    </Grid>
                                )}
                                {activeStep === "PRINT" && (
                                    <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                        <Divider className={classes.printDivider} />
                                    </Grid>
                                )}

                                {(activeStep === 7 || activeStep === "PRINT") && (
                                    <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                        <Typography variant="subtitle1">
                                            <b>Reimbursement Request</b>
                                        </Typography>
                                        {staffcontactemail !== "" && (
                                            <Typography gutterBottom>
                                                Download and complete the reimbursement form below. Attach the completed reimbursement form and
                                                itemized receipts/invoices that show proof of payment to begin the reimbursement process. You may also
                                                attach photos. If you have questions, contact {whatthef.contacts.staffcontact} at{" "}
                                                <a href={`mailto: ${staffcontactemail}`}>{staffcontactemail}</a>.
                                            </Typography>
                                        )}
                                        <Typography gutterBottom>
                                            <a href="/media/04_Stewardship Grant Reimbursement.pdf" target="_blank" rel="noopener noreferrer">
                                                Reimbursement Form
                                            </a>
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            <b>Upload Reimbursement Materials *</b>
                                        </Typography>
                                        <Button
                                            style={{ marginBottom: 5, marginTop: 5 }}
                                            onClick={() =>
                                                this.setState({
                                                    attachmentDialogOpen: true,
                                                    attachmentEditing: null,
                                                    attachmentFormKey: window.performance.now(),
                                                    attachmentType: "Reimbursement Material",
                                                })
                                            }
                                            disabled={is_read_only.find((r) => (r === 7 ? true : false))}
                                            variant="contained"
                                            color="primary">
                                            Add Materials
                                        </Button>
                                        <Table style={{ marginTop: 8, marginBottom: 16 }}>
                                            <EnhancedTableHead
                                                columnData={columnDataAttachments}
                                                order={attachmentOrder}
                                                orderBy={attachmentOrderBy}
                                                onRequestSort={this.handleRequestSort2}
                                            />

                                            <TableBody>
                                                {temp_attachments
                                                    .filter((n) => n._type === "Reimbursement Material")
                                                    .map((n) => {
                                                        let type = n.file.slice(n.file.indexOf("/") + 1, n.file.indexOf(";")); //allow for opening without saving but downloads
                                                        return (
                                                            <TableRow hover>
                                                                <CustomTableCell className={classes.nowrap} style={{ display: "inline-flex" }}>
                                                                    {n.file && n.file.includes("https") && (
                                                                        <Tooltip title="Open Link">
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={() => {
                                                                                    window.open(n.file);
                                                                                }}
                                                                                className={classes.deleteWidth}>
                                                                                <ViewIcon color="primary" />
                                                                            </Button>
                                                                        </Tooltip>
                                                                    )}
                                                                    <Tooltip title="Delete">
                                                                        <Button
                                                                            disabled={is_read_only.find((r) => (r === 7 ? true : false))}
                                                                            color="primary"
                                                                            onClick={() => {
                                                                                if (n.file && n.file.includes("https"))
                                                                                    ormCrwdStewardshipGrantAttachmentDelete(n.id);
                                                                                this.setState({
                                                                                    temp_attachments: temp_attachments.filter((tc) => tc !== n),
                                                                                });
                                                                            }}
                                                                            className={classes.deleteWidth}>
                                                                            <DeleteIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </CustomTableCell>
                                                                <CustomTableCell>
                                                                    <a href={n.file} download={`${n.name}.${type}`}>
                                                                        {n.name}
                                                                    </a>
                                                                </CustomTableCell>
                                                                <CustomTableCell>{n.notes}</CustomTableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                {temp_attachments.filter((n) => n._type === "Reimbursement Material").length < 1 && (
                                                    <TableRow>
                                                        <CustomTableCell colSpan={columnDataAttachments.length} className={classes.centerAlign}>
                                                            No Reimbursement Materials Added
                                                        </CustomTableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                        <Typography variant="subtitle1">
                                            <b>Clean Water Project Grant Feedback</b>
                                        </Typography>
                                        <Typography gutterBottom>
                                            Please answer the following survey questions and leave additional feedback in the Comments section
                                            including how you think the Clean Water Project Grant Program can be improved.
                                        </Typography>
                                        <Grid container spacing={1} style={{ marginBottom: 16 }}>
                                            <Grid item xs={7}>
                                                <RadioGroup
                                                    field="satisfied_overall"
                                                    options={MAKE_OPTIONS(["Very satisfied", "Satisfied", "Neutral", "Not satisfied"])}
                                                    alignment={true}
                                                    disabled={is_read_only.find((r) => (r === 7 ? true : false))}
                                                    required
                                                    label="How satisfied were you with the overall Clean Water Project Grant Program?"
                                                />
                                            </Grid>
                                            <Grid item xs={7}>
                                                <RadioGroup
                                                    field="satisfied_agreement"
                                                    options={MAKE_OPTIONS(["Very satisfied", "Satisfied", "Neutral", "Not satisfied"])}
                                                    alignment={true}
                                                    disabled={is_read_only.find((r) => (r === 7 ? true : false))}
                                                    required
                                                    label="How satisfied were you with the Clean Water Project Grant Agreement process?"
                                                />
                                            </Grid>
                                            <Grid item xs={7}>
                                                <RadioGroup
                                                    field="satisfied_design"
                                                    options={MAKE_OPTIONS(["Very satisfied", "Satisfied", "Neutral", "Not satisfied", "N/A"])}
                                                    alignment={true}
                                                    disabled={is_read_only.find((r) => (r === 7 ? true : false))}
                                                    required
                                                    label="How satisfied were you with the design process?"
                                                />
                                            </Grid>
                                            <Grid item xs={7}>
                                                <RadioGroup
                                                    field="satisfied_communication"
                                                    options={MAKE_OPTIONS(["Very satisfied", "Satisfied", "Neutral", "Not satisfied"])}
                                                    alignment={true}
                                                    disabled={is_read_only.find((r) => (r === 7 ? true : false))}
                                                    required
                                                    label="How satisfied were you with CRWD's communication throughout the grant process?"
                                                />
                                            </Grid>
                                            <Grid item xs={7}>
                                                <RadioGroup
                                                    field="likely_recommend"
                                                    options={MAKE_OPTIONS(["Very likely", "Likely", "Neutral", "Unlikely"])}
                                                    alignment={true}
                                                    disabled={is_read_only.find((r) => (r === 7 ? true : false))}
                                                    required
                                                    label="How likely are you to recommend the Clean Water Project Grant Program to others?"
                                                />
                                            </Grid>
                                            <Grid item xs={7}>
                                                <TextField
                                                    label="Comments"
                                                    field="satisfied_comments"
                                                    disabled={is_read_only.find((r) => (r === 7 ? true : false))}
                                                    fullWidth
                                                    rows={4}
                                                    multiline
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                {activeStep === 7 && activeStep !== "PRINT" && (
                                    <Grid item xs={12} xl={10} className={classes.rightAlign} classes={{ root: classes.root }}>
                                        <Button
                                            disabled={is_read_only.find((r) => (r === 7 ? true : false))}
                                            onClick={() =>
                                                //set state synchronous to error chk properly
                                                this.setState({ activeStep: 8 }, () => {
                                                    this.formApi.submitForm();
                                                })
                                            }
                                            variant="contained"
                                            color="primary">
                                            Submit Reimbursement Request
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </form>
                    )}
                </Form>
                <Dialog open={attachmentDialogOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar style={{ backgroundColor: "lightgray" }}>
                        <Typography variant="h6" className={classes.flex}>
                            Add {attachmentType}
                        </Typography>
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({ attachmentDialogOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <DialogContentText>
                            <Form
                                dontValidateOnMount={true}
                                // validateOnSubmit={true}
                                key={attachmentFormKey}
                                defaultValues={attachmentEditing}
                                // validateError={this.errorValidatorAttachment}
                                onSubmit={(values) => this.addAttachment(values)}>
                                {(formApi) => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <FileInput field="file" id="file_upl" fullWidth label="File *" />
                                                {attachmentType !== "Applicant Construction Designs" &&
                                                    attachmentType !== "Reimbursement Material" && (
                                                        <TextField field="name" label="Name" required fullWidth style={{ marginTop: 24 }} />
                                                    )}
                                                <TextField field="notes" label="Notes" fullWidth style={{ marginTop: 20 }} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth onClick={() => this.setState({ attachmentDialogOpen: false })}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </AppContainerApplication>
        );
    }
}

CrwdStwdshipGrantComp = connect(
    (state, ownProps) => ({
        application: getApplication(state, ownProps),
    }),
    {
        ...ApplicationRecord.actions,
        ...ApplicationRecordStatus.actions,
        ...CrwdStewardshipGrant.actions,
        ...CrwdStewardshipGrantAttachment.actions,
        ...AttachmentSection.actions,
    }
)(CrwdStwdshipGrantComp);

export default withStyles(styles)(withRouter(CrwdStwdshipGrantComp));
