import React, { Component } from "react";

import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import WarningIcon from "@material-ui/icons/Warning";

import HelpDialog from "./HelpDialog";

const styles = (theme) => ({
    helpGrid: {
        width: "20px",
        opacity: ".4",
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.secondary.dark,
        },
    },
    helpInline: {
        width: "20px",
        marginLeft: theme.spacing(1),
        marginBottom: "-7px",
        opacity: ".4",
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.secondary.dark,
        },
    },
    warningGrid: {
        width: "35px",
        marginLeft: theme.spacing(1),
        marginBottom: "-7px",
        opacity: "1",
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.error.dark,
        },
        color: "#3d3d3d",
    },
    absolute: {
        height: theme.spacing(2),
    },
});

class HelpLabel extends Component {
    state = {
        helpOpen: false,
    };

    iconClick = (e) => {
        this.setState({ helpOpen: true });
        //FIXME: How to stop the input from being focused
        e.stopPropagation();
    };

    render() {
        const { helpText, classes, inputLabel, question, overrideStyling, warning, expansionPanel } = this.props;
        const { helpOpen } = this.state;

        //allow for two rows to be left aligned when used with checkbox
        if (overrideStyling) var styling = { display: "-webkit-box", marginLeft: 27, marginTop: -33, width: "86%" };
        else var defaultStyling = { display: "inline-block", marginTop: -40 };
        if (expansionPanel) {
            var st1 = { display: "inline-flex", maxWidth: "88%", overflow: "hidden", textOverFlow: "ellipse" };
            var st2 = { display: "inline-block", paddingLeft: 1 };
        }
        return (
            <>
                {!expansionPanel && (
                    <Typography style={overrideStyling ? styling : defaultStyling} /*className={!inputLabel ? classes.absolute : classes.inline}*/>
                        {inputLabel}

                        {!warning && (
                            <Tooltip title="Help">
                                <HelpIcon className={inputLabel ? classes.helpInline : classes.helpGrid} onClick={this.iconClick} />
                            </Tooltip>
                        )}

                        {warning && (
                            <Tooltip title="New Information">
                                <WarningIcon className={classes.warningGrid} onClick={((e) => e.stopPropagation(), this.iconClick)} />
                            </Tooltip>
                        )}

                        <HelpDialog
                            question={inputLabel && inputLabel !== true ? inputLabel : question}
                            text={helpText}
                            open={helpOpen}
                            onClose={(e) => {
                                return this.setState({ helpOpen: false });
                            }}
                        />
                    </Typography>
                )}
                {expansionPanel && ( //only for form fill out
                    <>
                        <Tooltip title={inputLabel}>
                            <Typography style={st1}>{inputLabel}</Typography>
                        </Tooltip>
                        <Typography style={st2}>
                            {!warning && (
                                <Tooltip title="Help">
                                    <HelpIcon className={inputLabel ? classes.helpInline : classes.helpGrid} onClick={this.iconClick} />
                                </Tooltip>
                            )}

                            {warning && (
                                <Tooltip title="New Information">
                                    <WarningIcon className={classes.warningGrid} onClick={((e) => e.stopPropagation(), this.iconClick)} />
                                </Tooltip>
                            )}

                            <HelpDialog
                                question={inputLabel && inputLabel !== true ? inputLabel : question}
                                text={helpText}
                                open={helpOpen}
                                onClose={(e) => {
                                    return this.setState({ helpOpen: false });
                                }}
                            />
                        </Typography>
                    </>
                )}
            </>
        );
    }
}
HelpLabel.displayName = "HelpLabel";
export default withStyles(styles)(HelpLabel);
