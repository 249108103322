import React, { Component } from "react";

import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { Button, withStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CustomTableCell from "./TableCell";
import Checkbox from "@material-ui/core/Checkbox"; 
import filter from "../common/icons/icons8-filter-64.png"
const styles = {
    centerAlign: {
        textAlign: "center", 
    },
    deleteWidth: {
        minWidth: 34,
        width: 64 /*34*/,
        margin: "0 8px 0 0",
        backgroundColor: "red",
        left: 5 /*-8*/,
        border: "1px solid white",
    },
    chkbox: {
        "& svg": {
            color: "white",
        },
    },
    chkbox1: {
        "& svg": {
            color: "#3c8ac7",
        },
    },
    ellipsis: {
        fontWeight: 500,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 5,
        WebkitBoxOrient: "vertical",
    },
    disable: { 
        minWidth: 55, 
        height:37,
        // margin: "0 8px 0 0",
        // left: 5 /*-8*/,
        // width: 64 /*34*/,
        backgroundColor: "#4b8dc4", 
        border: "1px solid white",
        '&:hover':{
            backgroundColor:"#b9b9b9"
        }
    }, 
    // show filtering arrows
    root: {
        color: "white", 
        '& .MuiTableSortLabel-icon': {
                color: 'white !important',
            }, 
    },
    // Half visible for inactive icons
    // activeSortIcon: {
    //     opacity: 1,
    //     color:'white'
    // },
};

//this class is the black text header that is on the top of a table
class EnhancedTableHead extends Component {
    createSortHandler = (property) => (event) => {
        this.props.onRequestSort(event, property.id, property);
    };
    clearSortHandler = (property) => (event) => {
        this.props.onClearSort(event, property.id, property);
    };

    render() {
        const { order, classes, orderBy, columnData, actions, disabled, recordsView } = this.props;
        var st = { color: "white" };
        return (
            <TableHead>
                <TableRow>
                    {actions && (
                        <CustomTableCell key={"actions"} sortDirection={false} style={{ width: "auto" }}>
                            <Typography>{"Actions"}</Typography>
                        </CustomTableCell>
                    )}
                    {columnData &&
                        columnData.map((column) => {
                            // if (column.colorChange) st = { color: "red" };

                            return (
                                <CustomTableCell
                                    key={column.id}
                                    sortDirection={orderBy === column.id ? order : false}
                                    style={{ width: column.width ? column.width : "auto" }}
                                    className={column.center ? classes.centerAlign : ""}>
                                    {column.allowSort && (
                                        <>
                                        <Tooltip title={"Sort - " + column.label} placement="bottom-start" enterDelay={300}>
                                            
                                            <TableSortLabel active={orderBy === column.id} direction={order} onClick={this.createSortHandler(column)}  
                                                classes={{ root: classes.root,
                                                        // icon:((orderBy === column.id) ? classes.activeSortIcon : classes.inactiveSortIcon), 
                                                }}
                                                >
                                                <Typography style={st}>
                                                    {column.label}
                                                </Typography>
                                            </TableSortLabel>  
                                        </Tooltip> 
                                        {orderBy === column.id && recordsView &&  ( 
                                            <Tooltip title="Disable Filter" placement="bottom-start" enterDelay={300}> 
                                                <Button
                                                    className={classes.disable}
                                                    style={{ hover: { color: "white" }, display:"inline-grid" }}
                                                    color="primary"
                                                    onClick={this.clearSortHandler(column)}
                                                    >  
                                                    <img 
                                                        style={{
                                                        width: "1.9em",
                                                        height: "1.8em",
                                                        verticalAlign: "middle",  
                                                    }}
                                                    src={filter}
                                                    alt="Disable Filter"
                                                />
                                                </Button> 
                                            </Tooltip>
                                        )}
                                        </> 
                                       
                                    )}
                                    {!column.allowSort && column.label === "Form Name" && (
                                        <Tooltip title="Form Associated with Record" placement="bottom-start" enterDelay={300}>
                                            <Typography
                                                className={classes.ellipsis}
                                                style={{
                                                    width: column.width ? column.width : "auto",
                                                    color: column.colorChange ? "#75bafa" : "white",
                                                }}>
                                                {column.label}
                                            </Typography>
                                        </Tooltip>
                                    )}
                                    {!column.allowSort && column.label !== "Form Name" && (
                                        <Tooltip title={column.label ? column.label : ""} placement="bottom-start" enterDelay={300}>
                                            <Typography
                                                className={classes.ellipsis}
                                                style={{
                                                    width: column.width ? column.width : "auto",
                                                    color: column.colorChange ? "#75bafa" : "white",
                                                }}>
                                                {column.label}
                                            </Typography>
                                        </Tooltip>
                                    )}
                                    {column.select && !disabled && (
                                        <Tooltip title="Select All?">
                                            <Checkbox
                                                disabled={disabled}
                                                className={column.checked ? classes.chkbox1 : classes.chkbox}
                                                checked={column.checked}
                                                disableRipple={true}
                                                // color="primary"
                                                style={{ left: -12 }}
                                                // inputProps={{ "aria-label": "secondary checkbox" }}
                                                onChange={column.handleSelect}
                                            />
                                        </Tooltip>
                                    )}
                                    {column.delete && (
                                        <Tooltip title="Delete All?">
                                            <Button
                                                className={classes.deleteWidth}
                                                style={{ hover: { color: "white" } }}
                                                color="primary"
                                                onClick={column.handleDelete}>
                                                <DeleteIcon color="inherit" />
                                            </Button>
                                        </Tooltip>
                                    )}
                                </CustomTableCell>
                            );
                        }, this)}
                </TableRow>
            </TableHead>
        );
    }
}
EnhancedTableHead.displayName = "EnhancedTableHead";
export default withStyles(styles)(EnhancedTableHead);
