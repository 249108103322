//page maps not global maps
import React, { Component } from "react";
import AppContainer from "../common/AppContainer";
import BaseMapControl from "../common/esrimap/BaseMapControl";

class MapPage extends Component {
    render() {
        return (
            <AppContainer authenticated>
                <BaseMapControl />
            </AppContainer>
        );
    }
}
MapPage.displayName = "MapPage";

export default MapPage;
