import orm, { Model } from "../common/orm";

// This file contains the database for "users"
// They tie directly with the django models and redux-orm
// Feel free to split out the models into however many models.js you would like
// This "page" folder was originally supposed to be for anything related to the actual pages

export class User extends Model {
    static get fields() {
        return {};
    }
    static get modelName() {
        return "User";
    }
}
orm.register(User); //register the model with orm. "Action creators" and "reducers" are created automatically from the Model class
