/*****************************************************************************
 * This component is the "manage" users page. It encapsulates all of the users,
 * the admin will have access to modify any of the users under them.
 * Component allows for: "Creating a New User", "Editing of a User" or
 * "Deleting of a user"
 *
 * Created by: Robert Gautier/Joe J.
 * Date: 3/2019
 *****************************************************************************/
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import { withStyles } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import EditIcon from "@material-ui/icons/Edit";
import AppContainer from "../common/AppContainer";
import EnhancedTableHead from "../common/EnhancedTableHead";
import CustomTableCell from "../common/TableCell";

import { User } from "./models";

import { Page } from "../page/models";
import { createSelector } from "../common/orm";

// Get all "User" objects and order
const allUsers = createSelector((session) => {
    return session.User.all().orderBy("name").toRefArray();
});
const allPages = Page.selectAll();

// CSS in JS styles
const styles = (theme) => ({
    table: {
        minWidth: 500,
    },
    flex: {
        flex: 1,
    },
    button: {
        float: "right",
    },
    gutterBottom: {
        marginBottom: ".6em",
    },
    root: {
        width: "100%",
        overflowX: "auto",
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        margin: "0 8px 0 0",
    },
    centerAlign: {
        textAlign: "center",
    },
    nowrap: {
        whiteSpace: "nowrap",
    },
    expandedMargin: {
        marginTop: "12px",
    },
});

// The table columns on the Manage User page.
// http://localhost:3000/#/adm/manage
const columnData = [
    { id: "actions", numeric: false, label: "", allowSort: false, width: "auto" },
    { id: "username", numeric: false, label: "Username", allowSort: false, width: "auto" },
    { id: "name", numeric: false, label: "Name", allowSort: false, width: "auto" },
    { id: "department", numeric: false, label: "Department", allowSort: false, width: "auto" },
    { id: "title", numeric: false, label: "Title", allowSort: false, width: "auto" },
    { id: "role", numeric: false, label: "Role", allowSort: false, width: "auto" },
    { id: "dashboard", numeric: false, label: "Dashboard", allowSort: false, width: "auto" },
    { id: "contacts_read_only", numeric: false, label: "Contacts Read Only", allowSort: false, width: "auto" },
];

// The table component on the manage page
// http://localhost:3000/#/adm/manage
class UserTable extends Component {
    constructor(props, context) {
        super(props, context);

        // Default states
        this.state = {
            order: "asc",
            orderBy: "name",
            deleteDialogOpen: false,
            deleteDialogText: null,
            deleteDialogConfirmAction: null,
            editUser: false, //initialsetting of edit button not being pressed
        };
    }

    //edit user is called when button is clicked
    editUser = (edit) => {
        this.props.history.push("/adm/edit/" + edit.id); //will open edit page with userID at end of address
    };

    // Render the table
    render() {
        const { classes, user, pages } = this.props;
        const { order, orderBy } = this.state;

        return (
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <EnhancedTableHead order={order} orderBy={orderBy} columnData={columnData} />
                    <TableBody>
                        {user.map((n) => {
                            var userPage = pages.find((p) => p.id === parseInt(n.default_dashboard));
                            return (
                                <TableRow hover key={n.id}>
                                    <CustomTableCell className={classes.nowrap}>
                                        <Tooltip title="Edit">
                                            <Button
                                                className={classes.deleteWidth}
                                                onClick={() => this.editUser(n)}>
                                                <EditIcon color="primary" />
                                            </Button>
                                        </Tooltip>
                                    </CustomTableCell>
                                    {/* OUTPUT INTO THE TABLE FOR THE COLUMNS*/}
                                    <CustomTableCell>{n.username}</CustomTableCell>
                                    <CustomTableCell>{n.name}</CustomTableCell>
                                    <CustomTableCell>{n.department ? n.department : "-"}</CustomTableCell>
                                    <CustomTableCell>{n.title ? n.title : "-"}</CustomTableCell>
                                    <CustomTableCell>{n.role ? n.role : "-"}</CustomTableCell>
                                    <CustomTableCell>{userPage ? userPage.name : "-"}</CustomTableCell>
                                    <CustomTableCell>{n.contacts_read_only ? "Yes" : "No"}</CustomTableCell>
                                </TableRow>
                            );
                        })}
                        {user.length < 1 && (
                            <TableRow>
                                <CustomTableCell colSpan={columnData.length} className={classes.centerAlign}>
                                    No Users Added
                                </CustomTableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Paper>
        );
        // }
    }
}

// The root component of the user
// http://localhost:3000/#/config/user
class UserConfig extends Component {
    constructor(props, context) {
        super(props, context);
        // The new user dialog is closed by default
        this.state = {
            newDialogOpen: false,
        };
    }

    // A function that closes the new user dialog
    // This function is a good example of how to pass objects/funtions from their parents down
    // It is passed down several levels from here -> PageTable -> AddPage
    returnDialog = () => {
        this.setState({ newDialogOpen: false });
    };

    render() {
        const { classes, user, ormUserDelete, history, pages } = this.props;
        const { newDialogOpen } = this.state;

        var user_list = user;
        user_list = user_list.filter(account => !account.username.includes("heitest"))

        return (
            <AppContainer authenticated>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Manage Users
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            onClick={() => this.setState({ newDialogOpen: true })}
                            fullWidth
                            variant="contained"
                            color="primary"
                            component={Link}
                            to={"/adm/create/"}>
                            <AddCircleOutlineIcon />
                            &nbsp;&nbsp;&nbsp;Create New User
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Accordion defaultExpanded>
                            <AccordionSummary classes={{ root: classes.expandedMargin }} expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Administrators
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                <UserTable
                                    history={history}
                                    user={user_list.filter(n => n.is_city_admin)}
                                    returnDialog={this.returnDialog}
                                    newDialogOpen={newDialogOpen}
                                    classes={classes}
                                    ormUserDelete={ormUserDelete}
                                    pages={pages}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12}>
                        <Accordion defaultExpanded>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Users
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                <UserTable
                                    history={history}
                                    user={user_list.filter(n => !n.is_city_admin && n.is_active)}
                                    returnDialog={this.returnDialog}
                                    newDialogOpen={newDialogOpen}
                                    classes={classes}
                                    ormUserDelete={ormUserDelete}
                                    pages={pages}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12}>
                        <Accordion defaultExpanded>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Inactive Users
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                <UserTable
                                    history={history}
                                    user={user_list.filter(n => !n.is_city_admin && !n.is_active)}
                                    returnDialog={this.returnDialog}
                                    newDialogOpen={newDialogOpen}
                                    classes={classes}
                                    ormUserDelete={ormUserDelete}
                                    pages={pages}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </AppContainer>
        );
    }
}

// This connects the redux-orm "allUsers" function and allows this component to have the props "user"
// The second part loads all orm actions into this pages props for both Page and Section: ormPageCreate, ormSectionCreate, orPageUpdate, etc.
UserConfig = connect(
    (state) => ({
        user: allUsers(state),
        pages: allPages(state), //component wide state prop "user" gets derived info from database
    }),
    {
        ...User.actions, // user.action props--> ormUserCreate(payload), ormUserDelete(payload), etc.
    }
)(UserConfig);

export default withStyles(styles)(withRouter(UserConfig));
