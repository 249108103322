import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import DialogContent from "@material-ui/core/DialogContent";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import DialogContentText from "@material-ui/core/DialogContentText";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ViewIcon from "@material-ui/icons/Visibility";
import Close from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import WarningDialog from "../../common/WarningDialog";
import RadioGroup from "../../common/RadioGroup";
import AppContainerApplication from "../../common/AppContainerApplication";
import TextField from "../../common/TextField";
import Select from "../../common/Select";
import CheckboxGroup from "../../common/CheckboxGroup"; 
import DatePicker from "../../common/DatePicker2";
import FileInput from "../../common/FileInput";
import Checkbox from "../../common/Checkbox";
// import Subquestion from "../../common/Subquestion";
import HelpLabel from "../../common/HelpLabel";
// import { createSelector } from "../../common/orm";
import states from "../../common/statesmn.json";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import { ApplicationRecord, ApplicationRecordStatus, NineMilePermit, NineMilePermitAttachment } from "../../page/models";
import BaseMapControlApplication from "../esri/BaseMapControlApplication";
import ReactSelect from "../../common/ReactSelect";

const styles = (theme) => ({
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8,
        marginTop: 2,
        marginBottom: 2,
    },
    centerAlign: {
        textAlign: "center",
    },
    centerAlign2: {
        textAlign: "center",
        display: "inline-block",
        width: "100%",
    },
    nowrap: {
        whiteSpace: "nowrap",
    },
    rightAlign: {
        textAlign: "right",
        marginRight: 15,
    },
    flex: {
        flex: 1,
    },
    printDivider: {
        height: 8,
        backgroundColor: "rgba(0, 0, 0, 0.42)",
        marginTop: 16,
        marginBottom: 8,
    },
    dialogMin: {
        minWidth: 400,
    },
    minWidth: {
        minWidth: 600,
    },
    root: {
        flexBasis: "100%",
        maxWidth: "100%",
    },
});

function MAKE_OPTIONS(table) {
    return table.map((row) => ({
        label: row,
        value: row,
        linkhref: row.linkhref,
        linkname: row.linkname
    }));
}
function MAKE_OPTIONS_LINK(table) {
    return table.map((row) => ({
        label: row.label,
        value: row.value,
        linkhref: row.linkhref,
        linkname: row.linkname
    }));
}


// const getApplication = createSelector(
//     (state, ownProps) => ownProps.match.params["hash"],
//     (session, hash) => {
//         return session.NineMilePermit.filter((p) => p.hash === hash).toModelArray()[0];
//     }
// );

class NineMilePermit_ extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0, 
            formKey: null,
            order: "desc",
            orderBy: null,
            temp_contacts: [],
            attachmentDialogOpen: false,
            attachmentEditing: null,
            attachmentFormKey: null,
            attachmentOrder: "desc",
            attachmentOrderBy: null,
            temp_attachments: [],
            loading: false,
            new_save: false,
            new_save2: false,
            mainFormKey: null,
            entryError: false,
            thegeo:null,
            consentFromOwner: false,
            primaryContact:false,
            dialogLoading:false,
            alerts:"",
            outOfDistrict:false,
            address:null,
            city:null,
            zip:null,
            createPoint:false,
            hasAddress:true,
            appData:null,
            incompleteSave:false, 
            saveDialogOpen:false, 
            submittedSave:false,
            priorErrorsToClear:{},
            // saveInfo:{owner_first_name:null, owner_last_name:null, owner_email:null},
        };
    }
    componentDidMount() {
        const { match, ormNineMilePermitLoadDetailChild, ormNineMilePermitAttachmentLoadDetailChild } = this.props;
        var component = this;
        const {temp_attachments} = this.state
        let newAttachments = temp_attachments

        if (match.params && match.params.hash) {
            ormNineMilePermitLoadDetailChild(match.params.hash, function (hmm) {
                component.setState({appData:hmm[0], mainFormKey: window.performance.now() });
            });
      
            ormNineMilePermitAttachmentLoadDetailChild(match.params.hash, function (data) {
                data.forEach(att=>{ // we do this as we need the images that were saved previously 'id' key to not resave them again. 
                    let temp = {}
                    temp['id'] = 'blabla'
                    temp['file'] = att.file
                    temp['name'] = att.name
                    temp['_type'] = att._type
                    newAttachments.push(temp) 
                }) 
                component.setState({ temp_attachments: newAttachments });
            });
        }
        if (match.params && match.params.print) {
            component.setState({ activeStep: "PRINT" });
        }
    }

    errorValidator = (values) => {
        
        const {temp_attachments, priorErrorsToClear, submittedSave}  = this.state;

        const isRequired = (val) => {
            return !val ? "Required" : null;
        };
        const validateEmail = (email) => {
            var re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !re.test(email) ? "Invalid Email Address" : null;
        };
        
        if (!submittedSave){//we are just saving not submitting final so dont validate with this part

            var attachmentsNeeded = ['Site Plans', 'Project Narrative']
            if (values.consent_from_owner){
                attachmentsNeeded.push('Home Owners Consent')
            }
            temp_attachments.forEach(at=>{
                if(attachmentsNeeded.find(a=>a===at._type)){
                    attachmentsNeeded = attachmentsNeeded.filter(af=> af!==at._type? af:null)
                }
            })

            var valObj = {}
            if (!values.prop_owner){
                if (values.doesnt_have_address){
                    valObj = { 
                        owner_first_name: isRequired(values.owner_first_name),
                        owner_last_name: isRequired(values.owner_last_name),
                        owner_mailing_address: isRequired(values.owner_mailing_address),
                        owner_phone: isRequired(values.owner_phone),
                        owner_city: isRequired(values.owner_city),
                        owner_state: isRequired(values.owner_state),
                        owner_zip: isRequired(values.owner_zip),
                        owner_email: validateEmail(values.owner_email),
                        
                        project_address: isRequired(values.project_address), 
                        project_name: isRequired(values.project_name),
                        project_description: isRequired(values.project_description),
                        proposed_work: isRequired(values.proposed_work),
                        area_disturbance: isRequired(values.area_disturbance),
                        volume_evacuation: isRequired(values.volume_evacuation),
                        area_preimpervious: isRequired(values.area_preimpervious),
                        area_postimpervious: isRequired(values.area_postimpervious),
                        wetland_present: isRequired(values.wetland_present),
                        public_or_private: isRequired(values.public_or_private),
                        typedname: isRequired(values.typedname), 
                    
                    }
                }else{ 
                    valObj = {
                        consent_from_owner: isRequired(values.consent_from_owner),
                        owner_first_name: isRequired(values.owner_first_name),
                        owner_last_name: isRequired(values.owner_last_name),
                        owner_mailing_address: isRequired(values.owner_mailing_address),
                        owner_phone: isRequired(values.owner_phone),
                        owner_city: isRequired(values.owner_city),
                        owner_state: isRequired(values.owner_state),
                        owner_zip: isRequired(values.owner_zip),
                        owner_email: validateEmail(values.owner_email),
                        
                        project_address: isRequired(values.project_address),
                        project_city: isRequired(values.project_city),
                        project_name: isRequired(values.project_name),
                        project_description: isRequired(values.project_description),
                        proposed_work: isRequired(values.proposed_work),
                        area_disturbance: isRequired(values.area_disturbance),
                        volume_evacuation: isRequired(values.volume_evacuation),
                        area_preimpervious: isRequired(values.area_preimpervious),
                        area_postimpervious: isRequired(values.area_postimpervious),
                        wetland_present: isRequired(values.wetland_present),
                        public_or_private: isRequired(values.public_or_private),
                        typedname: isRequired(values.typedname), 
                    }; 
                }
            }else{
                valObj = { 
                    owner_first_name: isRequired(values.owner_first_name),
                    owner_last_name: isRequired(values.owner_last_name),
                    owner_mailing_address: isRequired(values.owner_mailing_address),
                    owner_phone: isRequired(values.owner_phone),
                    owner_city: isRequired(values.owner_city),
                    owner_state: isRequired(values.owner_state),
                    owner_zip: isRequired(values.owner_zip),
                    owner_email: validateEmail(values.owner_email),
                    
                    project_address: isRequired(values.project_address),
                    project_city: isRequired(values.project_city),
                    project_name: isRequired(values.project_name),
                    project_description: isRequired(values.project_description),
                    proposed_work: isRequired(values.proposed_work),
                    area_disturbance: isRequired(values.area_disturbance),
                    volume_evacuation: isRequired(values.volume_evacuation),
                    area_preimpervious: isRequired(values.area_preimpervious),
                    area_postimpervious: isRequired(values.area_postimpervious),
                    wetland_present: isRequired(values.wetland_present),
                    public_or_private: isRequired(values.public_or_private),
                    typedname: isRequired(values.typedname),
                
                
                }
            }
        
            var alerts = "";
            var ai = 6;
            if (valObj["consent_from_owner"] === "Required") {
                alerts += "Consent from Owner is required if not the Property Owner (Step 2)\n";
                ai = 1;
            }
            if (valObj["owner_first_name"] === "Required") {
                alerts += "Property Owner First Name (Step 2)\n";
                ai = 1;
            }
            if (valObj["owner_last_name"] === "Required") {
                alerts += "Property Owner Last Name (Step 2)\n";
                ai = 1;
            }
            if (valObj["owner_mailing_address"] === "Required") {
                alerts += "Property Owner Mailing Address (Step 2)\n";
                ai = 1;
            }
            if (valObj["owner_city"] === "Required") {
                alerts += "Property Owner City (Step 2)\n";
                ai = 1;
            }
            if (valObj["owner_state"] === "Required") {
                alerts += "Property Owner State (Step 2)\n";
                ai = 1;
            }
            if (valObj["owner_zip"] === "Required") {
                alerts += "Property Owner Zip (Step 2)\n";
                ai = 1;
            }
            if (valObj["owner_phone"] === "Required") {
                alerts += "Property Owner Phone (Step 2)\n";
                ai = 1;
            }
            if (valObj["owner_email"] === "Invalid Email Address") {
                alerts += "Property Owner Email (Step 2)\n";
                ai = 1;
            }
        

            if (valObj["project_address"] === "Required" && values.doesnt_have_address) {
                alerts += "Street Address (Step 3)\n";
                ai = 2;
            }else if (valObj["project_address"] === "Required") {
                alerts += "Project Address (Step 3)\n";
                ai = 2;
            }
            if (valObj["project_city"] === "Required") {
                alerts += "Project City (Step 3)\n";
                ai = 2;
            }
            if (valObj["project_name"] === "Required") {
                alerts += "Project Name (Step 4)\n";
                ai = 3;
            }
            if (valObj["project_description"] === "Required") {
                alerts += "Project Description (Step 4)\n";
                ai = 3;
            }
            if (valObj["proposed_work"] === "Required") {
                alerts += "Proposed Work (Step 4)\n";
                ai = 3;
            }
            if (valObj["area_disturbance"] === "Required") {
                alerts += "Area of Disturbance (Step 4)\n";
                ai = 3;
            }
            if (valObj["volume_evacuation"] === "Required") {
                alerts += "Volume Excavation (Step 4)\n";
                ai = 3;
            }
            if (valObj["area_preimpervious"] === "Required") {
                alerts += "Total Area Pre-Contruction Impervious(Step 4)\n";
                ai = 3;
            }
            if (valObj["area_postimpervious"] === "Required") {
                alerts += "Total Area Post-Contruction Impervious(Step 4)\n";
                ai = 3;
            }
            if (valObj["wetland_present"] === "Required") {
                alerts += "Wetland Present (Step 4)\n";
                ai = 3;
            }
            if (valObj["public_or_private"] === "Required") {
                alerts += "Public or Private Entity (Step 5)\n";
                ai = 4;
            }
            if(attachmentsNeeded.length > 0){
                attachmentsNeeded.forEach(at=>{
                    // let t = {}
                    // t[at]="Required"
                    // valObj = Object.assign(valObj, t)
                    alerts += `${at} File Required (Step 6)\n`;
                    ai = 5
                    
                })
                
            }
            valObj['attachment'] = attachmentsNeeded.length < 1 ? '' : 'Required'
            
            if (valObj["typedname"] === "Required") {
                alerts += "Applicant Signature (Step 7)\n";
                ai = 6
            }
        
            if (alerts) {
                alert("The following fields are missing or invalid:\n" + alerts);
                this.setState({ activeStep: ai, alerts: alerts, priorErrorsToClear:valObj });
            }else{
                this.setState({alerts:alerts, priorErrorsToClear:valObj})
            } 
        }else{//just saving so just check name and emails
            valObj = { 
                owner_first_name: isRequired(values.owner_first_name),
                owner_last_name: isRequired(values.owner_last_name),  
                owner_email: validateEmail(values.owner_email), 
            } 
            alerts = "";
            ai = 0; 
            if (valObj["owner_first_name"] === "Required") {
                alerts += "First Name \n";
                ai = 1;
            }
            if (valObj["owner_last_name"] === "Required") {
                alerts += "Last Name\n";
                ai = 1;
            }
            if (valObj["owner_email"] === "Invalid Email Address") {
                alerts += "Email\n";
                ai = 1;
            }
            if (alerts) { 
                this.setState({ alerts: alerts, activeStep: ai, alertOpen:true });
            }else{
                // occurs if they try to submit first memoized form then save only as has those fields as problems so we need to clear the keys else reactform wont submit
                if(JSON.stringify(priorErrorsToClear) !== '{}'){
                    Object.keys(priorErrorsToClear).forEach(key=>{priorErrorsToClear[key] = null}) 
                    this.setState({alerts:alerts, priorErrorsToClear})
                    valObj = {...priorErrorsToClear}
                }
                
            } 

            this.setState({submittedSave:false})

        }
        return valObj;
    };

    errorValidatorAttachment = (values) => {
        const isRequired = (val) => {
            return !val ? "Required" : null;
        };
        var valObj = {
            file: isRequired(values.file),
            name: isRequired(values.name),
        };

        return valObj;
    };

    submitApplication = (values, what) => {
        const {
            // application,
            ormApplicationRecordCreate,
            ormApplicationRecordStatusCreate,
            ormNineMilePermitCreate,
            ormNineMilePermitAttachmentCreate,
            ormNineMilePermitUpdatePublic,
        } = this.props;
        const { temp_attachments, thegeo, alerts, appData  } = this.state;

        const _this = this;
        
        if(!alerts) this.setState({ loading: true }); //used for attachment alerts to not go into loading
       
        // Editing
        if (appData) {
            ormApplicationRecordStatusCreate({
                applicationrecord: appData.apprec.id,
                status: what === 'Save Not Completed' ? 'Save Not Completed' :"Completeness Review"
            }).then(bla=>{ 

                ormNineMilePermitUpdatePublic({
                    id: appData.id,
                    ...values,
                });

                let add = 0; 
                let total = temp_attachments.length 
                temp_attachments.forEach(function (tc) {
                    if (tc.id) {

                        //Skip as attachment saved previously 
                        total = total - 1
                    } else { 
                        ormNineMilePermitAttachmentCreate({
                            ninemilepermit_id: appData.id,
                            ...tc,
                        }).then((_z) => {
                            add++;
                            if (total === add) {
                                if(what === 'Save Not Completed') _this.setState({incompleteSave: true, loading: false, }); 
                                else _this.setState({ loading: false, new_save2: true });
                            }
                        });
                    }
                }) 
                if (add === total) {
                    // No attachments or contacts - its finished loading
                    setTimeout(function () {
                        if(what === 'Save Not Completed') _this.setState({loading: false, incompleteSave: true });
                        else _this.setState({ loading: false, new_save2: true });
                       
                    }, 1000);
                }      
            })
        } else {
            if(!alerts){
                // New OR just saving to comeback later
                ormApplicationRecordCreate({
                    application: 11,
                }).then((ar) => {
                    ormApplicationRecordStatusCreate({
                        applicationrecord: ar.id,
                        status: what === 'Save Not Completed' ? 'Save Not Completed' :"Completeness Review",
                    });

                    ormNineMilePermitCreate({
                        applicationrecord: ar.id,
                        geometry: thegeo ? thegeo : null,
                        ...values,
                    }).then((rpb) => {
                        if (rpb && rpb.error) {
                            this.setState({ entryError: true, loading: false });
                            fetch("/error/post", {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify({
                                    error: JSON.stringify(values),
                                    error_info: rpb.error.toString(),
                                }),
                            });
                        } else { 
                            let add = 0; 
                            let total = temp_attachments.length 
                            temp_attachments.forEach(function (tc) {
                                if (tc.id) {
                                    //Skip as attachment saved previously 
                                    total = total - 1
                                } else { 
                                    ormNineMilePermitAttachmentCreate({
                                        ninemilepermit_id: rpb.id,
                                        ...tc,
                                    }).then((_z) => {
                                        add++;
                                        if (total === add){
                                            if(what === 'Save Not Completed') _this.setState({loading: false, incompleteSave: true });
                                            else _this.setState({ loading: false, new_save: true });
                                        } 
                                    
                                    });
                                }
                            });
                            if (add === total) {
                                // No attachments or contacts - its finished loading 
                                if(what === 'Save Not Completed') _this.setState({loading: false, incompleteSave: true });
                                else _this.setState({ loading: false, new_save: true });
                            }
                        }
                    });
                });
            }
        }
    };
   
    addAttachment = (values) => {
  

        var { attachmentEditing, temp_attachments } = this.state;

        if (!attachmentEditing) {
            temp_attachments.push(values);
            this.setState({ temp_attachments: temp_attachments });
        } else {
            var temp = temp_attachments.filter((tc) => tc !== attachmentEditing);
            temp.push(values);
            this.setState({ temp_attachments: temp });
        }
          
        this.setState({ attachmentDialogOpen: false });
    };

    resubmitAttachments=()=>{
        const { 
            ormApplicationRecordStatusCreate,
            ormNineMilePermitAttachmentCreate, 
        } = this.props;
        var { temp_attachments, appData } = this.state;
        
        
        //do this to add more attachments regardless if they already submitted
        if(appData){ 
            this.setState({ loading: true }); 
            var _this = this
            ormApplicationRecordStatusCreate({
                applicationrecord: appData.apprec.id,
                status: "Resubmittal Review",
            }); 
 
            let add = 0; 
            let total = temp_attachments.length 
            temp_attachments.forEach(function (tc) {
                if (tc.id) {//already saved prior so do nothing with them
                    /*ormRpbcwdPermitAttachmentUpdate({
                        id: tc.id,
                        ...tc
                    });*/
                    total = total - 1
                } else {
                 
                    ormNineMilePermitAttachmentCreate({
                        ninemilepermit_id: appData.id,
                        ...tc,
                    }).then((_z) => {
                        add++;
                        if (total === add){
                            _this.setState({ loading: false, new_save2: true });
                        }
                    });
                }
            });
            if (total === add){
                _this.setState({ loading: false, new_save2: true });
            }
        }
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.state.temp_contacts.sort((a, b) => (b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1))
            : this.state.temp_contacts.sort((a, b) => (a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1));

        this.setState({ order, orderBy });
    };

    handleRequestSort2 = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.state.temp_attachments.sort((a, b) => (b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1))
            : this.state.temp_attachments.sort((a, b) => (a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1));

        this.setState({ order, orderBy });
    };

    handleNext = (index) => {
        const { activeStep } = this.state;
        if (index || index === 0) {
            this.setState({ activeStep: index, dialogLoading: index === 2 && activeStep !== 2 ? true : false });
        } else if (activeStep < 6) this.setState({ activeStep: activeStep + 1 });
        else {
            this.formApi.submitForm();
        }
    };

    //save the geometry from the map
    saveAddress=(geometry, address)=>{
   
        this.formApi.setValue("project_address",address.StAddr)
        this.formApi.setValue("project_city",address.City) //if city isnt within list it wont show on dropdown
        this.formApi.setValue("project_zip",address.Postal)
    
        let geojson = {
            type: "Point",
            coordinates: [geometry.longitude,geometry.latitude]
        };

        //check if within boundary
        fetch(`
            https://services9.arcgis.com/FsMUjHd4ZYdso5QZ/ArcGIS/rest/services/NMCWD_Legal_Boundary/FeatureServer/0/query?where=1=1&objectIds=&time=&geometry=${geometry.longitude},${geometry.latitude}
            &geometryType=esriGeometryPoint&inSR=4326&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Meter&relationParam=&returnGeodetic=false&outFields
            =&returnGeometry=true&returnCentroid=false&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision
            =&outSR=&defaultSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false
            &returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false
            &returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pgeojson&token=`,
            {
                method : "GET"

            })
            .then(resp=> resp.json())
            .then(data=>{
               if(data.features.length === 0){
                this.setState({outOfDistrict:true})
               }
        })
        this.setState({thegeo:geojson})
  
    }

    //check address and city for proper geometry point
    checkAddress = (e) =>{
 
        const {formApi} = this;
   
        let city = formApi.getValue("project_city")
        let address = formApi.getValue("project_address")
        let zip = formApi.getValue("project_zip")
        if (city && address && zip) this.setState({city,address, zip, createPoint:true})

    }

    setPoint=()=>{
        this.setState({createPoint:false})
    }

    setDialogLoading = (e) =>{
        this.setState({dialogLoading:e})
    }

    render() {
        const { classes, ormNineMilePermitAttachmentDelete } = this.props;
        const {
            activeStep,
            attachmentDialogOpen,
            attachmentEditing,
            attachmentFormKey,
            attachmentOrder,
            attachmentOrderBy,
            temp_attachments,
            loading,
            new_save,
            mainFormKey,
            new_save2,
            entryError,
            consentFromOwner,
            primaryContact,
            dialogLoading,
            outOfDistrict,
            city,
            address,
            zip,
            createPoint,
            hasAddress,
            appData,
            incompleteSave,
            saveDialogOpen,  
        } = this.state;

        //this has all parameters for the basemapcontrolapplication
        var layers = [{attribution: "", city_id: 14,city_label: "Nine Mile Creek Watershed District",id: 22, is_basemap: false,
                        layer_type: "ArcGIS", layernumbers: "0", layerurl: "https://services9.arcgis.com/FsMUjHd4ZYdso5QZ/arcgis/rest/services/NMCWD_Legal_Boundary/FeatureServer/0",
                        name: "NMCWD Boundary",on_by_default: true,opacity: 1,order: 0,proxyurl: "",show_popup: false, subdomains: "",
                        tileddynamic: "Feature Service",token: "",webmap_id: null, wmslayers: "", x: -93.44004, y: 44.875283,
                        geocoder_left:[], geocoder_bottom:[], geocoder_right:[],geocoder_top:[], map_level:11 },
                        {attribution: "", city_id: 14,city_label: "Nine Mile Creek Watershed District",id: 22, is_basemap: false,
                        layer_type: "ArcGIS", layernumbers: "1", layerurl: "https://services9.arcgis.com/FsMUjHd4ZYdso5QZ/ArcGIS/rest/services/Adopt_a_Creek_2023_Map_WFL1/FeatureServer/3",
                        name: "City Boundary",on_by_default: true,opacity: 1,order: 1,proxyurl: "",show_popup: false, subdomains: "",
                        tileddynamic: "Feature Service",token: "",webmap_id: null, wmslayers: "", x: -93.44004, y: 44.875283,
                        geocoder_left:[], geocoder_bottom:[], geocoder_right:[],geocoder_top:[], map_level:11 } ]

        var is_read_only = false;
        if (
            appData &&
            appData.apprec &&
            (   appData.apprec.status === "Incomplete" ||
                appData.apprec.status === "Complete and Under Review," ||
                appData.apprec.status === "Board Meeting Scheduled," ||
                appData.apprec.status === "Permit Approved with Conditions" ||
                appData.apprec.status === "Completeness Review"||
                appData.apprec.status === "Permit Issued" ||
                appData.apprec.status === "Construction Complete"||
                appData.apprec.status === "Monitoring Period"||
                appData.apprec.status === "Permit Closed"||
                appData.apprec.status === "Resubmittal Review"||
                appData.apprec.status === "Withdrawn"||
                appData.apprec.status === "Permit Extended"

                )
        )
            is_read_only = true;

        var columnDataAttachments = [
            { id: "actions", numeric: false, label: "", allowSort: false, width: "20px" },
            { id: "name", numeric: false, label: "Name", allowSort: true },
            { id: "_type", numeric: false, label: "Type", allowSort: true },
            { id: "notes", numeric: false, label: "Notes", allowSort: true },
        ];
        // var columnDataContacts = [
        //     { id: "actions", numeric: false, label: "", allowSort: false, width: "20px" },
        //     { id: "type", numeric: false, label: "Type", allowSort: true },
        //     { id: "first_name", numeric: false, label: "First Name", allowSort: true },
        //     { id: "last_name", numeric: false, label: "Last Name", allowSort: true },
        //     { id: "mailing_address", numeric: false, label: "Address", allowSort: true },
        //     { id: "phone", numeric: false, label: "Phone", allowSort: true },
        //     { id: "email", numeric: false, label: "Email", allowSort: true },
        // ];

        if (incompleteSave) {
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "12" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10}>
                            <Typography>
                                This is a confirmation that the permit application has been saved, but not yet submitted to Nine Mile Creek Watershed District. A copy of the permit application
                                has been emailed to the applicant's email address which will include a link to finish the application later.
                                Please check your spam and junk folders if you have not received it or contact the District. 
                            </Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }
        if (new_save) {
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "12" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10}>
                            <Typography>
                                This is a confirmation that the permit application has been submitted successfully. A copy of the permit application
                                has been emailed to the primary property owner's email address. Please check your spam and junk folders if you have
                                not received it or contact the district. The district will contact you as the application is being reviewed.
                            </Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }
        if (entryError) {
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "12" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10}>
                            <Typography>
                                This permit application was 'not' successfully saved. Please contact the permit 'managing' staff to alert them of the
                                entry failure.
                            </Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }

        if (new_save2) {
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "12" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10}>
                            <Typography>This is a confirmation that the permit application has been resubmitted successfully.</Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }
        if (activeStep === "PRINT")
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "12" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10}>
                            <Typography>Permit Printed.</Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        //custom rules with links next to option names
        let options_Link = [{
            value: "2 - Floodplain Management and Drainage Alterations", 
            label: "2 - Floodplain Management and Drainage Alterations", 
            linkhref: "https://www.ninemilecreek.org/wp-content/uploads/Rule-2.0-Floodplain-Management-and-Drainage-Alterations.pdf", 
            linkname: "Rule",
        },{ 
            value: "3 - Wetlands Management", 
            label: "3 - Wetlands Management", 
            linkhref: "https://www.ninemilecreek.org/wp-content/uploads/Rule-3.0-Wetlands-Management.pdf", 
            linkname: "Rule", 
        },{
            value: "4 - Stormwater Management", 
            label: "4 - Stormwater Management", 
            linkhref: "https://www.ninemilecreek.org/wp-content/uploads/Rule-4.0-Stormwater-Management.pdf", 
            linkname: "Rule", 
        },{
            value: "5 - Erosion and Sediment Control", 
            label: "5 - Erosion and Sediment Control", 
            linkhref: "https://www.ninemilecreek.org/wp-content/uploads/Rule-5.0-Erosion-and-Sediment-Control.pdf", 
            linkname: "Rule",
        },{
            value: "6 - Waterbody Crossings and Structures", 
            label: "6 - Waterbody Crossings and Structures", 
            linkhref: "https://www.ninemilecreek.org/wp-content/uploads/Rule-6.0-Waterbody-Crossings-and-Structures.pdf", 
            linkname: "Rule",
        },{
            value: "7 - Shoreline and Streambank Improvements", 
            label: "7 - Shoreline and Streambank Improvements", 
            linkhref: "https://www.ninemilecreek.org/wp-content/uploads/Rule-7.0-Shoreline-and-Streambank-Improvements.pdf", 
            linkname: "Rule", 
        },{
            value: "8 - Sediment Removal", 
            label: "8 - Sediment Removal", 
            linkhref: "https://www.ninemilecreek.org/wp-content/uploads/Rule-8.0-Sediment-Removal.pdf", 
            linkname: "Rule", 
        },{
            value: '9 - Appropriation of Public Surface Waters', 
            label: '9 - Appropriation of Public Surface Waters', 
            linkhref: "https://www.ninemilecreek.org/wp-content/uploads/Rule-9.0-Appropriation-of-Public-Surface-Waters.pdf", 
            linkname: "Rule", 
        },{
            value: "10 - Variances and Exceptions", 
            label: "10 - Variances and Exceptions", 
            linkhref: "https://www.ninemilecreek.org/wp-content/uploads/Rule-10.0-Variances-and-Exceptions.pdf", 
            linkname: "Rule", 
        }]
               

        return (
            <AppContainerApplication
                city_id={"14"} //window.location.origin.includes("ms4prod") ? "45" : "2"}
                page_loading={loading}
                printing={activeStep === "PRINT"}>
                {activeStep === 2 && (//DUMB but did this way as clicking on seach on map would bubble form to trigger submit so pulled out
                <> 
                    {appData && appData.apprec && appData.apprec.status && (
                        <Grid container spacing={1} style={{ backgroundColor: "#a3a3a3", marginTop: -15 }}>
                            <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                <Typography className={classes.centerAlign} variant="h6">
                                    Permit Status: {appData && appData.apprec.status}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                            <Typography className={classes.centerAlign} variant="h6">
                                Permit Application Form {appData && appData.permit_number && "#" + appData.permit_number}
                            </Typography>{" "}
                            <Grid item xs={12} xl={10} className={classes.rightAlign} classes={{ root: classes.root }}></Grid>
                            <Typography className={classes.centerAlign}>
                                <b>IMPORTANT:</b> The entire permit application must be filled out at one time. Permit application progress cannot be saved. 
                            </Typography>
                            <Typography variant="caption" className={classes.centerAlign2}>
                                Required fields are marked with an asterisk (*)
                            </Typography>
                        </Grid> 
                        <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                            <Stepper style={{ padding: 8 }} alternativeLabel nonLinear activeStep={activeStep}>
                                {[
                                    "Instructions",
                                    "Contact Info",
                                    "Project Location",
                                    "Project Details",
                                    "Applicable Rules",
                                    "Attach Documents",
                                    "Review and Submit",
                                ].map((label, index) => {
                                    return (
                                        <Step key={label}>
                                            <StepButton style={{ cursor: "pointer" }} onClick={() => this.handleNext(index)}>
                                                {label}
                                            </StepButton>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </Grid>  
                        {!appData && (
                            <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                <Typography variant="subtitle1" gutterBottom style={{marginBottom:-7}}>
                                    Type in an address within the search widget to auto populate the project site address and city.<b>{" "} Note: valid addresses are within the boundary area shown on the map.</b>
                                </Typography>
                            </Grid>
                        )}
                    </Grid>

                    <Grid item xs={12} xl={10} style={{ marginLeft: 15, marginRight:15 }} classes={{ root: classes.root }}>
                        <BaseMapControlApplication
                            geometry={appData && appData.geometry?appData.geometry:null}
                            saveAddress={this.saveAddress}
                            disableSearch={appData && appData.geometry?true:false}
                            viewOnly
                            application={appData}
                            setDialogLoading={this.setDialogLoading}
                            address={address}
                            city={city}
                            zip={zip}
                            createPoint={createPoint}
                            setPoint={this.setPoint}
                            layers={layers}
                            cityID={"14"}
                        /> 
                        
                        {dialogLoading && ( 
                            <CircularProgress
                                size={72}
                                style={{ display: "table", marginLeft: "auto", marginRight: "auto", zIndex:90000 }}
                            /> 
                        )}
                    </Grid> 
                   
                    </>
                )}   
                <Form
                    getApi={(el) => (this.formApi = el)}
                    defaultValues={appData}
                    key={mainFormKey}
                    dontValidateOnMount={true}
                    validateOnSubmit={true}
                    validateError={(values) => this.errorValidator(values)}
                    onSubmit={(values,e) => this.submitApplication(values,e)}>
                    {(formApi) => (
                        <form onSubmit={formApi.submitForm}>
                            <>
                                {appData && appData.apprec && appData.apprec.status && activeStep !== 2 && (
                                    <Grid container spacing={1} style={{ backgroundColor: "#a3a3a3", marginTop: -15 }}>
                                        <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                            <Typography className={classes.centerAlign} variant="h6">
                                                Permit Status: {appData && appData.apprec.status}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                                <Grid container spacing={1} style={{ padding: "0 12px" }}>
                                {activeStep !== "PRINT" && activeStep !== 2 &&  (
                                    <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                        <Typography className={classes.centerAlign} variant="h6">
                                            Permit Application Form {appData && appData.permit_number && "#" + appData.permit_number}
                                        </Typography>{" "}
                                        <Grid item xs={12} xl={10} className={classes.rightAlign} classes={{ root: classes.root }}></Grid>
                                        <Typography className={classes.centerAlign}>
                                            <b>IMPORTANT:</b> The entire permit application must be filled out at one time. Permit application progress cannot be saved. 
                                        </Typography>
                                        <Typography variant="caption" className={classes.centerAlign2}>
                                            Required fields are marked with an asterisk (*)
                                        </Typography>
                                    </Grid>
                                )}
                                    {activeStep !== "PRINT" && activeStep !== 2 &&  (
                                        <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                            <Stepper style={{ padding: 8 }} alternativeLabel nonLinear activeStep={activeStep}>
                                                {[
                                                    "Instructions",
                                                    "Contact Info",
                                                    "Project Location",
                                                    "Project Details",
                                                    "Applicable Rules",
                                                    "Attach Documents",
                                                    "Review and Submit",
                                                ].map((label, index) => {
                                                    return (
                                                        <Step key={label}>
                                                            <StepButton style={{ cursor: "pointer" }} onClick={() => this.handleNext(index)}>
                                                                {label}
                                                            </StepButton>
                                                        </Step>
                                                    );
                                                })}
                                            </Stepper>
                                        </Grid>
                                    )}
                                    {activeStep === 0 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Typography variant="subtitle1">
                                                <b>
                                                    {"1. "}
                                                    <u>Preparation</u>
                                                </b>
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                {`\u2022`} As you work through the online application you will not be able to save your progress for
                                                submittal at a later time.
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                {`\u2022`} Please have all the required information on hand to ensure you are able to submit a
                                                complete application.
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                {`\u2022`} For guidance about the required information as well as the fee schedule and a printable
                                                permit application, please see the{" "}
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="https://www.ninemilecreek.org/permits/">
                                                    Nine Mile Creek Permitting Page
                                                </a>{" "}
                                                or contact the Nine Mile Creek office.
                                            </Typography>
                                            <br />
                                            <Typography variant="subtitle1">
                                                <b>
                                                    {"2. "}
                                                    <u>Submit Application</u>
                                                </b>
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                {`\u2022`} Once you have compiled the required information, complete the permit application and
                                                submit. Again, your progress will not be saved for submittal at a later time.
                                            </Typography>
                                            <br />
                                            <Typography variant="subtitle1">
                                                <b>
                                                    {"3. "}
                                                    <u>Application Fee</u>
                                                </b>
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                {`\u2022`} Your submitted application will be considered <u>incomplete</u> until we receive your
                                                permit application fee. Permit fees are based on parcel(s) size, see the{" "}
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="https://www.ninemilecreek.org/wp-content/uploads/Permit-Fee-Schedule-1.pdf">
                                                    Nine Mile Creek Permit Fees
                                                </a>{" "}
                                                for a fee schedule.
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                {`\u2022`} Permit fees may be paid via credit card. Please email{" "}
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="mailto:permitting@ninemilecreek.org">
                                                    permitting@ninemilecreek.org
                                                </a>{" "}
                                                to schedule a time, transaction fees will be applied.
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                {`\u2022`} Permit fees may also be mailed or dropped off via check at the Nine Mile Creek Office: {" "}
                                                <b>12800 Gerard Drive, Eden Prairie, MN, 55346.</b>
                                            </Typography>
                                        </Grid>
                                    )}
                                    {activeStep === 1 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Typography>Please add contacts associated with the application.</Typography>
                                            <Accordion defaultExpanded>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        Applicant / Property Owner (required)
                                                    </Typography>
                                                    
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <Checkbox disabled={is_read_only} label="Is the applicant the property owner? (Check box for yes)" field="prop_owner" eventHandle={(e)=>{
                                                                if(e)this.formApi.setValue('consent_from_owner', false)
                                                                this.setState({consentFromOwner:e})
                                                            }} />   
                                                        </Grid>
                                                        {!consentFromOwner && ( 
                                                            <Grid item xs={6}>
                                                                <Checkbox disabled={is_read_only}        
                                                                label="Do you have written authorization from the property owner that a named representative can act on the owner's behalf?"
                                                                field="consent_from_owner"
                                                                />
                                                                <Typography variant="subtitle1"  >
                                                                <a target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    href="https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fwww.ninemilecreek.org%2Fwp-content%2Fuploads%2FProperty-Owner-Authorization.docx&wdOrigin=BROWSELINK">
                                                                    Property Owners Authorization
                                                                </a>
                                                                </Typography>   
                                                            </Grid>   
                                                        )}
                                                        {consentFromOwner &&(
                                                        <Grid item xs={6}>
                                                         </Grid>   
                                                        )}
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="owner_first_name"
                                                                label="First Name *"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="owner_last_name"
                                                                label="Last Name *"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="owner_organization"
                                                                label="Organization"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="owner_title"
                                                                label="Title"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="owner_mailing_address"
                                                                label="Mailing Address *"
                                                                fullWidth
                                                            />
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="owner_city" label="City *" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Select
                                                                disabled={is_read_only}
                                                                field="owner_state"
                                                                label="State *"
                                                                options={states}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="owner_zip" label="Zip *" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="owner_phone" label="Phone *" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="owner_email" label="Email *" fullWidth />
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion defaultExpanded>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        Primary Contact
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <Checkbox disabled={is_read_only} label="Primary contact same as applicant?" field="primary_contact" eventHandle={(e)=>{
                                                                this.setState({primaryContact:e})
                                                                this.formApi.setValue('contact_type', '')
                                                                this.formApi.setValue('secondary_first_name', '')
                                                                this.formApi.setValue('secondary_last_name', '')
                                                                this.formApi.setValue('secondary_organization', '')
                                                                this.formApi.setValue('secondary_title', '')
                                                                this.formApi.setValue('secondary_mailing_address', '')
                                                                this.formApi.setValue('secondary_state', '')
                                                                this.formApi.setValue('secondary_zip', '')
                                                                this.formApi.setValue('secondary_phone', '')
                                                                this.formApi.setValue('secondary_email', '')
                                                                this.formApi.setValue('secondary_city', '')
                                                            }}/>   
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Select
                                                                disabled={is_read_only || primaryContact}
                                                                field="contact_type"
                                                                label="Contact Type"
                                                                options={MAKE_OPTIONS([
                                                                    "Applicant", "Engineer", "Applicant & Engineer", "Property Owner","Other"
                                                                ])}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only || primaryContact}
                                                                field="secondary_first_name"
                                                                label="First Name"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only || primaryContact}
                                                                field="secondary_last_name"
                                                                label="Last Name"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only  || primaryContact}
                                                                field="secondary_organization"
                                                                label="Organization"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                         <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only  || primaryContact}
                                                                field="secondary_title"
                                                                label="Title"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only  || primaryContact}
                                                                field="secondary_mailing_address"
                                                                label="Mailing Address"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only  || primaryContact} field="secondary_city" label="City" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Select
                                                                disabled={is_read_only  || primaryContact}
                                                                field="secondary_state"
                                                                label="State"
                                                                options={states}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only  || primaryContact} field="secondary_zip" label="Zip" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only  || primaryContact} field="secondary_phone" label="Phone" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only  || primaryContact} field="secondary_email" label="Email" fullWidth />
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>{" "}
                                            <Accordion defaultExpanded>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        Sediment and Erosion Control Contact (if available)
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="sediment_first_name"
                                                                label="First Name"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="sediment_last_name"
                                                                label="Last Name"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="sediment_organization"
                                                                label="Organization"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="sediment_title"
                                                                label="Title"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only}
                                                                field="sediment_mailing_address"
                                                                label="Mailing Address"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="sediment_city" label="City" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Select
                                                                disabled={is_read_only}
                                                                field="sediment_state"
                                                                label="State"
                                                                options={states}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="sediment_zip" label="Zip" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="sediment_phone" label="Phone" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only} field="sediment_email" label="Email" fullWidth />
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>{" "}
                                        </Grid>
                                    )}
                                    {activeStep === "PRINT" && (
                                        <>
                                            <div style={{ height: 50 }}></div>
                                            <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                                <Divider className={classes.printDivider} />
                                            </Grid>
                                        </>
                                    )}
                                    {activeStep === 2 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}> 
                                            <Typography variant="subtitle1" gutterBottom>
                                                <b>Please Provide Project Information</b>
                                            </Typography>
                                            <Grid container spacing={2}> 
                                                <Grid container spacing={2} style={{ padding: 10 }}>
                                                    <Grid item xs={6}>
                                                        <Checkbox disabled={is_read_only} label="Project doesnt have an address?" field="doesnt_have_address" eventHandle={(e)=>{
                                                            this.setState({hasAddress:!e})
                                                        }} />   
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                    </Grid>
                                                    {hasAddress &&(<>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                label={
                                                                    <HelpLabel
                                                                        inputLabel="Project Address*"
                                                                        helpText="Address of site.
                                                                        "
                                                                    />
                                                                }
                                                                field="project_address"
                                                                disabled={is_read_only}
                                                                fullWidth
                                                            
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Select
                                                                disabled={is_read_only}
                                                                field="project_city"
                                                                label="City Name *"
                                                                options={MAKE_OPTIONS([
                                                                    "Bloomington","Eden Prairie","Edina","Hopkins","Minnetonka","Richfield", "Minneapolis"
                                                                ])}
                                                                fullWidth
                                                                
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                label="Project Zip" 
                                                                field="project_zip"
                                                                disabled={is_read_only}
                                                                fullWidth
                                                            
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}> 
                                                        </Grid>
                                                    </>  
                                                    )}
                                                    {!hasAddress &&(
                                                        <>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    label={
                                                                        <HelpLabel
                                                                            inputLabel="Street Name "
                                                                            helpText="Street of Project.
                                                                            "
                                                                        />
                                                                    }
                                                                    field="project_address"
                                                                    disabled={is_read_only}
                                                                    fullWidth
                                                                
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}> 
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                            <TextField
                                                                    label={
                                                                        <HelpLabel
                                                                            inputLabel="Starting Roadway"
                                                                            helpText="Starting Roadway.
                                                                            "
                                                                        />
                                                                    }
                                                                    field="roadway1"
                                                                    disabled={is_read_only}
                                                                    fullWidth
                                                                
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                            <TextField
                                                                    label={
                                                                        <HelpLabel
                                                                            inputLabel="Ending Roadway "
                                                                            helpText="Ending Roadway.
                                                                            "
                                                                        />
                                                                    }
                                                                    field="roadway2"
                                                                    disabled={is_read_only}
                                                                    fullWidth
                                                                
                                                                />
                                                            </Grid>
                                                            
                                                        </>
                                                    )}
                                                    <Grid item xs={6}>
                                                        <Button
                                                            onClick={() => this.checkAddress()}
                                                            disabled={is_read_only}
                                                            variant="contained"
                                                            color="primary"
                                                            style={{ marginLeft: 16 }}>
                                                            Verify Address
                                                        </Button>
                                                    </Grid>
                                                </Grid>   
                                            </Grid>
                                        </Grid>
                                    )}
                                    {activeStep === "PRINT" && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Divider className={classes.printDivider} />
                                        </Grid>
                                    )}  
                                    {activeStep === 3 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}> 
                                            <Typography variant="subtitle1" gutterBottom>
                                                <b>Please Provide Project Details</b>
                                            </Typography>
                                            <Grid container spacing={2}> 
                                                <Grid container spacing={2} style={{ padding: 10 }}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Project Name*"
                                                                    helpText="Name of Project.
                                                                    "
                                                                />
                                                            }
                                                            field="project_name"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Select
                                                            disabled={is_read_only}
                                                            field="project_type"
                                                            label="Project Type"
                                                            options={MAKE_OPTIONS(["Commercial/Industrial","Multi-Residential","Private School",
                                                            "Private Utilities","Public","Public Utilities","Roads","Sediment Cleanout","Single Family Home"])}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                               
                                               
                                                <Grid item xs={6}>
                                                        <TextField
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Brief Description*"
                                                                    helpText="Describe what will be changed or added
                                                                    "
                                                                />
                                                            }
                                                            field="project_description"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        field="proposed_work"
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Proposed Work *"
                                                                helpText="Please be specific when describing your project.
                                                                "
                                                            />
                                                        }
                                                        multiline
                                                        rows={8}
                                                        disabled={is_read_only}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <br />
                                                        <hr />
                                                    <br />
                                                </Grid>
                                                <Grid container spacing={2} style={{ padding: 10 }}>
                                                    <Grid item xs={6}>
                                                        <DatePicker
                                                            field="construction_start"
                                                            label="Planned Construction Start Date"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <DatePicker
                                                            field="construction_end"
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Planned Construction End Date"
                                                                    helpText="By default, a permit issued by the NMCWD is valid for one year from the date of approval. If you know your project will require greater than a year to complete, please request additional time."
                                                                />
                                                            }
                                                            disabled={is_read_only}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2} style={{ padding: 10 }}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                        useNumberFormat3
                                                        label="Size of site (parcels under common or related ownership)"
                                                        field="project_size"
                                                        unitsVisual="acres"
                                                        disabled={is_read_only}
                                                        fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}> 
                                                        <TextField
                                                            useNumberFormat3
                                                            label="Total area of land disturbance *"
                                                            field="area_disturbance"
                                                            unitsVisual="sqft"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                             
                                                <Grid container spacing={2} style={{ padding: 10 }}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            useNumberFormat3
                                                            label="Volume of excavation *"
                                                            field="volume_evacuation"
                                                            unitsVisual="cu/ft"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}> 
                                                        <TextField
                                                            useNumberFormat3
                                                            label="Total area of pre-construction impervious surface *"
                                                            field="area_preimpervious"
                                                            unitsVisual="sqft"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                        /> 
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2} style={{ padding: 10 }}>
                                                    <Grid item xs={6}>
                                                    <TextField
                                                            useNumberFormat3
                                                            label="Total disturbed impervious" 
                                                            field="area_proposed"
                                                            unitsVisual="sqft"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            useNumberFormat3
                                                            label="New increase in site impervious area"
                                                            field="increase_size"
                                                            unitsVisual="sqft"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                                
                                                <Grid container spacing={2} style={{ padding: 10 }}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            useNumberFormat3
                                                            label="Total area of post-construction impervious surface *"
                                                            field="area_postimpervious"
                                                            unitsVisual="sqft"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid> 
                                                <Grid container spacing={2} style={{ padding: 10 }}>
                                                    <Grid item xs={6}>
                                                        <RadioGroup
                                                            field="wetland_present"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                            options={[
                                                                { label: "Yes", value: "Yes" },
                                                                { label: "No", value: "No" },
                                                                { label: "Not Sure", value: "Not Sure" },
                                                            ]}
                                                            alignment={true}
                                                            label="Is there a wetland present on or adjacent to the property *"
                                                            
                                                        />
                                                    </Grid>
                                                </Grid>  
                                                <Grid container spacing={2} style={{ padding: 10 }}>
                                                    <Grid item xs={6}>
                                                        <RadioGroup
                                                            field="wetland_delineated"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                            options={[
                                                                { label: "Yes", value: "Yes" },
                                                                { label: "No", value: "No" },
                                                            ]}
                                                            alignment={true}
                                                            label="Have you delineated the wetland(s)"
                                                            
                                                        />
                                                    </Grid>
                                                </Grid>  
                                            </Grid>
                                        </Grid>
                                    )}
                                    {activeStep === 4 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Typography gutterBottom>
                                                <br /> 
                                                If you are experiencing issues with the online application please manually fill out and send a{" "} 
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="https://www.ninemilecreek.org/permits/">
                                                    Permit Application
                                                </a>{" "} to 12800 Gerard Dr, Eden Prairie 55436. Please see{" "}
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="https://www.ninemilecreek.org/wp-content/uploads/NMCWD-Rules-Adopted-12.21.22.pdf">
                                                    Nine Mile Creek District Rules
                                                </a>{" "} for our current rule language.


                                            </Typography>
                                            {/* <Typography gutterBottom>
                                            <br />
                                            Rule guidance materials and additional information can be found at:
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href="https://srwdmn.org/wp-content/uploads/2021/03/2021.3.17-SRWD-Permit-Packet-Fillable-Comments.pdf">
                                                https://srwdmn.org/wp-content/uploads/2021/03/2021.3.17-SRWD-Permit-Packet-Fillable-Comments.pdf
                                            </a>
                                        </Typography> */}
                                            <CheckboxGroup
                                                field="rules"
                                                label="Applicable rule section (check all that apply)"
                                                row={true}
                                                width={205}
                                                disabled={is_read_only}
                                                options={MAKE_OPTIONS_LINK(options_Link)}
                                                
                                            /> 
                                            <Grid container spacing={2} style={{ padding: 10 }}>
                                                <Grid item xs={6}>
                                                    <ReactSelect
                                                        label="Select all other agencies from which a permit has been applied for"
                                                        field="permits_applied"
                                                        options={MAKE_OPTIONS([
                                                            "City",
                                                            "County",
                                                            "MN Pollution Control Agency",
                                                            "MN Department of Natural Resources",
                                                            "Army Corps of Engineers",
                                                            "Other",
                                                            "None",
                                                        ])}
                                                        disabled={is_read_only}
                                                        isMulti={true}
                                                        height="auto"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <ReactSelect
                                                        label="Select all other agencies from which a permit has been received"
                                                        field="permits_received"
                                                        options={MAKE_OPTIONS([
                                                            "City",
                                                            "County",
                                                            "MN Pollution Control Agency",
                                                            "MN Department of Natural Resources",
                                                            "Army Corps of Engineers",
                                                            "Other",
                                                            "None",
                                                        ])}
                                                        disabled={is_read_only}
                                                        isMulti={true}
                                                        height="auto"
                                                        fullWidth
                                                    />
                                                </Grid>
                                             </Grid>
                                            <Grid container spacing={2} style={{ padding: 10 }}>
                                                <Grid item xs={6}>
                                                    <RadioGroup
                                                        field="public_or_private"
                                                        disabled={is_read_only}
                                                        fullWidth
                                                        options={[
                                                            { label: "Public", value: "Public" },
                                                            { label: "Private", value: "Private" },
                                                        ]}
                                                        alignment={true}
                                                        label="Is application for a public or private entity? *"
                                                        
                                                    />
                                                </Grid>
                                            </Grid>  
                                        </Grid>
                                     
                                    )}
                                    {activeStep === "PRINT" && (
                                        <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                            <Divider className={classes.printDivider} />
                                        </Grid>
                                    )}

                                    {activeStep === 5 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Typography variant="subtitle1"><b>Attachments</b></Typography>
                                            <Typography gutterBottom>
                                                Please refer to “Required Information and Exhibits” section in each Rule triggered for information on required attachments. 
                                                Please attach all electronic files associated with your applications. This could include but is not
                                                limited to signed permit form, project plans, project narrative, stormwater volume, rate, and quality modeling in their respective native form, draft maintenance declaration/agreement, 
                                                stormwater management plan, chloride management plan, other agency approvals, geotechnical evaluation, wetland delineation report… 
                                            </Typography>
                                            <Grid container spacing={2} style={{ padding: 10 }}>
                                                <Grid item xs={2} style={{display:"grid", paddingLeft:0, paddingTop:25,height:35}}>
                                                    <Button
                                                        onClick={() =>
                                                            this.setState({
                                                                attachmentDialogOpen: true,
                                                                attachmentEditing: null,
                                                                attachmentFormKey: window.performance.now(),
                                                            })
                                                        }
                                                        // disabled={is_read_only}
                                                        variant="contained"
                                                        color="primary">
                                                        Add Attachment
                                                    </Button>  
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography gutterBottom style={{paddingTop:10}}>
                                                        <b>Required attachment types to submit an application:</b><br/> 1.) Site Plans<br/> 2.) Project Narrative <br/> 3.) Property Owners Authorization (only required if property owners authorization checkbox was checked--step 2 <a
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                href="https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fwww.ninemilecreek.org%2Fwp-content%2Fuploads%2FProperty-Owner-Authorization.docx&wdOrigin=BROWSELINK">
                                                                Property Owners Authorization
                                                            </a>)
                                                    </Typography>
                                                </Grid> 
                                            </Grid> 
                                            <Table style={{ marginTop: 8 }}>
                                                <EnhancedTableHead
                                                    columnData={columnDataAttachments}
                                                    order={attachmentOrder}
                                                    orderBy={attachmentOrderBy}
                                                    onRequestSort={this.handleRequestSort2}
                                                />
                                                <TableBody>
                                                    {temp_attachments.map((n) => {
                                                        return (
                                                            <TableRow hover>
                                                                <CustomTableCell className={classes.nowrap}>
                                                                    {n.file.includes("https") && (
                                                                        <Tooltip title="Open Link">
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={() => {
                                                                                    window.open(n.file);
                                                                                }}
                                                                                className={classes.deleteWidth}>
                                                                                <ViewIcon color="primary" />
                                                                            </Button>
                                                                        </Tooltip>
                                                                    )}
                                                                    <Tooltip title="Delete">
                                                                        <Button
                                                                            color="primary"
                                                                            onClick={() => {
                                                                             return(this.setState({
                                                                                    temp_attachments: temp_attachments.filter((tc) => tc !== n)
                                                                                }),
                                                                                ormNineMilePermitAttachmentDelete(n.id))
                                                                                 
                                                                            }
                                                                            }
                                                                            // disabled={is_read_only}
                                                                            className={classes.deleteWidth}>
                                                                            <DeleteIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </CustomTableCell>
                                                                <CustomTableCell>{n.name}</CustomTableCell>
                                                                <CustomTableCell>{n._type}</CustomTableCell>
                                                                <CustomTableCell>{n.notes}</CustomTableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                    {temp_attachments.length < 1 && (
                                                        <TableRow>
                                                            <CustomTableCell colSpan={columnDataAttachments.length} className={classes.centerAlign}>
                                                                No Attachments Added
                                                            </CustomTableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    )}
                                    {activeStep === "PRINT" && (
                                        <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                            <Divider className={classes.printDivider} />
                                        </Grid>
                                    )}
                                    {activeStep === 6 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Typography variant="subtitle1" gutterBottom style={{paddingLeft:1}}>
                                                <b><u>Review the following</u></b>
                                            </Typography>
                                            <Grid container spacing={2} >
                                                    <Typography gutterBottom style={{paddingLeft:10, paddingBottom:10}}>
                                                        I hereby make an application for a NMCWD permit to proceed with the proposed activities described above and have attached supporting maps, plans, and other information and all necessary fees. 
                                                        The information submitted and statements made concerning this application are true and correct to the best of my knowledge. I understand that I am not authorized to undertake any of the 
                                                        activities that are the subject of this application unless and until NMCWD issues a permit for such activities. I further represent that I possess the authority and necessary property-use rights 
                                                        to undertake the proposed activities or I am acting as the duly authorized agent of the property owner, in which case I have attached a completed, 
                                                        signed owner agent form. By submitting this application, I authorize NMCWD and its agents, employees and contractors to enter the property that is the site of the proposed 
                                                        activities to perform inspections of the site and activities occurring thereon.
                                                    </Typography>
                                                    <Grid item xs={12}>
                                                        <Typography gutterBottom>
                                                            Please type your name if you agree with the above paragraph.
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6} md={4}>
                                                        <TextField
                                                            label="Applicant Signature *"
                                                            field="typedname"
                                                            disabled={is_read_only}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                 
                                                    
                                            </Grid>  
                                        </Grid>
                                    )}
                                    {activeStep !== "PRINT" && (
                                        <Grid item xs={12} xl={10} className={classes.rightAlign} classes={{ root: classes.root }}>
                                            {activeStep <= 6 && (  
                                                <Button
                                                disabled={activeStep <= 0 || is_read_only}
                                                onClick={() => {
                                                    // let fname = this.formApi.getValue('owner_first_name')
                                                    // let lname = this.formApi.getValue('owner_last_name')
                                                    // let em = this.formApi.getValue('owner_email')
                                                    // let saveObj = {owner_first_name:fname, owner_last_name:lname, owner_email:em}
                                                    this.setState({saveDialogOpen:true})// saveInfo:saveObj})
                                                }}
                                                variant="contained"
                                                color="primary" 
                                                style={{ marginRight: 16 }}>
                                                Save and Resume Later
                                            </Button>
                                            )}  
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={() => this.setState({ activeStep: activeStep - 1 })}
                                                className={classes.button}>
                                                Back
                                            </Button>
                                            <Button
                                                onClick={() => this.handleNext()}
                                                disabled={is_read_only && activeStep === 6}
                                                variant="contained"
                                                color="primary"
                                                style={{ marginLeft: 16 }}>
                                                {activeStep === 6 ? "Submit Permit Application" : "Next"}
                                            </Button>
                                            {appData && appData.apprec && (appData.apprec.status !== "" && appData.apprec.status !== 'Save Not Completed') && activeStep === 5 && (
                                                <Button
                                                onClick={() => this.resubmitAttachments()}
                                                // disabled={is_read_only || appData.apprec.status === 'Save Not Completed'}
                                                variant="contained"
                                                color="primary"
                                                style={{ marginLeft: 16 }}>
                                                Resubmit Attachments
                                            </Button>
                                            )}
                                            
                                            {/* <Button
                                                style={{ marginLeft: 16 }}
                                                onClick={() => {
                                                    return (
                                                        // this.setState({ activeStep: "PRINT" }),
                                                        window.open("/reports/application/" + application.hash)
                                                    );
                                                }}
                                                variant="contained"
                                                color="primary">
                                                Print
                                            </Button> */}
                                        </Grid>
                                    )}
                                    <Dialog open={saveDialogOpen} classes={{ paper: classes.minWidth }}>
                                        <Toolbar style={{ backgroundColor: "lightgray" }}>
                                            <Typography variant="h6" className={classes.flex}>
                                                Save Progress and Resume Later? 
                                            </Typography>
                                            <IconButton aria-label="Close Dialog" onClick={() => this.setState({ saveDialogOpen: false })}>
                                                <Close />
                                            </IconButton>
                                        </Toolbar>
                                        <Toolbar>
                                            <Typography variant="h7" className={classes.flex}>
                                                Note: An email will be sent to the below email address where a link will be provided to resume this application. Keep in mind the application will not be processed until its fully filled out and submitted (i.e. step 7).
                                            </Typography>
                                        </Toolbar>
                                        <DialogContent className={classes.dialogMin}>
                                            <DialogContentText>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="owner_first_name"
                                                            label="First Name *"
                                                            fullWidth
                                                            eventHandle={(e)=>
                                                                this.formApi.setValue('owner_first_name', e)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            disabled={is_read_only}
                                                            field="owner_last_name"
                                                            label="Last Name *"
                                                            fullWidth
                                                            eventHandle={(e)=>
                                                            this.formApi.setValue('owner_last_name', e)}
                                                        />
                                                    </Grid> 
                                                    <Grid item xs={12}>
                                                        <TextField disabled={is_read_only} field="owner_email" label="Email *" fullWidth   eventHandle={(e)=>
                                                            this.formApi.setValue('owner_email', e)} />
                                                    </Grid>
                                                
                                                    <Grid item xs={6}>
                                                        <Button  fullWidth onClick={() => this.setState({ saveDialogOpen: false})}>
                                                            Cancel
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Button  disabled={is_read_only} fullWidth type="submit" variant="contained" color="primary"
                                                        onClick={()=>{
                                                            this.setState({submittedSave:true},()=>{
                                                            this.formApi.submitForm("Save Not Completed")})
                                                        }}>
                                                            Submit
                                                        </Button>
                                                    </Grid>
                                                </Grid> 
                                            </DialogContentText>
                                        </DialogContent>
                                    </Dialog>
                                </Grid>
                            </>
                        </form>
                    )}
                </Form>
                <Dialog open={attachmentDialogOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.flex}>
                            Attachment
                        </Typography>
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({ attachmentDialogOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <DialogContentText>
                            <Form
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                key={attachmentFormKey}
                                defaultValues={attachmentEditing}
                                validateError={this.errorValidatorAttachment}
                                onSubmit={(values) => this.addAttachment(values)}>
                                {(formApi) => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography gutterBottom>
                                                    Only these file types can be directly uploaded as an attachment. All other file types should be
                                                    put into a .zip file and then uploaded. Direct supported file types are: PDF (.pdf), Microsoft
                                                    Word (.doc, .docx), Microsoft Excel (.xls, .xlsx), Images (.png, .jpg, .jpeg, .tiff, .bmp),
                                                    Microsoft Powerpoint (.ppt, .pptx), Zip files (.zip).
                                                </Typography>
                                                <Typography gutterBottom style={{paddingTop:10}}>
                                                    <b>Required attachment types to submit an application:</b><br/> 1.) Site Plans <br/> 2.) Project Narrative <br/> 3.) Home Owners Consent (only required if homeowners consent checkbox was checked--step 2 <a
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                href="https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fwww.ninemilecreek.org%2Fwp-content%2Fuploads%2FProperty-Owner-Authorization.docx&wdOrigin=BROWSELINK">
                                                                Home Owners Authorization
                                                            </a>)
                                                </Typography>
                                                <br/>
                                                <FileInput field="file" id="file_upl" fullWidth label="File *" />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField field="name" label="Name *" fullWidth />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Select
                                                    field="_type"
                                                    label="Type"
                                                    options={MAKE_OPTIONS([
                                                        "Site Plans",
                                                        "Project Narrative",
                                                        "Home Owners Consent",
                                                        "Water Quantity and Quality Modeling in their respective Narrative Form",
                                                        "Draft Maintenance Declaration/Agreement",
                                                        "Stormwater Management Plan",
                                                        "Chloride Management Plan",  
                                                    ])}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField field="notes" label="Notes" fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth onClick={() => this.setState({ attachmentDialogOpen: false })}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid> 
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
      
                 <WarningDialog
                    confirmAction={() => {
                        this.setState({ outOfDistrict: false });
                   
                    }}
                    cancelAction={(e) => {
                        this.setState({ outOfDistrict: false});
                    }}
                    noDeleteButtons
                    confirmText="Ok" 
                    open={outOfDistrict}
                    title="Confirm Address is within District Boundaries"
                    text={
                        "For sites located within close proximity to the approximate NMCWD boundary identified on this map, the applicant must verify the legal property boundary with District staff."
                    }
                />
            </AppContainerApplication>
        );
    }
}

NineMilePermit_ = connect(
    (state, ownProps) => ({
        // application: getApplication(state, ownProps),
        authState: state.auth, // Admins will have an authstate
    }),
    {
        ...ApplicationRecord.actions,
        ...ApplicationRecordStatus.actions,
        ...NineMilePermit.actions,
        ...NineMilePermitAttachment.actions, 
    }
)(NineMilePermit_);

export default withStyles(styles)(withRouter(NineMilePermit_));
