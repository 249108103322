import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core";

import Submit from "../common/Submit";
import TextField from "../common/TextField";
import * as authActions from "./actions";
import { Permission } from "../page/models";
import * as navActions from "../common/actions";

const styles = {
    loginError: {
        color: "red",
        textAlign: "center",
    },
    marginBottomButton: {
        marginBottom: 24,
    },
    linkColor: {
        float: "right",
        marginTop: 16,
    },
};
const getdashboardPermissions = Permission.selectAll();

class LoginPage extends Component {
    logOut = () => {
        this.props.authLogout();
    };

    continueDashboard = () => {
        if (this.props.authState.user.default_dashboard) this.props.history.push("/dashboard/" + this.props.authState.user.default_dashboard);
        else this.props.history.push("/dashboard");
    };

    errorValidator = (values) => {
        const validateUsername = (value) => {
            return !value ? "Username is required." : null;
        };
        const validatePassword = (value) => {
            return !value ? "Password is required." : null;
        };
        return {
            username: validateUsername(values.username),
            password: validatePassword(values.password),
        };
    };

    loginSubmit = (values, e, formApi) => {
        //we delete cookies each time a user logs in
        var cook = document.cookie;
        var splitCookie = cook.split(";");
        splitCookie.forEach((c) => {
            document.cookie = `${c}; path=/; expires=Wed, 12 Oct 2000 08:50:17 UTC`;
        });
        //from actions.js file
        this.props.authLogin(values).then(() => {
            if (this.props.authState.error) {
                formApi.setValue("password", "");
            } else if (this.props.authState.user) {
                this.props.setMapSettings({
                    lat: null,
                    lng: null,
                    zoom: null,
                });
                if (this.props.authState.user.redirect) {
                    this.props.history.push(this.props.authState.user.redirect.replace("#", "") + "?old=True");
                } else if (this.props.location.search) {
                    this.props.history.push(this.props.location.search.replace("?return=", ""));
                } else {
                    if (this.props.authState.user.default_dashboard) {
                        if (this.props.authState.user.city_id === 32) this.props.history.push("/page/249/798/table"); //table direct route for LA only
                        else this.props.history.push("/dashboard/" + this.props.authState.user.default_dashboard);
                    } else {
                        if (this.props.authState.user.city_id === 32) this.props.history.push("/page/249/798/table"); //table direct route for LA only
                        else this.props.history.push("/dashboard");
                    }
                }
            }
        });
    };

    render() {
        const { classes, authState } = this.props;

        if (!authState) return "...";
        return authState.user ? (
            <div>
                <Typography variant="h6" gutterBottom={true}>
                    Welcome, {authState.user.name}
                </Typography>

                <Button onClick={this.continueDashboard} variant="contained" color="primary" fullWidth className={classes.marginBottomButton}>
                    Continue to Dashboard
                </Button>
                <Button onClick={this.logOut} fullWidth>
                    Logout
                </Button>
            </div>
        ) : (
            <Form dontValidateOnMount="true" validateOnSubmit="true" validateError={this.errorValidator} onSubmit={this.loginSubmit}>
                {(formApi) => (
                    <form onSubmit={formApi.submitForm}>
                        <Typography variant="h6" gutterBottom>
                            Existing Users
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField field="username" label="Username" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField field="password" label="Password" type="password" fullWidth />
                            </Grid>
                        </Grid>
                        <Typography className={classes.loginError} gutterBottom>
                            {authState.error}
                        </Typography>
                        <Submit variant="raised" label="Login" gridSize={24} loading={authState.pending} />
                        <Button component={Link} className={classes.linkColor} to="/password/reset">
                            Forgot your password?
                        </Button>
                    </form>
                )}
            </Form>
        );
    }
}
LoginPage.displayName = "LoginPage";
LoginPage = connect((state) => ({ authState: state.auth, dashboardPermissions: getdashboardPermissions(state) }), {
    ...authActions,
    ...navActions,
})(LoginPage);

export default withStyles(styles)(withRouter(LoginPage));
