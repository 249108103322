import React from "react";

import * as MUIcon from '@material-ui/icons';

export function MAKE_OPTIONS_ICON(table) {
    return table.map(row => ({
        label: row.component,
        value: row.id
    }));
}


export const ICON_LIST = [
    { id: "AccessAlarm", component: <MUIcon.AccessAlarm /> },
    //{id: "AccessAlarms", component: <MUIcon.AccessAlarms />},
    { id: "AccessibilityNew", component: <MUIcon.AccessibilityNew /> },
    { id: "Accessible", component: <MUIcon.Accessible /> },
    { id: "AccessTime", component: <MUIcon.AccessTime /> },
    { id: "AccountBalance", component: <MUIcon.AccountBalance /> },
    { id: "AccountBalanceWallet", component: <MUIcon.AccountBalanceWallet /> },
    { id: "AccountBox", component: <MUIcon.AccountBox /> },
    { id: "AccountCircle", component: <MUIcon.AccountCircle /> },
    { id: "AcUnit", component: <MUIcon.AcUnit /> },
    { id: "Adb", component: <MUIcon.Adb /> },
    { id: "Add", component: <MUIcon.Add /> },
    { id: "AddAlarm", component: <MUIcon.AddAlarm /> },
    { id: "AddAlert", component: <MUIcon.AddAlert /> },
    { id: "AddAPhoto", component: <MUIcon.AddAPhoto /> },
    { id: "AddBox", component: <MUIcon.AddBox /> },
    { id: "AddLocation", component: <MUIcon.AddLocation /> },
    { id: "AddShoppingCart", component: <MUIcon.AddShoppingCart /> },
    { id: "AddToPhotos", component: <MUIcon.AddToPhotos /> },
    { id: "AddToQueue", component: <MUIcon.AddToQueue /> },
    { id: "Adjust", component: <MUIcon.Adjust /> },
    { id: "AirlineSeatFlat", component: <MUIcon.AirlineSeatFlat /> },
    { id: "AirlineSeatFlatAngled", component: <MUIcon.AirlineSeatFlatAngled /> },
    { id: "AirlineSeatIndividualSuite", component: <MUIcon.AirlineSeatIndividualSuite /> },
    { id: "AirlineSeatLegroomNormal", component: <MUIcon.AirlineSeatLegroomNormal /> },
    { id: "AirlineSeatReclineNormal", component: <MUIcon.AirlineSeatReclineNormal /> },
    { id: "AirplanemodeActive", component: <MUIcon.AirplanemodeActive /> },
    { id: "AirplanemodeInactive", component: <MUIcon.AirplanemodeInactive /> },
    { id: "Airplay", component: <MUIcon.Airplay /> },
    { id: "AirportShuttle", component: <MUIcon.AirportShuttle /> },
    { id: "Alarm", component: <MUIcon.Alarm /> },
    { id: "AlarmAdd", component: <MUIcon.AlarmAdd /> },
    { id: "AlarmOff", component: <MUIcon.AlarmOff /> },
    { id: "AlarmOn", component: <MUIcon.AlarmOn /> },
    { id: "Album", component: <MUIcon.Album /> },
    { id: "AllInclusive", component: <MUIcon.AllInclusive /> },
    { id: "AllOut", component: <MUIcon.AllOut /> },
    { id: "Android", component: <MUIcon.Android /> },
    { id: "Announcement", component: <MUIcon.Announcement /> },
    { id: "Apps", component: <MUIcon.Apps /> },
    { id: "Archive", component: <MUIcon.Archive /> },
    { id: "ArrowBack", component: <MUIcon.ArrowBack /> },
    { id: "ArrowDownward", component: <MUIcon.ArrowDownward /> },
    { id: "ArrowDropDown", component: <MUIcon.ArrowDropDown /> },
    { id: "ArrowDropDownCircle", component: <MUIcon.ArrowDropDownCircle /> },
    { id: "ArrowDropUp", component: <MUIcon.ArrowDropUp /> },
    { id: "ArrowForward", component: <MUIcon.ArrowForward /> },
    { id: "ArrowUpward", component: <MUIcon.ArrowUpward /> },
    { id: "ArtTrack", component: <MUIcon.ArtTrack /> },
    { id: "AspectRatio", component: <MUIcon.AspectRatio /> },
    { id: "Assessment", component: <MUIcon.Assessment /> },
    { id: "Assignment", component: <MUIcon.Assignment /> },
    { id: "AssignmentInd", component: <MUIcon.AssignmentInd /> },
    { id: "AssignmentLate", component: <MUIcon.AssignmentLate /> },
    { id: "AssignmentReturn", component: <MUIcon.AssignmentReturn /> },
    { id: "AssignmentReturned", component: <MUIcon.AssignmentReturned /> },
    { id: "AssignmentTurnedIn", component: <MUIcon.AssignmentTurnedIn /> },
    { id: "Assistant", component: <MUIcon.Assistant /> },
    { id: "AssistantPhoto", component: <MUIcon.AssistantPhoto /> },
    { id: "AttachFile", component: <MUIcon.AttachFile /> },
    { id: "Attachment", component: <MUIcon.Attachment /> },
    { id: "AttachMoney", component: <MUIcon.AttachMoney /> },
    { id: "Audiotrack", component: <MUIcon.Audiotrack /> },
    { id: "Autorenew", component: <MUIcon.Autorenew /> },
    { id: "AvTimer", component: <MUIcon.AvTimer /> },
    { id: "Backspace", component: <MUIcon.Backspace /> },
    { id: "Backup", component: <MUIcon.Backup /> },
    { id: "Battery20", component: <MUIcon.Battery20 /> },
    { id: "Battery30", component: <MUIcon.Battery30 /> },
    { id: "Battery50", component: <MUIcon.Battery50 /> },
    { id: "Battery60", component: <MUIcon.Battery60 /> },
    { id: "Battery80", component: <MUIcon.Battery80 /> },
    { id: "Battery90", component: <MUIcon.Battery90 /> },
    { id: "BatteryAlert", component: <MUIcon.BatteryAlert /> },
    { id: "BatteryCharging20", component: <MUIcon.BatteryCharging20 /> },
    { id: "BatteryCharging30", component: <MUIcon.BatteryCharging30 /> },
    { id: "BatteryCharging50", component: <MUIcon.BatteryCharging50 /> },
    { id: "BatteryCharging60", component: <MUIcon.BatteryCharging60 /> },
    { id: "BatteryCharging80", component: <MUIcon.BatteryCharging80 /> },
    { id: "BatteryCharging90", component: <MUIcon.BatteryCharging90 /> },
    { id: "BatteryChargingFull", component: <MUIcon.BatteryChargingFull /> },
    { id: "BatteryFull", component: <MUIcon.BatteryFull /> },
    { id: "BatteryUnknown", component: <MUIcon.BatteryUnknown /> },
    { id: "BeachAccess", component: <MUIcon.BeachAccess /> },
    { id: "Beenhere", component: <MUIcon.Beenhere /> },
    { id: "Block", component: <MUIcon.Block /> },
    { id: "Bluetooth", component: <MUIcon.Bluetooth /> },
    { id: "BluetoothAudio", component: <MUIcon.BluetoothAudio /> },
    { id: "BluetoothConnected", component: <MUIcon.BluetoothConnected /> },
    { id: "BluetoothDisabled", component: <MUIcon.BluetoothDisabled /> },
    { id: "BluetoothSearching", component: <MUIcon.BluetoothSearching /> },
    { id: "BlurCircular", component: <MUIcon.BlurCircular /> },
    { id: "BlurLinear", component: <MUIcon.BlurLinear /> },
    { id: "BlurOff", component: <MUIcon.BlurOff /> },
    { id: "BlurOn", component: <MUIcon.BlurOn /> },
    { id: "Book", component: <MUIcon.Book /> },
    { id: "Bookmark", component: <MUIcon.Bookmark /> },
    { id: "BookmarkBorder", component: <MUIcon.BookmarkBorder /> },
    { id: "BorderAll", component: <MUIcon.BorderAll /> },
    { id: "BorderBottom", component: <MUIcon.BorderBottom /> },
    { id: "BorderClear", component: <MUIcon.BorderClear /> },
    { id: "BorderColor", component: <MUIcon.BorderColor /> },
    { id: "BorderHorizontal", component: <MUIcon.BorderHorizontal /> },
    { id: "BorderInner", component: <MUIcon.BorderInner /> },
    { id: "BorderLeft", component: <MUIcon.BorderLeft /> },
    { id: "BorderOuter", component: <MUIcon.BorderOuter /> },
    { id: "BorderRight", component: <MUIcon.BorderRight /> },
    { id: "BorderStyle", component: <MUIcon.BorderStyle /> },
    { id: "BorderTop", component: <MUIcon.BorderTop /> },
    { id: "BorderVertical", component: <MUIcon.BorderVertical /> },
    { id: "BrandingWatermark", component: <MUIcon.BrandingWatermark /> },
    { id: "Brightness1", component: <MUIcon.Brightness1 /> },
    { id: "Brightness2", component: <MUIcon.Brightness2 /> },
    { id: "Brightness3", component: <MUIcon.Brightness3 /> },
    { id: "Brightness4", component: <MUIcon.Brightness4 /> },
    { id: "Brightness5", component: <MUIcon.Brightness5 /> },
    { id: "Brightness6", component: <MUIcon.Brightness6 /> },
    { id: "Brightness7", component: <MUIcon.Brightness7 /> },
    { id: "BrightnessAuto", component: <MUIcon.BrightnessAuto /> },
    { id: "BrightnessHigh", component: <MUIcon.BrightnessHigh /> },
    { id: "BrightnessLow", component: <MUIcon.BrightnessLow /> },
    { id: "BrightnessMedium", component: <MUIcon.BrightnessMedium /> },
    { id: "BrokenImage", component: <MUIcon.BrokenImage /> },
    { id: "Brush", component: <MUIcon.Brush /> },
    { id: "BubbleChart", component: <MUIcon.BubbleChart /> },
    { id: "BugReport", component: <MUIcon.BugReport /> },
    { id: "Build", component: <MUIcon.Build /> },
    { id: "BurstMode", component: <MUIcon.BurstMode /> },
    { id: "Business", component: <MUIcon.Business /> },
    { id: "BusinessCenter", component: <MUIcon.BusinessCenter /> },
    { id: "Cached", component: <MUIcon.Cached /> },
    { id: "Cake", component: <MUIcon.Cake /> },
    { id: "Call", component: <MUIcon.Call /> },
    { id: "CallEnd", component: <MUIcon.CallEnd /> },
    { id: "CallMade", component: <MUIcon.CallMade /> },
    { id: "CallMerge", component: <MUIcon.CallMerge /> },
    { id: "CallMissed", component: <MUIcon.CallMissed /> },
    { id: "CallMissedOutgoing", component: <MUIcon.CallMissedOutgoing /> },
    { id: "CallReceived", component: <MUIcon.CallReceived /> },
    { id: "CallSplit", component: <MUIcon.CallSplit /> },
    { id: "CallToAction", component: <MUIcon.CallToAction /> },
    { id: "Camera", component: <MUIcon.Camera /> },
    { id: "CameraAlt", component: <MUIcon.CameraAlt /> },
    { id: "CameraEnhance", component: <MUIcon.CameraEnhance /> },
    { id: "CameraFront", component: <MUIcon.CameraFront /> },
    { id: "CameraRear", component: <MUIcon.CameraRear /> },
    { id: "CameraRoll", component: <MUIcon.CameraRoll /> },
    { id: "Cancel", component: <MUIcon.Cancel /> },
    { id: "CardGiftcard", component: <MUIcon.CardGiftcard /> },
    { id: "CardMembership", component: <MUIcon.CardMembership /> },
    { id: "CardTravel", component: <MUIcon.CardTravel /> },
    { id: "Casino", component: <MUIcon.Casino /> },
    { id: "Cast", component: <MUIcon.Cast /> },
    { id: "CastConnected", component: <MUIcon.CastConnected /> },
    { id: "CenterFocusStrong", component: <MUIcon.CenterFocusStrong /> },
    { id: "CenterFocusWeak", component: <MUIcon.CenterFocusWeak /> },
    { id: "ChangeHistory", component: <MUIcon.ChangeHistory /> },
    { id: "Chat", component: <MUIcon.Chat /> },
    { id: "ChatBubble", component: <MUIcon.ChatBubble /> },
    { id: "Check", component: <MUIcon.Check /> },
    { id: "CheckBox", component: <MUIcon.CheckBox /> },
    { id: "CheckBoxOutlineBlank", component: <MUIcon.CheckBoxOutlineBlank /> },
    { id: "CheckCircle", component: <MUIcon.CheckCircle /> },
    { id: "ChevronLeft", component: <MUIcon.ChevronLeft /> },
    { id: "ChevronRight", component: <MUIcon.ChevronRight /> },
    { id: "ChildCare", component: <MUIcon.ChildCare /> },
    { id: "ChildFriendly", component: <MUIcon.ChildFriendly /> },
    { id: "ChromeReaderMode", component: <MUIcon.ChromeReaderMode /> },
    { id: "Class", component: <MUIcon.Class /> },
    { id: "Clear", component: <MUIcon.Clear /> },
    { id: "ClearAll", component: <MUIcon.ClearAll /> },
    { id: "Close", component: <MUIcon.Close /> },
    { id: "ClosedCaption", component: <MUIcon.ClosedCaption /> },
    { id: "Cloud", component: <MUIcon.Cloud /> },
    { id: "CloudCircle", component: <MUIcon.CloudCircle /> },
    { id: "CloudDone", component: <MUIcon.CloudDone /> },
    { id: "CloudDownload", component: <MUIcon.CloudDownload /> },
    { id: "CloudOff", component: <MUIcon.CloudOff /> },
    { id: "CloudQueue", component: <MUIcon.CloudQueue /> },
    { id: "CloudUpload", component: <MUIcon.CloudUpload /> },
    { id: "Code", component: <MUIcon.Code /> },
    { id: "Collections", component: <MUIcon.Collections /> },
    { id: "CollectionsBookmark", component: <MUIcon.CollectionsBookmark /> },
    { id: "Colorize", component: <MUIcon.Colorize /> },
    { id: "ColorLens", component: <MUIcon.ColorLens /> },
    { id: "Comment", component: <MUIcon.Comment /> },
    { id: "Compare", component: <MUIcon.Compare /> },
    { id: "CompareArrows", component: <MUIcon.CompareArrows /> },
    { id: "Computer", component: <MUIcon.Computer /> },
    { id: "ConfirmationNumber", component: <MUIcon.ConfirmationNumber /> },
    { id: "ContactMail", component: <MUIcon.ContactMail /> },
    { id: "ContactPhone", component: <MUIcon.ContactPhone /> },
    { id: "Contacts", component: <MUIcon.Contacts /> },
    { id: "ControlPoint", component: <MUIcon.ControlPoint /> },
    { id: "ControlPointDuplicate", component: <MUIcon.ControlPointDuplicate /> },
    { id: "Copyright", component: <MUIcon.Copyright /> },
    { id: "Create", component: <MUIcon.Create /> },
    { id: "CreateNewFolder", component: <MUIcon.CreateNewFolder /> },
    { id: "CreditCard", component: <MUIcon.CreditCard /> },
    { id: "Crop", component: <MUIcon.Crop /> },
    { id: "Crop169", component: <MUIcon.Crop169 /> },
    { id: "Crop32", component: <MUIcon.Crop32 /> },
    { id: "Crop54", component: <MUIcon.Crop54 /> },
    { id: "Crop75", component: <MUIcon.Crop75 /> },
    { id: "CropDin", component: <MUIcon.CropDin /> },
    { id: "CropFree", component: <MUIcon.CropFree /> },
    { id: "CropLandscape", component: <MUIcon.CropLandscape /> },
    { id: "CropOriginal", component: <MUIcon.CropOriginal /> },
    { id: "CropPortrait", component: <MUIcon.CropPortrait /> },
    { id: "CropRotate", component: <MUIcon.CropRotate /> },
    { id: "CropSquare", component: <MUIcon.CropSquare /> },
    { id: "Dashboard", component: <MUIcon.Dashboard /> },
    { id: "DataUsage", component: <MUIcon.DataUsage /> },
    { id: "DateRange", component: <MUIcon.DateRange /> },
    { id: "Dehaze", component: <MUIcon.Dehaze /> },
    { id: "Delete", component: <MUIcon.Delete /> },
    { id: "DeleteForever", component: <MUIcon.DeleteForever /> },
    { id: "DeleteSweep", component: <MUIcon.DeleteSweep /> },
    { id: "Description", component: <MUIcon.Description /> },
    { id: "DesktopMac", component: <MUIcon.DesktopMac /> },
    { id: "DesktopWindows", component: <MUIcon.DesktopWindows /> },
    { id: "Details", component: <MUIcon.Details /> },
    { id: "DeveloperBoard", component: <MUIcon.DeveloperBoard /> },
    { id: "DeveloperMode", component: <MUIcon.DeveloperMode /> },
    { id: "DeviceHub", component: <MUIcon.DeviceHub /> },
    { id: "Devices", component: <MUIcon.Devices /> },
    { id: "DevicesOther", component: <MUIcon.DevicesOther /> },
    { id: "DialerSip", component: <MUIcon.DialerSip /> },
    { id: "Dialpad", component: <MUIcon.Dialpad /> },
    { id: "Directions", component: <MUIcon.Directions /> },
    { id: "DirectionsBike", component: <MUIcon.DirectionsBike /> },
    { id: "DirectionsBoat", component: <MUIcon.DirectionsBoat /> },
    { id: "DirectionsBus", component: <MUIcon.DirectionsBus /> },
    { id: "DirectionsCar", component: <MUIcon.DirectionsCar /> },
    { id: "DirectionsRailway", component: <MUIcon.DirectionsRailway /> },
    { id: "DirectionsRun", component: <MUIcon.DirectionsRun /> },
    { id: "DirectionsSubway", component: <MUIcon.DirectionsSubway /> },
    { id: "DirectionsTransit", component: <MUIcon.DirectionsTransit /> },
    { id: "DirectionsWalk", component: <MUIcon.DirectionsWalk /> },
    { id: "DiscFull", component: <MUIcon.DiscFull /> },
    { id: "Dns", component: <MUIcon.Dns /> },
    { id: "Dock", component: <MUIcon.Dock /> },
    { id: "Domain", component: <MUIcon.Domain /> },
    { id: "Done", component: <MUIcon.Done /> },
    { id: "DoneAll", component: <MUIcon.DoneAll /> },
    { id: "DonutLarge", component: <MUIcon.DonutLarge /> },
    { id: "DonutSmall", component: <MUIcon.DonutSmall /> },
    { id: "Drafts", component: <MUIcon.Drafts /> },
    { id: "DragHandle", component: <MUIcon.DragHandle /> },
    { id: "DriveEta", component: <MUIcon.DriveEta /> },
    { id: "Dvr", component: <MUIcon.Dvr /> },
    { id: "Edit", component: <MUIcon.Edit /> },
    { id: "EditLocation", component: <MUIcon.EditLocation /> },
    { id: "Eject", component: <MUIcon.Eject /> },
    { id: "Email", component: <MUIcon.Email /> },
    { id: "EnhancedEncryption", component: <MUIcon.EnhancedEncryption /> },
    { id: "Equalizer", component: <MUIcon.Equalizer /> },
    { id: "Error", component: <MUIcon.Error /> },
    { id: "EuroSymbol", component: <MUIcon.EuroSymbol /> },
    { id: "Event", component: <MUIcon.Event /> },
    { id: "EventAvailable", component: <MUIcon.EventAvailable /> },
    { id: "EventBusy", component: <MUIcon.EventBusy /> },
    { id: "EventNote", component: <MUIcon.EventNote /> },
    { id: "EventSeat", component: <MUIcon.EventSeat /> },
    { id: "EvStation", component: <MUIcon.EvStation /> },
    { id: "ExitToApp", component: <MUIcon.ExitToApp /> },
    { id: "ExpandLess", component: <MUIcon.ExpandLess /> },
    { id: "ExpandMore", component: <MUIcon.ExpandMore /> },
    { id: "Explicit", component: <MUIcon.Explicit /> },
    { id: "Explore", component: <MUIcon.Explore /> },
    { id: "Exposure", component: <MUIcon.Exposure /> },
    { id: "ExposureNeg1", component: <MUIcon.ExposureNeg1 /> },
    { id: "ExposureNeg2", component: <MUIcon.ExposureNeg2 /> },
    { id: "ExposurePlus1", component: <MUIcon.ExposurePlus1 /> },
    { id: "ExposurePlus2", component: <MUIcon.ExposurePlus2 /> },
    { id: "ExposureZero", component: <MUIcon.ExposureZero /> },
    { id: "Extension", component: <MUIcon.Extension /> },
    { id: "Face", component: <MUIcon.Face /> },
    { id: "FastForward", component: <MUIcon.FastForward /> },
    { id: "FastRewind", component: <MUIcon.FastRewind /> },
    { id: "Favorite", component: <MUIcon.Favorite /> },
    { id: "FavoriteBorder", component: <MUIcon.FavoriteBorder /> },
    { id: "FeaturedPlayList", component: <MUIcon.FeaturedPlayList /> },
    { id: "FeaturedVideo", component: <MUIcon.FeaturedVideo /> },
    { id: "Feedback", component: <MUIcon.Feedback /> },
    { id: "FiberDvr", component: <MUIcon.FiberDvr /> },
    { id: "FiberManualRecord", component: <MUIcon.FiberManualRecord /> },
    { id: "FiberNew", component: <MUIcon.FiberNew /> },
    { id: "FiberPin", component: <MUIcon.FiberPin /> },
    { id: "FiberSmartRecord", component: <MUIcon.FiberSmartRecord /> },
    { id: "Filter", component: <MUIcon.Filter /> },
    { id: "Filter1", component: <MUIcon.Filter1 /> },
    { id: "Filter2", component: <MUIcon.Filter2 /> },
    { id: "Filter3", component: <MUIcon.Filter3 /> },
    { id: "Filter4", component: <MUIcon.Filter4 /> },
    { id: "Filter5", component: <MUIcon.Filter5 /> },
    { id: "Filter6", component: <MUIcon.Filter6 /> },
    { id: "Filter7", component: <MUIcon.Filter7 /> },
    { id: "Filter8", component: <MUIcon.Filter8 /> },
    { id: "Filter9", component: <MUIcon.Filter9 /> },
    { id: "Filter9Plus", component: <MUIcon.Filter9Plus /> },
    { id: "FilterBAndW", component: <MUIcon.FilterBAndW /> },
    { id: "FilterCenterFocus", component: <MUIcon.FilterCenterFocus /> },
    { id: "FilterDrama", component: <MUIcon.FilterDrama /> },
    { id: "FilterFrames", component: <MUIcon.FilterFrames /> },
    { id: "FilterHdr", component: <MUIcon.FilterHdr /> },
    { id: "FilterList", component: <MUIcon.FilterList /> },
    { id: "FilterNone", component: <MUIcon.FilterNone /> },
    { id: "FilterTiltShift", component: <MUIcon.FilterTiltShift /> },
    { id: "FilterVintage", component: <MUIcon.FilterVintage /> },
    { id: "FindInPage", component: <MUIcon.FindInPage /> },
    { id: "FindReplace", component: <MUIcon.FindReplace /> },
    { id: "Fingerprint", component: <MUIcon.Fingerprint /> },
    { id: "FirstPage", component: <MUIcon.FirstPage /> },
    { id: "FitnessCenter", component: <MUIcon.FitnessCenter /> },
    { id: "Flag", component: <MUIcon.Flag /> },
    { id: "Flare", component: <MUIcon.Flare /> },
    { id: "FlashAuto", component: <MUIcon.FlashAuto /> },
    { id: "FlashOff", component: <MUIcon.FlashOff /> },
    { id: "FlashOn", component: <MUIcon.FlashOn /> },
    { id: "Flight", component: <MUIcon.Flight /> },
    { id: "FlightLand", component: <MUIcon.FlightLand /> },
    { id: "FlightTakeoff", component: <MUIcon.FlightTakeoff /> },
    { id: "Flip", component: <MUIcon.Flip /> },
    { id: "FlipToBack", component: <MUIcon.FlipToBack /> },
    { id: "FlipToFront", component: <MUIcon.FlipToFront /> },
    { id: "Folder", component: <MUIcon.Folder /> },
    { id: "FolderOpen", component: <MUIcon.FolderOpen /> },
    { id: "FolderShared", component: <MUIcon.FolderShared /> },
    { id: "FolderSpecial", component: <MUIcon.FolderSpecial /> },
    { id: "FontDownload", component: <MUIcon.FontDownload /> },
    { id: "FormatAlignCenter", component: <MUIcon.FormatAlignCenter /> },
    { id: "FormatAlignJustify", component: <MUIcon.FormatAlignJustify /> },
    { id: "FormatAlignLeft", component: <MUIcon.FormatAlignLeft /> },
    { id: "FormatAlignRight", component: <MUIcon.FormatAlignRight /> },
    { id: "FormatBold", component: <MUIcon.FormatBold /> },
    { id: "FormatClear", component: <MUIcon.FormatClear /> },
    { id: "FormatColorFill", component: <MUIcon.FormatColorFill /> },
    { id: "FormatColorReset", component: <MUIcon.FormatColorReset /> },
    { id: "FormatColorText", component: <MUIcon.FormatColorText /> },
    { id: "FormatIndentDecrease", component: <MUIcon.FormatIndentDecrease /> },
    { id: "FormatIndentIncrease", component: <MUIcon.FormatIndentIncrease /> },
    { id: "FormatItalic", component: <MUIcon.FormatItalic /> },
    { id: "FormatLineSpacing", component: <MUIcon.FormatLineSpacing /> },
    { id: "FormatListBulleted", component: <MUIcon.FormatListBulleted /> },
    { id: "FormatListNumbered", component: <MUIcon.FormatListNumbered /> },
    { id: "FormatPaint", component: <MUIcon.FormatPaint /> },
    { id: "FormatQuote", component: <MUIcon.FormatQuote /> },
    { id: "FormatShapes", component: <MUIcon.FormatShapes /> },
    { id: "FormatSize", component: <MUIcon.FormatSize /> },
    { id: "FormatStrikethrough", component: <MUIcon.FormatStrikethrough /> },
    { id: "FormatTextdirectionLToR", component: <MUIcon.FormatTextdirectionLToR /> },
    { id: "FormatTextdirectionRToL", component: <MUIcon.FormatTextdirectionRToL /> },
    { id: "FormatUnderlined", component: <MUIcon.FormatUnderlined /> },
    { id: "Forum", component: <MUIcon.Forum /> },
    { id: "Forward", component: <MUIcon.Forward /> },
    { id: "Forward10", component: <MUIcon.Forward10 /> },
    { id: "Forward30", component: <MUIcon.Forward30 /> },
    { id: "Forward5", component: <MUIcon.Forward5 /> },
    { id: "FreeBreakfast", component: <MUIcon.FreeBreakfast /> },
    { id: "Fullscreen", component: <MUIcon.Fullscreen /> },
    { id: "FullscreenExit", component: <MUIcon.FullscreenExit /> },
    { id: "Functions", component: <MUIcon.Functions /> },
    { id: "Gamepad", component: <MUIcon.Gamepad /> },
    { id: "Games", component: <MUIcon.Games /> },
    { id: "Gavel", component: <MUIcon.Gavel /> },
    { id: "Gesture", component: <MUIcon.Gesture /> },
    { id: "GetApp", component: <MUIcon.GetApp /> },
    { id: "Gif", component: <MUIcon.Gif /> },
    { id: "GolfCourse", component: <MUIcon.GolfCourse /> },
    { id: "GpsFixed", component: <MUIcon.GpsFixed /> },
    { id: "GpsNotFixed", component: <MUIcon.GpsNotFixed /> },
    { id: "GpsOff", component: <MUIcon.GpsOff /> },
    { id: "Grade", component: <MUIcon.Grade /> },
    { id: "Gradient", component: <MUIcon.Gradient /> },
    { id: "Grain", component: <MUIcon.Grain /> },
    { id: "GraphicEq", component: <MUIcon.GraphicEq /> },
    { id: "GridOff", component: <MUIcon.GridOff /> },
    { id: "GridOn", component: <MUIcon.GridOn /> },
    { id: "Group", component: <MUIcon.Group /> },
    { id: "GroupAdd", component: <MUIcon.GroupAdd /> },
    { id: "GroupWork", component: <MUIcon.GroupWork /> },
    {
        id: "GTranslate",
        component: <MUIcon.GTranslate />
    } ,
    {id: "Hd", component: <MUIcon.Hd />},
    {id: "HdrOff", component: <MUIcon.HdrOff />},
    {id: "HdrOn", component: <MUIcon.HdrOn />},
    {id: "HdrStrong", component: <MUIcon.HdrStrong />},
    {id: "HdrWeak", component: <MUIcon.HdrWeak />},
    {id: "Headset", component: <MUIcon.Headset />},
    {id: "HeadsetMic", component: <MUIcon.HeadsetMic />},
    {id: "Healing", component: <MUIcon.Healing />},
    {id: "Hearing", component: <MUIcon.Hearing />},
    {id: "Help", component: <MUIcon.Help />},
    {id: "Highlight", component: <MUIcon.Highlight />},
    {id: "HighlightOff", component: <MUIcon.HighlightOff />},
    {id: "HighQuality", component: <MUIcon.HighQuality />},
    {id: "History", component: <MUIcon.History />},
    {id: "Home", component: <MUIcon.Home />},
    {id: "Hotel", component: <MUIcon.Hotel />},
    {id: "HotTub", component: <MUIcon.HotTub />},
    {id: "HourglassEmpty", component: <MUIcon.HourglassEmpty />},
    {id: "HourglassFull", component: <MUIcon.HourglassFull />},
    {id: "Http", component: <MUIcon.Http />},
    {id: "Https", component: <MUIcon.Https />},
    {id: "Image", component: <MUIcon.Image />},
    {id: "ImageAspectRatio", component: <MUIcon.ImageAspectRatio />},
    {id: "ImportantDevices", component: <MUIcon.ImportantDevices />},
    {id: "ImportContacts", component: <MUIcon.ImportContacts />},
    {id: "ImportExport", component: <MUIcon.ImportExport />},
    {id: "Inbox", component: <MUIcon.Inbox />},
    {id: "IndeterminateCheckBox", component: <MUIcon.IndeterminateCheckBox />},
    {id: "Info", component: <MUIcon.Info />},
    {id: "Input", component: <MUIcon.Input />},
    {id: "InsertChart", component: <MUIcon.InsertChart />},
    {id: "InsertComment", component: <MUIcon.InsertComment />},
    {id: "InsertDriveFile", component: <MUIcon.InsertDriveFile />},
    {id: "InsertEmoticon", component: <MUIcon.InsertEmoticon />},
    {id: "InsertInvitation", component: <MUIcon.InsertInvitation />},
    {id: "InsertLink", component: <MUIcon.InsertLink />},
    {id: "InsertPhoto", component: <MUIcon.InsertPhoto />},
    {id: "InvertColors", component: <MUIcon.InvertColors />},
    {id: "InvertColorsOff", component: <MUIcon.InvertColorsOff />},
    {id: "Iso", component: <MUIcon.Iso />},
    {id: "Keyboard", component: <MUIcon.Keyboard />},
    {id: "KeyboardArrowDown", component: <MUIcon.KeyboardArrowDown />},
    {id: "KeyboardArrowLeft", component: <MUIcon.KeyboardArrowLeft />},
    {id: "KeyboardArrowRight", component: <MUIcon.KeyboardArrowRight />},
    {id: "KeyboardArrowUp", component: <MUIcon.KeyboardArrowUp />},
    {id: "KeyboardBackspace", component: <MUIcon.KeyboardBackspace />},
    {id: "KeyboardCapslock", component: <MUIcon.KeyboardCapslock />},
    {id: "KeyboardHide", component: <MUIcon.KeyboardHide />},
    {id: "KeyboardReturn", component: <MUIcon.KeyboardReturn />},
    {id: "KeyboardTab", component: <MUIcon.KeyboardTab />},
    {id: "KeyboardVoice", component: <MUIcon.KeyboardVoice />},
    {id: "Kitchen", component: <MUIcon.Kitchen />},
    {id: "Label", component: <MUIcon.Label />},
    {id: "Landscape", component: <MUIcon.Landscape />},
    {id: "Language", component: <MUIcon.Language />},
    {id: "Laptop", component: <MUIcon.Laptop />},
    {id: "LaptopChromebook", component: <MUIcon.LaptopChromebook />},
    {id: "LaptopMac", component: <MUIcon.LaptopMac />},
    {id: "LaptopWindows", component: <MUIcon.LaptopWindows />},
    {id: "LastPage", component: <MUIcon.LastPage />},
    {id: "Launch", component: <MUIcon.Launch />},
    {id: "Layers", component: <MUIcon.Layers />},
    {id: "LayersClear", component: <MUIcon.LayersClear />},
    {id: "LeakAdd", component: <MUIcon.LeakAdd />},
    {id: "LeakRemove", component: <MUIcon.LeakRemove />},
    {id: "Lens", component: <MUIcon.Lens />},
    {id: "LibraryAdd", component: <MUIcon.LibraryAdd />},
    {id: "LibraryBooks", component: <MUIcon.LibraryBooks />},
    {id: "LibraryMusic", component: <MUIcon.LibraryMusic />},
    {id: "LinearScale", component: <MUIcon.LinearScale />},
    {id: "LineStyle", component: <MUIcon.LineStyle />},
    {id: "LineWeight", component: <MUIcon.LineWeight />},
    {id: "Link", component: <MUIcon.Link />},
    {id: "LinkedCamera", component: <MUIcon.LinkedCamera />},
    {id: "List", component: <MUIcon.List />},
    {id: "LiveHelp", component: <MUIcon.LiveHelp />},
    {id: "LiveTv", component: <MUIcon.LiveTv />},
    {id: "LocalActivity", component: <MUIcon.LocalActivity />},
    {id: "LocalAirport", component: <MUIcon.LocalAirport />},
    {id: "LocalAtm", component: <MUIcon.LocalAtm />},
    {id: "LocalBar", component: <MUIcon.LocalBar />},
    {id: "LocalCafe", component: <MUIcon.LocalCafe />},
    {id: "LocalCarWash", component: <MUIcon.LocalCarWash />},
    {id: "LocalConvenienceStore", component: <MUIcon.LocalConvenienceStore />},
    {id: "LocalDining", component: <MUIcon.LocalDining />},
    {id: "LocalDrink", component: <MUIcon.LocalDrink />},
    {id: "LocalFlorist", component: <MUIcon.LocalFlorist />},
    {id: "LocalGasStation", component: <MUIcon.LocalGasStation />},
    {id: "LocalGroceryStore", component: <MUIcon.LocalGroceryStore />},
    {id: "LocalHospital", component: <MUIcon.LocalHospital />},
    {id: "LocalHotel", component: <MUIcon.LocalHotel />},
    {id: "LocalLaundryService", component: <MUIcon.LocalLaundryService />},
    {id: "LocalLibrary", component: <MUIcon.LocalLibrary />},
    {id: "LocalMall", component: <MUIcon.LocalMall />},
    {id: "LocalMovies", component: <MUIcon.LocalMovies />},
    {id: "LocalOffer", component: <MUIcon.LocalOffer />},
    {id: "LocalParking", component: <MUIcon.LocalParking />},
    {id: "LocalPharmacy", component: <MUIcon.LocalPharmacy />},
    {id: "LocalPhone", component: <MUIcon.LocalPhone />},
    {id: "LocalPizza", component: <MUIcon.LocalPizza />},
    {id: "LocalPlay", component: <MUIcon.LocalPlay />},
    {id: "LocalPostOffice", component: <MUIcon.LocalPostOffice />},
    {id: "LocalPrintshop", component: <MUIcon.LocalPrintshop />},
    {id: "LocalSee", component: <MUIcon.LocalSee />},
    {id: "LocalShipping", component: <MUIcon.LocalShipping />},
    {id: "LocalTaxi", component: <MUIcon.LocalTaxi />},
    {id: "LocationCity", component: <MUIcon.LocationCity />},
    {id: "LocationDisabled", component: <MUIcon.LocationDisabled />},
    {id: "LocationOff", component: <MUIcon.LocationOff />},
    {id: "LocationOn", component: <MUIcon.LocationOn />},
    {id: "LocationSearching", component: <MUIcon.LocationSearching />},
    {id: "Lock", component: <MUIcon.Lock />},
    {id: "LockOpen", component: <MUIcon.LockOpen />},
    {id: "Looks", component: <MUIcon.Looks />},
    {id: "Looks3", component: <MUIcon.Looks3 />},
    {id: "Looks4", component: <MUIcon.Looks4 />},
    {id: "Looks5", component: <MUIcon.Looks5 />},
    {id: "Looks6", component: <MUIcon.Looks6 />},
    {id: "LooksOne", component: <MUIcon.LooksOne />},
    {id: "LooksTwo", component: <MUIcon.LooksTwo />},
    {id: "Loop", component: <MUIcon.Loop />},
    {id: "Loupe", component: <MUIcon.Loupe />},
    {id: "LowPriority", component: <MUIcon.LowPriority />},
    {id: "Loyalty", component: <MUIcon.Loyalty />},
    {id: "Mail", component: <MUIcon.Mail />},
    {id: "Map", component: <MUIcon.Map />},
    {id: "Markunread", component: <MUIcon.Markunread />},
    {id: "MarkunreadMailbox", component: <MUIcon.MarkunreadMailbox />},
    {id: "Memory", component: <MUIcon.Memory />},
    {id: "Menu", component: <MUIcon.Menu />},
    {id: "MergeType", component: <MUIcon.MergeType />},
    {id: "Message", component: <MUIcon.Message />},
    {id: "Mic", component: <MUIcon.Mic />},
    {id: "MicNone", component: <MUIcon.MicNone />},
    {id: "MicOff", component: <MUIcon.MicOff />},
    {id: "Mms", component: <MUIcon.Mms />},
    {id: "ModeComment", component: <MUIcon.ModeComment />},
    {id: "MonetizationOn", component: <MUIcon.MonetizationOn />},
    {id: "MoneyOff", component: <MUIcon.MoneyOff />},
    {id: "MonochromePhotos", component: <MUIcon.MonochromePhotos />},
    {id: "Mood", component: <MUIcon.Mood />},
    {id: "MoodBad", component: <MUIcon.MoodBad />},
    {id: "More", component: <MUIcon.More />},
    {id: "MoreHoriz", component: <MUIcon.MoreHoriz />},
    {id: "MoreVert", component: <MUIcon.MoreVert />},
    {id: "Motorcycle", component: <MUIcon.Motorcycle />},
    {id: "Mouse", component: <MUIcon.Mouse />},
    {id: "MoveToInbox", component: <MUIcon.MoveToInbox />},
    {id: "Movie", component: <MUIcon.Movie />},
    {id: "MovieCreation", component: <MUIcon.MovieCreation />},
    {id: "MovieFilter", component: <MUIcon.MovieFilter />},
    {id: "MultilineChart", component: <MUIcon.MultilineChart />},
    {id: "MusicNote", component: <MUIcon.MusicNote />},
    {id: "MusicVideo", component: <MUIcon.MusicVideo />},
    {id: "MyLocation", component: <MUIcon.MyLocation />},
    {id: "Nature", component: <MUIcon.Nature />},
    {id: "NaturePeople", component: <MUIcon.NaturePeople />},
    {id: "NavigateBefore", component: <MUIcon.NavigateBefore />},
    {id: "NavigateNext", component: <MUIcon.NavigateNext />},
    {id: "Navigation", component: <MUIcon.Navigation />},
    {id: "NearMe", component: <MUIcon.NearMe />},
    {id: "NetworkCell", component: <MUIcon.NetworkCell />},
    {id: "NetworkCheck", component: <MUIcon.NetworkCheck />},
    {id: "NetworkLocked", component: <MUIcon.NetworkLocked />},
    {id: "NetworkWifi", component: <MUIcon.NetworkWifi />},
    {id: "NewReleases", component: <MUIcon.NewReleases />},
    {id: "NextWeek", component: <MUIcon.NextWeek />},
    {id: "Nfc", component: <MUIcon.Nfc />},
    {id: "NoEncryption", component: <MUIcon.NoEncryption />},
    {id: "NoSim", component: <MUIcon.NoSim />},
    {id: "Note", component: <MUIcon.Note />},
    {id: "NoteAdd", component: <MUIcon.NoteAdd />},
    {id: "Notifications", component: <MUIcon.Notifications />},
    {id: "NotificationsActive", component: <MUIcon.NotificationsActive />},
    {id: "NotificationsNone", component: <MUIcon.NotificationsNone />},
    {id: "NotificationsOff", component: <MUIcon.NotificationsOff />},
    {id: "NotificationsPaused", component: <MUIcon.NotificationsPaused />},
    {id: "NotInterested", component: <MUIcon.NotInterested />},
    {id: "OfflinePin", component: <MUIcon.OfflinePin />},
    {id: "OndemandVideo", component: <MUIcon.OndemandVideo />},
    {id: "Opacity", component: <MUIcon.Opacity />},
    {id: "OpenInBrowser", component: <MUIcon.OpenInBrowser />},
    {id: "OpenInNew", component: <MUIcon.OpenInNew />},
    {id: "OpenWith", component: <MUIcon.OpenWith />},
    {id: "Pages", component: <MUIcon.Pages />},
    {id: "Pageview", component: <MUIcon.Pageview />},
    {id: "Palette", component: <MUIcon.Palette />},
    {id: "Panorama", component: <MUIcon.Panorama />},
    {id: "PanoramaFishEye", component: <MUIcon.PanoramaFishEye />},
    {id: "PanoramaHorizontal", component: <MUIcon.PanoramaHorizontal />},
    {id: "PanoramaVertical", component: <MUIcon.PanoramaVertical />},
    {id: "PanoramaWideAngle", component: <MUIcon.PanoramaWideAngle />},
    {id: "PanTool", component: <MUIcon.PanTool />},
    {id: "PartyMode", component: <MUIcon.PartyMode />},
    {id: "Pause", component: <MUIcon.Pause />},
    {id: "PauseCircleFilled", component: <MUIcon.PauseCircleFilled />},
    {id: "Payment", component: <MUIcon.Payment />},
    {id: "People", component: <MUIcon.People />},
    {id: "PermCameraMic", component: <MUIcon.PermCameraMic />},
    {id: "PermContactCalendar", component: <MUIcon.PermContactCalendar />},
    {id: "PermDataSetting", component: <MUIcon.PermDataSetting />},
    {id: "PermDeviceInformation", component: <MUIcon.PermDeviceInformation />},
    {id: "PermIdentity", component: <MUIcon.PermIdentity />},
    {id: "PermMedia", component: <MUIcon.PermMedia />},
    {id: "PermPhoneMsg", component: <MUIcon.PermPhoneMsg />},
    {id: "PermScanWifi", component: <MUIcon.PermScanWifi />},
    {id: "Person", component: <MUIcon.Person />},
    {id: "PersonAdd", component: <MUIcon.PersonAdd />},
    {id: "PersonalVideo", component: <MUIcon.PersonalVideo />},
    {id: "PersonPin", component: <MUIcon.PersonPin />},
    {id: "PersonPinCircle", component: <MUIcon.PersonPinCircle />},
    {id: "Pets", component: <MUIcon.Pets />},
    {id: "Phone", component: <MUIcon.Phone />},
    {id: "PhoneAndroid", component: <MUIcon.PhoneAndroid />},
    {id: "PhoneBluetoothSpeaker", component: <MUIcon.PhoneBluetoothSpeaker />},
    {id: "PhoneForwarded", component: <MUIcon.PhoneForwarded />},
    {id: "PhoneInTalk", component: <MUIcon.PhoneInTalk />},
    {id: "PhoneIphone", component: <MUIcon.PhoneIphone />},
    {id: "Phonelink", component: <MUIcon.Phonelink />},
    {id: "PhonelinkErase", component: <MUIcon.PhonelinkErase />},
    {id: "PhonelinkLock", component: <MUIcon.PhonelinkLock />},
    {id: "PhonelinkOff", component: <MUIcon.PhonelinkOff />},
    {id: "PhonelinkRing", component: <MUIcon.PhonelinkRing />},
    {id: "PhonelinkSetup", component: <MUIcon.PhonelinkSetup />},
    {id: "PhoneLocked", component: <MUIcon.PhoneLocked />},
    {id: "PhoneMissed", component: <MUIcon.PhoneMissed />},
    {id: "PhonePaused", component: <MUIcon.PhonePaused />},
    {id: "Photo", component: <MUIcon.Photo />},
    {id: "PhotoAlbum", component: <MUIcon.PhotoAlbum />},
    {id: "PhotoCamera", component: <MUIcon.PhotoCamera />},
    {id: "PhotoFilter", component: <MUIcon.PhotoFilter />},
    {id: "PhotoLibrary", component: <MUIcon.PhotoLibrary />},
    {id: "PhotoSizeSelectActual", component: <MUIcon.PhotoSizeSelectActual />},
    {id: "PhotoSizeSelectLarge", component: <MUIcon.PhotoSizeSelectLarge />},
    {id: "PhotoSizeSelectSmall", component: <MUIcon.PhotoSizeSelectSmall />},
    {id: "PictureAsPdf", component: <MUIcon.PictureAsPdf />},
    {id: "PictureInPicture", component: <MUIcon.PictureInPicture />},
    {id: "PictureInPictureAlt", component: <MUIcon.PictureInPictureAlt />},
    {id: "PieChart", component: <MUIcon.PieChart />},
    {id: "PinDrop", component: <MUIcon.PinDrop />},
    {id: "Place", component: <MUIcon.Place />},
    {id: "PlayArrow", component: <MUIcon.PlayArrow />},
    {id: "PlayCircleFilled", component: <MUIcon.PlayCircleFilled />},
    {id: "PlayForWork", component: <MUIcon.PlayForWork />},
    {id: "PlaylistAdd", component: <MUIcon.PlaylistAdd />},
    {id: "PlaylistAddCheck", component: <MUIcon.PlaylistAddCheck />},
    {id: "PlaylistPlay", component: <MUIcon.PlaylistPlay />},
    {id: "PlusOne", component: <MUIcon.PlusOne />},
    {id: "Poll", component: <MUIcon.Poll />},
    {id: "Polymer", component: <MUIcon.Polymer />},
    {id: "Pool", component: <MUIcon.Pool />},
    {id: "PortableWifiOff", component: <MUIcon.PortableWifiOff />},
    {id: "Portrait", component: <MUIcon.Portrait />},
    {id: "Power", component: <MUIcon.Power />},
    {id: "PowerInput", component: <MUIcon.PowerInput />},
    {id: "PowerSettingsNew", component: <MUIcon.PowerSettingsNew />},
    {id: "PregnantWoman", component: <MUIcon.PregnantWoman />},
    {id: "PresentToAll", component: <MUIcon.PresentToAll />},
    {id: "Print", component: <MUIcon.Print />},
    {id: "PriorityHigh", component: <MUIcon.PriorityHigh />},
    {id: "Public", component: <MUIcon.Public />},
    {id: "Publish", component: <MUIcon.Publish />},
    {id: "QueryBuilder", component: <MUIcon.QueryBuilder />},
    {id: "QuestionAnswer", component: <MUIcon.QuestionAnswer />},
    {id: "Queue", component: <MUIcon.Queue />},
    {id: "QueueMusic", component: <MUIcon.QueueMusic />},
    {id: "QueuePlayNext", component: <MUIcon.QueuePlayNext />},
    {id: "Radio", component: <MUIcon.Radio />},
    {id: "RadioButtonChecked", component: <MUIcon.RadioButtonChecked />},
    {id: "RadioButtonUnchecked", component: <MUIcon.RadioButtonUnchecked />},
    {id: "RateReview", component: <MUIcon.RateReview />},
    {id: "Receipt", component: <MUIcon.Receipt />},
    {id: "RecentActors", component: <MUIcon.RecentActors />},
    {id: "RecordVoiceOver", component: <MUIcon.RecordVoiceOver />},
    {id: "Redeem", component: <MUIcon.Redeem />},
    {id: "Redo", component: <MUIcon.Redo />},
    {id: "Refresh", component: <MUIcon.Refresh />},
    {id: "Remove", component: <MUIcon.Remove />},
    {id: "RemoveCircle", component: <MUIcon.RemoveCircle />},
    {id: "RemoveFromQueue", component: <MUIcon.RemoveFromQueue />},
    {id: "RemoveRedEye", component: <MUIcon.RemoveRedEye />},
    {id: "RemoveShoppingCart", component: <MUIcon.RemoveShoppingCart />},
    {id: "Reorder", component: <MUIcon.Reorder />},
    {id: "Repeat", component: <MUIcon.Repeat />},
    {id: "RepeatOne", component: <MUIcon.RepeatOne />},
    {id: "Replay", component: <MUIcon.Replay />},
    {id: "Replay10", component: <MUIcon.Replay10 />},
    {id: "Replay30", component: <MUIcon.Replay30 />},
    {id: "Replay5", component: <MUIcon.Replay5 />},
    {id: "Reply", component: <MUIcon.Reply />},
    {id: "ReplyAll", component: <MUIcon.ReplyAll />},
    {id: "Report", component: <MUIcon.Report />},
    {id: "ReportProblem", component: <MUIcon.ReportProblem />},
    {id: "Restaurant", component: <MUIcon.Restaurant />},
    {id: "RestaurantMenu", component: <MUIcon.RestaurantMenu />},
    {id: "Restore", component: <MUIcon.Restore />},
    {id: "RestorePage", component: <MUIcon.RestorePage />},
    {id: "RingVolume", component: <MUIcon.RingVolume />},
    {id: "Room", component: <MUIcon.Room />},
    {id: "RoomService", component: <MUIcon.RoomService />},
    {id: "Rotate90DegreesCcw", component: <MUIcon.Rotate90DegreesCcw />},
    {id: "RotateLeft", component: <MUIcon.RotateLeft />},
    {id: "RotateRight", component: <MUIcon.RotateRight />},
    {id: "RoundedCorner", component: <MUIcon.RoundedCorner />},
    {id: "Router", component: <MUIcon.Router />},
    {id: "Rowing", component: <MUIcon.Rowing />},
    {id: "RssFeed", component: <MUIcon.RssFeed />},
    {id: "RvHookup", component: <MUIcon.RvHookup />},
    {id: "Satellite", component: <MUIcon.Satellite />},
    {id: "Save", component: <MUIcon.Save />},
    {id: "Scanner", component: <MUIcon.Scanner />},
    {id: "Schedule", component: <MUIcon.Schedule />},
    {id: "School", component: <MUIcon.School />},
    {id: "ScreenLockLandscape", component: <MUIcon.ScreenLockLandscape />},
    {id: "ScreenLockPortrait", component: <MUIcon.ScreenLockPortrait />},
    {id: "ScreenLockRotation", component: <MUIcon.ScreenLockRotation />},
    {id: "ScreenRotation", component: <MUIcon.ScreenRotation />},
    {id: "ScreenShare", component: <MUIcon.ScreenShare />},
    {id: "SdCard", component: <MUIcon.SdCard />},
    {id: "SdStorage", component: <MUIcon.SdStorage />},
    {id: "Search", component: <MUIcon.Search />},
    {id: "Security", component: <MUIcon.Security />},
    {id: "SelectAll", component: <MUIcon.SelectAll />},
    {id: "Send", component: <MUIcon.Send />},
    {id: "SentimentDissatisfied", component: <MUIcon.SentimentDissatisfied />},
    {id: "SentimentSatisfied", component: <MUIcon.SentimentSatisfied />},
    {id: "SentimentVeryDissatisfied", component: <MUIcon.SentimentVeryDissatisfied />},
    {id: "SentimentVerySatisfied", component: <MUIcon.SentimentVerySatisfied />},
    {id: "Settings", component: <MUIcon.Settings />},
    {id: "SettingsApplications", component: <MUIcon.SettingsApplications />},
    {id: "SettingsBackupRestore", component: <MUIcon.SettingsBackupRestore />},
    {id: "SettingsBluetooth", component: <MUIcon.SettingsBluetooth />},
    {id: "SettingsBrightness", component: <MUIcon.SettingsBrightness />},
    {id: "SettingsCell", component: <MUIcon.SettingsCell />},
    {id: "SettingsEthernet", component: <MUIcon.SettingsEthernet />},
    {id: "SettingsInputAntenna", component: <MUIcon.SettingsInputAntenna />},
    {id: "SettingsInputComponent", component: <MUIcon.SettingsInputComponent />},
    {id: "SettingsInputComposite", component: <MUIcon.SettingsInputComposite />},
    {id: "SettingsInputHdmi", component: <MUIcon.SettingsInputHdmi />},
    {id: "SettingsInputSvideo", component: <MUIcon.SettingsInputSvideo />},
    {id: "SettingsOverscan", component: <MUIcon.SettingsOverscan />},
    {id: "SettingsPhone", component: <MUIcon.SettingsPhone />},
    {id: "SettingsPower", component: <MUIcon.SettingsPower />},
    {id: "SettingsRemote", component: <MUIcon.SettingsRemote />},
    {id: "SettingsSystemDaydream", component: <MUIcon.SettingsSystemDaydream />},
    {id: "SettingsVoice", component: <MUIcon.SettingsVoice />},
    {id: "Share", component: <MUIcon.Share />},
    {id: "Shop", component: <MUIcon.Shop />},
    {id: "ShoppingBasket", component: <MUIcon.ShoppingBasket />},
    {id: "ShoppingCart", component: <MUIcon.ShoppingCart />},
    {id: "ShopTwo", component: <MUIcon.ShopTwo />},
    {id: "ShortText", component: <MUIcon.ShortText />},
    {id: "ShowChart", component: <MUIcon.ShowChart />},
    {id: "Shuffle", component: <MUIcon.Shuffle />},
    {id: "SignalCellular0Bar", component: <MUIcon.SignalCellular0Bar />},
    {id: "SignalCellular1Bar", component: <MUIcon.SignalCellular1Bar />},
    {id: "SignalCellular2Bar", component: <MUIcon.SignalCellular2Bar />},
    {id: "SignalCellular3Bar", component: <MUIcon.SignalCellular3Bar />},
    {id: "SignalCellular4Bar", component: <MUIcon.SignalCellular4Bar />},
    {id: "SignalCellularConnectedNoInternet0Bar", component: <MUIcon.SignalCellularConnectedNoInternet0Bar />},
    {id: "SignalCellularConnectedNoInternet1Bar", component: <MUIcon.SignalCellularConnectedNoInternet1Bar />},
    {id: "SignalCellularConnectedNoInternet2Bar", component: <MUIcon.SignalCellularConnectedNoInternet2Bar />},
    {id: "SignalCellularConnectedNoInternet3Bar", component: <MUIcon.SignalCellularConnectedNoInternet3Bar />},
    {id: "SignalCellularConnectedNoInternet4Bar", component: <MUIcon.SignalCellularConnectedNoInternet4Bar />},
    {id: "SignalCellularNoSim", component: <MUIcon.SignalCellularNoSim />},
    {id: "SignalCellularNull", component: <MUIcon.SignalCellularNull />},
    {id: "SignalCellularOff", component: <MUIcon.SignalCellularOff />},
    {id: "SignalWifi0Bar", component: <MUIcon.SignalWifi0Bar />},
    {id: "SignalWifi1Bar", component: <MUIcon.SignalWifi1Bar />},
    {id: "SignalWifi1BarLock", component: <MUIcon.SignalWifi1BarLock />},
    {id: "SignalWifi2Bar", component: <MUIcon.SignalWifi2Bar />},
    {id: "SignalWifi2BarLock", component: <MUIcon.SignalWifi2BarLock />},
    {id: "SignalWifi3Bar", component: <MUIcon.SignalWifi3Bar />},
    {id: "SignalWifi3BarLock", component: <MUIcon.SignalWifi3BarLock />},
    {id: "SignalWifi4Bar", component: <MUIcon.SignalWifi4Bar />},
    {id: "SignalWifi4BarLock", component: <MUIcon.SignalWifi4BarLock />},
    {id: "SignalWifiOff", component: <MUIcon.SignalWifiOff />},
    {id: "SimCard", component: <MUIcon.SimCard />},
    {id: "SkipNext", component: <MUIcon.SkipNext />},
    {id: "SkipPrevious", component: <MUIcon.SkipPrevious />},
    {id: "Slideshow", component: <MUIcon.Slideshow />},
    {id: "SlowMotionVideo", component: <MUIcon.SlowMotionVideo />},
    {id: "Smartphone", component: <MUIcon.Smartphone />},
    {id: "SmokeFree", component: <MUIcon.SmokeFree />},
    {id: "SmokingRooms", component: <MUIcon.SmokingRooms />},
    {id: "Sms", component: <MUIcon.Sms />},
    {id: "SmsFailed", component: <MUIcon.SmsFailed />},
    {id: "Snooze", component: <MUIcon.Snooze />},
    {id: "Sort", component: <MUIcon.Sort />},
    {id: "SortByAlpha", component: <MUIcon.SortByAlpha />},
    {id: "Spa", component: <MUIcon.Spa />},
    {id: "SpaceBar", component: <MUIcon.SpaceBar />},
    {id: "Speaker", component: <MUIcon.Speaker />},
    {id: "SpeakerGroup", component: <MUIcon.SpeakerGroup />},
    {id: "SpeakerNotes", component: <MUIcon.SpeakerNotes />},
    {id: "SpeakerNotesOff", component: <MUIcon.SpeakerNotesOff />},
    {id: "SpeakerPhone", component: <MUIcon.SpeakerPhone />},
    {id: "Spellcheck", component: <MUIcon.Spellcheck />},
    {id: "Star", component: <MUIcon.Star />},
    {id: "StarBorder", component: <MUIcon.StarBorder />},
    {id: "StarHalf", component: <MUIcon.StarHalf />},
    {id: "Stars", component: <MUIcon.Stars />},
    {id: "StayCurrentLandscape", component: <MUIcon.StayCurrentLandscape />},
    {id: "StayCurrentPortrait", component: <MUIcon.StayCurrentPortrait />},
    {id: "StayPrimaryLandscape", component: <MUIcon.StayPrimaryLandscape />},
    {id: "StayPrimaryPortrait", component: <MUIcon.StayPrimaryPortrait />},
    {id: "Stop", component: <MUIcon.Stop />},
    {id: "StopScreenShare", component: <MUIcon.StopScreenShare />},
    {id: "Storage", component: <MUIcon.Storage />},
    {id: "Store", component: <MUIcon.Store />},
    {id: "StoreMallDirectory", component: <MUIcon.StoreMallDirectory />},
    {id: "Straighten", component: <MUIcon.Straighten />},
    {id: "Streetview", component: <MUIcon.Streetview />},
    {id: "StrikethroughS", component: <MUIcon.StrikethroughS />},
    {id: "Style", component: <MUIcon.Style />},
    {id: "SubdirectoryArrowLeft", component: <MUIcon.SubdirectoryArrowLeft />},
    {id: "SubdirectoryArrowRight", component: <MUIcon.SubdirectoryArrowRight />},
    {id: "Subject", component: <MUIcon.Subject />},
    {id: "Subscriptions", component: <MUIcon.Subscriptions />},
    {id: "Subtitles", component: <MUIcon.Subtitles />},
    {id: "Subway", component: <MUIcon.Subway />},
    {id: "SupervisorAccount", component: <MUIcon.SupervisorAccount />},
    {id: "SurroundSound", component: <MUIcon.SurroundSound />},
    {id: "SwapCalls", component: <MUIcon.SwapCalls />},
    {id: "SwapHoriz", component: <MUIcon.SwapHoriz />},
    {id: "SwapVert", component: <MUIcon.SwapVert />},
    {id: "SwapVerticalCircle", component: <MUIcon.SwapVerticalCircle />},
    {id: "SwitchCamera", component: <MUIcon.SwitchCamera />},
    {id: "SwitchVideo", component: <MUIcon.SwitchVideo />},
    {id: "Sync", component: <MUIcon.Sync />},
    {id: "SyncDisabled", component: <MUIcon.SyncDisabled />},
    {id: "SyncProblem", component: <MUIcon.SyncProblem />},
    {id: "SystemUpdate", component: <MUIcon.SystemUpdate />},
    {id: "SystemUpdateAlt", component: <MUIcon.SystemUpdateAlt />},
    {id: "Tab", component: <MUIcon.Tab />},
    {id: "Tablet", component: <MUIcon.Tablet />},
    {id: "TabletAndroid", component: <MUIcon.TabletAndroid />},
    {id: "TabletMac", component: <MUIcon.TabletMac />},
    {id: "TabUnselected", component: <MUIcon.TabUnselected />},
    {id: "TagFaces", component: <MUIcon.TagFaces />},
    {id: "TapAndPlay", component: <MUIcon.TapAndPlay />},
    {id: "Terrain", component: <MUIcon.Terrain />},
    {id: "TextFields", component: <MUIcon.TextFields />},
    {id: "TextFormat", component: <MUIcon.TextFormat />},
    {id: "Textsms", component: <MUIcon.Textsms />},
    {id: "Texture", component: <MUIcon.Texture />},
    {id: "Theaters", component: <MUIcon.Theaters />},
    {id: "ThreeDRotation", component: <MUIcon.ThreeDRotation />},
    {id: "ThumbDown", component: <MUIcon.ThumbDown />},
    {id: "ThumbsUpDown", component: <MUIcon.ThumbsUpDown />},
    {id: "ThumbUp", component: <MUIcon.ThumbUp />},
    {id: "Timelapse", component: <MUIcon.Timelapse />},
    {id: "Timeline", component: <MUIcon.Timeline />},
    {id: "Timer", component: <MUIcon.Timer />},
    {id: "Timer10", component: <MUIcon.Timer10 />},
    {id: "Timer3", component: <MUIcon.Timer3 />},
    {id: "TimerOff", component: <MUIcon.TimerOff />},
    {id: "TimeToLeave", component: <MUIcon.TimeToLeave />},
    {id: "Title", component: <MUIcon.Title />},
    {id: "Toc", component: <MUIcon.Toc />},
    {id: "Today", component: <MUIcon.Today />},
    {id: "Toll", component: <MUIcon.Toll />},
    {id: "Tonality", component: <MUIcon.Tonality />},
    {id: "TouchApp", component: <MUIcon.TouchApp />},
    {id: "Toys", component: <MUIcon.Toys />},
    {id: "TrackChanges", component: <MUIcon.TrackChanges />},
    {id: "Traffic", component: <MUIcon.Traffic />},
    {id: "Train", component: <MUIcon.Train />},
    {id: "Tram", component: <MUIcon.Tram />},
    {id: "TransferWithinAStation", component: <MUIcon.TransferWithinAStation />},
    {id: "Transform", component: <MUIcon.Transform />},
    {id: "Translate", component: <MUIcon.Translate />},
    {id: "TrendingDown", component: <MUIcon.TrendingDown />},
    {id: "TrendingFlat", component: <MUIcon.TrendingFlat />},
    {id: "TrendingUp", component: <MUIcon.TrendingUp />},
    {id: "Tune", component: <MUIcon.Tune />},
    {id: "TurnedIn", component: <MUIcon.TurnedIn />},
    {id: "TurnedInNot", component: <MUIcon.TurnedInNot />},
    {id: "Tv", component: <MUIcon.Tv />},
    {id: "Unarchive", component: <MUIcon.Unarchive />},
    {id: "Undo", component: <MUIcon.Undo />},
    {id: "UnfoldLess", component: <MUIcon.UnfoldLess />},
    {id: "UnfoldMore", component: <MUIcon.UnfoldMore />},
    {id: "Update", component: <MUIcon.Update />},
    {id: "Usb", component: <MUIcon.Usb />},
    {id: "VerifiedUser", component: <MUIcon.VerifiedUser />},
    {id: "VerticalAlignBottom", component: <MUIcon.VerticalAlignBottom />},
    {id: "VerticalAlignCenter", component: <MUIcon.VerticalAlignCenter />},
    {id: "VerticalAlignTop", component: <MUIcon.VerticalAlignTop />},
    {id: "Vibration", component: <MUIcon.Vibration />},
    {id: "VideoCall", component: <MUIcon.VideoCall />},
    {id: "Videocam", component: <MUIcon.Videocam />},
    {id: "VideocamOff", component: <MUIcon.VideocamOff />},
    {id: "VideogameAsset", component: <MUIcon.VideogameAsset />},
    {id: "VideoLabel", component: <MUIcon.VideoLabel />},
    {id: "VideoLibrary", component: <MUIcon.VideoLibrary />},
    {id: "ViewAgenda", component: <MUIcon.ViewAgenda />},
    {id: "ViewArray", component: <MUIcon.ViewArray />},
    {id: "ViewCarousel", component: <MUIcon.ViewCarousel />},
    {id: "ViewColumn", component: <MUIcon.ViewColumn />},
    {id: "ViewComfy", component: <MUIcon.ViewComfy />},
    {id: "ViewCompact", component: <MUIcon.ViewCompact />},
    {id: "ViewDay", component: <MUIcon.ViewDay />},
    {id: "ViewHeadline", component: <MUIcon.ViewHeadline />},
    {id: "ViewList", component: <MUIcon.ViewList />},
    {id: "ViewModule", component: <MUIcon.ViewModule />},
    {id: "ViewQuilt", component: <MUIcon.ViewQuilt />},
    {id: "ViewStream", component: <MUIcon.ViewStream />},
    {id: "ViewWeek", component: <MUIcon.ViewWeek />},
    {id: "Vignette", component: <MUIcon.Vignette />},
    {id: "Visibility", component: <MUIcon.Visibility />},
    {id: "VisibilityOff", component: <MUIcon.VisibilityOff />},
    {id: "VoiceChat", component: <MUIcon.VoiceChat />},
    {id: "Voicemail", component: <MUIcon.Voicemail />},
    {id: "VolumeDown", component: <MUIcon.VolumeDown />},
    {id: "VolumeMute", component: <MUIcon.VolumeMute />},
    {id: "VolumeOff", component: <MUIcon.VolumeOff />},
    {id: "VolumeUp", component: <MUIcon.VolumeUp />},
    {id: "VpnKey", component: <MUIcon.VpnKey />},
    {id: "VpnLock", component: <MUIcon.VpnLock />},
    {id: "Wallpaper", component: <MUIcon.Wallpaper />},
    {id: "Warning", component: <MUIcon.Warning />},
    {id: "Watch", component: <MUIcon.Watch />},
    {id: "WatchLater", component: <MUIcon.WatchLater />},
    {id: "WbAuto", component: <MUIcon.WbAuto />},
    {id: "WbCloudy", component: <MUIcon.WbCloudy />},
    {id: "WbIncandescent", component: <MUIcon.WbIncandescent />},
    {id: "WbIridescent", component: <MUIcon.WbIridescent />},
    {id: "WbSunny", component: <MUIcon.WbSunny />},
    {id: "Wc", component: <MUIcon.Wc />},
    {id: "Web", component: <MUIcon.Web />},
    {id: "WebAsset", component: <MUIcon.WebAsset />},
    {id: "Weekend", component: <MUIcon.Weekend />},
    {id: "Whatshot", component: <MUIcon.Whatshot />},
    {id: "Widgets", component: <MUIcon.Widgets />},
    {id: "Wifi", component: <MUIcon.Wifi />},
    {id: "WifiLock", component: <MUIcon.WifiLock />},
    {id: "WifiTethering", component: <MUIcon.WifiTethering />},
    {id: "Work", component: <MUIcon.Work />},
    {id: "WrapText", component: <MUIcon.WrapText />},
    {id: "YoutubeSearchedFor", component: <MUIcon.YoutubeSearchedFor />},
    {id: "ZoomIn", component: <MUIcon.ZoomIn />},
    {id: "ZoomOut", component: <MUIcon.ZoomOut />},
    {id: "ZoomOutMap", component: <MUIcon.ZoomOutMap />},
    { id: "Blank" }
]
