/*****************************************************************************
 * This component is the User Permissions that can be set by the administrator.
 * It is used in conjunction with create.js or edit.js
  
 *
 * Created by: Robert Gautier/Joe J
 * Date: 3/2019
 *****************************************************************************/

import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "react-form";
import { withRouter } from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

import Checkbox from "../common/Checkbox";
import WarningDialog from "../common/WarningDialog";
import EnhancedTableHead from "../common/EnhancedTableHead";
import CustomTableCell from "../common/TableCell";
import { createSelector } from "../common/orm";
import { Permission } from "../page/models";

import { User } from "./models";
// Get all "Page" objects and order by name
// // Also include a mapping to _sections
const allPages = createSelector((schema) => {
    return schema.Page.all().orderBy("name").toModelArray();
});

const getUser = User.selectByUrlId();

const userPermissions = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, objId) => {
        return session.Permission.filter((perm) => perm.user === objId).toRefArray();
    }
);

// CSS in JS styles
const styles = (theme) => ({
    italics: {
        fontStyle: "italic",
    },
    table: {
        minWidth: 500,
    },
    flex: {
        flex: 1,
    },
    button: {
        float: "right",
    },
    gutterBottom: {
        marginBottom: ".6em",
    },
    root: {
        width: "100%",
        overflowX: "auto",
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        margin: "0 8px 0 0",
    },
    centerAlign: {
        textAlign: "center",
    },
    nowrap: {
        whiteSpace: "nowrap",
    },
});

// The table columns on the "edit" permissions page.
// http://localhost:3000/#/adm/edit/ (id)
const columnData = [
    // { id: "actions", numeric: false, label: "", allowSort: false, width: "auto" },
    { id: "name", numeric: false, label: "Module Name", allowSort: true, width: "40%" },
    { id: "fullaccess", numeric: false, label: "Full Access", allowSort: false, width: "auto" },
    { id: "readonly", numeric: false, label: "Read Only", allowSort: false, width: "auto" },
    { id: "noaccess", numeric: false, label: "No Access", allowSort: false, width: "auto" },
    { id: "readonlyexternal", numeric: false, label: "Read Only (External)", allowSort: false, width: "auto" },
    { id: "dashboard", numeric: false, label: "Dashboard", allowSort: false, width: "auto" },
];

// The root component of the page
// http://localhost:3000/#/adm/edit/ (id)
class UserPermissions extends Component {
    constructor(props, context) {
        super(props, context);
        // Default states
        this.state = {
            order: "asc",
            orderBy: "name",
            setDefault: true,
            dialogOpen: false,
        };
    }

    // When a table column is sorted
    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.props.pages.sort((a, b) => (b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1))
            : this.props.pages.sort((a, b) => (a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1));

        this.setState({ order, orderBy });
    };

    //update the checkbox,
    checkboxUpdated = (value, page_id, user_id, perm_exists, typeOfAccess) => {
        const { ormPermissionCreate, ormPermissionUpdate, ormUserUpdate, ormUserUpdateLocalOnly } = this.props;

        if (typeOfAccess === "dashboard") {
            //permissions have not been set and are undefined
            if (perm_exists === undefined) {
                ormPermissionCreate({
                    page: page_id,
                    user: user_id,
                    dashboard: value, //readonly will be set to true
                });
                //update permissions that have already been set
            } else {
                ormPermissionUpdate({
                    id: perm_exists.id,
                    dashboard: value,
                });
            }
            //update user model so we can route directly from login screen
            if (value) {
                ormUserUpdate({
                    id: user_id,
                    default_dashboard: page_id,
                });
                ormUserUpdateLocalOnly({
                    id: user_id,
                    default_dashboard: page_id,
                });
            } else {
                ormUserUpdate({
                    id: user_id,
                    default_dashboard: null,
                });
            }
        } else if (typeOfAccess === "readonly") {
            //permissions have not been set and are undefined
            if (perm_exists === undefined) {
                ormPermissionCreate({
                    page: page_id,
                    user: user_id,
                    [typeOfAccess]: value, //readonly will be set to true
                    fullaccess: false,
                    noaccess: false,
                    readonlyexternal: false,
                });
                //update permissions that have already been set
            } else {
                ormPermissionUpdate({
                    id: perm_exists.id,
                    [typeOfAccess]: value,
                    fullaccess: false,
                    noaccess: false,
                    readonlyexternal: false,
                });
            }
        } else if (typeOfAccess === "fullaccess") {
            //permissions have not been set and are undefined
            if (perm_exists === undefined) {
                ormPermissionCreate({
                    page: page_id,
                    user: user_id,
                    [typeOfAccess]: value, //fullaccess will be set to true
                    readonly: false,
                    noaccess: false,
                    readonlyexternal: false,
                });
            } else {
                //update permissions that have already been set
                ormPermissionUpdate({
                    id: perm_exists.id,
                    [typeOfAccess]: value,
                    readonly: false,
                    noaccess: false,
                    readonlyexternal: false,
                });
            }
        } else if (typeOfAccess === "readonlyexternal") {
            //permissions have not been set and are undefined
            if (perm_exists === undefined) {
                ormPermissionCreate({
                    page: page_id,
                    user: user_id,
                    [typeOfAccess]: value, //readonlyexternal will be set to true
                    fullaccess: false,
                    noaccess: false,
                    readonly: false,
                });
                //update permissions that have already been set
            } else {
                ormPermissionUpdate({
                    id: perm_exists.id,
                    [typeOfAccess]: value,
                    fullaccess: false,
                    noaccess: false,
                    readonly: false,
                });
            }
        } else {
            //this is for NO ACCESS SITUATION
            //permissions have not been set and are undefined
            if (perm_exists === undefined) {
                ormPermissionCreate({
                    page: page_id,
                    user: user_id,
                    [typeOfAccess]: value, //NO ACCESS WILL BE SET TO TRUE
                    fullaccess: false,
                    readonly: false,
                    readonlyexternal: false,
                });
                //update permissions that have already been set
            } else {
                ormPermissionUpdate({
                    id: perm_exists.id,
                    [typeOfAccess]: value,
                    fullaccess: false,
                    readonly: false,
                    readonlyexternal: false,
                });
            }
        }
    };

    setDefaultPermission = () => {
        const { permissions, useInfo, ormPermissionCreate, pages } = this.props;
        this.setState({ setDefault: false });

        //set permissions to full access for creation of new user
        if (permissions.length < 1) {
            pages.map((p) => {
                return ormPermissionCreate({
                    page: p.id,
                    user: useInfo.id,
                    noaccess: false,
                    fullaccess: true,
                    readonly: false,
                    readonlyexternal: false,
                });
            });
        }
    };

    render() {
        const { classes, pages, useInfoCreate, permissions } = this.props;
        var { useInfo } = this.props;
        const { order, orderBy, setDefault, dialogOpen } = this.state;
        if (!useInfo || useInfo.name === "Not Found") useInfo = useInfoCreate;

        if (setDefault) {
            //set permission to full access for new user
            this.setDefaultPermission();
        }

        return (
            <Form getApi={(el) => (this.formApi = el)} dontValidateOnMount="true" validateOnSubmit="true">
                {(formApi) => (
                    <form>
                        <>
                            <Typography variant="subtitle1" gutterBottom={true} style={{ marginLeft: 3 }}>
                                Note: each module needs to have an access filter, if one isnt selected for the shown module "Full Access" will result.
                            </Typography>
                            <Paper className={classes.root}>
                                <Table className={classes.table}>
                                    <EnhancedTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={this.handleRequestSort}
                                        columnData={columnData}
                                    />

                                    {/* this is the layout of the table for the pages & checkboxes map function which will loop based on total amount of pages*/}
                                    <TableBody>
                                        {/* loop through the pages from DB one-by-one where "n is the page",
                                    PERMISSIONS are all the permissions in DB. permission.FIND looks and finds the "first" element in the array
                                    that matches "permissions.pageID" with "pages.id" when that is found it is placed in perm (i.e. permissions)
                                    to be looped through the return statement which will populate the checkboxes, etc. 
                                    */}
                                        {pages.map((page) => {
                                            const perm = permissions.find(function (permission) {
                                                return permission.page === page.id;
                                            });

                                            // console.log(permissions);
                                            // console.log(useInfo);
                                            return (
                                                <TableRow hover key={page.id}>
                                                    {/* page name */}
                                                    <CustomTableCell>{page.name}</CustomTableCell>
                                                    {/* Checkboxes for each page start with fullaccess, readonly and then noaccess. */}
                                                    <CustomTableCell>
                                                        <Checkbox
                                                            //check if perm is undefined set checkbox to true as default is fullAccess==true
                                                            checked={perm !== undefined ? perm.fullaccess : false}
                                                            disabled={false}
                                                            label=""
                                                            field="fullaccess"
                                                            className={classes.deleteWidth}
                                                            // custom call to common/checkBox.js component which allows for eventHandle to be called
                                                            // within handleChange function with "value" being the event. This triggers the button
                                                            // being clicked or not. It also calls checkboxUpdated function in this component
                                                            eventHandle={(value) => {
                                                                this.checkboxUpdated(value, page.id, useInfo.id, perm, "fullaccess");
                                                            }}
                                                        />
                                                    </CustomTableCell>
                                                    <CustomTableCell>
                                                        <Checkbox
                                                            checked={perm !== undefined ? perm.readonly : false}
                                                            disabled={false}
                                                            label=""
                                                            className={classes.deleteWidth}
                                                            field="readonly"
                                                            eventHandle={(value) =>
                                                                this.checkboxUpdated(value, page.id, useInfo.id, perm, "readonly")
                                                            }
                                                        />
                                                    </CustomTableCell>
                                                    <CustomTableCell>
                                                        <Checkbox
                                                            checked={perm !== undefined ? perm.noaccess : false}
                                                            disabled={false}
                                                            label=""
                                                            className={classes.deleteWidth}
                                                            field="noaccess"
                                                            //trigger for NO ACCESS Permissions
                                                            eventHandle={(value) =>
                                                                this.checkboxUpdated(value, page.id, useInfo.id, perm, "noaccess")
                                                            }
                                                        />
                                                    </CustomTableCell>
                                                    <CustomTableCell>
                                                        <Checkbox
                                                            checked={perm !== undefined ? perm.readonlyexternal : false}
                                                            disabled={false}
                                                            label=""
                                                            className={classes.deleteWidth}
                                                            field="readonlyexternal"
                                                            //trigger for read only external Permissions
                                                            eventHandle={(value) =>
                                                                this.checkboxUpdated(value, page.id, useInfo.id, perm, "readonlyexternal")
                                                            }
                                                        />
                                                    </CustomTableCell>
                                                    <CustomTableCell>
                                                        <Checkbox
                                                            //only allow one page to be checked for the dashboard
                                                            checked={page.id === parseInt(useInfo.default_dashboard) ? page.id : false}
                                                            disabled={false}
                                                            label=""
                                                            field="dashboard"
                                                            className={classes.deleteWidth}
                                                            eventHandle={(value) => {
                                                                if (
                                                                    value &&
                                                                    (!perm ||
                                                                        perm.noaccess ||
                                                                        (!perm.fullaccess && !perm.readonly && !perm.readonlyexternal))
                                                                ) {
                                                                    this.setState({ dialogOpen: true });
                                                                } else {
                                                                    this.checkboxUpdated(value, page.id, useInfo.id, perm, "dashboard");
                                                                }
                                                            }}
                                                        />
                                                    </CustomTableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {pages.length < 1 && (
                                            <TableRow>
                                                <CustomTableCell colSpan={3} className={classes.centerAlign}>
                                                    No Permissions Defined
                                                </CustomTableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </Paper>
                            <WarningDialog
                                confirmAction={() => {
                                    this.setState({ dialogOpen: false });
                                }}
                                cancelAction={() => this.setState({ dialogOpen: false })}
                                open={dialogOpen}
                                confirmText="Ok"
                                noDeleteButtons
                                title="Module doesnt have Access"
                                text="Only Modules that are set as 'Full Access' or 'Read Only' can be set as the default Dashboard"
                            />
                        </>
                    </form>
                )}
            </Form>
        );
    }
}
UserPermissions.displayName = "UserPermissions";
// This connects the redux-orm "allPages" function and allows the UserPermissions to have the props "pages"
// The second part loads all orm actions into the UserPermissions props for both Page and Permission: ormPageCreate, ormPermissionCreate, orPageUpdate, etc.
UserPermissions = connect(
    (state, ownProps) => ({
        pages: allPages(state),
        useInfo: getUser(state, ownProps), //state prop "useInfo" to use with the page. Note ownProps are declared as they are needed for the orm function, however are not used
        permissions: userPermissions(state, ownProps),
        authState: state.auth,
    }),
    {
        // ...Page.actions, //orm actionCreators to DB for Page ex. ormPageUpdate(values), ormPageCreate(values)
        ...Permission.actions,
        ...User.actions, //orm actionCreators to DB for Permissions ex. ormPermissionUpdate(values), ormPermissionCreate(values)
    }
)(UserPermissions);

export default withStyles(styles)(withRouter(UserPermissions));
