import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import { IconButton } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import PhotoAlbumIcon from "@material-ui/icons/PhotoAlbum";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
// import Button from "@material-ui/core/Button";
import AppContainer from "../../common/AppContainer";
import { createSelector } from "../../common/orm";
import WarningDialog from "../../common/WarningDialog";
import { Page } from "../models";
import ViewRecordTable from "../table/ViewRecordTable";
// import PageAnalytics from "../advancedSearch/PageAnalytics";
import FormUpload from "../../common/FormUpload";
const styles = (theme) => ({
    iconbutton: {
        margin: "10px 15px 0px 15px",
    },
    italics: {
        fontStyle: "italic",
    },
    floatLeft: {
        float: "left",
    },
    floatRight: {
        float: "right",
    },
    flex: {
        flex: 1,
    },

    listItem: {
        borderBottom: "1px solid #262626",
    },
    hideSmall: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    hideLarge: {
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
    },
    list: {
        "& ul": {
            padding: 0,
        },
    },
    topListItem: {
        borderBottom: "1px solid #262626",
        backgroundColor: "#eee",
        cursor: "default",
        textAlign: "center",
    },
});

// Get the specific page objects from the page model--these are AKA props
// Ex: the URL is http://localhost:3000/#/page/49 we would get page object #49
const getPage = Page.selectByUrlId();

//get permissions
const getPermission = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (session, pageID) => {
        return session.Permission.filter((p) => p.page === pageID).toRefArray();
    } //toRefArray returns a plain JavaScript object which are references to the DB
);

const getSection = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (state, ownProps) => parseInt(ownProps.match.params["pageId"]),
    (session, id, pageId) => {
        if (isNaN(id)) {
            return session.Section.filter((sect) => sect.page === pageId).toRefArray();
        } else {
            return session.Section.filter((sect) => sect.page === id).toRefArray();
        }
    }
);

//get the sectionID from the URL
const getSectionId = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["sectionId"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (session, sectionID) => {
        return sectionID;
    }
);

class AllRecordsTableContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
            open: false,
            helpMenu: null,
            smallAnchor: null,
            exportModule: false,
            exportModuleAcknowledgement:false
        };
    }

    closeDialog = () => {
        this.setState({ dialogOpen: false });
    };

    handleHelpMenu = (event) => {
        this.setState({ helpMenu: event.currentTarget });
    };

    handleHelpMenuClose = () => {
        this.setState({ helpMenu: null });
    };
    handleSmallMenu = (event) => {
        this.setState({ smallAnchor: event.currentTarget });
    };

    handleSmallMenuClose = () => {
        this.setState({ smallAnchor: null });
    };

    render() {
        const { open, helpMenu, smallAnchor, exportModule, exportModuleAcknowledgement } = this.state;
        const { classes, page, removeTitle, section, actSection, authState, formm, permission } = this.props;
        const userHelpOpen = Boolean(helpMenu);
        const smallMenuOpen = Boolean(smallAnchor);
        //get section name for header
        var sectionName = section.find((s) => {
            if (s.id === actSection) {
                return s;
            }
            return null;
        });

        if (authState.user) {
            var readOnly;
            var perm = permission.find((p) => {
                if (p.user === authState.user.id) {
                    return p;
                }
                return null;
            });
            if (perm) readOnly = perm.readonly || perm.readonlyexternal ? true : false;
            if (readOnly === undefined) readOnly = false;
        }

        //////////PRINT TABLE FOR ALL RECORDS OF PAGE REMOVE "TITLE" PASSED IN FROM 'EXPANSIONPANELFORMRECORD'/////////////////////////////////////
        if (!removeTitle) {
            return (
                <AppContainer authenticated>
                    <Grid container spacing={0} style={{ height: "calc(100% - 20px)" }}>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid className={classes.hideSmall} item xs={6}>
                                    {sectionName && <Typography variant="h5">{page.name + " - " + sectionName.name}</Typography>}
                                    <Typography variant="body1" className={classes.italics}>
                                        Use the table below, the filter tool or the map to identify and select an existing record or create a new
                                        record.
                                    </Typography>
                                </Grid>
                                <Grid className={classes.hideLarge} item xs={10}>
                                    {sectionName && (
                                        <Typography variant="h5" style={{ paddingTop: 17 }}>
                                            {page.name + " - " + sectionName.name}
                                        </Typography>
                                    )}
                                    <Typography variant="body1" className={classes.italics}>
                                        Use the table below, the filter tool or the map to identify and select an existing record or create a new
                                        record.
                                    </Typography>
                                </Grid>
                                <Grid className={classes.hideLarge} style={{ textAlign: "right", paddingTop: 17 }} item xs={2}>
                                    <Tooltip title="Module Menu" placement="bottom-start" enterDelay={300}>
                                        <IconButton className={classes.headerButton} onClick={this.handleSmallMenu}>
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid className={classes.hideSmall} item xs={6} style={{ textAlign: "right" }}>
                                    {page.id && sectionName && (
                                        <Tooltip className={classes.hideSmall} title={`Dashboard`}>
                                            <IconButton className={classes.iconbutton} component={Link} to={"/dashboard/" + page.id}>
                                                <Icon>timeline</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    <Tooltip className={classes.hideSmall} title="Import / Export">
                                        <IconButton className={classes.iconbutton} onClick={this.handleHelpMenu}>
                                            <Icon>import_export</Icon>
                                        </IconButton>
                                    </Tooltip>
                                    {page.id && sectionName && sectionName.has_geometry && (
                                        <Tooltip className={classes.hideSmall} title={`${page.name} Map`}>
                                            <IconButton
                                                className={classes.iconbutton}
                                                component={Link}
                                                to={"/map/" + page.id + "/section/" + sectionName.id + "/advancedsearch/" + 1}>
                                                <Icon>map</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {sectionName && (
                                        <Tooltip className={classes.hideSmall} title={`Search`}>
                                            <IconButton className={classes.iconbutton} component={Link} to={"/page/" + page.id + "/advancedsearch"}>
                                                <Icon>search</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {authState.user && authState.user.is_city_admin === true && (
                                        <Tooltip className={classes.hideSmall} title="Module Configuration">
                                            <IconButton
                                                disabled={readOnly}
                                                className={classes.iconbutton}
                                                component={Link}
                                                to={"/page/" + page.id + "/config"} //route to page config
                                            >
                                                <Icon>settings</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    <Menu
                                        id="importexport"
                                        anchorEl={helpMenu}
                                        classes={{
                                            paper: classes.list,
                                        }}
                                        anchorOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        open={userHelpOpen}
                                        onClose={this.handleHelpMenuClose}>
                                        <ListItem button className={classes.topListItem}>
                                            <ListItemText
                                                primary={<Typography variant="h6">Import / Export</Typography>}
                                                classes={{
                                                    root: classes.topListText,
                                                    primary: classes.topListText,
                                                }}
                                            />
                                        </ListItem>
                                        <table style={{ width: "100%" }}>
                                            {authState.user && authState.user.is_city_admin === true && (
                                                <tr>
                                                    <td>
                                                        <ListItem
                                                            button
                                                            className={classes.listItem}
                                                            onClick={() => {
                                                                return (
                                                                    this.setState({ open: true }), //route to page config
                                                                    this.handleHelpMenuClose()
                                                                );
                                                            }}>
                                                            <Icon style={{ paddingRight: 7 }}>cloud_upload </Icon>
                                                            <ListItemText primary={`Import Records`} />
                                                        </ListItem>
                                                    </td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td>
                                                    <ListItem
                                                        button
                                                        className={classes.listItem}
                                                        onClick={() => {
                                                            if (!exportModule) { 
                                                                this.setState({ exportModuleAcknowledgement: true });
                                                                this.handleHelpMenuClose(); 
                                                              
                                                            }
                                                            // return ( OLD WAY OPENS ANOTHER WINDOW
                                                            //     // window.open(
                                                            //     //     "/exportpage/page/" + page.id + "/" + authState.user.auth_token,
                                                            //     //     window.location.href + authState.user.auth_token
                                                            //     // ), //route to page config
                                                            //     // this.handleHelpMenuClose()
                                                            // );
                                                        }}>
                                                        <CloudDownloadIcon style={{ paddingRight: 7 }} />
                                                        <ListItemText primary={`Export Records`} />
                                                    </ListItem>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <ListItem
                                                        button
                                                        className={classes.listItem}
                                                        onClick={() => {
                                                            return (
                                                                window.open("/attachments/page/" + page.id + "/" + authState.user.auth_token),
                                                                this.handleHelpMenuClose()
                                                            );
                                                        }}>
                                                        <PhotoAlbumIcon style={{ paddingRight: 7 }} />
                                                        <ListItemText primary={`Export Attachments`} />
                                                    </ListItem>
                                                </td>
                                            </tr>
                                        </table>
                                    </Menu>
                                    <Menu
                                        id="menu-small"
                                        anchorEl={smallAnchor}
                                        classes={{
                                            paper: classes.list,
                                        }}
                                        anchorOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        open={smallMenuOpen}
                                        onClose={this.handleSmallMenuClose}>
                                        <ListItem button className={classes.topListItem}>
                                            <ListItemText
                                                primary={<Typography variant="h6">Page Menu</Typography>}
                                                classes={{
                                                    root: classes.topListText,
                                                    primary: classes.topListText,
                                                }}
                                            />
                                        </ListItem>
                                        {sectionName && sectionName.has_geometry && (
                                            <ListItem
                                                button
                                                component={Link}
                                                to={"/map/" + page.id + "/section/" + sectionName.id + "/advancedsearch/" + 1}
                                                className={classes.listItem}>
                                                <ListItemIcon>
                                                    <Icon>map</Icon>
                                                </ListItemIcon>
                                                <ListItemText primary={`All ${sectionName.name} Geometries`} />
                                            </ListItem>
                                        )}
                                        {sectionName && (
                                            <ListItem
                                                button
                                                component={Link}
                                                to={"/page/" + page.id + "/advancedsearch"}
                                                className={classes.listItem}>
                                                <ListItemIcon>
                                                    <Icon>search</Icon>
                                                </ListItemIcon>
                                                <ListItemText primary={`All ${sectionName.name} Saved Searches`} />
                                            </ListItem>
                                        )}
                                        <ListItem button onClick={this.handleHelpMenu} className={classes.listItem}>
                                            <ListItemIcon>
                                                <Icon>import_export</Icon>
                                            </ListItemIcon>
                                            <ListItemText primary="Import / Export" />
                                        </ListItem>{" "}
                                        {authState.user && authState.user.is_city_admin === true && (
                                            <ListItem button component={Link} to={"/page/" + page.id + "/config"} className={classes.listItem}>
                                                <ListItemIcon>
                                                    <Icon>settings</Icon>
                                                </ListItemIcon>
                                                <ListItemText primary="Module Configuration" />
                                            </ListItem>
                                        )}
                                    </Menu>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* <Grid item xs={12}>
                            <PageAnalytics pageID={page.id} />
                        </Grid> */}
                        <Grid item xs={12}> 
                            {exportModuleAcknowledgement && (
                                <WarningDialog
                                    confirmAction={() => {
                                        this.setState({ exportModule: true, exportModuleAcknowledgement: false });
                                        try {
                                            fetch(`/exportpage/page/${page.id}/${authState.user.auth_token}`, {
                                                method: "GET",
                                                headers: {
                                                    Authorization: "Token " + authState.user.auth_token,
                                                    "Content-Disposition": "attachment",
                                                },
                                            }).then((e) => {
                                                if (e.status === 403) {
                                                    return;
                                                }
                                                return e.blob().then((blob) => {
                                                    var url = window.URL.createObjectURL(blob);
                                                    var a = document.createElement("a");
                                                    a.href = url;
                                                    a.download = `Records Export - ${page.name}.xlsx`;
                                                    document.body.appendChild(a);
                                                    a.click();
                                                    a.remove();

                                                    this.setState({ exportModule: false });
                                                });
                                            });
                                        } catch {
                                            this.setState({ exportModule: false });
                                        }

                                    }}
                                    cancelAction={() => this.setState({ exportModuleAcknowledgement: false })}
                                    open={exportModuleAcknowledgement}
                                    confirmText="Start Export"
                                    cancelText="Export Later" 
                                    title={`Export ${page.name} Module?`}
                                    text={`Exporting modules may take a while depending on the total amount of records. Please wait and dont re-export or refresh the page for up to an hour`}
                                />
                            )}
                            {exportModule && (
                                <Dialog open={exportModule} style={{ minHeight: 250 }}>
                                    <DialogContent style={{ minWidth: 400 }}>
                                        <DialogContentText>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                                    <CircularProgress size={90} className={classes.progress} />
                                                    <Typography variant="h6" style={{ textAlign: "center" }}>
                                                        Exporting {page.name}. This may take a while if there were a lot of records. 
                                                        Please wait...
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </DialogContentText>
                                    </DialogContent>
                                </Dialog>
                            )}
                        </Grid> 
                        <Grid item xs={12} style={{ height: "100%" }}>
                            <ViewRecordTable pageID={page.id} showParentTable={true} />
                        </Grid>
                        {sectionName && (
                            <FormUpload
                                page={page}
                                section={sectionName.id}
                                authState={authState}
                                formm={formm}
                                classes={classes}
                                open={open}
                                returnDialog={() => {
                                    this.setState({ open: false });
                                }}
                            />
                        )} 
                    </Grid>
                </AppContainer>
            );
        }
    }
}
AllRecordsTableContainer.displayName = "AllRecordsTableContainer";

AllRecordsTableContainer = connect(
    (state, ownProps) => ({
        section: getSection(state, ownProps),
        actSection: getSectionId(state, ownProps),
        page: getPage(state, ownProps),
        authState: state.auth, //component wide state prop "authState" gets derived info from database
        permission: getPermission(state, ownProps),
    }),
    null
)(AllRecordsTableContainer);

export default withStyles(styles)(withRouter(AllRecordsTableContainer));
