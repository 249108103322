/*****************************************************************************
 * This component is the bottom wrapper for "editing" or "creating" a new user.
 * It encapsulates all the field data for the user
 *
 * Created by: Robert Gautier
 * Date: 4/2/19
 *****************************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form } from "react-form";

import { withStyles } from "@material-ui/core/styles";
import TextField from "../common/TextField";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { User } from "./models";
import { withRouter } from "react-router-dom";
import Checkbox from "../common/Checkbox";
import HelpLabel from "../common/HelpLabel";

const styles = (theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "22%",
        height: 71,
    },
    wrapHeader: {
        color: "white",
        backgroundColor: "#4e4e4e",
        width: "35%",
        hover: "none",
        fontSize: "16px",
        border: "1px solid black",
        borderRadius: "5px",
        paddingLeft: "11px",
        paddingTop: "5px",
        paddingBottom: "5px",
        marginTop: "-1%",
        marginLeft: ".2%",
        marginBottom: 16,
    },
    //this is needed to adjust styling for "TextFieldWrapper"
    overriddenGrid: {
        paddingLeft: "1.5%",
        backgroundColor: "#dddddd",
        border: "1px solid black",
        borderRadius: "5px",
    },
});

class TextFieldWrapper extends React.Component {
    state = {
        display_error: null,
    };

    //method for passing the error data if there is one
    serializeError(data) {
        return (
            "Error: " +
            Object.values(data)
                .map((err) => err /*[0]*/)
                .join(", ")
        );
    }

    //create a user and pass all the form field values
    userUpdate = (values) => {
        //ormUserCreate, authState props from ORM, continueStep from parent Create.js
        const { ormUserUpdate, authState, continueStep, edit } = this.props;
        if (!edit) values["city_id"] = authState.user.city_id; //only override city for new user created--assume only admins of city will make. Is to prevent django admin user changes from not refreshing window and accidently changing users permission and changing there city

        ormUserUpdate(values);

        continueStep();
    };
    userCreate = (values) => {
        //ormUserCreate, authState props from ORM, continueStep from parent Create.js
        const { ormUserCreate, authState, continueStep, create } = this.props;
        if (create) values["city_id"] = authState.user.city_id;

        //'.then' will return an error if there is one. If there is set the 'display_error'
        // which will be passed to the parent component, otherwise continueStep() which will
        ormUserCreate(values).then((return_message) => {
            if (return_message.name === "Error") {
                const display_error = this.serializeError(JSON.parse(return_message.message));
                this.setState({ display_error: display_error });
            } else {
                continueStep(return_message);
                this.setState({ display_error: null });
            }
        });
    };

    //error validator checks for matching login IDs, matching passwords and makes sure the email is in the correct format
    //this validator is used for creating a NEW USER
    errorValidator = (values) => {
        const validatePassword = (password, password2) => {
            if (password !== password2) return "Passwords do not match";
            else if (!password || password.length < 8) return "Invalid password";
            else return null;
        };
        const validateUsername = (username, username2) => {
            if (!username) return "Username is required";
            if (username !== username2) return "Usernames do not match";
            if (username.includes(" ")) return "Spaces are not allowed in username";
            if (username2.includes(" ")) return "Spaces are not allowed in username";
            else return null;
        };
        const validateState = (state) => {
            if (state && state.length > 0 && state.length !== 2) return "Please enter the 2 letter state code";
            else return null;
        };
        const validateEmail = (email) => {
            var re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !re.test(email) ? "Invalid Email Address" : null;
        };
        const validateUser = (value) => {
            return !value ? "Name is required." : null;
        };
        var valObj = {};

        valObj["name"] = validateUser(values.name);
        valObj["email"] = validateEmail(values.email);
        valObj["state"] = validateState(values.state);
        valObj["password"] = validatePassword(values.password, values.password2);
        valObj["password2"] = validatePassword(values.password, values.password2);
        valObj["username"] = validateUsername(values.username, values.username2);
        valObj["username2"] = validateUsername(values.username, values.username2);

        return valObj;
    };

    //this validator is the same as above "errorValidator"  except the PASSWORD validator is taken out \
    //this validator is only used with the "EDITING" of a user
    errorValidator2 = (values) => {
        const validateUsername = (username) => {
            if (!username) return "Username is required";
            else return null;
        };
        const validateState = (state) => {
            if (state && state.length > 0 && state.length !== 2) return "Please enter the 2 letter state code";
            else return null;
        };
        const validateEmail = (email) => {
            var re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !re.test(email) ? "Invalid Email Address" : null;
        };
        var valObj = {};

        valObj["email"] = validateEmail(values.email);
        valObj["state"] = validateState(values.state);
        valObj["username"] = validateUsername(values.username, values.username2);

        return valObj;
    };

    //reference to the parent to allow the parent to submit the form remotely
    componentDidMount() {
        this.props.onRef(this);
    };

    componentWillUnmount() {
        this.props.onRef(undefined);
    };

    render() {
        //styling
        const { authState, classes, useInfo } = this.props;
        const { display_error } = this.state; //if there is an error display the text from errorValidator method

        return (
            //wrap form around grid, etc to allow for fields to be captured
            <Form
                getApi={(el) => (this.formApi = el)} //creates
                dontValidateOnMount={true}
                validateOnSubmit={true}
                defaultValues={useInfo ? useInfo : {is_active: true}} //default values use "user" which links the FIELDS below
                validateError={useInfo ? this.errorValidator2 : this.errorValidator}
                onSubmit={(values) => useInfo ? this.userUpdate(values) : this.userCreate(values)}>
                {(formApi) => (
                    <form onSubmit={formApi.submitForm}>
                        <Grid container className={classes.overriddenGrid}>
                            <Typography className={classes.wrapHeader}> Login Information</Typography>
                                <Grid item xs={12}>
                                    <TextField
                                        className={classes.textField}
                                        field="username"
                                        type="username"
                                        disabled={useInfo} // This is the only way to edit their login id, but the validator logic won't work
                                    >
                                        <HelpLabel 
                                            inputLabel={<b>Username *</b>}
                                            helpText="Username must be unique across all MS4Front accounts." />
                                    </TextField>
                                    
                                    {!useInfo && (
                                        <TextField
                                            label={<b>Confirm Username *</b>}
                                            className={classes.textField}
                                            field="username2"
                                        />
                                    )}
                                </Grid>
                                {!useInfo && (
                                    <Grid item xs={12}>
                                        <TextField
                                            label={<b>Password (min. 8 char) *</b>}
                                            className={classes.textField}
                                            type="password"
                                            field="password"
                                        />
                                        <TextField
                                            label={<b>Confirm Password *</b>}
                                            className={classes.textField}
                                            type="password"
                                            field="password2"
                                        />
                                    </Grid>
                                )}
                        </Grid>
                        <Grid item xs={12} style={{marginTop:40}} className={classes.overriddenGrid}>
                            <Typography className={classes.wrapHeader}> Basic Details</Typography>
                            <Grid item xs={12}>
                                <TextField
                                    label={<b>Name *</b>}
                                    className={classes.textField}
                                    field="name"
                                />
                                <TextField
                                    label="Title"
                                    className={classes.textField}
                                    field="title"
                                />
                                <TextField
                                    label="Department"
                                    className={classes.textField}
                                    field="department"
                                />
                                <TextField
                                    label="Sub-Department"
                                    className={classes.textField}
                                    field="sub_department"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Mailing Address"
                                    className={classes.textField}
                                    field="address"
                                />
                                <TextField
                                    label="City"
                                    className={classes.textField}
                                    field="city_address"
                                />
                                <TextField
                                    label="State (Two-letter)"
                                    className={classes.textField}
                                    field="state"
                                />
                                <TextField
                                    label="Zip Code"
                                    className={classes.textField}
                                    field="zip"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label={<b>Email Address *</b>}
                                    className={classes.textField}
                                    field="email"
                                />
                                <TextField
                                    label="Second Email"
                                    className={classes.textField}
                                    field="secondary_email"
                                />
                                <TextField label="Phone (office)" className={classes.textField} field="phone_office" />
                                <TextField
                                    label="Phone (cell)"
                                    className={classes.textField}
                                    field="phone_cell"
                                />
                            </Grid>
                            {authState.user.is_city_admin === true && (
                                <Grid item xs={12}>
                                    <Checkbox field="is_city_admin" st={true}>
                                        <HelpLabel inputLabel="Is Account Admin?" helpText="User will be created as an Account Administrator. These users have permission to change configuration settings in the account." />
                                    </Checkbox>

                                    <Checkbox field="contacts_read_only" st={true}>
                                        <HelpLabel inputLabel="Contacts Read Only?" helpText="Contacts access will only be read only." />
                                    </Checkbox>

                                    <Checkbox field="is_active" st={true}>
                                        <HelpLabel
                                            inputLabel="Is Active?"
                                            helpText="Inactive users will not be able to log in to the database, however, their historial records will remain."
                                        />
                                    </Checkbox>
                                </Grid>
                            )}
                            <Typography style={{ color: "red", fontSize: "large" }}>{display_error}</Typography>
                        </Grid>
                    </form>
                )}
            </Form>
        );
    }
}
TextFieldWrapper.displayName = "TextFieldWrapper";
TextFieldWrapper.propTypes = {
    classes: PropTypes.object.isRequired,
};

// This connects the redux-orm "allUsers" function and allows this component to have the props "user"
// The second part loads all orm actions into this pages props for both Page and Section: ormPageCreate, ormSectionCreate, orPageUpdate, etc.
TextFieldWrapper = connect(
    (state) => ({
        authState: state.auth, //component wide state prop "authState" gets derived info from database
    }),
    {
        ...User.actions, //this allows for the ORM functions ormUserCreate(payload), ormUserUpdate(payload), ormUserDelete(id). Payload is the "values (all fields)"" in "updatUser" function
    }
)(TextFieldWrapper);

export default withStyles(styles)(withRouter(TextFieldWrapper));
