import React, { Component } from "react";
import { Form as Formm } from "react-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Toolbar from "@material-ui/core/Toolbar";
import { IconButton } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import WarningDialog from "../common/WarningDialog";
import * as authActions from "../auth/actions";
import { ParentRecord, ContactRecord } from "../page/models";
import FileInput from "./FileInput";
import HelpLabel from "./HelpLabel";
import Switch from "./Switch";

const styles = (theme) => ({
    floatLeft: {
        float: "left",
    },
    floatRight: {
        float: "right",
    },
    flex: {
        flex: 1,
    },
    minWidth2: {
        width: 400,
    },
});

class FormUpload extends Component {
    state = {
        loading: false,
        results: null,
        deleteSelected: false,
        warningOpen: false,
    };
    formKey = window.performance.now();

    uploadDocument = (values, formApi) => {
        var { authState, history, page, /* ormParentRecordReload, ormContactRecordReload,*/ authLogout } = this.props;
        this.setState({ loading: true, results: "Currently Uploading... Please Wait" });
        var component = this;

        values["unique_token"] = Math.random().toString(36).substring(2, 15);

        var ping_server = setInterval(function () {
            fetch("importpage/ping/" + values["unique_token"], {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + authState.user.auth_token,
                },
            }).then((e) => {
                if (e.status === 401) {
                    history.push("/");
                    authLogout();
                } else if (e.status !== 500) {
                    e.json().then((data) => {
                        component.setState({ results: data.section_name + ": Row " + data.current_count + " out of " + data.total_count });
                    });
                }
            });
        }, 8000);

        fetch("importpage/page/" + page.id, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token,
            },
            body: JSON.stringify(values),
        }).then((e) => {
            if (e.status === 401) {
                history.push("/");
                authLogout();
                // throw new Error("Upload Error");
            } else if (e.status === 500) {
                this.setState({ loading: false, results: "Upload Failed" });
            } else {
                e.json().then((data) => {
                    formApi.resetAll();
                    this.formKey = window.performance.now();
                    // ormParentRecordReload().then(() => {
                    this.setState({ loading: false, results: data.message });
                    // });
                    // ormContactRecordReload();
                });
            }
            clearInterval(ping_server);
        });
    };

    deleteOldCookies = () => {
        const { page } = this.props;
        //delete all cookies for this page as we are now importing in new
        var cookie = document.cookie;
        if (cookie || cookie === "") {
            var isCookie = true;
            while (isCookie) {
                //find all instances of cookie and delete them as we are importing
                if (cookie.indexOf("pageid" + page.id) > -1) {
                    let startIdx = cookie.indexOf("pageid" + page.id);
                    let endIdx = cookie.indexOf("=", startIdx);
                    // let endIdx = cookie.indexOf(";", startIdx);

                    let thisCookie = cookie.slice(startIdx, endIdx);

                    document.cookie = `${thisCookie}=page=${page.id}: rowsPerPage=25; path=/; expires=Wed, 12 Oct 2000 08:50:17 UTC`;
                    cookie = document.cookie;

                    //no more page cookies!
                    if (startIdx === -1) {
                        isCookie = false;
                    }
                } else isCookie = false;
            }
        }
    };

    errorValidator = (values) => {
        const isRequired = (val) => {
            return !val ? "Required" : null;
        };
        var valObj = {
            file: isRequired(values.file),
        };

        return valObj;
    };

    closeDialog = () => {
        const { returnDialog, history, page, section } = this.props;

        if (section) history.push("/page/" + page.id + "/" + section + "/table");
        this.setState({ results: null, loading: false });
        returnDialog();
    };

    render() {
        const { classes, open } = this.props;
        const { loading, results, warningOpen, deleteSelected } = this.state;

        return (
            <Dialog open={open} classes={{ paper: classes.minWidth2 }}>
                <Toolbar style={{ backgroundColor: "lightgray" }}>
                    <Typography variant="h5" className={classes.flex}>
                        Upload New Records
                    </Typography>
                    <IconButton aria-label="Close Dialog" onClick={this.closeDialog}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <DialogContentText>
                        <Formm
                            key={this.formKey}
                            getApi={(el) => (this.formApi = el)}
                            dontValidateOnMount={true}
                            validateOnSubmit={true}
                            validateError={this.errorValidator}
                            onSubmit={(values, e, formApi) => this.uploadDocument(values, formApi)}>
                            {(formApi) => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FileInput
                                                field="file"
                                                id="file_upl"
                                                fullWidth
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Module Record Spreadsheet"
                                                        helpText="Upload an Excel spreadsheet (.xlsx) containing your records for each row. The column names should match the spreadsheet generated from the 'Module Download' button."
                                                    />
                                                }
                                            />
                                            <Switch
                                                field="delete_current"
                                                name="delete_current"
                                                label="Remove All Existing Records for this Module?"
                                                eventHandle={(e) => this.setState({ deleteSelected: e })}
                                            />
                                            <br />
                                            {loading && (
                                                <CircularProgress size={24} style={{ display: "table", marginLeft: "auto", marginRight: "auto" }} />
                                            )}
                                            {results && <Typography style={{ textAlign: "center" }}>{results}</Typography>}
                                            {!loading && (
                                                <>
                                                    <Button
                                                        onClick={(e) =>
                                                            deleteSelected ? this.setState({ warningOpen: true }) : this.formApi.submitForm()
                                                        }
                                                        variant="raised"
                                                        color="primary"
                                                        className={classes.floatLeft}>
                                                        Upload
                                                    </Button>
                                                    <Button
                                                        onClick={this.closeDialog}
                                                        type="button"
                                                        variant="raised"
                                                        color="primary"
                                                        className={classes.floatRight}>
                                                        Close
                                                    </Button>
                                                </>
                                            )}

                                            <WarningDialog
                                                confirmAction={() => {
                                                    this.setState({ warningOpen: false });
                                                    this.deleteOldCookies();
                                                    this.formApi.submitForm();
                                                }}
                                                cancelAction={(e) => {
                                                    this.setState({ warningOpen: false, deleteSelected: false });
                                                    this.formApi.setValue(`delete_current`, false);
                                                }}
                                                confirmText="Yes"
                                                cancelText="No"
                                                open={warningOpen}
                                                title="Remove All Existing Records for this Module?"
                                                text={
                                                    "WARNING: You are about to permanently delete data. Are you sure you want to remove all of the existing data on this module?"
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formm>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}
FormUpload.displayName = "FormUpload";
FormUpload = connect(
    () => null,

    {
        ...ParentRecord.actions,
        ...ContactRecord.actions,
        ...authActions,
    }
)(FormUpload);

export default withStyles(styles)(withRouter(FormUpload));
