import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Form as Formm } from "react-form";

import { withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";

import TopButtons from "../page/form/TopButtons";
// import { Form } from "react-form";
import RecordSearch from "../home/RecordSearch";
// import ReactSelect from "../../common/ReactSelectAsync";
import { createSelector } from "../common/orm";
import { PageAnalytics } from "../page/models";
import SimpleCard from "../common/Card";
import * as authActions from "../auth/actions";
// import HelpLabel from "../common/HelpLabel";

const styles = (theme) => ({
    expandedMargin: {
        marginTop: "12px",
    },
    titlePanel: {
        minHeight: "auto !important",
    },
    hideIfSmall: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    root: {
        flexBasis: "28%",
        maxWidth: "25%",
        marginBottom: -28,
        [theme.breakpoints.down(1700)]: {
            flexBasis: "35%",
            maxWidth: "31%",
        },
        // [theme.breakpoints.between("1700", "2300")]: {

        // },
        [theme.breakpoints.up(2300)]: {
            flexBasis: "20%",
        },
    },
});

//get the pageID from the URL
const getPageId = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (session, pageID) => {
        //return pageID; //result function
        return session.Page.filter((p) => p.id === pageID).toRefArray();
    }
);

const getAllSections = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (state, ownProps) => parseInt(ownProps.match.params["pageId"]),
    (session, id, pageId) => {
        if (isNaN(id)) {
            return session.Section.filter((sect) => sect.page === pageId)
                .orderBy("order")
                .toRefArray();
        } else {
            return session.Section.filter((sect) => sect.page === id)
                .orderBy("order")
                .toRefArray();
        }
    }
);

//get permissions
const getPermission = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (session, pageID) => {
        return session.Permission.filter((p) => p.page === pageID).toRefArray();
    } //toRefArray returns a plain JavaScript object which are references to the DB
);

const getAdvancedGroups = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, pageID) => {
        return session.AdvancedGroup.filter((r) => r.page === pageID)
            .orderBy("order")
            .toModelArray()
            .map((advancedGroup) => ({
                advancedSearch: advancedGroup.advancedsearch
                    .all()
                    .orderBy("order")
                    .toModelArray()
                    .map((advancedsearch) => ({
                        pageanalytics: advancedsearch.pageanalytics.all().orderBy("order").toRefArray(),
                        ...advancedsearch.ref,
                    })),
                ...advancedGroup.ref,
            }));
    }
);

const getAdvancedFilters = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, pageID) => {
        return session.AdvancedSearch.filter((r) => r.page === pageID)
            .orderBy("id")
            .toModelArray()
            .map((advancedSearch) => ({
                advancedFilter: advancedSearch.advancedfilter.all().orderBy("id").toRefArray(),
                ...advancedSearch.ref,
            }));
    } //toRefArray returns a plain JavaScript object which are references to the DB
);

const getPageFields = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (session, actPage) => {
        return session.Section.filter((sect) => sect.page === actPage)
            .orderBy("id")
            .toModelArray()
            .map((section) => ({
                form: section.forms //form.groups is FK linkage
                    .all()
                    .orderBy("id")
                    .toModelArray()
                    .map((form) => ({
                        groups: form.groups //form.groups is FK linkage
                            .all()
                            .orderBy("id")
                            .toModelArray()
                            .map((group) => ({
                                fields: group.fields.all().orderBy("order").toRefArray(),
                                ...group.ref, // take all groups as a reference and tie in each field
                            })),
                        ...form.ref, // take all forms as a reference and tie in each field
                    })),
                ...section.ref,
            }));
    } //toRefArray returns a plain JavaScript object which are references to the DB
);

class Analytics extends Component {
    constructor(props) {
        super(props);
        const { pagefields } = this.props;

        var childFields = [];
        //get all child form fields
        var child = pagefields.filter((p) => {
            if (!p.parent) {
                return p;
            }
            return null;
        });
        child.forEach((c) => {
            c.form.forEach((f) => {
                f.groups.forEach((g) => {
                    g.fields.forEach((k) => {
                        childFields.push(k.name);
                    });
                });
            });
        });

        //get all parent form fields
        var parentFields = [];
        const parent = pagefields.filter((p) => {
            if (p.parent) {
                return p;
            }
            return null;
        });
        parent.forEach((c) => {
            c.form.forEach((f) => {
                f.groups.forEach((g) => {
                    g.fields.forEach((k) => {
                        parentFields.push(k.name);
                    });
                });
            });
        });
        //this is to get the 'columnNames' for the selected section so that the 'dialog dropdown' can be populated.

        // Remove the duplicates from tempFields
        this.state = {
            formCleanUp: [...new Set(childFields)],
            shownFilters: [],
            dialogStatus: false,
            loading: false,
            advancedSearchLandingPageTable: false,
            deleteDialogOpen: false,
            searchToDelete: null,
            groupDelete: false,
            totalSectionCounts: 0,
        };

        // I don't even...
        this.formKey = window.performance.now();
    }
    typingTimer;

    componentDidMount() {
        const { advancedSearchLandingPageTable } = this.props;
        if (!advancedSearchLandingPageTable) this.setState({ advancedSearchLandingPageTable: true });
        setTimeout(() => {
            this.loadTotalRecords();
        }, 500);
    }

    componentDidUpdate(prevProps) {
        const { page } = this.props;

        if (page && prevProps && prevProps.page[0].id !== page[0].id) {
            this.setState({ totalSectionCounts: 0 });
            this.loadTotalRecords();
        }
    }

    //we will load the total records for each sections
    loadTotalRecords = (_filters) => {
        const { authState, history, sections, authLogout } = this.props;
        var component = this;

        //get all section ids
        var allSections = [];
        sections.filter((s) => !s.is_master_contact_form && !s.iframe_url_section).forEach((s) => allSections.push(s.id));
        // get all the section total records
        if (sections.length > 0) {
            fetch("/count/recordsections/dashboard/" + allSections, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + authState.user.auth_token,
                },
            }).then((e) => {
                if (e.status === 401) {
                    history.push("/");
                    authLogout();
                } else if (e.status !== 500) {
                    e.json().then((data) => {
                        component.setState({ totalSectionCounts: data.message });
                    });
                }
            });
        }
    };
    showFilter = (value) => {
        const { dummyState } = this.props;
        var { shownFilters } = this.state;
        shownFilters.push(value);
        this.formKey = window.performance.now();
        //this.formApi.resetAll();
        this.setState({ shownFilters });
        dummyState();
    };

    returnDialog = () => {
        this.setState({ loading: false });
    };

    defaultSearchValues = () => {
        let obj = {};
        obj["field_to_search"] = 111;

        return obj;
    };

    render() {
        const { classes, page, advancedgroups, authState, permission, ormPageAnalyticsUpdate, sections } = this.props;
        const { totalSectionCounts } = this.state;
        if (authState.user) {
            var readOnly;
            var perm = permission.find((p) => {
                if (p.user === authState.user.id) {
                    return p;
                }
                return null;
            });
            if (perm) readOnly = perm.readonly || perm.readonlyexternal ? true : false;
            if (readOnly === undefined) readOnly = false;
        }
        var parentSection = sections.find((s) => (s.parent === true ? s : null));
        //check if we should show analytics
        var hasAnalytics = false;
        advancedgroups.map((a, index) => {
            return a.advancedSearch.map((s) => {
                if (s.analytics_view) {
                    return (hasAnalytics = true);
                } else return null;
            });
        });
        return (
            <>
                <Grid container spacing={1}>
                    <Grid item xs={12} style={{ display: "inline-flex" }}>
                        <Grid item xs={12} sm={7} style={{ marginTop: 7 }}>
                            {page && page[0] && <Typography variant="h5"> {`${page[0].name} Dashboard`}</Typography>}
                            {!page && <Typography variant="h5"> Dashboard </Typography>}
                        </Grid>
                        {parentSection && (
                            <Grid item xs={12} sm={5} style={{ paddingBottom: 12, marginTop: -7 }}>
                                <TopButtons perm={perm} section={parentSection} page={page[0]} authState={authState} readOnly={false} fromAnalytics={true} />
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={12} style={{ display: "flex", marginTop: 5 }}>
                        <Typography variant="subtitle1" style={{ marginTop: -32, marginLeft: 1, paddingRight: 11, color: "#262626" }}>
                            Go To Section:
                        </Typography>
                        {totalSectionCounts.length > 0 &&
                            totalSectionCounts.map((m, i) => {
                                return (
                                    <Tooltip key={i} title={m.SectionName + " Table View - (number of records in section)"}>
                                        <Link
                                            style={{ textDecoration: "underline", paddingRight: 10, marginTop: -29 }}
                                            to={`/page/${page[0].id}/${m.SectionId}/table`}>
                                            {m.SectionName + ` (` + m.TotalCount + ")  "}
                                        </Link>
                                    </Tooltip>
                                );
                            })}
                        {(totalSectionCounts === 0 || totalSectionCounts.length === 0) && (
                            <CircularProgress
                                size={19}
                                className={classes.progress}
                                style={{
                                    marginTop: -29,
                                    color: "#000",
                                }}
                            />
                        )}
                    </Grid>
                </Grid>
                {/* Quick Search Feature */}
                <RecordSearch page={page} />

                {hasAnalytics && (
                    <Formm key={this.formKey} dontValidateOnMount={true} validateOnSubmit={true} getApi={(el) => (this.formApi = el)}>
                        {(formApi) => (
                            <form onSubmit={formApi.submitForm}>
                                <Accordion defaultExpanded={true} className={classes.hideIfSmall}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        style={{ backgroundColor: "#cbcbcb" }}
                                        classes={{ root: classes.expandedMargin }}
                                        className={classes.titlePanel}>
                                        <Icon style={{ color: "#66686a" }}>
                                            <ShowChartIcon />
                                        </Icon>
                                        <Grid container spacing={1}>
                                            <Grid item xs={11}>
                                                <Typography variant="subtitle1" style={{ marginLeft: 12, color: "#66686a" }}>
                                                    Module Analytics
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ paddingBottom: 0 }}>
                                        <Grid container spacing={1}>
                                            {advancedgroups.map((a) => {
                                                return a.advancedSearch.map((s) => {
                                                    if (s.analytics_view) {
                                                        return s.pageanalytics.map((p) => {
                                                            return (
                                                                <Grid key={p.id}item xs={12} md={6} lg={4} xl={3}>
                                                                    <SimpleCard
                                                                        pageanalytics={p}
                                                                        advsearch={s}
                                                                        authState={authState}
                                                                        page={page}
                                                                        ormPageAnalyticsUpdate={ormPageAnalyticsUpdate}
                                                                        sections={sections}
                                                                    />
                                                                </Grid>
                                                            );
                                                        });
                                                    } else return null;
                                                });
                                            })}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </form>
                        )}
                    </Formm>
                )}
            </>
        );
        // } else return null;
    }
}
Analytics.displayName = "Analytics";

Analytics = connect(
    (state, ownProps) => ({
        page: getPageId(state, ownProps),
        sections: getAllSections(state, ownProps),
        pagefields: getPageFields(state, ownProps),
        advancedgroups: getAdvancedGroups(state, ownProps),
        advancedfilter: getAdvancedFilters(state, ownProps),
        permission: getPermission(state, ownProps),
        authState: state.auth,
    }),
    {
        ...PageAnalytics.actions,
        ...authActions,
    }
)(Analytics);

export default withStyles(styles)(withRouter(Analytics));
