import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import Toolbar from "@material-ui/core/Toolbar";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { withStyles } from "@material-ui/core";

import Close from "@material-ui/icons/Close";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AppContainer from "../common/AppContainer";
import TextField from "../common/TextField";
import EnhancedTableHead from "../common/EnhancedTableHead";
import CustomTableCell from "../common/TableCell";
import HelpLabel from "../common/HelpLabel";
import Select from "../common/Select";
import FileInput from "../common/FileInput";
import { createSelector } from "../common/orm";
import Snackbar from "../common/Snackbar";
import WarningDialog from "../common/WarningDialog";
import { LetterTemplate } from "../page/models";

const allTemplates = createSelector((schema) => {
    return schema.LetterTemplate.all().orderBy("name").toRefArray();
});
const getSections = createSelector((schema) => {
    return schema.Section.orderBy(["page", "order"]).toModelArray();
});

const styles = (theme) => ({
    centerAlign: {
        textAlign: "center",
    },
    minWidth: {
        minWidth: 400,
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        margin: "0 8px 0 0",
    },
    flex: {
        flex: 1,
    },
    linkColor: {
        color: "#2b6dad",
        textDecoration: "none",
        "&:hover": {
            backgroundColor: "#F9EBC8",
        },
    },
    root: {
        flexBasis: "100%",
        maxWidth: "100%",
    },
});

const columnData = [
    { id: "actions", numeric: false, label: "", allowSort: false },
    { id: "name", numeric: false, label: "Name", allowSort: true },
    { id: "section", numeric: false, label: "Section", allowSort: true },
    { id: "template", numeric: false, label: "Template", allowSort: false },
];

class LetterTemplates extends Component {
    state = {
        layerDialogOpen: false,
        snackbarOpen: false,
        activeTemplate: {},
        deleteDialogOpen: false,
        selectedSection: null,
        order: "asc",
        orderBy: "name",
    };

    errorValidator = (values) => {
        const { templates } = this.props;
        const isRequired = (val) => {
            return !val ? "Required" : null;
        };
        var valObj = {
            name: isRequired(values.name),
            section: isRequired(values.section),
            template: isRequired(values.template),
        };
        templates.filter(ttt => ttt.id !== values.id).forEach((t) => {
            if (values.name === t.name) valObj["name"] = "Templates must have a unique name";
        });
        return valObj;
    };

    updateLayer = (values) => {
        const { ormLetterTemplateCreate, ormLetterTemplateUpdate, authState } = this.props;
        if (values.id) {
            ormLetterTemplateUpdate({
                id: values.id,
                ...values,
            });
        } else {
            ormLetterTemplateCreate({
                city_id: authState.user.city_id,
                ...values,
            });
        }
        this.setState({ layerDialogOpen: false });
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        if (orderBy === "section") {
            order === "desc"
                ? this.props.templates.sort((a, b) => (b[orderBy]["name"].toUpperCase() < a[orderBy]["name"].toUpperCase() ? -1 : 1))
                : this.props.templates.sort((a, b) => (a[orderBy]["name"].toUpperCase() < b[orderBy]["name"].toUpperCase() ? -1 : 1));

        } else {
            order === "desc"
                ? this.props.templates.sort((a, b) => (b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1))
                : this.props.templates.sort((a, b) => (a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1));
        }


        this.setState({ order, orderBy });
    };

    MAKE_OPTIONS_CUST = (table) => {
        return table.map((row) => ({
            label: row.name + " (" + row.page.name + ")",
            value: row.id,
        }));
    };

    switchSection = (s) => {
        this.setState({ selectedSection: s });
    };

    render() {
        const { classes, templates, ormLetterTemplateDelete, sections, authState } = this.props;
        const { layerDialogOpen, snackbarOpen, activeTemplate, deleteDialogOpen, order, orderBy, selectedSection } = this.state;

        return (
            <AppContainer authenticated>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={10} classes={{ root: classes.root }}>
                        <Typography variant="h6" gutterBottom>
                            Letter & Report Templates
                        </Typography>
                        <Typography gutterBottom>
                            On this page you may upload custom reports or letters that contain placeholders which are populated with data from
                            MS4Front. When setting up a new template a list of valid placeholders will be available to download. Insert these
                            placeholder values into your custom word document. Once uploaded the template will be available to download for each
                            record associated with the selected section.
                        </Typography>
                        <Button
                            onClick={() => this.setState({ layerDialogOpen: true, activeTemplate: {}, selectedSection: null })}
                            fullWidth
                            variant="contained"
                            color="primary">
                            <AddCircleOutlineIcon />
                            &nbsp;&nbsp;&nbsp;Add New Template
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={10} classes={{ root: classes.root }}>
                        <Table className={classes.minWidth}>
                            <EnhancedTableHead columnData={columnData} order={order} orderBy={orderBy} onRequestSort={this.handleRequestSort} />
                            <TableBody>
                                {templates.map((l) => {
                                    var found_section = sections.find(sss => sss.id === l.section);
                                    if(found_section){
                                        return (
                                            <TableRow key={l.id} hover>
                                                <CustomTableCell>
                                                    <Tooltip title="Edit">
                                                        <Button
                                                            onClick={ () => {
                                                                this.setState({
                                                                    activeTemplate: l,
                                                                    layerDialogOpen:true,
                                                                    selectedSection: null
                                                                });
                                                            }}
                                                            className={ classes.deleteWidth }>
                                                            <EditIcon color="primary" />
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip title="Delete">
                                                        <Button
                                                            onClick={() => this.setState({ deleteDialogOpen: true, activeTemplate: l })}
                                                            className={classes.deleteWidth}>
                                                            <DeleteIcon />
                                                        </Button>
                                                    </Tooltip>
                                                </CustomTableCell>
                                                <CustomTableCell>{l.name}</CustomTableCell>
                                                <CustomTableCell>({found_section.name})</CustomTableCell>
                                                <CustomTableCell>
                                                    <a className={classes.linkColor} href={l.template} target="_blank" rel="noopener noreferrer">
                                                        Open Template
                                                    </a>
                                                </CustomTableCell>
                                            </TableRow>
                                        );
                                    }else return null
                                    
                                })}
                                {templates.length < 1 && (
                                    <TableRow>
                                        <CustomTableCell colSpan={4} className={classes.centerAlign}>
                                            No Templates Defined
                                        </CustomTableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item xs={12} md={10} lg={8}>
                        <Typography variant="h6">Download Placeholder Template</Typography>
                        <Form dontValidateOnMount={true} validateOnSubmit={true}>
                            {(formApi) => (
                                <form onSubmit={formApi.submitForm}>
                                    <Select
                                        field="section"
                                        eventHandle={this.switchSection}
                                        options={this.MAKE_OPTIONS_CUST(sections)}
                                        label="Select Section..."
                                        style={{ width: 300 }}
                                    />
                                    {selectedSection && (
                                        <Button
                                            style={{ display: "block" }}
                                            onClick={() => window.open("/reports/placeholders/" + authState.user.city_id + "/" + selectedSection)}>
                                            Download Template Placeholder for Section
                                        </Button>
                                    )}
                                </form>
                            )}
                        </Form>
                    </Grid>
                </Grid>
                <Dialog open={layerDialogOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar>
                        <Typography variant="h5" className={classes.flex}>
                            {Object.keys(activeTemplate).length > 0 ? "Edit" : "Add New"} Template
                        </Typography>
                        <IconButton onClick={() => this.setState({ layerDialogOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent>
                        <DialogContentText>
                            <Form
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                validateError={this.errorValidator}
                                defaultValues={activeTemplate}
                                onSubmit={(values) => this.updateLayer(values)}>
                                {(formApi) => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField field="name" label="Template Name" fullWidth />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Select
                                                    field="section"
                                                    eventHandle={this.switchSection}
                                                    options={this.MAKE_OPTIONS_CUST(sections)}
                                                    label="Section"
                                                    fullWidth
                                                />
                                                {selectedSection && (
                                                    <Button
                                                        onClick={() =>
                                                            window.open("/reports/placeholders/" + authState.user.city_id + "/" + selectedSection)
                                                        }>
                                                        Download Valid Template Placeholders for Section
                                                    </Button>
                                                )}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FileInput customaccept=".docx" field="template" id="template" fullWidth />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            field="signature_height"
                                                            unitsVisual="inches"
                                                            useNumberFormat
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Signature Height (optional)"
                                                                    helpText="The height of how signatures appear on this report in inches. The default is 1 inch."
                                                                />
                                                            }
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            field="attachment_height"
                                                            unitsVisual="inches"
                                                            useNumberFormat
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Attachment Height (optional)"
                                                                    helpText="The height of how attachments appear on this report in inches. The default is 3 inches."
                                                                />
                                                            }
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    field="export_name"
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Export File Name (optional)"
                                                            helpText="When generating files from the system you can define a custom name to overwrite the default name the file is named as. Use the same template parameters as within the report. Ex: {{field_xxx}}"
                                                        />
                                                    }
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} className={classes.centerAlign}>
                                                <Button type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Snackbar
                    snackbarOpen={snackbarOpen}
                    handleSnackbarClose={() => this.setState({ snackbarOpen: false })}
                    isSuccess
                    operationType="setting"
                />
                <WarningDialog
                    confirmAction={() => {
                        ormLetterTemplateDelete(activeTemplate.id);
                        this.setState({ deleteDialogOpen: false });
                    }}
                    cancelAction={() => this.setState({ deleteDialogOpen: false })}
                    open={deleteDialogOpen}
                    title="Delete Template"
                    text={"Are you sure you wish to permanently delete " + activeTemplate.name + "?"}
                />
            </AppContainer>
        );
    }
}
LetterTemplates.displayName = "LetterTemplates";

LetterTemplates = connect(
    (state) => ({
        templates: allTemplates(state),
        sections: getSections(state),
        authState: state.auth,
    }),
    {
        ...LetterTemplate.actions,
    }
)(LetterTemplates);

export default withStyles(styles)(withRouter(LetterTemplates));
