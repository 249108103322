import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CircularProgress from "@material-ui/core/CircularProgress";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core";

import ViewIcon from "@material-ui/icons/Visibility";
import Close from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import ReportIcon from "@material-ui/icons/Assignment";

import AppContainer from "../common/AppContainer";
import { createSelector } from "../common/orm";
import TextField from "../common/TextField";
import Select from "../common/Select";
import DatePicker from "../common/DatePicker";
import HelpLabel from "../common/HelpLabel";
import EnhancedTableHead from "../common/EnhancedTableHead";
import CustomTableCell from "../common/TableCell";
import { Application, ApplicationRecordStatus, ApplicationRecord, ParentRecord } from "../page/models";

const styles = (theme) => ({
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8,
        marginTop: 2,
        marginBottom: 2,
    },
    centerAlign: {
        textAlign: "center",
    },
    nowrap: {
        whiteSpace: "nowrap",
    },
    dialogMin: {
        minWidth: 400,
    },
    minWidth: {
        minWidth: 600,
    },
    flex: {
        flex: 1,
    },
});

function MAKE_OPTIONS(table) {
    return table.map((row) => ({
        label: row,
        value: row,
    }));
}

const getApplication = Application.selectByUrlId();
const getApplicationRecords = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, application_id) => {
        return session.ApplicationRecord.filter((p) => p.application === application_id)
            .orderBy("date_real", "desc")
            .toRefArray();
    }
);

class Records extends Component {
    state = {
        order: "desc",
        orderBy: "date",
        statusDialogOpen: false,
        record: null,
        _status: null,
        dialogLoading: false,
        formKey: window.performance.now(),
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        if (orderBy === "status") {
            order === "desc"
                ? this.props.application_records.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
                : this.props.application_records.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));
        } else if (orderBy === "date") {
            order === "desc"
                ? this.props.application_records.sort((a, b) => (new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1))
                : this.props.application_records.sort((a, b) => (new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1));
        } else {
            order === "desc"
                ? this.props.application_records.sort((a, b) => {
                      var c = a.columns.find((z) => z.name === orderBy);
                      var d = b.columns.find((z) => z.name === orderBy);
                      if (d.value < c.value) return -1;
                      else return 1;
                  })
                : this.props.application_records.sort((a, b) => {
                      var c = a.columns.find((z) => z.name === orderBy);
                      var d = b.columns.find((z) => z.name === orderBy);
                      if (c.value < d.value) return -1;
                      else return 1;
                  });
        }

        this.setState({ order, orderBy });
    };

    errorValidatorStatus = (values) => {
        const isRequired = (val) => {
            return !val ? "Required" : null;
        };
        var valObj = {
            status: isRequired(values.status),
        };

        return valObj;
    };

    changeStatus = (val) => {
        this.setState({ _status: val });
    };

    defaultValues = () => {
        const { record } = this.state;
        const { application } = this.props;
        var defaultValues = {};

        if (application.id === 4 && record) {
            defaultValues["board_date"] = record.board_date;
        }
        return defaultValues;
    };
    
    componentDidMount=()=>{
        const { ormApplicationRecordReload } = this.props;
        ormApplicationRecordReload();
    }
    
    updateStatus = (values) => {
        const { ormApplicationRecordStatusCreate, ormApplicationRecordReload, ormParentRecordReload } = this.props;
        const { record } = this.state;
        this.setState({ dialogLoading: true });

        ormApplicationRecordStatusCreate({
            applicationrecord: record.id,
            ...values,
        }).then((rec) => {
            ormApplicationRecordReload();
            ormParentRecordReload(); //JJ--are we really needing to pull all parents back in?
            this.setState({ statusDialogOpen: false, dialogLoading: false });
        });
    };

    render() {
        const { application, classes, application_records } = this.props;
        const { orderBy, order, statusDialogOpen, formKey, record, _status, dialogLoading } = this.state;

        var columnData = [
            { id: "view", numeric: false, label: "View", allowSort: false, width: "60px" },
            { id: "update", numeric: false, label: "Update", allowSort: false, width: "60px" },
            { id: "report", numeric: false, label: "Report", allowSort: false, width: "60px" },
        ];

        if (application_records.length > 0) {
            application_records[0].columns.forEach(function (col) {
                columnData.push({ id: col.name, numeric: false, label: col.name, allowSort: true });
            });
        }

        columnData.push({ id: "status", numeric: false, label: "Current Status", allowSort: true });
        columnData.push({ id: "date", numeric: false, label: "Date", allowSort: true });

        return (
            <AppContainer authenticated>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{application.application_name} Applications</Typography>
                        <Typography>
                            <a className={classes.linkColor} href={"/#/applications/" + application.link} target="_blank" rel="noopener noreferrer">
                                Open Public Form
                            </a>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Table className={classes.table}>
                            <EnhancedTableHead columnData={columnData} order={order} orderBy={orderBy} onRequestSort={this.handleRequestSort} />
                            <TableBody>
                                {application_records
                                    .filter((a) => a.is_historic === false)
                                    .map((n) => {
                                        return (
                                            <TableRow hover>
                                                <CustomTableCell className={classes.nowrap}>
                                                    <Tooltip title="View">
                                                        <a
                                                            className={classes.linkColor}
                                                            href={"/#/applications/" + n.link + "/" + n.hash}
                                                            target="_blank"
                                                            rel="noopener noreferrer">
                                                            <Button color="primary" className={classes.deleteWidth}>
                                                                <ViewIcon color="primary" />
                                                            </Button>
                                                        </a>
                                                    </Tooltip>
                                                </CustomTableCell>
                                                <CustomTableCell className={classes.nowrap}>
                                                    <Tooltip title="Update Status">
                                                        <Button
                                                            color="primary"
                                                            onClick={() =>
                                                                this.setState({
                                                                    formKey: window.performance.now(),
                                                                    statusDialogOpen: true,
                                                                    record: n,
                                                                })
                                                            }
                                                            className={classes.deleteWidth}>
                                                            <EditIcon color="primary" />
                                                        </Button>
                                                    </Tooltip>
                                                </CustomTableCell>
                                                <CustomTableCell className={classes.nowrap}>
                                                    <Tooltip title="Report">
                                                        <Button
                                                            color="primary"
                                                            onClick={() => window.open("/reports/application/" + n.hash)}
                                                            className={classes.deleteWidth}>
                                                            <ReportIcon color="primary" />
                                                        </Button>
                                                    </Tooltip>
                                                </CustomTableCell>
                                                {n.columns &&
                                                    n.columns.map((o) => {
                                                        return <CustomTableCell>{o.value}</CustomTableCell>;
                                                    })}
                                                <CustomTableCell>{n.status}</CustomTableCell>
                                                <CustomTableCell>{n.date}</CustomTableCell>
                                            </TableRow>
                                        );
                                    })}
                                {application_records.filter((a) => a.is_historic === false).length < 1 && (
                                    <TableRow>
                                        <CustomTableCell colSpan={columnData.length} className={classes.centerAlign}>
                                            No Applications Submitted
                                        </CustomTableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item xs={12}>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                <Typography variant="subtitle1" gutterBottom>
                                    Historic Applications
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                <Table className={classes.table}>
                                    <EnhancedTableHead
                                        columnData={columnData}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={this.handleRequestSort}
                                    />
                                    <TableBody>
                                        {application_records
                                            .filter((a) => a.is_historic === true)
                                            .map((n) => {
                                                return (
                                                    <TableRow hover>
                                                        <CustomTableCell className={classes.nowrap}>
                                                            <Tooltip title="View">
                                                                <a
                                                                    className={classes.linkColor}
                                                                    href={"/#/applications/" + n.link + "/" + n.hash}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer">
                                                                    <Button color="primary" className={classes.deleteWidth}>
                                                                        <ViewIcon color="primary" />
                                                                    </Button>
                                                                </a>
                                                            </Tooltip>
                                                        </CustomTableCell>
                                                        <CustomTableCell className={classes.nowrap}>
                                                            <Tooltip title="Update Status">
                                                                <Button
                                                                    color="primary"
                                                                    onClick={() =>
                                                                        this.setState({
                                                                            formKey: window.performance.now(),
                                                                            statusDialogOpen: true,
                                                                            record: n,
                                                                        })
                                                                    }
                                                                    className={classes.deleteWidth}>
                                                                    <EditIcon color="primary" />
                                                                </Button>
                                                            </Tooltip>
                                                        </CustomTableCell>
                                                        <CustomTableCell className={classes.nowrap}>
                                                            <Tooltip title="Report">
                                                                <Button
                                                                    color="primary"
                                                                    onClick={() => window.open("/reports/application/" + n.hash)}
                                                                    className={classes.deleteWidth}>
                                                                    <ReportIcon color="primary" />
                                                                </Button>
                                                            </Tooltip>
                                                        </CustomTableCell>
                                                        {n.columns &&
                                                            n.columns.map((o) => {
                                                                return <CustomTableCell>{o.value}</CustomTableCell>;
                                                            })}
                                                        <CustomTableCell>{n.status}</CustomTableCell>
                                                        <CustomTableCell>{n.date}</CustomTableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        {application_records.filter((a) => a.is_historic === true).length < 1 && (
                                            <TableRow>
                                                <CustomTableCell colSpan={columnData.length} className={classes.centerAlign}>
                                                    No Historic Applications
                                                </CustomTableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
                <Dialog open={statusDialogOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.flex}>
                            Update Status
                        </Typography>
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({ statusDialogOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <DialogContentText>
                            <Form
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                key={formKey}
                                validateError={this.errorValidatorStatus}
                                defaultValues={this.defaultValues()}
                                onSubmit={(values) => this.updateStatus(values)}>
                                {(formApi) => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography>Status History</Typography>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <td>Status</td>
                                                            <td>Date</td>
                                                            <td>Conditions</td>
                                                        </tr>
                                                    </thead>
                                                    {record.history.map((n) => {
                                                        return (
                                                            <tr>
                                                                <td>{n.status}</td>
                                                                <td>{n.date}</td>
                                                                <td>{n.conditions}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </table>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {application.id === 1 && (
                                                    <Select
                                                        field="status"
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Status"
                                                                helpText={
                                                                    <ul>
                                                                        <li>
                                                                            Received: The application was originally submitted by the applicant and is
                                                                            awaiting review. The permit number is assigned at this point.
                                                                        </li>
                                                                        <li>
                                                                            Incomplete: The application needs additional details from the applicant.
                                                                        </li>
                                                                        <li>
                                                                            Complete and Under Review: Staff has determined that the application has
                                                                            everything needed and will be placed under consideration.
                                                                        </li>
                                                                    </ul>
                                                                }
                                                            />
                                                        }
                                                        options={MAKE_OPTIONS(["Received", "Incomplete", "Complete and Under Review"])}
                                                        fullWidth
                                                    />
                                                )}
                                                {application.id === 2 && (
                                                    <Select
                                                        field="status"
                                                        eventHandle={(value) => this.changeStatus(value)}
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Status"
                                                                helpText={
                                                                    <ul>
                                                                        <li>
                                                                            Invalid: The application does not make any sense and is unsalvageable. No
                                                                            further action should be taken.
                                                                        </li>
                                                                        <li>
                                                                            Incomplete: The application needs additional details from the applicant.
                                                                        </li>
                                                                        <li>
                                                                            Complete and Under Review: Staff has determined that the application has
                                                                            everything needed and will be placed under consideration.
                                                                        </li>
                                                                        <li>Deficient: ...</li>
                                                                        <li>Under Review After Resubmittal: ...</li>
                                                                        <li>Additional Documents Required Prior to Approval: ...</li>
                                                                        <li>Approved: ...</li>
                                                                        <li>Approved with Contingencies: ...</li>
                                                                    </ul>
                                                                }
                                                            />
                                                        }
                                                        options={MAKE_OPTIONS([
                                                            "Invalid",
                                                            "Incomplete",
                                                            "Complete and Under Review",
                                                            "Deficient",
                                                            "Under Review After Resubmittal",
                                                            "Additional Documents Required Prior to Approval",
                                                            "Approved",
                                                            "Approved with Contingencies",
                                                        ])}
                                                        fullWidth
                                                    />
                                                )}
                                                {application.id === 4 && (
                                                    <Select
                                                        field="status"
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Status"
                                                                helpText={
                                                                    <ul>
                                                                        <li>[CRWD Staff provide help text if needed]</li>
                                                                        <li>Received:</li>
                                                                        <li>Fake/Invalid:</li>
                                                                        <li>Incomplete:</li>
                                                                        <li>Complete:</li>
                                                                        <li>Table:</li>
                                                                        <li>Uner Review:</li>
                                                                        <li>Approval Recommended:</li>
                                                                        <li>Approve:</li>
                                                                        <li>Issued:</li>
                                                                    </ul>
                                                                }
                                                            />
                                                        }
                                                        eventHandle={(value) => this.changeStatus(value)}
                                                        options={MAKE_OPTIONS([
                                                            "Fake/Invalid",
                                                            "Incomplete",
                                                            "Complete",
                                                            "Table",
                                                            "Approval Recommended",
                                                            "Approve",
                                                            "Issued",
                                                            "Erosion Control Only Permit Issued",
                                                            "Withdrawn",
                                                            "Deny",
                                                        ])}
                                                        fullWidth
                                                    />
                                                )}
                                                {application.id === 5 && (
                                                    <Select
                                                        field="status"
                                                        label="Status"
                                                        options={MAKE_OPTIONS([
                                                            "Application Received",
                                                            "Application Incomplete",
                                                            "Application Complete",
                                                            "Application Ineligible",
                                                            "Grant Awarded",
                                                            "Grant Denied",
                                                            "Board Approval",
                                                            "Grant Agreement Sent",
                                                            "Grant Agreement Complete",
                                                            "Processing Project Report",
                                                            "Completed",
                                                            "Canceled",
                                                            "Project Report Requested",
                                                            "Project Report Submitted",
                                                            "Project Report Incomplete",
                                                            "Processing Project Reimbursement",
                                                            "Other",
                                                        ])}
                                                        fullWidth
                                                    />
                                                )}
                                                {application.id === 7 && (
                                                    <>
                                                        <Select
                                                            field="status"
                                                            label="Status"
                                                            options={MAKE_OPTIONS([
                                                                "Received",
                                                                "Under Review",
                                                                "Incomplete",
                                                                "Tabled",
                                                                "Withdrawn",
                                                                "Conditionally Approved",
                                                                "Conditionally Approved - Update",
                                                                "Permitted",
                                                                "Under Construction",
                                                                "Escrow Requested",
                                                                "Closed Out",
                                                                "Resubmitted",
                                                            ])}
                                                            fullWidth
                                                        />
                                                        <Typography>
                                                            <i>Changing the status may result in an email being sent to the applicant.</i>
                                                        </Typography>
                                                    </>
                                                )}
                                            </Grid>

                                            {application.id === 4 && (
                                                <>
                                                    {(_status === "Approve" || _status === "Table" || _status === "Approval Recommended") && (
                                                        <Grid item xs={12}>
                                                            <Select
                                                                field="conditions"
                                                                label="Conditions"
                                                                options={MAKE_OPTIONS([
                                                                    "0",
                                                                    "1",
                                                                    "2",
                                                                    "3",
                                                                    "4",
                                                                    "5",
                                                                    "6",
                                                                    "7",
                                                                    "8",
                                                                    "9",
                                                                    "10",
                                                                    "11",
                                                                    "12",
                                                                    "13",
                                                                    "14",
                                                                    "15",
                                                                    "16",
                                                                    "17",
                                                                    "18",
                                                                    "19",
                                                                    "20",
                                                                ])}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                    )}
                                                    {(_status === "Complete" || _status === "Approve" || _status === "Approval Recommended") && (
                                                        <Grid item xs={12}>
                                                            <DatePicker field="board_date" label="Board Date (If Applicable)" fullWidth />
                                                        </Grid>
                                                    )}
                                                </>
                                            )}
                                            {application.id === 2 && (_status === "Incomplete" || _status === "Additional Documents Required Prior to Approval" ) && (
                                                <Grid item xs={12}>
                                                    <TextField
                                                        field="correspondence"
                                                        multiline
                                                        label="List the required additional information that the applicant is missing"
                                                        fullWidth
                                                    />
                                                </Grid>
                                            )}
                                            {application.id !== 2 && application.id !== 4 && application.id !== 7 && application.id !== 8 && (
                                                <Grid item xs={12}>
                                                    <TextField
                                                        field="correspondence"
                                                        multiline
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Email Message"
                                                                helpText="Email message to be sent to the applicant. Staff will be copied on the message. Leaving this field blank will not send an email."
                                                            />
                                                        }
                                                        fullWidth
                                                    />
                                                </Grid>
                                            )}
                                            {dialogLoading && (
                                                <Grid item xs={12}>
                                                    <CircularProgress
                                                        size={24}
                                                        style={{ display: "table", marginLeft: "auto", marginRight: "auto" }}
                                                    />
                                                </Grid>
                                            )}
                                            {!dialogLoading && application.id !== 8 && (
                                                <>
                                                    <Grid item xs={6}>
                                                        <Button fullWidth onClick={() => this.setState({ statusDialogOpen: false })}>
                                                            Cancel
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Button fullWidth type="submit" variant="contained" color="primary">
                                                            Save
                                                        </Button>
                                                    </Grid>
                                                </>
                                            )}
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </AppContainer>
        );
    }
}

Records = connect(
    (state, ownProps) => ({
        application: getApplication(state, ownProps),
        application_records: getApplicationRecords(state, ownProps),
        authState: state.auth,
    }),
    {
        ...ApplicationRecord.actions,
        ...ApplicationRecordStatus.actions,
        ...ParentRecord.actions,
    }
)(Records);

export default withStyles(styles)(withRouter(Records));
