import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import AppContainer from "../../common/AppContainer";
import { createSelector } from "../../common/orm";
import SectionTabHeader from "./SectionTabHeader";
import TopButtons from "./TopButtons";
import ViewRecordTable from "../table/ViewRecordTable";
import ViewContactsTable from "../table/ViewContactsTable";
import { withStyles } from "@material-ui/core";

const getPermission = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["pageId"]),
    (session, pageID) => {
        // Get all permissions related to the page id
        return session.Permission.filter((p) => p.page === pageID).toRefArray();
    }
);

const getPage = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["pageId"]),
    (session, pageID) => {
        // Get the specific page we are on
        return session.Page.withId(pageID);
    }
);

const getSection = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["sectionId"]),
    (session, sectionId) => {
        // Get the specific section we are on
        return session.Section.withId(sectionId);
    }
);

const styles = (theme) => ({
    topMargin: {
        [theme.breakpoints.up("1355")]: {
            marginTop: 144,
        },
        [theme.breakpoints.down("1355")]: {
            marginTop: 156,
        },
    },
});

class ChildTables extends Component {
    render() {
        const { section, page, authState, permission, classes } = this.props;

        if (!page) return "";

        if (authState.user) {
            var readOnly;
            var perm = permission.find((p) => {
                if (p.user === authState.user.id) {
                    return p;
                }
                return null;
            });
            if (perm) readOnly = perm.readonly || perm.readonlyexternal ? true : false;
            if (readOnly === undefined) readOnly = false;
        }

        return (
            <AppContainer authenticated>
                <Grid container spacing={0} style={{ height: "calc(100% - 45px)" }}>
                    <Grid item xs={12}>
                        <TopButtons perm={perm} section={section} page={page} authState={authState} readOnly={readOnly} showPageName={true} />
                    </Grid>
                    <Grid item xs={12}>
                        <SectionTabHeader value={section.id} />
                    </Grid>

                    {section.is_master_contact_form && (
                        <Grid item xs={12} style={{ height: "100%" }} className={classes.topMargin}>
                            <ViewContactsTable />
                        </Grid>
                    )}
                    {!section.is_master_contact_form && (
                        <Grid item xs={12} style={{ height: "100%" }}>
                            <ViewRecordTable newSection={section} pageID={page.id} />
                        </Grid>
                    )}
                </Grid>
            </AppContainer>
        );
    }
}
ChildTables.displayName = "ChildTables";

ChildTables = connect(
    (state, ownProps) => ({
        authState: state.auth,
        permission: getPermission(state, ownProps),
        page: getPage(state, ownProps),
        section: getSection(state, ownProps),
    }),
    null
)(ChildTables);

export default withStyles(styles)(withRouter(ChildTables));
