import React, { Component } from "react";
import { FormField } from "react-form";

import MuiCheckbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { withStyles } from "@material-ui/core";

const styles = {
    error: {
        color: "#f44336",
    },
};

class CheckboxWrapper extends Component {
    handleChange = (event) => {
        const { fieldApi, eventHandle } = this.props;
        const { setValue } = fieldApi;

        setValue(event.target.checked);

        if (eventHandle) eventHandle(event.target.checked, this);
    };

    render() {
        const { fieldApi, label, align, classes, color, st, disabled, required, ...rest } = this.props;

        const { getValue, getError, getWarning, setTouched } = fieldApi;

        const value = getValue() || false,
            error = getError(),
            warning = getWarning(),
            hasError = !!(error || warning);

        //user edit styling for admins
        if (st) var style = { display: "inline-block", width: 210, marginTop: 10, marginLeft: 8 };

        return (
            <div className={align} style={style}>
                {/* {!disabled && ( */}
                <FormControlLabel
                    control={
                        <MuiCheckbox
                            disabled={disabled}
                            color={color ? color : "primary"}
                            onChange={this.handleChange}
                            required={required}
                            onBlur={() => setTouched()}
                            checked={value}
                            {...rest} //allows for chkboxes to show their saved state
                        />
                    }
                    label={label}
                    style={{ marginRight: -4 }}
                    classes={{
                        label: hasError ? classes.error : "",
                    }}
                />
                {required && <FormHelperText style={{ marginTop: 0 }}>Required</FormHelperText>}
                {rest.children}
                {hasError && (
                    <FormHelperText className={align} error={true}>
                        {error}
                    </FormHelperText>
                )}
            </div>
        );
    }
}
CheckboxWrapper.displayName = "CheckboxWrapper";
const Checkbox = FormField(CheckboxWrapper);

export default withStyles(styles)(Checkbox);
