import React, { Component } from "react";
import classNames from "classnames";
import DateFnsUtils from "@date-io/date-fns";
import { withStyles } from "@material-ui/core";
import { DatePicker as MuiDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { FormField } from "react-form";
import FormHelperText from "@material-ui/core/FormHelperText";

const styles = (theme) => ({
    rootLabelShrink: {
        marginLeft: 0,
        marginTop: -2,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
    },
    bootstrapInput: {
        borderRadius: 4,
        border: "1px solid #979797",
        padding: "6px 8px",
        width: "calc(100% - 24px)",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderColor: theme.palette.secondary.light,
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
        backgroundColor: "#fff",
        fontSize: "15px",
        fontWeight: 500,
        color: "#262626 !important",
    },
    bootstrapInputError: {
        "& input": {
            border: "1px solid #f44336",
        },
    },
    disabled: {
        backgroundColor: "#EEEEEE",
    },
    tableFormat: {
        padding: "4px 6px !important",
    },
});

class DatePickerWrapper extends Component {
    // The first commit of Material-UI
    //const [selectedDate, setSelectedDate] = React.useState(new Date());

    handleDateChange = (date) => {
        const { fieldApi, eventHandle } = this.props;
        const { setValue, getFieldName } = fieldApi;

        const dateFns = new DateFnsUtils();
        var db_format = dateFns.format(date, "yyyy-MM-dd");

        if (date) {
            setValue(db_format + " 12:00:00");
            if (eventHandle) eventHandle(db_format + " 12:00:00", getFieldName());
        }
    };

    render() {
        const { helperText, fieldApi, classes, disabled, tableFormat, required, ...rest } = this.props;
        const { getValue, getError, getWarning, getSuccess } = fieldApi;
        var value = getValue(),
            error = getError(),
            warning = getWarning(),
            success = getSuccess(),
            hasError = !!error,
            errorText = error || warning || success || helperText;

        let InputProps = {};
        InputProps["disableUnderline"] = true;
        InputProps["classes"] = {
            input: classNames(classes.bootstrapInput, disabled && classes.disabled, tableFormat && classes.tableFormat),
            error: classes.bootstrapInputError,
        };

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <MuiDatePicker
                    value={value !== undefined ? value : null}
                    onChange={this.handleDateChange}
                    openTo="year"
                    //views={["year", "month", "day"]}
                    format="MMMM d, yyyy"
                    autoOk
                    disabled={disabled}
                    InputProps={InputProps}
                    margin="none"
                    InputLabelProps={{
                        shrink: true,
                        classes: {
                            shrink: classes.rootLabelShrink,
                        },
                    }}
                    error={hasError}
                    helperText={errorText}
                    {...rest}
                />
                {required && !hasError && <FormHelperText style={{ marginTop: -1 }}>Required</FormHelperText>}
            </MuiPickersUtilsProvider>
        );
    }
}

DatePickerWrapper.displayName = "DatePickerWrapper";
const DatePicker = FormField(DatePickerWrapper);

export default withStyles(styles)(DatePicker);
