import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import logo from "../../ms4logo.png";
import Typography from "@material-ui/core/Typography";
const styles = (theme) => ({
    appLogo: {
        width: 115,
    },
    zIndex: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
        zIndex: 1300,
        backgroundColor: "#75bafa",
    },
});

//saves errors to db cauth_errorhandling. ComponentDidCatch sends info
class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, theError: null };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        console.log(error);
        return { hasError: true, theError: error };
    }

    componentDidCatch(error, errorInfo) {
        const { store } = this.props;
        var state = store.getState();
        var error_ = {};
        error_["the_error"] = error["stack"];
        error_["user"] = state.auth.user.name;
        error_["email"] = state.auth.user.email;
        error_["city"] = state.auth.user.city_name;
        error_["url"] = window.location.href;

        // You can also log the error to an error reporting service
        fetch("/error/post", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                error: error_,
                error_info: errorInfo,
            }),
        });
    }

    render() {
        const { classes, store } = this.props;
        const { theError } = this.state;

        if (this.state.hasError) {
            var getHash = window.location["hash"].replace("#/", "#/?return=");
            if (JSON.stringify(store.getState()["auth"]) === "{}") {
                return <meta http-equiv="refresh" content={`0; url=${getHash}`} />;
            } else {
                return (
                    <>
                        <AppBar position="fixed" className={classes.zIndex}>
                            <Toolbar>
                                <img src={logo} alt="MS4Front" className={classes.appLogo} />
                            </Toolbar>
                        </AppBar>
                        <Grid container spacing={3} style={{ marginLeft: 24, marginTop: 80 }}>
                            <meta http-equiv="refresh" content={`3; url=/`} />
                            <Grid item xs={12}>
                                <Typography variant="h5" gutterBottom>
                                    You have encountered an unhandled error!
                                </Typography>
                                <Typography gutterBottom>
                                    The error has been logged. Any details you can provide to Houston Engineering about what you were doing prior to
                                    this message will be helpful.
                                </Typography>
                                <Typography gutterBottom>{theError.message}</Typography>
                                <Typography>
                                    <a rel="noopener noreferrer" href="/">
                                        Return to Application
                                    </a>
                                </Typography>
                            </Grid>
                        </Grid>
                    </>
                );
            }
        }

        return this.props.children;
    }
}
ErrorBoundary.displayName = "ErrorBoundary";
export default withStyles(styles)(ErrorBoundary);
