/******************************************************************************
 * Saved Search component uses is for creating new searches and showing results
 * when creating a new search on any page. Also used for
 * the dashboard
 *
 * Author: Robert Gautier
 * Date: 1/6/20
 *******************************************************************************/

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Form as Formm } from "react-form";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import Reorder from "@material-ui/icons/Reorder";
import Edit from "@material-ui/icons/Edit";
import Map from "@material-ui/icons/Map";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Toolbar from "@material-ui/core/Toolbar";
import Close from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import Table from "@material-ui/core/Table";
import SettingsIcon from "@material-ui/icons/Settings";
import NumberFormat from "react-number-format";
import MapIcon from "@material-ui/icons/Map";
import Menu from "@material-ui/core/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import TablePaginationActionsWrapped from "../../common/Paginator";
import WarningDialog from "../../common/WarningDialog";
import HelpLabel from "../../common/HelpLabel";
import TextField from "../../common/TextField";
import Select from "../../common/Select";
import AppContainer from "../../common/AppContainer";
import { createSelector } from "../../common/orm";
import { Filter, AdvancedGroup, AdvancedSearch, AdvancedFilter, AdvancedTableFields, ExternalDashboard } from "../models";
import TopButtons from "../form/TopButtons";
import drag from "../../common/icons/dnd.png";
import Switch from "../../common/Switch";
import ColorPicker from "../../common/ColorPicker";

const styles = (theme) => ({
    disable: {
        height: "33px",
        width: "33px",
        paddingTop: "4px",
        marginTop: 33,
        [theme.breakpoints.up("lg")]: {
            marginRight: 40,
        },
        [theme.breakpoints.down("md")]: {
            marginRight: 16,
        },
    },
    delete: {
        backgroundColor: "red",
        height: "36px",
        width: "36px",
        marginLeft: "2%",
    },
    noDashDelete: {
        backgroundColor: "red",
        height: "36px",
        width: "36px",
        marginTop: -33,
        marginLeft: "2%",
    },
    edit: {
        marginLeft: "2%",
        height: 36,
        width: 36,
        backgroundColor: "#17ad0b",
    },
    noDashEdit: {
        marginTop: -33,
        marginLeft: "2%",
        height: 36,
        width: 36,
        backgroundColor: "#17ad0b",
    },
    expandedMargin: {
        marginTop: "12px",
    },
    titlePanel: {
        minHeight: "auto !important",
    },
    minWidth: {
        minWidth: 600,
    },
    MuiButtonLabel: {
        "& span": {
            height: "35px",
            [theme.breakpoints.between(1500, "xl")]: {
                width: "188px",
            },
            [theme.breakpoints.down(1500)]: {
                width: 130,
            },
        },
        // marginBottom: "32px",
        [theme.breakpoints.up(1100)]: {
            marginLeft: 9,
        },
    },
    noDashMuiButtonLabel: {
        "& span": {
            height: "35px",
            [theme.breakpoints.between(1500, "xl")]: {
                width: "188px",
            },
            [theme.breakpoints.down(1500)]: {
                width: 130,
            },
        },
        marginBottom: "32px",
        [theme.breakpoints.up(1100)]: {
            marginLeft: 9,
        },
    },
    flex: {
        flex: 1,
    },
    floatRight: {
        float: "right",
    },
    dottedLine: {
        //works with the MuiTypographyBody below for adding content after the name of the advanced search
        width: "100%",
        overflow: "hidden",
        position: "relative",
        //whiteSpace: "nowrap",
    },
    MuiTypographyBody: {
        //override the after on the typography
        "&:after": {
            content:
                "'..........................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................'",
            position: "absolute",
        },
    },

    hideIfSmall: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    ////for table on dashboard
    root: {
        width: "100%",
        overflowX: "auto",
        backgroundColor: "#f0f0f0",
    },
    deleteWidth: {
        minWidth: 34,
        width: 34,
        margin: "0 8px 0 0",
    },
    nowrap: {
        whiteSpace: "nowrap",
    },

    searchSettings: {
        [theme.breakpoints.down(960)]: {
            maxWidth: "13%",
            flexBasis: "14%",
        },
        [theme.breakpoints.down(1625)]: {
            maxWidth: "14%",
            flexBasis: "14%",
        },
        [theme.breakpoints.up(1620)]: {
            maxWidth: "17%",
            flexBasis: "17%",
        },
    },
    advancedName: {
        [theme.breakpoints.down(960)]: {
            maxWidth: "22%",
        },
        [theme.breakpoints.down(1279)]: {
            flexBasis: "26%",
        },
        [theme.breakpoints.up(1700)]: {
            flexBasis: "12%",
        },
        [theme.breakpoints.up(1279)]: {
            flexBasis: "16%",
        },
    },
    loadResults: {
        marginTop: -3,
        width: 220,
        height: 36,
        [theme.breakpoints.down(1750)]: {
            width: 170,
        },
        [theme.breakpoints.down(960)]: {
            maxWidth: "64%",
        },
    },
    searchButtons: {
        [theme.breakpoints.down(1220)]: {
            display: "none",
        },
    },
    searchShowSmall: {
        [theme.breakpoints.up(1220)]: { display: "none" },
    },
    listItem: {
        borderBottom: "1px solid #262626",
    },
    topListItem: {
        borderBottom: "1px solid #262626",
        backgroundColor: "#eee",
        cursor: "default",
        textAlign: "center",
    },
    list: {
        "& ul": {
            padding: 0,
        },
    },
    ellipsis: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 15,
        WebkitBoxOrient: "vertical",
    },
});

const grid = 8;
//full group section which encapsulates all draggable content
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    //padding: grid * 2,
    // margin: `0 0 ${grid}px 0`,
    //border: "1.5px dotted",
    borderRadius: 3,
    boxShadow: "0 2px 18px rgba(0,0,0,0.2), 0 2px 2px rgba(0,0,0,0.25)",
    // change background colour if dragging
    // background: isDragging ? "lightgrey" : "lightgrey",

    // styles we need to apply on draggables
    ...draggableStyle,
});

//styling section for adv groups
const getItemStyle2 = (isDragging, draggableStyle, override) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid,
    margin: `0 0 ${grid}px 0`,
    color: "primary",
    fontSize: "18px",
    borderRadius: 5,
    textAlign: "left",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 2px, rgba(0, 0, 0, 0.25) 0px 2px 2px",

    // change background of fields when dragging
    background: isDragging && override !== '#9cc5eb'? "lightgrey" : override,

    // styles we need to apply on draggables
    ...draggableStyle,
});
// background color on main card advanced group fields when dragging
const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#4e4e4e" : "",
    //padding: grid,
    width: "99%",
    borderRadius: 5,
    // margin: `0 0 50 0`,
});
// background color on "search child cards" section when dragging
const getListStyle2 = (isDraggingOver) => ({
    background: isDraggingOver ? "#4e4e4e" : "",
    padding: 5,
    borderRadius: 5,
    marginBottom: isDraggingOver ? 16 : 0,
    width: "100%",
});

//get the pageID from the URL
const getPageId = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (session, pageID) => {
        //return pageID; //result function
        return session.Page.filter((p) => p.id === pageID).toRefArray();
    }
);

const allSections = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (session, pageID) => {
        //return pageID; //result function
        return session.Section.filter((p) => p.page === pageID).toRefArray();
    }
);
//get permissions
const getPermission = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (session, pageID) => {
        if (isNaN(pageID)) {
            //no dashboard set
            return session.Permission.all().toRefArray();
        } else return session.Permission.filter((p) => p.page === pageID).toRefArray();
    } //toRefArray returns a plain JavaScript object which are references to the DB
);

const getFilters = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["sectionId"]),
    (session, section) => {
        return session.Filter.filter((r) => r.section === section).toRefArray();
    } //toRefArray returns a plain JavaScript object which are references to the DB
);

const getAllAdvancedSearches = createSelector((schema) => {
    return schema.AdvancedGroup.all()
        .orderBy("order")
        .toModelArray()
        .map((advancedGroup) => ({
            advancedSearch: advancedGroup.advancedsearch
                .all()
                .orderBy("order")
                .toModelArray()
                .map((advancedSearch) => ({
                    advancedtablefields: advancedSearch.advancedtablefields.all().orderBy("order").toRefArray(),
                    ...advancedSearch.ref,
                })),
            ...advancedGroup.ref,
        }));
});

const getAdvancedGroups = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, pageID) => {
        return session.AdvancedGroup.filter((r) => r.page === pageID)
            .orderBy("order")
            .toModelArray()
            .map((advancedGroup) => ({
                advancedSearch: advancedGroup.advancedsearch.all().orderBy("order").toRefArray(),
                ...advancedGroup.ref,
            }));
    }
);

const getExternalDashboards = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, pageID) => {
        return session.ExternalDashboard.filter((r) => r.page === pageID)
            .orderBy("id")
            .toRefArray();
    }
);

const getAdvancedFilters = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]),
    (session, pageID) => {
        return session.AdvancedSearch.filter((r) => r.page === pageID)
            .orderBy("id")
            .toModelArray()
            .map((advancedSearch) => ({
                advancedFilter: advancedSearch.advancedfilter.all().orderBy("id").toRefArray(),
                ...advancedSearch.ref,
            }));
    } //toRefArray returns a plain JavaScript object which are references to the DB
);

const getPageFields = createSelector(
    (state, ownProps) => parseInt(ownProps.match.params["id"]), //input selector which matches ownProps page :id in route i.e index.js ("return id" will be injected into "pageID" in the result function args)
    (session, actPage) => {
        return session.Section.filter((sect) => sect.page === actPage)
            .orderBy("id")
            .toModelArray()
            .map((section) => ({
                form: section.forms //form.groups is FK linkage
                    .all()
                    .orderBy("id")
                    .toModelArray()
                    .map((form) => ({
                        groups: form.groups //form.groups is FK linkage
                            .all()
                            .orderBy("id")
                            .toModelArray()
                            .map((group) => ({
                                fields: group.fields.all().orderBy("order").toRefArray(),
                                ...group.ref, // take all groups as a reference and tie in each field
                            })),
                        ...form.ref, // take all forms as a reference and tie in each field
                    })),
                ...section.ref,
            }));
    } //toRefArray returns a plain JavaScript object which are references to the DB
);

class CreateAdvancedSearches extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            loading_external: false,
            dashboard: null,
            advancedSearchLandingPageTable: false,
            deleteDialogOpen: false,
            searchToDelete: null,
            groupDelete: false,
            advancedSearch: [],
            loadingId: [],
            rowsPages: [],
            dashboardSearchExpanded: [],
            orderByField: [],
            groupName: null,
            smallAnchor: null,
            searchSection: null,
            moving:false
        };
        this.onDragEnd = this.onDragEnd.bind(this);
        this.formKey = window.performance.now();
    }

    componentDidMount() {
        const { advancedSearchLandingPageTable, dashboardConfigured, authState } = this.props;
        if (!advancedSearchLandingPageTable) this.setState({ advancedSearchLandingPageTable: true });

        if (dashboardConfigured) {
            //here we are going to load the pages/rows for the multiple saved searches on the dashboard this timeout is needed b/c props are not loading when it mounts
            //not a fan of though
            setTimeout(() => {
                var comp = this;
                let filteredAdvancedSearch = comp.props.alladvancedsearches.filter((fadv) => {
                    var match = comp.props.filteredDashboardPermissions.find((f) => {
                        if (fadv.page === f.page) {
                            if (f.fullaccess || f.readonly || (!f.fullaccess && !f.readonly && !f.noaccess)) {
                                return f;
                            }
                        }
                        return null;
                    });
                    if (match) return fadv;
                    else return null;
                });

                if (filteredAdvancedSearch.length > 0) {
                    //for groups that are already created
                    var ids = [];
                    filteredAdvancedSearch.forEach((a, i) => {
                        if (a.advancedSearch.length !== 0) {
                            a.advancedSearch.forEach((e) => {
                                if (
                                    (e.accessby === "All" ||
                                        (e.accessby === "Creator" && parseInt(e.accesscreatorid) === authState.user.id) ||
                                        (e.accessby === "Admin" && authState.user.is_city_admin)) &&
                                    e.dashboard.toString() === "true"
                                ) {
                                    ids.push(e.id);
                                } else return null;
                            });
                        }
                    });
                    comp.initialRowsPageTablesSetup(ids);
                }
            }, 2000);
        }
    }

    componentDidUpdate(prevProps) {
        const { page, alladvancedsearches, dashboardConfigured, authState, filteredDashboardPermissions } = this.props;

        //used for when routing from page to page to get the new searches permissions and rowsPages added
        if (prevProps && prevProps.page.length > 0 && page && prevProps.page[0].id !== page[0].id) {
            if (dashboardConfigured) {
                //here we are going to load the pages/rows for the multiple saved searches on the dashboard
                let filteredAdvancedSearch = alladvancedsearches.filter((fadv) => {
                    var match = filteredDashboardPermissions.find((f) => {
                        if (fadv.page === f.page) {
                            if (f.fullaccess || f.readonly || (!f.fullaccess && !f.readonly && !f.noaccess)) {
                                return f;
                            }
                        }
                        return null;
                    });
                    if (match) return fadv;
                    else return null;
                });

                if (filteredAdvancedSearch.length > 0) {
                    //for groups that are already created
                    var ids = [];
                    filteredAdvancedSearch.forEach((a, i) => {
                        if (a.advancedSearch.length !== 0) {
                            a.advancedSearch.forEach((e) => {
                                if (
                                    (e.accessby === "All" ||
                                        (e.accessby === "Creator" && parseInt(e.accesscreatorid) === authState.user.id) ||
                                        (e.accessby === "Admin" && authState.user.is_city_admin)) &&
                                    e.dashboard.toString() === "true"
                                ) {
                                    ids.push(e.id);
                                } else return null;
                            });
                        }
                    });

                    //reset searches in group
                    this.setState({ advancedSearch: [] });
                    this.initialRowsPageTablesSetup(ids);
                }
            }
        }
    }

    //used for dashboard configured setups, clone and put into new obj
    initialRowsPageTablesSetup = (ids) => {
        let temp = [];
        ids.forEach((i) => temp.push({ id: i, rowsPerPage: 5, page: 0 }));
        this.setState({ rowsPages: temp });
    };

    returnDialog = () => {
        this.setState({ loading: false });
    };

    returnDialogExternal = () => {
        this.setState({ loading_external: false });
    };

    //used for dashboard configured setups, clone and put into new obj
    handleChangePage = (event, page, searchid) => {
        const { rowsPages } = this.state;
        let newRowsPages = rowsPages;
        let idx = newRowsPages.findIndex((i) => i.id === searchid);
        newRowsPages[idx]["page"] = page;

        this.setState({ rowsPages: newRowsPages });
    };

    //used for dashboard configured setups, clone and put into new obj
    handleChangeRowsPerPage = (event, rows, searchid) => {
        const { rowsPages } = this.state;
        let newRowsPages = rowsPages;
        let idx = newRowsPages.findIndex((i) => i.id === searchid);
        newRowsPages[idx]["rowsPerPage"] = event.target.value;
        newRowsPages[idx]["page"] = 0;
        this.setState({ rowsPages: newRowsPages });
    };

    //handle expansion panels opening and closing (dashboard configured only )
    dashboardHandleChange = (panel) => {
        const { dashboardSearchExpanded } = this.state;

        var clone = [...dashboardSearchExpanded];
        let isThere = dashboardSearchExpanded.find((foundpanel) => {
            return foundpanel === panel;
        });
        if (!isThere) {
            //add
            clone.push(panel);
            this.setState({ dashboardSearchExpanded: clone });
        } else {
            //remove
            let idx = clone.findIndex((i) => i === panel);
            clone.splice(idx, 1);
            this.setState({ dashboardSearchExpanded: clone });
        }
    };

    handleRequestSort = (event, id, columnRecord) => {
        const { advancedSearch, orderByField } = this.state;
        var columnFind = columnRecord.find((c) => c.label === event.currentTarget.outerText);

        //get the actual table
        var table = advancedSearch.find((a) => {
            return parseInt(Object.keys(a)) === id ? a : null;
        });

        // columnFind = fieldID to sort by
        const orderBy = columnFind.id;
        var order = "desc";

        //look for prior settings in sort we will change state on the field from asc to desc
        if (orderByField.length > 0 && orderByField.find((f) => f.id === orderBy)) {
            var copy = orderByField.find((f) => f.id === orderBy);
            if (copy.order === "desc") {
                order = "asc";
                orderByField.forEach((f) => (f.id === orderBy ? (f["order"] = "asc") : null));
            } else {
                order = "desc";
                orderByField.forEach((f) => (f.id === orderBy ? (f["order"] = "desc") : null));
            }
            this.setState({ orderByField: orderByField });
        } else {
            //not found
            var obj = {};
            obj["id"] = orderBy;
            obj["order"] = order;
            this.setState({ orderByField: [...orderByField, obj] });
        }

        order === "desc"
            ? Object.values(table)[0].sort((a, b) => {
                  var bInput = b.recordfields
                      .find((e) => {
                          return e.field_id === orderBy;
                      })
                      .userinput.toUpperCase();
                  var aInput = a.recordfields
                      .find((e) => {
                          return e.field_id === orderBy;
                      })
                      .userinput.toUpperCase();
                  if (typeof (aInput || bInput) !== "function" && aInput.includes("/") && bInput.includes("/")) {
                      aInput = Date.parse(aInput);
                      bInput = Date.parse(bInput);
                      return aInput < bInput ? -1 : 1;
                  }
                  //non date field
                  return bInput < aInput ? -1 : 1;
              })
            : Object.values(table)[0].sort((a, b) => {
                  var aInput = a.recordfields
                      .find((e) => {
                          return e.field_id === orderBy;
                      })
                      .userinput.toUpperCase();
                  var bInput = b.recordfields
                      .find((e) => {
                          return e.field_id === orderBy;
                      })
                      .userinput.toUpperCase();
                  if (typeof (aInput || bInput) !== "function" && aInput.includes("/") && bInput.includes("/")) {
                      aInput = Date.parse(aInput);
                      bInput = Date.parse(bInput);
                      return bInput < aInput ? -1 : 1;
                  }
                  //non date field
                  return aInput < bInput ? -1 : 1;
              });
    };

    handleSmallMenu = (event, searchSection) => {
        this.setState({ smallAnchor: event.currentTarget, searchSection });
    };

    handleSmallMenuClose = () => {
        this.setState({ smallAnchor: null });
    };

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        console.log(result);
        var _this = this;
        const { ormAdvancedSearchLoadDetailChild, ormAdvancedGroupLoadDetailChild, advancedgroups, authState,  page } = this.props;
        this.setState({moving:true})
        // Order Groups
        if (result.destination.droppableId === "group_list") {
            // Set the order of groups moved between starting and ending position
        
            // Set the order of groups moved between starting and ending position
            //eslint-disable-next-line
            const res = fetch(`dndreorder/${page[0].id}/${result.destination.index}/${result.source.index}/${result.draggableId}/${"searchgroup"}/${authState.user.auth_token}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + authState.user.auth_token,
                },
            }).then((e) => {
                if (e.status === 200) {
                    e.json().then((rsp) => {   
                         ormAdvancedGroupLoadDetailChild(result.draggableId, function () { 
                            _this.setState({ moving: false });
                         }); 
                    });
                }else { 
                    _this.setState({moving:false}) 
                }
            });
       
        }
        // Order Searches
        else { 
            const searchid = result.destination.droppableId.split("-")[1];
            const grp = advancedgroups.find((s) => s.id === parseInt(searchid));
    
            // Set the order of searches moved between starting and ending position
            //eslint-disable-next-line
            const res = fetch(`dndreorder/${grp.id}/${result.destination.index}/${result.source.index}/${result.draggableId}/${"search"}/${authState.user.auth_token}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + authState.user.auth_token,
                },
            }).then((e) => {
                if (e.status === 200) {
                    e.json().then((rsp) => {   
                         ormAdvancedSearchLoadDetailChild(result.draggableId, function () { 
                            _this.setState({ moving: false });
                         }); 
                    });
                }else { 
                    _this.setState({moving:false}) 
                }
            });  
        }
    }

    render() {
        const {
            classes,
            page,
            history,
            advancedgroups,
            ormAdvancedSearchDelete,
            ormAdvancedGroupDelete,
            advancedSearchLandingPageTable,
            dashboardNotConfigured,
            dashboardConfigured,
            alladvancedsearches,
            authState,
            permission,
            filteredDashboardPermissions,
            expanded,
            sections,
            pagefields,
            ormAdvancedSearchCreate,
            ormAdvancedGroupCreate,
            ormAdvancedGroupUpdate,
            ormExternalDashboardCreate,
            ormExternalDashboardUpdate,
            ormExternalDashboardDelete,
            dashboards,
            key,
        } = this.props;
        const {
            loading,
            deleteDialogOpen,
            searchToDelete,
            groupDelete,
            advancedSearch,
            loadingId,
            rowsPages,
            dashboardSearchExpanded,
            groupName,
            loading_external,
            dashboard,
            moving
        } = this.state;
        
        var readOnly;
        if (authState.user) {    
            var perm = permission.find((p) => {
                if (p.user === authState.user.id) {
                    return p;
                }
                return null;
            });
            if (perm) readOnly = perm.readonly || perm.readonlyexternal ? true : false; //set for read only deleting of things
        }

        //advanced search expansion panel on the landing page, shows above the table
        if (advancedSearchLandingPageTable) {
            if (advancedgroups.length === 0) {
                return (
                    <Formm dontValidateOnMount={true} validateOnSubmit={true} getApi={(el) => (this.formApi = el)}>
                        {(formApi) => (
                            <form onSubmit={formApi.submitForm}>
                                <Accordion defaultExpanded={expanded} className={classes.hideIfSmall}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        style={{ backgroundColor: "#ddd" }}
                                        classes={{ root: classes.expandedMargin }}
                                        className={classes.titlePanel}>
                                        <Icon style={{ color: "#66686a" }}>search</Icon>
                                        <Typography variant="subtitle1" style={{ marginLeft: 12, color: "#66686a" }}>
                                            Saved Search
                                        </Typography>
                                    </AccordionSummary>{" "}
                                    <AccordionDetails style={{ paddingBottom: 8 }}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={5} lg={4} xl={3}></Grid>
                                        </Grid>
                                    </AccordionDetails>
                                    <CreateNewSearch
                                        classes={classes}
                                        groupName={groupName}
                                        loading={loading}
                                        returnDialog={this.returnDialog}
                                        sections={sections}
                                        history={history}
                                        advancedgroups={advancedgroups}
                                        ormAdvancedGroupCreate={ormAdvancedGroupCreate}
                                        ormAdvancedSearchCreate={ormAdvancedSearchCreate}
                                        ormAdvancedGroupUpdate={ormAdvancedGroupUpdate}
                                        page={page}
                                        authState={authState}
                                    />
                                </Accordion>
                            </form>
                        )}
                    </Formm>
                );
            } else {
                //for groups that are already created
                return advancedgroups.map((a, index) => {
                    return (
                        <Formm key={this.formKey} dontValidateOnMount={true} validateOnSubmit={true} getApi={(el) => (this.formApi = el)}>
                            {(formApi) => (
                                <form onSubmit={formApi.submitForm}>
                                    <Accordion defaultExpanded={expanded} key={a.id} className={classes.hideIfSmall}>
                                        <AccordionSummary
                                            key={a.id}
                                            expandIcon={<ExpandMoreIcon />}
                                            style={{
                                                backgroundColor: a.expansion_color ? a.expansion_color : "#ddd",
                                            }}
                                            classes={{ root: classes.expandedMargin }}
                                            className={classes.titlePanel}>
                                            <Icon
                                                style={{
                                                    color: a.expansion_font_color ? a.expansion_font_color : "#66686a",
                                                }}>
                                                search
                                            </Icon>
                                            <Grid container spacing={0}>
                                                <Grid xs={10}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        style={{
                                                            marginLeft: 12,
                                                            color: a.expansion_font_color ? a.expansion_font_color : "#66686a",
                                                        }}>
                                                        {a.name}
                                                    </Typography>
                                                </Grid>
                                                <Grid xs={2} style={{ textAlign: "end", paddingTop: 3 }}>
                                                    {a.advancedSearch.length === 0 && (
                                                        <Icon
                                                            style={{ color: "#3c8ac7" }}
                                                            onClick={() => {
                                                                // ormAdvancedGroupDelete(a.id);
                                                                this.setState({
                                                                    deleteDialogOpen: true,
                                                                    searchToDelete: null,
                                                                    groupDelete: a,
                                                                });
                                                            }}>
                                                            delete
                                                        </Icon>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails style={{ paddingBottom: 8 }}>
                                            <Grid container>
                                                {a.advancedSearch.map((s) => {
                                                    if (
                                                        s.accessby === "All" ||
                                                        (s.accessby === "Creator" && parseInt(s.accesscreatorid) === authState.user.id) ||
                                                        (s.accessby === "Admin" && authState.user.is_city_admin)
                                                    ) {
                                                        return (
                                                            <>
                                                                <Grid sm={12} md={5} lg={6} xl={7} className={classes.dottedLine}>
                                                                    <Typography className={classes.MuiTypographyBody} style={{ fontSize: 19 }}>
                                                                        {s.name}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid xs style={{ whiteSpace: "nowrap" }}>
                                                                    <Button
                                                                        className={classes.MuiButtonLabel}
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={(e) => {
                                                                            history.push(
                                                                                "/page/" + page[0].id + "/advancedsearch/" + s.id + "&=load&view"
                                                                            );
                                                                        }}>
                                                                        <Reorder />
                                                                        &nbsp;&nbsp;&nbsp;View Search
                                                                    </Button>
                                                                    {s.geometriesonmap && (
                                                                        <Button
                                                                            className={classes.MuiButtonLabel}
                                                                            style={{ marginLeft: 13 }}
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={(e) => {
                                                                                history.push(
                                                                                    "/map/" +
                                                                                        page[0].id +
                                                                                        "/section/" +
                                                                                        s.section +
                                                                                        "/advancedsearch/" +
                                                                                        s.id
                                                                                );
                                                                            }}>
                                                                            <Map />
                                                                            &nbsp;&nbsp;&nbsp;View Map
                                                                        </Button>
                                                                    )}
                                                                    <Tooltip title="Edit Saved Search">
                                                                        <IconButton
                                                                            className={classes.edit}
                                                                            onClick={(e) => {
                                                                                history.push("/page/" + page[0].id + "/advancedsearch/" + s.id);
                                                                            }}>
                                                                            <Edit style={{ color: "white" }} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Permanently Delete Search">
                                                                        <IconButton
                                                                            disabled={readOnly}
                                                                            className={classes.delete}
                                                                            onClick={(e) => {
                                                                                this.setState({
                                                                                    deleteDialogOpen: true,
                                                                                    searchToDelete: s,
                                                                                });
                                                                            }}>
                                                                            <DeleteIcon style={{ color: "white" }} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Grid>
                                                            </>
                                                        );
                                                    } else return null;
                                                })}
                                                {!loading && (
                                                    <Grid container spacing={2} alignItems="center">
                                                        <Grid item xs={12} md={8} lg={3}>
                                                            <Button
                                                                fullWidth
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={(e) => {
                                                                    this.setState({
                                                                        loading: true,
                                                                        groupName: a.name,
                                                                    });
                                                                }}
                                                                style={{
                                                                    width: "270px",
                                                                }}>
                                                                <AddIcon />
                                                                &nbsp;&nbsp;&nbsp;Create New
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                                {deleteDialogOpen && index === 0 && (
                                                    <WarningDialog
                                                        confirmAction={() => {
                                                            if (searchToDelete) {
                                                                ormAdvancedSearchDelete(searchToDelete.id);
                                                            } else {
                                                                ormAdvancedGroupDelete(groupDelete.id);
                                                            }

                                                            this.setState({ deleteDialogOpen: false });
                                                        }}
                                                        cancelAction={() => this.setState({ deleteDialogOpen: false })}
                                                        open={deleteDialogOpen}
                                                        confirmText={searchToDelete ? "Delete Saved Search" : "Delete Group"}
                                                        title={`Delete ${
                                                            searchToDelete ? "'" + searchToDelete.name + "' Search" : groupDelete.name + " Group?"
                                                        }`}
                                                        text="Are you sure you wish to permanently delete?"
                                                    />
                                                )}
                                                {index === 0 && (
                                                    <CreateNewSearch
                                                        classes={classes}
                                                        loading={loading}
                                                        groupName={groupName}
                                                        returnDialog={this.returnDialog}
                                                        sections={sections}
                                                        history={history}
                                                        advancedgroups={advancedgroups}
                                                        ormAdvancedGroupCreate={ormAdvancedGroupCreate}
                                                        ormAdvancedSearchCreate={ormAdvancedSearchCreate}
                                                        ormAdvancedGroupUpdate={ormAdvancedGroupUpdate}
                                                        page={page}
                                                        authState={authState}
                                                    />
                                                )}
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </form>
                            )}
                        </Formm>
                    );
                });
            }
        }

        //dashboard isnt configured and clickon on desktop--clicking on ms4ICON runs this
        if (dashboardNotConfigured) {
            //only show the advancedsearches for users that have read only or full access. We lock out non-dashboard configured read only external users from url route /dashboard/
            var filteredAdvancedSearch = alladvancedsearches.filter((fadv) => {
                var match = filteredDashboardPermissions.find((f) => {
                    if (fadv.page === f.page) {
                        if (f.fullaccess || f.readonly || (!f.fullaccess && !f.readonly && !f.noaccess && !f.readonlyexternal)) {
                            return f;
                        }
                    }
                    return null;
                });
                if (match) return fadv;
                else return null;
            });
            if (filteredAdvancedSearch.length > 0) {
                //for groups that are already created, make sure one is found to be on the dashboard in the group
                return filteredAdvancedSearch.map((a) => {
                    if (a.advancedSearch.length !== 0) {
                        var found = a.advancedSearch.find((e) => {
                            if (
                                e.dashboard.toString() === "true" &&
                                (e.accessby === "All" ||
                                    e.accessby === "All Including External Read Only Users" ||
                                    (e.accessby === "Creator" && parseInt(e.accesscreatorid) === authState.user.id) ||
                                    (e.accessby === "Admin" && authState.user.is_city_admin))
                            ) {
                                return e;
                            } else return null;
                        });
                        if (found) {
                            return (
                                <>
                                    <Formm key={key} dontValidateOnMount={true} validateOnSubmit={true} getApi={(el) => (this.formApi = el)}>
                                        {(formApi) => (
                                            <form onSubmit={formApi.submitForm}>
                                                <Accordion defaultExpanded={true}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        style={{ backgroundColor: a.expansion_color ? a.expansion_color : "#ddd" }}
                                                        classes={{ root: classes.expandedMargin }}
                                                        className={classes.titlePanel}>
                                                        <Icon
                                                            style={{
                                                                color: a.expansion_font_color ? a.expansion_font_color : "#66686a",
                                                            }}>
                                                            search
                                                        </Icon>
                                                        <Grid container spacing={0}>
                                                            <Grid item xs={10}>
                                                                <Typography
                                                                    variant="subtitle1"
                                                                    style={{
                                                                        marginLeft: 12,
                                                                        color: a.expansion_font_color ? a.expansion_font_color : "#66686a",
                                                                    }}>
                                                                    {a.name}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2} style={{ textAlign: "end", paddingTop: 3 }}>
                                                                {a.advancedSearch.length === 0 && (
                                                                    <Icon
                                                                        style={{ color: "#3c8ac7" }}
                                                                        onClick={() => {
                                                                            ormAdvancedGroupDelete(a.id);
                                                                        }}>
                                                                        delete
                                                                    </Icon>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionSummary>
                                                    <AccordionDetails style={{ paddingBottom: 8 }}>
                                                        <Grid container>
                                                            {a.advancedSearch.map((s, i) => {
                                                                //only show searches that are allowed on dashboard
                                                                if (
                                                                    s.dashboard.toString() === "true" &&
                                                                    (s.accessby === "All" ||
                                                                        s.accessby === "All Including External Read Only Users" ||
                                                                        (s.accessby === "Creator" &&
                                                                            parseInt(s.accesscreatorid) === authState.user.id) ||
                                                                        (s.accessby === "Admin" && authState.user.is_city_admin))
                                                                ) {
                                                                    return (
                                                                        <>
                                                                            <Grid sm={12} md={5} lg={6} xl={7} className={classes.dottedLine}>
                                                                                <Typography
                                                                                    className={classes.MuiTypographyBody}
                                                                                    style={{ fontSize: 19 }}>
                                                                                    {s.name}
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid xs style={{ whiteSpace: "nowrap" }}>
                                                                                <Button
                                                                                    className={classes.noDashMuiButtonLabel}
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    onClick={(e) => {
                                                                                        history.push("/page/" + a.page + "/advancedsearch/" + s.id);
                                                                                    }}>
                                                                                    <Reorder />
                                                                                    &nbsp;&nbsp;&nbsp;View Search
                                                                                </Button>
                                                                                {s.geometriesonmap && (
                                                                                    <Button
                                                                                        className={classes.noDashMuiButtonLabel}
                                                                                        style={{ marginLeft: 13 }}
                                                                                        variant="contained"
                                                                                        color="primary"
                                                                                        onClick={(e) => {
                                                                                            history.push(
                                                                                                "/map/" +
                                                                                                    a.page +
                                                                                                    "/section/" +
                                                                                                    s.section +
                                                                                                    "/advancedsearch/" +
                                                                                                    s.id
                                                                                            );
                                                                                        }}>
                                                                                        <Map />
                                                                                        &nbsp;&nbsp;&nbsp;View Map
                                                                                    </Button>
                                                                                )}
                                                                                <Tooltip title="Edit Saved Search">
                                                                                    <IconButton
                                                                                        className={classes.noDashEdit}
                                                                                        onClick={(e) => {
                                                                                            history.push(
                                                                                                "/page/" + a.page + "/advancedsearch/" + s.id
                                                                                            );
                                                                                        }}>
                                                                                        <Edit style={{ color: "white" }} />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                <Tooltip title="Permanently Delete Search">
                                                                                    <IconButton
                                                                                        disabled={readOnly}
                                                                                        className={classes.noDashDelete}
                                                                                        onClick={(e) => {
                                                                                            this.setState({
                                                                                                deleteDialogOpen: true,
                                                                                                searchToDelete: s,
                                                                                            });
                                                                                        }}>
                                                                                        <DeleteIcon style={{ color: "white" }} />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </Grid>
                                                                        </>
                                                                    );
                                                                }
                                                                // i used to prevent multiple rendering
                                                                if (deleteDialogOpen === true && i === 0) {
                                                                    return (
                                                                        <WarningDialog
                                                                            confirmAction={() => {
                                                                                ormAdvancedSearchDelete(searchToDelete.id);
                                                                                this.setState({ deleteDialogOpen: false });
                                                                            }}
                                                                            cancelAction={() => this.setState({ deleteDialogOpen: false })}
                                                                            open={deleteDialogOpen}
                                                                            confirmText="Delete Saved Search"
                                                                            title={`Delete ${
                                                                                searchToDelete ? "'" + searchToDelete.name + "'" : " "
                                                                            } Search?`}
                                                                            text="Are you sure you wish to permanently delete this search?"
                                                                        />
                                                                    );
                                                                } else return null;
                                                            })}
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </form>
                                        )}
                                    </Formm>
                                </>
                            );
                        } else return null;
                    } else return null;
                });
            } else return null;
        }

        //advanced searches shown for each module on dashboard
        if (dashboardConfigured) {
            //only show the advancedsearches they have permissions to see on the dashboard
            let filteredAdvancedSearch = alladvancedsearches.filter((fadv) => {
                var match = filteredDashboardPermissions.find((f) => {
                    if (fadv.page === f.page) {
                        if (f.fullaccess || f.readonly || (!f.fullaccess && !f.readonly && !f.noaccess)) {
                            return f;
                        }
                    }
                    return null;
                });
                if (match) return fadv;
                else return null;
            });

            var returned_jsx = null;
            var returned_jsx2 = null;

            if (dashboards.length > 0) {
                returned_jsx = (
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            style={{ backgroundColor: "#ddd" }}
                            classes={{ root: classes.expandedMargin }}
                            className={classes.titlePanel}>
                            <Icon
                                style={{
                                    color: "#66686a",
                                }}>
                                addchart
                            </Icon>
                            <Grid container spacing={1}>
                                <Grid item xs={5}>
                                    <Typography
                                        variant="subtitle1"
                                        style={{
                                            marginLeft: 12,
                                            color: "#66686a",
                                        }}>
                                        External Dashboards
                                    </Typography>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails style={{ display: "block", position: "relative", paddingBottom: 8 }}>
                            {dashboards.map((a) => {
                                return (
                                    <iframe
                                        src={a.iframe_url_dashboard}
                                        title="External Analytic"
                                        frameborder="1"
                                        style={{
                                            overflow: "hidden",
                                            height: a.height ? parseInt(a.height) : 600,
                                            width: a.width ? parseInt(a.width) : "100%",
                                        }}></iframe>
                                );
                            })}
                        </AccordionDetails>
                    </Accordion>
                );
            }

            if (filteredAdvancedSearch.length > 0) {
                //for groups that are already created, chk to make sure at least ONE is in the saved searches list b4 we start to display
                returned_jsx2 = filteredAdvancedSearch.map((a, i) => {
                    if (a.advancedSearch.length !== 0) {
                        var found = a.advancedSearch.find((e) => {
                            if (
                                e.dashboard.toString() === "true" &&
                                (e.accessby === "All" ||
                                    e.accessby === "All Including External Read Only Users" ||
                                    (e.accessby === "Creator" && parseInt(e.accesscreatorid) === authState.user.id) ||
                                    (e.accessby === "Admin" && authState.user.is_city_admin))
                            ) {
                                return e;
                            } else return null;
                        });
                        if (found) {
                            return (
                                <>
                                    {perm &&
                                        !perm.readonlyexternal && ( //all users will see this part of code except for external users which will hit next block of code below, redundant but easier to just cut/paste
                                            <Formm
                                                key={found.id}
                                                dontValidateOnMount={true}
                                                validateOnSubmit={true}
                                                getApi={(el) => (this.formApi = el)}>
                                                {(formApi) => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <Accordion defaultExpanded={i === 0 ? true : false}>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                style={{ backgroundColor: a.expansion_color ? a.expansion_color : "#ddd" }}
                                                                classes={{ root: classes.expandedMargin }}
                                                                className={classes.titlePanel}>
                                                                <Icon
                                                                    style={{
                                                                        color: a.expansion_font_color ? a.expansion_font_color : "#66686a",
                                                                    }}>
                                                                    search
                                                                </Icon>
                                                                <Grid container spacing={0}>
                                                                    <Grid item xs={10}>
                                                                        <Typography
                                                                            variant="subtitle1"
                                                                            style={{
                                                                                marginLeft: 12,
                                                                                color: a.expansion_font_color ? a.expansion_font_color : "#66686a",
                                                                            }}>
                                                                            {a.name}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={2} style={{ textAlign: "end", paddingTop: 3 }}>
                                                                        {a.advancedSearch.length === 0 && (
                                                                            <Icon
                                                                                style={{ color: "#3c8ac7" }}
                                                                                onClick={() => {
                                                                                    ormAdvancedGroupDelete(a.id);
                                                                                }}>
                                                                                delete
                                                                            </Icon>
                                                                        )}
                                                                    </Grid>
                                                                </Grid>
                                                            </AccordionSummary>
                                                            <AccordionDetails style={{ paddingBottom: 8 }}>
                                                                <Grid container>
                                                                    {a.advancedSearch.map((s, i) => {
                                                                        //only show searches that are allowed on the dashboard
                                                                        //here we will show all, however read only external will not be seen (basically all internal)
                                                                        if (
                                                                            s.dashboard.toString() === "true" &&
                                                                            (s.accessby === "All" ||
                                                                                s.accessby === "All Including External Read Only Users" ||
                                                                                (s.accessby === "Creator" &&
                                                                                    parseInt(s.accesscreatorid) === authState.user.id) ||
                                                                                (s.accessby === "Admin" && authState.user.is_city_admin))
                                                                        ) {
                                                                            var getFields = [];
                                                                            s.advancedtablefields.forEach((t) => getFields.push(t.field));

                                                                            var columnRecord = [];
                                                                            columnRecord.push({ id: "actions", label: "", allowSort: false });
                                                                            //get columns foreach search
                                                                            pagefields.forEach((pf) =>
                                                                                pf.form.forEach((f) =>
                                                                                    f.groups.forEach((g) =>
                                                                                        g.fields.forEach((f) =>
                                                                                            getFields.find((gf) =>
                                                                                                gf === f.id
                                                                                                    ? columnRecord.push({
                                                                                                          id: f.id,
                                                                                                          label: f.name,
                                                                                                          type: f.type,
                                                                                                          allowSort: true,
                                                                                                      })
                                                                                                    : null
                                                                                            )
                                                                                        )
                                                                                    )
                                                                                )
                                                                            );

                                                                            //match up the advanced search that was loaded from the loaded button
                                                                            var tableData;
                                                                            if (advancedSearch.length !== 0)
                                                                                tableData = advancedSearch.find((k, i) =>
                                                                                    parseInt(Object.keys(k)) === s.id ? Object.values(k) : null
                                                                                );
                                                                            var tablePagination;
                                                                            var expand = dashboardSearchExpanded.find((f) => {
                                                                                return f === s.id ? true : false;
                                                                            });
                                                                            return (
                                                                                <Grid item xs={12}>
                                                                                    <Accordion
                                                                                        defaultExpanded={false}
                                                                                        expanded={expand ? true : false}
                                                                                        style={{
                                                                                            boxShadow:
                                                                                                " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                                                                                        }}
                                                                                        onChange={(e) => this.dashboardHandleChange(s.id)}>
                                                                                        <AccordionSummary
                                                                                            expandIcon={<ExpandMoreIcon />}
                                                                                            style={{
                                                                                                backgroundColor: "#ddd",
                                                                                            }}
                                                                                            classes={{ root: classes.expandedMargin }}
                                                                                            className={classes.titlePanel}>
                                                                                            <Icon style={{ marginTop: 16, color: "#66686a" }}>
                                                                                                search
                                                                                            </Icon>
                                                                                            <table style={{width:"100%"}}>
                                                                                                <tr>
                                                                                                    <td style={{width:"100%"}}>
                                                                                                        <Tooltip title={s.name}>
                                                                                                            <Typography
                                                                                                                variant="subtitle1"
                                                                                                                style={{
                                                                                                                    marginLeft: 12,
                                                                                                                    color: "#66686a",
                                                                                                                }}>
                                                                                                                {s.name}
                                                                                                            </Typography>
                                                                                                        </Tooltip>
                                                                                                    </td>
                                                                                                    <td style={{whiteSpace:"nowrap"}}>
                                                                                                        <Button
                                                                                                            style={{ width: 220, marginRight: 16 }}
                                                                                                            variant="contained"
                                                                                                            color="primary"
                                                                                                            disabled={tableData}
                                                                                                            onClick={(e) => {
                                                                                                                e.stopPropagation();
                                                                                                                let tempids = loadingId;
                                                                                                                tempids.push(s.id);
                                                                                                                this.setState({
                                                                                                                    loadingId: tempids,
                                                                                                                });

                                                                                                                //only fetch if data is not in array, prevents multiple fetching when data is already in store
                                                                                                                if (!tableData)
                                                                                                                    try {
                                                                                                                        const res = fetch(
                                                                                                                            `page/${a.page}/advancedsearch/${s.id}`,
                                                                                                                            {
                                                                                                                                method: "POST",
                                                                                                                                headers: {
                                                                                                                                    "Content-Type":
                                                                                                                                        "application/json",
                                                                                                                                    Authorization:
                                                                                                                                        "Token " +
                                                                                                                                        authState.user
                                                                                                                                            .auth_token,
                                                                                                                                },
                                                                                                                            }
                                                                                                                        ).then((e) => {
                                                                                                                            if (e.status === 200) {
                                                                                                                                e.json().then(
                                                                                                                                    (reply) => {
                                                                                                                                        let temp = {};
                                                                                                                                        temp[s.id] =
                                                                                                                                            reply.message;
                                                                                                                                        let loadingId =
                                                                                                                                            this.state
                                                                                                                                                .loadingId; //find if in array
                                                                                                                                        let idx =
                                                                                                                                            loadingId.findIndex(
                                                                                                                                                (i) =>
                                                                                                                                                    i ===
                                                                                                                                                    s.id
                                                                                                                                            ); //remove
                                                                                                                                        if (
                                                                                                                                            idx !== -1
                                                                                                                                        )
                                                                                                                                            loadingId.splice(
                                                                                                                                                idx,
                                                                                                                                                1
                                                                                                                                            );

                                                                                                                                        //set in array results for each table on UI, update loading tables
                                                                                                                                        this.setState(
                                                                                                                                            {
                                                                                                                                                advancedSearch:
                                                                                                                                                    [
                                                                                                                                                        ...this
                                                                                                                                                            .state
                                                                                                                                                            .advancedSearch,
                                                                                                                                                        temp,
                                                                                                                                                    ],
                                                                                                                                                loadingId,
                                                                                                                                            }
                                                                                                                                        );

                                                                                                                                        //verify expansion isnt already opened as we dont want to close it
                                                                                                                                        let isOpenAlready =
                                                                                                                                            dashboardSearchExpanded.find(
                                                                                                                                                (
                                                                                                                                                    f
                                                                                                                                                ) => {
                                                                                                                                                    return f ===
                                                                                                                                                        s.id
                                                                                                                                                        ? true
                                                                                                                                                        : false;
                                                                                                                                                }
                                                                                                                                            );
                                                                                                                                        //add to dashboard to expand panel assuming it wasnt already opened
                                                                                                                                        if (
                                                                                                                                            !isOpenAlready
                                                                                                                                        )
                                                                                                                                            this.dashboardHandleChange(
                                                                                                                                                s.id
                                                                                                                                            );
                                                                                                                                    }
                                                                                                                                );
                                                                                                                            } else {
                                                                                                                                let loadingId =
                                                                                                                                    this.state
                                                                                                                                        .loadingId;
                                                                                                                                let idx =
                                                                                                                                    loadingId.findIndex(
                                                                                                                                        (i) =>
                                                                                                                                            i === s.id
                                                                                                                                    );
                                                                                                                                if (idx !== -1)
                                                                                                                                    loadingId.splice(
                                                                                                                                        idx,
                                                                                                                                        1
                                                                                                                                    );
                                                                                                                                this.setState({
                                                                                                                                    loadingId,
                                                                                                                                });
                                                                                                                            }
                                                                                                                        });
                                                                                                                        console.log(res);
                                                                                                                    } catch {
                                                                                                                        let loadingId =
                                                                                                                            this.state.loadingId;
                                                                                                                        let idx = loadingId.findIndex(
                                                                                                                            (i) => i === s.id
                                                                                                                        );
                                                                                                                        if (idx !== -1)
                                                                                                                            loadingId.splice(idx, 1);
                                                                                                                        this.setState({
                                                                                                                            loadingId,
                                                                                                                        });
                                                                                                                    }
                                                                                                            }}>
                                                                                                            <Reorder />
                                                                                                            &nbsp;&nbsp;&nbsp;Load Results
                                                                                                            {loadingId.find((l) => l === s.id) && (
                                                                                                                // <div style={{ marginLeft: -20, marginTop: 16 }}>
                                                                                                                <CircularProgress
                                                                                                                    size={16}
                                                                                                                    style={{
                                                                                                                        color: "#fff",
                                                                                                                        marginLeft: 9,
                                                                                                                    }}
                                                                                                                />
                                                                                                                // </div>
                                                                                                            )}
                                                                                                        </Button>

                                                                                                        {tableData &&
                                                                                                            Object.values(tableData)[0].length ===
                                                                                                                0 && (
                                                                                                                //in cases where nothing is returned from search
                                                                                                                <Typography
                                                                                                                    variant="subtitle1"
                                                                                                                    style={{
                                                                                                                        width: 250,
                                                                                                                        color: "#66686a",
                                                                                                                        marginLeft: 20,
                                                                                                                        textDecoration: "underline",
                                                                                                                    }}>
                                                                                                                    0 Records Returned
                                                                                                                </Typography>
                                                                                                        )}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </table>
                                                                                            {s.geometriesonmap && (
                                                                                                <Tooltip
                                                                                                    title="Saved Search Geometries"
                                                                                                    style={{
                                                                                                        paddingRight: 10,
                                                                                                        marginTop: 16,
                                                                                                        color: "#656565",
                                                                                                    }}>
                                                                                                    <MapIcon
                                                                                                        onClick={(e) => {
                                                                                                            return (
                                                                                                                e.stopPropagation(),
                                                                                                                history.push(
                                                                                                                    "/map/" +
                                                                                                                        a.page +
                                                                                                                        "/section/" +
                                                                                                                        s.section +
                                                                                                                        "/advancedsearch/" +
                                                                                                                        s.id
                                                                                                                )
                                                                                                            );
                                                                                                        }}
                                                                                                    />
                                                                                                </Tooltip>
                                                                                            )}
                                                                                            <Tooltip
                                                                                                title="Saved Search Settings"
                                                                                                style={{
                                                                                                    marginLeft: 5,
                                                                                                    marginTop: 16,
                                                                                                    color: "#656565",
                                                                                                }}>
                                                                                                <SettingsIcon
                                                                                                    onClick={(e) => {
                                                                                                        return (
                                                                                                            e.stopPropagation(),
                                                                                                            history.push(
                                                                                                                "/page/" +
                                                                                                                    a.page +
                                                                                                                    "/advancedsearch/" +
                                                                                                                    s.id
                                                                                                            )
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                            </Tooltip>
                                                                                        </AccordionSummary>

                                                                                        <AccordionDetails
                                                                                            style={{
                                                                                                paddingBottom: 8,
                                                                                                backgroundColor: "f6f0f0",
                                                                                                boxShadow:
                                                                                                    " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                                                                                            }}>
                                                                                            <Grid item xs={12}>
                                                                                                {/* TABLE SECTION */}
                                                                                                <Paper className={classes.root}>
                                                                                                    <Table className={classes.table}>
                                                                                                        <EnhancedTableHead
                                                                                                            // order={order}
                                                                                                            // orderBy={orderBy}
                                                                                                            onRequestSort={(e) =>
                                                                                                                this.handleRequestSort(
                                                                                                                    e,
                                                                                                                    s.id,
                                                                                                                    columnRecord
                                                                                                                )
                                                                                                            }
                                                                                                            columnData={columnRecord}
                                                                                                        />
                                                                                                        <TableBody>
                                                                                                            {(() => {
                                                                                                                if (!tableData) {
                                                                                                                    return (
                                                                                                                        <TableRow>
                                                                                                                            <CustomTableCell
                                                                                                                                colSpan={
                                                                                                                                    columnRecord.length
                                                                                                                                }
                                                                                                                                // className={classes.centerAlign}
                                                                                                                                style={{
                                                                                                                                    paddingBottom:
                                                                                                                                        "35px",
                                                                                                                                    height: 10,
                                                                                                                                }}>
                                                                                                                                <div
                                                                                                                                    className={
                                                                                                                                        classes.centerAlign
                                                                                                                                    }
                                                                                                                                    style={{
                                                                                                                                        position:
                                                                                                                                            "absolute",
                                                                                                                                        float: "right",
                                                                                                                                        width: "100%",
                                                                                                                                    }}>
                                                                                                                                    {columnRecord &&
                                                                                                                                        columnRecord.length <
                                                                                                                                            2 &&
                                                                                                                                        "No Column Fields have been Selected. Select Fields from the 'Saved Search Table Settings'  "}

                                                                                                                                    {columnRecord &&
                                                                                                                                        columnRecord.length <
                                                                                                                                            2 && (
                                                                                                                                            <SettingsIcon
                                                                                                                                                onClick={() =>
                                                                                                                                                    history.push(
                                                                                                                                                        "/page/" +
                                                                                                                                                            a.page +
                                                                                                                                                            "/advancedsearch/" +
                                                                                                                                                            s.id
                                                                                                                                                    )
                                                                                                                                                }
                                                                                                                                            />
                                                                                                                                        )}
                                                                                                                                </div>
                                                                                                                            </CustomTableCell>
                                                                                                                        </TableRow>
                                                                                                                    );
                                                                                                                }
                                                                                                                if (tableData.length < 1) {
                                                                                                                    return (
                                                                                                                        <TableRow>
                                                                                                                            <CustomTableCell
                                                                                                                                colSpan={
                                                                                                                                    columnRecord.length
                                                                                                                                }
                                                                                                                                className={
                                                                                                                                    classes.centerAlign
                                                                                                                                }
                                                                                                                                style={{
                                                                                                                                    paddingBottom: 8,
                                                                                                                                }}>
                                                                                                                                No Records Found
                                                                                                                            </CustomTableCell>
                                                                                                                        </TableRow>
                                                                                                                    );
                                                                                                                }

                                                                                                                if (tableData) {
                                                                                                                    // Group columns with same name together
                                                                                                                    // tableData.forEach(function(n) {
                                                                                                                    //     var cols = [];
                                                                                                                    //     n.columns.forEach(function(c) {
                                                                                                                    //         // If column isn't in temp array yet add it
                                                                                                                    //         if (cols.filter(e => e.field === c.field).length === 0) {
                                                                                                                    //             cols.push(c);
                                                                                                                    //         } else if (c.value) {
                                                                                                                    //             // Else the column already is in the temp array
                                                                                                                    //             // And if this new column has a value that means the other column has no value
                                                                                                                    //             // Update the value here
                                                                                                                    //             var objIndex = cols.findIndex(e => e.field === c.field);
                                                                                                                    //             cols[objIndex].value = c.value;
                                                                                                                    //         }
                                                                                                                    //     });
                                                                                                                    //     n.cols = cols;
                                                                                                                    // });
                                                                                                                    tablePagination = rowsPages.find(
                                                                                                                        (i) => i.id === s.id
                                                                                                                    );
                                                                                                                    if (
                                                                                                                        typeof tablePagination ===
                                                                                                                        "object"
                                                                                                                    )
                                                                                                                        return tableData[s.id]
                                                                                                                            .slice(
                                                                                                                                tablePagination.page *
                                                                                                                                    tablePagination.rowsPerPage,
                                                                                                                                tablePagination.page *
                                                                                                                                    tablePagination.rowsPerPage +
                                                                                                                                    tablePagination.rowsPerPage
                                                                                                                            )
                                                                                                                            .map((n) => {
                                                                                                                                return (
                                                                                                                                    <TableRow hover>
                                                                                                                                        <CustomTableCell
                                                                                                                                            className={
                                                                                                                                                classes.nowrap
                                                                                                                                            }>
                                                                                                                                            <Tooltip title="Edit">
                                                                                                                                                {/* LINK TO EDIT OF RECORD "EXPANSIONPANELFORMRECORD" */}
                                                                                                                                                <Button
                                                                                                                                                    component={
                                                                                                                                                        Link
                                                                                                                                                    }
                                                                                                                                                    to={
                                                                                                                                                        "/page/" +
                                                                                                                                                        n.page_id +
                                                                                                                                                        "/parentrecord/" +
                                                                                                                                                        n.parentrecord +
                                                                                                                                                        "/section/" +
                                                                                                                                                        n.section +
                                                                                                                                                        "/recordsection/" +
                                                                                                                                                        n.recordsection +
                                                                                                                                                        "/form"
                                                                                                                                                    }
                                                                                                                                                    className={
                                                                                                                                                        classes.deleteWidth
                                                                                                                                                    }>
                                                                                                                                                    <EditIcon color="primary" />
                                                                                                                                                </Button>
                                                                                                                                            </Tooltip>
                                                                                                                                        </CustomTableCell>
                                                                                                                                        {/* PRINTING OF THE DATA IN THE TABLE */}
                                                                                                                                        {columnRecord.map(
                                                                                                                                            (
                                                                                                                                                column,
                                                                                                                                                index
                                                                                                                                            ) => {
                                                                                                                                                if (
                                                                                                                                                    index !==
                                                                                                                                                    0
                                                                                                                                                ) {
                                                                                                                                                    //dont print 0 as that is button location
                                                                                                                                                    var found =
                                                                                                                                                        n.recordfields.find(
                                                                                                                                                            (
                                                                                                                                                                actCol
                                                                                                                                                            ) => {
                                                                                                                                                                if (
                                                                                                                                                                    actCol.field_id ===
                                                                                                                                                                    column.id
                                                                                                                                                                ) {
                                                                                                                                                                    return actCol.userinput;
                                                                                                                                                                }
                                                                                                                                                                return null;
                                                                                                                                                            }
                                                                                                                                                        );
                                                                                                                                                    if (
                                                                                                                                                        found
                                                                                                                                                    ) {
                                                                                                                                                        if (
                                                                                                                                                            found.type ===
                                                                                                                                                            "Phone Number"
                                                                                                                                                        ) {
                                                                                                                                                            return (
                                                                                                                                                                <CustomTableCell
                                                                                                                                                                    key={
                                                                                                                                                                        index +
                                                                                                                                                                        column.label
                                                                                                                                                                    }>
                                                                                                                                                                    <NumberFormat
                                                                                                                                                                        format="+1 (###) ###-####"
                                                                                                                                                                        allowEmptyFormatting
                                                                                                                                                                        mask="_"
                                                                                                                                                                        displayType={
                                                                                                                                                                            "text"
                                                                                                                                                                        }
                                                                                                                                                                        value={
                                                                                                                                                                            found.userinput
                                                                                                                                                                        }
                                                                                                                                                                    />
                                                                                                                                                                </CustomTableCell>
                                                                                                                                                            );
                                                                                                                                                        } else {
                                                                                                                                                            if (found.userinput.length > 250) {
                                                                                                                                                                return (
                                                                                                                                                                    <Tooltip title={found.userinput}>
                                                                                                                                                                        <CustomTableCell key={index + column.label}>
                                                                                                                                                                            <div className={classes.ellipsis}>
                                                                                                                                                                                {found.userinput}
                                                                                                                                                                            </div>
                                                                                                                                                                        </CustomTableCell>
                                                                                                                                                                    </Tooltip>
                                                                                                                                                                )
                                                                                                                                                            } else {
                                                                                                                                                                return (
                                                                                                                                                                    <CustomTableCell key={index + column.label}>
                                                                                                                                                                        {found.userinput}
                                                                                                                                                                    </CustomTableCell>
                                                                                                                                                                )
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    } else {
                                                                                                                                                        return (
                                                                                                                                                            <CustomTableCell>
                                                                                                                                                                {
                                                                                                                                                                    "-"
                                                                                                                                                                }
                                                                                                                                                            </CustomTableCell>
                                                                                                                                                        );
                                                                                                                                                    }
                                                                                                                                                } else {
                                                                                                                                                    return null;
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        )}
                                                                                                                                    </TableRow>
                                                                                                                                );
                                                                                                                            });
                                                                                                                }
                                                                                                            })()}
                                                                                                        </TableBody>
                                                                                                        {tableData &&
                                                                                                            tableData[s.id].length > 4 &&
                                                                                                            tablePagination && (
                                                                                                                <TableFooter>
                                                                                                                    <TableRow>
                                                                                                                        <TablePagination
                                                                                                                            colSpan={
                                                                                                                                columnRecord.length
                                                                                                                            }
                                                                                                                            count={
                                                                                                                                tableData[s.id].length
                                                                                                                            }
                                                                                                                            rowsPerPage={
                                                                                                                                tablePagination.rowsPerPage
                                                                                                                            }
                                                                                                                            page={
                                                                                                                                tablePagination.page
                                                                                                                            }
                                                                                                                            onChangePage={(e, i) =>
                                                                                                                                this.handleChangePage(
                                                                                                                                    e,
                                                                                                                                    i,
                                                                                                                                    s.id
                                                                                                                                )
                                                                                                                            }
                                                                                                                            onChangeRowsPerPage={(
                                                                                                                                e,
                                                                                                                                i
                                                                                                                            ) =>
                                                                                                                                this.handleChangeRowsPerPage(
                                                                                                                                    e,
                                                                                                                                    i,
                                                                                                                                    s.id
                                                                                                                                )
                                                                                                                            }
                                                                                                                            ActionsComponent={
                                                                                                                                TablePaginationActionsWrapped
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </TableRow>
                                                                                                                </TableFooter>
                                                                                                            )}
                                                                                                    </Table>
                                                                                                </Paper>
                                                                                            </Grid>
                                                                                        </AccordionDetails>
                                                                                    </Accordion>
                                                                                </Grid>
                                                                            );
                                                                        } else return null;
                                                                    })}
                                                                </Grid>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </form>
                                                )}
                                            </Formm>
                                        )}
                                    {perm &&
                                        perm.readonlyexternal && ( //all external users will see this part of code same as above other than who can see
                                            <Formm
                                                key={found.id}
                                                dontValidateOnMount={true}
                                                validateOnSubmit={true}
                                                getApi={(el) => (this.formApi = el)}>
                                                {(formApi) => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <Accordion defaultExpanded={i === 0 ? true : false}>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                style={{ backgroundColor: a.expansion_color ? a.expansion_color : "#ddd" }}
                                                                classes={{ root: classes.expandedMargin }}
                                                                className={classes.titlePanel}>
                                                                <Icon
                                                                    style={{
                                                                        color: a.expansion_font_color ? a.expansion_font_color : "#66686a",
                                                                    }}>
                                                                    search
                                                                </Icon>
                                                                <Grid container spacing={0}>
                                                                    <Grid item xs={10}>
                                                                        <Typography
                                                                            variant="subtitle1"
                                                                            style={{
                                                                                marginLeft: 12,
                                                                                color: a.expansion_font_color ? a.expansion_font_color : "#66686a",
                                                                            }}>
                                                                            {a.name}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={2} style={{ textAlign: "end", paddingTop: 3 }}>
                                                                        {a.advancedSearch.length === 0 && (
                                                                            <Icon
                                                                                style={{ color: "#3c8ac7" }}
                                                                                onClick={() => {
                                                                                    ormAdvancedGroupDelete(a.id);
                                                                                }}>
                                                                                delete
                                                                            </Icon>
                                                                        )}
                                                                    </Grid>
                                                                </Grid>
                                                            </AccordionSummary>
                                                            <AccordionDetails style={{ paddingBottom: 8 }}>
                                                                <Grid container>
                                                                    {a.advancedSearch.map((s, i) => {
                                                                        //only show searches that are allowed on the dashboard
                                                                        //here we will show only external users
                                                                        if (
                                                                            s.dashboard.toString() === "true" &&
                                                                            s.accessby === "All Including External Read Only Users"
                                                                        ) {
                                                                            var getFields = [];
                                                                            s.advancedtablefields.forEach((t) => getFields.push(t.field));

                                                                            var columnRecord = [];
                                                                            columnRecord.push({ id: "actions", label: "", allowSort: false });
                                                                            //get columns foreach search
                                                                            pagefields.forEach((pf) =>
                                                                                pf.form.forEach((f) =>
                                                                                    f.groups.forEach((g) =>
                                                                                        g.fields.forEach((f) =>
                                                                                            getFields.find((gf) =>
                                                                                                gf === f.id
                                                                                                    ? columnRecord.push({
                                                                                                          id: f.id,
                                                                                                          label: f.name,
                                                                                                          type: f.type,
                                                                                                          allowSort: true,
                                                                                                      })
                                                                                                    : null
                                                                                            )
                                                                                        )
                                                                                    )
                                                                                )
                                                                            );

                                                                            //match up the advanced search that was loaded from the loaded button
                                                                            var tableData;
                                                                            if (advancedSearch.length !== 0)
                                                                                tableData = advancedSearch.find((k, i) =>
                                                                                    parseInt(Object.keys(k)) === s.id ? Object.values(k) : null
                                                                                );
                                                                            var tablePagination;
                                                                            var expand = dashboardSearchExpanded.find((f) => {
                                                                                return f === s.id ? true : false;
                                                                            });
                                                                            return (
                                                                                <Grid item xs={12}>
                                                                                    <Accordion
                                                                                        defaultExpanded={false}
                                                                                        expanded={expand ? true : false}
                                                                                        style={{
                                                                                            boxShadow:
                                                                                                " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                                                                                        }}
                                                                                        onChange={(e) => this.dashboardHandleChange(s.id)}>
                                                                                        <AccordionSummary
                                                                                            expandIcon={<ExpandMoreIcon />}
                                                                                            style={{
                                                                                                backgroundColor: "#ddd",
                                                                                            }}
                                                                                            classes={{ root: classes.expandedMargin }}
                                                                                            className={classes.titlePanel}>
                                                                                            <Icon style={{ marginTop: 8, color: "#66686a" }}>
                                                                                                search
                                                                                            </Icon>
                                                                                            <table>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <Tooltip title={s.name}>
                                                                                                            <Typography
                                                                                                                variant="subtitle1"
                                                                                                                style={{
                                                                                                                    marginLeft: 12,
                                                                                                                    marginTop: 5,
                                                                                                                    color: "#66686a",
                                                                                                                    display: "inline-block",
                                                                                                                }}>
                                                                                                                {s.name
                                                                                                                    ? s.name.length > 49
                                                                                                                        ? s.name.substring(0, 50) + "..."
                                                                                                                        : s.name
                                                                                                                    : "-"}
                                                                                                            </Typography>
                                                                                                        </Tooltip>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                    <div style={{ display: "inline-flex" }}>
                                                                                                        <Button
                                                                                                            style={{ width: 220 }}
                                                                                                            variant="contained"
                                                                                                            color="primary"
                                                                                                            disabled={tableData}
                                                                                                            onClick={(e) => {
                                                                                                                e.stopPropagation();
                                                                                                                let tempids = loadingId;
                                                                                                                tempids.push(s.id);
                                                                                                                this.setState({
                                                                                                                    loadingId: tempids,
                                                                                                                });

                                                                                                                //only fetch if data is not in array, prevents multiple fetching when data is already in store
                                                                                                                if (!tableData)
                                                                                                                    try {
                                                                                                                        const res = fetch(
                                                                                                                            `page/${a.page}/advancedsearch/${s.id}`,
                                                                                                                            {
                                                                                                                                method: "POST",
                                                                                                                                headers: {
                                                                                                                                    "Content-Type":
                                                                                                                                        "application/json",
                                                                                                                                    Authorization:
                                                                                                                                        "Token " +
                                                                                                                                        authState.user
                                                                                                                                            .auth_token,
                                                                                                                                },
                                                                                                                            }
                                                                                                                        ).then((e) => {
                                                                                                                            if (e.status === 200) {
                                                                                                                                e.json().then(
                                                                                                                                    (reply) => {
                                                                                                                                        let temp = {};
                                                                                                                                        temp[s.id] =
                                                                                                                                            reply.message;
                                                                                                                                        let loadingId =
                                                                                                                                            this.state
                                                                                                                                                .loadingId; //find if in array
                                                                                                                                        let idx =
                                                                                                                                            loadingId.findIndex(
                                                                                                                                                (i) =>
                                                                                                                                                    i ===
                                                                                                                                                    s.id
                                                                                                                                            ); //remove
                                                                                                                                        if (
                                                                                                                                            idx !== -1
                                                                                                                                        )
                                                                                                                                            loadingId.splice(
                                                                                                                                                idx,
                                                                                                                                                1
                                                                                                                                            );

                                                                                                                                        //set in array results for each table on UI, update loading tables
                                                                                                                                        this.setState(
                                                                                                                                            {
                                                                                                                                                advancedSearch:
                                                                                                                                                    [
                                                                                                                                                        ...this
                                                                                                                                                            .state
                                                                                                                                                            .advancedSearch,
                                                                                                                                                        temp,
                                                                                                                                                    ],
                                                                                                                                                loadingId,
                                                                                                                                            }
                                                                                                                                        );

                                                                                                                                        //verify expansion isnt already opened as we dont want to close it
                                                                                                                                        let isOpenAlready =
                                                                                                                                            dashboardSearchExpanded.find(
                                                                                                                                                (
                                                                                                                                                    f
                                                                                                                                                ) => {
                                                                                                                                                    return f ===
                                                                                                                                                        s.id
                                                                                                                                                        ? true
                                                                                                                                                        : false;
                                                                                                                                                }
                                                                                                                                            );
                                                                                                                                        //add to dashboard to expand panel assuming it wasnt already opened
                                                                                                                                        if (
                                                                                                                                            !isOpenAlready
                                                                                                                                        )
                                                                                                                                            this.dashboardHandleChange(
                                                                                                                                                s.id
                                                                                                                                            );
                                                                                                                                    }
                                                                                                                                );
                                                                                                                            } else {
                                                                                                                                let loadingId =
                                                                                                                                    this.state
                                                                                                                                        .loadingId;
                                                                                                                                let idx =
                                                                                                                                    loadingId.findIndex(
                                                                                                                                        (i) =>
                                                                                                                                            i === s.id
                                                                                                                                    );
                                                                                                                                if (idx !== -1)
                                                                                                                                    loadingId.splice(
                                                                                                                                        idx,
                                                                                                                                        1
                                                                                                                                    );
                                                                                                                                this.setState({
                                                                                                                                    loadingId,
                                                                                                                                });
                                                                                                                            }
                                                                                                                        });
                                                                                                                        console.log(res);
                                                                                                                    } catch {
                                                                                                                        let loadingId =
                                                                                                                            this.state.loadingId;
                                                                                                                        let idx = loadingId.findIndex(
                                                                                                                            (i) => i === s.id
                                                                                                                        );
                                                                                                                        if (idx !== -1)
                                                                                                                            loadingId.splice(idx, 1);
                                                                                                                        this.setState({
                                                                                                                            loadingId,
                                                                                                                        });
                                                                                                                    }
                                                                                                            }}>
                                                                                                            <Reorder />
                                                                                                            &nbsp;&nbsp;&nbsp;Load Results
                                                                                                            {loadingId.find((l) => l === s.id) && (
                                                                                                                // <div style={{ marginLeft: -20, marginTop: 16 }}>
                                                                                                                <CircularProgress
                                                                                                                    size={16}
                                                                                                                    style={{
                                                                                                                        color: "#fff",
                                                                                                                        marginLeft: 9,
                                                                                                                    }}
                                                                                                                />
                                                                                                                // </div>
                                                                                                            )}
                                                                                                        </Button>

                                                                                                        {tableData &&
                                                                                                            Object.values(tableData)[0].length ===
                                                                                                                0 && (
                                                                                                                //in cases where nothing is returned from search
                                                                                                                <Typography
                                                                                                                    variant="subtitle1"
                                                                                                                    style={{
                                                                                                                        width: 250,
                                                                                                                        color: "#66686a",
                                                                                                                        marginLeft: 20,
                                                                                                                        textDecoration: "underline",
                                                                                                                    }}>
                                                                                                                    0 Records Returned
                                                                                                                </Typography>
                                                                                                            )}
                                                                                                    </div>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {s.geometriesonmap && (
                                                                                                            <Tooltip
                                                                                                                title="Saved Search Geometries"
                                                                                                                style={{
                                                                                                                    paddingRight: 10,
                                                                                                                    color: "#656565",
                                                                                                                }}>
                                                                                                                <MapIcon
                                                                                                                    onClick={(e) => {
                                                                                                                        return (
                                                                                                                            e.stopPropagation(),
                                                                                                                            history.push(
                                                                                                                                "/map/" +
                                                                                                                                    a.page +
                                                                                                                                    "/section/" +
                                                                                                                                    s.section +
                                                                                                                                    "/advancedsearch/" +
                                                                                                                                    s.id
                                                                                                                            )
                                                                                                                        );
                                                                                                                    }}
                                                                                                                />
                                                                                                            </Tooltip>
                                                                                                        )}

                                                                                                        <Tooltip
                                                                                                            title="Saved Search Settings"
                                                                                                            style={{
                                                                                                                marginLeft: 5,
                                                                                                                marginTop: 10,
                                                                                                                color: "#656565",
                                                                                                            }}>
                                                                                                            <SettingsIcon
                                                                                                                onClick={(e) => {
                                                                                                                    return (
                                                                                                                        e.stopPropagation(),
                                                                                                                        history.push(
                                                                                                                            "/page/" +
                                                                                                                                a.page +
                                                                                                                                "/advancedsearch/" +
                                                                                                                                s.id
                                                                                                                        )
                                                                                                                    );
                                                                                                                }}
                                                                                                            />
                                                                                                        </Tooltip>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </table>
                                                                                        </AccordionSummary>

                                                                                        <AccordionDetails
                                                                                            style={{
                                                                                                paddingBottom: 8,
                                                                                                backgroundColor: "f6f0f0",
                                                                                                boxShadow:
                                                                                                    " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                                                                                            }}>
                                                                                            <Grid item xs={12}>
                                                                                                {/* TABLE SECTION */}
                                                                                                <Paper className={classes.root}>
                                                                                                    <Table className={classes.table}>
                                                                                                        <EnhancedTableHead
                                                                                                            // order={order}
                                                                                                            // orderBy={orderBy}
                                                                                                            onRequestSort={(e) =>
                                                                                                                this.handleRequestSort(
                                                                                                                    e,
                                                                                                                    s.id,
                                                                                                                    columnRecord
                                                                                                                )
                                                                                                            }
                                                                                                            columnData={columnRecord}
                                                                                                        />
                                                                                                        <TableBody>
                                                                                                            {(() => {
                                                                                                                if (!tableData) {
                                                                                                                    return (
                                                                                                                        <TableRow>
                                                                                                                            <CustomTableCell
                                                                                                                                colSpan={
                                                                                                                                    columnRecord.length
                                                                                                                                }
                                                                                                                                // className={classes.centerAlign}
                                                                                                                                style={{
                                                                                                                                    paddingBottom:
                                                                                                                                        "35px",
                                                                                                                                    height: 10,
                                                                                                                                }}>
                                                                                                                                <div
                                                                                                                                    className={
                                                                                                                                        classes.centerAlign
                                                                                                                                    }
                                                                                                                                    style={{
                                                                                                                                        position:
                                                                                                                                            "absolute",
                                                                                                                                        float: "right",
                                                                                                                                        width: "100%",
                                                                                                                                    }}>
                                                                                                                                    {columnRecord &&
                                                                                                                                        columnRecord.length <
                                                                                                                                            2 &&
                                                                                                                                        "No Column Fields have been Selected. Select Fields from the 'Saved Search Table Settings'  "}

                                                                                                                                    {columnRecord &&
                                                                                                                                        columnRecord.length <
                                                                                                                                            2 && (
                                                                                                                                            <SettingsIcon
                                                                                                                                                onClick={() =>
                                                                                                                                                    history.push(
                                                                                                                                                        "/page/" +
                                                                                                                                                            a.page +
                                                                                                                                                            "/advancedsearch/" +
                                                                                                                                                            s.id
                                                                                                                                                    )
                                                                                                                                                }
                                                                                                                                            />
                                                                                                                                        )}
                                                                                                                                </div>
                                                                                                                            </CustomTableCell>
                                                                                                                        </TableRow>
                                                                                                                    );
                                                                                                                }
                                                                                                                if (tableData.length < 1) {
                                                                                                                    return (
                                                                                                                        <TableRow>
                                                                                                                            <CustomTableCell
                                                                                                                                colSpan={
                                                                                                                                    columnRecord.length
                                                                                                                                }
                                                                                                                                className={
                                                                                                                                    classes.centerAlign
                                                                                                                                }
                                                                                                                                style={{
                                                                                                                                    paddingBottom: 8,
                                                                                                                                }}>
                                                                                                                                No Records Found
                                                                                                                            </CustomTableCell>
                                                                                                                        </TableRow>
                                                                                                                    );
                                                                                                                }

                                                                                                                if (tableData) {
                                                                                                                    // Group columns with same name together
                                                                                                                    // tableData.forEach(function(n) {
                                                                                                                    //     var cols = [];
                                                                                                                    //     n.columns.forEach(function(c) {
                                                                                                                    //         // If column isn't in temp array yet add it
                                                                                                                    //         if (cols.filter(e => e.field === c.field).length === 0) {
                                                                                                                    //             cols.push(c);
                                                                                                                    //         } else if (c.value) {
                                                                                                                    //             // Else the column already is in the temp array
                                                                                                                    //             // And if this new column has a value that means the other column has no value
                                                                                                                    //             // Update the value here
                                                                                                                    //             var objIndex = cols.findIndex(e => e.field === c.field);
                                                                                                                    //             cols[objIndex].value = c.value;
                                                                                                                    //         }
                                                                                                                    //     });
                                                                                                                    //     n.cols = cols;
                                                                                                                    // });
                                                                                                                    tablePagination = rowsPages.find(
                                                                                                                        (i) => i.id === s.id
                                                                                                                    );
                                                                                                                    if (
                                                                                                                        typeof tablePagination ===
                                                                                                                        "object"
                                                                                                                    )
                                                                                                                        return tableData[s.id]
                                                                                                                            .slice(
                                                                                                                                tablePagination.page *
                                                                                                                                    tablePagination.rowsPerPage,
                                                                                                                                tablePagination.page *
                                                                                                                                    tablePagination.rowsPerPage +
                                                                                                                                    tablePagination.rowsPerPage
                                                                                                                            )
                                                                                                                            .map((n) => {
                                                                                                                                return (
                                                                                                                                    <TableRow hover>
                                                                                                                                        <CustomTableCell
                                                                                                                                            className={
                                                                                                                                                classes.nowrap
                                                                                                                                            }>
                                                                                                                                            <Tooltip title="Edit">
                                                                                                                                                {/* LINK TO EDIT OF RECORD "EXPANSIONPANELFORMRECORD" */}
                                                                                                                                                <Button
                                                                                                                                                    component={
                                                                                                                                                        Link
                                                                                                                                                    }
                                                                                                                                                    to={
                                                                                                                                                        "/page/" +
                                                                                                                                                        n.page_id +
                                                                                                                                                        "/parentrecord/" +
                                                                                                                                                        n.parentrecord +
                                                                                                                                                        "/section/" +
                                                                                                                                                        n.section +
                                                                                                                                                        "/recordsection/" +
                                                                                                                                                        n.recordsection +
                                                                                                                                                        "/form"
                                                                                                                                                    }
                                                                                                                                                    className={
                                                                                                                                                        classes.deleteWidth
                                                                                                                                                    }>
                                                                                                                                                    <EditIcon color="primary" />
                                                                                                                                                </Button>
                                                                                                                                            </Tooltip>
                                                                                                                                        </CustomTableCell>
                                                                                                                                        {/* PRINTING OF THE DATA IN THE TABLE */}
                                                                                                                                        {columnRecord.map(
                                                                                                                                            (
                                                                                                                                                column,
                                                                                                                                                index
                                                                                                                                            ) => {
                                                                                                                                                if (
                                                                                                                                                    index !==
                                                                                                                                                    0
                                                                                                                                                ) {
                                                                                                                                                    //dont print 0 as that is button location
                                                                                                                                                    var found =
                                                                                                                                                        n.recordfields.find(
                                                                                                                                                            (
                                                                                                                                                                actCol
                                                                                                                                                            ) => {
                                                                                                                                                                if (
                                                                                                                                                                    actCol.field_id ===
                                                                                                                                                                    column.id
                                                                                                                                                                ) {
                                                                                                                                                                    return actCol.userinput;
                                                                                                                                                                }
                                                                                                                                                                return null;
                                                                                                                                                            }
                                                                                                                                                        );
                                                                                                                                                    if (
                                                                                                                                                        found
                                                                                                                                                    ) {
                                                                                                                                                        if (
                                                                                                                                                            found.type ===
                                                                                                                                                            "Phone Number"
                                                                                                                                                        ) {
                                                                                                                                                            return (
                                                                                                                                                                <CustomTableCell
                                                                                                                                                                    key={
                                                                                                                                                                        index +
                                                                                                                                                                        column.label
                                                                                                                                                                    }>
                                                                                                                                                                    <NumberFormat
                                                                                                                                                                        format="+1 (###) ###-####"
                                                                                                                                                                        allowEmptyFormatting
                                                                                                                                                                        mask="_"
                                                                                                                                                                        displayType={
                                                                                                                                                                            "text"
                                                                                                                                                                        }
                                                                                                                                                                        value={
                                                                                                                                                                            found.userinput
                                                                                                                                                                        }
                                                                                                                                                                    />
                                                                                                                                                                </CustomTableCell>
                                                                                                                                                            );
                                                                                                                                                        } else {
                                                                                                                                                            if (found.userinput.length > 250) {
                                                                                                                                                                return (
                                                                                                                                                                    <Tooltip title={found.userinput}>
                                                                                                                                                                        <CustomTableCell key={index + column.label}>
                                                                                                                                                                            <div className={classes.ellipsis}>
                                                                                                                                                                                {found.userinput}
                                                                                                                                                                            </div>
                                                                                                                                                                        </CustomTableCell>
                                                                                                                                                                    </Tooltip>
                                                                                                                                                                )
                                                                                                                                                            } else {
                                                                                                                                                                return (
                                                                                                                                                                    <CustomTableCell key={index + column.label}>
                                                                                                                                                                        {found.userinput}
                                                                                                                                                                    </CustomTableCell>
                                                                                                                                                                )
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    } else {
                                                                                                                                                        return (
                                                                                                                                                            <CustomTableCell>
                                                                                                                                                                {
                                                                                                                                                                    "-"
                                                                                                                                                                }
                                                                                                                                                            </CustomTableCell>
                                                                                                                                                        );
                                                                                                                                                    }
                                                                                                                                                } else {
                                                                                                                                                    return null;
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        )}
                                                                                                                                    </TableRow>
                                                                                                                                );
                                                                                                                            });
                                                                                                                }
                                                                                                            })()}
                                                                                                        </TableBody>
                                                                                                        {tableData &&
                                                                                                            tableData[s.id].length > 4 &&
                                                                                                            tablePagination && (
                                                                                                                <TableFooter>
                                                                                                                    <TableRow>
                                                                                                                        <TablePagination
                                                                                                                            colSpan={
                                                                                                                                columnRecord.length
                                                                                                                            }
                                                                                                                            count={
                                                                                                                                tableData[s.id].length
                                                                                                                            }
                                                                                                                            rowsPerPage={
                                                                                                                                tablePagination.rowsPerPage
                                                                                                                            }
                                                                                                                            page={
                                                                                                                                tablePagination.page
                                                                                                                            }
                                                                                                                            onChangePage={(e, i) =>
                                                                                                                                this.handleChangePage(
                                                                                                                                    e,
                                                                                                                                    i,
                                                                                                                                    s.id
                                                                                                                                )
                                                                                                                            }
                                                                                                                            onChangeRowsPerPage={(
                                                                                                                                e,
                                                                                                                                i
                                                                                                                            ) =>
                                                                                                                                this.handleChangeRowsPerPage(
                                                                                                                                    e,
                                                                                                                                    i,
                                                                                                                                    s.id
                                                                                                                                )
                                                                                                                            }
                                                                                                                            ActionsComponent={
                                                                                                                                TablePaginationActionsWrapped
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </TableRow>
                                                                                                                </TableFooter>
                                                                                                            )}
                                                                                                    </Table>
                                                                                                </Paper>
                                                                                            </Grid>
                                                                                        </AccordionDetails>
                                                                                    </Accordion>
                                                                                </Grid>
                                                                            );
                                                                        } else return null;
                                                                    })}
                                                                </Grid>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </form>
                                                )}
                                            </Formm>
                                        )}
                                </>
                            );
                        } else return null;
                    } else return null;
                });
            }

            return (
                <>
                    {returned_jsx}
                    {returned_jsx2}
                </>
            );
        }

        if (!advancedSearchLandingPageTable) {
            //OUTPUTS AN EXPANSION PANEL "TABLE" OF 'ALL' OF THE 'ADVANCED SEARCHES' FOR THE GIVEN PAGE, NEEDED FOR APPCONTAINER AUTHENTICATION
            //page/advid/advancedsearch
            // drag and drop is only allowed on this page for each module and will mirror onto the dashboard...
            const { smallAnchor, searchSection } = this.state;
            const smallMenuOpen = Boolean(smallAnchor);
            return (
                <AppContainer authenticated>
                    <Grid container spacing={2}>
                        <Grid item xs={5} style={{alignItems:"center", display:"flex"}}>
                            {page.length > 0 && <Typography variant="h5">{`All ${page[0].name} Saved Searches`}</Typography>}
                        </Grid>
                        <Grid item xs={7}>
                            <TopButtons perm={perm} page={page[0]} section={sections.find((s) => s.parent === true)} showPageName={false} fromAnalytics />
                        </Grid>
                        <Grid item xs={12} style={{paddingTop: 0}}>
                            <>
                                {perm && perm.readonlyexternal === false && (
                                    <>
                                        <DragDropContext onDragEnd={this.onDragEnd}>
                                            <Droppable droppableId="group_list" type="group_list" key="group_list">
                                                {(provided, snapshot) => (
                                                    <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                                        {advancedgroups.map((a, idx) => {
                                                            return (
                                                                <Draggable
                                                                    key={a.id}
                                                                    draggableId={a.id}
                                                                    index={a.order}
                                                                    isDragDisabled={!authState.user.is_city_admin}>
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            key={a.id}
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                                                                            <Formm
                                                                                key={this.formKey}
                                                                                dontValidateOnMount={true}
                                                                                validateOnSubmit={true}
                                                                                getApi={(el) => (this.formApi = el)}>
                                                                                {(formApi) => (
                                                                                    <form onSubmit={formApi.submitForm}>
                                                                                        <Accordion defaultExpanded={true}>
                                                                                            <AccordionSummary
                                                                                                expandIcon={<ExpandMoreIcon />}
                                                                                                style={{
                                                                                                    backgroundColor: a.expansion_color
                                                                                                        ? a.expansion_color
                                                                                                        : "#ddd",
                                                                                                }}
                                                                                                classes={{ root: classes.expandedMargin }}
                                                                                                className={classes.titlePanel}>
                                                                                                <Icon
                                                                                                    style={{
                                                                                                        color: a.expansion_font_color
                                                                                                            ? a.expansion_font_color
                                                                                                            : "#66686a",
                                                                                                    }}>
                                                                                                    search
                                                                                                </Icon>
                                                                                                <Grid container spacing={1}>
                                                                                                    <Grid xs={5}>
                                                                                                        <Typography
                                                                                                            variant="subtitle1"
                                                                                                            style={{
                                                                                                                marginLeft: 12,
                                                                                                                color: a.expansion_font_color
                                                                                                                    ? a.expansion_font_color
                                                                                                                    : "#66686a",
                                                                                                            }}>
                                                                                                            {a.name}
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                    <Grid
                                                                                                        xs={7}
                                                                                                        style={{ textAlign: "end", paddingTop: 3 }}>
                                                                                                        {a.advancedSearch.length === 0 && (
                                                                                                            <Icon
                                                                                                                style={{
                                                                                                                    color: "#3c8ac7",
                                                                                                                    verticalAlign: "middle",
                                                                                                                    marginRight: 12,
                                                                                                                }}
                                                                                                                onClick={() => {
                                                                                                                    // ormAdvancedGroupDelete(a.id);
                                                                                                                    this.setState({
                                                                                                                        deleteDialogOpen: true,
                                                                                                                        searchToDelete: null,
                                                                                                                        groupDelete: a,
                                                                                                                    });
                                                                                                                }}>
                                                                                                                delete
                                                                                                            </Icon>
                                                                                                        )}
                                                                                                        <img
                                                                                                            style={{
                                                                                                                width: "1.5em",
                                                                                                                height: "1.6em",
                                                                                                                verticalAlign: "middle",
                                                                                                                marginRight: 11,
                                                                                                                marginLeft: 6,
                                                                                                            }}
                                                                                                            src={drag}
                                                                                                            alt="fireSpot"
                                                                                                        />
                                                                                                        <HelpLabel
                                                                                                            style={{ marginBottom: 0 }}
                                                                                                            inputLabel=" "
                                                                                                            helpText="Drag and drop the Advanced Groups or Saved Searches for re-ordering. This ordering will be shown on 'All Saved Searches' and on each 'modules' dashboard. <br><br><b>Note:</b> users must have 'admin' permisions to re-order Advanced Groups or Saved Searches."
                                                                                                        />
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </AccordionSummary>
                                                                                            <AccordionDetails style={{ paddingBottom: 8 }}>
                                                                                                <Droppable
                                                                                                    droppableId={"field_list-" + a.id}
                                                                                                    type={"field_list-" + a.id}
                                                                                                    key={"field_list-" + a.id}>
                                                                                                    {(provided, snapshot) => (
                                                                                                        <div
                                                                                                            ref={provided.innerRef}
                                                                                                            style={getListStyle2(
                                                                                                                snapshot.isDraggingOver
                                                                                                            )}>
                                                                                                            {a.advancedSearch.map((s, index) => {
                                                                                                                if (
                                                                                                                    //dont allow external users to d&d
                                                                                                                    s.accessby === "All" ||
                                                                                                                    s.accessby ===
                                                                                                                        "All Including External Read Only Users" ||
                                                                                                                    (s.accessby === "Creator" &&
                                                                                                                        parseInt(
                                                                                                                            s.accesscreatorid
                                                                                                                        ) === authState.user.id) ||
                                                                                                                    (s.accessby === "Admin" &&
                                                                                                                        authState.user.is_city_admin)
                                                                                                                ) {
                                                                                                                    //figure out who edited it last
                                                                                                                    var editableby = '', lastupdated = null;  
                                                                                                                    if(s.editableby !== "All Except External Read Only Users"){
                                                                                                                        s.editcreatorid !== ''? editableby = s.editcreatorid : editableby = "MS4Front" 
                                                                                                                    }else{//'All except external read only users case
                                                                                                                        editableby = s.editableby
                                                                                                                    }
                                                                                                                    let date = new Date(s.lastupdated);
                                                                                                                    lastupdated = " " + date.toLocaleDateString() 
                                                                                                                    if (parseInt(editableby)){
                                                                                                                        editableby = permission.find(p=>p.user === parseInt(editableby)? p.user_label:null) 
                                                                                                                        if(editableby !== '' && typeof(editableby) === 'object')editableby = editableby.user_label
                                                                                                                    }
                                                                                                                    let colorOverride = s.customized  ?  '#9cc5eb' : 'lightgrey' //all hei custom searches change a diff color

                                                                                                                    return (
                                                                                                                        <Draggable
                                                                                                                            key={s.id}
                                                                                                                            draggableId={s.id}
                                                                                                                            index={s.order}
                                                                                                                            isDragDisabled={
                                                                                                                                !authState.user
                                                                                                                                    .is_city_admin
                                                                                                                            }>
                                                                                                                            {(provided, snapshot) => (
                                                                                                                                //group search information (each button)
                                                                                                                                <div
                                                                                                                                    ref={provided.innerRef}
                                                                                                                                    {...provided.draggableProps}
                                                                                                                                    {...provided.dragHandleProps}
                                                                                                                                    style={getItemStyle2(
                                                                                                                                        snapshot.isDragging,
                                                                                                                                        provided.draggableProps.style,
                                                                                                                                        colorOverride
                                                                                                                                    )}
                                                                                                                                
                                                                                                                                    variant="contained"
                                                                                                                                    className={classes.fieldButton}>
                                                                                                                                    <table
                                                                                                                                        style={{width: "100%"}}>
                                                                                                                                        <tbody>
                                                                                                                                            <>
                                                                                                                                                {s.accessby === "All" ||
                                                                                                                                                    s.accessby === "All Including Read Only Users" ||
                                                                                                                                                    (s.accessby === "Creator" &&
                                                                                                                                                        parseInt() === authState.user.id) ||
                                                                                                                                                    (s.accessby === "Admin" &&
                                                                                                                                                        authState.user.is_city_admin)}
                                                                                                                                                {
                                                                                                                                                    <tr key={s.id }>
                                                                                                                                                        <td className={ classes.dottedLine}>
                                                                                                                                                        <Tooltip title={s.customized ?"Created by Houston Engineering":"Last Edited by: " + editableby + ". Date Last Edited: " + lastupdated}>
                                                                                                                                                            <Typography
                                                                                                                                                                className={ classes.MuiTypographyBody}
                                                                                                                                                                style={{ width: "100%",  fontSize: 19}}>
                                                                                                                                                                { s.name }
                                                                                                                                                            </Typography>
                                                                                                                                                        </Tooltip>
                                                                                                                                                        </td>

                                                                                                                                                        <td style={{whiteSpace:"nowrap" }}
                                                                                                                                                            className={classes.searchButtons}>
                                                                                                                                                            <Button
                                                                                                                                                                style={{
                                                                                                                                                                    marginLeft: 13,
                                                                                                                                                                    width: "220px",
                                                                                                                                                                }}
                                                                                                                                                                className={
                                                                                                                                                                    classes.MuiButtonLabel
                                                                                                                                                                }
                                                                                                                                                                variant="contained"
                                                                                                                                                                color="primary"
                                                                                                                                                                onClick={(e) => {
                                                                                                                                                                    history.push("/page/" + page[0].id +"/advancedsearch/" +s.id);
                                                                                                                                                                }}>
                                                                                                                                                                <Reorder />
                                                                                                                                                                &nbsp;&nbsp;&nbsp;View
                                                                                                                                                                Search
                                                                                                                                                            </Button>
                                                                                                                                                            {s.geometriesonmap && (
                                                                                                                                                                <Button
                                                                                                                                                                    style={{
                                                                                                                                                                        marginLeft: 13,
                                                                                                                                                                        width: "220px",
                                                                                                                                                                    }}
                                                                                                                                                                    className={classes.MuiButtonLabel}
                                                                                                                                                                    variant="contained"
                                                                                                                                                                    color="primary"
                                                                                                                                                                    onClick={(e) => {
                                                                                                                                                                        history.push("/map/" +page[0].id +"/section/" + s.section +"/advancedsearch/" + s.id);
                                                                                                                                                                    }}>
                                                                                                                                                                    <Map />
                                                                                                                                                                    &nbsp;&nbsp;&nbsp;View Map
                                                                                                                                                                </Button>
                                                                                                                                                            )}
                                                                                                                                                            <Tooltip title="Edit Saved Search">
                                                                                                                                                                <IconButton
                                                                                                                                                                    className={
                                                                                                                                                                        classes.edit
                                                                                                                                                                    } 
                                                                                                                                                                    onClick={(e) => {
                                                                                                                                                                    history.push("/page/" + page[0].id +"/advancedsearch/" +s.id);
                                                                                                                                                                    }}>
                                                                                                                                                                    <Edit
                                                                                                                                                                        style={{  color: "white"}}
                                                                                                                                                                    />
                                                                                                                                                                </IconButton>
                                                                                                                                                            </Tooltip>
                                                                                                                                                            <Tooltip title="Permanently Delete Search">
                                                                                                                                                                <IconButton
                                                                                                                                                                    disabled={
                                                                                                                                                                        readOnly
                                                                                                                                                                    }
                                                                                                                                                                    className={
                                                                                                                                                                        classes.delete
                                                                                                                                                                    }
                                                                                                                                                                    style={{
                                                                                                                                                                        marginLeft: 15,
                                                                                                                                                                        marginRight: 15,
                                                                                                                                                                    }}
                                                                                                                                                                    onClick={(
                                                                                                                                                                        e
                                                                                                                                                                    ) => {
                                                                                                                                                                        this.setState(
                                                                                                                                                                            {
                                                                                                                                                                                deleteDialogOpen: true,
                                                                                                                                                                                searchToDelete:
                                                                                                                                                                                    s,
                                                                                                                                                                            }
                                                                                                                                                                        );
                                                                                                                                                                    }}>
                                                                                                                                                                    <DeleteIcon
                                                                                                                                                                        style={{
                                                                                                                                                                            color: "white",
                                                                                                                                                                        }}
                                                                                                                                                                    />
                                                                                                                                                                </IconButton>
                                                                                                                                                            </Tooltip>
                                                                                                                                                        </td>
                                                                                                                                                        <td
                                                                                                                                                            key={
                                                                                                                                                                s.id
                                                                                                                                                            }
                                                                                                                                                            className={
                                                                                                                                                                classes.searchShowSmall
                                                                                                                                                            }>
                                                                                                                                                            <Tooltip
                                                                                                                                                                title="Search Menu"
                                                                                                                                                                placement="bottom-start"
                                                                                                                                                                enterDelay={
                                                                                                                                                                    300
                                                                                                                                                                }>
                                                                                                                                                                <IconButton
                                                                                                                                                                    className={
                                                                                                                                                                        classes.headerButton
                                                                                                                                                                    }
                                                                                                                                                                    onClick={(
                                                                                                                                                                        event
                                                                                                                                                                    ) =>
                                                                                                                                                                        this.handleSmallMenu(
                                                                                                                                                                            event,
                                                                                                                                                                            s
                                                                                                                                                                        )
                                                                                                                                                                    }>
                                                                                                                                                                    <MoreVertIcon />
                                                                                                                                                                </IconButton>
                                                                                                                                                            </Tooltip>
                                                                                                                                                        </td>
                                                                                                                                                    </tr>
                                                                                                                                                }

                                                                                                                                                <Menu
                                                                                                                                                    id="menu-small"
                                                                                                                                                    anchorEl={
                                                                                                                                                        smallAnchor
                                                                                                                                                    }
                                                                                                                                                    classes={{
                                                                                                                                                        paper: classes.list,
                                                                                                                                                    }}
                                                                                                                                                    anchorOrigin={{
                                                                                                                                                        vertical:
                                                                                                                                                            "top",
                                                                                                                                                        horizontal:
                                                                                                                                                            "right",
                                                                                                                                                    }}
                                                                                                                                                    transformOrigin={{
                                                                                                                                                        vertical:
                                                                                                                                                            "top",
                                                                                                                                                        horizontal:
                                                                                                                                                            "right",
                                                                                                                                                    }}
                                                                                                                                                    open={
                                                                                                                                                        smallMenuOpen
                                                                                                                                                    }
                                                                                                                                                    onClose={
                                                                                                                                                        this
                                                                                                                                                            .handleSmallMenuClose
                                                                                                                                                    }>
                                                                                                                                                    <ListItem
                                                                                                                                                        button
                                                                                                                                                        className={
                                                                                                                                                            classes.topListItem
                                                                                                                                                        }>
                                                                                                                                                        <ListItemText
                                                                                                                                                            primary={
                                                                                                                                                                <Typography variant="h6">
                                                                                                                                                                    Advanced
                                                                                                                                                                    Search
                                                                                                                                                                    Menu
                                                                                                                                                                </Typography>
                                                                                                                                                            }
                                                                                                                                                            classes={{
                                                                                                                                                                root: classes.topListText,
                                                                                                                                                                primary:
                                                                                                                                                                    classes.topListText,
                                                                                                                                                            }}
                                                                                                                                                        />
                                                                                                                                                    </ListItem>
                                                                                                                                                    <ListItem
                                                                                                                                                        button
                                                                                                                                                        onClick={(
                                                                                                                                                            e
                                                                                                                                                        ) => {
                                                                                                                                                            if (
                                                                                                                                                                searchSection
                                                                                                                                                            )
                                                                                                                                                                history.push(
                                                                                                                                                                    "/page/" +
                                                                                                                                                                        page[0]
                                                                                                                                                                            .id +
                                                                                                                                                                        "/advancedsearch/" +
                                                                                                                                                                        searchSection.id
                                                                                                                                                                );
                                                                                                                                                        }}
                                                                                                                                                        className={
                                                                                                                                                            classes.listItem
                                                                                                                                                        }>
                                                                                                                                                        <ListItemIcon>
                                                                                                                                                            <Edit />
                                                                                                                                                        </ListItemIcon>
                                                                                                                                                        <ListItemText primary="View Search" />
                                                                                                                                                    </ListItem>
                                                                                                                                                    {s.geometriesonmap && (
                                                                                                                                                        <ListItem
                                                                                                                                                            button
                                                                                                                                                            onClick={(
                                                                                                                                                                e
                                                                                                                                                            ) => {
                                                                                                                                                                if (
                                                                                                                                                                    searchSection
                                                                                                                                                                )
                                                                                                                                                                    history.push(
                                                                                                                                                                        "/map/" +
                                                                                                                                                                            page[0]
                                                                                                                                                                                .id +
                                                                                                                                                                            "/section/" +
                                                                                                                                                                            searchSection.section +
                                                                                                                                                                            "/advancedsearch/" +
                                                                                                                                                                            searchSection.id
                                                                                                                                                                    );
                                                                                                                                                            }}
                                                                                                                                                            className={
                                                                                                                                                                classes.listItem
                                                                                                                                                            }>
                                                                                                                                                            <ListItemIcon>
                                                                                                                                                                <Map />
                                                                                                                                                            </ListItemIcon>
                                                                                                                                                            <ListItemText primary="View Map" />
                                                                                                                                                        </ListItem>
                                                                                                                                                    )}
                                                                                                                                                    <ListItem
                                                                                                                                                        button
                                                                                                                                                        onClick={(
                                                                                                                                                            e
                                                                                                                                                        ) => {
                                                                                                                                                            if (
                                                                                                                                                                searchSection
                                                                                                                                                            )
                                                                                                                                                                history.push(
                                                                                                                                                                    "/page/" +
                                                                                                                                                                        page[0]
                                                                                                                                                                            .id +
                                                                                                                                                                        "/advancedsearch/" +
                                                                                                                                                                        searchSection.id
                                                                                                                                                                );
                                                                                                                                                        }}
                                                                                                                                                        className={
                                                                                                                                                            classes.listItem
                                                                                                                                                        }>
                                                                                                                                                        <ListItemIcon>
                                                                                                                                                            <Edit />
                                                                                                                                                        </ListItemIcon>
                                                                                                                                                        <ListItemText primary="Edit Saved Search" />
                                                                                                                                                    </ListItem>
                                                                                                                                                    <ListItem
                                                                                                                                                        button
                                                                                                                                                        disabled={
                                                                                                                                                            readOnly
                                                                                                                                                        }
                                                                                                                                                        onClick={(
                                                                                                                                                            e
                                                                                                                                                        ) => {
                                                                                                                                                            if (
                                                                                                                                                                searchSection
                                                                                                                                                            )
                                                                                                                                                                return (
                                                                                                                                                                    this.setState(
                                                                                                                                                                        {
                                                                                                                                                                            deleteDialogOpen: true,
                                                                                                                                                                            searchToDelete:
                                                                                                                                                                                searchSection,
                                                                                                                                                                        }
                                                                                                                                                                    ),
                                                                                                                                                                    this.handleSmallMenuClose()
                                                                                                                                                                );
                                                                                                                                                        }}
                                                                                                                                                        className={
                                                                                                                                                            classes.listItem
                                                                                                                                                        }>
                                                                                                                                                        <ListItemIcon>
                                                                                                                                                            <DeleteIcon />
                                                                                                                                                        </ListItemIcon>
                                                                                                                                                        <ListItemText primary="Permanetly Delete Search" />
                                                                                                                                                    </ListItem>
                                                                                                                                                </Menu>
                                                                                                                                            </>
                                                                                                                                        </tbody>
                                                                                                                                    </table>
                                                                                                                                </div>
                                                                                                                            )}
                                                                                                                        </Draggable>
                                                                                                                    );
                                                                                                                } else return null;
                                                                                                            })}
                                                                                                        </div>
                                                                                                    )}
                                                                                                </Droppable>
                                                                                            </AccordionDetails>
                                                                                            {deleteDialogOpen && idx === 0 && (
                                                                                                <WarningDialog
                                                                                                    confirmAction={() => {
                                                                                                        if (searchToDelete) {
                                                                                                            ormAdvancedSearchDelete(
                                                                                                                searchToDelete.id
                                                                                                            );
                                                                                                        } else {
                                                                                                            ormAdvancedGroupDelete(groupDelete.id);
                                                                                                        }

                                                                                                        this.setState({
                                                                                                            deleteDialogOpen: false,
                                                                                                        });
                                                                                                    }}
                                                                                                    cancelAction={() =>
                                                                                                        this.setState({
                                                                                                            deleteDialogOpen: false,
                                                                                                        })
                                                                                                    }
                                                                                                    open={deleteDialogOpen}
                                                                                                    confirmText={
                                                                                                        searchToDelete
                                                                                                            ? "Delete Saved Search"
                                                                                                            : "Delete Group"
                                                                                                    }
                                                                                                    title={`Delete ${
                                                                                                        searchToDelete
                                                                                                            ? "'" + searchToDelete.name + "' Search"
                                                                                                            : groupDelete.name + " Group?"
                                                                                                    }`}
                                                                                                    text="Are you sure you wish to permanently delete?"
                                                                                                />
                                                                                            )}
                                                                                            {moving && (
                                                                                                <Dialog open={moving}>
                                                                                                    <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                                                                                                        <Typography variant="h5" className={classes.flex}>
                                                                                                            Resaving Ordering
                                                                                                        </Typography>
                                                                            
                                                                                                        {/* <IconButton onClick={returnDialog}>
                                                                                                            <Close />
                                                                                                        </IconButton> */}
                                                                                                    </Toolbar>
                                                                                                    <DialogContent style={{ minWidth: 400 }}>
                                                                                                        <DialogContentText>
                                                                                                            <Grid container spacing={2}>
                                                                                                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                                                                                                    <CircularProgress size={90} className={classes.progress} />
                                                                                                                    <Typography variant="h6" style={{ textAlign: "center" }}>
                                                                                                                        Please Wait...
                                                                                                                    </Typography>
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        </DialogContentText>
                                                                                                    </DialogContent>
                                                                                                </Dialog>
                                                                                            )}
                                                                                            {!loading && (
                                                                                                <Grid container spacing={2} alignItems="center">
                                                                                                    <Grid item xs={12} md={8} lg={3}>
                                                                                                        <Button
                                                                                                            fullWidth
                                                                                                            variant="contained"
                                                                                                            color="primary"
                                                                                                            onClick={(e) => {
                                                                                                                this.setState({
                                                                                                                    loading: true,
                                                                                                                    groupName: a.name,
                                                                                                                });
                                                                                                            }}
                                                                                                            style={{
                                                                                                                width: "270px",
                                                                                                                marginBottom: 19,
                                                                                                                marginLeft: 19,
                                                                                                            }}>
                                                                                                            <AddIcon />
                                                                                                            &nbsp;&nbsp;&nbsp;Create New
                                                                                                        </Button>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            )}
                                                                                        </Accordion>
                                                                                    </form>
                                                                                )}
                                                                            </Formm>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            );
                                                        })}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>

                                        {advancedgroups.length === 0 && ( //used when a page doesnt already have any saved searches as ONLY shows created new button, used with magnifying glass icon
                                            <Formm
                                                key={this.formKey}
                                                dontValidateOnMount={true}
                                                validateOnSubmit={true}
                                                getApi={(el) => (this.formApi = el)}>
                                                {(formApi) => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <Accordion defaultExpanded={true}>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                style={{ backgroundColor: "#ddd" }}
                                                                classes={{ root: classes.expandedMargin }}
                                                                className={classes.titlePanel}>
                                                                <Icon style={{ color: "#66686a" }}>search</Icon>
                                                                <Typography variant="subtitle1" style={{ marginLeft: 12, color: "#66686a" }}>
                                                                    Saved Search
                                                                </Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails style={{ paddingBottom: 8 }}>
                                                                <table style={{ width: "100%" }}>
                                                                    <tbody>
                                                                        {!loading && (
                                                                            <Grid container spacing={2} alignItems="center">
                                                                                <Grid item xs={5} lg={4} xl={3}>
                                                                                    <Button
                                                                                        fullWidth
                                                                                        variant="contained"
                                                                                        color="primary"
                                                                                        onClick={(e) => {
                                                                                            this.setState({
                                                                                                loading: true,
                                                                                                groupName: null,
                                                                                            });
                                                                                        }}>
                                                                                        <AddIcon />
                                                                                        &nbsp;&nbsp;&nbsp;Create New
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </form>
                                                )}
                                            </Formm>
                                        )}
                                    </>
                                )}
                                {perm &&
                                    perm.readonlyexternal && //for read only external users to only see the selected searches for a module, removes D&D, creating, deleting, etc...
                                    advancedgroups.map((a, idx) => {
                                        return (
                                            <Formm
                                                key={this.formKey}
                                                dontValidateOnMount={true}
                                                validateOnSubmit={true}
                                                getApi={(el) => (this.formApi = el)}>
                                                {(formApi) => (
                                                    <form onSubmit={formApi.submitForm}>
                                                        <Accordion defaultExpanded={true}>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                style={{
                                                                    backgroundColor: a.expansion_color ? a.expansion_color : "#ddd",
                                                                }}
                                                                classes={{ root: classes.expandedMargin }}
                                                                className={classes.titlePanel}>
                                                                <Icon
                                                                    style={{
                                                                        color: a.expansion_font_color ? a.expansion_font_color : "#66686a",
                                                                    }}>
                                                                    search
                                                                </Icon>
                                                                <Grid container spacing={1}>
                                                                    <Grid xs={5}>
                                                                        <Typography
                                                                            variant="subtitle1"
                                                                            style={{
                                                                                marginLeft: 12,
                                                                                color: a.expansion_font_color ? a.expansion_font_color : "#66686a",
                                                                            }}>
                                                                            {a.name}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </AccordionSummary>
                                                            <AccordionDetails style={{ paddingBottom: 8 }}>
                                                                <Grid container spacing={1}>
                                                                    {a.advancedSearch.map((s, index) => {
                                                                        if (s.accessby === "All Including External Read Only Users") {
                                                                            return (
                                                                                //group search information (each button)
                                                                                <Grid item xs={12}>
                                                                                    <table
                                                                                        style={{
                                                                                            width: "100%",
                                                                                        }}>
                                                                                        <tbody>
                                                                                            <>
                                                                                                <tr key={s.id}>
                                                                                                    <td className={classes.dottedLine}>
                                                                                                        <Typography
                                                                                                            className={classes.MuiTypographyBody}
                                                                                                            style={{
                                                                                                                width: "100%",
                                                                                                                fontSize: 19,
                                                                                                            }}>
                                                                                                            {s.name}
                                                                                                        </Typography>
                                                                                                    </td>

                                                                                                    <td
                                                                                                        style={{
                                                                                                            whiteSpace: "nowrap",
                                                                                                        }}
                                                                                                        className={classes.searchButtons}>
                                                                                                        <Button
                                                                                                            style={{
                                                                                                                marginLeft: 13,
                                                                                                                // marginBottom:
                                                                                                                //     "32px",
                                                                                                                width: "220px",
                                                                                                            }}
                                                                                                            className={classes.MuiButtonLabel}
                                                                                                            variant="contained"
                                                                                                            color="primary"
                                                                                                            onClick={(e) => {
                                                                                                                history.push(
                                                                                                                    "/page/" +
                                                                                                                        page[0].id +
                                                                                                                        "/advancedsearch/" +
                                                                                                                        s.id
                                                                                                                );
                                                                                                            }}>
                                                                                                            <Reorder />
                                                                                                            &nbsp;&nbsp;&nbsp;View Search
                                                                                                        </Button>
                                                                                                        {s.geometriesonmap && (
                                                                                                            <Button
                                                                                                                style={{
                                                                                                                    marginLeft: 13,
                                                                                                                    // marginBottom:
                                                                                                                    //     "32px",
                                                                                                                    width: "220px",
                                                                                                                }}
                                                                                                                className={classes.MuiButtonLabel}
                                                                                                                variant="contained"
                                                                                                                color="primary"
                                                                                                                onClick={(e) => {
                                                                                                                    history.push(
                                                                                                                        "/map/" +
                                                                                                                            page[0].id +
                                                                                                                            "/section/" +
                                                                                                                            s.section +
                                                                                                                            "/advancedsearch/" +
                                                                                                                            s.id
                                                                                                                    );
                                                                                                                }}>
                                                                                                                <Map />
                                                                                                                &nbsp;&nbsp;&nbsp;View Map
                                                                                                            </Button>
                                                                                                        )}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        key={s.id}
                                                                                                        className={classes.searchShowSmall}>
                                                                                                        <Tooltip
                                                                                                            title="Search Menu"
                                                                                                            placement="bottom-start"
                                                                                                            enterDelay={300}>
                                                                                                            <IconButton
                                                                                                                className={classes.headerButton}
                                                                                                                onClick={(event) =>
                                                                                                                    this.handleSmallMenu(event, s)
                                                                                                                }>
                                                                                                                <MoreVertIcon />
                                                                                                            </IconButton>
                                                                                                        </Tooltip>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </Grid>
                                                                            );
                                                                        } else return null;
                                                                    })}
                                                                </Grid>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </form>
                                                )}
                                            </Formm>
                                        );
                                    })}
                                <Accordion defaultExpanded={true} style={{marginRight:16}}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        style={{ backgroundColor: "#ddd" }}
                                        classes={{ root: classes.expandedMargin }}
                                        className={classes.titlePanel}>
                                        <Icon style={{ color: "#66686a" }}>addchart</Icon>
                                        <Typography variant="subtitle1" style={{ marginLeft: 12, color: "#66686a" }}>
                                            External Dashboards
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ paddingBottom: 8 }}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                {dashboards.map((s) => {
                                                    return (
                                                        <table style={{ width: "100%" }}>
                                                            <tr>
                                                                <td style={{ width: "100%" }}>
                                                                    <Typography style={{ fontSize: 19 }}>{s.iframe_url_dashboard}</Typography>
                                                                </td>
                                                                <td style={{ width: 100, whiteSpace: "nowrap" }}>
                                                                    <Tooltip title="Edit External Dashboard">
                                                                        <IconButton
                                                                            className={classes.edit}
                                                                            onClick={(e) => {
                                                                                this.setState({
                                                                                    loading_external: true,
                                                                                    dashboard: s,
                                                                                });
                                                                            }}>
                                                                            <Edit style={{ color: "white" }} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Delete External Dashboard">
                                                                        <IconButton
                                                                            disabled={readOnly}
                                                                            className={classes.delete}
                                                                            onClick={(e) => {
                                                                                ormExternalDashboardDelete(s.id);
                                                                            }}>
                                                                            <DeleteIcon style={{ color: "white" }} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    );
                                                })}
                                            </Grid>
                                            {perm &&
                                                !perm.readonlyexternal && 
                                                <Grid item xs={12}>
                                                    {!loading && (
                                                        <Grid container spacing={2} alignItems="center">
                                                            <Grid item xs={5} lg={4} xl={3}>
                                                                <Button
                                                                    fullWidth
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={(e) => {
                                                                        this.setState({
                                                                            loading_external: true,
                                                                            dashboard: null,
                                                                        });
                                                                    }}>
                                                                    <AddIcon />
                                                                    &nbsp;&nbsp;&nbsp;Add New
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            }
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </>
                        </Grid>

                        <ExternalDahboardEdit
                            classes={classes}
                            loading_external={loading_external}
                            returnDialog={this.returnDialogExternal}
                            ormExternalDashboardUpdate={ormExternalDashboardUpdate}
                            ormExternalDashboardCreate={ormExternalDashboardCreate}
                            page={page}
                            dashboard={dashboard}
                        />
                        <CreateNewSearch
                            classes={classes}
                            loading={loading}
                            groupName={groupName}
                            returnDialog={this.returnDialog}
                            sections={sections}
                            history={history}
                            advancedgroups={advancedgroups}
                            ormAdvancedGroupCreate={ormAdvancedGroupCreate}
                            ormAdvancedSearchCreate={ormAdvancedSearchCreate}
                            ormAdvancedGroupUpdate={ormAdvancedGroupUpdate}
                            page={page}
                            authState={authState}
                        />
                    </Grid>
                </AppContainer>
            );
        }
    }
}

class ExternalDahboardEdit extends Component {
    saveRecord = (values) => {
        const { ormExternalDashboardCreate, ormExternalDashboardUpdate, page, returnDialog } = this.props;

        if (values.id) {
            ormExternalDashboardUpdate(values);
            returnDialog();
        } else {
            ormExternalDashboardCreate({
                page: page[0].id,
                ...values,
            }).then((e) => {
                returnDialog();
            });
        }
    };

    fieldValidator = (values) => {
        const isRequired = (val) => {
            return !val ? "Required" : null;
        };

        return {
            iframe_url_dashboard: isRequired(values.iframe_url_dashboard),
        };
    };

    render() {
        const { loading_external, classes, dashboard } = this.props;

        return (
            <Dialog open={loading_external}>
                <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                    <Typography variant="h5" className={classes.flex}>
                        External Dashboard
                    </Typography>
                    <IconButton aria-label="Close Dialog" onClick={this.props.returnDialog}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <DialogContentText>
                        <Formm
                            key={this.formKey}
                            dontValidateOnMount={true}
                            defaultValues={dashboard}
                            validateOnSubmit={true}
                            validateError={this.fieldValidator}
                            onSubmit={this.saveRecord}>
                            {(formApi) => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField field="iframe_url_dashboard" label="iFrame URL: " fullWidth />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField field="width" label="(Optional) Width (px): " fullWidth />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField field="height" label="(Optional) Height (px): " fullWidth />
                                        </Grid>
                                        <Grid xs={12}>
                                            <Button type="submit" variant="raised" color="primary" className={classes.floatRight}>
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formm>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

class CreateNewSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSaving: false,
        };
    }
    saveRecord = (value) => {
        const {
            ormAdvancedGroupCreate,
            ormAdvancedSearchCreate,
            ormAdvancedGroupUpdate,
            advancedgroups,
            page,
            history,
            authState,
            returnDialog,
        } = this.props;

        const group = advancedgroups.find((g) => {
            return g.name === value.group_name;
        });

        if (group) {
            //group already created

            ormAdvancedGroupUpdate({
                id: group.id,
                page: page[0].id,
                // order: advancedgroups.length,  //dont care as its already set orderwise
            });

            ormAdvancedSearchCreate({
                advancedgroup: group.id,
                name: value.name,
                accessby: value.accessby,
                accesscreatorid: authState.user.id,
                editableby: value.editableby,
                editcreatorid: authState.user.id,
                dashboard: value.dashboard,
                geometriesonmap: value.geometriesonmap,
                order: group.advancedSearch.length,
                geo_color:value.geo_color
            }).then((e) => {
                returnDialog();
                history.push("/page/" + page[0].id + "/advancedsearch/" + e.id);
            });
        } else {
            //new group to create
            ormAdvancedGroupCreate({
                page: page[0].id,
                name: value.g_name,
                expansion_color: "#dddddd",
                order: advancedgroups.length, //make sure to add to orderlist
            }).then((g) => {
                returnDialog();
                ormAdvancedSearchCreate({
                    //dont care about order as its only the first one and will be 0
                    advancedgroup: group ? group.id : g.id,
                    name: value.name,
                    accessby: value.accessby,
                    accesscreatorid: authState.user.id,
                    editableby: value.editableby,
                    editcreatorid: authState.user.id,
                    dashboard: value.dashboard,
                    geometriesonmap: value.geometriesonmap,
                    geo_color:value.geo_color
                }).then((e) => {
                    history.push("/page/" + page[0].id + "/advancedsearch/" + e.id);
                });
            });
        }
    };

    fieldValidator = (values) => {
        const isRequired = (val) => {
            return !val ? "Required" : null;
        };

        if (values.g_name === " ") values.g_name = null;

        //create groupname and gname for the chks to make sure only one is selected
        var groupname = null,
            gname = null;
        //setup warnings for the creation of searches making sure they select only a valid group
        if (values.g_name && values.group_name !== "No Group Selected. Select a Group or Create a New Group") {
            groupname = "Select 'No Group Selected'from the dropdown to create a 'New Group' (above)";
            gname = "Remove this 'New Groups' name to use a 'Selected Group' (below)";
        } else if (!values.g_name && values.group_name === "No Group Selected. Select a Group or Create a New Group") {
            groupname = "'Select Group' from the dropdown or 'Create a New Group' (above)";
            gname = "Create a 'New Group' or 'Select a Group' from the dropdown (below)";
        }

        var valObj = {};
        if (values.group_name) {
            valObj = {
                g_name: gname,
                group_name: groupname,
                name: isRequired(values.name),
                dashboard: null, //dont care
                // g_name: null //clear buffer
            };
        }
        // else {
        //     valObj = {
        //         // accessby: isRequired(values.accessby),

        //         // editableby: isRequired(values.editableby),
        //         g_name: isRequired(values.g_name) === "Required" ? isRequired(values.g_name) + " or Select Previously Saved Group Below" : null,
        //         name: isRequired(values.name),

        //         dashboard: null, //dont care
        //         group_name: null //clear buffer
        //     };
        // }

        var r = false; //used for saving circ progress
        for (const i in valObj) {
            if (valObj[i]) {
                // there is a failure so dont proceed
                r = true;
            }
        }
        if (!r) {
            this.setState({ isSaving: true });
        }

        return valObj;
    };

    //value function for dropdown datatype. This is the options property.
    dropDownValues = (table) => {
        return table.map((row) => ({
            label: row.name,
            value: row.name,
        }));
    };
    dropDownValues2 = (table) => {
        return table.map((row) => ({
            label: row,
            value: row,
        }));
    };

    defaultValues = () => {
        const { groupName } = this.props;
        var defaultValues = {};

        defaultValues["group_name"] = groupName;
        defaultValues["editableby"] = "Creator";
        defaultValues["accessby"] = "Creator";
        defaultValues["dashboard"] = false
        defaultValues["geo_color"] = "#C13A3A"; 
        defaultValues["geometriesonmap"] = true
        return defaultValues;
    };

    //update color of expansion bar if a prior group was choosen
    relay = (e) => {
        const { advancedgroups } = this.props;

        const found = advancedgroups.find((g) => {
            return g.name === e.target.value;
        });
        this.formApi.setValue("group_name", e.target.value);
        if (found) {
            this.formApi.setValue("expansion_color", found.expansion_color);
            this.formApi.setValue("expansion_font_color", found.expansion_font_color);
        }
    };
    

    render() {
        const { loading, classes, advancedgroups } = this.props;
        const { isSaving } = this.state;

        var copyAdvGroups = [];
        copyAdvGroups.push({ name: "No Group Selected. Select a Group or Create a New Group" });
        advancedgroups.map((g) => copyAdvGroups.push({ name: g.name }));
        const edit_access = ["Creator", "Admin", "All", "All Except External Read Only Users"];
        const accessibility = ["Creator", "Admin", "All", "All Including External Read Only Users"];
        return (
            <Dialog open={loading}>
                <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                    <Typography variant="h5" className={classes.flex}>
                        Saved Search Initial Configuration
                    </Typography>
                    <IconButton aria-label="Close Dialog" onClick={this.props.returnDialog}>
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <DialogContentText>
                        <Formm
                            key={this.formKey}
                            dontValidateOnMount={true}
                            defaultValues={this.defaultValues()}
                            validateOnSubmit={true}
                            validateError={this.fieldValidator}
                            getApi={(el) => (this.formApi = el)} //allows remote access
                            onSubmit={(values, e, formApi) => this.saveRecord(values)}>
                            {(formApi) => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={2} style={{ paddingTop: 16 }}>
                                        <TextField field="name" label="Search Name: " fullWidth style={{ marginBottom: 12 }} />
                                        <div
                                            style={{
                                                backgroundColor: "#d3d3d3",
                                                minWidth: 570,
                                                paddingBottom: 7,
                                                borderRadius: 4,
                                            }}>
                                            <Typography
                                                variant="h6"
                                                className={classes.flex}
                                                style={{
                                                    fontSize: 16,

                                                    marginBottom: 7,
                                                    paddingLeft: 12,
                                                    color: "#fff",
                                                    background: "#191919",
                                                    borderRadius: 4,
                                                    paddingTop: 4,
                                                    paddingBottom: 4,
                                                }}>
                                                Group Display: Choose a New 'or' Existing Group to display this Saved Search
                                            </Typography>
                                            <TextField
                                                field="g_name"
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Create a New Group?"
                                                        helpText="The Saved Search will be grouped under this name. Leave this field blank if you want to place this search within an existing Saved Search Group."
                                                    />
                                                }
                                                style={{ marginLeft: "2%", width: "96%" }}
                                            />

                                            <Grid item xs={10}>
                                                <Typography
                                                    variant="h5"
                                                    className={classes.flex}
                                                    style={{ marginLeft: "54%", paddingTop: 5, fontSize: 21 }}>
                                                    (OR)
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Select
                                                    field="group_name"
                                                    options={this.dropDownValues(copyAdvGroups)}
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Select Group?"
                                                            helpText="This Saved Search will be placed in the selected Group."
                                                        />
                                                    }
                                                    onChange={(e) => this.relay(e)}
                                                    style={{ marginLeft: "2%", minWidth: 551, marginBottom: 19 }}
                                                />
                                            </Grid>
                                        </div>
                                       
                                        <Select
                                            field="editableby"
                                            options={this.dropDownValues2(edit_access)}
                                            label={
                                                <HelpLabel
                                                    inputLabel="Edit Privileges?"
                                                    helpText="Select the user type that can edit this search.  Note: the 'Creator' selection is the user that selected this setting."
                                                />
                                            }
                                            style={{ minWidth: 576,marginTop: 12 }}
                                        />
                                    
                                        <Select
                                            field="accessby"
                                            options={this.dropDownValues2(accessibility)}
                                            label={
                                                <HelpLabel
                                                    inputLabel="Accessibility?"
                                                    helpText="Select who can view this search. Note: the 'Creator' is the person that 'Saved' the search with this setting being set."
                                                />
                                            }
                                            style={{ minWidth: 576,marginTop: 12, marginBottom:12  }}
                                        />
                                        <Grid container>
                                            <Grid xs={5} item style={{marginLeft:2}}>
                                                <Switch
                                                    field="dashboard"
                                                    name="dashboard"
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Show on Dashboard?"
                                                            helpText="Select 'Yes' to show this Saved Search on the dashboard."
                                                        />
                                                    }
                                                />
                                            </Grid>
                                            <Grid xs={5} item>
                                                <Switch
                                                    field="geometriesonmap"
                                                    name="geometriesonmap"
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Show Geometry on Map?"
                                                            helpText="Select 'Yes' to show this Saved Search Geometries on the Map."
                                                        />
                                                    }
                                                />
                                            </Grid>
                                            
                                        </Grid>
                                        <ColorPicker field="geo_color" label="Map Layer Color" />
                                         
                                        <Grid xs={12}>
                                            <div style={{ marginTop: 20 }}>
                                                <Button type="submit" variant="raised" color="primary" className={classes.floatRight}>
                                                    Create Search
                                                </Button>
                                            </div>
                                        </Grid>
                                        {/* PLACE CIRCULAR LOADING DIAL ON SCREEN WHEN LOADING IS TRUE THIS PREVENTS USER FROM DOUBLE CLICKING FORM CREATE */}
                                        {isSaving && (
                                            <div style={{ marginTop: 20 }}>
                                                <Typography style={{ display: "inline-block", verticalAlign: "top" }} variant="h6">
                                                    Please wait...
                                                </Typography>
                                                <CircularProgress size={36} className={classes.progress} />
                                            </div>
                                        )}
                                    </Grid>
                                </form>
                            )}
                        </Formm>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}
CreateAdvancedSearches.displayName = "CreateAdvancedSearches";

CreateAdvancedSearches = connect(
    (state, ownProps) => ({
        page: getPageId(state, ownProps),
        sections: allSections(state, ownProps),
        pagefields: getPageFields(state, ownProps),
        filters: getFilters(state, ownProps),
        advancedgroups: getAdvancedGroups(state, ownProps),
        advancedfilter: getAdvancedFilters(state, ownProps),
        alladvancedsearches: getAllAdvancedSearches(state, ownProps),
        permission: getPermission(state, ownProps),
        dashboards: getExternalDashboards(state, ownProps),
        authState: state.auth,
    }),
    {
        ...Filter.actions,
        ...AdvancedGroup.actions,
        ...AdvancedSearch.actions,
        ...AdvancedFilter.actions,
        ...AdvancedTableFields.actions,
        ...ExternalDashboard.actions,
    }
)(CreateAdvancedSearches);

export default withStyles(styles)(withRouter(CreateAdvancedSearches));
